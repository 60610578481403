import React, { ReactChild, ReactChildren } from "react";
import styled from "styled-components/macro";
import { Classes, H3, H6 } from "@blueprintjs/core";

const StyledSettingsView = styled.div`
  height: 100%;
  margin-left: 5px;
`;

interface ISettingsViewProps {
  children: ReactChildren | ReactChild[];
  helperText?: string;
  title: string;
}

const SettingsView: React.FC<ISettingsViewProps> = ({
  children,
  helperText,
  title,
  ...divProps
}) => {
  return (
    <StyledSettingsView {...divProps}>
      <H3>{title}</H3>
      <H6 className={Classes.TEXT_MUTED}>{helperText}</H6>
      {children}
    </StyledSettingsView>
  );
};

export default SettingsView;
