export type TMilieu = "IMPORT" | "MAIN" | "MODEL_DATA" | "MODEL_VIEW";

export const Milieu: { [key: string]: TMilieu } = {
  Import: "IMPORT",
  Main: "MAIN",
  ModelData: "MODEL_DATA",
  ModelView: "MODEL_VIEW",
};

export enum AuthState {
  SignUp = "signup",
  SignOut = "signout",
  SignIn = "signin",
  Loading = "loading",
  SignedOut = "signedout",
  SignedIn = "signedin",
  SigningUp = "signingup",
  ConfirmSignUp = "confirmSignUp",
  confirmingSignUpCustomFlow = "confirmsignupcustomflow",
  ConfirmSignIn = "confirmSignIn",
  confirmingSignInCustomFlow = "confirmingsignincustomflow",
  VerifyingAttributes = "verifyingattributes",
  ForgotPassword = "forgotpassword",
  ResetPassword = "resettingpassword",
  SettingMFA = "settingMFA",
  TOTPSetup = "TOTPSetup",
  CustomConfirmSignIn = "customConfirmSignIn",
  VerifyContact = "verifyContact",
}

export const BLUEPRINT_NAMESPACE = "bp5";
export const MISSING_VALUE = "-";

export const Scenarios = ["Base", "Upside", "Downside", "Consensus"];

export const ABSOLUTE_PERIOD_START_YEAR = 2018;
export const ABSOLUTE_PERIOD_END_YEAR = 2035;
export const RELATIVE_PERIOD_YEARS_GENERATED = 10;
