import React, { ChangeEventHandler, useMemo, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import {
  Button,
  Classes,
  ControlGroup,
  FormGroup,
  H3,
  HTMLSelect,
  Intent,
  NumericInput,
  PanelProps,
  Popover,
} from "@blueprintjs/core";
import range from "lodash.range";
import StyledConfigurationPanel from "components/utility/StyledConfigurationPanel";
import { IModelConfiguration } from "types/createModel";
import { IConfigureModelContext } from "context/configureModelContext";
import { Flex } from "components/utility/StyledComponents";
import { PeriodTypes } from "constants/createModelConstants";
import { clamp } from "utils/generic";
import { DatePicker3 } from "@blueprintjs/datetime2";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  width: 100%;
`;

interface IModelTimeConfigProps {
  config: IModelConfiguration &
    Pick<IModelConfiguration, "companyName" | "ticker" | "exchange" | "startDate">;
  configuring: boolean;
  isImport?: boolean;
  onConfigure: () => void;
  title?: string;
}

export type TModelTimeConfig = PanelProps<
  IModelTimeConfigProps & Omit<IConfigureModelContext, "config">
>;

const ModelTimeConfig: React.FC<TModelTimeConfig> = ({
  config,
  configuring,
  isImport = false,
  onConfigure,
  setConfig,
  title = "Configure Periodisation",
}) => {
  // TODO: Default to Q4 on shange to quarterly/
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const setPeriodType: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setConfig({ ...config, periodType: e.currentTarget.value as "Annual" | "Quarterly" });
  };
  const handleStartPeriodChange = (periodType: "A" | "Q", value: string) => {
    const nextValue = Number(value);
    if (periodType === "A") {
      setConfig({ ...config, startPeriod: nextValue });
    } else if (periodType === "Q") {
      setConfig({ ...config, startPeriod: Math.floor(config.startPeriod) + nextValue });
    }
  };
  const startPeriod = useMemo(() => {
    return config.periodType === PeriodTypes.Annual
      ? { startPeriodA: Math.floor(config?.startPeriod) }
      : { startPeriodA: Math.floor(config?.startPeriod), startPeriodQ: config?.startPeriod % 1 };
  }, [config?.periodType, config.startPeriod]);

  const startYears =
    config?.newestReportedYear && config.oldestReportedYear
      ? range(config.oldestReportedYear, config.newestReportedYear + 1)
      : range(1980, new Date().getFullYear() + 1);

  const availableHistoricals = useMemo(() => {
    if (isImport) {
      return { min: 1, max: 15 };
    }
    if (!config.blank && config?.newestReportedYear && config?.oldestReportedYear) {
      const remainingPeriods = startYears.filter((year) => year <= startPeriod.startPeriodA);
      const maxYear = Math.max(...remainingPeriods) - Math.min(...remainingPeriods) + 1;
      if (config.periodType === PeriodTypes.Annual) {
        return {
          min: 1,
          max: maxYear,
        };
      } else if (
        config.periodType === PeriodTypes.Quarterly &&
        typeof startPeriod?.startPeriodQ === "number"
      ) {
        const { startPeriodQ } = startPeriod;
        return { min: 1, max: maxYear - (startPeriodQ < 1 ? startPeriodQ : 0) };
      }
    }
    return { min: 1, max: 5 };
  }, [config, isImport, startPeriod, startYears]);

  const onHistoricalPeriodChange = (numHistoricalYears: number) => {
    setConfig({
      ...config,
      numHistoricalYears: clamp(
        numHistoricalYears,
        availableHistoricals.min,
        availableHistoricals.max
      ),
    });
  };
  const onForecastPeriodChange = (numForecastYears: number) => {
    setConfig({ ...config, numForecastYears: clamp(numForecastYears, 1, 15) });
  };

  return (
    <StyledConfigurationPanel>
      <H3>{title}</H3>
      <p className={Classes.RUNNING_TEXT} style={{ fontWeight: "bold" }}>
        Configuring company: <span>{config.companyName}</span>{" "}
        <span className={Classes.TEXT_MUTED}>
          {config.exchange
            ? `(${
                config.exchange
                  ? config.exchange["Google Prefix"] || config.exchange["ISO MIC"]
                  : undefined
              }: ${config.ticker})`
            : ""}
        </span>
      </p>
      <StyledForm>
        <div>
          <FormGroup label="Period type:">
            <HTMLSelect
              options={Object.values(PeriodTypes)}
              onChange={setPeriodType}
              value={config.periodType}
            />
          </FormGroup>
          <FormGroup
            label="Historical periods:"
            helperText={`Minimum 1, maximum ${availableHistoricals.max}.`}
          >
            <NumericInput
              clampValueOnBlur={true}
              min={1}
              max={availableHistoricals.max}
              onValueChange={onHistoricalPeriodChange}
              stepSize={config.periodType === "Annual" ? 1 : 0.25}
              minorStepSize={0.25}
              value={config.numHistoricalYears}
            />
          </FormGroup>
          <FormGroup label="Forecast periods:" helperText="Minimum 1, maximum: 15.">
            <NumericInput
              clampValueOnBlur={true}
              min={1}
              minorStepSize={0.25}
              max={15}
              onValueChange={onForecastPeriodChange}
              stepSize={config.periodType === "Annual" ? 1 : 0.25}
              value={config.numForecastYears}
            />
          </FormGroup>
          <FormGroup
            label="Most recent historical period:"
            helperText="The most recent reported period to use in the model."
          >
            <ControlGroup>
              <HTMLSelect
                onChange={(e) => handleStartPeriodChange("A", e.currentTarget.value)}
                options={startYears}
                value={startPeriod.startPeriodA}
              />
              {config?.periodType === "Quarterly" ? (
                <HTMLSelect
                  value={startPeriod.startPeriodQ}
                  onChange={(e) => handleStartPeriodChange("Q", e.currentTarget.value)}
                  options={[
                    { label: "Q1", value: 0 },
                    { label: "Q2", value: 0.25 },
                    { label: "Q3", value: 0.5 },
                    { label: "Q4", value: 0.75 },
                  ]}
                />
              ) : null}
            </ControlGroup>
          </FormGroup>
          {config.companyType === "Public" ? (
            <FormGroup
              label="Start date:"
              helperText="The date and time from which to take the firm's share price."
            >
              <Popover
                content={
                  <DatePicker3
                    onChange={(selectedDate) =>
                      setConfig({ ...config, startDate: selectedDate ?? undefined })
                    }
                    maxDate={new Date()}
                    timePrecision={undefined}
                    value={
                      typeof config.startDate === "string"
                        ? new Date(config.startDate)
                        : config.startDate
                    }
                  />
                }
                isOpen={datePickerOpen}
                onClose={() => setDatePickerOpen(false)}
              >
                <Button
                  active={datePickerOpen}
                  onClick={() => setDatePickerOpen(!datePickerOpen)}
                  text={
                    config?.startDate
                      ? typeof config.startDate === "string"
                        ? new Date(config.startDate).toLocaleDateString()
                        : config.startDate.toLocaleDateString()
                      : ""
                  }
                />
              </Popover>
            </FormGroup>
          ) : null}
        </div>
        <Flex
          alignItems="flex-end"
          fullWidth
          justifyContent="space-between"
          style={{ alignSelf: "flex-end" }}
        >
          <Button
            className={Classes.FIXED}
            disabled={configuring}
            icon="delete"
            intent={Intent.DANGER}
            text="Clear"
          />
          <Button
            className={Classes.FIXED}
            intent={Intent.PRIMARY}
            loading={configuring}
            onClick={onConfigure}
            icon="build"
            text={isImport ? "Configure" : "Create"}
          />
        </Flex>
      </StyledForm>
    </StyledConfigurationPanel>
  );
};

// TODO: type state and remove the any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => {
  return {
    configuring: state.create.metaData.building.isBuilding,
  };
};

export default connect(mapStateToProps)(ModelTimeConfig);
