import React, { useContext, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components/macro";
import colors from "styles/colors.module.scss";
import Explorer, { IExplorerProps } from "components/model/Explorer";
import EventHistory from "components/model/EventHistory";
import Search from "components/model/Search";
import ModelModuleTree from "components/model/ModelModuleTree";
import { CONTROL_BAR_HEIGHT, SIDE_MENU_WIDTH, Themes } from "constants/uiConstants";
import { EDashboardType } from "types/dashboard";
import { ISize } from "types";
import { noop } from "utils/generic";
import AddOrEditImportModule, {
  IAddOrEditImportModuleProps,
} from "components/import/AddOrEditImportModule";
import AppContext from "context/appContext";
import { WidgetKeys } from "constants/widgetKeys";

export type TSideMenuKeys = "TREE" | "EVENT_HISTORY" | "SEARCH" | "IMPORT_MODULE_EDITOR";

const StyledSideMenu = styled.div<{ appSize: ISize }>`
  flex-shrink: 0;
  height: ${({ appSize }) =>
    appSize?.height
      ? css`
          ${appSize.height - CONTROL_BAR_HEIGHT}px
        `
      : undefined};
  min-height: 300px;
  width: ${SIDE_MENU_WIDTH}px;
  background-color: ${({ theme }) =>
    theme === Themes.DARK ? colors.darkGray4 : colors.lightGray5};
  border-right: ${({ theme }) =>
    theme === Themes.DARK ? "1px solid " + colors.darkGray2 : "1px solid " + colors.lightGray2};
`;

export const SideMenuContentOptions: { [EDashboardType: string]: TSideMenuKeys[] } = {
  [EDashboardType.Edit]: [WidgetKeys.TREE, WidgetKeys.SEARCH, WidgetKeys.EVENT_HISTORY],
  [EDashboardType.Import]: [WidgetKeys.TREE, WidgetKeys.IMPORT_MODULE_EDITOR],
};

export interface IModelContextProps {
  modelLocked: boolean;
  onCreateModule: () => void;
  onDeleteModule: () => void;
}

interface ISideMenuProps {
  activeMenu: number;
  appSize: ISize;
  context: EDashboardType;
  importContextProps?: IAddOrEditImportModuleProps;
  modelContextProps?: IModelContextProps;
  treeProps?: Pick<
    IExplorerProps,
    | "currentModule"
    | "diagramOpen"
    | "expandedNodes"
    | "lineItemNavigationActive"
    | "lineItems"
    | "loading"
    | "modules"
    | "onNodeClick"
    | "setExpandedNodes"
    | "setShowLineItems"
    | "setShowUntaggedLineItems"
    | "showLineItems"
    | "showUntaggedLineItems"
    | "toggleDiagramOpen"
    | "updateModuleName"
  >;
}

const SideMenu: React.FC<ISideMenuProps> = ({
  activeMenu,
  appSize,
  context,
  modelContextProps = {
    modelLocked: false,
    onCreateModule: noop,
    onDeleteModule: noop,
  },
  importContextProps,
  treeProps,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const [content, setContent] = useState(SideMenuContentOptions[context]?.[activeMenu - 1]);

  useEffect(() => {
    let content: TSideMenuKeys | undefined = undefined;
    if (activeMenu > 0) {
      content = SideMenuContentOptions[context][activeMenu - 1];
    }
    if (content) setContent(content);
  }, [activeMenu, context]);

  const component = useMemo(() => {
    const KeyToComponentMap = {
      [WidgetKeys.TREE]:
        treeProps && context === EDashboardType.Edit ? (
          <ModelModuleTree
            expandedNodes={treeProps.expandedNodes}
            modelContextProps={modelContextProps}
            setExpandedNodes={treeProps.setExpandedNodes}
            setShowLineItems={treeProps.setShowLineItems}
            setShowUntaggedLineItems={treeProps.setShowUntaggedLineItems}
            showLineItems={treeProps.showLineItems}
            showUntaggedLineItems={treeProps.showUntaggedLineItems}
            updateModuleName={treeProps?.updateModuleName}
          />
        ) : treeProps?.currentModule ? (
          <Explorer modelContextProps={modelContextProps} {...treeProps} />
        ) : null,
      [WidgetKeys.EVENT_HISTORY]: <EventHistory />,
      [WidgetKeys.SEARCH]: <Search />,
      [WidgetKeys.IMPORT_MODULE_EDITOR]: importContextProps ? (
        <AddOrEditImportModule {...importContextProps} />
      ) : null,
    };
    if (content) {
      return KeyToComponentMap?.[content];
    }
  }, [content, context, importContextProps, modelContextProps, treeProps]);

  return component ? (
    <StyledSideMenu appSize={appSize} theme={theme}>
      {component}
    </StyledSideMenu>
  ) : null;
};

export default SideMenu;
