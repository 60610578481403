import React, { ChangeEvent, useState, forwardRef } from "react";
import { FileInput, FormGroup, Icon, Intent, Tooltip } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Flex } from "components/utility/StyledComponents";
import styled from "styled-components";

interface FileUploadProps {
  title: string;
  infoIconText?: string;
  onFilesAdded: (files: File[]) => void;
  showFirstFile?: boolean;
}

const StyledTooltip = styled(Tooltip)`
  &&& {
    margin: 0 5px;
  }
`;

export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  ({ title, infoIconText, onFilesAdded, showFirstFile = true }, ref) => {
    const [fileName, setFileName] = useState<string | null>(null);

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files ? Array.from(event.target.files) : [];
      onFilesAdded(files);
      if (showFirstFile && files.length > 0) {
        setFileName(files[0].name);
      }
    };

    return (
      <FormGroup
        label={
          <Flex justifyContent="flex-start" alignContent="center">
            {title}
            {infoIconText ? (
              <StyledTooltip content={infoIconText}>
                <Icon icon={IconNames.INFO_SIGN} intent={Intent.PRIMARY} />
              </StyledTooltip>
            ) : null}
          </Flex>
        }
      >
        <FileInput
          id="csvFile"
          text="Choose file..."
          onInputChange={handleFileUpload}
          inputProps={{ accept: ".csv", ref }}
        />
        {showFirstFile && fileName && (
          <Flex style={{ marginTop: 5 }} justifyContent="flex-start" alignContent="center">
            {fileName}
            <Icon
              icon={IconNames.TICK_CIRCLE}
              intent={Intent.SUCCESS}
              size={12}
              style={{ marginLeft: 5 }}
            />
          </Flex>
        )}
      </FormGroup>
    );
  }
);

FileUpload.displayName = "FileUpload";
