import { useCallback, useEffect, useState } from "react";
import { Intent } from "@blueprintjs/core";

import { noop } from "utils/generic";
import { multiToaster } from "utils/toaster";
import { DELETE_MODEL_ROUTE, EMethods, errorMessages, Status } from "constants/apiConstants";
import useUser from "hooks/useUser";
import { useApiCallback } from "hooks/useApiCallback";
import usePrevious from "hooks/usePrevious";
import useSuccessFailToast from "hooks/useSuccessFailToast";
import { IModelHistoryResponse, TModelHistoryMutator } from "hooks/useModelHistory2";
import { IResponse } from "types";
import { IExtendedModelInfo } from "types/model";
import isFunction from "lodash.isfunction";

type IModelInfoWithPage = IExtendedModelInfo & Pick<{ page: number }, "page">;

// eslint-disable-next-line @typescript-eslint/ban-types
type TDeleteModelsCallback = (models: IModelInfoWithPage[], onSuccess: Function) => void;

type TUseDeleteModels = (config: {
  mutate?: TModelHistoryMutator; // Bound swr mutate for the model history page being edited
  data?: IModelHistoryResponse; //
}) => {
  deleteModels?: TDeleteModelsCallback;
  deleting: boolean;
  response: IResponse<null> | null;
};

/**
 * Hook to give a component access to delete model functionality
 * */
const useDeleteModels: TUseDeleteModels = ({ mutate, data } = {}) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [onSuccess, setOnSuccess] = useState<Function>();

  const { callback, fetching, response } = useApiCallback<null>();
  const { data: userData, mutate: mutateUser } = useUser();

  const previousResponse = usePrevious(response);

  const deleteModelsCallback = useCallback<TDeleteModelsCallback>(
    (models, onSuccess = noop) => {
      if (callback) {
        setOnSuccess(() => onSuccess);
        const modelIDs = models.map((m) => m.id);
        const body = { models: modelIDs };
        callback(DELETE_MODEL_ROUTE, { method: EMethods.DELETE, body });
        if (mutate && data) {
          const nextModels = data.data.models.filter(
            (model) => !modelIDs.find((id) => id === model.model.id)
          );
          mutate({
            ...data,
            data: {
              ...data.data,
              models: nextModels,
              totalModels: data.data.totalModels - models.length,
            },
          });
          if (userData?.data.modelHistory) {
            const nextUserData = {
              ...userData,
              data: {
                ...userData.data,
                modelHistory: userData.data.modelHistory.filter((m) => {
                  return modelIDs.indexOf(m) === -1;
                }),
              },
            };
            mutateUser(nextUserData, false);
          }
        }
      }
    },
    [callback, data, mutate, mutateUser, userData]
  );

  useEffect(() => {
    if (response !== previousResponse) {
      // Ensure this hook fires only on the first response
      if (response?.status === Status.FAILURE) {
        multiToaster.show({
          message: response.message || errorMessages.SOMETHING_WENT_WRONG,
          intent: Intent.DANGER,
        });
        mutateUser();
      } else if (response?.status === Status.SUCCESS) {
        if (isFunction(onSuccess)) onSuccess();
      }
    }
  }, [mutateUser, onSuccess, previousResponse, response]);

  useSuccessFailToast(response);

  return { deleteModels: deleteModelsCallback, deleting: fetching, response };
};

export default useDeleteModels;
