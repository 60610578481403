import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Classes,
  Dialog,
  DialogFooter,
  DialogProps,
  Divider,
  H6,
  Intent,
  Tree,
  TreeNodeInfo,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import useListConfigs from "hooks/useListConfigs";
import { IResponse } from "types";
import useSWR from "swr";
import { Dashboard } from "types/dashboard";
import { swrApi } from "utils/api";
import {
  EStatus,
  FETCH_PORTFOLIOS_CONFIG_ROUTE,
  FETCH_WATCHLISTS_CONFIG_ROUTE,
} from "constants/apiConstants";
import LoadingIndicator from "components/utility/LoadingIndicator";
import {
  Flex,
  Grid,
  NonScrollableDialogBody,
  StyledLink,
} from "components/utility/StyledComponents";
import useView from "hooks/dashboard/useView";
import { multiToaster } from "utils/toaster";
import { Link } from "react-router-dom";
import AppContext from "context/appContext";
import { Themes } from "constants/uiConstants";
import useUserConfig from "hooks/useUserConfig";

interface CopyViewDialogProps extends DialogProps {
  onClose: () => void;
  viewId: string;
}

const CopyViewDialog: React.FC<CopyViewDialogProps> = ({ onClose, viewId, ...dialogProps }) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const [copying, setCopying] = useState(false);
  const [expandedNodes, setExpandedNodes] = useState<{ [key: string]: boolean }>({});
  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard>();
  const [copyToHome, setSendViewToHome] = useState(false);

  const { copyViewToDashboard, view } = useView(viewId);

  const {
    data: watchlistConfigs,
    isLoading: loadingWatchlistConfigs,
    error: watchlistConfigsError,
  } = useSWR<IResponse<{ name: string; id: string; dashboard: Dashboard }[]>>(
    [FETCH_WATCHLISTS_CONFIG_ROUTE],
    swrApi
  );

  const {
    data: portfolioConfigs,
    isLoading: loadingPortfolioConfigs,
    error: portfolioConfigsError,
  } = useSWR<IResponse<{ name: string; id: string; dashboard: Dashboard }[]>>(
    [FETCH_PORTFOLIOS_CONFIG_ROUTE],
    swrApi
  );

  const { home, isLoading } = useUserConfig();

  const {
    dashboards,
    isLoading: loadingDashboards,
    error: generalDashboardsError,
  } = useListConfigs();

  const loading =
    loadingWatchlistConfigs || loadingDashboards || loadingPortfolioConfigs || isLoading;

  const handleCopyToHome = async () => {
    if (copyToHome && home?.id) {
      const response = await copyViewToDashboard(home.id);

      if (response.status === EStatus.success) {
        multiToaster.show({
          intent: Intent.SUCCESS,
          message: `Successfully copied ${view?.name} to ${home.name}`,
        });
      } else {
        multiToaster.show({
          intent: Intent.WARNING,
          message: `Unable to copy ${view?.name} to ${home.name}. Please try again.`,
        });
      }
    }
  };

  const handleCopyView = async () => {
    if (!selectedDashboard) return;
    setCopying(true);
    const response = await copyViewToDashboard(selectedDashboard.id);

    if (response.status === EStatus.success) {
      multiToaster.show({
        intent: Intent.SUCCESS,
        message: `Successfully copied ${view?.name} to ${selectedDashboard.name}`,
      });
    } else {
      multiToaster.show({
        intent: Intent.WARNING,
        message: `Unable to copy ${view?.name} to ${selectedDashboard.name}. Please try again.`,
      });
    }
    setCopying(false);
  };

  const tree: TreeNodeInfo<{ dashboard?: Dashboard }>[] = [
    {
      icon: IconNames.DASHBOARD,
      id: "generalDashboards",
      isExpanded: expandedNodes["generalDashboards"],
      label: "General Dashboards",
    },
    {
      icon: IconNames.EYE_ON,
      id: "watchlistDashboards",
      isExpanded: expandedNodes["watchlistDashboards"],
      label: "Watchlist Dashboards",
    },
    {
      icon: IconNames.MULTI_SELECT,
      id: "portfolioDashboards",
      isExpanded: expandedNodes["portfolioDashboards"],
      label: "Portfolio Dashboards",
    },
  ];

  if (dashboards && !generalDashboardsError) {
    const generalDashboards = dashboards.map((dashboard) => {
      return {
        id: dashboard.id,
        isExpanded: expandedNodes?.[dashboard.id],
        isSelected: selectedDashboard && selectedDashboard.id === dashboard.id,
        label: (
          <Flex justifyContent="space-between">
            {dashboard.name}
            <Link target="_blank" to={`/dashboards/${dashboard.id}`}>
              <Button icon={IconNames.DOCUMENT_OPEN} minimal />
            </Link>
          </Flex>
        ),
        nodeData: { dashboard },
      };
    });
    tree[0].childNodes = generalDashboards;
  }

  if (watchlistConfigs?.data && !watchlistConfigsError) {
    const watchlistDashboards = watchlistConfigs.data.map((watchlist) => {
      const dashboard = watchlist?.dashboard;
      return {
        id: dashboard.id,
        isExpanded: expandedNodes?.[dashboard.id],
        isSelected: selectedDashboard && selectedDashboard.id === dashboard.id,
        label: (
          <Flex justifyContent="space-between">
            {dashboard.name}
            <Link target="_blank" to={`/dashboards/${dashboard.id}`}>
              <Button icon={IconNames.DOCUMENT_OPEN} minimal />
            </Link>
          </Flex>
        ),
        nodeData: { dashboard },
      };
    });
    tree[1].childNodes = watchlistDashboards;
  }

  if (portfolioConfigs?.data && !portfolioConfigsError) {
    const portfolioDashboards = portfolioConfigs.data.map((portfolio) => {
      const dashboard = portfolio?.dashboard;
      return {
        id: dashboard.id,
        isExpanded: expandedNodes?.[dashboard.id],
        isSelected: selectedDashboard && selectedDashboard.id === dashboard.id,
        label: (
          <Flex justifyContent="space-between">
            {dashboard.name}
            <Link target="_blank" to={`/dashboards/${dashboard.id}`}>
              <Button icon={IconNames.DOCUMENT_OPEN} minimal />
            </Link>
          </Flex>
        ),
        nodeData: { dashboard },
      };
    });
    tree[2].childNodes = portfolioDashboards;
  }

  return (
    <Dialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      onClose={onClose}
      title="Copy View to Dashboard"
      {...dialogProps}
    >
      <NonScrollableDialogBody>
        {loading && (
          <LoadingIndicator status={{ intent: Intent.PRIMARY, message: "Loading Dashboards..." }} />
        )}
        <Grid fullHeight rows="auto auto auto auto 1fr">
          <Card>
            <p className={Classes.RUNNING_TEXT}>
              Copy View <strong>{view?.name}</strong> to Dashboard: <br />
              {selectedDashboard ? (
                <StyledLink to={`/dashboards/${selectedDashboard.id}`}>
                  <strong>{selectedDashboard.name}</strong>
                </StyledLink>
              ) : (
                <span className={Classes.TEXT_MUTED}>No Dashboard selected.</span>
              )}
            </p>
          </Card>
          <Divider style={{ margin: "10px 0" }} />
          <Checkbox
            checked={copyToHome}
            inline
            label="Copy to Home Dashboard"
            onChange={() => setSendViewToHome(!copyToHome)}
          />
          <H6>Browse Dashboards</H6>
          <div style={{ overflowY: "scroll" }}>
            {tree && (
              <Tree
                contents={tree}
                onNodeClick={(node) => {
                  if (node?.nodeData?.dashboard) {
                    setSelectedDashboard(node.nodeData.dashboard);
                  }
                }}
                onNodeCollapse={(node) => setExpandedNodes({ ...expandedNodes, [node.id]: false })}
                onNodeExpand={(node) => setExpandedNodes({ ...expandedNodes, [node.id]: true })}
              />
            )}
          </div>
        </Grid>
      </NonScrollableDialogBody>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose} text="Cancel" />
          <Button
            disabled={!selectedDashboard && !copyToHome}
            icon={IconNames.DUPLICATE}
            intent={Intent.PRIMARY}
            loading={copying}
            onClick={() => {
              handleCopyView();
              handleCopyToHome();
            }}
            text="Copy"
          />
        </div>
      </DialogFooter>
    </Dialog>
  );
};

export default CopyViewDialog;
