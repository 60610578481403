import React, { FormEvent, useRef, useState } from "react";
import styled from "styled-components/macro";
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Classes,
  ControlGroup,
  FormGroup,
  H4,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  Panel,
  PanelProps,
  Radio,
  RadioGroup,
  Tooltip,
} from "@blueprintjs/core";
import { ItemListRenderer, ItemRenderer, Select, Suggest } from "@blueprintjs/select";
import List, { ListRowRenderer } from "react-virtualized/dist/commonjs/List";
import StyledConfigurationPanel from "components/utility/StyledConfigurationPanel";
import { Flex } from "components/utility/StyledComponents";
import useAvailableCompanies from "hooks/useAvailableCompanies";
import { clamp } from "utils/generic";
import { IExchange, TCompanyInfo, TConfigureModelPanels } from "types/createModel";
import useModelConfigOptions from "hooks/useModelConfigOptions";
import { IConfigureModelContext, withConfigureModelContext } from "context/configureModelContext";
import ConfigureCompany from "./ConfigureCompany";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  width: 100%;
`;

const StyledCompanyItem = styled(MenuItem)`
  span {
    margin-right: 10px;
  }
`;

interface ISelectCompanyProps {
  isImport?: boolean;
  title?: string;
}

export type TSelectOrInitialiseCompany = PanelProps<ISelectCompanyProps & IConfigureModelContext>;

const SelectOrInitialiseCompany: React.FC<TSelectOrInitialiseCompany> = ({
  blankCompany,
  company,
  config,
  isImport = false,
  loadingConfigOpts,
  openPanel,
  setBlankCompany,
  setCompany,
  setConfig,
  title = "Select Company",
}) => {
  const suggestRef = useRef<HTMLInputElement>(null);
  const [searchType, setSearchType] = useState("both");
  const { template } = config;
  const { availableCompanies: companies, fetching: fetchingCompanies } = useAvailableCompanies(
    template?.uid
  );

  const { exchanges } = useModelConfigOptions();

  const inputValueRenderer = (company: TCompanyInfo) => {
    return `${company.companyName}: ${company.ticker}`;
  };

  const handleCompanySelect = (company: TCompanyInfo) => {
    setCompany(company);
  };

  const filterItems = (query: string, item: TCompanyInfo) => {
    switch (searchType) {
      case "name":
        return item.companyName.toLowerCase().indexOf(query.toLowerCase()) >= 0;
      case "ticker":
        return item.ticker.toLowerCase().indexOf(query.toLowerCase()) >= 0;
      default:
        return (
          item.ticker.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
          item.companyName.toLowerCase().indexOf(query.toLowerCase()) >= 0
        );
    }
  };

  const itemListRenderer: ItemListRenderer<TCompanyInfo> = ({ activeItem, filteredItems }) => {
    const activeIndex = filteredItems.findIndex((i) => i === activeItem);
    const rowRenderer: ListRowRenderer = ({ index, key, style }) => {
      const item = filteredItems[index];
      if (item) {
        if (searchType === "name" || searchType === "both") {
          return (
            <StyledCompanyItem
              active={item === activeItem}
              key={key}
              label={item.ticker}
              onClick={() => handleCompanySelect(item)}
              style={style}
              text={item.companyName}
            />
          );
        } else {
          return (
            <StyledCompanyItem
              active={item === activeItem}
              key={key}
              label={item.companyName}
              onClick={() => handleCompanySelect(item)}
              style={style}
              text={item.ticker}
            />
          );
        }
      }
    };
    if (filteredItems.length === 0) {
      return (
        <Menu>
          <StyledCompanyItem disabled={true} active={false} text="No results!" />
        </Menu>
      );
    }
    return (
      <Menu>
        <List
          height={clamp(filteredItems.length * 30, 0, 300)}
          rowCount={filteredItems.length}
          rowHeight={30}
          rowRenderer={rowRenderer}
          scrollToIndex={activeIndex}
          width={300}
        />
      </Menu>
    );
  };

  /** EXCHANGE SELECTOR */
  const [exchangeQuery, setExchangeQuery] = useState("");

  const renderExchanges: ItemRenderer<IExchange> = (exchange, { modifiers, handleClick }) => {
    const text = (
      <Flex justifyContent="space-between">
        <span>{exchange.Description}</span>
        <span className={Classes.TEXT_MUTED}>{exchange["Google Prefix"]}</span>
      </Flex>
    );
    return (
      <MenuItem
        active={modifiers.active}
        key={exchange["ISO MIC"]}
        onClick={handleClick}
        text={text}
      />
    );
  };

  const handleExchangeSelect = (exchange: IExchange) => {
    setConfig({ ...config, exchange });
  };

  const checkExchangeQuery = (query: string, exchange: IExchange) => {
    return (
      exchange["Google Prefix"].toLowerCase().includes(query.toLowerCase()) ||
      exchange["Description"].toLowerCase().includes(query.toLowerCase())
    );
  };

  const canAdvance =
    (!!(company && !config.blank) ||
      !!(config.companyType === "Private" && blankCompany?.companyName && config.blank) ||
      !!(
        config.companyType === "Public" &&
        config.blank &&
        blankCompany?.companyName &&
        blankCompany?.ticker
      )) &&
    (isImport || (!loadingConfigOpts && config?.newestReportedYear));

  const nextPanel = () => {
    if (config.blank) {
      setConfig({ ...config, ...blankCompany });
    } else if (company) {
      setConfig({
        ...config,
        companyName: company.companyName,
        ticker: company.ticker,
        industry: company.industry,
        geography: company.geography,
      });
    }
    openPanel({
      props: {
        title: isImport ? "Import: Configure Company" : "Configure Company",
        isImport,
      },
      // TODO: Why the heck is this assertion needed? What is wrong with TConfigureModelPanels
      renderPanel: withConfigureModelContext(ConfigureCompany as React.FC<TConfigureModelPanels>),
      title: isImport ? "Import: Configure Company" : "Configure Company",
      // TODO: Seems to be a bug with Blueprint types, will likely be resolved in v4.
      // eslint-disable-next-line @typescript-eslint/ban-types
    } as Panel<object>);
  };

  const handleSearchTypeChange = (e: FormEvent<HTMLInputElement>) => {
    setSearchType(e.currentTarget.value);
  };

  const setBlank = (blank: boolean) => {
    setConfig({ ...config, blank });
  };

  return (
    <StyledConfigurationPanel>
      <H4>{title}</H4>
      <Flex flex="1 1 0" flexDirection="column" fullWidth justifyContent="flex-start">
        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div>
            <FormGroup label="Company type" helperText="Whether the company is public or private.">
              <ButtonGroup>
                <Button
                  active={config.companyType === "Public"}
                  onClick={() => setConfig({ ...config, companyType: "Public" })}
                  text="Public"
                />
                <Button
                  active={config.companyType === "Private"}
                  text="Private"
                  onClick={() => setConfig({ ...config, blank: true, companyType: "Private" })}
                />
              </ButtonGroup>
            </FormGroup>
            <FormGroup label="Configuration type:">
              <ButtonGroup>
                <Tooltip
                  content={
                    config.companyType === "Public"
                      ? "Select a company from the list of companies known by Valsys."
                      : "Company selection is only available for public companies!"
                  }
                  intent={config.companyType === "Public" ? undefined : Intent.WARNING}
                >
                  <AnchorButton
                    active={!config.blank}
                    disabled={config.companyType === "Private"}
                    onClick={() => setBlank(false)}
                    text="Select from list"
                  />
                </Tooltip>
                <Tooltip content="Initialise a new manually configured company.">
                  <Button
                    active={config.blank}
                    onClick={() => setBlank(true)}
                    text="Manual entry"
                  />
                </Tooltip>
              </ButtonGroup>
            </FormGroup>
            {!config.blank ? (
              <>
                <FormGroup label="Company:">
                  <ControlGroup fill>
                    {!fetchingCompanies ? (
                      <Suggest<TCompanyInfo>
                        className="suggest-component"
                        fill
                        inputValueRenderer={inputValueRenderer}
                        inputProps={{ id: "ticker", autoComplete: "off", inputRef: suggestRef }}
                        itemListRenderer={itemListRenderer}
                        items={companies}
                        itemsEqual="ticker"
                        itemPredicate={filterItems}
                        noResults={<MenuItem disabled text="No results" />}
                        onItemSelect={handleCompanySelect}
                        popoverProps={{ matchTargetWidth: true, minimal: true }}
                        resetOnClose
                        selectedItem={company}
                      />
                    ) : (
                      <Button loading={true} />
                    )}
                    <Button
                      className={Classes.FIXED}
                      disabled={!company}
                      text="Clear"
                      loading={!companies}
                      onClick={() => {
                        setCompany(undefined);
                        if (suggestRef.current) suggestRef.current.focus();
                      }}
                    />
                  </ControlGroup>
                </FormGroup>
                <FormGroup label="Search by:">
                  <RadioGroup inline onChange={handleSearchTypeChange} selectedValue={searchType}>
                    <Radio label="Name & ticker" value="both" inline />
                    <Radio label="Name" value="name" inline />
                    <Radio label="Ticker" value="ticker" inline />
                  </RadioGroup>
                </FormGroup>
              </>
            ) : (
              <>
                <FormGroup label="Company name:">
                  <InputGroup
                    value={blankCompany?.companyName}
                    onChange={(e) =>
                      setBlankCompany({ ...blankCompany, companyName: e.currentTarget.value })
                    }
                  />
                </FormGroup>
                {config.companyType === "Public" ? (
                  <FormGroup label="Ticker:">
                    <InputGroup
                      value={blankCompany?.ticker}
                      onChange={(e) =>
                        setBlankCompany({ ...blankCompany, ticker: e.currentTarget.value })
                      }
                    />
                  </FormGroup>
                ) : null}
              </>
            )}
            {config.companyType === "Public" ? (
              <FormGroup
                disabled={!exchanges.loading && exchanges?.data.length === 0}
                label="Exchange:"
                helperText={
                  !exchanges.loading && exchanges?.data.length === 0
                    ? "No exchange options available."
                    : undefined
                }
              >
                <Select
                  disabled={!exchanges.loading && exchanges?.data.length === 0}
                  onItemSelect={handleExchangeSelect}
                  itemRenderer={renderExchanges}
                  itemsEqual="ISO MIC"
                  itemPredicate={checkExchangeQuery}
                  items={exchanges?.data || []}
                  query={exchangeQuery}
                  onQueryChange={setExchangeQuery}
                >
                  <Button
                    disabled={!exchanges.loading && exchanges?.data.length === 0}
                    loading={exchanges.loading}
                    rightIcon="caret-down"
                    text={
                      config?.exchange ? (
                        typeof config?.exchange !== "string" ? (
                          <span>
                            {config?.exchange?.Description}{" "}
                            <span className={Classes.TEXT_MUTED}>
                              {config?.exchange?.["Google Prefix"]}
                            </span>
                          </span>
                        ) : (
                          config?.exchange
                        )
                      ) : (
                        "Select exchange..."
                      )
                    }
                  />
                </Select>
              </FormGroup>
            ) : null}
          </div>
          <Flex
            alignItems="flex-end"
            justifyContent="space-between"
            style={{ alignSelf: "flex-end" }}
            fullWidth
          >
            <Button className={Classes.FIXED} icon="delete" intent={Intent.DANGER} text="Clear" />
            <Button
              className={Classes.FIXED}
              disabled={!canAdvance}
              intent={Intent.PRIMARY}
              onClick={nextPanel}
              rightIcon="circle-arrow-right"
              text="Next"
              type="submit"
            />
          </Flex>
        </StyledForm>
      </Flex>
    </StyledConfigurationPanel>
  );
};

export default SelectOrInitialiseCompany;
