import { createSelector } from "reselect";
import { lineItemsSelector } from "selectors/modelSelectors";

export const tagLineItemsMapSelector = createSelector([lineItemsSelector], (lineItems) => {
  if (!lineItems) return {};
  const map: Record<string, string[]> = {};
  Object.keys(lineItems).forEach((liUid: string) => {
    lineItems[liUid].tags?.forEach((tag: string) => {
      const tagItems = map[tag];
      map[tag] = tagItems ? [...tagItems, liUid] : [liUid];
    });
  });
  return map;
});
