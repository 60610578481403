import * as consts from "constants/eventHistoryConstants";

/**
 * Add an event tag filter
 *
 * @param {string} tag - The tag filter to add
 * */
export function addEventTagFilter(tag) {
  return {
    type: consts.ADD_EVENT_TAG_FILTER,
    payload: {
      tag,
    },
  };
}

/**
 * Remove an event tag filter
 *
 * @param {string} tag - The tag filter to remove
 * */
export function removeEventTagFilter(tag) {
  return {
    type: consts.REMOVE_EVENT_TAG_FILTER,
    payload: {
      tag,
    },
  };
}

/**
 * Clear all active tag filters
 * */
export function clearEventTagFilters() {
  return {
    type: consts.CLEAR_EVENT_TAG_FILTERS,
  };
}

/**
 * Reset the reducer to the initial state.
 * */
export function flushHistoryReducer() {
  return { type: consts.FLUSH_EVENT_HISTORY_REDUCER };
}
