import ChartRenderer from "components/dashboard/renderers/ChartRenderer";
import { StyledWidgetScrollContainer } from "components/utility/StyledDashboardComponents";
import { YAxisId } from "constants/chartConstants";
import AppContext from "context/appContext";
import HighchartsReact from "highcharts-react-official";
import React, { RefObject, useContext, useState } from "react";
import { ViewWidget } from "types/view";
import { ChartWidgetType, VSLChart, VSLResponse } from "types/vsl";
import { ChartWidgetConfig, Widget as TWidget } from "types/widget";
import ChartBottomControlRibbon from "components/dashboard/widget/chart/ChartBottomControlRibbon";
import ChartTopControlRibbon from "components/dashboard/widget/chart/ChartTopControlRibbon";

interface ChartWidgetProps {
  updateWidget?: (details: Partial<ViewWidget>) => Promise<boolean>;
  updateWidgetConfig?: (details: Partial<ChartWidgetConfig>) => Promise<boolean>;
  showControlRibbon?: boolean;
  locked?: boolean;
  widget: TWidget;
  config?: ChartWidgetConfig;
  /* eslint-disable-next-line "@typescript-eslint/no-explicit-any" */
  error: any;
  loading: boolean;
  data: VSLResponse;
  chartComponentRef: RefObject<HighchartsReact.RefObject>;
}

/**
 * Chart Widget - widgets that display charts (bar charts, line chart, etc)
 */
const ChartWidget: React.FC<ChartWidgetProps> = ({
  locked = false,
  showControlRibbon = true,
  updateWidget,
  updateWidgetConfig,
  widget,
  config,
  error,
  loading,
  data,
  chartComponentRef,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const [currentChartState, setCurrentChartState] = useState<Highcharts.Chart>();

  const xAxisMin = currentChartState?.xAxis?.[0]?.min;
  const xAxisMax = currentChartState?.xAxis?.[0]?.max;

  const showContent = data.data && !error && widget.id;

  return (
    <>
      {data && updateWidgetConfig ? (
        <ChartTopControlRibbon
          chartData={data.data as VSLChart}
          config={config}
          updateConfig={updateWidgetConfig}
          widgetType={data.widgetType as ChartWidgetType}
        />
      ) : null}
      <StyledWidgetScrollContainer
        $extraPadding={!!(loading || error || !widget?.query)}
        $theme={theme}
      >
        {showContent ? (
          <ChartRenderer
            chartComponentRef={chartComponentRef}
            config={config}
            loading={loading}
            data={data.data as unknown as VSLChart<string>}
            type={data.widgetType as ChartWidgetType}
            setCurrentChartState={setCurrentChartState}
            updateConfig={updateWidgetConfig}
          />
        ) : null}
      </StyledWidgetScrollContainer>
      {showControlRibbon && showContent && updateWidget ? (
        <ChartBottomControlRibbon
          chartRendererConfig={config}
          data={data.data as VSLChart}
          defaultYAxisBounds={{
            [YAxisId.RIGHT]: {
              min: currentChartState?.yAxis?.[0]?.min,
              max: currentChartState?.yAxis?.[0]?.max,
            },
            [YAxisId.LEFT]: {
              min: currentChartState?.yAxis?.[1]?.min,
              max: currentChartState?.yAxis?.[1]?.max,
            },
          }}
          locked={locked}
          type={data.widgetType as ChartWidgetType}
          updateWidget={updateWidget}
          updateWidgetConfig={
            updateWidgetConfig as (details: Partial<ChartWidgetConfig>) => Promise<boolean>
          }
          widget={widget}
          xAxisChartState={{
            min: xAxisMin,
            max: xAxisMax,
          }}
        />
      ) : null}
    </>
  );
};

ChartWidget.displayName = "ChartWidget";

export default ChartWidget;
