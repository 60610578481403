import { createSelector } from "reselect";

export const eventSelector = (state) => state.eventHistory.events;

/**
 * Selector to determine which state changes are available to a given model.
 * */
export const availableStateChangeSelector = createSelector([eventSelector], (events) => {
  if (!events || events.length < 1) return { undo: false, redo: false };
  const undoable = events.some((event) => event.state === "UNDO");
  const redoable = events.some((event) => event.state === "REDO");
  return { undo: undoable, redo: redoable };
});
