import colors from "styles/colors.module.scss";
import { CellStyles } from "@vsjs/react-data-grid";
import { NavigateFunction } from "react-router-dom";
import range from "lodash.range";
import { schemaLevel, vsNormalize } from "utils/normalizr";
import { deepMerge } from "utils/data";
import { convertFormattedNumericFact, f, periodFormatter } from "utils/formatter";
import { CSSProperties } from "react";
import { IFocusedCell, IRowsSelectorConfig } from "types/dataEntry";
import { IRowHeader, ITableCoordinates, ITableSelection, TCardinality } from "types/vsTable";
import { IFact, ILineItem, IModule, IRawModule, isLineItem, isModule, IVSRow } from "types/model";
import {
  IImportFact,
  INormalizedImportFact,
  TNormalizedImportLineItem,
  TNormalizedImportModule,
} from "types/import";
import { shiftFormula } from "./formulas";
import { vsNavigate } from "./generic";
import {
  DATAENTRY_DEFAULT_MIN_COL_WIDTH,
  DATAENTRY_DEFAULT_ROW_HEADER_WIDTH,
} from "constants/uiConstants";
import { EValFormat, IFormatObject } from "types/format";

/**
 * Given a fact, returns a css properties object configured to that cell.
 */
export function buildCellStyleObject(
  format: IFormatObject,
  error: string,
  skipError: boolean
): CSSProperties {
  const { backgroundColor } = format;
  let { textAlign } = format;
  const { color, fontStyle, fontWeight, valFormat, textDecoration } = format;
  /* If we are in an errored state, then set the color to red. Otherwise, check
   **/
  if (error && !skipError) {
    return {
      fontWeight: "bold",
      color: colors.red1,
      fontStyle: "italic",
    };
  }

  textAlign = valFormat === EValFormat.PLAINTEXT ? "left" : "right";

  return {
    color,
    backgroundColor,
    fontStyle,
    fontWeight,
    textAlign,
    textDecoration,
  };
}

/** convert "default" to the default value for that prop */
export function expandDefaults(
  defaults: Record<string, string | number>,
  format: IFormatObject
): Record<string, string | number> {
  const expanded: Record<string, string | number> = {};

  for (const [key, value] of Object.entries(format)) {
    if (value === "default" && key in defaults) {
      expanded[key] = defaults[key];
    } else {
      expanded[key] = value;
    }
  }

  return expanded;
}

export const defaultLineItemName = "New line item";
/** Gets the next default line item name based on the current row headers */
export function getDefaultName(rowHeaders: IRowHeader[], increment: number): string {
  if (!rowHeaders) return defaultLineItemName + " 1";
  const defaultIndices = rowHeaders
    .filter((header) => header.name.includes(defaultLineItemName))
    .map((header) => Number(header.name.split("item")[1]))
    .filter((idx) => !isNaN(idx));
  if (defaultIndices.length === 0) return defaultLineItemName + " 1";
  const maxDefaultIndex = Math.max(...defaultIndices);
  return defaultLineItemName + " " + (maxDefaultIndex + increment);
}

/**
 * Given a fact and the appropriate default formatting, returns the appropriate
 * cell formatting object.
 * */
export function buildCellFormattingObject(
  { currency, decimalPlaces, valFormat, unit }: IFormatObject,
  defaultFormats: IFormatObject
) {
  return {
    ...defaultFormats,
    currency,
    decimalPlaces,
    valFormat,
    unit,
  };
}

/**
 * Utility function to check if a value is equal to the string 'default'.
 * Also returns true if no test is supplied.
 */
export function isDefault(test: string): boolean {
  return test === "default" || !test;
}

/**
 * Update list item index when two item are swapped. For a straight reorder without swapping, see
 * 'reorder' in utils/generic.js
 */
export function swapReorder(index: number, first: number, last: number) {
  if (index === first) return last;
  if (first < last) {
    if (index > first && index <= last) {
      return index - 1;
    }
  }
  if (first > last) {
    if (index >= last && index < first) {
      return index + 1;
    }
  }
  return index;
}

/**
 * Test if selection matches a single cell
 */
export function isSelectionCell(cell: IFocusedCell<IFact>, selection: ITableSelection) {
  if (!selection.rows || !selection.cols) return false;
  return (
    cell.row === selection.rows[0] &&
    cell.col === selection.cols[0] &&
    selection.rows[0] === selection.rows[1] &&
    selection.cols[0] === selection.cols[1]
  );
}

/**
 * Test if selection includes just one cell
 */
export function isSingleCellSelection(selection: ITableSelection) {
  return selection.rows[0] === selection.rows[1] && selection.cols[0] === selection.cols[1];
}

/**
 * Extends an existing selection
 * @param {Region} selection - original selection
 * @param {Object} originCell - cell where the selection started
 * @param {number} rowIncrement - number of rows we want to add/remove to current selection
 * @param {number} colIncrement - number of cols we want to add/remove to current selection
 * @param {number} maxRowIndex
 * @param {number} maxColIndex
 * @returns {Object} selection
 */
export function extendSelection(
  selection: ITableSelection[],
  originCell: ITableCoordinates,
  rowIncrement: number,
  colIncrement: number,
  maxRowIndex: number,
  maxColIndex: number
) {
  const sel = selection[0];
  const newSel = { cols: [...sel.cols], rows: [...sel.rows] };

  // case first selection
  if (sel.rows[0] === sel.rows[1] && sel.rows[0] === sel.rows[1]) {
    if (rowIncrement > 0) newSel.rows[1] += rowIncrement;
    else newSel.rows[0] += rowIncrement;
  }
  if (sel.cols[0] === sel.cols[1] && sel.cols[0] === sel.cols[1]) {
    if (colIncrement > 0) newSel.cols[1] += colIncrement;
    else newSel.cols[0] += colIncrement;
  }

  // ongoing selection case
  if (sel.rows[0] < originCell.row) newSel.rows[0] += rowIncrement;
  if (sel.rows[1] > originCell.row) newSel.rows[1] += rowIncrement;
  if (sel.cols[0] < originCell.col) newSel.cols[0] += colIncrement;
  if (sel.cols[1] > originCell.col) newSel.cols[1] += colIncrement;

  // limit edges
  if (newSel.rows[0] < 0) newSel.rows[0] = 0;
  if (newSel.rows[1] > maxRowIndex) newSel.rows[1] = maxRowIndex;
  if (newSel.cols[0] < 0) newSel.cols[0] = 0;
  if (newSel.cols[1] > maxColIndex) newSel.cols[1] = maxColIndex;

  return [newSel];
}

/**
 * Takes in a cell object and returns a selection object for the same cell
 * @param {object} cell
 * @returns {Array} selection
 */
export function selectionFromCell(cell: IFocusedCell<IFact>) {
  return [cellsToRegion(cell.row, cell.col)];
}

/**
 * Checks if two Cells are equal
 */
export function equalCells(cell1: IFocusedCell<IFact>, cell2: IFocusedCell<IFact>) {
  if (!cell1 || !cell2) return false;
  return cell1.row === cell2.row && cell1.col === cell2.col;
}

/**
 * Checks if two Regions are equal
 */
export function equalRegions(reg1: ITableSelection[], reg2: ITableSelection[]) {
  if (!reg1 || !reg2 || !reg1.length || !reg2.length) return false;
  const sel1 = reg1[0];
  const sel2 = reg2[0];
  if (!sel1 || !sel2) return false;

  let equalRows = false;
  if (!sel1.rows && !sel2.rows) equalRows = true;
  else if (sel1.rows[0] === sel2.rows[0] && sel1.rows[1] === sel2.rows[1]) equalRows = true;

  let equalCols = false;
  if (!sel1.cols && !sel2.cols) equalCols = true;
  else if (sel1.cols[0] === sel2.cols[0] && sel1.cols[1] === sel2.cols[1]) equalCols = true;

  return equalRows && equalCols;
}

/**
 * Checks if a region matches a single cell
 */
export function regionEqualsCell(
  region: ITableSelection[],
  cell: IFocusedCell<IFact | IImportFact>
) {
  if (!region || !cell || !region.length || !region[0].rows || !region[0].cols) return false;
  const regRow1 = region[0].rows[0];
  const regRow2 = region[0].rows[1];
  let regRow;
  if (regRow1 !== regRow2) return false;
  else regRow = regRow1;

  const regCol1 = region[0].cols[0];
  const regCol2 = region[0].cols[1];
  let regCol;
  if (regCol1 !== regCol2) return false;
  else regCol = regCol1;

  return regRow === cell.row && regCol === cell.col;
}

/**
 * Build data entry table. Logic moved out of selectors so multiple
 * selectors can build table structures.
 * */
export const buildRows = <F>(
  currentModule: F extends IFact ? IModule : TNormalizedImportModule,
  lineItems: Record<string, F extends IFact ? ILineItem : TNormalizedImportLineItem>,
  facts: Record<string, F extends IFact ? IFact : INormalizedImportFact>,
  config: IRowsSelectorConfig
): IVSRow<F extends IFact ? IFact : INormalizedImportFact>[] => {
  const moduleLineItems = currentModule?.lineItems
    ?.map((li) => lineItems[li])
    .sort((a, b) => a.order - b.order);

  // empty module
  if (!moduleLineItems?.some((li) => li)) return [];

  const rows: IVSRow<F extends IFact ? IFact : INormalizedImportFact>[] = [];

  moduleLineItems.forEach((li, i) => {
    const row: IVSRow<F extends IFact ? IFact : INormalizedImportFact> = {
      uid: li.uid,
      rowHeader: li.name,
      format: isLineItem(li) ? li.format : "",
    };
    if (li.facts) {
      const liFacts = li.facts.map((fact) => facts[fact]).sort((a, b) => a.period - b.period);
      liFacts.forEach((fact) => {
        const j = (fact.period - currentModule.moduleStart) * (1 / currentModule.forecastIncrement);
        row[j] = { ...fact };
        const colLabel = periodFormatter(
          fact.period,
          isModule(currentModule) ? currentModule.startPeriod : null,
          currentModule.forecastIncrement
        );
        // FACT FORMATTING
        const { showErrors, rawValues } = config;
        if (fact.value === "-") row[colLabel] = "-";
        else if (fact.error) row[colLabel] = showErrors ? "#ERROR" : "";
        else row[colLabel] = rawValues ? fact.value : f(fact, "en-US");
      });
    }
    rows[i] = row;
  });

  // TODO: validateData({ ...structure, styledRegions: [] });

  return rows;
};

/** Get the cell coordinates object for a specific fact */
export function cellPositionFromFact(period: number, parentOrder: number, moduleStart: number) {
  return { row: parentOrder - 1, col: period - moduleStart };
}

/**
 * Pull a selected region based slice from an array of row or column headers.
 * */
export function selectedRegionToHeaderSlice(
  selectedRegions: ITableSelection[],
  headers: IRowHeader[],
  cardinality: TCardinality
) {
  const regionCardinality = cardinality || getRegionCardinality(selectedRegions[0]);
  if (regionCardinality === RegionCardinality.FULL_COLUMNS) {
    return headers.slice(selectedRegions[0].cols[0], selectedRegions[0].cols[1] + 1);
  } else if (regionCardinality === RegionCardinality.FULL_ROWS) {
    return headers.slice(selectedRegions[0].rows[0], selectedRegions[0].rows[1] + 1);
  }
}

/**
 * Truncates a table header.
 *
 * @param {string} header - The header to format
 * */
export function formatTableHeader(header: string) {
  return header && header.length > 40 ? `${header.slice(0, 34)} ...` : header;
}

/**
 * Mutates cellMap to include provided styles to the chosen cell
 * @param rowIdx
 * @param colIdx
 * @param cellMap
 * @param classes
 */
export const addCustomClassCell = (
  rowIdx: number,
  colIdx: number,
  cellMap: CellStyles[][],
  classes: string
) => {
  const row = cellMap[rowIdx] ? [...cellMap[rowIdx]] : [];
  const cell = row[colIdx + 1]
    ? { ...row[colIdx + 1] }
    : { style: {}, classes: "", loading: false };
  if (cell?.classes) {
    cell.classes += " " + classes;
  } else {
    cell.classes = classes;
  }
  cellMap[rowIdx] = row;
  cellMap[rowIdx][colIdx + 1] = cell;
};

/* LINE ITEM OPERATIONS*/

export const removeTempLi = (
  facts: Record<string, IFact>,
  lineItems: Record<string, ILineItem>,
  modules: Record<string, IModule>,
  tempLi: ILineItem
) => {
  const mod = modules[tempLi.parentUid];
  if (!mod?.lineItems || !mod.lineItemsNames) return;

  const modLiIdx = mod.lineItems.findIndex((item) => item === tempLi.uid);
  if (modLiIdx > -1) mod.lineItems.splice(modLiIdx, 1);
  const modLiNamesIdx = mod.lineItemsNames.findIndex((item) => item === tempLi.name);
  if (modLiNamesIdx > -1) mod.lineItemsNames.splice(modLiNamesIdx, 1);

  // facts temp ids
  tempLi.facts.forEach((liFactUid) => delete facts[liFactUid]);
  delete lineItems[tempLi.uid];
};

/**
 * Delete a line item from a model.
 * */
export const safelyDeleteLineItem = (
  facts: Record<string, IFact>,
  lineItems: Record<string, ILineItem>,
  modules: Record<string, IModule>,
  moduleID: string,
  deletedItemUid: string
) => {
  const lineItem = lineItems[deletedItemUid];
  if (lineItem) {
    if (lineItem?.facts) {
      // error line item facts dependant facts
      lineItem.facts.forEach((factUid) => {
        const depCells = facts[factUid].dependantCells;
        if (depCells) {
          depCells.forEach((dep) => {
            const depFact = facts[dep.uid];
            if (!depFact) return;
            depFact.error = "Error";
          });
        }
      });
      // Remove the line item's facts
      lineItem.facts.forEach((factUid) => delete facts[factUid]);
    }

    // Remove the line item
    delete lineItems[deletedItemUid];

    // Update module line item arrays
    const delOrder = lineItem.order;
    const moduleLineItems = modules[moduleID].lineItems;
    if (moduleLineItems) {
      moduleLineItems.splice(moduleLineItems.indexOf(deletedItemUid), 1);
      moduleLineItems.forEach((liUid) => {
        const li = lineItems[liUid];
        if (li.order > delOrder) {
          li.order -= 1;
          // fix facts parent order
          li.facts.forEach((factUid) => (facts[factUid].parentOrder = li.order - 1));
        }
      });
    }
    // Modify line item names
    const namesArray = modules[moduleID].lineItemsNames;
    if (namesArray) {
      namesArray.splice(namesArray.indexOf(lineItem.name), 1);
    }
  }
};

/* COLUMN OPERATIONS */
/**
 * Returns true if a provided period is valid for a given module.
 *
 * @param {Object} module - A Valsys module object
 * @param {number} period - The period to test for
 * */
export const isValidModulePeriod = (module: IModule, period: number) => {
  const { moduleStart, moduleEnd } = module;
  return period >= moduleStart && period <= moduleEnd;
};

/**
 * Mutates a module's periods in response to a period length change
 *
 * @param {Object} module - A Valsys module object.
 * @param {number} period - The period to be added or removed.
 * */
export const updateModulePeriods = (module: IModule, period: number) => {
  if (period > module.moduleEnd) {
    module.moduleEnd += module.forecastIncrement;
  } else if (period === module.moduleEnd) {
    module.moduleEnd -= module.forecastIncrement;
  } else if (period === module.moduleStart) {
    module.moduleStart += module.forecastIncrement;
  } else if (period < module.moduleStart) {
    module.moduleStart -= module.forecastIncrement;
  }
};

/**
 * Add a column to a module
 * Removes an existing temp column and replaces it with server supplied information
 * */
export const safelyAddColumn = (
  facts: Record<string, IFact>,
  lineItems: Record<string, ILineItem>,
  modules: Record<string, IModule>,
  addedModule: IRawModule
) => {
  /**
   * - Normalize the module object provided by the server.
   * - Merge the newly supplied facts into the model's state.
   * - Loop over the module's line items and:
   *    - Form the temp fact uid
   *    - Remove it from the fact object
   * */
  const mod = modules[addedModule.uid];
  const withCaseUid: IRawModule = { ...addedModule, caseUid: mod.caseUid };
  const { entities } = vsNormalize<IFact>(withCaseUid, schemaLevel.MODULE);
  //const { lineItems: normalizedLineItems } = entities;

  if (entities.facts) {
    const deepMerged: Record<string, IFact> = deepMerge(entities.facts, facts);
    const keys = Object.keys(deepMerged) as (keyof IFact)[];
    keys.forEach((key) => (facts[key] = deepMerged[key]));
  }

  /*if (mod.lineItems && normalizedLineItems) {
    mod.lineItems.forEach((li) => {
      const tempUid = `temp-${li}-${newPeriod}`;
      const lineItem = lineItems[li];
      lineItem.facts = Array.from(new Set(lineItem.facts.concat(normalizedLineItems[li].facts)));
      if (facts[tempUid]) {
        lineItem.facts.splice(lineItem.facts.indexOf(tempUid), 1);
        delete facts[tempUid];
      }
    });
  }*/
};

/***
 * Delete a column from a module.
 * */
export const safelyDeleteColumn = (
  facts: Record<string, IFact>,
  lineItems: Record<string, ILineItem>,
  modules: Record<string, IModule>,
  moduleID: string,
  strPeriod: string
) => {
  const period = Number(strPeriod);
  const module = modules[moduleID];
  updateModulePeriods(module, period);

  if (module?.lineItems?.length) {
    module.lineItems.forEach((liUid) => {
      const li = lineItems[liUid];
      const delFactUid = li.facts.find((factUid) => {
        return facts[factUid].period === period;
      });
      if (delFactUid) {
        li.facts.splice(li.facts.indexOf(delFactUid), 1);
        delete facts[delFactUid];
      }
    });
  }
};

/**
 * Remove dependencies from precedents of a fact being edited or deleted
 *
 * @param {Array} affectedFacts - The facts edited or removed
 * @param {Object} facts - The store facts.
 * @param {Boolean} deleteFlag - Flags if it's a delete opertion (instead of an edit)
 * */
export const removeInvalidDepsAndPrecs = (
  affectedFacts: IFact[],
  facts: Record<string, IFact>,
  deleteFlag = null
) => {
  affectedFacts.forEach((afFact) => {
    // edited facts grabbed from facts because have already been stripped out
    // on delete they don't exist anymore in facts
    afFact = facts[afFact.uid] || afFact;
    if (afFact.precedentCells) {
      afFact.precedentCells.forEach((prec) => {
        const modFact = facts[prec.uid];
        if (modFact?.dependantCells) {
          modFact.dependantCells = modFact.dependantCells.filter((dep) => dep.uid !== afFact.uid);
        }
      });
    }
    if (deleteFlag && afFact.dependantCells) {
      // remove deps only on delete
      afFact.dependantCells.forEach((dep) => {
        const modFact = facts[dep.uid];
        if (modFact?.precedentCells) {
          modFact.precedentCells.splice(
            modFact.precedentCells.findIndex((cell) => cell.uid === afFact.uid),
            1
          );
        }
      });
    }
    // on edit new precs will be sent by the server. on delete doesn't matter.
    if (facts[afFact.uid]) delete afFact.precedentCells;
  });
};

/**
 * Legacy blueprint table region cardinalities
 */
export const RegionCardinality = {
  /**
   * A region that contains a finite rectangular group of table cells
   */
  CELLS: "cells",

  /**
   * A region that represents all cells within 1 or more rows.
   */
  FULL_ROWS: "full-rows",

  /**
   * A region that represents all cells within 1 or more columns.
   */
  FULL_COLUMNS: "full-columns",

  /**
   * A region that represents all cells in the table.
   */
  FULL_TABLE: "full-table",
};

export const cardinalityMap = {
  [RegionCardinality.FULL_COLUMNS]: "cols",
  [RegionCardinality.FULL_ROWS]: "rows",
};

/**
 * Legacy method from blueprint table to get cardinality from selection region
 */
export function getRegionCardinality(region: ITableSelection) {
  if (region.cols != null && region.rows != null) {
    return RegionCardinality.CELLS;
  } else if (region.cols != null) {
    return RegionCardinality.FULL_COLUMNS;
  } else if (region.rows != null) {
    return RegionCardinality.FULL_ROWS;
  } else {
    return RegionCardinality.FULL_TABLE;
  }
}

/**
 * Legacy blueprint table method
 */
function normalizeInterval(coord: number, coord2?: number) {
  if (coord2 == null) {
    coord2 = coord;
  }

  const interval = [coord, coord2];
  interval.sort((a, b) => a - b);
  return interval;
}

/**
 * Legacy vs table function to get region object from cells
 */
export function cellsToRegion(row: number, col: number, row2?: number, col2?: number) {
  return {
    cols: normalizeInterval(col, col2),
    rows: normalizeInterval(row, row2),
  };
}

export function navToCell(
  fact: IFact,
  modules: Record<string, IModule>,
  navigate: NavigateFunction
) {
  const moduleObj = Object.values(modules).find(
    (mod) => mod.uid === fact.moduleUid && mod.caseUid === fact.caseUid
  );
  if (moduleObj) {
    const cell = cellPositionFromFact(fact.period, fact.parentOrder, moduleObj.moduleStart);
    navigate(vsNavigate(moduleObj.name, cell));
  }
}

//  Given a selected regions object and column headers and row headers extracts a range of 'body' coordinates
export const cellCoordArrayFromSelectedRegions = (
  rows: IVSRow<IFact | INormalizedImportFact>[],
  selectedRegions: ITableSelection[]
) => {
  const { cols: selCols, rows: selRows } = selectedRegions[0];
  let colRange: number[];
  let rowRange;

  // Handle non cell only cardinalities, eg FULL_TABLE, FULL_ROW
  if (!selCols) {
    colRange = range(getRowsColumnHeaders(rows).length);
  } else {
    colRange = range(selCols[0], selCols[1] + 1);
  }
  if (!selRows) {
    rowRange = range(rows.length);
  } else {
    rowRange = range(selRows[0], selRows[1] + 1);
  }

  return rowRange.map((row) => colRange.map((col) => ({ col, row })));
};

export const getFactsFromCellCoordsArray = <F>(
  rows: IVSRow<F>[],
  rowArray: { col: number; row: number }[][]
) => {
  const items = rowArray.map((row) => {
    return row.map((cell) => {
      const { row, col } = cell;
      return rows[row][col];
    });
  });
  return items.flat();
};

export const getRowsColumnHeaders = (rows: IVSRow<IFact | INormalizedImportFact>[]) => {
  return (
    Object.keys(rows[0])
      // filters the 0-indexed facts in the row object
      .filter((key) => key !== "uid" && key !== "rowHeader" && !isNaN(Number(key)))
      .sort()
  );
};

export const iterateRows = (
  rows: IVSRow<IFact>[],
  func: (i: number, j: number, value: string) => void,
  rowRange?: number[],
  colRange?: number[]
) => {
  const periods: number[] = Object.keys(rows[0]).filter(
    (key) => !isNaN(Number(key))
  ) as unknown as number[];
  periods.sort((a, b) => a - b);
  const rowsRange = rowRange || [0, rows.length - 1];
  const columnRange = colRange || [0, periods.length - 1];
  for (let i = rowsRange[0]; i <= rowsRange[1]; i++) {
    for (let j = columnRange[0]; j <= columnRange[1]; j++) {
      const period = periods[j];
      const value = rows[i][String(period)] as string;
      func(i, j, value);
    }
  }
};

export interface CopyData {
  columnHeaders?: string[];
  items: string[][];
  rowHeaders?: string[];
}

export const tableCopy = (
  rows: IVSRow<IFact>[],
  selectedRegions: { cols: number[]; rows: number[] }[],
  formattedColumnHeaders: string[],
  rowHeaders: IRowHeader[],
  userRequestedHeaders?: boolean
) => {
  const copyData: CopyData = { items: [] };
  let { cols: selCols, rows: selRows } = selectedRegions[0];
  // no cols/rows object present then we know we're dealing with full rows/cols
  if (!selCols) selCols = [0, formattedColumnHeaders.length - 1];
  if (!selRows) selRows = [0, rowHeaders.length - 1];
  //const rowsCount = selRows[1] - selRows[0];
  const copyStructure: string[][] = Array(rows.length)
    .fill(null)
    .map(() => []);
  const includeHeaders = false; // TODO: implement cardinality here
  const fullTable = false; // TODO: implement cardinality

  if (includeHeaders || userRequestedHeaders) copyData.rowHeaders = rowHeaders.map((rH) => rH.name);

  iterateRows(
    rows,
    (i, j, value) => {
      copyStructure[i][j] = value;
    },
    selRows,
    selCols
  );

  // add row headers
  if (includeHeaders || userRequestedHeaders) {
    copyStructure.forEach((row, idx) => {
      row.unshift(rowHeaders[idx].name);
    });
  }

  // Build header row
  const headerRow = [];
  if (includeHeaders || userRequestedHeaders) {
    copyData.columnHeaders = formattedColumnHeaders;
    // copying the full table with headers we need the blank cell in the top right of the table
    if (fullTable) headerRow.push("");
    for (let i = selCols[0]; i <= selCols[1]; i++) {
      headerRow.push(formattedColumnHeaders[i]);
    }
  }
  if (headerRow.length > 0) copyStructure.unshift(headerRow);

  copyData.items = copyStructure;
  return copyData;
};

export const tablePaste = (
  data: IFact[][],
  rows: IVSRow<IFact>[],
  focusedCell: ITableCoordinates,
  rawValues: boolean,
  modules: Record<string, IModule>,
  lineItems: Record<string, ILineItem>,
  facts: Record<string, IFact>
) => {
  if (!data) return;
  const { row, col } = focusedCell;
  let rowToPaste = row;
  let colToPaste = col;
  const editedFacts: Partial<IFact>[] = [];
  data.forEach((item) => {
    if (item.length) {
      item.forEach((pasteFact) => {
        const row = rows[rowToPaste];
        if (!row) return;
        const rowsFact = row[colToPaste];
        if (!rowsFact) return;
        let fact: Partial<IFact> = { ...rowsFact };
        if (rawValues) {
          fact = convertFormattedNumericFact({
            uid: fact.uid,
            formula: pasteFact.value?.toString(),
            type: pasteFact.format?.type ? pasteFact.format.type : EValFormat.NUMERIC,
          });
        } else {
          const rowShift = rowToPaste - (pasteFact.parentOrder - 1);
          const originCol = pasteFact.period - modules[pasteFact.moduleUid].moduleStart;
          const colShift = colToPaste - originCol;
          const newFormula = shiftFormula(pasteFact.formula, rowShift, colShift, {
            modules,
            lineItems,
            facts,
          });
          fact = {
            uid: fact.uid,
            formula: newFormula,
          };
        }
        editedFacts.push(fact);
        colToPaste += 1;
      });
      rowToPaste += 1;
      colToPaste = col;
    }
  });
  return editedFacts;
};

export const tableDrag = (
  rows: IVSRow<IFact>[],
  focusedCell: ITableCoordinates,
  selectedRegions: { cols: number[]; rows: number[] }[],
  direction: "row" | "col",
  start: number,
  end: number,
  modules: Record<string, IModule>,
  lineItems: Record<string, ILineItem>,
  facts: Record<string, IFact>
) => {
  const edits: Partial<IFact>[] = [];
  const { row, col } = focusedCell;
  let startCol, endCol, startRow, endRow;

  if (start === undefined) {
    const { cols, rows } = selectedRegions[0];
    startCol = cols[0];
    endCol = cols[1];
    startRow = rows[0];
    endRow = rows[1];
  } else {
    startRow = direction === "row" ? start : row;
    endRow = direction === "row" ? end : row;
    startCol = direction === "col" ? start : col;
    endCol = direction === "col" ? end : col;
  }
  const originalFormula = rows[row][col].formula;

  if (direction === "row") {
    for (let i = startRow; i <= endRow; i++) {
      if (i === row) continue;
      const fact = (({ uid, formula }) => ({
        uid,
        formula,
      }))(rows[i][startCol]);
      const shift = i - row;
      fact.formula = shiftFormula(originalFormula, shift, 0, { modules, lineItems, facts });
      edits.push(fact);
    }
  }
  if (direction === "col") {
    for (let i = startCol; i <= endCol; i++) {
      if (i === col) continue;
      const fact = (({ uid, formula }) => ({
        uid,
        formula,
      }))(rows[startRow][i]);
      const shift = i - col;
      fact.formula = shiftFormula(originalFormula, 0, shift, { modules, lineItems, facts });
      edits.push(fact);
    }
  }

  return edits;
};

/** Takes an array that can be partial (we only store the necessary widths in config)
 *  and the total number of columns and returns an array with all the widths
 *  defaulting those that are missing
 */
export const tableWidthsWithDefaults = (partialWidths: number[], columnCount: number) => {
  if (!columnCount) return [];
  if (!partialWidths) partialWidths = [];
  const widths = [];
  widths.push(partialWidths[0] || DATAENTRY_DEFAULT_ROW_HEADER_WIDTH);
  for (let i = 1; i < columnCount; i++) {
    widths.push(partialWidths[i] || DATAENTRY_DEFAULT_MIN_COL_WIDTH);
  }
  return widths;
};
