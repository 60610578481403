import * as consts from "constants/dataEntryConstants";
import { FETCH_QUICK_CALCULATIONS_SUCCESS } from "constants/dataEntryConstants";
import { UPDATE_UNIT } from "constants/uiConstants";

/**
 * Returns a new moduleData object with the changed module data
 * Required all the module data to be provided
 * Mostly used when a full module is returned when we first fetch or when making changes
 */
function updateModuleData(moduleData, currentCase, data) {
  const caseData = moduleData[currentCase];
  return {
    ...moduleData,
    [currentCase]: {
      ...caseData,
      // Might be needed  ...(currentCaseData || {}),
      [data.uid]: data,
    },
  };
}

const initialState = {
  clipboardData: null,
  commitError: false,
  committing: [],
  moduleData: {},
  moduleList: [],
  moduleTemplates: [],
  showFullEditHistory: true,
  quickCalcs: [],
  unit: "Millions",
};

function dataEntryReducer(state = initialState, action) {
  switch (action.type) {
    case consts.COMMITTING:
      return { ...state, committing: state.committing.concat([1]) };
    case consts.COMMITTED: {
      const { clear } = action.payload;
      const committing = clear ? [] : [...state.committing];
      committing.pop();
      return {
        ...state,
        committing,
      };
    }
    case consts.SET_COMMIT_ERROR:
      return {
        ...state,
        commitErrorState: action.payload.commitErrorState,
      };
    case consts.FETCH_KEY_RATIO_DATA_SUCCESS:
      return {
        ...state,
        keyRatios: {
          ...state.keyRatios,
          [action.payload.moduleUid]: action.payload.data.data,
        },
      };

    case consts.FETCH_KEY_RATIO_DATA_FAILURE:
      return {
        ...state,
        keyRatios: {
          ...state.keyRatios,
          [action.payload.moduleUid]: "Key ratio data unavailable",
        },
      };
    case consts.ADD_CLIPBOARD_ITEM:
      return {
        ...state,
        clipboardData: action.payload.item,
      };
    case consts.DELETE_MODULE_SUCCESS:
      // TODO: not implemented yet
      return { ...state };
    case consts.FETCH_MODULE_DATA_SUCCESS: {
      const { data, currentCase } = action.payload;
      const { moduleData } = state;
      return {
        ...state,
        moduleData: updateModuleData(moduleData, currentCase, data),
      };
    }
    case consts.FLUSH_DATA_REDUCER:
      return { ...initialState };
    case UPDATE_UNIT:
      return { ...state, unit: action.payload.unit };
    case FETCH_QUICK_CALCULATIONS_SUCCESS:
      return {
        ...state,
        quickCalcs: action.payload.data,
      };
    default:
      return state;
  }
}

export default dataEntryReducer;
