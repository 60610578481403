import { Callout, Classes, H6, Intent } from "@blueprintjs/core";
import Table from "components/table/Table";
import { Grid, StyledScrollContainer } from "components/utility/StyledComponents";
import AppContext from "context/appContext";
import React, { useContext, useMemo } from "react";
import { IBasicModelInfo } from "types/model";
import { TModelHistoryTablePartialModel } from "types/modelHistory";

interface ICreateWatchlistModelSelectionProps {
  selectedModelId?: string;
  setModel: (modelId: string) => void;
  ticker: { models: IBasicModelInfo<string>[]; ticker: string };
}

const CollectionModelSelection: React.FC<ICreateWatchlistModelSelectionProps> = ({
  selectedModelId,
  setModel,
  ticker,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const models = ticker.models;

  const tableData = useMemo(() => {
    return models.map((model) => {
      return {
        companyName: model.companyName,
        createdAt: model.createdAt,
        createdBy: model?.createdBy ?? "",
        id: model.id,
        industry: model.industry,
        modelID: model.id,
        title: model.title,
      };
    });
  }, [models]);

  const handleModelSelection = (models: TModelHistoryTablePartialModel[]) => {
    setModel(models[0].id);
  };

  return (
    <Grid fullHeight gap="10px" rows="auto 1fr">
      <div>
        <H6>
          <span className={Classes.TEXT_MUTED}>Ticker: </span>
          {ticker.ticker}
        </H6>
        <H6>
          <span className={Classes.TEXT_MUTED}>Company Name: </span> {ticker.models[0].companyName}
        </H6>
        <Callout intent={Intent.PRIMARY} title="Model Selection Required.">
          <p className={Classes.RUNNING_TEXT}>
            Use the table below to select a model. Click the model ID link to open the model in a
            new tab.
          </p>
        </Callout>
      </div>
      <StyledScrollContainer $theme={theme}>
        <Table
          allowSelectAll={false}
          columnTemplates={[
            {
              header: "Model ID",
              id: "id",
              meta: {
                type: "link",
              },
            },
            { header: "Model Title", id: "title" },
            { header: "Company", id: "companyName" },
            { header: "Industry", id: "industry" },
            { header: "Created", id: "createdAt" },
            { header: "Analyst", id: "createdBy" },
          ]}
          configOverrides={{ layoutMode: "auto" }}
          data={tableData}
          enableSelection={true}
          id="watchlistTable"
          loading={false}
          setSelectedRows={handleModelSelection}
          selectedRows={selectedModelId ? [{ id: selectedModelId }] : []}
          useRadioButtons={true}
        />
      </StyledScrollContainer>
    </Grid>
  );
};

export default CollectionModelSelection;
