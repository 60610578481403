import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Select } from "@blueprintjs/select";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  caseModulesSelector,
  currentCaseSelector,
  currentModuleSelector,
} from "selectors/modelSelectors";
import { addModule } from "actions/dataEntryActions";
import {
  Button,
  Checkbox,
  Classes,
  ControlGroup,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
  MenuItem,
  Tooltip,
} from "@blueprintjs/core";

import DataEntryModuleSelect from "./DataEntryModuleSelect";
import { Themes } from "../../constants/uiConstants";
import AppContext from "../../context/appContext";
import { PeriodTypes } from "../../constants/createModelConstants";

const StyledTooltip = styled(Tooltip)`
  align-self: flex-end;
`;

export const NewModuleDialog = React.memo(
  ({ addModule, currentCase, currentModule, isOpen, handleClose, modules }) => {
    const [moduleName, setModuleName] = useState("");
    const [parentItem, setParentItem] = useState("");
    const [periodType, setPeriodType] = useState(PeriodTypes.Annual);
    const [unlinked, setUnlinked] = useState(false);
    const [nameError, setNameError] = useState("");
    const [creating, setCreating] = useState(false);
    const {
      config: { theme },
    } = useContext(AppContext);

    const moduleList = useMemo(() => Object.values(modules), [modules]);

    const close = useCallback(() => {
      setModuleName("");
      setNameError("");
      setCreating(false);
      setPeriodType(PeriodTypes.Annual);
      handleClose();
    }, [handleClose, setCreating, setModuleName, setNameError]);

    useEffect(() => {
      if (!currentModule || !moduleList) return;
      setParentItem(moduleList.find((mod) => mod.name === currentModule.name));
    }, [currentModule, moduleList]);

    useEffect(() => {
      close();
    }, [close, currentModule]);

    function submit(e) {
      e.preventDefault();
      if (!moduleName) {
        setNameError("Provide a name for the new module");
      } else if (moduleList.find((mod) => mod.name === moduleName)) {
        setNameError("Modules must have unique names");
      } else {
        setCreating(true);
        setNameError("");
        addModule(
          moduleName,
          currentCase.uid,
          currentModule.uid,
          unlinked ? Object.values(modules).find((mod) => mod.depth === 0).uid : parentItem.uid,
          unlinked,
          periodType
        );
      }
    }

    return (
      <Dialog
        autoFocus={false}
        enforceFocus={false}
        icon="CUBE_ADD"
        className={theme === Themes.DARK ? Classes.DARK : null}
        title="Create new module"
        canEscapeKeyClose={false}
        canOutsideClickClose={true}
        isOpen={isOpen}
        onClose={close}
      >
        <form onSubmit={submit}>
          <div className={Classes.DIALOG_BODY}>
            <FormGroup
              label="Module name:"
              helperText={nameError}
              htmlFor="module-name"
              intent={nameError ? Intent.DANGER : Intent.NONE}
            >
              <InputGroup
                autoFocus
                id="module-name"
                type="text"
                value={moduleName}
                onChange={(e) => setModuleName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <ControlGroup fill>
                <DataEntryModuleSelect
                  currentModule={parentItem}
                  disabled={unlinked}
                  fill
                  id="data-entry-module-selector-new-module-dialog"
                  inline={false}
                  label="Parent module:"
                  margin="0"
                  moduleList={moduleList}
                  onItemSelect={(item) => setParentItem(item)}
                />
                <StyledTooltip
                  className={Classes.FIXED}
                  content="Set parent to current active module"
                >
                  <Button
                    disabled={unlinked}
                    onClick={() => {
                      setParentItem(moduleList.find((mod) => mod.name === currentModule.name));
                    }}
                    text="Current"
                  />
                </StyledTooltip>
              </ControlGroup>
            </FormGroup>
            <FormGroup helperText="Whether the module is connected to the main model.">
              <Checkbox label="Root" onChange={() => setUnlinked(!unlinked)} checked={unlinked} />
            </FormGroup>
            <FormGroup label="Period type:">
              <ControlGroup fill>
                <Select
                  filterable={false}
                  items={[PeriodTypes.Annual, PeriodTypes.Quarterly]}
                  itemRenderer={(itemPeriodType) => {
                    return (
                      <MenuItem
                        active={periodType === itemPeriodType}
                        key={itemPeriodType}
                        onClick={() => setPeriodType(itemPeriodType)}
                        text={itemPeriodType}
                      />
                    );
                  }}
                >
                  <Button rightIcon="caret-down" text={periodType} />
                </Select>
              </ControlGroup>
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button disabled={creating} onClick={close} text="Cancel" />
              <Button
                intent={Intent.PRIMARY}
                disabled={!moduleName}
                loading={creating}
                type="submit"
              >
                Create
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    );
  }
);

function mapStateToProps(state) {
  return {
    currentCase: currentCaseSelector(state),
    currentModule: currentModuleSelector(state),
    modules: caseModulesSelector(state),
  };
}

const mapDispatchToProps = {
  addModule,
};

NewModuleDialog.displayName = "NewModuleDialog";
export default connect(mapStateToProps, mapDispatchToProps)(NewModuleDialog);
