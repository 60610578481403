import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Highcharts from "highcharts/highstock";
import { Intent } from "@blueprintjs/core";
import { SizeMe } from "react-sizeme";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import LoadingIndicator from "components/utility/LoadingIndicator";
import usePrevious from "hooks/usePrevious";
import { tagLineItemsMapSelector } from "selectors/cpp_selectors";
import { lineItemsSelector, modelInfoSelector } from "selectors/modelSelectors";
import { factsSelector } from "selectors/dataEntrySelectors";
import { fetchTagData } from "utils/cpp";
import { darkThemeHighchartsConfig, lightThemeHighchartsConfig } from "configs/highchartsConfigs";
import HighchartsReact from "highcharts-react-official";
import EnvContext, { EEnv } from "context/envContext";
import { formatValue } from "utils/formatter";

const StyledGraphContainer = styled.div`
  height: 350px;
  width: 100%;
`;

const SeriesNames = ["Base", "Upside", "Downside", "Consensus"];

const CPPTargetPrice = () => {
  const [height, setHeight] = useState(null);
  const prevHeight = usePrevious(height);
  const {
    config: { theme },
  } = useContext(AppContext);
  const { env } = useContext(EnvContext);

  const tagLineItemsMap = useSelector(tagLineItemsMapSelector);
  const lineItems = useSelector(lineItemsSelector);
  const facts = useSelector(factsSelector);
  const modelInfo = useSelector(modelInfoSelector);
  const startPeriod = modelInfo?.startPeriod;
  // const labels = getEffectivePeriodRange(startPeriod, tagLineItemsMap, lineItems, facts);

  const tag = "Total Inc Dividends";
  const scenarios =
    env === EEnv.DEVELOPMENT
      ? ["Valuation (Base)", "Valuation (Upside)", "Valuation (Downside)"]
      : [
          "{Valuation} (Base)",
          "{Valuation} (Upside)",
          "{Valuation} (Downside)",
          "{Valuation} (Consensus)",
        ];

  const items = scenarios.map((scenario) => {
    return env === EEnv.DEVELOPMENT
      ? tagLineItemsMap?.[`${tag}, ${scenario}`]?.[0]
      : tagLineItemsMap?.[`${tag} ${scenario}`]?.[0];
  });

  if (!items || !items.every((item) => item)) {
    return null;
  }

  let format = { hasSuffix: true };

  const series = [];

  items.forEach((liId, index) => {
    const seriesData = [];
    for (let i = 0; i < lineItems[liId].facts.length - 1; i++) {
      const factId = lineItems[liId].facts[i];
      const fact = facts[factId];
      if (fact.format) format = { ...format, ...fact.format };
      if (fact.period > startPeriod) {
        if (!fact.value) seriesData.push([fact.period, 0]);
        else seriesData.push([fact.period, Number(fact.value)]);
      }
    }
    // we must sort because the facts are not retrieved sorted by period
    series.push({
      type: "line",
      name: SeriesNames[index],
      data: seriesData.sort((a, b) => a?.[0] - b?.[0]),
    });
  });

  const currentSeriesData = [];
  const currentValue = Number(
    fetchTagData(
      "Share Price, Valuation (Base)",
      startPeriod,
      tagLineItemsMap,
      lineItems,
      facts,
      true
    )
  );
  for (let i = startPeriod + 1; i <= startPeriod + series[0]?.data?.length; i++) {
    currentSeriesData.push([i, currentValue]);
  }
  // TODO: Current line should be dotted
  // Axis on the right hand side
  // formatting from the model
  series.push({
    dashStyle: "dash",
    data: currentSeriesData,
    marker: { enabled: false },
    name: "Current Price",
    type: "line",
  });

  const themeOptions =
    theme === Themes.LIGHT ? lightThemeHighchartsConfig : darkThemeHighchartsConfig;
  const chartOpts = Highcharts.merge(themeOptions, {
    tooltip: {
      formatter: function () {
        const points = this.points || [this.point];

        const tooltips = points.map((point) => {
          return `${point.series.name}: ${formatValue(point.y, format)}`;
        });
        // Custom option only seem to exist on the LAST point in a points array.
        return [points[0].x, ...tooltips];
      },
      split: true,
    },
    series,
    yAxis: {
      labels: {
        formatter: function () {
          return formatValue(this.value, format);
        },
      },
      title: "",
    },
  });
  if (!series) {
    return (
      <div>
        <LoadingIndicator loading status={{ intent: Intent.PRIMARY, message: "Loading data" }} />
      </div>
    );
  }

  return (
    <SizeMe monitorHeight>
      {({ size }) => {
        if (size?.height !== prevHeight) {
          setHeight(size.height);
        }
        return (
          <StyledGraphContainer>
            <HighchartsReact
              containerProps={{ style: { height: "100%" } }}
              highcharts={Highcharts}
              options={chartOpts}
            />
          </StyledGraphContainer>
        );
      }}
    </SizeMe>
  );
};

export default CPPTargetPrice;
