import * as Highcharts from "highcharts/highstock";
import { Classes } from "@blueprintjs/core";

interface AxisTitleInputPosition {
  x: number;
  y: number;
  rotation?: number;
  inputWidth: number;
}

declare module "highcharts" {
  namespace Axis {
    let MIN_INPUT_WIDTH: number;
  }
  interface Axis {
    getTitleInputPosition: () => AxisTitleInputPosition;
    renderInputForTitle: () => void;
    finishTitleEdit: (cancel?: boolean) => void;
    triggerEditEvent?: () => void;
    finishEditEvent?: (e: MouseEvent) => void;
    axisTitle: SVGElement;
    titleInput?: SVGElement;
  }
  interface SVGElement {
    rotation?: number;
    textStr?: string;
    yCorr?: number;
    addFocusEvent?: () => void;
    handleKeyDown?: (e: KeyboardEvent) => void;
  }
  interface XAxisTitleOptions {
    editable?: boolean;
  }
  interface YAxisTitleOptions {
    editable?: boolean;
  }
  interface ZAxisTitleOptions {
    editable?: boolean;
  }
}

export default function (H: typeof Highcharts) {
  if (!H.AST.allowedTags.includes("input")) {
    H.AST.allowedTags.push("input");
  }

  if (!H.AST.allowedAttributes.includes("placeholder")) {
    H.AST.allowedAttributes.push("placeholder");
  }

  // You can easily define MIN_INPUT_WIDTH on the Highcharts Axis
  H.Axis.MIN_INPUT_WIDTH = 150;

  /**
   * Determines whether a given point (chartX, chartY) is inside a potentially rotated rectangle.
   * The rectangle is defined by its top-left corner (rectX, rectY), its dimensions (rectWidth, rectHeight),
   * and a rotation in degrees about its center.
   *
   * @param {number} chartX - The x-coordinate of the point to check.
   * @param {number} chartY - The y-coordinate of the point to check.
   * @param {number} rectX - The x-coordinate of the top-left corner of the rectangle.
   * @param {number} rectY - The y-coordinate of the top-left corner of the rectangle.
   * @param {number} rectWidth - The width of the rectangle.
   * @param {number} rectHeight - The height of the rectangle.
   * @param {number} [rotation=0] - The rotation of the rectangle in degrees, counterclockwise, with a transform-origin at its center.
   * @returns {boolean} True if the point is inside the rectangle, false otherwise.
   */
  function isClickInsideRectangle(
    chartX: number,
    chartY: number,
    rectX: number,
    rectY: number,
    rectWidth: number,
    rectHeight: number,
    rotation = 0
  ): boolean {
    // Convert rotation to radians
    const rotationRadians = rotation * (Math.PI / 180);

    // Calculate the center of the rectangle
    const centerX = rectX + rectWidth / 2;
    const centerY = rectY + rectHeight / 2;

    // Translate the click coordinates to the center of the rectangle
    const translatedX = chartX - centerX;
    const translatedY = chartY - centerY;

    // Rotate the click coordinates back
    const rotatedX =
      translatedX * Math.cos(-rotationRadians) - translatedY * Math.sin(-rotationRadians);
    const rotatedY =
      translatedX * Math.sin(-rotationRadians) + translatedY * Math.cos(-rotationRadians);

    // Check if the translated and rotated point is inside the rectangle
    return (
      rotatedX >= -rectWidth / 2 &&
      rotatedX <= rectWidth / 2 &&
      rotatedY >= -rectHeight / 2 &&
      rotatedY <= rectHeight / 2
    );
  }

  /**
   * Calculates the position and width for a title input field on an axis.
   *
   * @this {Highcharts.Axis}
   * @returns {{ x: number, y: number, rotation: number, inputWidth: number }} Position and size of the input field.
   */
  H.Axis.prototype.getTitleInputPosition = function (
    this: Highcharts.Axis
  ): AxisTitleInputPosition {
    const title = this.axisTitle,
      bbox = title.getBBox(),
      inputWidth = Math.max(H.Axis.MIN_INPUT_WIDTH, bbox.width);

    return {
      x: (title.attr("x") as number) - inputWidth / 2 - (!this.isXAxis ? bbox.width / 2 : 0),
      y: title.attr("y") as number,
      rotation: title.rotation,
      inputWidth,
    };
  };

  /**
   * Renders an input field for editing the axis title.
   *
   * @this {Highcharts.Axis} - Context of the Highcharts.Axis instance.
   */
  H.Axis.prototype.renderInputForTitle = function (this: Highcharts.Axis) {
    const chart = this.chart,
      title = this.axisTitle;

    if (!title) return;

    const { x, y, rotation, inputWidth } = this.getTitleInputPosition();

    this.titleInput = chart.renderer
      .text(
        `<input
          type="text"
          placeholder="${title?.textStr || "Input the new title..."}"
          class="highcharts-title-input ${Classes.INPUT}"
          style="position: relative; width: ${inputWidth}px; text-align: center;"
        >`,
        x,
        y,
        true
      )
      .attr({
        "data-rotation": rotation,
      })
      .css({
        transform: `rotate(${rotation}deg)`,
        "transform-origin": "50% 50%",
      })
      .add();

    const increaseBackgroundOpacity = () => {
      const titleElement = this.titleInput?.element?.children[0] as HTMLElement;

      if (titleElement) {
        // assuming the color is in 'rgba(r, g, b, opacity)' format
        const rgba = getComputedStyle(titleElement).backgroundColor;
        if (rgba) {
          if (rgba.includes("rgba")) {
            const opaqueRgba = rgba.replace(/[^,]+(?=\))/, "1");
            titleElement.style.backgroundColor = opaqueRgba;
          }
        }
      }
    };

    increaseBackgroundOpacity();

    this.titleInput.addFocusEvent = () => {
      if (this.titleInput && this.titleInput.element && this.titleInput.element.children[0]) {
        (this.titleInput.element.children?.[0] as HTMLElement)?.focus();
      }
    };

    this.titleInput.element.onclick = this.titleInput.addFocusEvent;

    this.titleInput.handleKeyDown = (event: KeyboardEvent) => {
      if (this.titleInput && this.titleInput.element) {
        if (event.key === "Escape") this.finishTitleEdit(true);
        else if (event.key === "Enter") this.finishTitleEdit();
      }
    };

    this.titleInput.element.onkeydown = this.titleInput.handleKeyDown;
  };

  /**
   * Cancels the edition of the axis title: destroys the input field and updates the title.
   *
   * @this {Highcharts.Axis} - Context of the Highcharts.Axis instance.
   */
  H.Axis.prototype.finishTitleEdit = function (this: Highcharts.Axis, cancel?: boolean) {
    const textValue =
      this.titleInput && (this.titleInput.element.children[0] as HTMLInputElement).value;

    if (this.titleInput) {
      if (this.titleInput.addFocusEvent)
        this.titleInput.element.removeEventListener("click", this.titleInput.addFocusEvent);

      if (this.titleInput.handleKeyDown)
        this.titleInput.element.removeEventListener(
          "keydown",
          this.titleInput.handleKeyDown as EventListener
        );

      this.titleInput.destroy();
      this.titleInput = undefined;
    }

    const newTitle = cancel ? this.axisTitle.textStr : textValue || this.axisTitle.textStr || "";

    this.setTitle({ text: newTitle });
    H.fireEvent(this, "finishTitleEdit", { newTitle });
  };

  H.addEvent(H.Axis, "afterRender", function (this: Highcharts.Axis) {
    const title = this.axisTitle;

    // todo: override axis defaults later
    let titleEditable = this?.options?.title?.editable;
    titleEditable = typeof titleEditable === "undefined" ? true : titleEditable;

    if (this.titleInput) {
      const { x, y } = this.getTitleInputPosition();
      this.titleInput.attr({ x, y });
      return;
    }

    if (title && titleEditable) {
      if (!this.triggerEditEvent) {
        this.triggerEditEvent = () => {
          this.renderInputForTitle();
          this.axisTitle?.attr({ opacity: 0 });
        };

        title.element.addEventListener("dblclick", this.triggerEditEvent);
      }

      if (!this.finishEditEvent) {
        this.finishEditEvent = (event: MouseEvent) => {
          const e = this.chart.pointer.normalize(event);
          if (!this.titleInput) return;

          // clicked outside the chart
          if (!e.chartX && !e.chartY) {
            if (this) this.finishTitleEdit();
          }

          // clicked inside the chart, but outside the input field
          const x = this.titleInput.attr("x") as number,
            y = (this.titleInput.attr("y") as number) + (this.titleInput.yCorr || 0),
            h = this.titleInput.getBBox().height,
            w = this.titleInput.getBBox().width,
            rotation = (this.titleInput.attr("data-rotation") as number) || 0;

          // todo: include the rotation
          if (!isClickInsideRectangle(e.chartX, e.chartY, x, y, w, h, rotation)) {
            this.axisTitle?.attr({ opacity: 1 });
            this.finishTitleEdit();
          }
        };

        document.addEventListener("click", this.finishEditEvent);
      }
    }
  });

  /* Clear custom events after destroying the axis */
  H.addEvent(H.Axis, "destroy", function (this: Highcharts.Axis) {
    const title = this.axisTitle;

    if (title && this.triggerEditEvent) {
      title.element.removeEventListener("dblclick", this.triggerEditEvent);
    }

    if (this.finishEditEvent) {
      document.removeEventListener("click", this.finishEditEvent);
    }

    this.titleInput?.destroy();
  });
}
