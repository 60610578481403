import { captureException } from "@sentry/react";

import { IApiConfig, IAppStatus, IResponse, IUnboundedConfig } from "types";
import { appStates, EStatus, errorMessages, EMethods } from "constants/apiConstants";
import { getToken } from "utils/auth";

const APPLICATION_JSON = "application/json";
/**
 * Function to convert an Error into a VS response
 * */
const handleErr = <Dt>(error?: string): IResponse<Dt> =>
  ({
    status: EStatus.failed,
    error: error || errorMessages.INVALID_RESPONSE,
  } as IResponse<Dt>);

/** Attempts to safely parse a JSON string and returns the object */
export function getValidJSON<Dt>(text: string): Dt | null {
  if (!text) return null;
  if (typeof text === "object") return text;
  try {
    return JSON.parse(text);
  } catch {
    return null;
  }
}

/**
 * Small utility function to spread swr 2.x array key syntax to the api function and return the result.
 */
export const swrApi = <Dt>([route, config]: [string, IUnboundedConfig]): Promise<IResponse<Dt>> => {
  return api<Dt>(route, config);
};

export const api = async <Dt>(
  route: string,
  config: IUnboundedConfig = {}
): Promise<IResponse<Dt>> => {
  try {
    const request = await createRequest(config as IApiConfig);

    // eslint-disable-next-line no-console
    const res = await fetch(route, request).catch((err) => console.error(err));
    if (!res) return handleErr(errorMessages.CANT_ESTABLISH_CONNECTION);
    const text = await res.text();

    const { contentType = APPLICATION_JSON } = config as IApiConfig;
    if ((text && contentType === APPLICATION_JSON) || contentType === "none") {
      const jsonResponse: IResponse<Dt> | null = getValidJSON(text);
      if (!res.ok) return handleErr(jsonResponse?.error || errorMessages.SERVICE_ERROR);
      return jsonResponse || handleErr();
    } else {
      captureException({ text, route });
      return handleErr(`${errorMessages.INVALID_RESPONSE_DATA} [${route}]`);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    captureException(err);
    return handleErr(String(err));
  }
};

export default api;

export const checkConnection = (appStatus: IAppStatus) => {
  return !(
    appStatus.message === appStates.CONNECTION_ERROR ||
    appStatus.message === appStates.DISCONNECTED ||
    appStatus.message === appStates.OFFLINE
  );
};

const createRequest = async (config: IApiConfig): Promise<RequestInit> => {
  const token = await getToken("accessToken");

  const { body = "", method = EMethods.GET, contentType = APPLICATION_JSON, ...headers } = config;

  const headersObj = new Headers({
    Authorization: `Bearer ${token}`,
    Client: "web-client",
    ...(contentType !== "none" && { "Content-Type": contentType }),
    ...cleanHeaders(headers),
  });

  const request: RequestInit = {
    method,
    headers: headersObj,
  };

  if (
    method === EMethods.PUT ||
    method === EMethods.POST ||
    method === EMethods.DELETE ||
    method === EMethods.PATCH
  ) {
    request.body = (
      contentType === APPLICATION_JSON ? JSON.stringify(body) : body
    ) as BodyInit | null;
  }

  return request;
};

const cleanHeaders = (headers: { [key: string]: unknown }): { [key: string]: unknown } => {
  return Object.keys(headers).reduce((cleanedHeaders, key) => {
    if (headers[key] !== undefined) {
      return {
        ...cleanedHeaders,
        [key]: headers[key],
      };
    }

    return cleanedHeaders;
  }, {});
};
