import React from "react";
import { createRoot } from "react-dom/client";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import createSagaMiddleware from "redux-saga";
import "normalize.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "react-virtualized/styles.css";
import addGlobalEventListeners from "./utils/offline";
import rootSaga from "./sagas/sagaHelpers";
import rootReducer from "./reducers/rootReducer";
import * as serviceWorker from "./serviceWorker";
import App from "components/App";
import Authentication from "components/user/Authentication";
import ValsysApp from "ValsysApp";
import EquityScreen from "components/user/EquityScreen";
import CreateModel from "components/new/CreateModel";
import ModelHistory from "components/user/ModelHistory";
import ModelDashboard from "components/dashboard/model/ModelDashboard";
import ScenarioAnalytics from "components/multimodel/ScenarioAnalytics";
// disabled for now to avoid confusion with sheets v2
// import ImportWrapper from "components/import/ImportWrapper";
// import Import from "components/import/Import";
// import ConfigureImport from "components/import/ConfigureImport";
// import ViewAndInitImports from "components/import/ViewAndInitImports";
import WidgetLibrary, { WidgetLibraryHome } from "components/dashboard/libraries/WidgetLibrary";
import WidgetLibraryWidget from "components/dashboard/libraries/WidgetLibraryWidget";
import ViewLibrary, { ViewLibraryHome } from "components/dashboard/ViewLibrary";
import ViewLibraryView from "components/dashboard/ViewLibraryView";
import SettingsLayout from "components/user/SettingsLayout";
import ProfileSettings from "components/user/ProfileSettings";
import InterfaceSettings from "components/user/InterfaceSettings";
import AppearanceSettings from "components/user/AppearanceSettings";
import SetupTwoStepVerification from "components/user/SetupTwoStepVerification";
import ChangePassword from "components/user/ChangePassword";
import DeveloperSettings from "components/user/DeveloperSettings";
import TermsOfUseSettings from "components/user/TermsOfUseSettings";
import NotFound from "components/utility/NotFound";
import "styles/index.scss";
import {
  COMMITTING,
  SET_COMMIT_ERROR,
  UNDO_HISTORY,
  UNDO_HISTORY_SUCCESS,
} from "constants/dataEntryConstants";
import {
  SET_WIDGET_INTRINSIC_HEIGHT,
  UPDATE_CURRENT_BREAKPOINT,
  UPDATE_LAYOUT,
} from "constants/layoutConsts";
import { UPDATE_APP_STATUS } from "constants/uiConstants";
import DashboardManager from "components/dashboard/DashboardManager";
import Dashboard from "components/dashboard/Dashboard";
import WatchlistsManager from "components/collections/WatchlistsManager";
import PortfoliosManager from "components/collections/PortfoliosManager";
import Home from "components/user/Home";
import KPITrends from "components/user/KPITrends";

let releasePrefix = "vs-dev";

if (window.__env__?.REACT_APP_RELEASE_PREFIX) {
  releasePrefix = window.__env__.REACT_APP_RELEASE_PREFIX;
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    release: `${releasePrefix}-${process.env.REACT_APP_VERSION}`,
  });
}

const sagaMiddleware = createSagaMiddleware();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    // filter unwanted actions
    const filteredActions = [
      COMMITTING,
      SET_WIDGET_INTRINSIC_HEIGHT,
      UPDATE_CURRENT_BREAKPOINT,
      UPDATE_APP_STATUS,
      UNDO_HISTORY,
      UNDO_HISTORY_SUCCESS,
      "UNDO_HISTORY_FINISHED",
      SET_COMMIT_ERROR,
      UPDATE_LAYOUT,
    ];
    if (filteredActions.includes(action.type)) {
      return null;
    }
    return action;
  },
  stateTransformer: () => {
    return null;
  },
});

const composeEnhancers = composeWithDevTools({ trace: true });

const store = createStore(
  rootReducer,
  undefined,
  composeEnhancers(applyMiddleware(sagaMiddleware), sentryReduxEnhancer)
);
sagaMiddleware.run(rootSaga);
window.store = store;

addGlobalEventListeners(store);

const Root: React.FC = () => {
  return (
    <Provider store={store}>
      <Outlet />
    </Provider>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route element={<App />}>
        <Route element={<Authentication />}>
          <Route element={<ValsysApp />}>
            <Route path="/" element={<Home />} />
            <Route path="/kpi-trends" element={<KPITrends />} />
            <Route path="/kpi-trends/:dashboardId?/:viewId?" element={<Dashboard />} />
            <Route path="model/create" element={<CreateModel />} />
            <Route path="models/:modelTable" element={<ModelHistory />} />
            <Route path="model/:model" element={<ModelDashboard />} />
            <Route path="model/:model/:tab" element={<ModelDashboard />} />
            <Route path="model/:model/:tab/*" element={<ModelDashboard />} />
            <Route path="scenario-analytics" element={<ScenarioAnalytics />} />
            <Route path="equity-screen" element={<EquityScreen />} />
            <Route path="dashboards" element={<DashboardManager type="Shared" />} />
            <Route path="dashboards/:dashboardId" element={<Dashboard />} />
            <Route path="dashboards/:dashboardId/:viewId" element={<Dashboard />} />
            <Route path="watchlists/shared">
              <Route path=":watchlistId?" element={<WatchlistsManager />} />
              <Route path=":watchlistId/:dashboardId/:viewId?" element={<Dashboard />} />
            </Route>
            <Route path="portfolios/shared">
              <Route path=":portfolioId?" element={<PortfoliosManager />} />
              <Route path=":portfolioId/:dashboardId/:viewId?" element={<Dashboard />} />
            </Route>
            <Route path="settings/widgets" element={<WidgetLibrary />}>
              <Route index={true} element={<WidgetLibraryHome />} />
              <Route path=":widgetId" element={<WidgetLibraryWidget />} />
            </Route>
            <Route path="settings/views" element={<ViewLibrary />}>
              <Route index={true} element={<ViewLibraryHome />} />
              <Route path=":viewId" element={<ViewLibraryView />} />
            </Route>
            <Route path="settings" element={<SettingsLayout />}>
              <Route path="profile" element={<ProfileSettings />} />
              <Route path="interface" element={<InterfaceSettings />} />
              <Route path="valuation/appearance" element={<AppearanceSettings />} />
              <Route path="two-step-auth" element={<SetupTwoStepVerification />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="developer" element={<DeveloperSettings />} />
              <Route path="terms-of-use" element={<TermsOfUseSettings />} />
            </Route>
            {/* disabled for now to avoid confusion with sheets v2 */}
            {/*<Route path="importer" element={<ImportWrapper />}>*/}
            {/*  <Route index={true} element={<ViewAndInitImports />} />*/}
            {/*  <Route path=":importID/" element={<Import />} />*/}
            {/*  <Route path=":importID/:sheetName/:paramView" element={<Import />} />*/}
            {/*  <Route path=":importID/configure" element={<ConfigureImport />} />*/}
            {/*</Route>*/}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

const render = () => {
  const container = document.getElementById("root");
  // disabling non-null assertion checking here as we know "root" exists
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);
  root.render(<RouterProvider router={router} />);
};

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
