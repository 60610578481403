export default function authReducer(
  state = {
    signedIn: false,
  },
  action
) {
  switch (action.type) {
    case "LOGIN":
      return { ...state, signedIn: true };
    case "LOGOUT":
      return { ...state, signedIn: false };
    default:
      return state;
  }
}
