import React from "react";
import "styles/sensitivity-analysis.scss";
import VSHTMLTable from "components/dashboard/VSHTMLTable";
import { useSelector } from "react-redux";
import { H6 } from "@blueprintjs/core";
import range from "lodash.range";
import { buildScenarios, cppTableWidths } from "utils/cpp";
import { lineItemsSelector, modelInfoSelector } from "selectors/modelSelectors";
import { factsSelector } from "selectors/dataEntrySelectors";
import { tagLineItemsMapSelector } from "selectors/cpp_selectors";
import styled from "styled-components";

const methodTags = ["Methodology", "FV Multiple"];
const keyMetricsTags = ["Revenue", "EBIT incl SBC", "EPS - Diluted incl SBC", "EBIT Margin %"];
const valuationTags = ["Price Target", "Dividends", "Total Inc Dividends"];
const impliedMetricsTags = [
  "Implied P/E NTM",
  "Implied EV/EBITDA NTM",
  "Implied EV/Revenue NTM",
  "Implied FCF Yield",
];
const methodTable = {
  name: "Method",
  header: false,
  tags: methodTags,
};
const keyMetricsTable = {
  name: "Key Metrics",
  header: true,
  headerLabel: true,
  tags: keyMetricsTags,
  altTagsIdxs: [3],
};
const valuationTable = {
  name: "Valuation",
  header: false,
  tags: valuationTags,
  altTagsIdxs: [1, 2],
};
const impliedMetricsTable = {
  name: "Implied Metrics",
  header: false,
  tags: impliedMetricsTags,
  altTagsIdxs: [0, 1, 2, 3],
};
const scenariosTables = [keyMetricsTable, valuationTable, impliedMetricsTable];
const scenariosRowHeaders = [
  ["Revenue", "EBIT", "EPS", "EBIT Margin %"],
  ["Price Target", "Dividends", "Total Inc Dividends"],
  [
    "Implied P/E NTM incl SBC",
    "Implied EV/EBITDA incl SBC",
    "Implied EV/Revenue",
    "Implied FCF Yield",
  ],
];
const methodRowHeaders = [["Methodology", "FV Multiple"]];

const StyledHorizontalLine = styled.hr`
  border-block-color: #000000;
  width: 100%;
  margin-bottom: 20px;
`;

const CPP_Scenarios = () => {
  const tagLineItemsMap = useSelector(tagLineItemsMapSelector);
  const lineItems = useSelector(lineItemsSelector);
  const facts = useSelector(factsSelector);
  const modelInfo = useSelector(modelInfoSelector);
  const startPeriod = modelInfo?.startPeriod;

  const header: number[] = range(startPeriod + 1, startPeriod + 6, 1);
  const scenariosData = buildScenarios(
    scenariosTables,
    header,
    tagLineItemsMap,
    lineItems,
    facts,
    scenariosRowHeaders
  );

  const methodData = buildScenarios(
    [methodTable],
    [startPeriod + 1],
    tagLineItemsMap,
    lineItems,
    facts,
    methodRowHeaders
  );

  return (
    <>
      <H6>Scenario Base</H6>
      <div>
        <VSHTMLTable data={methodData.base.method} widths={cppTableWidths} />
      </div>
      <br />
      <VSHTMLTable data={scenariosData.base.keyMetrics} widths={cppTableWidths} />
      <br />
      <H6>&nbsp;&nbsp;&nbsp;Valuation</H6>
      <VSHTMLTable data={scenariosData.base.valuation} widths={cppTableWidths} />
      <br />
      <H6>&nbsp;&nbsp;&nbsp;Implied Metrics</H6>
      <VSHTMLTable data={scenariosData.base.impliedMetrics} widths={cppTableWidths} />
      <br />
      <br />
      <StyledHorizontalLine />
      <H6>Scenario Upside</H6>
      <div>
        <VSHTMLTable data={methodData.upside.method} widths={cppTableWidths} />
      </div>
      <br />
      <VSHTMLTable data={scenariosData.upside.keyMetrics} widths={cppTableWidths} />
      <br />
      <H6>&nbsp;&nbsp;&nbsp;Valuation</H6>
      <VSHTMLTable data={scenariosData.upside.valuation} widths={cppTableWidths} />
      <br />
      <H6>&nbsp;&nbsp;&nbsp;Implied Metrics</H6>
      <VSHTMLTable data={scenariosData.upside.impliedMetrics} widths={cppTableWidths} />
      <br />
      <br />
      <StyledHorizontalLine />
      <H6>Scenario Downside</H6>
      <div>
        <VSHTMLTable data={methodData.downside.method} widths={cppTableWidths} />
      </div>
      <br />
      <VSHTMLTable data={scenariosData.downside.keyMetrics} widths={cppTableWidths} />
      <br />
      <H6>&nbsp;&nbsp;&nbsp;Valuation</H6>
      <VSHTMLTable data={scenariosData.downside.valuation} widths={cppTableWidths} />
      <br />
      <H6>&nbsp;&nbsp;&nbsp;Implied Metrics</H6>
      <VSHTMLTable data={scenariosData.downside.impliedMetrics} widths={cppTableWidths} />
      <StyledHorizontalLine />
      <H6>Scenario Consensus</H6>
      <div>
        <VSHTMLTable data={methodData.consensus.method} widths={cppTableWidths} />
      </div>
      <br />
      <VSHTMLTable data={scenariosData.consensus.keyMetrics} widths={cppTableWidths} />
      <br />
      <H6>&nbsp;&nbsp;&nbsp;Valuation</H6>
      <VSHTMLTable data={scenariosData.consensus.valuation} widths={cppTableWidths} />
      <br />
      <H6>&nbsp;&nbsp;&nbsp;Implied Metrics</H6>
      <VSHTMLTable data={scenariosData.consensus.impliedMetrics} widths={cppTableWidths} />
    </>
  );
};

export default CPP_Scenarios;
