import * as Sentry from "@sentry/react";
import { call, cancel, put, select } from "redux-saga/effects";
import { Intent } from "@blueprintjs/core";

import { api } from "utils/api";
import {
  currentCaseDataSelector,
  currentCaseSelector,
  currentModuleSelector,
  lineItemsSelector,
  modelUidSelector,
  modulesSelector,
} from "selectors/modelSelectors";
import { multiToaster } from "utils/toaster";
import * as apiConsts from "constants/apiConstants";
import { updateEditMode, updateFocusedCell } from "actions/dataEntryUIActions";
import { selectionFromCell } from "utils/modelGrid";
import {
  editLineItem,
  editLineItemTagsFailure,
  editModuleNameFailure,
  editModuleNameSuccess,
  fetchKeyRatioDataFailure,
  fetchKeyRatioDataSuccess,
  fetchQuickCalculationsFailure,
  fetchQuickCalculationsSuccess,
  fetchUndoHistoryFailure,
  fetchUndoHistorySuccess,
  pullSourceFailure,
  pullSourceSuccess,
  updateCurrentModuleReducer,
} from "actions/dataEntryActions";
import { editModeSelector } from "selectors/dataEntryUISelectors";
import { DataEntryEditMode } from "constants/dataEntryUIConstants";
import { factsSelector } from "selectors/dataEntrySelectors";
import { fixFactFx } from "utils/data";

/**
 * Saga to fetch a case's undo history
 * */
export function* fetchUndoHistory(action) {
  try {
    const { caseID, modelID } = action.payload.params;
    const res = yield call(api, apiConsts.FETCH_UNDO_HISTORY_ROUTE, {
      method: apiConsts.EMethods.POST,
      body: {
        caseID,
        modelID,
      },
    });
    // eslint-disable-next-line no-console
    if (res.status === apiConsts.Status.SUCCESS) {
      yield put(fetchUndoHistorySuccess(res.data));
    } else {
      yield put(fetchUndoHistoryFailure());
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    Sentry.captureException(err);
  }
}

/**
 * Fetch the key ratio data
 * */
export function* fetchKeyRatioData(action) {
  try {
    const { statement, moduleUid } = action.payload;
    const currentCase = yield select(currentCaseSelector);
    const dcfModuleUid = currentCase.modules[0];
    const res = yield call(api, apiConsts.FETCH_KEY_RATIOS_ROUTE, {
      "module-id": dcfModuleUid,
      statement,
    });
    if (res.status !== "success") {
      yield put(fetchKeyRatioDataFailure(moduleUid));
    } else {
      yield put(fetchKeyRatioDataSuccess(res.data, moduleUid));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    Sentry.captureException(err);
  }
}

/**
 * Synchronously and safely updates the current module by ensuring the focused and selected cell
 * is always set to 0, 0.
 */
export function* updateCurrentModule(action) {
  try {
    let { moduleName } = action.payload;
    const modules = yield select(modulesSelector);
    const currentModule = yield select(currentModuleSelector);
    const currentCaseData = yield select(currentCaseDataSelector);

    // check if module name has changed or if the case has changed
    if (
      currentModule &&
      moduleName === currentModule.name &&
      currentModule.caseUid === currentCaseData.uid
    ) {
      yield cancel();
    }

    const editMode = yield select(editModeSelector);
    if (editMode === DataEntryEditMode.FORMULA) yield put(updateEditMode(DataEntryEditMode.NORMAL));

    if (!modules) yield cancel();

    const caseModules = Object.values(modules).filter((mod) => mod.caseUid === currentCaseData.uid);
    const nextCurrentModule = caseModules.find((mod) => mod.name === moduleName);

    if (!nextCurrentModule) {
      yield cancel();
    }
    // If there's not a nextCurrentModule, then we have just changed some metadata about the current
    // one. We can therefore safely skip actions related to loading a new module.
    if (nextCurrentModule) {
      yield put(updateCurrentModuleReducer(nextCurrentModule.uid));
      const cell = { col: 0, row: 0 };
      yield put(updateFocusedCell(cell, selectionFromCell(cell)));
    }
    // in the future this fetch should only be done if there's invalidation
    // yield put(fetchModuleDataAction());
    // Todo: this is also being done after fetching new module data to set formula in dataEntry.ui
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    Sentry.captureException(err);
  }
}

export function* editModuleName(action) {
  try {
    const modelID = yield select(modelUidSelector);
    const { module, newName } = action.payload;

    const res = yield call(api, apiConsts.EDIT_MODULE_NAME_ROUTE, {
      method: apiConsts.EMethods.POST,
      body: {
        name: newName,
        moduleID: module.uid,
        modelID,
      },
    });

    if (res.status === apiConsts.Status.SUCCESS) {
      yield put(editModuleNameSuccess(res.data));
    } else {
      multiToaster.show({
        message: `Unable to edit module name.`,
        intent: Intent.WARNING,
      });
      yield put(editModuleNameFailure(module));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    Sentry.captureException(err);
  }
}

export function* fetchQuickCalculations() {
  try {
    const currentCase = yield select(currentCaseSelector);
    const res = yield call(api, apiConsts.FETCH_QUICK_CALCULATIONS_ROUTE, {
      caseID: currentCase.uid,
    });

    if (res.status !== "success") {
      yield put(fetchQuickCalculationsFailure());
    } else {
      yield put(fetchQuickCalculationsSuccess(res.data.functions));
    }
  } catch (err) {
    yield put(fetchQuickCalculationsFailure());
    // eslint-disable-next-line no-console
    console.log(err);
    Sentry.captureException(err);
  }
}

export function* pullSource(action) {
  const { ticker, period } = action.payload;
  try {
    const res = yield call(api, apiConsts.PULL_SOURCE_ROUTE, {
      contentType: "text/html",
      ticker: ticker,
      period: period,
    });

    if (res.status !== "success") {
      yield put(pullSourceFailure(ticker + period));
    } else {
      const blob = new Blob([res.data], {
        type: "text/html;charset=utf-8",
      });
      yield put(pullSourceSuccess(ticker + period, URL.createObjectURL(blob)));
    }
  } catch (err) {
    yield put(pullSourceFailure(ticker + period));
    // eslint-disable-next-line no-console
    console.log(err);
    Sentry.captureException(err);
  }
}

// Adds internal formula with expanded and sorted ranges
export function* editFormulaPreFX(action) {
  const editedFacts = action.payload.params.facts;
  const currentCaseData = yield select(currentCaseDataSelector);
  const modules = yield select(modulesSelector);
  const lineItems = yield select(lineItemsSelector);
  const facts = yield select(factsSelector);

  const processedEditedFacts = [];
  const originalFacts = [];
  const firstFact = facts[editedFacts[0].uid];
  const module = modules[firstFact.moduleUid];

  editedFacts.forEach((ef) => {
    const originalFact = facts[ef.uid];
    const { identifier, period } = originalFact;
    originalFacts.push(originalFact);

    const newEf = {
      id: ef.uid,
      identifier,
      period,
      ...ef,
    };

    const fixedEf = fixFactFx(ef, facts, lineItems, modules, currentCaseData);
    if (fixedEf) {
      newEf.formula = fixedEf.formula;
      newEf.internalFormula = fixedEf.internalFormula;
    }

    processedEditedFacts.push(newEf);
    originalFacts.push(facts[ef.uid]);
  });

  action.payload.params.facts = processedEditedFacts;
  action.payload.params.forecastIncrement = module.forecastIncrement;
  action.payload.reverseParams.facts = originalFacts;
  return editedFacts;
}

/**
 * Send edit line item name if it was changed during creation (temp stage)
 */
export function* addLineItemSFX(data) {
  const lineItems = yield select(lineItemsSelector);
  const currentCaseData = yield select(currentCaseDataSelector);
  const moduleID = data.module.id;
  const li = data.module.edges.lineItems.find((li) => !lineItems[li.id]);

  const liTempId = "temp-" + moduleID + "-" + li.name;
  const newName = lineItems[liTempId].name;
  const oldName = li.name;
  if (newName !== oldName) {
    yield put(
      editLineItem(currentCaseData.uid, moduleID, [
        {
          uid: li.id,
          id: li.id,
          name: newName,
          oldName: oldName,
        },
      ])
    );
  }
}

export function* editLineItemTags(action) {
  try {
    const { lineItemID, modelID, tags, oldTags } = action.payload;
    const res = yield call(api, apiConsts.UPDATE_TAGS_ROUTE, {
      method: apiConsts.EMethods.POST,
      body: { modelID, tags, uid: lineItemID },
    });
    if (res.status !== apiConsts.Status.SUCCESS) {
      yield put(editLineItemTagsFailure(lineItemID, oldTags));
      multiToaster.show({
        message: "Unable to update tags!",
        intent: Intent.DANGER,
      });
    }
  } catch (err) {
    Sentry.captureException(err);
  }
}
