import { combineReducers } from "redux";
import dataEntryReducer from "./dataEntryReducer";
import dataEntryUIReducer from "./dataEntryUIReducer";

const dataEntryRootReducer = combineReducers({
  data: dataEntryReducer,
  ui: dataEntryUIReducer,
});

export default dataEntryRootReducer;
