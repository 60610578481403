import {
  AnchorButton,
  Button,
  Card,
  Classes,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogProps,
  FormGroup,
  Icon,
  Intent,
  Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import useListConfigs from "hooks/useListConfigs";
import React, { useContext, useState } from "react";
import { View as TView, View } from "types/view";
import { Flex } from "components/utility/StyledComponents";
import { multiToaster } from "utils/toaster";
import LoadingIndicator from "components/utility/LoadingIndicator";
import { Link } from "react-router-dom";

interface AddViewDialogProps extends DialogProps {
  addView: (view: View) => Promise<boolean>;
  updating: boolean;
}

const BLANK_VIEW = "Blank View";
const SCENARIO = "Scenario";

const AddViewDialog: React.FC<AddViewDialogProps> = ({ addView, updating, ...dialogProps }) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const [selectedView, setSelectedView] = useState<TView>();

  const handleAddView = async (event: React.SyntheticEvent<HTMLElement, Event>) => {
    if (selectedView) {
      const success = await addView(selectedView);
      if (success) {
        multiToaster.show({
          intent: Intent.SUCCESS,
          message: (
            <p>
              Successfully added View <strong>{selectedView.name}</strong>.
            </p>
          ),
        });
        if (dialogProps.onClose) dialogProps.onClose(event);
      }
    }
  };

  const { views, isLoading } = useListConfigs();

  const sortedViews = (views || [])
    .filter((view) => view.name !== BLANK_VIEW)
    .sort((a, b) => {
      if (a.name === BLANK_VIEW || b.name === BLANK_VIEW) {
        return -1;
      }
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });

  return (
    <Dialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      icon={IconNames.ADD}
      onClose={!updating ? dialogProps.onClose : undefined}
      title="Add View to Dashboard"
      {...dialogProps}
    >
      <DialogBody>
        <FormGroup label="Custom Views">
          {/*TODO: Re-add when we implement multiple persisted model scenarios
          <Card
            compact
            interactive={true}
            onClick={() =>
              setSelectedView({
                id: SCENARIO,
                name: SCENARIO,
                createdAt: "",
                createdBy: "",
              })
            }
            selected={selectedView?.id === SCENARIO}
            style={{ marginBottom: "10px" }}
          >
            <Icon icon={IconNames.LAYOUT_AUTO} style={{ marginRight: "10px" }} />
            Scenario
          </Card> */}
          <Card
            compact
            interactive={true}
            onClick={() => setSelectedView(views?.find((view) => view.name === BLANK_VIEW))}
            selected={selectedView?.name === BLANK_VIEW}
          >
            <Icon icon={IconNames.SQUARE} style={{ marginRight: "10px" }} />
            Blank View
          </Card>
        </FormGroup>

        <FormGroup label="Pre-built Views">
          {isLoading ? (
            <LoadingIndicator status={{ intent: Intent.PRIMARY, message: "Loading views" }} />
          ) : (
            <div style={{ padding: "2px", maxHeight: "300px", overflowY: "auto" }}>
              {sortedViews.map((view) => (
                <Card
                  key={view.id}
                  compact
                  interactive={true}
                  onClick={() => setSelectedView(view)}
                  style={{ marginBottom: "10px" }}
                  selected={view.id === selectedView?.id}
                >
                  <Flex justifyContent="flex-start" flexDirection="row">
                    <Flex alignItems="flex-start" flexDirection="column">
                      <Flex alignItems="flex-start" flexDirection="row">
                        <Icon
                          icon={IconNames.GRID_VIEW}
                          style={{ paddingTop: "1px", marginRight: "10px" }}
                        />
                        {view.name}
                      </Flex>
                      {view.description && (
                        <p
                          className={Classes.TEXT_MUTED}
                          style={{ marginTop: "5px", marginBottom: 0 }}
                        >
                          {view.description}
                        </p>
                      )}
                    </Flex>
                    <div style={{ marginLeft: "auto" }}>
                      <Tooltip content="Preview">
                        <Link target="_blank" to={`/settings/views/${view.id}`}>
                          <AnchorButton minimal icon={IconNames.SHARE} />
                        </Link>
                      </Tooltip>
                    </div>
                  </Flex>
                </Card>
              ))}
            </div>
          )}
        </FormGroup>
      </DialogBody>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={dialogProps.onClose} text="Cancel" />
          <Button
            disabled={!selectedView || selectedView.id === SCENARIO}
            intent={Intent.PRIMARY}
            loading={updating}
            onClick={handleAddView}
            text="Add View"
          />
        </div>
      </DialogFooter>
    </Dialog>
  );
};

export default AddViewDialog;
