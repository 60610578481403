import React from "react";
import { createRoot } from "react-dom/client";
import { Classes, Intent, OverlayToaster, Position, Spinner, SpinnerSize } from "@blueprintjs/core";
import { Flex } from "components/utility/StyledComponents";
import ColorableIcon from "components/utility/ColorableIcon";

export let multiToaster: OverlayToaster;
const elem = document.getElementById("toaster");
if (elem) {
  createRoot(elem).render(
    <OverlayToaster
      className={"toaster"}
      position={Position.TOP}
      ref={(instance) => {
        if (instance) multiToaster = instance;
      }}
    />
  );
}

export let singleToaster: OverlayToaster;
const singleToasterElem = document.getElementById("copy_toaster");
if (singleToasterElem) {
  createRoot(singleToasterElem).render(
    <OverlayToaster
      position={Position.TOP}
      maxToasts={1}
      ref={(instance) => {
        if (instance) singleToaster = instance;
      }}
    />
  );
}

export let socketToaster: OverlayToaster;
const socketToasterElem = document.getElementById("socket_toaster");
if (socketToasterElem) {
  createRoot(socketToasterElem).render(
    <OverlayToaster
      position={Position.TOP_LEFT}
      maxToasts={1}
      ref={(instance) => {
        if (instance) socketToaster = instance;
      }}
    />
  );
}

/**
 * A component to correctly position a Spinner in a toast. Used as the icon prop.
 */
interface IToasterSpinnerProps {
  intent?: Intent;
}
export const ToasterSpinner: React.FC<IToasterSpinnerProps> = ({
  intent = undefined,
  ...parentProps
}) => (
  <Flex className={Classes.INTENT_PRIMARY} fullHeight style={{ marginLeft: 11 }} {...parentProps}>
    <ColorableIcon intent={intent} icon={<Spinner size={SpinnerSize.SMALL} />} />
  </Flex>
);
