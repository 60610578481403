import { createSelector } from "reselect";

import {
  buildCellFormattingObject,
  cellCoordArrayFromSelectedRegions,
  getFactsFromCellCoordsArray,
} from "utils/modelGrid";
import { f } from "utils/formatter";
import { selectorObject } from "utils/formulas";
import {
  defaultFormatSelector,
  focusedCellCoordsSelector,
  selectedRegionsSelector,
} from "./dataEntryUISelectors";
import {
  caseModulesSelector,
  currentModuleSelector,
  modulePeriodsFormulaSelector,
} from "selectors/modelSelectors";
import { buildRows } from "../utils/modelGrid";

export const lineItems = (state) => state.model.lineItems;
export const modules = (state) => state.model.modules;
export const factsSelector = (state) => state.model.facts;
const committing = (state) => state.dataEntry.data.committing;
const keyRatiosSelector = (state) => state.dataEntry.data.keyRatios;

/**
 * Selector to determine if the app in currently in a committing or committed state.
 * */
export const isCommittingSelector = createSelector(
  [committing],
  (committingArray) => committingArray.length > 0
);

/**
 * Selector for the current data entry URL.
 *
 * If the user has visited the page before, we need to compute the
 * current module name and sort the URL.
 * current module name and sort the URL.
 *
 * If not, default to the DCF module.
 **/
export const dataEntryURLSelector = createSelector([currentModuleSelector], (module) => {
  if (!module?.name) {
    return "data/";
  } else {
    return `data/${module.name.replace(/ /g, "-")}`;
  }
});

/** Build the rows object to display data table */
export const rowsSelector = createSelector(
  [currentModuleSelector, lineItems, factsSelector, (state, config) => config],
  (currentModule, lineItems, facts, config) => {
    if (!currentModule || !facts) return;
    if (!config) config = { showErrors: undefined, rawValues: undefined };
    return buildRows(currentModule, lineItems, facts, config);
  },
  // 2 memos of rows: one for dataEntry with specific config and one for other selectors to consume
  { memoizeOptions: { maxSize: 2 } }
);

/**
 * Build the data structure with nodes and links for the diagram
 */
export const moduleDiagramSelector = createSelector([caseModulesSelector], (modules) => {
  if (!modules) return;
  let unlinked = false;
  const moduleList = Object.values(modules);
  const parsedModuleList = moduleList.map((module) => {
    if (module.unlinked) unlinked = true;
    if (module.childModules) {
      return {
        ...module,
        childModules: module.childModules.filter((mod) => !modules[mod].unlinked),
      };
    }
    return module;
  });

  const nodes = [];
  const links = [];
  const depthToTypeMap = {
    0: "DCF",
    1: "core",
    2: "common",
  };

  parsedModuleList.forEach((mod) => {
    nodes.push({
      id: mod.uid,
      type: !mod.unlinked ? depthToTypeMap[mod.depth] : depthToTypeMap[0],
      label: mod.name,
      children: mod.childModules,
      unlinked: mod.unlinked,
    });
    if (mod.childModules)
      mod.childModules.forEach((childMod) => {
        links.push({
          source: mod.uid,
          target: childMod,
          unlinked: moduleList.find((module) => module.uid === childMod).unlinked,
        });
      });
  });

  return {
    nodes: nodes,
    links: links,
    unlinked,
  };
});

export const focusedCellFactSelector = createSelector(
  [focusedCellCoordsSelector, rowsSelector],
  (cell, rows) => {
    if (!rows?.length || !cell) return {};
    const { col, row } = cell;
    let fact = rows?.[row]?.[col];
    if (!fact) return {};
    return { cell, fact };
  }
);

export const focusedCellSelector = createSelector(
  [focusedCellFactSelector, lineItems, currentModuleSelector],
  ({ cell, fact }, lineItems, currentModule) => {
    if (!fact) return;
    return {
      ...cell,
      focusSelectionIndex: 0,
      fact: {
        ...fact,
        parent: lineItems[fact.parentUid],
        module: currentModule,
      },
    };
  }
);

/**
 * Given the currently selected regions, return a subset of the module structure
 * that reflects those facts.
 *
 *  Data structure as follows:
 *
 *  [
 *    {
 *      ...lineItemFromSelectedRegion,
 *      facts: [...facts from selectedRegion],
 *    },
 *    {
 *      ...lineItemFromSelectedRegion,
 *      facts: [...facts from selectedRegion],
 *    }
 *  ]
 *
 * Operations can then be performed on them sequentially.
 * */
const selectedCellArraySelector = createSelector(
  [rowsSelector, selectedRegionsSelector],
  (rows, regions) => {
    if (rows.length && regions[0]) {
      return cellCoordArrayFromSelectedRegions(rows, regions);
    }
  }
);

/**
 * Returns the formatting of the currently active selection as
 * an array of lineItems and their attendent facts with their formatting options included.
 */
export const selectedRegionFactsSelector = createSelector(
  [rowsSelector, selectedCellArraySelector],
  (rows, rowArray) => {
    if (!rows.length || !rowArray) return;
    return getFactsFromCellCoordsArray(rows, rowArray);
  }
);

/** Returns an object with the selector(s) for a given table region */
export const selectedRegionsObjectSelector = createSelector(
  [selectedRegionsSelector, currentModuleSelector, rowsSelector, modulePeriodsFormulaSelector],
  (selectedRegions, currentModule, rows, formulaPeriods) => {
    if (!formulaPeriods || !rows?.length) return;
    const { rows: selRows, cols: selCols } = selectedRegions[0];
    const selectedRegionsObject = selectorObject(
      currentModule.name,
      selRows ? rows?.[selRows[0]]?.rowHeader : 0,
      selCols ? formulaPeriods[selCols[0]] : 0
    );
    if (!selRows) selectedRegionsObject.endLineItem = rows.length;
    else if (selRows[0] !== selRows[1])
      selectedRegionsObject.endLineItem = rows?.[selRows[1]].rowHeader;
    if (!selCols) selectedRegionsObject.endPeriod = formulaPeriods.length;
    else if (selCols[0] !== selCols[1])
      selectedRegionsObject.endPeriod = formulaPeriods[selCols[1]];
    return selectedRegionsObject;
  }
);

export const currentKeyRatiosSelector = createSelector(
  [currentModuleSelector, defaultFormatSelector, keyRatiosSelector],
  (currentModule, defaultFormats, keyRatios) => {
    if (currentModule && keyRatios?.[currentModule.uid]) {
      if (!keyRatios[currentModule.uid].lineItems) return keyRatios[currentModule.uid];
      return {
        ...keyRatios[currentModule.uid],
        lineItems: keyRatios[currentModule.uid].lineItems.map((lineItem) => {
          const formats = buildCellFormattingObject(lineItem.format, defaultFormats);
          return {
            ...lineItem,
            facts: lineItem.facts
              .sort((a, b) => a.period - b.period)
              .map((fact) => {
                return { ...fact, value: f({ ...fact, ...formats }) };
              }),
          };
        }),
        periods: keyRatios[currentModule.uid].lineItems[0].facts
          .map((f) => f.period)
          .sort((a, b) => a - b),
      };
    }
  }
);
export const currentStatementSelector = createSelector([currentModuleSelector], (currentModule) => {
  // TODO - Limit this to recognised statements?
  // const STATEMENTS = ["DCF", "Balance Sheet", "Income Statement", "Cash Flow Statement"];
  // if (STATEMENTS.indexOf(currentModule?.name) !== -1) {
  return currentModule?.name;
  // }
});
