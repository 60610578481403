// // TYPE (PANEL 1)
export const SET_VALUATION_TYPE = "SET_VALUATION_TYPE";

// COMPANY (PANEL 2)
export const UPDATE_COMPANY_SELECT_ACTIVE_ITEM = "UPDATE_COMPANY_SELECT_ACTIVE_ITEM";
export const UPDATE_COMPANY_TYPE = "UPDATE_COMPANY_TYPE";
export const UPDATE_GEOGRAPHY = "UPDATE_GEOGRAPHY";
export const UPDATE_INDUSTRY = "UPDATE_INDUSTRY";
export const UPDATE_SELECTED_COMPANY = "UPDATE_TICKER";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const UPDATE_UNIT = "UPDATE_UNIT";
export const UPDATE_COMPANY_NAME = "UPDATE_NEW_COMPANY_NAME";
export const UPDATE_COMPANY_TICKER = "UPDATE_NEW_COMPANY_TICKER";

// CONFIGURATION (PANEL 3)
export const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";
export const UPDATE_TERMINAL_VALUATION_TYPE = "UPDATE_TERMINAL_VALUATION_TYPE";
export const UPDATE_EXIT_MULTIPLE_TYPE = "UPDATE_EXIT_MULTIPLE_TYPE";
export const UPDATE_EXIT_MULTIPLE_TARGET = "UPDATE_EXIT_MULTIPLE_TARGET";
export const TOGGLE_MARGIN_OF_SAFETY = "TOGGLE_MARGIN_OF_SAFETY";
export const UPDATE_MARGIN_OF_SAFETY = "UPDATE_MARGIN_OF_SAFETY";

// TIME (PANEL 4)
export const UPDATE_HISTORICALS = "UPDATE_HISTORICALS";
export const UPDATE_FORECASTS = "UPDATE_FORECASTS";
export const UPDATE_START_DATE = "UPDATE_START_DATE";
export const UPDATE_START_PERIOD = "UPDATE_START_PERIOD";
export const UPDATE_PERIOD_TYPE = "UPDATE_PERIOD_TYPE";

// FETCH
export const FETCH_USER_MODEL_TEMPLATES_SUCCESS = "FETCH_USER_MODEL_TEMPLATES_SUCCESS";
export const FETCH_LOGO = "FETCH_LOGO";

// UTILITY
export const TICKER = "TICKER";
export const INDUSTRY = "INDUSTRY";
export const CURRENCY = "CURRENCY";
export const GEOGRAPHY = "GEOGRAPHY";
export const TEMPLATE = "TEMPLATE";
export const UPDATE_ADVANCED_CONTROLS_ACTIVE = "UPDATE_ADVANCED_CONTROLS_ACTIVE";

// CONSTANT OPTIONS

export const EXIT_MULTIPLE_TYPES = ["EV/EBITDA", "EV/EBIT", "P/E", "P/S", "P/BV", "EV/Sales"];

// CREATE
export const CREATE_MODEL = "CREATE_MODEL";
export const CREATE_MODEL_SUCCESS = "CREATE_MODEL_SUCCESS";
export const CREATE_MODEL_FAILURE = "CREATE_MODEL_FAILURE";

// TICKERS
export const FETCH_AVAILABLE_TICKERS = "FETCH_AVAILABLE_TICKERS";
export const FETCH_AVAILABLE_TICKERS_SUCCESS = "FETCH_AVAILABLE_TICKERS_SUCCESS";
export const FETCH_AVAILABLE_TICKERS_FAILURE = "FETCH_AVAILABLE_TICKERS_FAILURE";

// MODEL TEMPLATES
export const FETCH_MODEL_TEMPLATES = "FETCH_MODEL_TEMPLATES";
export const FETCH_MODEL_TEMPLATES_SUCCESS = "FETCH_MODEL_TEMPLATES_SUCCESS";
export const FETCH_MODEL_TEMPLATES_FAILURE = "FETCH_MODEL_TEMPLATES_FAILURE";
export const ADD_TEMPLATE_VARIABLE = "ADD_TEMPLATE_VARIABLE";
export const REMOVE_TEMPLATE_VARIABLE = "REMOVE_TEMPLATE_VARIABLE";
export const EDIT_TEMPLATE_VARIABLE = "EDIT_TEMPLATE_VARIABLE";

// CONFIGURATION OPTIONS
export const FETCH_CONFIG_ITEM_OPTIONS = "FETCH_CONFIG_ITEM_OPTIONS";
export const FETCH_CONFIG_ITEM_OPTIONS_SUCCESS = "FETCH_CONFIG_ITEM_OPTIONS_SUCCESS";
export const FETCH_CONFIG_ITEM_OPTIONS_FAILURE = "FETCH_CONFIG_ITEM_OPTIONS_FAILURE";
export const FETCH_ALL_CONFIG_OPTIONS = "FETCH_ALL_CONFIG_OPTIONS";

// COMPANY CONFIGURATION
export const FETCH_COMPANY_CONFIG_OPTIONS = "FETCH_COMPANY_CONFIG_OPTIONS";
export const FETCH_COMPANY_CONFIG_OPTIONS_SUCCESS = "FETCH_COMPANY_CONFIG_OPTIONS_SUCCESS";
export const FETCH_COMPANY_CONFIG_OPTIONS_FAILURE = "FETCH_COMPANY_CONFIG_OPTIONS_FAILURE";

// UTILITY
export const FLUSH_CONFIG = "FLUSH_CONFIG"; // Flush the current config
export const FLUSH_CREATE = "FLUSH_CREATE"; // Flush the entire reducer

export const ADD_FILTER = "ADD_FILTER";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const CLEAR_FILTER = "CLEAR_FILTER";

export const UPDATE_BUILDING_STATUS = "UPDATE_BUILDING_STATUS";

export const FilterTypes = {
  INDUSTRY: "INDUSTRY",
  GEOGRAPHY: "GEOGRAPHY",
};

export const BuildStates = {
  BUILDING: "building",
  COMPLETED: "completed",
};

export const PeriodTypes = {
  Annual: "Annual",
  Quarterly: "Quarterly",
};

export const CASH_FLOW_TYPES = [
  {
    value: "FCFF",
    label: "Free Cash Flow to Firm",
  },
  {
    value: "FCFE",
    label: "Free Cash Flow to Equity",
  },
];

export const TV_CALC_TYPES = {
  PERPETUAL_GROWTH: "Perpetual Growth",
  EXIT_MULTIPLE: "Exit Multiple",
};

export const TARGET_VARIABLES = {
  IMPLIED_SHARE_PRICE: {
    value: "Implied share price",
    label: "Implied Share Price",
  },
  IMPLIED_EQUITY_VALUE: {
    value: "Implied Equity Value",
    label: "Implied Equity Value",
  },
  IMPLIED_ENTERPRISE_VALUE: {
    value: "Implied Enterprise Value",
    label: "Implied Enterprise Value",
  },
};
