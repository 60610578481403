import { formulaPeriodToNumber, periodFormatter } from "./formatter";
import { DataEntryEditMode } from "../constants/dataEntryUIConstants";

/**
 * A VS formula simple or range variable
 * @typedef {Object} VSVariable
 * @property {string} module - The module name
 * @property {string} lineItem - The line item name
 * @property {string} period - The period
 * @property {string} endLineItem - The line item name of the second variable if it's a range
 * @property {string} endPeriod - The period of the second variable if it's a range
 */

/**
 * @category Formula
 * @enum {string}
 * @desc All functions available in VS formulas
 * @example "SUM"
 */
export const vsModeFunctions = [
  "ADD_TO_DATE",
  "AVERAGE",
  "DATA",
  "DIV",
  "INTERPERCENT",
  "INTERNAL_DB",
  "IRR",
  "MULTI_VIRR",
  "VIRR",
  "LINREG",
  "MAX",
  "MEDIAN",
  "MIN",
  "MULT",
  "PERSIST",
  "PRICE",
  "SUBTRACT",
  "SUM",
];

export const vsKeywords = {
  IF: "IF",
  ELSE: "ELSE",
  LATEST: "@latest",
  AND: "AND",
  OR: "OR",
};

/**
 * @category Formula
 * @enum {string}
 * @desc Generic time series tokens
 * @example "HISTORICAL_START"
 */
export const vsFxPeriodKeywords = [
  "HISTORICAL_START",
  "HISTORICAL_END",
  "FORECAST_START",
  "FORECAST_END",
];

/**
 * @category Formula
 * @enum {string}
 * @desc All tokens in VS formulas
 * @example "function"
 */
export const vsTokens = {
  Anchor: "keyword.operator.anchor",
  Conditional: "keyword.conditional",
  ComparisonOperator: "keyword.operator.cond",
  Dot: "keyword.operator.dot",
  DQuote: "punctuation.double_quote",
  Empty: "empty",
  FormulaAnchor: "keyword.fx_anchor",
  Function: "function",
  LeftBrace: "paren.lbrace",
  LeftBracket: "paren.lbrac",
  LeftParenthesis: "paren.lcurl",
  LineItem: "variable.lineItem",
  LogicOperator: "keyword.operator.logic",
  Module: "variable.module",
  Numeric: "constant.numeric",
  Operator: "keyword.operator",
  Other: "constant.other",
  Period: "variable.period",
  Punctuation: "punctuation.operator",
  RightBrace: "paren.rbrace",
  RightBracket: "paren.rbrac",
  RightParenthesis: "paren.rcurl",
  RangeOperator: "keyword.operator.range",
  SelectorLeftBracket: "paren.selectlbrac",
  SelectorRightBracket: "paren.selectrbrac",
  SpecialDate: "sdate",
  String: "string",
  Text: "text",
};

export const INTERPRETER_ERRORS = {
  InvalidToken: "Invalid token",

  // ADD_TO_DATE
  AddToDateExpectedArgs:
    "Expected 4 or 5 arguments for function ADD_TO_DATE: (date, years, months, days, end_of_month?)",
  AddToDateFirstArg:
    "Invalid ADD_TO_DATE first argument: expected reference or flag of format 'date=mm/dd/yy'",
  AddToDateSecondArg: "Invalid ADD_TO_DATE second argument: expected flag of format 'years=[NUM]",
  AddToDateThirdArg: "Invalid ADD_TO_DATE third argument: expected flag of format 'months=[NUM]",
  AddToDateFourthArg: "Invalid ADD_TO_DATE fourth argument: expected flag of format 'days=[NUM]",
  AddToDateFifthArg:
    "Invalid ADD_TO_DATE fifth argument: expected flag of format 'end_of_month=[BOOLEAN]",

  // CONDITIONAL
  CondMissingIf: "Missing IF condition (...)",
  CondInvalidIf: "Invalid IF condition (...)",
  CondMissingIfExp: "Missing IF expression {...}",
  CondInvalidIfExp: "Invalid IF expression {...}",
  CondEmptyIfExp: "Invalid empty IF expression {}",
  CondMissingElseExp: "Missing ELSE expression {...}",
  CondInvalidElseExp: "Invalid ELSE expression {...}",
  CondEmptyElseExp: "Invalid empty ELSE expression {}",
  CondElseExpected: "ELSE clause expected",
  CondInvalidCompScope: "Invalid comparison outside IF condition",

  // DATASOURCE
  DataSourceExpectedArgs:
    "Expected 4 arguments for function DATASOURCE: (ticker, datasource, identifier, period)",
  DataSourceFirstArg: "Invalid DATA first argument: Expected a valid company ticker",
  DataSourceFirstArgDQ: "Invalid DATA first argument: Expected a string in double quotes",
  DataSourceSecondArg: "Invalid DATA second argument: Expected a valid data source",
  DataSourceSecondArgDQ: "Invalid DATA second argument: Expected a string in double quotes",
  DataSourceThirdArg: "Invalid DATA second argument: Expected a valid identifier",
  DataSourceThirdArgDQ: "Invalid DATA third argument: Expected a string in double quotes",
  DataSourceFourthArg: "Invalid DATA fourth argument: Expected a valid period or @latest",

  // INTERNAL DB
  IDBExpectedArgs:
    "Expected 4 or 5 arguments for function INTERNAL_DB: (ticker, datasource, identifier, period, flag?)",
  IDBFirstArg: "Invalid INTERNAL_DB first argument: Expected a valid company ticker",
  IDBFirstArgDQ: "Invalid INTERNAL_DB first argument: Expected a string in double quotes",
  IDBSecondArgDQ: "Invalid INTERNAL_DB second argument: Expected a string in double quotes",
  IDBThirdArg: "Invalid INTERNAL_DB second argument: Expected a valid identifier",
  IDBThirdArgDQ: "Invalid INTERNAL_DB third argument: Expected a string in double quotes",
  IDBFourthArg: "Invalid INTERNAL_DB fourth argument: Expected a valid period or @latest",
  IDBFifthArg: 'Invalid INTERNAL_DB fifth argument: Expected updates=[BOOLEAN] or case="[CASE]"',

  // FUNCTION
  FuncMissingParam: "Expected token after ','",
  FuncInvalidParam: "Invalid token after ','",
  FuncMissingArgs: "Missing arguments for function ",
  FuncMissingArg: "Missing function argument",

  // INTERPERCENT
  InterpercentArgs:
    "Expected 5 arguments for function INTERPERCENT: (past year, past year value, target year, final year, value to converge on)",
  InterpercentFirstArg: "Invalid INTERPERCENT first argument: Expected a period.",
  InterpercentThirdArg: "Invalid INTERPERCENT third argument: Expected a period.",
  InterpercentFourthArg: "Invalid INTERPERCENT fourth argument: Expected a period.",
  InterpercentPastTargetPeriods:
    "INTERPERCENT past period must be less or equal than target period",
  InterpercentPastFinalPeriods:
    "INTERPERCENT target period must be less or equal than final period",
  InterpercentTargetFinalPeriods: "INTERPERCENT past period must be less than target period",

  // LINREG
  LinRegExpectedArgs:
    "Expected 2 or 3 arguments for function LINREG: (range, preditction target period, optional outlier detection boolean)",
  LinRegFirstArg: "Invalid LINREG first argument. Expected a range.",
  LinRegRange: "Invalid LINREG range module",
  LinRegSecondArg: "Invalid LINREG second argument. Expected period",
  LinRegPeriod: "Invalid LINREG second argument. Period must be outside module range.",
  LinRegOutliers: "Invalid LINREG third argument. Expected detect_outliers=[BOOLEAN].",

  // MULTIVIRR
  MultiVIRRExpectedArgs:
    "Expected 6 arguments for function MULTI_VIRR: (current date cell, initial date cell, initial cash outflow cell, forecast date range, intermediate cash inflow range, final cash inflow range)",
  MultiVIRRFirstArg: "Invalid MULTI_VIRR first argument: Expected a selector.",
  MultiVIRRSecondArg: "Invalid MULTI_VIRR second argument: Expected a selector.",
  MultiVIRRThirdArg: "Invalid MULTI_VIRR third argument: Expected a selector.",
  MultiVIRRFourthArg: "Invalid MULTI_VIRR fourth argument: Expected a range.",
  MultiVIRRFifthArg: "Invalid MULTI_VIRR fifth argument: Expected a range.",
  MultiVIRRSixthArg: "Invalid MULTI_VIRR sixth argument: Expected a range.",

  // NUMERIC
  NumericContext: "Numeric constant must come after an operator or be an argument of a function",

  // OPERATORS
  CompOpInvalidLeftSide: "Invalid comparison left side ",
  CompOpInvalidRightSide: "Invalid comparison right side ",
  InvalidCompSeq: "Invalid sequence of comparisons",
  CondParen: "Conditions need to be inside parenthesis ",
  MissingArgAfterOp: "Missing argument after operator ",
  InvalidOpLeft: "Invalid left side of operator ",
  InvalidOpRight: "Invalid right side of operator ",
  InvalidDotOp: "Invalid operator '.'",
  InvalidOp: "Invalid operator ",
  InvalidPuncOp: "Invalid punctuation operator ",
  LogicOpExpectedTokens: "Logic operators needs a valid expression before and after",
  LogicOpScope: "Logic operator can only be used in a condition scope",
  LogicOpScopeDepth: "Logic operator conditions must be inside parenthesis",
  LogicOpInvalidLeftSide: "Logic operator left side must be an expression",
  LogicOpInvalidRightSide: "Logic operator right side must be an expression",

  // PARENTHESIS
  MissingParenthesis: "Missing closing ')'",
  TooManyParenthesis: "Unexpected character ')'",
  MissingBrackets: "Missing closing ']'",
  TooManyBrackets: "Unexpected character '['",
  ParenMissingContent: "Expected token after '('",
  ParenInvalidContent: "Invalid token after '('",
  ParenCloseInvalidFollow: "Invalid token after ')'",

  // PERSIST
  PersistArgs: "Expected 1 argument for function PERSIST",

  // PRICE
  PriceExpectedArgs: "Expected 2 arguments for function PRICE: (ticker, date string)",
  PriceFirstArg: "Invalid PRICE first argument: Expected a valid company ticker",
  PriceFirstArgDQ: "Invalid PRICE first argument: Expected a string in double quotes",
  PriceSecondArg:
    "Invalid PRICE second argument: Expected date string in format dd/mm/yyyy or @latest",

  // PUNCTUATION
  CommaOutsideFunction: "Can't have arguments outside function scope",

  // RANGE
  RangeOperatorBefore: "Range operator must follow a selector",
  RangeOperatorAfter: "Range operator must be followed by a selector",
  HorizontalRangePeriod: "Invalid range: Range across line item requires the period to be the same",
  RangePeriodOrder: "Invalid range: Second period must be equal or after the first",
  RangeOutsideFunction: "Can't have range outside function scope",

  // VARIABLE
  MissingModuleName: "Expected valid module name after [",
  MissingLineItem: "Expected valid line item name",
  MissingLineItemName: "Expected line item name after [",
  MissingPeriod: "Expected valid period",
  MissingVarOpen: "Missing variable opening bracket [",
  MissingVarClose: "Missing variable closing bracket ]",
  VariableContext: "Variable should come after an operator or as a function argument",

  // VIRR
  VIRRExpectedArgs: "Expected 2 arguments for function VIRR: (range, range)",
  VIRRFirstArg: "Invalid VIRR first argument: Expected a range.",
  VIRRSecondArg: "Invalid VIRR second argument: Expected a range.",

  // STRING
  InvalidId: "Invalid identifier ",
};

// REGULAR EXPRESSIONS
// Raw
export const vsReferenceIdentifierRegex = "[^\\[\\]]+";
export const vsPeriodRegex = "\\$?\\d{4}(Q[1-4])?";
export const vsSimPeriodRegex = "\\$?LFY([+-]\\d\\d?)?";
export const vsVariableRegex =
  "\\[" +
  vsReferenceIdentifierRegex +
  "\\[" +
  vsReferenceIdentifierRegex +
  "\\[" +
  vsPeriodRegex +
  "\\]\\]\\]";
const vsVariableRegExp = new RegExp(vsVariableRegex, "g");
export const vsSimVariableRegex =
  "\\[" + vsReferenceIdentifierRegex + "\\[" + vsSimPeriodRegex + "\\]\\]";
export const vsRangeRegex = vsVariableRegex + ":" + vsVariableRegex;
export const vsFullVarRegex = vsVariableRegex + "(:" + vsVariableRegex + ")?";
export const vsVariableNotInRangeRegex = "(?<!:)" + vsVariableRegex + "(?!:)";
export const flagParamRegex = '\\w[\\w\\s]*=("?[\\w\\s\\d\\/]+"?|' + vsVariableRegex + ")";
export const stringRegex = "[\\w\\W\\s]+";
export const dqStringRegex = '"' + stringRegex + '"';

export const caseFlagRegex = 'case="[\\w\\s]+"';
export const updatesFlagRegex = "updates\\s?=\\s?(true)|(false)";
export const dateFlagRegex = "date\\s?=\\s?\\d\\d\\/\\d\\d\\/\\d\\d";
export const dateSelFlagRegex = "date\\s?=\\s?" + vsVariableRegex;
export const yearsFlagRegex = "years\\s?=\\s?-?[\\d]+";
export const monthsFlagRegex = "months\\s?=\\s?-?[\\d]+";
export const daysFlagRegex = "days\\s?=\\s?-?[\\d]+";
export const endOfMonthFlagRegex = "end_of_month\\s?=\\s?(true)|(false)";

// Ad hoc use regular expressions
export const vsValidPeriodRegex = new RegExp("^" + vsPeriodRegex + "$", "g");
export const vsValidDQStringRegex = new RegExp("^" + dqStringRegex + "$", "g");

/**
 * @category Formula
 * @desc Takes and array of tokens and returns a new array that has index and start index for all tokens
 * @param tokens
 * @returns {Array} tokens
 */
export function indexTokens(tokens) {
  const newTokens = [];
  let tokenIndex = 0;
  tokens.forEach((token, i) => {
    if (!token) return;
    // clean empty tokens (ie remove irrelevant blank spaces)
    if (token.type !== "empty") {
      // add start indexes to allow error markers
      token.start = tokenIndex;
      newTokens.push(token);
    }
    // redo token indexes
    token.index = i;
    tokenIndex += token.value.length;
  });
  return newTokens;
}

/*
mutates tokens but not the original tokens array
  cleans empty tokens and fixes start and index for all tokens
  adds cursor token index to first token
  adds args array to function tokens args
  index: ignores empties
  start: tracks original indexes (considering empties/blanks)
  return: array of tokens annotated, without empties
*/
export function annotateFormulaTokens(tokens, cursorToken) {
  const newTokens = [];
  const funcTokensIdxs = [];
  let cursorTokenIdx;

  let idx = 0;
  let startIdx = 0;

  tokens.forEach((token, i) => {
    if (token) {
      if (cursorToken) {
        if (cursorToken === token) {
          if (token.type !== vsTokens.Empty) cursorTokenIdx = idx;
          else if (tokens[i - 1]) cursorTokenIdx = idx - 1;
        }
      }

      // TODO: remove empties from original tokens array to avoid having to filter in interpreter/autocompelter
      if (token.type !== vsTokens.Empty) {
        token.vsIdx = idx; // index can be overridden by ace
        token.start = startIdx;
        newTokens.push(token);
        if (token.type === vsTokens.Function) funcTokensIdxs.push(idx);
        idx += 1;
      }
      // startIdx still needs to consider empty spaces
      startIdx += token.value.length;
    }
  });

  // annotate function arguments (or error) in function token
  funcTokensIdxs.forEach((idx) => {
    getFunctionArguments(idx, newTokens);
  });

  if (cursorTokenIdx) {
    newTokens[0].cursorTokenIdx = cursorTokenIdx;
  }
  if (tokens[0]) tokens[0].annotated = true; // in case first token was empty
  return newTokens;
}

/** Given all selector parameters returns a selector string */
export const selectorToString = function (module, lineItem, period, endLineItem, endPeriod) {
  let result = "[" + module + "[" + lineItem + "[" + period + "]]]";
  if (endLineItem && endPeriod)
    result += ":[" + module + "[" + endLineItem + "[" + endPeriod + "]]]";
  return result;
};

/**
 * Given a VSVariable object returns a VSVariable string
 * @param {VSVariable} selObj - selector object
 * @returns {string}
 */
export function selectorObjToString(selObj) {
  let result = "[" + selObj.module + "[" + selObj.lineItem + "[" + selObj.period + "]]]";
  if (
    (selObj.endLineItem && selObj.endLineItem !== selObj.lineItem) ||
    (selObj.endPeriod && selObj.endPeriod !== selObj.period)
  ) {
    const endPeriod = selObj.endPeriod ? selObj.endPeriod : selObj.period;
    const endLineItem = selObj.endLineItem ? selObj.endLineItem : selObj.lineItem;
    result += ":[" + selObj.module + "[" + endLineItem + "[" + endPeriod + "]]]";
  }
  return result;
}

/** Converts a selector string into a selector object */
export const stringToSelector = function (selector, cleanAnchors = true) {
  if (!selector) return;
  const split = selector.split("[");
  const module = split[1];
  let lineItem = cleanAnchors ? cleanAnchor(split[2]) : split[2];
  let period = cleanAnchors ? cleanAnchor(split[3]) : split[3];
  period = period ? period.substring(0, period.indexOf("]")) : null;
  const endLineItem = cleanAnchors ? cleanAnchor(split[5]) : split[5];
  let endPeriod = cleanAnchors ? cleanAnchor(split[6]) : split[6];
  endPeriod = endPeriod ? endPeriod.substring(0, endPeriod.indexOf("]")) : null;
  if (module && lineItem && period)
    return selectorObject(module, lineItem, period, endLineItem, endPeriod);
};

export const cleanAnchor = (str) => {
  if (str?.[0] === "$") return str.slice(1);
  else return str;
};

/** Returns a selector object given all selector parameters */
export const selectorObject = function (module, lineItem, period, endLineItem, endPeriod) {
  const selectorObj = {
    module: module,
    lineItem: lineItem,
    period: period,
  };
  if (endLineItem) selectorObj.endLineItem = endLineItem;
  if (endPeriod) selectorObj.endPeriod = endPeriod;
  return selectorObj;
};

/**
 * Returns true if the two selectors are the same
 * @param {VSVariable} sel1
 * @param {VSVariable} sel2
 * @returns {boolean}
 */
export const isEqualSelector = function (sel1, sel2) {
  return (
    sel1.endLineItem === sel2.endLineItem &&
    sel1.endPeriod === sel2.endPeriod &&
    sel1.module === sel2.module &&
    sel1.lineItem === sel2.lineItem &&
    sel1.period === sel2.period
  );
};

/** Transforms a selector into table coordinates */
export const selectorToTableCoords = function (selector, columnHeaders, rowHeaders) {
  let result = {};
  const colStart = columnHeaders
    .map((header) => header.substring(0, header.length - 1))
    .indexOf(selector.period);
  if (colStart < 0) return;
  const colEnd = selector.endPeriod
    ? columnHeaders
        .map((header) => header.substring(0, header.length - 1))
        .indexOf(selector.endPeriod)
    : colStart;
  result.columns = [colStart, colEnd];

  const rowStart = rowHeaders.indexOf(selector.lineItem);
  if (rowStart < 0) return;
  const rowEnd = selector.endLineItem ? rowHeaders.indexOf(selector.endLineItem) : rowStart;
  result.rows = [rowStart, rowEnd];
  return result;
};

/**
 * Determines whether a formula is a single constant value.
 * Achieves this by trying to coerce it to a number without cleaning the string first.
 * @param {String} formula
 * @returns {boolean}
 */
export function isConstant(formula) {
  return !Number.isNaN(Number(formula));
}

/**
 * Changes formula lineItem/period according to row/col shift
 * @returns {String} - the changed formula
 */
export function shiftFormula(formula, rowShift, colShift, entities) {
  const { modules, lineItems, facts } = entities;
  return formula.replace(new RegExp(vsVariableRegex, "g"), (selector) => {
    const sel = stringToSelector(selector, false);

    const modulesValues = Object.values(modules);
    const originalModule = modulesValues.find((mod) => mod.name === sel.module);

    let lineItem;
    let originalLineItem;
    if (sel.lineItem[0] === "$") {
      originalLineItem =
        lineItems[
          originalModule.lineItems.find(
            (liUid) => lineItems[liUid].name === sel.lineItem.substring(1)
          )
        ];
      lineItem = { ...originalLineItem };
      lineItem.name = "$" + lineItem.name;
    } else {
      originalLineItem =
        lineItems[originalModule.lineItems.find((liUid) => lineItems[liUid].name === sel.lineItem)];
      const newOrder = originalLineItem.order + rowShift;
      if (newOrder < 1 || newOrder > originalModule.lineItems.length) return "#REF";
      lineItem =
        lineItems[originalModule.lineItems.find((liUid) => lineItems[liUid].order === newOrder)];
    }

    let period;
    if ("" + sel.period[0] === "$") period = sel.period;
    else {
      period = formulaPeriodToNumber(sel.period);
      const originalFact =
        facts[originalLineItem.facts.find((factUid) => facts[factUid].period === period)];
      const newFactIndex =
        (originalFact.period - originalModule.moduleStart) *
          (1 / originalModule.forecastIncrement) +
        colShift;
      if (newFactIndex < 0 || newFactIndex >= lineItem.facts.length) return "#REF";
      const fact =
        facts[
          lineItem.facts.find((factUid) => {
            return (
              facts[factUid].period - originalModule.moduleStart ===
              newFactIndex * originalModule.forecastIncrement
            );
          })
        ];
      period = periodFormatter(fact.period, null, originalModule.forecastIncrement);
    }
    return selectorToString(originalModule.name, lineItem.name, period);
  });
}

/**
 * Horizontal shift of period in selector in sim formulas of format [item[LFY+/-X]]
 * @param {string} fx
 * @param {number} shift
 * @returns {string}
 */
export function shiftSimFx(fx, shift) {
  return fx.replace(new RegExp(vsSimVariableRegex, "g"), (sel) => {
    return sel.replace(new RegExp(vsSimPeriodRegex, "g"), (period) => {
      if (period[0] === "$") return period;
      let idx = Number(period.replace("LFY", ""));
      idx += shift;
      if (idx === 0) return "LFY";
      if (idx < 0) return "LFY" + idx;
      if (idx > 0) return "LFY+" + idx;
    });
  });
}

/**
 * Refactors a formula's VSVariables module and lineItem names
 *
 * @param {string} formula - The formula to be edited
 * @param {string} moduleName
 * @param {string} lineItemName
 * @param {string} newModuleName
 * @param {string} newLineItemName
 * */
export function refactorFormulaVariables(
  formula,
  moduleName,
  lineItemName,
  newModuleName,
  newLineItemName
) {
  return formula.replace(vsVariableRegExp, (varString) => {
    const selector = stringToSelector(varString);

    if (newModuleName && selector.module === moduleName) selector.module = newModuleName;
    if (newLineItemName && moduleName === selector.module && lineItemName === selector.lineItem)
      selector.lineItem = newLineItemName;

    return selectorObjToString(selector);
  });
}

/**
 * Mutates line item facts dependant facts formula and original formula on module and/or line item name change
 * @param lineItem
 * @param facts
 * @param moduleName
 * @param newModuleName
 * @param newLineItemName
 */
export function refactorLineItemDeps(lineItem, facts, moduleName, newModuleName, newLineItemName) {
  lineItem.facts.forEach((factUid) => {
    const fact = facts[factUid];
    if (fact.uid.indexOf("temp") !== -1) return;
    fact.identifier = fact.identifier.replaceAll(lineItem.name, newLineItemName);
    fact.formula = refactorFormulaVariables(
      fact.formula,
      moduleName,
      lineItem.name,
      newModuleName,
      newLineItemName
    );
    if (fact.dependantCells) {
      fact.dependantCells.forEach((dep) => {
        const depFact = facts[dep.uid];
        depFact.formula = refactorFormulaVariables(
          depFact.formula,
          moduleName,
          lineItem.name,
          newModuleName,
          newLineItemName
        );
      });
    }
  });
}

/**
 * Starting at a token goes back and forth the list of tokens to figure out if token is part of a valid variable in formulae
 * When token is part of a valid variable returns an object with module, lineItem and period
 * @param token
 * @param tokens
 * @returns {{period: *, lineItem: *, module: *} | void}
 */
export function getCursorVar(token, tokens, editMode) {
  const selectorTypes = [
    vsTokens.LeftBracket,
    vsTokens.Module,
    vsTokens.LineItem,
    vsTokens.Period,
    vsTokens.SelectorLeftBracket,
    vsTokens.SelectorRightBracket,
  ];
  let firstTokenIdx = token.index;
  if (!selectorTypes.includes(token.type)) {
    // case when the only possibility is for all the selector to be after the cursor, so look at the next token
    token = tokens[firstTokenIdx - 1];
    if (token?.type !== vsTokens.LeftBracket) return;
  }

  let range = false;
  while (firstTokenIdx >= 0) {
    if (tokens[firstTokenIdx].type === vsTokens.LeftBracket) {
      // in insert mode, if I find a range operator before '[' and the initial token was a ']]]' I want to anchor a range
      if (
        tokens[firstTokenIdx - 1]?.type === vsTokens.RangeOperator &&
        token.type === vsTokens.SelectorRightBracket &&
        editMode === DataEntryEditMode.INSERT
      ) {
        range = true;
      } else break;
    }
    firstTokenIdx -= 1;
  }
  // couldn't find an initial '['
  if (firstTokenIdx === -1) return;

  // there's not enough tokens after initial token
  if (tokens.length <= firstTokenIdx + 6) return;
  if (range && tokens.length <= firstTokenIdx + 14) return;

  let module = { ...tokens[firstTokenIdx + 1] };
  let lineItem = { ...tokens[firstTokenIdx + 3] };
  let period = { ...tokens[firstTokenIdx + 5] };
  let endLineItem, endPeriod;
  if (range) {
    endLineItem = { ...tokens[firstTokenIdx + 11] };
    endPeriod = { ...tokens[firstTokenIdx + 13] };
  }

  if (
    module.type !== vsTokens.Module ||
    lineItem.type !== vsTokens.LineItem ||
    period.type !== vsTokens.Period ||
    tokens[firstTokenIdx + 6].value !== "]]]"
  )
    return;

  if (range && (endLineItem.type !== vsTokens.LineItem || endPeriod.type !== vsTokens.Period))
    return;

  const cursorVar = { module: module, lineItem: lineItem, period: period };
  if (range) {
    cursorVar.endLineItem = endLineItem;
    cursorVar.endPeriod = endPeriod;
  }
  return cursorVar;
}

/**
 * Evaluates a formulae and returns an array of all the selected variables in it
 * @param editor
 * @param formula
 * @param editMode
 */
export function getSelectionVariables(editor, formula, editMode) {
  if (!formula) return;
  const cursor = editor.getCursorPosition();
  const session = editor.session;
  const tokens = indexTokens(session.bgTokenizer.lines[cursor.row]);
  tokens.map((token, i) => (token.index = i));
  const selAnchor = editor.selection.anchor;
  const isSelection = cursor.row !== selAnchor.row || cursor.col !== selAnchor.column;
  let cursorToken = session.getTokenAt(cursor.row, isNaN(cursor.column) ? 0 : cursor.column);
  // if it's a selection we need the start token from the selection anchor and the end token from cursor position
  let token = isSelection
    ? session.getTokenAt(selAnchor.row, isNaN(selAnchor.column) ? 0 : selAnchor.column)
    : cursorToken;
  // invert if it was backward selection
  if (selAnchor.row > cursor.row || selAnchor.column > cursor.column) {
    const tempToken = token;
    token = cursorToken;
    cursorToken = tempToken;
  }

  let vars = [];
  while (token.index <= tokens.length - 1) {
    if (token.index > cursorToken.index) break;
    const newVar = getCursorVar(token, tokens, editMode);

    if (newVar) {
      const lastPeriod = newVar.endPeriod ? newVar.endPeriod : newVar.period;
      newVar.endIndex = lastPeriod.start + lastPeriod.value.length + 3;
      vars.push(newVar);
      const nextTokenIndex = lastPeriod.index + 2;
      token = tokens[nextTokenIndex];
    } else {
      token = tokens[token.index + 1];
    }
    if (!token) break;
  }
  return vars;
}

/**
 * Cycles through different anchor modes for each selected variable in formulae
 * Returns the new formulae with changed variable(s)
 * There are 4 stages: No anchors, anchor lineItem and period, anchor lineItem only and anchor period only
 * eg. [Module[LineItem[Period] -> [Module[$LineItem[$Period] -> [Module[$LineItem[Period] -> [Module[LineItem[$Period] -> [Module[LineItem[Period]
 * @param vars
 * @param formula
 * @return {string} anchoredFormula
 */
export function anchorVariables(vars, formula) {
  if (!vars || !vars.length) return formula;
  // find state based on first var anchors
  const liAnchor = vars[0].lineItem.value[0] === "$";
  const periodAnchor = vars[0].period.value[0] === "$";
  let state = 0;
  if (liAnchor && periodAnchor) state = 1;
  else if (liAnchor) state = 2;
  else if (periodAnchor) state = 3;

  // mutates item value if needed and mutates total char resize
  const anchorItem = (variable, item, add) => {
    if (add) {
      if (variable[item].value.indexOf("$") !== 0) {
        variable[item].value = "$" + variable[item].value;
        variable.resize += 1;
      }
    } else {
      if (variable[item].value.indexOf("$") === 0) {
        variable[item].value = variable[item].value.slice(1);
        variable.resize -= 1;
      }
    }
  };

  // anchor line item and/or period
  vars.forEach((variable) => {
    variable.resize = 0;

    const { endLineItem, endPeriod } = variable;
    switch (state) {
      case 0: {
        anchorItem(variable, "lineItem", true);
        if (endLineItem) anchorItem(variable, "endLineItem", true);
        anchorItem(variable, "period", true);
        if (endPeriod) anchorItem(variable, "endPeriod", true);
        break;
      }
      case 1: {
        anchorItem(variable, "lineItem", true);
        if (endLineItem) anchorItem(variable, "endLineItem", true);
        anchorItem(variable, "period", false);
        if (endPeriod) anchorItem(variable, "endPeriod", false);
        break;
      }
      case 2: {
        anchorItem(variable, "lineItem", false);
        if (endLineItem) anchorItem(variable, "endLineItem", false);
        anchorItem(variable, "period", true);
        if (endPeriod) anchorItem(variable, "endPeriod", true);
        break;
      }
      case 3: {
        anchorItem(variable, "lineItem", false);
        if (endLineItem) anchorItem(variable, "endLineItem", false);
        anchorItem(variable, "period", false);
        if (endPeriod) anchorItem(variable, "endPeriod", false);
        break;
      }
      default:
        break;
    }
  });

  // replace formula
  let charDiff = 0;
  let newFormula = formula;
  vars.forEach((variable) => {
    const selector = selectorToString(
      variable.module.value,
      variable.lineItem.value,
      variable.period.value,
      variable.endLineItem?.value,
      variable.endPeriod?.value
    );
    newFormula = newFormula.slice(0, variable.module.start - 1 + charDiff) + selector;
    charDiff += variable.resize;
    newFormula += formula.slice(newFormula.length - charDiff, formula.length);
  });
  return newFormula;
}

export function comparePeriodsOrder(p1, p2) {
  // generic case
  if (p1 === p2) return false;

  // numeric case
  if (!isNaN(p1) && !isNaN(p2) && p1 >= p2) return false;

  // lfy cases
  if (p1.indexOf("LFY") >= 0) {
    if (p2.indexOf("LFY") >= 0) {
      let p1LFYIdx = p1.replace("LFY", "");
      if (p1LFYIdx === "") p1LFYIdx = 0;

      let p2LFYIdx = p2.replace("LFY", "");
      if (p2LFYIdx === "") p2LFYIdx = 0;
      if (!isNaN(p1LFYIdx) && !isNaN(p2LFYIdx) && Number(p1LFYIdx) >= Number(p2LFYIdx))
        return false;
    }
  }

  /* keyword cases
  if (p1 === "FORECAST_END") return false;
  if (p1 === "FORECAST_START") {
    if (p2.indexOf("HISTORICAL") >= 0) return false;
  }*/

  return true;
}

/*
  Mutates function token to include arguments as a list of strings or an error
  Does this by matching curly parenthesis.
   */
export function getFunctionArguments(funcTokenIndex, tokens) {
  const funcToken = tokens[funcTokenIndex];
  let tokenIndex = funcTokenIndex;
  let argList = [];
  let currentArgIdx = 0;
  let currentStart = null;
  let nextToken = getNextToken(tokenIndex, tokens);
  if (!nextToken || nextToken.type !== vsTokens.LeftParenthesis)
    funcToken.funcArgs = {
      error: [
        "Invalid " + funcToken.value + " function body",
        funcTokenIndex,
        funcToken.value.length,
      ],
    };
  let parenCount = 0;
  let currentArg = "";
  while (nextToken) {
    tokenIndex += 1;
    if (nextToken.type === vsTokens.RightParenthesis) parenCount -= nextToken.value.length;
    if (nextToken.type === vsTokens.LeftParenthesis) parenCount += nextToken.value.length;
    if (nextToken.type === vsTokens.Punctuation && parenCount === 1) {
      currentArgIdx += 1;
      argList.push({ value: currentArg, start: currentStart, end: nextToken.start });
      currentArg = "";
      currentStart = null;
    } else {
      if (!currentStart) currentStart = nextToken.start;
      currentArg += nextToken.value;
    }
    const token = tokens[tokenIndex];
    // Auto-completer needs to know function scope of a token
    token.scope = { func: funcToken.value, argIdx: currentArgIdx };

    // end case
    if (parenCount <= 0) {
      // clean opening and closing func parenthesis
      if (argList[0]) {
        argList[0].value = argList[0].value.slice(1);
        argList[0].start += 1;
      }
      currentArg = currentArg.slice(0, -1);
      argList.push({ value: currentArg, start: currentStart, end: nextToken.start });

      // mutate function token to include args
      funcToken.funcArgs = argList;

      return argList;
    }

    nextToken = getNextToken(tokenIndex, tokens);
  }
  funcToken.funcArgs = {
    error: [
      "Invalid " + funcToken.value + " function body",
      funcTokenIndex,
      funcToken.value.length,
    ],
  };
}

/*
   Checks if a next token exists and returns the next token from the parsed tokens
   */
export function getNextToken(i, tokens) {
  if (i + 1 < tokens.length) return tokens[i + 1];
  else return null;
}

export function validateSelector(sel) {
  return sel && typeof sel === "string" && sel.match(vsVariableRegex);
}

export function validateRange(range) {
  return range && typeof range === "string" && range.match(vsRangeRegex);
}
