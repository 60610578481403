import React from "react";
import useUserConfig from "hooks/useUserConfig";
import LoadingIndicator from "components/utility/LoadingIndicator";
import { Callout, Intent } from "@blueprintjs/core";
import { Navigate } from "react-router-dom";
import { errorMessages } from "constants/apiConstants";

const Home = () => {
  const { error, home, isLoading: loadingConfig } = useUserConfig();

  if (loadingConfig) {
    return (
      <LoadingIndicator
        loading
        status={{ intent: Intent.PRIMARY, message: "Loading dashboard..." }}
      />
    );
  }

  if (home) {
    if (home?.id) {
      let to = `/dashboards/${home.id}/`;
      if (home?.views) {
        to += home.views[0].id;
      }
      return <Navigate to={to} replace />;
    } else {
      return (
        <Callout intent={Intent.WARNING} title="No Home dashboard set.">
          <p>There is no home Dashboard set for your user. Please contact support.</p>
        </Callout>
      );
    }
  }

  if (error) {
    return (
      <Callout>
        <p>{error?.message || errorMessages.SOMETHING_WENT_WRONG}</p>
      </Callout>
    );
  }
  return null;
};

export default Home;
