import React, { useContext, useEffect, useMemo, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import { Classes, Intent } from "@blueprintjs/core";

import "styles/vs-table.scss";

import ModelHistoryTabs from "components/user/ModelHistoryTabs";

import useSetPageTitle from "hooks/useSetPageTitle";
import { IModelHistoryInfo, ITableConfigColumn } from "types/modelHistory";
import LoadingIndicator from "components/utility/LoadingIndicator";
import { parseStoredModelTables } from "utils/modelHistoryUtils";
import ModelHistoryContext from "context/modelHistoryContext";
import { OutletContext as IncomingContext } from "ValsysApp";
import Page from "components/utility/Page";

const StyledTabContainer = styled.div`
  height: 100%;
  padding: 10px;
  width: 100%;
  & > div.${Classes.TABS} {
    height: 100%;
    display: flex;
    flex-direction: column;
    & > div.${Classes.TAB_PANEL} {
      flex: 1 1 0;
      height: 100%;
    }
  }
`;

const ModelHistory: React.FC = () => {
  const {
    fetchingConfig: loadingModelTables,
    currentModelTable: modelTable,
    serverConfig,
    setConfig,
    setCurrentModelTable,
  } = useOutletContext<IncomingContext>();
  const serverModelTables = serverConfig?.modelTables || null;
  useSetPageTitle("Model History");
  const { modelTable: paramsModelTable } = useParams();
  const { selectedModels, setSelectedModels } = useContext(ModelHistoryContext);

  // MODEL SELECTION
  const [allModelsSelected, setAllModelsSelected] = useState<{ [key: string]: boolean }>({});

  const [addTableDialog, setAddTableDialog] = useState(false);

  const toggleAddTableDialog = () => {
    setAddTableDialog(!addTableDialog);
  };

  const modelTables = useMemo(() => {
    return serverModelTables ? parseStoredModelTables(serverModelTables) : null;
  }, [serverModelTables]);

  useEffect(() => {
    if (paramsModelTable && paramsModelTable !== modelTable) {
      setCurrentModelTable(paramsModelTable);
    }
  }, [modelTable, paramsModelTable, setCurrentModelTable]);

  const editColumns = (nextColumns: ITableConfigColumn[]) => {
    if (Array.isArray(modelTables)) {
      const nextModelTables = [...modelTables];
      const index = nextModelTables.findIndex((table) => table.uid === modelTable);
      nextModelTables.splice(index, 1, {
        ...nextModelTables[index],
        columns: nextColumns,
      });
      setConfig({ modelTables: nextModelTables });
    }
  };

  useEffect(() => {
    if (modelTables) {
      const selectedState: { [key: string]: IModelHistoryInfo[] } = {};
      const allSelectedState: { [key: string]: boolean } = {};
      modelTables.forEach((table) => {
        selectedState[table.uid] = [];
        allSelectedState[table.uid] = false;
      });
      setAllModelsSelected(allSelectedState);
    }
  }, [modelTables, setSelectedModels]);

  const setAllSelected = (allSelected?: boolean) => {
    const nextAllSelected = {
      ...allModelsSelected,
      [modelTable]: allSelected !== undefined ? allSelected : !allModelsSelected[modelTable],
    };
    setAllModelsSelected(nextAllSelected);
  };

  return (
    <Page title="Model History">
      <StyledTabContainer>
        {!loadingModelTables && modelTables ? (
          <ModelHistoryTabs
            allModelsSelected={allModelsSelected}
            disabled={false}
            editColumns={editColumns}
            modelTable={modelTable}
            modelTables={modelTables}
            selectedModels={selectedModels}
            setAllSelected={setAllSelected}
            setConfig={setConfig}
            toggleAddTableDialog={toggleAddTableDialog}
          />
        ) : (
          <LoadingIndicator
            loading={true}
            status={{ message: "Loading model tables...", intent: Intent.PRIMARY }}
          />
        )}
      </StyledTabContainer>
    </Page>
  );
};

export default ModelHistory;
