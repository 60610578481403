import { Button, NumericInput } from "@blueprintjs/core";
import { Flex } from "components/utility/StyledComponents";
import React, { useState } from "react";
import { ChartWidgetConfig } from "types/widget";
import { EValFormat, IFormatObject, SuffixToUnitMap } from "types/format";
import { applyUnit, getRawValueFromUnit } from "utils/formatter";
import { isNil } from "lodash";

interface XAxisMinMaxMenuProps {
  chartRendererConfig?: ChartWidgetConfig;
  configFormat?: Partial<IFormatObject>;
  chartXAxisMin?: number | null;
  chartXAxisMax?: number | null;
  updateConfig: (details: Partial<ChartWidgetConfig>) => void;
}

const XAxisMinMaxMenu: React.FC<XAxisMinMaxMenuProps> = ({
  chartRendererConfig,
  configFormat,
  chartXAxisMin,
  chartXAxisMax,
  updateConfig,
}) => {
  const xAxisConfig = chartRendererConfig?.xAxis || {};
  const configXAxisMin = xAxisConfig.min;
  const configXAxisMax = xAxisConfig.max;
  const unit = configFormat?.unit;

  const suffix = `${unit ? SuffixToUnitMap[unit] : ""}${
    configFormat?.valFormat === EValFormat.PERCENTAGE ? "%" : ""
  }`;

  const [minValue, setMinValue] = useState(configXAxisMin);
  const [maxValue, setMaxValue] = useState(configXAxisMax);

  // Returns value formatted by unit and percentage.
  const getFormattedValue = (value: number): number => {
    const valueWithUnit = applyUnit(value, unit);
    const formattedValue =
      configFormat?.valFormat === EValFormat.PERCENTAGE ? valueWithUnit * 100 : valueWithUnit;

    return parseFloat(formattedValue.toFixed(6));
  };

  // Returns value without unit/percentage formatting.
  const getRawValue = (value: number): number => {
    const valueWithoutUnit = getRawValueFromUnit(value, unit);

    return configFormat?.valFormat === EValFormat.PERCENTAGE
      ? valueWithoutUnit / 100
      : valueWithoutUnit;
  };

  const handleMinChange = (value?: number, isRaw?: boolean) => {
    const rawValue = value && !isRaw ? getRawValue(value) : value;

    if (rawValue || rawValue === 0 || isRaw) {
      setMinValue(rawValue);
      updateConfig({
        xAxis: { ...xAxisConfig, min: rawValue },
      });
    }
  };

  const handleMaxChange = (value?: number, isRaw?: boolean) => {
    const rawValue = value && !isRaw ? getRawValue(value) : value;

    if (rawValue || rawValue === 0 || isRaw) {
      setMaxValue(rawValue);
      updateConfig({
        xAxis: { ...xAxisConfig, max: rawValue },
      });
    }
  };

  return (
    <>
      <Flex justifyContent="space-between">
        <Button
          active={configXAxisMin !== void 0}
          minimal
          onClick={() =>
            handleMinChange(configXAxisMin === void 0 ? chartXAxisMin || 0 : void 0, true)
          }
          outlined
          small
          style={{ marginRight: 5, width: 70 }}
          text="Min"
        />
        <NumericInput
          allowNumericCharactersOnly
          asyncControl={true}
          disabled={configXAxisMin === void 0}
          onValueChange={(value) => handleMinChange(value)}
          small
          style={{ width: 130 }}
          value={isNil(minValue) ? "Click Min to activate" : getFormattedValue(minValue)}
          rightElement={
            !isNil(minValue) ? <div style={{ margin: "3px 5px 0px 0px" }}>{suffix}</div> : null
          }
        />
      </Flex>
      <Flex justifyContent="space-between" style={{ marginTop: 5 }}>
        <Button
          active={configXAxisMax !== void 0}
          minimal
          onClick={() =>
            handleMaxChange(configXAxisMax === void 0 ? chartXAxisMax || void 0 : void 0, true)
          }
          outlined
          small
          style={{ marginRight: 5, width: 70 }}
          text="Max"
        />
        <NumericInput
          onValueChange={(value) => handleMaxChange(value)}
          disabled={configXAxisMax === void 0}
          small
          allowNumericCharactersOnly
          style={{ width: 130 }}
          value={isNil(maxValue) ? "Click Max to activate" : getFormattedValue(maxValue)}
          rightElement={
            !isNil(maxValue) ? <div style={{ margin: "3px 5px 0px 0px" }}>{suffix}</div> : null
          }
        />
      </Flex>
    </>
  );
};

export default XAxisMinMaxMenu;
