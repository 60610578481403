import React from "react";
import { Button, ButtonGroup, Classes, Tooltip } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ChartWidgetConfig } from "types/widget";
import { StyledHTMLSelectSmall } from "components/utility/StyledComponents";

interface CategoryFiltersProps {
  categories: string[];
  updateConfig?: (details: Partial<ChartWidgetConfig>) => void;
  config?: ChartWidgetConfig | null;
  overlapLabels?: [string, string] | null;
}

const CategoryFilters: React.FC<CategoryFiltersProps> = ({
  categories,
  updateConfig,
  config,
  overlapLabels,
}) => {
  const minIndex = config?.categoryFilters?.min ?? 0;
  const maxIndex = config?.categoryFilters?.max ?? categories.length - 1;
  const maxCategories = categories.slice(minIndex + 1);
  const minCategories = [...categories];
  minCategories.pop();

  const updateMaxIndex = (nextMaxIndex: number) => {
    if (updateConfig) {
      updateConfig({ categoryFilters: { min: minIndex, max: nextMaxIndex } });
    }
  };

  const updateMinIndex = (nextMinIndex: number) => {
    // this is used to ensure that the max index is always at least one above the min index
    const nextMaxIndex = maxIndex <= nextMinIndex ? nextMinIndex + 1 : maxIndex;
    if (updateConfig) {
      updateConfig({ categoryFilters: { min: nextMinIndex, max: nextMaxIndex } });
    }
  };

  const updateBothIndeces = (nextMinIndex: number, nextMaxIndex: number) => {
    if (
      nextMinIndex <= nextMaxIndex &&
      nextMinIndex !== -1 &&
      nextMaxIndex !== -1 &&
      updateConfig
    ) {
      updateConfig({ categoryFilters: { min: nextMinIndex, max: nextMaxIndex } });
    }
  };

  return (
    <ButtonGroup>
      <StyledHTMLSelectSmall
        className={Classes.TEXT_SMALL}
        onChange={(e) => {
          updateMinIndex(categories.indexOf(e.currentTarget.value));
        }}
        options={minCategories}
        value={categories[minIndex]}
      />
      <StyledHTMLSelectSmall
        className={Classes.TEXT_SMALL}
        onChange={(e) => {
          updateMaxIndex(categories.indexOf(e.currentTarget?.value));
        }}
        options={maxCategories}
        value={categories[maxIndex]}
      />
      <Tooltip content="Overlapping Periods Only" hoverOpenDelay={1000}>
        <Button
          onClick={() => {
            const [minLabel, maxLabel] = overlapLabels ?? [];
            if (minLabel && maxLabel) {
              updateBothIndeces(categories.indexOf(minLabel), categories.indexOf(maxLabel));
            }
          }}
          small
          icon={IconNames.INTERSECTION}
        />
      </Tooltip>
    </ButtonGroup>
  );
};

export default CategoryFilters;
