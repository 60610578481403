import { SegmentedControl, ButtonGroup, Classes } from "@blueprintjs/core";
import { Flex, PaddedContent } from "components/utility/StyledComponents";
import React, { useEffect } from "react";
import { ChartWidgetType, VSLChart, EWidgetType2 } from "types/vsl";
import { ChartWidgetConfig } from "types/widget";
import {
  chartDefaults,
  ChartPeriodType,
  ETransformationType,
  YAxisId,
} from "constants/chartConstants";
import CategoryFilters from "components/dashboard/renderers/CategoryFilters/CategoryFilters";
import {
  getChartFrequency,
  getCurrentChartConfig,
  getOverlappingPeriodLabels,
  getXAxisCategories,
  getXAxisType,
  getYAxisIds,
} from "utils/chartUtils";
import { isNumber } from "highcharts";
import { StyledHTMLSelectSmall } from "components/utility/StyledComponents";

interface ChartTopControlRibbon {
  chartData?: VSLChart;
  config?: ChartWidgetConfig;
  updateConfig: (details: Partial<ChartWidgetConfig>) => Promise<boolean>;
  widgetType: ChartWidgetType;
}

const ChartTopControlRibbon: React.FC<ChartTopControlRibbon> = ({
  chartData,
  config,
  updateConfig,
  widgetType,
}) => {
  const periodType = chartData?.opts?.time
    ? ChartPeriodType.Raw
    : config?.periodType ?? ChartPeriodType.Fiscal;

  const xAxisType = getXAxisType(widgetType, periodType);

  const frequency = chartData && (config?.frequency ?? getChartFrequency(chartData));

  const axesInUse = getYAxisIds(chartData, config);

  const overlap = chartData ? getOverlappingPeriodLabels(chartData, config?.periodType) : null;

  const categories =
    chartData && frequency && getXAxisCategories(chartData, xAxisType, frequency, periodType);

  const { rangeSelectorEnabled, transformationEnabled } = getCurrentChartConfig(
    chartDefaults[widgetType],
    widgetType,
    config
  );

  useEffect(() => {
    if (categories && config?.categoryFilters) {
      const { min, max } = config.categoryFilters;
      if (isNumber(min)) {
        if (min >= categories.length - 1) {
          updateConfig({
            categoryFilters: { ...config?.categoryFilters, min: categories.length - 2 },
          });
        }
      }
      if (isNumber(max)) {
        if (max > categories.length) {
          updateConfig({
            categoryFilters: { ...config?.categoryFilters, max: categories.length - 1 },
          });
        }
      }
    }
  }, [categories, config, updateConfig]);

  const onChangeTransformationType = (
    axisId: YAxisId
  ): ((e: React.ChangeEvent<HTMLSelectElement>) => void) => {
    return (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (updateConfig && config && config.yAxis) {
        const transformation = e.target.value as ETransformationType;
        const updatedSingleAxis = {
          ...config.yAxis[axisId],
          transformation,
        };
        const updatedAxes = { ...config.yAxis, [axisId]: updatedSingleAxis };
        updateConfig({ ...config, yAxis: updatedAxes });
      }
    };
  };

  return (
    <PaddedContent padding="5px 10px" fullWidth>
      <Flex alignItems="center" fullWidth justifyContent="flex-start" flexWrap="wrap" gap={5}>
        {transformationEnabled &&
          axesInUse?.length === 2 &&
          (widgetType === EWidgetType2.LINE_CHART || widgetType === EWidgetType2.BAR_CHART) && (
            <ButtonGroup>
              <StyledHTMLSelectSmall
                className={Classes.TEXT_SMALL}
                onChange={onChangeTransformationType(axesInUse[0])}
                options={Object.values(ETransformationType)}
                value={config?.yAxis?.[axesInUse[0]]?.transformation}
              />
              <StyledHTMLSelectSmall
                className={Classes.TEXT_SMALL}
                onChange={onChangeTransformationType(axesInUse[1])}
                options={Object.values(ETransformationType)}
                value={config?.yAxis?.[axesInUse[1]]?.transformation}
              />
            </ButtonGroup>
          )}
        {transformationEnabled &&
          axesInUse?.length === 1 &&
          (widgetType === EWidgetType2.LINE_CHART || widgetType === EWidgetType2.BAR_CHART) && (
            <SegmentedControl
              onValueChange={(transform) => {
                if (updateConfig && config && config.yAxis) {
                  const tr = transform as ETransformationType;
                  const updatedSingleAxis = {
                    ...config.yAxis[axesInUse[0]],
                    transformation: tr,
                  };
                  const updatedAxes = { ...config.yAxis, [axesInUse[0]]: updatedSingleAxis };
                  updateConfig({ ...config, yAxis: updatedAxes });
                }
              }}
              options={Object.values(ETransformationType).map((transform) => ({
                label: transform,
                value: transform,
              }))}
              value={config?.yAxis?.[axesInUse[0]]?.transformation}
              small
            />
          )}
        {xAxisType === "category" && categories && rangeSelectorEnabled && (
          <CategoryFilters
            categories={categories}
            config={config}
            overlapLabels={overlap}
            updateConfig={updateConfig}
          />
        )}
      </Flex>
    </PaddedContent>
  );
};

export default ChartTopControlRibbon;
