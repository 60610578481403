import { END, eventChannel } from "redux-saga";
import { call, cancel, cancelled, delay } from "redux-saga/effects";
import { captureException } from "@sentry/react";

import { Status as statusTypes } from "constants/apiConstants";

export const StatusMap = {
  [statusTypes.SUCCESS]: statusTypes.SUCCESS,
  [statusTypes.FAILURE]: statusTypes.FAILURE,
  [statusTypes.FINISHED]: statusTypes.FINISHED,
  [statusTypes.FAILED]: statusTypes.FAILURE,
};

/**
 * Handles + logs errors and restarts saga.
 *
 * @param {Object} sagaObject - An object containing a saga and any arguments to be passed.
 * */
export function* restart(sagaObject) {
  while (true) {
    try {
      const { saga, args } = sagaObject;
      const task = yield call(saga, ...args);
      yield delay(300);
      if (yield cancelled(task)) yield cancel();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      captureException(err);
    }
  }
}

/**
 * Create a websocket connection.
 *
 * @param {string} subRoute - The subroute for the socket
 * */
export const createWebSocketConnection = (route) => {
  return new Promise((resolve, reject) => {
    // Create the socket.
    const socket = new WebSocket(route);
    window.vsSocket = socket;

    // Resolve on successul upgrade. Reject with error.
    socket.onopen = () => resolve(socket);
    socket.onerror = (event) => reject(event);
  });
};

/**
 * Create a redux saga eventChannel to listen to messages from the socket.
 *
 * @param socket - The socket to subscribe to events for.
 **/
export const createDataEventChannel = (socket) => {
  return eventChannel((emit) => {
    /**
     * Called when socket receives a message. Emits an event to the channel.
     * */
    socket.onmessage = (e) => emit(e);

    /**
     * Called when socket errors. Emits an event to the channel.
     * */
    socket.onerror = (errorEvent) => {
      emit(new Error(errorEvent));
    };

    // Called when the socket is closed. Emits END to close the channel.
    socket.onclose = () => {
      emit(END);
    };

    // Cleanup if the channel is closed but the socket is still open.
    const unsubscribe = () => {
      if (socket) {
        // Called with 1000 to indicate a deliberate closure.
        socket.close(1000, "Disconnected from server.");
      }
    };

    return unsubscribe;
  });
};
