import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ItemRenderer, Select } from "@blueprintjs/select";
import styled from "styled-components/macro";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AnchorButton,
  Button,
  ControlGroup,
  Divider,
  FormGroup,
  H6,
  HTMLTable,
  InputGroup,
  Intent,
  MenuItem,
  Position,
  Spinner,
  Tooltip,
} from "@blueprintjs/core";
import AppContext from "context/appContext";
import { caseModulesSelector, currentCaseDataSelector } from "selectors/modelSelectors";
import {
  Flex,
  MaybeStickyDiv,
  SideMenuHeader,
  SideMenyHeaderTitle,
} from "components/utility/StyledComponents";
import colors from "styles/colors.module.scss";
import { Themes } from "constants/uiConstants";
import { IActionReturnType, IFactEdit } from "types";
import { periodFormatter } from "utils/formatter";
import { editFormula } from "actions/dataEntryActions";
import { navToCell } from "utils/modelGrid";
import { ICase, IFact, ILineItem, IModule } from "types/model";
import { vsNavigate } from "utils/generic";
import ModelContext from "context/modelContext";

interface ISearchMenuProps {
  currentCase: ICase;
  editFormula: (
    currentCaseUid: string,
    currentModuleUid: string,
    edits: IFactEdit[],
    successMsg: string
  ) => IActionReturnType<
    string,
    {
      params: {
        caseID: string;
        moduleID: string;
        facts: IFact[];
        customSuccessMsg: string;
      };
      reverseParams: { caseID: string; moduleID: string };
    }
  >;
  facts: Record<string, IFact>;
  lineItems: Record<string, ILineItem>;
  modules: Record<string, IModule>;
}

type TSearchType = "fx_raw" | "fx_param" | "line_item";

const SearchTypes: { [key: string]: TSearchType } = {
  FX_RAW: "fx_raw",
  FX_PARAM: "fx_param",
  LINE_ITEM: "line_item",
};

interface IQueryFunctionParam {
  name: string;
  pos: number;
  type: string;
}

interface IQueryFunction {
  name: string;
  paramCount: number[];
  params: IQueryFunctionParam[];
}

const StyledFindTableEntry = styled.span`
  cursor: pointer;
`;

const isFact = (vsObject: IFact | ILineItem | IModule): vsObject is IFact => {
  return (vsObject as IFact).formula !== undefined;
};

//TODO: Move these to utils
const _isLineItem = (vsObject: IFact | ILineItem | IModule): vsObject is ILineItem => {
  return (vsObject as ILineItem).deps !== undefined || (vsObject as ILineItem).facts !== undefined;
};

const functions: IQueryFunction[] = [
  {
    name: "DATA",
    paramCount: [4],
    params: [{ name: "Data Source", pos: 2, type: "dqstring" }],
  },
  {
    name: "INTERNAL_DB",
    paramCount: [4, 5],
    params: [{ name: "Data Source", pos: 2, type: "dqstring" }],
  },
];

const Search: React.FC<ISearchMenuProps> = ({
  currentCase,
  editFormula,
  facts,
  lineItems,
  modules,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const { locked } = useContext(ModelContext);
  const navigate = useNavigate();
  const findInput = useRef<HTMLInputElement>(null);
  const [searchType, setSearchType] = useState(SearchTypes.FX_RAW);
  const [findQuery, setFindQuery] = useState("");

  const [liSearchType, setLiSearchType] = useState({
    name: true,
    tags: false,
  });

  const [func, setFunc] = useState<IQueryFunction | null>(null);
  const [param, setParam] = useState<IQueryFunctionParam | null>(null);

  const [replaceQuery, setReplaceQuery] = useState("");

  const [running, setRunning] = useState(false);

  const findingsCount = useRef(0);
  const findingsEntries = useRef<IFact[] | ILineItem[]>([]);
  const findingsMatches = useRef<RegExpMatchArray[]>([]);

  const [findingsElems, setFindingsElems] = useState<JSX.Element[] | null>(null);

  useEffect(() => {
    reset();
  }, [searchType]);

  const reset = () => {
    setFindQuery("");
    setFindingsElems(null);
    findingsCount.current = 0;
    findingsEntries.current = [];
    findingsMatches.current = [];
    setReplaceQuery("");
    setFunc(null);
    setParam(null);
    setRunning(false);
  };

  const submit = () => {
    const edits: IFactEdit[] = [];
    findingsEntries.current.map((fact) => {
      if ("formula" in fact && fact.formula) {
        edits.push({
          uid: fact.uid,
          formula: fact.formula.replaceAll(findQuery, replaceQuery),
        });
      }
    });
    const successMsg =
      "Replaced all " + findingsCount.current + " match" + (findingsCount.current > 1 ? "es" : "");
    editFormula(currentCase.uid, "", edits, successMsg);
    reset();
  };

  const submitFX = () => {
    const edits: IFactEdit[] = [];
    findingsEntries.current.map((fact, idx) => {
      if ("formula" in fact && fact.formula) {
        const match = findingsMatches.current[idx][0];
        const dqIdxs = [];
        for (let i = 0; i < match.length; i++) {
          if (match[i] === '"') dqIdxs.push(i);
        }
        const newFx =
          match.substring(0, dqIdxs[2]) + '"' + replaceQuery + match.substring(dqIdxs[3]);
        edits.push({
          uid: fact.uid,
          formula: fact.formula.replaceAll(match, newFx),
        });
      }
    });
    const successMsg =
      "Replaced all " +
      func?.name.toUpperCase() +
      " function " +
      param?.name.toUpperCase() +
      " parameters with " +
      replaceQuery;
    editFormula(currentCase.uid, "", edits, successMsg);
    reset();
  };

  // generates element for a query search
  const genFindListElem = useCallback(
    (entry: IFact | ILineItem, query: string) => {
      let count = 0;
      let findQueryAtEnd = false;
      const partials = [];
      let partialStr = "";
      if ("formula" in entry && entry.formula) {
        partialStr = entry.formula;
      } else if ("tags" in entry) {
        if (liSearchType.tags && entry?.tags) {
          // figure out tag match and render
          const matchedTags = entry.tags.filter((tag) => {
            return tag.toLowerCase().includes(query.toLowerCase());
          });
          partialStr = matchedTags[0];
        } else {
          // name partial
          partialStr = entry.name;
        }
      }

      const marks: string[] = [];
      while (partialStr) {
        const idx = partialStr.toLowerCase().indexOf(query.toLowerCase());
        const mark = partialStr.substring(idx, idx + query.length);
        marks.push(mark);
        if (idx === -1) {
          partials.push(partialStr);
          partialStr = "";
        } else {
          partials.push(partialStr.substring(0, idx));
          partialStr = partialStr.substring(idx + query.length);
          if (!partialStr) findQueryAtEnd = true;
          count += 1;
        }
      }
      findingsCount.current += count;
      return (
        <StyledFindTableEntry key={entry.uid}>
          {partials.map((partial, idx) => (
            <span key={idx}>
              {partial}
              {partials.length - 1 !== idx ? (
                <b>
                  <mark>{marks[idx]}</mark>
                </b>
              ) : null}
              {partials.length - 1 === idx && findQueryAtEnd ? (
                <b>
                  <mark>{marks[idx]}</mark>
                </b>
              ) : null}
            </span>
          ))}
        </StyledFindTableEntry>
      );
    },
    [liSearchType]
  );

  // generates the list of elements to display as results
  const buildResultList = useCallback(
    (fromQuery: boolean, query: string) => {
      const findingElems = findingsEntries.current.map((entry) => {
        if (isFact(entry)) {
          const module = modules[entry.moduleUid];
          const lineItem = lineItems[entry.parentUid];
          return (
            <Tooltip
              key={entry.uid}
              content={
                "Module: " +
                module.name +
                " | Line item: " +
                lineItem.name +
                " | Period: " +
                periodFormatter(entry.period, currentCase.startPeriod, module.forecastIncrement)
              }
              openOnTargetFocus={false}
            >
              {fromQuery ? genFindListElem(entry, query) : genFuncListElem(entry)}
            </Tooltip>
          );
        } else {
          return <span key={entry.uid}>{genFindListElem(entry, query)}</span>;
        }
      });
      setFindingsElems(findingElems);
    },
    [currentCase.startPeriod, genFindListElem, lineItems, modules]
  );

  // generates element for function parameter search
  const genFuncListElem = (fact: IFact) => {
    // TODO: highlight correct param
    findingsCount.current += 1;
    return <StyledFindTableEntry>{fact.formula}</StyledFindTableEntry>;
  };

  // const findLineItemsByName = () => {};

  const findFactsByQuery = (facts: IFact[], findQuery: string) => {
    return facts.filter((fact) => fact.formula?.toLowerCase().includes(findQuery.toLowerCase()));
  };

  const findFactsByFunc = (facts: IFact[], func: IQueryFunction | null) => {
    const paramRegex = new RegExp(func?.name + "\\(.+\\)", "i");
    return facts.filter((fact) => {
      const match = fact.formula?.match(paramRegex);
      if (match) findingsMatches.current.push(match);
      return !!match;
    });
  };

  const preFind = () => {
    setRunning(true);
    findingsCount.current = 0;
    findingsEntries.current = [];
    findingsMatches.current = [];
  };

  const findLineItemsByQuery = (
    lineItems: ILineItem[],
    query: string,
    liSearchType: { tags: boolean; name: boolean }
  ): ILineItem[] => {
    const lowercaseQuery = query.toLowerCase();
    const lis = lineItems.filter((li) => {
      if (liSearchType.tags && liSearchType.name) {
        return (
          li.name.toLowerCase().includes(lowercaseQuery) ||
          (li.tags && li.tags.find((tag) => tag.toLowerCase().includes(lowercaseQuery)))
        );
      } else if (liSearchType.tags) {
        return li.tags && li.tags.find((tag) => tag.toLowerCase().includes(lowercaseQuery));
      } else if (liSearchType.name) {
        return li.name.toLowerCase().includes(lowercaseQuery);
      }
    });
    return lis;
  };

  useEffect(() => {
    if (!findQuery) return;
    preFind();
    const findTimeout = setTimeout(() => {
      if (searchType === SearchTypes.LINE_ITEM) {
        findingsEntries.current = findLineItemsByQuery(
          Object.values(lineItems),
          findQuery,
          liSearchType
        );
      } else {
        findingsEntries.current = findFactsByQuery(Object.values(facts), findQuery);
      }
      buildResultList(true, findQuery);
      setRunning(false);
    }, 300);
    return () => {
      if (findTimeout) clearTimeout(findTimeout);
    };
  }, [buildResultList, facts, findQuery, liSearchType, lineItems, searchType]);

  useEffect(() => {
    if (!param) return;
    preFind();
    findingsEntries.current = findFactsByFunc(Object.values(facts), func);
    buildResultList(false, "");
    setRunning(false);
  }, [facts, func, buildResultList, param]);

  const navigateToObject = useCallback(
    (idx: number) => {
      const entry = findingsEntries.current[idx];
      if (isFact(entry)) {
        navToCell(entry, modules, navigate);
      } else {
        const mod = modules[entry.parentUid];
        navigate(vsNavigate(mod.name, { row: entry.order - 1, col: 0 }));
      }
    },
    [modules, navigate]
  );

  function predicate(query: string, item: IQueryFunction | IQueryFunctionParam) {
    const value = item?.name || item;
    return String(value).toLowerCase().includes(query.toLowerCase());
  }

  const renderFuncItem: ItemRenderer<IQueryFunction> = (item, { handleClick, modifiers }) => {
    return (
      <MenuItem active={modifiers.active} key={item.name} onClick={handleClick} text={item.name} />
    );
  };

  const renderParamItem: ItemRenderer<IQueryFunctionParam> = (item, { handleClick, modifiers }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.name}
        label={"parameter"}
        onClick={handleClick}
        text={item.name}
      />
    );
  };

  let searchView: JSX.Element | null = null;

  if (searchType === SearchTypes.FX_PARAM) {
    searchView = (
      <ControlGroup>
        <Select
          activeItem={func}
          itemPredicate={predicate}
          itemRenderer={renderFuncItem}
          onItemSelect={(item) => {
            if (func?.name === item.name) return;
            reset();
            setFunc(item);
          }}
          items={functions || []}
          popoverProps={{
            canEscapeKeyClose: true,
            minimal: true,
          }}
        >
          <Button
            icon="function"
            rightIcon="caret-down"
            text={func ? func.name : "Select Function"}
          />
        </Select>
        {func ? (
          <Select
            activeItem={param}
            itemPredicate={predicate}
            itemRenderer={renderParamItem}
            onItemSelect={(item) => {
              if (param?.name === item.name) return;
              setParam(item);
            }}
            items={func.params || []}
            popoverProps={{
              canEscapeKeyClose: true,
              minimal: true,
            }}
          >
            <Button
              icon="publish-function"
              rightIcon="caret-down"
              text={param ? param.name : "Select Parameter"}
            />
          </Select>
        ) : null}
      </ControlGroup>
    );
  } else if (searchType === SearchTypes.FX_RAW) {
    searchView = (
      <InputGroup
        autoFocus
        leftIcon="search-text"
        onChange={(ev) => setFindQuery(ev.target.value)}
        placeholder="Search"
        inputRef={findInput}
        style={{
          borderRadius: 0,
          boxShadow: "none",
          backgroundColor: theme === Themes.LIGHT ? colors.lightGray4 : colors.darkGray3,
        }}
        value={findQuery}
      />
    );
  } else if (searchType === SearchTypes.LINE_ITEM) {
    searchView = (
      <InputGroup
        autoFocus
        leftIcon="search-text"
        onChange={(ev) => setFindQuery(ev.target.value)}
        placeholder="Search"
        inputRef={findInput}
        style={{
          borderRadius: 0,
          boxShadow: "none",
          backgroundColor: theme === Themes.LIGHT ? colors.lightGray4 : colors.darkGray3,
        }}
        value={findQuery}
        rightElement={
          <Flex>
            <Tooltip content="Search by name">
              <Button
                active={liSearchType.name}
                icon="stadium-geometry"
                intent={liSearchType.name ? Intent.PRIMARY : undefined}
                minimal
                onClick={() => {
                  const nextLiSearchType = { ...liSearchType };
                  // TODO: Implement search by both
                  // if (!!liSearchType.name && !liSearchType.tags) {
                  //   nextLiSearchType.tags = true;
                  // }
                  if (liSearchType.tags) {
                    nextLiSearchType.name = true;
                    nextLiSearchType.tags = false;
                  }
                  setLiSearchType(nextLiSearchType);
                }}
                small
              />
            </Tooltip>
            <Tooltip content="Search by tags">
              <Button
                active={liSearchType.tags}
                icon="tag"
                intent={liSearchType.tags ? Intent.PRIMARY : undefined}
                minimal
                onClick={() => {
                  const nextLiSearchType = { ...liSearchType };
                  // TODO: Implement search by both
                  // if (!!liSearchType.tags && !liSearchType.name) {
                  //   nextLiSearchType.name = true;
                  // }
                  // nextLiSearchType.tags = !liSearchType.tags;
                  if (liSearchType.name) {
                    nextLiSearchType.tags = true;
                    nextLiSearchType.name = false;
                  }
                  setLiSearchType(nextLiSearchType);
                }}
                small
              />
            </Tooltip>
          </Flex>
        }
      />
    );
  }

  return (
    <div
      style={{
        flexDirection: "column",
        height: "100%",
        width: "100%",
        textAlign: "left",
        overflowY: "auto",
      }}
    >
      <MaybeStickyDiv position="top" pixels={0} pinned={true}>
        <SideMenuHeader
          alignItems="flex-start"
          flexDirection="row"
          justifyContent="space-between"
          theme={theme}
        >
          <Flex alignItems="center" fullHeight>
            <SideMenyHeaderTitle>Search</SideMenyHeaderTitle>
          </Flex>
          <ControlGroup>
            <Tooltip content="Clear search" position={Position.BOTTOM} openOnTargetFocus={false}>
              <AnchorButton
                icon="clean"
                minimal
                onClick={() => {
                  setFindQuery("");
                  setReplaceQuery("");
                  setFunc(null);
                  setParam(null);
                  findInput.current?.focus();
                }}
              />
            </Tooltip>
            <Divider />
            <Tooltip
              content="Search line items by name/tag."
              position={Position.BOTTOM}
              openOnTargetFocus={false}
            >
              <AnchorButton
                active={searchType === SearchTypes.LINE_ITEM}
                icon="stadium-geometry"
                minimal
                onClick={() => setSearchType(SearchTypes.LINE_ITEM)}
              />
            </Tooltip>
            <Tooltip content="Search formulas" position={Position.BOTTOM} openOnTargetFocus={false}>
              <AnchorButton
                active={searchType === SearchTypes.FX_RAW}
                icon="search-text"
                minimal
                onClick={() => setSearchType(SearchTypes.FX_RAW)}
              />
            </Tooltip>
            <Tooltip
              content="Search formulas by parameters."
              position={Position.BOTTOM}
              openOnTargetFocus={false}
            >
              <AnchorButton
                active={searchType === SearchTypes.FX_PARAM}
                icon="publish-function"
                minimal
                onClick={() => setSearchType(SearchTypes.FX_PARAM)}
              />
            </Tooltip>
          </ControlGroup>
        </SideMenuHeader>
        <FormGroup
          style={{ backgroundColor: theme === Themes.LIGHT ? colors.lightGray3 : colors.darkGray3 }}
        >
          {searchView}
          {searchType === SearchTypes.FX_PARAM || searchType === SearchTypes.FX_RAW ? (
            <Flex
              flexDirection="row"
              style={{
                borderTop:
                  theme === Themes.LIGHT
                    ? "1px solid " + colors.lightGray5
                    : "1px solid " + colors.darkGray5,
                borderBottom:
                  theme === Themes.LIGHT
                    ? "1px solid " + colors.lightGray3
                    : "1px solid " + colors.darkGray3,
              }}
            >
              <InputGroup
                id="text-input"
                fill
                leftIcon="text-highlight"
                onChange={(ev) => setReplaceQuery(ev.target.value)}
                placeholder="Replace with"
                style={{
                  borderRadius: 0,
                  boxShadow: "none",
                  backgroundColor: theme === Themes.LIGHT ? colors.lightGray4 : colors.darkGray3,
                }}
                value={replaceQuery}
              />
              <Tooltip content="Replace all" openOnTargetFocus={false}>
                <AnchorButton
                  disabled={(!findQuery && !param) || !findingsElems?.length || locked}
                  minimal
                  icon="swap-horizontal"
                  onClick={param ? submitFX : submit}
                />
              </Tooltip>
            </Flex>
          ) : null}
        </FormGroup>
      </MaybeStickyDiv>

      {!findQuery && !param ? null : findingsElems === null ? null : findingsElems.length > 0 ? (
        !running ? (
          <div>
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              style={{ padding: "0 10px 5px 10px" }}
            >
              <b>Results: {}</b>
              <span>
                {findingsCount.current} match{findingsCount.current > 1 ? "es" : ""}
              </span>
            </Flex>
            <HTMLTable
              bordered
              compact
              striped
              interactive
              style={{ width: "100%", overflowY: "scroll" }}
            >
              <tbody>
                {findingsElems.map((elem, idx) => (
                  <tr
                    key={`${idx}`}
                    onClick={() => {
                      navigateToObject(idx);
                    }}
                  >
                    <td>{elem}</td>
                  </tr>
                ))}
              </tbody>
            </HTMLTable>
          </div>
        ) : (
          <Spinner />
        )
      ) : (
        <H6 style={{ paddingLeft: "10px" }}>No results found.</H6>
      )}
    </div>
  );
};

// TODO: Remove this any when state is fully typed
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mapStateToProps(state: any) {
  return {
    currentCase: currentCaseDataSelector(state),
    modules: caseModulesSelector(state),
    lineItems: state.model.lineItems,
    facts: state.model.facts,
  };
}

const mapDispatchToProps = {
  editFormula,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
