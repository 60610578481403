import React from "react";
import "styles/sensitivity-analysis.scss";
import VSHTMLTable from "components/dashboard/VSHTMLTable";
import { useSelector } from "react-redux";
import { fetchTagData } from "utils/cpp";
import { lineItemsSelector, modelInfoSelector } from "selectors/modelSelectors";
import { factsSelector } from "selectors/dataEntrySelectors";
import { tagLineItemsMapSelector } from "selectors/cpp_selectors";

const marketDataTags = [
  "Share Price, Valuation (Base)",
  "BVPS {Valuation} (Base)",
  "Net Cash {Valuation} (Base)",
  "Shares Outstanding {Valuation} (Base)",
  "Net Cash Per Share {Valuation} (Base)",
];

const CPP_MarkteData = () => {
  const tagLineItemsMap = useSelector(tagLineItemsMapSelector);
  const lineItems = useSelector(lineItemsSelector);
  const facts = useSelector(factsSelector);
  const modelInfo = useSelector(modelInfoSelector);
  const startPeriod = modelInfo?.startPeriod;

  const marketData = [
    null,
    [
      "Share Price",
      fetchTagData(marketDataTags[0], startPeriod, tagLineItemsMap, lineItems, facts),
      "Book Value Per Share",
      fetchTagData(marketDataTags[1], startPeriod, tagLineItemsMap, lineItems, facts),
      "5yr Net Cash",
      fetchTagData(marketDataTags[2], startPeriod + 5, tagLineItemsMap, lineItems, facts),
    ],
    [
      "Shares Outstanding",
      fetchTagData(marketDataTags[3], startPeriod, tagLineItemsMap, lineItems, facts),
      "Net Cash Per Share",
      fetchTagData(marketDataTags[4], startPeriod, tagLineItemsMap, lineItems, facts),
    ],
  ];

  return <VSHTMLTable data={marketData} />;
};

export default CPP_MarkteData;
