import { EStatus } from "constants/apiConstants";
import { Store } from "redux";
import { IExtendedModelInfo } from "types/model";
import { IModelHistoryTable, isModelHistoryTable } from "types/modelHistory";
import { IVSDashboard } from "types/dashboard";
import { IconName, Intent } from "@blueprintjs/core";

export interface IAppConfig {
  dashboards: IVSDashboard[];
  debugMode?: boolean;
  home: IModelHistoryTable[] | null;
  // todo: new generic version
  //home: ITableConfig[] | null;
  modelTables: IModelHistoryTable[] | null;
  theme: TTheme;
}

// Type Guard
export const isAppConfig = (maybeAppConfig: unknown): maybeAppConfig is IAppConfig => {
  // Use a partial here to force us to check for props existing after coercion.
  const test = maybeAppConfig as Partial<IAppConfig>;

  const homeValid =
    "home" in test &&
    Array.isArray(test.home) &&
    test.home.every((maybeTable) => isModelHistoryTable(maybeTable));

  const modelTablesValid =
    "modelTables" in test &&
    Array.isArray(test.modelTables) &&
    test.modelTables.every((maybeTable) => isModelHistoryTable(maybeTable));

  return homeValid && modelTablesValid && "theme" in test && "debugMode" in test;
};

// Informs TS compiler that colors.module.scss is an importable module.
declare module "*.module.css";

interface IVSEnvironment {
  // [key: string]: string | undefined;
  REACT_APP_VERSION: string;
  REACT_APP_COGNITOREGION: string;
  REACT_APP_COGNITOUSERPOOLID: string;
  REACT_APP_COGNITOAPPCLIENTID: string;
  REACT_APP_URL: string;
  REACT_APP_SOCKET_URL: string;
  REACT_APP_SENTRY_DSN?: string;
  REACT_APP_RELEASE_PREFIX: string;
}

declare global {
  interface Window {
    __env__: IVSEnvironment;
    store?: Store;
  }
  namespace NodeJS {
    // eslint-disable-next-line
    interface ProcessEnv {
      REACT_APP_VERSION: string;
      REACT_APP_COGNITOREGION: string;
      REACT_APP_COGNITOUSERPOOLID: string;
      REACT_APP_COGNITOAPPCLIENTID: string;
      REACT_APP_URL: string;
      REACT_APP_SOCKET_URL: string;
      REACT_APP_SENTRY_DSN?: string;
      REACT_APP_RELEASE_PREFIX: string;
    }
  }
}

type ContentType = "text/html" | "application/json" | "multipart/form-data" | "none";

export interface IApiConfig {
  body?: BodyInit;
  method?: string;
  contentType?: ContentType;
  route?: string;
  [key: string]: unknown;
}

export interface IUnboundedConfig extends Omit<IApiConfig, "body"> {
  body?: BodyInit | { [key: string]: unknown } | unknown;
}

/**
 * Interface for generic VS server side response.
 * */
export interface IResponse<Dt> {
  status: EStatus;
  name?: string;
  data: Dt;
  error?: string;
  message?: string;
}

export interface IModelCopyData {
  action: string;
  buffered: boolean;
  grouped: boolean;
  message: string;
  messageID: string;
  model: IExtendedModelInfo;
}

export type TTheme = "light" | "dark";

export interface ISize {
  width?: number | null;
  height?: number | null;
  position?: { x: number; y: number } | null;
}

export interface IAppStatus {
  intent?: Intent;
  message?: string;
  icon?: IconName;
  loading: boolean;
}

export interface IActionReturnType<T, P> {
  readonly type: T;
  readonly payload?: P;
}

export interface IFactEdit {
  uid: string;
  formula: string;
}

export type AtLeastOnePropertyOf<T> = {
  [K in keyof T]: { [L in K]: T[L] } & { [L in Exclude<keyof T, K>]?: T[L] };
}[keyof T];

export enum ESortDirection {
  ASC = "asc",
  DESC = "desc",
}
