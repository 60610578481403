import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import styled, { css } from "styled-components/macro";
import { connect } from "react-redux";
import Auth from "@aws-amplify/auth";
import { Button, FormGroup, InputGroup, Intent } from "@blueprintjs/core";

import { Flex } from "components/utility/StyledComponents";
import { multiToaster } from "utils/toaster";
import SettingsView from "components/user/SettingsView";
import "styles/user/settings-item.scss";
import LockButton from "components/utility/LockButton";
import { signOut } from "utils/auth";

const AuthContainer = styled.form`
  width: ${({ width }) => width || css`30em`};
`;

const ChangePassword = () => {
  const [oldPw, setOldPw] = useState("");
  const [newPw, setNewPw] = useState("");
  const [confirmNewPw, setConfirmNewPw] = useState("");
  const [helperText, setHelperText] = useState({});
  const navigate = useNavigate();

  const [showNewPw, toggleShowNewPw] = useState(false);
  const [showCurrentPw, toggleShowCurrentPw] = useState(false);

  function noMatch() {
    setHelperText({ text: "Passwords do not match.", intent: Intent.WARNING });
    setConfirmNewPw("");
  }

  function changePassword(e) {
    e.preventDefault();
    if (newPw !== confirmNewPw) {
      noMatch();
      return;
    }
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPw, newPw);
      })
      .then((res) => {
        if (res === "SUCCESS") {
          multiToaster.show({
            message: "Successfully changed password. Please login again.",
            intent: Intent.SUCCESS,
          });
        }
        navigate("/");
        signOut();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setHelperText({ text: err.message, intent: Intent.WARNING });
        setNewPw("");
        setConfirmNewPw("");
        multiToaster.show({ message: err.message, intent: Intent.WARNING });
        Sentry.captureException(err);
      });
  }

  return (
    <SettingsView title="Change Password" helperText="Use the form below to change your password.">
      <AuthContainer onSubmit={changePassword}>
        <FormGroup className="form-group" label="Current password:" labelFor="current-password">
          <InputGroup
            id="current-password"
            type={showCurrentPw ? "text" : "password"}
            rightElement={
              <LockButton
                handleLockClick={() => toggleShowCurrentPw(!showCurrentPw)}
                showPassword={showCurrentPw}
              />
            }
            value={oldPw}
            onChange={(e) => setOldPw(e.target.value)}
          />
        </FormGroup>
        <FormGroup className="form-group" label="New password:" labelFor="new-password">
          <InputGroup
            id="new-password"
            type={showNewPw ? "text" : "password"}
            rightElement={
              <LockButton
                handleLockClick={() => {
                  toggleShowNewPw(!showNewPw);
                }}
                showPassword={showNewPw}
              />
            }
            value={newPw}
            onChange={(e) => setNewPw(e.target.value)}
          />
        </FormGroup>
        <FormGroup
          label="Confirm new password:"
          labelFor="confirm-new-password"
          className="form-group"
          intent={helperText.intent}
          helperText={helperText.text}
        >
          <InputGroup
            id="confirm-new-password"
            type={showNewPw ? "text" : "password"}
            intent={helperText.intent}
            rightElement={
              <LockButton
                handleLockClick={() => toggleShowNewPw(!showNewPw)}
                showPassword={showNewPw}
              />
            }
            value={confirmNewPw}
            onChange={(e) => setConfirmNewPw(e.target.value)}
          />
        </FormGroup>
        <Flex fullWidth flexDirection="column">
          <Button
            className="save-button"
            intent={Intent.PRIMARY}
            rightIcon="circle-arrow-right"
            style={{ alignSelf: "flex-end" }}
            text="Change Password"
            type="submit"
          />
        </Flex>
      </AuthContainer>
    </SettingsView>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ChangePassword);
