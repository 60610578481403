import React, {
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Classes,
  ControlGroup,
  Dialog,
  Divider,
  FormGroup,
  H5,
  H6,
  HTMLSelect,
  Icon,
  InputGroup,
  Intent,
  MenuItem,
  Spinner,
  SpinnerSize,
  Tag,
  UL,
} from "@blueprintjs/core";
import { ItemRenderer, MultiSelect2 } from "@blueprintjs/select";
import debounce from "lodash.debounce";
import shortid from "shortid";

import { EStatus, EMethods } from "constants/apiConstants";
import ProfileImage from "components/utility/ProfileImage";
import { Themes } from "constants/uiConstants";
import AppContext from "../../context/appContext";
import useShareModels from "hooks/useShareModels";
import { basicEmailValidate } from "utils/generic";
import { TTheme } from "types";
import { IModelInfo } from "types/model";
import { Flex, PaddedContent } from "components/utility/StyledComponents";
import useUser from "hooks/useUser";
import usePrevious from "hooks/usePrevious";
import { TModelHistoryTablePartialModel } from "types/modelHistory";
import usePermissions from "../../hooks/usePermissions";
import { IUser, IUserPermission } from "../../types/user";

interface IShareModelDialogProps {
  isOpen: boolean;
  modelInfo: IModelInfo | TModelHistoryTablePartialModel;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const UserMultiSelect = MultiSelect2.ofType<IUser>();

const ShareModelDialog: React.FC<IShareModelDialogProps> = ({ isOpen, modelInfo, setOpen }) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [query, setQuery] = useState("");
  const [shareByEmail, setShareByEmail] = useState(false);
  const { user } = useUser();
  const {
    mutate: mutatePermissions,
    permissions,
    updatePermissions,
    updating,
  } = usePermissions(modelInfo?.id);

  const { searching, searchResults, searchUsers, shareModels, sharing, shareResponse } =
    useShareModels(modelInfo?.id, permissions, mutatePermissions);

  const canEdit = useMemo(() => {
    if (modelInfo) {
      const permission = getPermission(modelInfo.permissions);
      return !permission || permission === "owner" || permission === "fullAccess";
    }
    return false;
  }, [modelInfo]);

  const sortedPermissions = useMemo(() => {
    if (permissions && user) {
      const sorted = [...permissions];
      const ownerIndex = sorted.findIndex((permission) => permission.userID === user.uid);
      const owner = sorted[ownerIndex];
      sorted.splice(ownerIndex, 1);
      sorted.splice(0, 0, owner);
      return sorted.filter((p) => p);
    }
    return undefined;
  }, [permissions, user]);

  const handleShare: FormEventHandler = (e): void => {
    e.preventDefault();
    if (shareByEmail) {
      shareModels([{ email, uid: shortid.generate() }], { view: true });
    } else {
      shareModels(selectedUsers, { view: true, edit: false, fullAccess: false });
    }
  };

  const onClose = () => {
    setOpen(false);
    setQuery("");
    setEmail("");
  };

  const toggleShareByEmail = () => {
    setShareByEmail(!shareByEmail);
  };

  const removePermission: TPermissionHandler = (email: string) => {
    updatePermissions(email, EMethods.DELETE);
  };

  const editPermission: TPermissionHandler = (email, permissionLevel) => {
    updatePermissions(email, EMethods.POST, permissionLevel);
  };

  useEffect(() => {
    if (searchResults) {
      setUsers(searchResults?.data || []);
    }
  }, [searchResults]);

  useEffect(() => {
    if (shareResponse && shareResponse.status === EStatus.success) {
      setQuery("");
      setEmail("");
      setSelectedUsers([]);
    }
  }, [shareResponse]);

  const handleEmailChange: FormEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.currentTarget.value);
  };

  const debouncedSearchHandler = useMemo(() => {
    return debounce(searchUsers, 400);
  }, [searchUsers]);

  const handleQueryChange = (query: string) => {
    setQuery(query);
    debouncedSearchHandler(query);
  };

  const itemRenderer: ItemRenderer<IUser> = (user, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate || !sortedPermissions) return null;
    const isUserSelected =
      selectedUsers.findIndex((selectedUser) => selectedUser.uid === user.uid) !== -1;
    const hasPermissions =
      sortedPermissions.findIndex((userPermission) => userPermission.userID === user.uid) !== -1;
    return (
      <MenuItem
        active={modifiers.active}
        disabled={hasPermissions}
        key={user.uid}
        icon={isUserSelected || hasPermissions ? "tick" : null}
        labelElement={<Icon icon="user" />}
        onClick={handleClick}
        text={user.fullname}
      />
    );
  };

  const tagRenderer = (user: IUser): React.ReactNode => {
    return (
      <Tag icon="user" minimal>
        {user.fullname}
      </Tag>
    );
  };

  const onUserSelect = (user: IUser) => {
    const isUserSelected =
      selectedUsers.findIndex((selectedUser) => selectedUser.uid === user.uid) !== -1;
    if (isUserSelected) {
      onUserRemove(user);
    } else {
      setSelectedUsers(selectedUsers.concat([user]));
      setQuery("");
    }
  };

  const onUserRemove = (userToRemove: IUser) => {
    setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.uid !== userToRemove.uid));
  };

  useEffect(() => {
    if (basicEmailValidate(email)) setEmailValid(true);
    else setEmailValid(false);
  }, [email]);

  return (
    <Dialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      icon={
        sortedPermissions && !sharing ? (
          "share"
        ) : (
          <div style={{ marginRight: 10 }}>
            <Spinner size={SpinnerSize.SMALL} />
          </div>
        )
      }
      isOpen={isOpen}
      onClose={onClose}
      title={sortedPermissions ? "Share Model" : "Loading..."}
    >
      <div className={Classes.DIALOG_BODY}>
        <p className={Classes.RUNNING_TEXT}>
          Use the list below to view users that have access to this model and customise their level
          of access.
        </p>
        <UL>
          <li>
            <strong>View:</strong> Users can view models (including all data) but not edit the model
            or share it.
          </li>
          <li>
            <strong>Edit:</strong> Users can view and edit the model but not share it.
          </li>
          <li>
            <strong>Full Access:</strong> Users can view, edit, share and delete the model as if
            they were the owner.
          </li>
        </UL>
        <form onSubmit={handleShare}>
          <FormGroup intent={shareResponse?.status === EStatus.failed ? Intent.DANGER : undefined}>
            <ControlGroup>
              <UserMultiSelect
                fill
                tagInputProps={{
                  disabled: !canEdit || shareByEmail || sharing,
                  placeholder: canEdit ? "Add people" : "Insufficient access!",
                  rightElement: searching ? <Spinner size={SpinnerSize.SMALL} /> : undefined,
                  tagProps: { minimal: true },
                }}
                items={users}
                itemRenderer={itemRenderer}
                noResults={
                  <MenuItem
                    disabled={!searching}
                    text={
                      query.length > 0
                        ? searching
                          ? "Loading..."
                          : "No results!"
                        : "Begin typing to search..."
                    }
                  />
                }
                onItemSelect={onUserSelect}
                onQueryChange={handleQueryChange}
                onRemove={onUserRemove}
                popoverProps={{ minimal: true }}
                query={query}
                selectedItems={selectedUsers}
                tagRenderer={tagRenderer}
              />
              <Button
                disabled={shareByEmail || sharing || selectedUsers.length < 1}
                onClick={handleShare}
                intent={Intent.PRIMARY}
                rightIcon="share"
                text="Share model"
              />
            </ControlGroup>
          </FormGroup>
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            style={{ maxHeight: 196, overflow: "auto" }}
          >
            {sortedPermissions
              ? sortedPermissions.map((userPermission) => {
                  return (
                    <PermissionController
                      activeUserID={user?.uid}
                      canEdit={canEdit}
                      key={userPermission.userID}
                      onPermissionChange={editPermission}
                      isOwner={userPermission.owner || false}
                      userPermission={userPermission}
                      removePermission={removePermission}
                      updating={updating || sharing}
                    />
                  );
                })
              : new Array(3).map((_, key) => (
                  <H5 className={Classes.SKELETON} key={key}>
                    -----------------------------------
                  </H5>
                ))}
          </Flex>
          <PaddedContent padding="10px 0 10px 0">
            <Button
              active={shareByEmail}
              disabled
              intent={Intent.PRIMARY}
              minimal
              onClick={toggleShareByEmail}
              small
              style={{ marginBottom: 5 }}
              text="Share by email"
            />
            {shareByEmail ? (
              <FormGroup
                intent={shareResponse?.status === EStatus.failed ? Intent.DANGER : undefined}
                label="Enter the email of a user to share model with:"
                helperText={email.length > 0 && !emailValid ? "Email not valid!" : undefined}
              >
                <ControlGroup fill>
                  <InputGroup onChange={handleEmailChange} type="email" value={email} />
                  <Button icon="plus" onClick={handleShare} text="Add" />
                </ControlGroup>
              </FormGroup>
            ) : null}
          </PaddedContent>
        </form>
      </div>
    </Dialog>
  );
};

const PermissionLevels: { [key: string]: { label: string; value: string } } = {
  view: { label: "View", value: "view" },
  edit: { label: "Edit", value: "edit" },
  fullAccess: { label: "Full Access", value: "fullAccess" },
};

const Permissions = {
  view: false,
  edit: false,
  fullAccess: false,
  owner: false,
};

type TPermissionHandler = (email: string, permissionLevel?: { [key: string]: boolean }) => void;

interface IPermissionController {
  activeUserID?: string;
  canEdit: boolean;
  userPermission: IUserPermission;
  isOwner: boolean;
  onPermissionChange: TPermissionHandler;
  removePermission: TPermissionHandler;
  updating: boolean;
}

export const getPermission = (
  permission: Partial<IUserPermission> | undefined
): string | undefined => {
  if (!permission) return;
  return Object.keys(Permissions).find((permissionLevel) => {
    return permission[permissionLevel as "view" | "edit" | "fullAccess"];
  });
};

const PermissionController: React.FC<IPermissionController> = ({
  activeUserID,
  canEdit,
  userPermission,
  isOwner,
  onPermissionChange,
  removePermission,
  updating,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const onChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    onPermissionChange(userPermission.email, { ...Permissions, [e.currentTarget.value]: true });
  };
  const {
    config: { theme },
  } = useContext(AppContext);

  const openDeleteDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const permission = getPermission(userPermission);
  const { email, fullname, userID } = userPermission;

  return (
    <PaddedContent padding="6px" fullWidth>
      <ConfirmDeleteDialog
        fullname={fullname}
        email={email}
        isOpen={dialogOpen}
        onClose={closeDialog}
        removePermission={removePermission}
        theme={theme}
        user={userPermission}
        removing={updating}
      />
      <Flex alignItems="center" key={userID} fullWidth justifyContent="space-between">
        <Flex>
          <ProfileImage
            fill={false}
            fontSize={15}
            fullname={userPermission.fullname}
            size={{ height: 30, width: 30, position: null }}
            theme={theme}
            tooltip={false}
          />
          <Divider />
          <Flex flexDirection="column" alignItems="flex-start">
            <H6 style={{ margin: 0 }}>{fullname}</H6>
            <p className={Classes.RUNNING_TEXT} style={{ margin: 0 }}>
              {email}
            </p>
          </Flex>
        </Flex>
        {isOwner ? (
          <H6 className={Classes.TEXT_MUTED} style={{ fontStyle: "italic" }}>
            Owner
          </H6>
        ) : (
          <ControlGroup>
            <HTMLSelect
              disabled={!canEdit || updating}
              onChange={onChange}
              options={Object.values(PermissionLevels)}
              value={permission}
            />
            <Button
              disabled={(!canEdit && activeUserID !== userID) || updating}
              icon="delete"
              onClick={openDeleteDialog}
            />
          </ControlGroup>
        )}
      </Flex>
    </PaddedContent>
  );
};

interface IConfirmDeleteDialogProps {
  fullname: string;
  email: string;
  isOpen: boolean;
  onClose: () => void;
  removePermission: TPermissionHandler;
  theme: TTheme;
  user: IUserPermission;
  removing: boolean;
}

const ConfirmDeleteDialog: React.FC<IConfirmDeleteDialogProps> = ({
  isOpen,
  onClose,
  fullname,
  email,
  removePermission,
  removing,
  theme,
}) => {
  const wasRemoving = usePrevious(removing);
  const onClick = () => {
    removePermission(email);
  };
  useEffect(() => {
    if (wasRemoving && !removing) {
      onClose();
    }
  }, [onClose, removing, wasRemoving]);
  return (
    <Dialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      icon="delete"
      isOpen={isOpen}
      onClose={onClose}
      title="Remove Access"
    >
      <div className={Classes.DIALOG_BODY}>
        <p className={Classes.RUNNING_TEXT}>
          Removing access permissions for user: <strong>{fullname}</strong> ({email}).
        </p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose} icon="cross" text="Cancel" />
          <Button
            onClick={onClick}
            icon="delete"
            loading={removing}
            intent={Intent.DANGER}
            text="Remove"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ShareModelDialog;
