import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
  Spinner,
  SpinnerSize,
  TextArea,
} from "@blueprintjs/core";
import AppContext from "context/appContext";
import { Themes } from "constants/uiConstants";
import { singleToaster } from "utils/toaster";

export interface IFormDialogField<T> {
  id: keyof T;
  type: "text" | "textarea";
  pattern?: RegExp;
  label: string;
  optional?: boolean;
}

export interface IFormDialogProps<T> {
  title: string;
  fields: IFormDialogField<T>[];
  isOpen: boolean;
  values?: T;
  loading?: boolean;
  submit: (values: Partial<T>) => Promise<boolean | void> | void;
  setDialogOpen?: (isOpen: boolean) => void;
  submitText?: string;
  submitting?: boolean;
  successToastMessage?: string;
  failureToastMessage?: string;
}

/** Generic dialog to edit props of an entity */
const FormDialog = <T,>(props: IFormDialogProps<T>) => {
  const {
    title,
    fields,
    values,
    loading = false,
    submit,
    isOpen,
    setDialogOpen,
    submitting = false,
    submitText = "Update",
    successToastMessage,
    failureToastMessage,
  } = props;
  const {
    config: { theme },
  } = useContext(AppContext);
  const [currentValues, setCurrentValues] = useState<Partial<T>>(values || {});
  const [localSubmitting, setLocalSubmitting] = useState<boolean>(false);

  const closeDialog = useCallback(() => {
    if (setDialogOpen) {
      setDialogOpen(false);
    }
  }, [setDialogOpen]);

  const onSubmit = async () => {
    setLocalSubmitting(true);
    const success = await submit(currentValues);
    setLocalSubmitting(false);
    if (success && successToastMessage) {
      singleToaster.show({ intent: Intent.SUCCESS, message: successToastMessage });
    }
    if (!success && failureToastMessage) {
      singleToaster.show({ intent: Intent.DANGER, message: failureToastMessage });
    }
    closeDialog();
  };

  const updateValue = (id: string, value: string) => {
    setCurrentValues({
      ...currentValues,
      [id]: value,
    });
  };

  const onOpening = () => {
    setLocalSubmitting(false);
    setCurrentValues({ ...values } || {});
  };

  /** assign values if loading completes */
  useEffect(() => {
    if (!loading) setCurrentValues({ ...values } || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <Dialog
      className={theme === Themes.DARK ? Classes.DARK : ""}
      isOpen={isOpen}
      onClose={submitting ? undefined : closeDialog}
      onOpening={onOpening}
      title={title}
    >
      <div className={Classes.DIALOG_BODY}>
        {loading ? (
          <Spinner size={SpinnerSize.LARGE} />
        ) : (
          fields.map((field, i) => (
            <FormGroup
              key={field.id as string}
              label={field.label}
              labelInfo={!field.optional && "(required)"}
            >
              {field.type === "text" && (
                <InputGroup
                  autoFocus={i === 0}
                  value={(currentValues[field.id] as string) || ""}
                  onInput={(e) =>
                    updateValue(field.id as string, (e.target as HTMLInputElement).value)
                  }
                />
              )}
              {field.type === "textarea" && (
                <TextArea
                  autoFocus={i === 0}
                  value={(currentValues[field.id] as string) || ""}
                  onInput={(e) =>
                    updateValue(field.id as string, (e.target as HTMLTextAreaElement).value)
                  }
                  style={{ height: "6em" }}
                  fill={true}
                />
              )}
            </FormGroup>
          ))
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            className={Classes.DIALOG_CLOSE_BUTTON}
            disabled={submitting || localSubmitting}
            onClick={closeDialog}
            text="Cancel"
          />
          <Button
            disabled={fields.some((field) => {
              return !field.optional && !currentValues[field.id];
            })}
            loading={submitting || localSubmitting}
            intent={Intent.PRIMARY}
            onClick={onSubmit}
            text={submitText}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default FormDialog;
