import React, { Dispatch, Fragment, SetStateAction, useContext, useRef, useState } from "react";
import {
  Alignment,
  Button,
  Card,
  Classes,
  DialogFooter,
  DialogProps,
  Divider,
  EditableText,
  FormGroup,
  H4,
  H5,
  HTMLTable,
  Icon,
  InputGroup,
  Intent,
  Switch,
  Tag,
  UL,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

import { Flex, FullWidthDialog, NormalAlignDivider } from "components/utility/StyledComponents";
import { IModelHistoryInfo, IModelHistoryTable } from "types/modelHistory";
import { TableWidgetColumnConfig } from "types/widget";
import { IAppConfig } from "types";
import { multiToaster } from "utils/toaster";

import AppContext from "context/appContext";
import { Themes } from "constants/uiConstants";

import colors from "styles/colors.module.scss";
import { reorder } from "utils/generic";
import useSetAndGetConfig from "hooks/useSetAndGetConfig";
import useUser from "hooks/useUser";
import LoadingIndicator from "components/utility/LoadingIndicator";
import { flushSync } from "react-dom";

interface IEditModelTableDialogProps extends DialogProps {
  editKey: "modelTables" | "home";
  modelTable: IModelHistoryTable;
  sampleModel?: IModelHistoryInfo | null;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const EditModelTableDialog: React.FC<IEditModelTableDialogProps> = ({
  editKey,
  isOpen,
  modelTable,
  setDialogOpen,
}) => {
  const { user } = useUser();
  const {
    config: { theme },
  } = useContext(AppContext);
  const { config, update } = useSetAndGetConfig<IAppConfig>(
    "appConfig",
    user?.uid || null,
    "userID"
  );
  const [query, setQuery] = useState("");
  const [modified, setModified] = useState<boolean>(false);
  const navigate = useNavigate();
  const { defaultConfig } = useSetAndGetConfig<{
    availableFields: TableWidgetColumnConfig[] | null;
  }>("availableFields", null, null);

  const previewTableScrollContainerRef = useRef<HTMLDivElement>(null);

  const [dirtyModelTable, setDirtyModelTable] = useState(modelTable);

  const saveChanges = () => {
    // Save the changes, triggering expensive requests and updates...
    const tables = config?.[editKey];
    if (tables) {
      const nextTables = [...tables];
      const editIndex = nextTables?.findIndex((t) => t.uid === modelTable.uid);
      nextTables.splice(editIndex, 1, dirtyModelTable);
      // If name has changes, we need to context dependantly update the ui.
      if (dirtyModelTable.name !== modelTable.name) {
        if (editKey === "modelTables") {
          navigate(`/models/${dirtyModelTable.uid}`, { replace: true });
        }
        if (editKey === "home") {
          navigate(`/?table=${dirtyModelTable.uid}`, { replace: true });
        }
      }
      update({ [editKey]: nextTables });
      setModified(false);
      setDialogOpen(false);
    } else {
      // TODO: Handle missing config opt.
      multiToaster.show({
        intent: Intent.DANGER,
        message: "Unable to save changes due to a problem with the configuration.",
      });
    }
  };

  const handleAddOrRemoveColumn = (column: TableWidgetColumnConfig) => {
    const hasColumn = dirtyModelTable.columns.findIndex((col) => col.id === column.id) !== -1;
    if (hasColumn) {
      // remove column
      const nextColumns = dirtyModelTable.columns?.filter((col) => col.id !== column.id);
      setDirtyModelTable({ ...dirtyModelTable, columns: nextColumns });
    } else {
      // add column
      const nextColumns = dirtyModelTable.columns.concat([column]);
      // wrap setState call inside flush sync to force update to the DOM prior to scrolling to end.
      flushSync(() => {
        setDirtyModelTable({ ...dirtyModelTable, columns: nextColumns });
      });
      if (previewTableScrollContainerRef?.current) {
        previewTableScrollContainerRef.current.scrollLeft =
          previewTableScrollContainerRef.current.scrollWidth;
      }
    }
    setModified(true);
  };

  const handleEditColumnName = (columnID: string, newName: string) => {
    const nextTable = {
      ...dirtyModelTable,
      columns: dirtyModelTable.columns.map((col) =>
        col.id === columnID ? { header: newName, id: columnID } : col
      ),
    };

    setDirtyModelTable(nextTable);
    setModified(true);
  };

  const editTableProperty = <K extends keyof IModelHistoryTable>(
    key: K,
    value: IModelHistoryTable[K]
  ) => {
    let uid = dirtyModelTable.uid;
    if (key === "name") {
      // If name, we need to update the uid. We also know that value will be of type string.
      uid = (value as string).toLowerCase().replaceAll(" ", "-");
    }
    const nextTable = {
      ...dirtyModelTable,
      [key]: value,
      uid,
    };

    setDirtyModelTable(nextTable);
    setModified(true);
  };

  const handleColumnReorder = (result: DropResult) => {
    if (!result.destination) return;

    const nextColumns = reorder(
      dirtyModelTable.columns,
      result.source.index,
      result.destination.index
    );

    editTableProperty("columns", nextColumns as TableWidgetColumnConfig[]);
    setModified(true);
  };

  const onClose = () => {
    let close = true;
    if (modified) {
      close = confirm(
        "You have unsaved changes. Press 'OK' to continue without saving or 'Cancel' to remain on this screen."
      );
    }
    if (close) {
      setDialogOpen(false);
    }
  };

  return (
    <FullWidthDialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      icon={IconNames.EDIT}
      isOpen={isOpen}
      onClose={onClose}
      title="Edit Model Table"
    >
      <Flex
        className={Classes.DIALOG_BODY}
        flexDirection="column"
        justifyContent="flex-start"
        style={{ height: "78vh" }}
      >
        <Card
          style={{
            flex: "0 0 15%",
            justifyContent: "flex-start",
            marginBottom: "15px",
            width: "100%",
          }}
        >
          <Flex alignItems="flex-start" columnGap={20} fullWidth justifyContent="flex-start">
            <H4>
              <EditableText
                defaultValue={modelTable.name}
                intent={Intent.PRIMARY}
                onConfirm={(nextName: string) => {
                  editTableProperty("name", nextName);
                }}
              />
            </H4>
            <NormalAlignDivider />
            <FormGroup helperText="Whether the row index should be indicated in the first column.">
              <Switch
                alignIndicator={Alignment.RIGHT}
                onChange={() =>
                  editTableProperty("displayRowIndex", !dirtyModelTable.displayRowIndex)
                }
                checked={dirtyModelTable.displayRowIndex}
                label="Display row index"
                style={{ marginTop: 0 }}
              />
            </FormGroup>
          </Flex>
        </Card>
        <DragDropContext onDragEnd={handleColumnReorder}>
          <Flex
            alignItems="flex-start"
            columnGap={20}
            flex="1 1 "
            flexDirection="row"
            fullWidth
            justifyContent="flex-start"
            style={{ overflow: "auto" }}
          >
            <Card
              style={{
                flex: "30%",
                height: "100%",
              }}
            >
              <div style={{ height: "100%", overflow: "auto" }}>
                <div
                  style={{
                    backgroundColor:
                      theme === Themes.LIGHT
                        ? colors.moduleBackgroundColor
                        : colors.darkModuleBackgroundColor,
                    marginBottom: 2,
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                  }}
                >
                  <H5>Available Columns</H5>
                  <p className={Classes.RUNNING_TEXT}>
                    Click a greyed out column tag to add it to the table. Use the cross to remove an
                    active column tag.
                  </p>
                  <p className={Classes.RUNNING_TEXT}>
                    Columns are in the following format:{" "}
                    <Tag minimal>
                      <strong>Header </strong>- Lookup
                    </Tag>
                  </p>
                  <InputGroup
                    leftIcon={IconNames.SEARCH}
                    placeholder="Search columns..."
                    type="search"
                    value={query}
                    onChange={(e) => setQuery(e.currentTarget.value.toLowerCase())}
                  />
                  <Divider />
                </div>
                <div>
                  {defaultConfig?.availableFields ? (
                    defaultConfig?.availableFields
                      .filter(
                        (col) =>
                          col.header.toLowerCase().includes(query) ||
                          col.id.toLowerCase().includes(query)
                      )
                      .map((col) => {
                        const activeColumn =
                          dirtyModelTable.columns.findIndex(
                            (activeCol) => col.id === activeCol.id
                          ) !== -1;
                        return (
                          <Fragment key={col.id}>
                            <Tag
                              intent={activeColumn ? Intent.PRIMARY : undefined}
                              interactive={!activeColumn}
                              key={col.id}
                              minimal
                              multiline
                              onClick={
                                !activeColumn ? () => handleAddOrRemoveColumn(col) : undefined
                              }
                              onRemove={
                                activeColumn ? () => handleAddOrRemoveColumn(col) : undefined
                              }
                              style={{ marginBottom: 4 }}
                            >
                              <strong>{col.header}</strong> - {col.id}
                            </Tag>
                            <br />
                          </Fragment>
                        );
                      })
                  ) : (
                    <LoadingIndicator
                      loading={true}
                      status={{ message: "Loading available columns..." }}
                    />
                  )}
                </div>
              </div>
            </Card>
            <div
              ref={previewTableScrollContainerRef}
              style={{ flex: "1 0 70%", height: "100%", overflow: "auto" }}
            >
              <div style={{ left: 0, position: "sticky" }}>
                <H5>Preview</H5>
                <p className={Classes.RUNNING_TEXT}>The following controls are available:</p>
                <UL>
                  <li>
                    Reorder columns using the drag handle on the left hand side of the header. (
                    <Icon icon={IconNames.DRAG_HANDLE_VERTICAL} />)
                  </li>
                  <li>Rename a column by clicking into its header text.</li>
                  <li>
                    Remove a column by either clicking the cross on the right hand side of the
                    header.
                  </li>
                </UL>
                <p className={Classes.RUNNING_TEXT}>
                  Column resizing is done on the table itself, <strong>not</strong> in this dialog.
                </p>
              </div>
              <HTMLTable bordered>
                <thead>
                  <Droppable direction="horizontal" droppableId="preview-table">
                    {(provided) => (
                      <tr ref={provided.innerRef} {...provided.droppableProps}>
                        {dirtyModelTable.columns.map((column, index) => (
                          <Draggable draggableId={column.id} index={index} key={column.id}>
                            {(provided) => (
                              <th
                                key={column.id}
                                ref={provided.innerRef}
                                style={{ verticalAlign: "middle" }}
                                {...provided.draggableProps}
                              >
                                <Flex fullWidth justifyContent="space-between" columnGap={4}>
                                  <Icon
                                    icon={IconNames.DRAG_HANDLE_VERTICAL}
                                    {...provided.dragHandleProps}
                                  />
                                  <EditableText
                                    defaultValue={column.header}
                                    onConfirm={(nextName: string) =>
                                      handleEditColumnName(column.id, nextName)
                                    }
                                  />
                                  <Button
                                    icon={IconNames.CROSS}
                                    minimal
                                    onClick={() => handleAddOrRemoveColumn(column)}
                                    small
                                  />
                                </Flex>
                              </th>
                            )}
                          </Draggable>
                        ))}
                      </tr>
                    )}
                  </Droppable>
                </thead>
              </HTMLTable>
            </div>
          </Flex>
        </DragDropContext>
      </Flex>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose} text="Cancel" />
          <Button intent={Intent.PRIMARY} onClick={saveChanges} text="Update" />
        </div>
      </DialogFooter>
    </FullWidthDialog>
  );
};

export default EditModelTableDialog;
