import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import { Alignment, Button, FormGroup, MenuItem } from "@blueprintjs/core";
import { ItemRenderer, Select2 } from "@blueprintjs/select";

import { IModule, IModuleInfo } from "types/model";

interface IStyledFormGroupProps {
  margin: number;
}

const ModuleSelect = Select2.ofType<IModule>();

const StyledFormGroup = styled(FormGroup)<IStyledFormGroupProps>`
  ${({ margin }) =>
    css`
      margin: ${margin}px;
    `}
`;

interface IDataEntryModuleSelect {
  currentModule?: IModuleInfo;
  disabled?: boolean;
  fill?: boolean;
  helperText?: string;
  id?: string;
  inline?: boolean;
  label?: string;
  margin?: number;
  moduleList?: IModule[];
  onItemSelect: (item: IModule) => void;
  onClose?: () => void;
}

const DataEntryModuleSelect: React.FC<IDataEntryModuleSelect> = ({
  currentModule,
  disabled = false,
  fill = false,
  helperText = "",
  id = "select-module",
  inline = false,
  label = "Select module:",
  margin = 0,
  moduleList,
  onClose,
  onItemSelect,
}) => {
  const [modulePredicate, setModulePredicate] = useState("");
  const [activeItem, setActiveItem] = useState<IModule>();

  useEffect(() => {
    if (currentModule && moduleList) {
      const active = moduleList.find((mod) => mod.name === currentModule.name);
      setActiveItem(active);
    }
  }, [currentModule, moduleList]);

  /**
   * Renders the module MenuItems
   * */
  const renderModules: ItemRenderer<IModuleInfo> = (item, { handleClick, modifiers }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={`${item.name}-${item.uid}`}
        label={item.label}
        onClick={handleClick}
        text={item.name}
        icon="cube"
      />
    );
  };

  function itemPredicate(query: string, item: IModuleInfo) {
    return item.name ? item.name.toLowerCase().includes(query.toLowerCase()) : true;
  }

  return (
    <StyledFormGroup
      className="data-entry-module-selector"
      helperText={helperText}
      inline={inline}
      label={label}
      labelFor={id}
      margin={margin}
    >
      <ModuleSelect
        activeItem={activeItem}
        disabled={disabled}
        fill={fill}
        itemPredicate={itemPredicate}
        itemRenderer={renderModules}
        items={moduleList || []}
        onItemSelect={onItemSelect}
        onQueryChange={setModulePredicate}
        popoverProps={{ onClose }}
        query={modulePredicate}
      >
        <Button
          alignText={Alignment.LEFT}
          className="target"
          disabled={disabled}
          icon="cube"
          fill={fill}
          loading={!disabled && (!currentModule || !moduleList)}
          rightIcon="double-caret-vertical"
          style={{ minWidth: "200px" }}
          text={currentModule?.name}
        />
      </ModuleSelect>
    </StyledFormGroup>
  );
};

export default DataEntryModuleSelect;
