import { Intent } from "@blueprintjs/core";
import { EMethods, EStatus, FETCH_PORTFOLIOS_ROUTE, PORTFOLIO_ROUTE } from "constants/apiConstants";
import { useCallback } from "react";
import useSWR from "swr";
import { IResponse } from "types";
import { Portfolio } from "types/collections";
import api, { swrApi } from "utils/api";
import { multiToaster } from "utils/toaster";

const usePortfolios = () => {
  const {
    data: portfoliosResponse,
    // error: fetchPortfoliosError,
    mutate: mutatePortfolios,
    isLoading,
  } = useSWR<IResponse<Portfolio[]>>([FETCH_PORTFOLIOS_ROUTE], swrApi);

  const deletePortfolio = useCallback(
    async (portfolio: Portfolio) => {
      const response = await api(PORTFOLIO_ROUTE, {
        watchlistId: portfolio.id,
        method: EMethods.DELETE,
      });
      if (response.status === EStatus.success) {
        multiToaster.show({
          intent: Intent.SUCCESS,
          message: `${portfolio.name} was deleted.`,
        });
        if (portfoliosResponse?.data) {
          const nextPortfolios = portfoliosResponse?.data?.filter((p) => p.id !== portfolio.id);
          mutatePortfolios(
            { ...portfoliosResponse, status: EStatus.success, data: nextPortfolios as Portfolio[] },
            false
          );
        }
      } else {
        multiToaster.show({ intent: Intent.WARNING, message: response.error });
      }
    },
    [mutatePortfolios, portfoliosResponse]
  );

  return {
    deletePortfolio,
    loadingPortfolios: isLoading,
    portfolios: portfoliosResponse?.data,
  };
};

export default usePortfolios;
