import { Intent } from "@blueprintjs/core";
import { EMethods, EStatus, WATCHLIST_CONFIG_ROUTE, WATCHLIST_ROUTE } from "constants/apiConstants";
import { useCallback, useState } from "react";
import useSWR from "swr";
import { IResponse } from "types";
import { MatchingModel, Watchlist } from "types/collections";
import { Dashboard } from "types/dashboard";
import api, { swrApi } from "utils/api";
import { multiToaster } from "utils/toaster";

/***
 * Hook for dealing with an individual watchlist. Takes a single watchlist id
 * as an optional parameter.
 *
 * Provides the following functionality:
 * - Fetch a specific watchlist.
 * - Edit the fetched watchlist
 * - Delete the supplied watchlist. The watchlist will then return as non-extant.
 *   Deletion handles editing the main watchlist list as well.
 */
const useWatchlist = (watchlistId?: string) => {
  const [editingWatchlist, setEditingWatchlist] = useState(false);
  const {
    data: watchlistResponse,
    isLoading,
    mutate,
  } = useSWR<IResponse<{ watchlist: Watchlist; matchingModels: MatchingModel[] | null }>>(
    watchlistId ? [WATCHLIST_ROUTE, { watchlistId }] : null,
    swrApi
  );

  const { data: watchlistConfig } = useSWR<IResponse<{ dashboard: Dashboard }>>(
    watchlistId ? [WATCHLIST_CONFIG_ROUTE, { watchlistId }] : null,
    swrApi
  );

  const editWatchlist = useCallback(
    async (edit: Partial<Watchlist>) => {
      setEditingWatchlist(true);

      const body = {
        ...watchlistResponse?.data?.watchlist,
        ...edit,
      };

      const previousWatchlist = watchlistResponse?.data?.watchlist;

      mutate(
        {
          ...watchlistResponse,
          status: EStatus.success,
          data: { ...watchlistResponse?.data, watchlist: body } as {
            matchingModels: MatchingModel[] | null;
            watchlist: Watchlist;
          },
        },
        false
      );

      const response = await api<{ matchingModels: MatchingModel[] | null; watchlist: Watchlist }>(
        WATCHLIST_ROUTE,
        {
          body,
          method: EMethods.PUT,
          watchlistId,
        }
      );

      // Replaces the matching models with those returned from the server.
      // We cannot simply spread the whole object as the endpoint returns some
      // incorrect metadata
      mutate(
        {
          ...watchlistResponse,
          status: EStatus.success,
          data: {
            watchlist: {
              ...response?.data.watchlist,
              previewWidgetId: watchlistResponse?.data.watchlist.previewWidgetId,
            },
            matchingModels:
              response?.data?.matchingModels ?? watchlistResponse?.data.matchingModels,
          } as {
            matchingModels: MatchingModel[] | null;
            watchlist: Watchlist;
          },
        },
        false
      );

      if (response.status !== EStatus.success) {
        multiToaster.show({ message: response.error, intent: Intent.WARNING });
        mutate(
          {
            ...watchlistResponse,
            status: EStatus.success,
            data: { ...watchlistResponse?.data, watchlist: previousWatchlist } as {
              matchingModels: MatchingModel[] | null;
              watchlist: Watchlist;
            },
          },
          false
        );
      }

      setEditingWatchlist(false);

      return response;
    },
    [mutate, watchlistResponse, watchlistId]
  );

  return {
    editingWatchlist,
    editWatchlist,
    loadingWatchlist: isLoading,
    matchingModels: watchlistResponse?.data?.matchingModels,
    watchlist: watchlistResponse?.data?.watchlist,
    watchlistConfig: watchlistConfig?.data,
  };
};

export default useWatchlist;
