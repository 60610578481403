import { LanguageSupport, LRLanguage, syntaxTree } from "@codemirror/language";
import { styleTags, tags as t } from "@lezer/highlight";
import { SyntaxNode } from "@lezer/common";
import { parser } from "utils/codemirror/vsl.parser";
import { TVSLAutoCompleter } from "types/vsl";

const autoCompleteInfo = {
  topLevelDirectives: ["External", "Filter", "ReturnSelectors"],
  builtinFunctions: ["Selector"],
  nextDirectives: {
    Filter: ["Column", "History", "Histories", "Series"],
    External: ["Filter"],
    Column: ["Column", "Table"],
    Series: ["LineChart", "BarChart"],
    Histories: ["LineChart", "BarChart"],
    History: ["History", "LineChart", "BarChart"],
  },
  params: {
    /*
     * keys are either directive/function names or TopLevelDirective.Directive
     *
     * there should only be one matching key per context, as completions come
     * from the first matching key
     *
     * to create common values for e.g. Filter.Series and External.Series, the
     * values must be added to both arrays
     */
    Selector: [
      "label",
      "source",
      "field",
      "options",
      "given",
      "dashboardSelector",
      "widgetSelector",
    ],
    // matches any External directive
    External: ["from"],
    Filter: ["tags", "analyst", "industry", "ticker", "modelID"],
    Column: ["label", "field", "tag", "expression"],
    History: ["label", "tag"],
    Histories: ["modelFieldLabel", "tag"],
    // matches Series directives where the top-level (first) directive is Filter
    "Filter.Series": ["modelFieldLabel", "lineItem"],
    // matches Series directives where the top-level directive is External
    "External.Series": ["fieldLabel", "item"],
    Table: [],
    LineChart: ["start", "end"],
    BarChart: [],
  },
  values: {
    "Selector.source": ['"models"'],
    "Selector.dashboardSelector": ["TRUE", "FALSE"],
    "Selector.widgetSelector": ["TRUE", "FALSE"],
    "Column.field": ['"analyst"', '"ticker"', '"tags"', '"modelID"'],
    "Series.modelFieldLabel": ['"ticker"'],
    "Series.fieldLabel": ['"source"'],
    "Histories.modelFieldLabel": ['"ticker"'],
    "External.from": ['"market"', '"fundamentals"'],
  },
};

function getPossibleParamNames(vsl: string, directive: SyntaxNode) {
  const chain = directive?.parent;
  const identifier = directive?.firstChild;
  const topLevelIdentifier = chain?.firstChild?.firstChild;
  if (!topLevelIdentifier || !identifier) return [];
  const name = vsl.substring(identifier.from, identifier.to);
  const topLevelName = vsl.substring(topLevelIdentifier.from, topLevelIdentifier.to);

  for (const [selector, candidates] of Object.entries(autoCompleteInfo.params)) {
    if (selector === name || selector === topLevelName + "." + name) {
      return candidates;
    }
  }

  return [];
}

export default function (autoCompleter?: TVSLAutoCompleter) {
  const language = LRLanguage.define({
    parser: parser.configure({
      props: [
        styleTags({
          "Func Var Boolean": t.keyword,
          DirectiveName: t.keyword,
          "FunctionCall/Identifier": t.function(t.variableName),
          Identifier: t.content,
          String: t.string,
          Number: t.number,
          "ArithOp LogicOp Equals Dot": t.operator,
          Bracket: t.bracket,
          "FactRef/...": t.className,
          Punctuation: t.punctuation,
        }),
      ],
    }),
  });

  if (!autoCompleter) {
    autoCompleter = async (context) => {
      const { state, pos } = context;
      const vsl = state.doc.toString();
      const tree = syntaxTree(state);

      const node = tree.resolve(pos, -1);
      const type = node.name;
      const parentType = node.parent?.name;

      const wordToLeft = context.matchBefore(/\w+/);
      const charToLeft = context.matchBefore(/\S\s*/)?.text.trim() || "";

      if (wordToLeft) {
        let candidates: string[] = [];
        let suffix = "";
        const from = wordToLeft.from;
        const filter = wordToLeft.text;

        if (type === "DirectiveName") {
          const chain = node.parent?.parent;
          if (!chain) return null;
          const isTopLevel = node.from === chain.from;
          suffix = "(";

          if (isTopLevel) {
            // top-level directive name
            candidates = autoCompleteInfo.topLevelDirectives;
          } else {
            // sub directive name
            const prevDirective = node.parent?.prevSibling?.prevSibling;
            const identifier = prevDirective?.firstChild;
            if (!identifier) return null;
            const prevName = vsl.substring(
              identifier.from,
              identifier.to
            ) as keyof typeof autoCompleteInfo.nextDirectives;
            candidates = autoCompleteInfo.nextDirectives[prevName] || [];
          }
        } else if (type === "Identifier" && parentType === "VariableDeclaration") {
          // builtin function (not directive) name
          candidates = autoCompleteInfo.builtinFunctions;
          suffix = "(";
        } else if (type === "Identifier" && parentType === "Directive") {
          // builtin function/directive param name
          const directive = node.parent;
          if (!directive) return null;
          candidates = getPossibleParamNames(vsl, directive);
          suffix = "=";
        }

        return {
          from,
          options: candidates
            .filter((n) => n.toLowerCase().startsWith(filter.toLowerCase()))
            .map((n) => ({ label: n + suffix })),
        };
      } else {
        let options: string[] = [];
        const prefix = "";
        let suffix = "";
        const from = pos;

        if (type === "Dot") {
          // sub directive name
          const prev = node.prevSibling;
          const identifier = prev?.firstChild;
          if (!identifier) return null;
          const prevName = vsl.substring(
            identifier.from,
            identifier.to
          ) as keyof typeof autoCompleteInfo.nextDirectives;
          options = autoCompleteInfo.nextDirectives[prevName] || [];
          suffix = "(";
        } else if (
          (type === "Directive" || parentType === "Directive") &&
          "(,".includes(charToLeft)
        ) {
          // builtin function/directive param name
          const directive = type === "Directive" ? node : node.parent;
          if (!directive) return null;
          options = getPossibleParamNames(vsl, directive);
          suffix = "=";
        } else if (type === "Equals" && parentType === "NamedArg") {
          // builtin function/directive param value
          const arg = node.parent;
          const name = arg?.firstChild;
          if (!name) return null;
          const argName = vsl.substring(name.from, name.to);
          const directive = node.parent?.parent;
          const directiveIdentifier = directive?.firstChild;
          if (!directiveIdentifier) return null;
          const directiveName = vsl.substring(directiveIdentifier.from, directiveIdentifier.to);
          const key = (directiveName + "." + argName) as keyof typeof autoCompleteInfo.values;
          options = autoCompleteInfo.values[key] || [];
        }

        return {
          from,
          options: options.map((o) => ({ label: prefix + o + suffix })),
        };
      }
    };
  }

  const _completion = language.data.of({
    autocomplete: autoCompleter,
  });

  return new LanguageSupport(language, [
    // Auto completions disabled
    /*completion*/
  ]);
}
