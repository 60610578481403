import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Button, Divider, Intent, Tab, Tabs, Tooltip } from "@blueprintjs/core";
import { Flex } from "components/utility/StyledComponents";
import {
  IModelHistoryTable,
  ITableConfigColumn,
  TModelHistoryTablePartialModel,
} from "types/modelHistory";
import ModelHistoryTable from "components/user/ModelHistoryTable";
import { EModelTables } from "constants/modelHistoryConstants";
import DeleteCustomTableDialog from "./DeleteCustomTableDialog";
import useSetAndGetConfig from "hooks/useSetAndGetConfig";
import useUser from "hooks/useUser";
import { IAppConfig } from "types";

interface IModelHistoryProps {
  allModelsSelected: { [key: string]: boolean };
  disabled: boolean;
  editColumns: (nextColumns: ITableConfigColumn[]) => void;
  modelTable: string;
  modelTables: IModelHistoryTable[] | undefined;
  selectedModels: { [key: string]: TModelHistoryTablePartialModel[] };
  setAllSelected: () => void;
  setConfig: (config: Partial<IAppConfig>) => void;
  toggleAddTableDialog: () => void;
}

const ModelHistoryTabs: React.FC<IModelHistoryProps> = ({
  allModelsSelected,
  editColumns,
  modelTable,
  modelTables,
  disabled,
  selectedModels,
  setAllSelected,
  setConfig,
}) => {
  // CREATE New MODEL TABLE STATE
  const navigate = useNavigate();
  const [deletingTable, setDeletingTable] = useState<IModelHistoryTable | undefined>(undefined);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { user } = useUser();

  const { update } = useSetAndGetConfig("appConfig", user?.uid || null, "userID");

  const openDeleteTableDialog = (toDelete: IModelHistoryTable) => {
    setDeleteDialogOpen(true);
    setDeletingTable(toDelete);
  };
  const onTableDelete = (modelTableID: string) => {
    if (modelTables) {
      const nextTables = [...modelTables];
      nextTables.splice(
        nextTables.findIndex((table) => table.uid === modelTableID),
        1
      );
      setConfig({ modelTables: nextTables });
      setDeleteDialogOpen(false);
      navigate("/models/" + EModelTables.userModels);
    }
  };

  const editTableProperties = (edit: Partial<IModelHistoryTable>) => {
    if (Array.isArray(modelTables)) {
      const nextTables = [...modelTables];
      const index = nextTables.findIndex((table) => table.uid === modelTable);

      nextTables.splice(index, 1, {
        ...nextTables[index],
        ...edit,
        ...(edit.name && {
          uid: `${edit.name.toLowerCase().replaceAll(" ", "-")}`,
        }),
      });

      update({ modelTables: nextTables });
    }
  };

  return (
    <>
      <DeleteCustomTableDialog
        deletingTable={deletingTable}
        isOpen={deleteDialogOpen}
        onTableDelete={onTableDelete}
        setDialogOpen={setDeleteDialogOpen}
      />
      <Tabs selectedTabId={modelTable}>
        {modelTables
          ? modelTables.map((table) => {
              return (
                <Tab
                  disabled={disabled}
                  id={table.name.toLowerCase().replaceAll(" ", "-")}
                  key={table.name}
                  panel={
                    <ModelHistoryTable
                      allModelsSelected={allModelsSelected[table.uid]}
                      editColumns={editColumns}
                      editKey="modelTables"
                      editTableProperties={editTableProperties}
                      selectedModels={selectedModels[table.uid]}
                      setAllSelected={setAllSelected}
                      modelTable={table}
                    />
                  }
                >
                  <Flex>
                    <Link to={`/models/${table.name.toLowerCase().replaceAll(" ", "-")}`}>
                      {table.name}
                    </Link>
                    {["shared-models", "my-models"].findIndex((t) => t === table.uid) === -1 ? (
                      <>
                        <Divider />
                        <Tooltip content="Delete custom table" openOnTargetFocus={false}>
                          <Button
                            small
                            minimal
                            icon="small-cross"
                            intent={Intent.DANGER}
                            onClick={() => openDeleteTableDialog(table)}
                          />
                        </Tooltip>
                      </>
                    ) : null}
                  </Flex>
                </Tab>
              );
            })
          : null}
      </Tabs>
    </>
  );
};

export default ModelHistoryTabs;
