import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Classes,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogProps,
  Intent,
  Label,
  TextArea,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import { TableWidgetColumnConfig } from "types/widget";
import { multiToaster } from "utils/toaster";

interface ColumnNotesDialogProps extends DialogProps {
  columnConfig: TableWidgetColumnConfig | null;
  onClose: () => void;
  onSubmit: (column: TableWidgetColumnConfig) => Promise<boolean>;
}

const ColumnNotesDialog: React.FC<ColumnNotesDialogProps> = ({
  columnConfig,
  onClose,
  onSubmit,
  ...dialogProps
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const [localNotes, setLocalNotes] = useState("");
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setLocalNotes(columnConfig?.notes ?? "");
  }, [columnConfig?.notes]);

  const submit = async () => {
    if (!columnConfig) return;
    setSaving(true);
    const res = await onSubmit({ ...columnConfig, notes: localNotes });
    setSaving(false);
    if (res) onClose();
    else
      multiToaster.show({
        message: "Unable to update column notes",
        intent: Intent.DANGER,
      });
  };

  return (
    <Dialog
      className={theme === Themes.DARK ? Classes.DARK : ""}
      icon={IconNames.EDIT}
      onClose={onClose}
      title={columnConfig?.id + " column"}
      {...dialogProps}
    >
      <DialogBody>
        <Label>Notes:</Label>
        <TextArea
          fill
          onChange={(e) => setLocalNotes(e.currentTarget.value)}
          value={localNotes}
          style={{ height: "250px" }}
        />
      </DialogBody>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose} text="Cancel" />
          <Button intent={Intent.PRIMARY} onClick={submit} text="Update" loading={saving} />
        </div>
      </DialogFooter>
    </Dialog>
  );
};

export default ColumnNotesDialog;
