import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Flex, Grid } from "components/utility/StyledComponents";
import {
  Selector,
  SelectorContext,
  SelectorOption,
  SelectorOptionsResponse,
  SelectorTypes,
} from "types/vsl";
import { isValidSelector, parseVSLQuerySelectors } from "utils/vsl";
import { Button, Callout, Intent } from "@blueprintjs/core";
import VSLSelector from "components/dashboard/vsl/VSLSelector";
import { ViewWidget } from "types/view";
import LoadingIndicator from "components/utility/LoadingIndicator";
import styled from "styled-components";

interface SelectorsGroupProps {
  context: SelectorContext;
  locked?: boolean;
  injectedSelectors?: Record<string, SelectorOption[]>;
  enableEditableSelectors?: boolean;
  setSelectorsDialogOpen?: Dispatch<SetStateAction<boolean>>;
  setSelectorOnSelect: (selector: Selector) => void;
  selectorsOptionsError?: string;
  selectorsOptions?: Record<string, SelectorOptionsResponse>;
  loadingSelectorsOptions?: boolean;
  selectors?: Selector[];
  query?: string;
  activeSelectors?: Record<string, SelectorOption[]>;
  widgets?: ViewWidget[];
  availableSelectorTypes?: SelectorTypes;
  isLoading?: boolean;
  error?: string;
}

export const SelectorsGroup: React.FC<SelectorsGroupProps> = ({
  selectors = [],
  query = "",
  activeSelectors = {},
  context,
  injectedSelectors,
  enableEditableSelectors = true,
  locked = false,
  setSelectorsDialogOpen,
  setSelectorOnSelect,
  selectorsOptionsError,
  selectorsOptions,
  loadingSelectorsOptions = false,
  widgets = [],
  availableSelectorTypes,
  isLoading = false,
  error = "",
}) => {
  const vslSelectors = useMemo(() => {
    const queries =
      context === SelectorContext.VIEW
        ? widgets.reduce((queries: string[], widget) => {
            if (widget.query) {
              return [...queries, widget.query];
            }
            return queries;
          }, [])
        : [query];

    return parseVSLQuerySelectors(queries);
  }, [query, widgets, context]);

  // See FEAT-1345 for more details on why this was here
  // Hopefully we can eventually remove this
  const resetAllSelectors = useCallback(async (): Promise<boolean> => {
    if (!confirm("Are you sure you want to reset all selectors for this Widget?")) {
      return false;
    }

    const requests = selectors.map((selector) => {
      return new Promise<boolean>((resolve) => {
        setSelectorOnSelect({
          ...selector,
          selected_options: [],
        });

        resolve(true);
      });
    });

    await Promise.all(requests);
    return true;
  }, [selectors, setSelectorOnSelect]);

  if (isLoading) {
    return (
      <LoadingIndicator status={{ message: "Loading selector types...", intent: Intent.PRIMARY }} />
    );
  }

  if (error) {
    return <Callout intent={Intent.DANGER} title={"Couldn't load selectors"}></Callout>;
  }

  if (selectors.length === 0) {
    return null;
  }

  const rows: JSX.Element[][] = [[]];

  let breakIndex = 0;
  selectors.forEach((selector, index) => {
    if (selector.hidden) return;

    const isInvalid = !isValidSelector(selector, vslSelectors, availableSelectorTypes);

    rows[breakIndex].push(
      <div key={selector.id}>
        <VSLSelector
          context={context}
          loading={loadingSelectorsOptions}
          selector={
            context === SelectorContext.VIEW
              ? selector
              : {
                  ...selector,
                  selected_options: activeSelectors?.[selector.id],
                }
          }
          onSelect={setSelectorOnSelect}
          options={selectorsOptions?.[selector.id]?.available_options || []}
          isInvalid={isInvalid}
          disabled={
            context === SelectorContext.VIEW
              ? isInvalid
              : Object.keys(injectedSelectors || {}).some(
                  (selKey) => selKey === selector.id && injectedSelectors?.[selKey]
                ) ||
                locked ||
                isInvalid
          }
          enableEditableSelectors={enableEditableSelectors}
          error={selectorsOptions?.[selector.id]?.error}
          setSelectorsDialogOpen={setSelectorsDialogOpen}
        />
      </div>
    );

    if (selector.breakline && selectors[index + 1]) {
      rows.push([]);
      breakIndex += 1;
    }
  });

  return (
    <Grid rows="auto" gap="5px" style={{ padding: "7px 10px 7px 9px" }} fullWidth>
      {selectorsOptionsError ? (
        <StyledCallout intent={Intent.DANGER}>
          <Flex justifyContent="flex-start" gap={20}>
            <span>{selectorsOptionsError}</span>
            <Button intent={Intent.DANGER} onClick={resetAllSelectors} text="Reset Selectors" />
          </Flex>
        </StyledCallout>
      ) : (
        rows.map((row, i) => (
          <Flex key={i} flexWrap="wrap" gap={5} justifyContent="flex-start" fullWidth>
            {row.map((selector) => selector)}
          </Flex>
        ))
      )}
    </Grid>
  );
};

export const StyledCallout = styled(Callout)`
  padding-top: 12px;
  padding-bottom: 12px;
`;

SelectorsGroup.displayName = "SelectorsGroup";
