import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Divider,
  Intent,
  Position,
  Tooltip,
  Tree,
  TreeNodeInfo,
  useHotkeys,
} from "@blueprintjs/core";
import usePrevious from "hooks/usePrevious";
import AppContext from "context/appContext";
import { buildNodes, getModuleParents } from "utils/tree";
import {
  Flex,
  MaybeStickyDiv,
  PaddedContent,
  SideMenuHeader,
  SideMenyHeaderTitle,
} from "components/utility/StyledComponents";
import { multiToaster } from "utils/toaster";
import { ILineItem, IModule, IModuleInfo, TVSEntity } from "types/model";
import { TNormalizedImportLineItem, TNormalizedImportModule } from "types/import";
import { IModelContextProps } from "./SideMenu";
import LoadingIndicator from "components/utility/LoadingIndicator";
import isFunction from "lodash.isfunction";
import TreeContext from "context/treeContext";
import { editLineItem } from "actions/dataEntryActions";
import { useDispatch, useSelector } from "react-redux";
import EditTagDialog from "./EditTagDialog";
import { modelUidSelector } from "selectors/modelSelectors";
import { schemaLevel } from "utils/normalizr";

export type TNodeType = IModule | ILineItem | TNormalizedImportLineItem | TNormalizedImportModule;

type TTreeNodeInfo = TreeNodeInfo<TNodeType>;

export interface IEditingNode {
  uid: string;
  type: "tags" | "name";
}

export interface IExplorerProps {
  modelContextProps: IModelContextProps;
  currentModule?: IModuleInfo;
  diagramOpen: boolean;
  expandedNodes?: string[];
  lineItems?: Record<string, ILineItem | TNormalizedImportLineItem>;
  lineItemNavigationActive?: boolean;
  loading?: boolean;
  modules?: Record<string, IModule> | Record<string, TNormalizedImportModule>;
  onNodeClick: (nodeType: TVSEntity, nodeData: TNodeType) => void;
  setExpandedNodes: (nextExpanded: string[]) => void;
  setShowLineItems?: (showLineItems: boolean) => void;
  setShowUntaggedLineItems?: (showUntaggedLineItems: boolean) => void;
  showLineItems?: boolean;
  showUntaggedLineItems?: boolean;
  toggleDiagramOpen: () => void;
  updateModuleName?: (moduleID: string, moduleName: string) => void;
}

const Explorer: React.FC<IExplorerProps> = ({
  modelContextProps,
  currentModule,
  diagramOpen,
  expandedNodes,
  lineItemNavigationActive = true,
  lineItems,
  loading = false,
  modules,
  onNodeClick,
  setExpandedNodes,
  setShowLineItems,
  setShowUntaggedLineItems,
  showLineItems = true,
  showUntaggedLineItems = true,
  toggleDiagramOpen,
  updateModuleName,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const dispatch = useDispatch();
  const modelID = useSelector(modelUidSelector);
  const treeRef = useRef<Tree<TNodeType> | null>(null);
  const prevExpandedLength = usePrevious(expandedNodes?.length);
  const [editingNode, setEditingNode] = useState<IEditingNode>();
  const [editTagDialogOpen, setEditTagDialogOpen] = useState(false);
  const prevEditingNode = usePrevious(editingNode);

  const hotkeys = useMemo(() => {
    return [
      {
        combo: "enter",
        group: "Explorer",
        label: "Rename module",
        onKeyDown: () => {
          return currentModule?.uid
            ? setEditingNode({ uid: currentModule.uid, type: "name" })
            : undefined;
        },
      },
    ];
  }, [currentModule?.uid]);

  const { handleKeyDown } = useHotkeys(hotkeys);

  const onEditNameConfirm = useCallback(
    (nextName: string) => {
      if (editingNode && isFunction(updateModuleName) && modules) {
        const isModule = !!modules[editingNode.uid];
        if (isModule) {
          const editMod = modules[editingNode.uid];
          const duplicateName = Object.values(modules).findIndex((m) => m.name === nextName) !== -1;
          if (editMod.name === nextName) {
            setEditingNode(undefined);
            return;
          }
          if (duplicateName) {
            multiToaster.show({ message: "Module names must be unique! " });
            return;
          }
          updateModuleName(editingNode.uid, nextName);
        } else if (lineItems) {
          const editLi = lineItems[editingNode.uid];
          const { caseUid, parentUid: moduleID, name, uid } = editLi;
          const editedLi = [{ id: uid, uid, oldName: name, name: nextName }];
          dispatch(editLineItem(caseUid, moduleID, editedLi));
        }
        setEditingNode(undefined);
      }
    },
    [dispatch, editingNode, lineItems, modules, updateModuleName, setEditingNode]
  );

  const tree = useMemo(() => {
    return modules && currentModule?.uid && lineItems && expandedNodes
      ? buildNodes(
          modules,
          lineItems,
          currentModule.uid,
          expandedNodes,
          showLineItems,
          showUntaggedLineItems,
          setEditTagDialogOpen,
          editingNode,
          isFunction(updateModuleName) ? onEditNameConfirm : undefined
        )
      : undefined;
  }, [
    currentModule?.uid,
    editingNode,
    lineItems,
    modules,
    onEditNameConfirm,
    expandedNodes,
    showLineItems,
    showUntaggedLineItems,
    updateModuleName,
  ]);

  useEffect(() => {
    if (editingNode && editingNode !== prevEditingNode) {
      const inputElement = document.getElementsByClassName("rename-module-input")[0] as HTMLElement;
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [editingNode, prevEditingNode]);

  useEffect(() => {
    if (modules && !expandedNodes) {
      setExpandedNodes(
        Object.values(modules)
          .filter((mod) => mod.depth === 0)
          .map((mod) => mod.uid)
      );
    }
  }, [expandedNodes, modules, setExpandedNodes]);

  const handleNodeClick = (treeNode: TTreeNodeInfo) => {
    const { nodeData } = treeNode;
    if (nodeData?.type === schemaLevel.MODULE) {
      onNodeClick(nodeData?.type, nodeData);
    }
    if (nodeData?.type === schemaLevel.LINE_ITEM && lineItemNavigationActive) {
      onNodeClick(nodeData?.type, nodeData);
    }
  };

  const handleNodeExpand = (treeNode: TTreeNodeInfo) => {
    const { nodeData } = treeNode;
    if (nodeData?.uid && expandedNodes) {
      const newExpanded = expandedNodes.concat([nodeData.uid]);
      const filtered = newExpanded.filter((n, i) => newExpanded.indexOf(n) === i);
      setExpandedNodes(filtered);
    }
  };

  const handleNodeCollapse = (treeNode: TTreeNodeInfo) => {
    const { nodeData } = treeNode;
    if (nodeData?.uid && expandedNodes) {
      const newExpanded = expandedNodes.filter((n) => n !== nodeData.uid);
      setExpandedNodes(newExpanded);
    }
  };

  const locateActiveNode = () => {
    if (currentModule && modules && expandedNodes) {
      const withActiveTree = getModuleParents(currentModule, expandedNodes, modules);
      setExpandedNodes(withActiveTree);
    }
  };

  const toggleShowLineItems = () => {
    if (isFunction(setShowLineItems)) setShowLineItems(!showLineItems);
  };

  const toggleShowUntaggedLineItems = () => {
    if (isFunction(setShowUntaggedLineItems)) {
      setShowUntaggedLineItems(!showUntaggedLineItems);
    }
  };

  useEffect(() => {
    if (currentModule?.uid && tree && treeRef?.current) {
      if (expandedNodes?.length !== prevExpandedLength) {
        const nodeElement = treeRef.current.getNodeContentElement(currentModule.uid);
        if (nodeElement) {
          nodeElement.scrollIntoView(false);
        }
      }
    }
  }, [currentModule, expandedNodes, prevExpandedLength, tree]);

  return (
    <Flex
      flexDirection="column"
      fullHeight
      fullWidth
      justifyContent="flex-start"
      style={{ overflowY: "auto" }}
    >
      {modelID ? (
        <EditTagDialog
          isOpen={editTagDialogOpen}
          lineItem={editingNode ? lineItems?.[editingNode.uid] : undefined}
          modelID={modelID}
          setIsOpen={setEditTagDialogOpen}
        />
      ) : null}
      <MaybeStickyDiv $fill={true} position="top" pixels={0} pinned={true} style={{ flex: 0 }}>
        <SideMenuHeader
          alignItems="flex-start"
          flexDirection="row"
          justifyContent="space-between"
          theme={theme}
        >
          <Flex alignItems="center" fullHeight>
            <SideMenyHeaderTitle>Explorer</SideMenyHeaderTitle>
          </Flex>
          <ButtonGroup>
            <Tooltip
              content="Create new module"
              position={Position.BOTTOM}
              openOnTargetFocus={false}
            >
              <AnchorButton
                disabled={modelContextProps.modelLocked}
                icon="cube-add"
                minimal
                onClick={() => modelContextProps.onCreateModule()}
              />
            </Tooltip>
            <Tooltip
              content="Delete current module"
              position={Position.BOTTOM}
              openOnTargetFocus={false}
            >
              <AnchorButton
                disabled={modelContextProps.modelLocked}
                icon="cube-remove"
                minimal
                onClick={() => modelContextProps.onDeleteModule()}
              />
            </Tooltip>
            <Divider />
            <Tooltip
              content={showLineItems ? "Hide line items" : "Show line items"}
              openOnTargetFocus={false}
            >
              <Button
                active={showLineItems}
                icon="stadium-geometry"
                minimal
                onClick={toggleShowLineItems}
              />
            </Tooltip>
            <Tooltip
              content={
                showUntaggedLineItems ? "Hide untagged line items" : "Show untagged line items"
              }
              openOnTargetFocus={false}
            >
              <Button
                active={showUntaggedLineItems}
                icon="tag"
                minimal
                onClick={toggleShowUntaggedLineItems}
              />
            </Tooltip>
            <Divider />
            <Tooltip
              content="Show module diagram"
              position={Position.BOTTOM}
              openOnTargetFocus={false}
            >
              <Button
                active={diagramOpen}
                minimal
                icon="layout-hierarchy"
                onClick={toggleDiagramOpen}
              />
            </Tooltip>
            <Divider />
            <Tooltip
              content="Show current module"
              position={Position.BOTTOM}
              openOnTargetFocus={false}
            >
              <Button minimal icon="locate" onClick={locateActiveNode} />
            </Tooltip>
          </ButtonGroup>
        </SideMenuHeader>
      </MaybeStickyDiv>
      {loading ? (
        <PaddedContent padding="10px">
          <LoadingIndicator
            loading={true}
            status={{
              message: "Loading model preview (may take some time)...",
              intent: Intent.PRIMARY,
            }}
          />
        </PaddedContent>
      ) : null}
      {tree ? (
        <div style={{ width: "100%" }} onKeyDown={handleKeyDown}>
          <TreeContext.Provider value={{ locked: modelContextProps.modelLocked, setEditingNode }}>
            <Tree<TNodeType>
              contents={tree}
              onNodeCollapse={handleNodeCollapse}
              onNodeExpand={handleNodeExpand}
              onNodeClick={handleNodeClick}
              ref={treeRef}
            />
          </TreeContext.Provider>
        </div>
      ) : null}
    </Flex>
  );
};

export default Explorer;
