import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Callout,
  Classes,
  Divider,
  EditableText,
  H4,
  H6,
  Icon,
  IconSize,
  Intent,
} from "@blueprintjs/core";
import { Flex, PaddedContent } from "components/utility/StyledComponents";
import { IconNames } from "@blueprintjs/icons";
import LoadingIndicator from "components/utility/LoadingIndicator";
import useWidget from "hooks/dashboard/useWidget";
import isFunction from "lodash.isfunction";
import VSLEditor from "../vsl/VSLEditor";
import AppContext from "context/appContext";
import GridLayout, { Layout } from "react-grid-layout";
import { SizeMe } from "react-sizeme";
import styled from "styled-components";
import Widget from "components/dashboard/widget/Widget";

const StyledWidgetLibraryWidget = styled(Flex)`
  width: calc(100% - 400px);
`;

const StyledTitleEditableText = styled(EditableText)`
  max-width: 350px;
`;

const WidgetLibraryWidget: React.FC = () => {
  const { widgetId } = useParams();
  const {
    delete: deleteWidget,
    error: widgetError,
    loading: loadingWidget,
    saving: savingWidget,
    setDescription: setWidgetDescription,
    setName: setWidgetName,
    update: updateWidget,
    widget,
  } = useWidget(widgetId);

  const [testingLayout, setTestingWidgetLayout] = useState<Layout>({
    isDraggable: false,
    i: "testing-widget",
    x: 0,
    y: 0,
    w: 12,
    h: 8,
    maxW: 12,
    minW: 4,
    minH: 5,
  });

  const {
    config: { theme },
  } = useContext(AppContext);

  const [localQuery, setLocalQuery] = useState(widget?.query || "");
  const navigate = useNavigate();

  useEffect(() => {
    setLocalQuery(widget?.query || "");
  }, [widget?.query]);

  const runQuery = () => {
    if (isFunction(updateWidget)) updateWidget({ query: localQuery });
  };

  if (widgetError) {
    return (
      <Callout intent={Intent.DANGER}>Unable to retrieve data. Service is unavailable.</Callout>
    );
  }
  if (loadingWidget) {
    return (
      <LoadingIndicator loading status={{ intent: Intent.PRIMARY, message: "Loading widget..." }} />
    );
  }
  if (!widget) {
    return <Navigate to="/settings/widgets" replace />;
  }

  return (
    <StyledWidgetLibraryWidget
      alignItems="flex-start"
      flex="1 0 auto"
      flexDirection="column"
      fullHeight
      justifyContent="flex-start"
    >
      <Flex
        alignItems="center"
        flex="0"
        fullWidth
        justifyContent="space-between"
        style={{ padding: "0 0 10px 10px" }}
      >
        <Flex
          alignItems="flex-start"
          alignContent="start"
          flex="0 1 75%"
          flexDirection="column"
          justifyContent="space-between"
          style={{ minWidth: 0 }}
        >
          <H4>
            <Flex justifyContent="flex-start">
              <span className={Classes.TEXT_MUTED}>Name:&nbsp;</span>
              <StyledTitleEditableText
                maxLength={80}
                onChange={setWidgetName}
                placeholder="Enter a name (required)"
                value={widget.name}
              />
            </Flex>
          </H4>
          <H6>
            <Flex flexWrap="wrap" justifyContent="flex-start">
              <span className={Classes.TEXT_MUTED}>Description:&nbsp;</span>
              <StyledTitleEditableText
                value={widget.description}
                placeholder="Enter a description (optional)"
                maxLength={120}
                onChange={setWidgetDescription}
              />
            </Flex>
          </H6>
        </Flex>
        <Flex alignItems="flex-start" flex="1 1 auto" justifyContent="flex-end">
          <Button
            icon={IconNames.REFRESH}
            intent={Intent.PRIMARY}
            onClick={runQuery}
            text="Run Query"
          />
          <Divider />
          <Button
            intent={Intent.DANGER}
            icon={IconNames.DELETE}
            disabled={savingWidget}
            onClick={() => {
              deleteWidget().then((result) => {
                if (result) navigate("/settings/widgets");
              });
            }}
            text="Delete"
          />
        </Flex>
      </Flex>
      <SizeMe>
        {({ size }) => (
          <PaddedContent padding="0px" fullWidth fullHeight style={{ overflow: "hidden" }}>
            <Flex flex="0" fullWidth justifyContent="flex-start" style={{ overflow: "auto" }}>
              <Icon
                icon={IconNames.DATA_CONNECTION}
                intent={Intent.PRIMARY}
                size={IconSize.LARGE}
                style={{ display: "block", margin: "5px 7px" }}
              />
              <div style={{ flex: 1, marginBottom: 10 }}>
                <VSLEditor
                  theme={theme}
                  onChange={setLocalQuery}
                  minHeight={"100px"}
                  maxHeight={"200px"}
                  value={localQuery}
                />
              </div>
            </Flex>
            <div style={{ overflow: "auto" }}>
              <GridLayout
                cols={12}
                onDrop={(_, item) => setTestingWidgetLayout(item)}
                rowHeight={50}
                width={size.width || 800}
              >
                <div key="widget-template" data-grid={testingLayout}>
                  <Widget
                    enableEditWidgetDialog={false}
                    savingWidget={savingWidget}
                    updateWidget={updateWidget}
                    widget={widget}
                  />
                </div>
              </GridLayout>
            </div>
          </PaddedContent>
        )}
      </SizeMe>
    </StyledWidgetLibraryWidget>
  );
};

export default WidgetLibraryWidget;
