import React, { useEffect } from "react";
import { Intent } from "@blueprintjs/core";
import { IModelCopyData, IResponse } from "types";
import { ToasterSpinner, singleToaster } from "utils/toaster";
import { COPY_MODEL_ROUTE, EMethods, Status } from "constants/apiConstants";
import { IModelHistoryResponse, TModelHistoryMutator } from "hooks/useModelHistory2";
import { useApiCallback } from "hooks/useApiCallback";
import useUser from "hooks/useUser";
import { TModelHistoryTablePartialModel } from "types/modelHistory";
import { IModelInfo } from "types/model";

type TUseCopyModel = (config?: {
  mutate?: TModelHistoryMutator;
  data?: IModelHistoryResponse | undefined;
}) => {
  copyModel: (model: TModelHistoryTablePartialModel | IModelInfo) => void;
  copying: boolean;
  copyResponse: IResponse<unknown> | null;
};

/** Hook to copy a shared model into a user's own model. */
const useCopyModel: TUseCopyModel = (config = {}) => {
  const { mutate } = config;
  const { mutate: mutateUser } = useUser();
  // const { mutate } = useModelHistory();
  const {
    callback: copyCallback,
    fetching: copying,
    response: copyResponse,
  } = useApiCallback<IModelCopyData>();
  const copyModel = (model: TModelHistoryTablePartialModel | IModelInfo) => {
    copyCallback(COPY_MODEL_ROUTE, {
      method: EMethods.POST,
      body: { modelID: model.id },
    });

    singleToaster.show({
      timeout: 0,
      message: "Duplicating '" + model.companyName + "' model...",
      icon: <ToasterSpinner />,
    });
  };

  useEffect(() => {
    if (!copying && copyResponse) {
      if (copyResponse.status === Status.FAILED) {
        singleToaster.show({
          message: "Error while duplicating model: " + copyResponse.error,
          intent: Intent.DANGER,
        });
      } else {
        const rspData = copyResponse.data;
        const model = rspData?.model;
        if (typeof mutate === "function") {
          mutate();
        }
        mutateUser();

        singleToaster.show({
          message: (
            <span>
              <strong>{`"${model?.companyName}"`}</strong> model duplicated successfully
            </span>
          ),
          intent: Intent.SUCCESS,
        });
      }
    }
  }, [copying, copyResponse, mutate, mutateUser]);

  return { copyModel, copyResponse, copying };
};

export default useCopyModel;
