import useSWR, { mutate, MutatorCallback, MutatorOptions } from "swr";
import { swrApi } from "utils/api";
import { EStatus, EMethods, MODEL_PERMISSIONS_ROUTE } from "constants/apiConstants";
import { IResponse } from "types";
import { IUserPermission } from "../types/user";
import { useApiCallback } from "./useApiCallback";
import { useCallback, useEffect } from "react";
import useSuccessFailToast from "./useSuccessFailToast";

type TUpdatePermissionsCallback = (
  email: string,
  method: string,
  permissionLevel?: { [key: string]: boolean }
) => void;

const usePermissions: (
  modelID: string,
  userID?: string
) => {
  mutate: (
    data?:
      | Promise<IResponse<IUserPermission[]>>
      | MutatorCallback<IResponse<IUserPermission[]>>
      | IResponse<IUserPermission[]>,
    opts?: boolean | MutatorOptions<IResponse<IUserPermission[]>>
  ) => Promise<IResponse<IUserPermission[]> | undefined>;
  updating: boolean;
  permissions: IUserPermission[] | null;
  userPermissions: IUserPermission | null;
  updateResponse: IResponse<unknown> | null;
  updatePermissions: (
    email: string,
    method: string,
    permissionLevel?: { [p: string]: boolean }
  ) => void;
} = (modelID, userID) => {
  const { data, mutate: mutatePermissions } = useSWR<IResponse<IUserPermission[]>>(
    modelID ? [MODEL_PERMISSIONS_ROUTE, { modelID }] : null,
    swrApi
  );

  const {
    callback: updateCallback,
    fetching: updating,
    response: updateResponse,
  } = useApiCallback<IUserPermission>();

  const permissions = data?.data || null;
  const userPermissions =
    userID && permissions ? permissions.find((perm) => perm.userID === userID) || null : null;

  const updatePermissions: TUpdatePermissionsCallback = useCallback(
    (email, method, permissionLevel = {}) => {
      updateCallback(MODEL_PERMISSIONS_ROUTE, {
        method: method,
        body: { ...permissionLevel },
        userEmail: email,
        modelID,
      });

      const nextData: IUserPermission[] = [];
      if (permissions) {
        permissions.forEach((userPermission) => {
          if (email === userPermission.email) {
            if (method === EMethods.DELETE) return;
            nextData.push({
              ...userPermission,
              ...permissionLevel,
            });
          } else nextData.push(userPermission);
        });
      }

      const nextPermissions: IResponse<IUserPermission[]> = {
        status: EStatus.success,
        data: nextData,
      };
      mutatePermissions(nextPermissions, false);
    },
    [modelID, mutatePermissions, permissions, updateCallback]
  );

  useEffect(() => {
    if (updateResponse && updateResponse.status !== EStatus.success) {
      mutate([MODEL_PERMISSIONS_ROUTE, { modelID }]);
    }
  }, [modelID, permissions, updateResponse]);

  useSuccessFailToast(updateResponse);

  return {
    mutate: mutatePermissions,
    permissions,
    updatePermissions,
    updateResponse,
    updating,
    userPermissions,
  };
};

export default usePermissions;
