import { useMemo } from "react";
import useSWR, { KeyedMutator } from "swr";
import { swrApi } from "utils/api";
import { EMethods, MODEL_HISTORY_ROUTE } from "constants/apiConstants";
import { InitialFilterState } from "components/table/ModelFilters";
import {
  IFilterState,
  IModelHistoryInfo,
  IModelInfoWithFields,
  TModelType,
} from "types/modelHistory";
import { ESortDirection, IResponse } from "types";
import { flattenModelFields } from "utils/modelHistoryUtils";

export type IModelHistoryResponse = IResponse<{
  models: IModelInfoWithFields[];
  totalModels: number;
}>;

export type TModelHistoryMutator = KeyedMutator<IModelHistoryResponse>;

export interface IHookReturn {
  error: Error;
  loading: boolean;
  models: IModelHistoryInfo[] | null;
  mutate: TModelHistoryMutator;
  response: IModelHistoryResponse | undefined;
  totalModels?: number | undefined;
  validating: boolean;
}

export interface IGetModelHistoryConfig {
  fields?: string[];
  filterConfig?: IFilterState;
  modelType?: TModelType;
  pagination: number;
  sortBy?: string;
  sortDirection?: ESortDirection;
  tableID?: string;
}

type TUseModelHistory = (config?: IGetModelHistoryConfig) => IHookReturn;

const useModelHistory2: TUseModelHistory = (
  // @Joao - this config would be replaced with the new config for the DSL endpoint.
  config = {
    fields: [],
    filterConfig: InitialFilterState,
    modelType: "Both",
    pagination: 0,
    tableID: "default",
  }
) => {
  const body = {
    ...config,
    ...config?.filterConfig,
  };

  const {
    data: response,
    error,
    isLoading,
    isValidating,
    mutate,
  } = useSWR<IModelHistoryResponse>(
    config // @Joao - modify this swr config to work with the new DSL endpoint.
      ? [
          `${MODEL_HISTORY_ROUTE}?page=${config.pagination}`,
          { body, method: EMethods.POST, pagination: config.pagination },
        ]
      : null,
    swrApi,
    {
      errorRetryCount: 5,
      revalidateOnMount: true,
      revalidateOnFocus: true,
    }
  );

  // Return flattened models for convenience and with-fields for mutation. Needs to be memoized to prevent re-renders of TableBody when ModelHistoryTable changes for unrelated reasons.
  const models = useMemo(
    () => (response?.data?.models ? flattenModelFields(response?.data?.models) : null),
    [response?.data?.models]
  );

  let totalPages: number | null = null;

  if (response?.data?.totalModels) {
    totalPages = response.data.totalModels / 50;
  }

  return {
    response,
    error,
    loading: isLoading,
    models,
    mutate,
    totalModels: response?.data?.totalModels,
    totalPages,
    validating: isValidating,
  };
};

export default useModelHistory2;
