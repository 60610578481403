import React, { createContext, Dispatch, SetStateAction } from "react";
import { IModelConfiguration, TCompanyInfo, TConfigureModelPanels } from "types/createModel";

export interface IConfigureModelContext {
  config: IModelConfiguration;
  company?: TCompanyInfo;
  blankCompany: Partial<TCompanyInfo>;
  loadingConfigOpts?: boolean;
  onConfigure: () => void;
  configuring: boolean;
  setBlankCompany: Dispatch<SetStateAction<Partial<TCompanyInfo>>>;
  setCompany: Dispatch<SetStateAction<TCompanyInfo | undefined>>;
  setConfig: Dispatch<SetStateAction<IModelConfiguration>>;
}

const ConfigureModelContext = createContext<IConfigureModelContext>({
  config: {
    blank: false,
    companyName: "",
    companyType: "Public",
    currency: { ISO: "USD", name: "United States Dollar" },
    numHistoricalYears: 1,
    numForecastYears: 1,
    periodType: "Annual",
    startDate: new Date(),
    startPeriod: new Date().getFullYear() + 0.75,
    variables: [],
  },
  setConfig: () => {
    throw new Error("setConfig function must be overwritten!");
  },
  // The working company state for SelectOrConfigureCompany.tsx. Used to preserve both
  // blank and known whilst configuring
  company: undefined,
  setCompany: () => {
    throw new Error("setCompany function must be overwritten!");
  },
  blankCompany: {},
  setBlankCompany: () => {
    throw new Error("setCompany function must be overwritten!");
  },
  onConfigure: () => {
    throw new Error("onConfigure function must be overwritten!");
  },
  configuring: false,
  loadingConfigOpts: false,
});

export const withConfigureModelContext = (
  Component: React.FC<TConfigureModelPanels>
): React.ReactNode => {
  return function WithContextComponent(props: TConfigureModelPanels) {
    return (
      <ConfigureModelContext.Consumer>
        {(contexts) => <Component {...props} {...contexts} />}
      </ConfigureModelContext.Consumer>
    );
  };
};

export default ConfigureModelContext;
