import { Button, Callout, Classes, Intent } from "@blueprintjs/core";
import React from "react";

interface IFallbackErrorProps {
  error: Error;
  showError: boolean;
}

export const reload = () => window.location.reload();

const FallbackError: React.FC<IFallbackErrorProps> = ({ error, showError }) => {
  return (
    <Callout icon="warning-sign" intent={Intent.WARNING} title={"Something went wrong"}>
      <p className={Classes.RUNNING_TEXT}>
        An error occurred while running the Valsys web client and has been logged.
      </p>
      {showError && error.message ? <h5>Error message: {error.message}</h5> : null}
      <p className={Classes.RUNNING_TEXT}>Please refresh the page to continue.</p>
      <Button icon="refresh" onClick={reload} intent={Intent.PRIMARY}>
        Refresh
      </Button>
      <p className={Classes.RUNNING_TEXT} style={{ paddingTop: "20px" }}>
        If you need any further support please contact{" "}
        <a href={"mailto:support@valsys.io"}> support@valsys.io</a>
      </p>
    </Callout>
  );
};

export default FallbackError;
