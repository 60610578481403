import { darkThemeHighchartsConfig, lightThemeHighchartsConfig } from "configs/highchartsConfigs";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { isNumber } from "highcharts/highstock";
import HighchartsTitleEditablePlugin from "./highcharts-extensions/AxisTitleEditable";
import HighchartsSeriesEndLabelPlugin from "./highcharts-extensions/SeriesEndLabel";
import HighchartsNavigatorHandlesPlugin from "./highcharts-extensions/NavigatorHandles";
import HighchartsPieChartLegendTooltip from "./highcharts-extensions/PieChartLegendTooltip";
import React, { RefObject, useEffect, useContext, useMemo, useCallback } from "react";
import { ChartDataset, EWidgetType2, VSLChart } from "types/vsl";
import { ChartWidgetConfig, LabelFormatConfig } from "types/widget";
import { EValFormat } from "types/format";
import {
  calculateScatterExtremes,
  getChartSeriesFromDataset,
  getConfigFormat,
  getChartFrequency,
  getStatisticsPlotLines,
  labelFormatter,
  tooltipPointFormatter,
  parseDatasetDates,
  getXAxisType,
  getXAxisCategories,
  handleStackTransformation,
  calculateDataMetrics,
  getCurrentChartConfig,
  getChartPeriodTypeOptions,
  handleSEQandYOYTransformations,
  getChartLabelKey,
  isYAxisPercentage,
} from "utils/chartUtils";
import {
  ChartDataFrequency,
  chartDefaults,
  ChartPeriodType,
  ChartTickGap,
  ETransformationType,
  YAxisId,
} from "constants/chartConstants";
import { Callout, Colors, Intent, Tag } from "@blueprintjs/core";
import { isNil } from "lodash";
import { TSeriesLineOptions } from "types/chart";
import { Flex, Grid } from "components/utility/StyledComponents";
import { formatValue } from "utils/formatter";
import { SizeMe } from "react-sizeme";

interface IChartRendererProps {
  chartComponentRef: RefObject<HighchartsReact.RefObject>;
  config?: ChartWidgetConfig;
  loading: boolean;
  data: VSLChart<string>;
  type:
    | EWidgetType2.BAR_CHART
    | EWidgetType2.LINE_CHART
    | EWidgetType2.PIE_CHART
    | EWidgetType2.SCATTER_CHART;
  setCurrentChartState: React.Dispatch<React.SetStateAction<Highcharts.Chart | undefined>>;
  updateConfig?: (details: Partial<ChartWidgetConfig>) => void;
}

// Call seems to have to be made prior to render and not inline using the opts prop
Highcharts.setOptions({ lang: { numericSymbols: ["K", "M", "B", "T"] } });

// Apply Highcharts Plugins
HighchartsSeriesEndLabelPlugin(Highcharts);
HighchartsTitleEditablePlugin(Highcharts);
HighchartsNavigatorHandlesPlugin(Highcharts);
HighchartsPieChartLegendTooltip(Highcharts);

const ChartRenderer: React.FC<IChartRendererProps> = ({
  chartComponentRef,
  config,
  updateConfig,
  loading,
  data,
  type,
  setCurrentChartState,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const chartDefaultOptions = chartDefaults[type];

  // Workaround to globally set theme color option for plotLines (Scatter cross)
  Highcharts.addEvent(Highcharts.PlotLineOrBand, "render", function () {
    const scatterCrossColor = theme === Themes.LIGHT ? Colors.GRAY4 : Colors.GRAY1;
    // eslint-disable-next-line
    // @ts-ignore
    this.options.color = this.options.color || scatterCrossColor;
  });

  const onRangeSelectorButtonChange = useCallback(
    (buttonSelected: Highcharts.RangeSelectorButtonsOptions): boolean => {
      if (!chartComponentRef.current?.chart || !updateConfig) {
        return false;
      }

      const chart = chartComponentRef.current.chart as unknown as {
        rangeSelector?: { buttonOptions: Highcharts.RangeSelectorButtonsOptions[] };
      };
      const chartRangeSelector = chart.rangeSelector;

      if (!chartRangeSelector?.buttonOptions) {
        return false;
      }
      // for some reason the only way to preselect the rangeSelector in the configs is by setting the button idx
      const rangeSelectorButtonIdx = chartRangeSelector.buttonOptions.findIndex(
        (button) => button.text === buttonSelected.text
      );

      if (rangeSelectorButtonIdx >= 0 && rangeSelectorButtonIdx !== config?.activeRangeSelector) {
        updateConfig({
          activeRangeSelector: rangeSelectorButtonIdx,
        });
        return true;
      }

      return false;
    },
    [chartComponentRef, updateConfig, config?.activeRangeSelector]
  );

  useEffect(() => {
    const chart = chartComponentRef.current?.chart;
    chart?.axes.forEach((axis) => {
      Highcharts.removeEvent(axis, "finishTitleEdit"); // prevent multiple events on a single axis
      Highcharts.addEvent(axis, "finishTitleEdit", function ({ newTitle }: { newTitle: string }) {
        let updatedConfig = {};
        if (this.isXAxis) {
          updatedConfig = {
            xAxis: {
              title: {
                text: newTitle,
              },
            },
          };
        } else {
          const axisID = this.options.id === "right" ? YAxisId.RIGHT : YAxisId.LEFT;
          const oppositeAxisID = this.options.id === "right" ? YAxisId.LEFT : YAxisId.RIGHT;

          updatedConfig = {
            yAxis: {
              [axisID]: {
                ...config?.yAxis?.[axisID],
                title: {
                  text: newTitle,
                },
              },
              [oppositeAxisID]: {
                ...config?.yAxis?.[oppositeAxisID],
              },
            },
          };
        }

        if (updateConfig) {
          updateConfig(updatedConfig);
        }
      });
    });
  }, [chartComponentRef, config, updateConfig]);

  const dateTypeData: VSLChart<Date> | undefined = useMemo(() => {
    if (data?.datasets) {
      return { ...data, datasets: data.datasets.map((dataset) => parseDatasetDates(dataset)) };
    }
  }, [data]);

  const frequency = dateTypeData && (config?.frequency ?? getChartFrequency(dateTypeData));

  const periodTypes = dateTypeData ? getChartPeriodTypeOptions(dateTypeData) : undefined;
  let periodType = config?.periodType ?? ChartPeriodType.Fiscal;
  if (periodTypes && !periodTypes.includes(periodType)) {
    periodType = ChartPeriodType.Raw;
  }

  const xAxisType = getXAxisType(type, periodType);

  const metricsAvailable = data?.datasets?.length === 2 && xAxisType === "category";

  const categories =
    dateTypeData && frequency && getXAxisCategories(dateTypeData, xAxisType, frequency, periodType);

  const parsedAndTrimmedData = useMemo(() => {
    if (!dateTypeData || !frequency) return;
    const { dataLabelsEnabled } = getCurrentChartConfig(chartDefaults[type], type, config);

    let cleanData: VSLChart<Date> | undefined = dateTypeData ?? undefined;

    if (type === EWidgetType2.LINE_CHART || type === EWidgetType2.BAR_CHART) {
      if (
        config?.yAxis?.left?.transformation === ETransformationType.SEQ ||
        config?.yAxis?.left?.transformation === ETransformationType.YOY
      ) {
        cleanData = handleSEQandYOYTransformations(cleanData, config, YAxisId.LEFT);
      }
      if (
        config?.yAxis?.right?.transformation === ETransformationType.SEQ ||
        config?.yAxis?.right?.transformation === ETransformationType.YOY
      ) {
        cleanData = handleSEQandYOYTransformations(cleanData, config, YAxisId.RIGHT);
      }
    }

    let maybeTrimmedData: VSLChart<Date> | undefined = cleanData;
    let xAxisCategories: string[] | undefined = categories;

    /**
     * If categoric and category filters are applied, filter as appropriate,
     * Unfortunately we have to use a filter/includes as series are of indeterminate length, so we cannot slice the arrays.
     * */
    if (
      xAxisType === "category" &&
      categories &&
      config?.categoryFilters &&
      cleanData &&
      cleanData.datasets &&
      type !== EWidgetType2.PIE_CHART
    ) {
      const minIndex = config.categoryFilters.min ?? 0;
      const maxIndex = config.categoryFilters.max;

      xAxisCategories = categories.slice(minIndex, maxIndex + 1);

      const labelKey = getChartLabelKey(frequency, periodType);

      if (xAxisCategories) {
        maybeTrimmedData = {
          ...cleanData,
          datasets: cleanData.datasets.map((dataset) => {
            return {
              ...dataset,
              ...(dataset?.data && {
                data: dataset.data.filter((point) => xAxisCategories?.includes(point[labelKey])),
              }),
            } as ChartDataset;
          }),
        };
      }
    }

    let defaultSeriesOptions = getChartSeriesFromDataset(
      maybeTrimmedData,
      type,
      frequency,
      periodType,
      dataLabelsEnabled ?? false,
      config
    );

    //TODO: What is the issue with this, if any - how is it handled?
    if (!defaultSeriesOptions || defaultSeriesOptions?.length === 0) return;

    if (type === EWidgetType2.LINE_CHART || type === EWidgetType2.BAR_CHART) {
      if (
        config?.yAxis?.left?.transformation == ETransformationType.STACK ||
        config?.yAxis?.left?.transformation == ETransformationType.STACK_PERCENT
      ) {
        defaultSeriesOptions = handleStackTransformation(
          defaultSeriesOptions as unknown as Highcharts.SeriesLineOptions[],
          config.yAxis.left.transformation,
          dataLabelsEnabled ?? false,
          YAxisId.LEFT
        );
      }
      if (
        config?.yAxis?.right?.transformation == ETransformationType.STACK ||
        config?.yAxis?.right?.transformation == ETransformationType.STACK_PERCENT
      ) {
        defaultSeriesOptions = handleStackTransformation(
          defaultSeriesOptions as unknown as Highcharts.SeriesLineOptions[],
          config.yAxis.right.transformation,
          dataLabelsEnabled ?? false,
          YAxisId.RIGHT
        );
      }
    }

    return { series: defaultSeriesOptions, xAxisCategories };
  }, [categories, config, periodType, frequency, dateTypeData, type, xAxisType]);

  const metricsRibbon = useMemo(() => {
    if (!parsedAndTrimmedData || !frequency) return <div />;
    const { series } = parsedAndTrimmedData;
    const metrics = calculateDataMetrics(series as TSeriesLineOptions[], xAxisType);

    if (metrics) {
      return (
        <Flex
          flexWrap="wrap"
          fullWidth
          gap={5}
          justifyContent="flex-start"
          style={{ padding: "5px 10px" }}
        >
          {metrics.map((metric) => (
            <Tag minimal key={`${metric.label}:${metric.value}`}>{`${metric.label}: ${formatValue(
              metric.value,
              metric.format
            )}`}</Tag>
          ))}
        </Flex>
      );
    }
    return <div />;
  }, [parsedAndTrimmedData, frequency, xAxisType]);

  const chartOptsMemo = useMemo<Highcharts.Options | undefined>(() => {
    if (dateTypeData && frequency && parsedAndTrimmedData) {
      const { series, xAxisCategories } = parsedAndTrimmedData;

      const rightAxisConfigFormat = getConfigFormat(dateTypeData, YAxisId.RIGHT, config);
      const leftAxisConfigFormat = getConfigFormat(dateTypeData, YAxisId.LEFT, config);

      // whether we want to force the formatting later on
      const isRightPercentage = isYAxisPercentage(YAxisId.RIGHT, config);
      const isLeftPercentage = isYAxisPercentage(YAxisId.LEFT, config);
      let xAxisConfigFormat: LabelFormatConfig | null = null;

      const chartOptions = Highcharts.merge(chartDefaultOptions, config || {});
      const seriesOptions = config?.seriesOptions ?? {};
      const themeOptions =
        theme === Themes.LIGHT ? lightThemeHighchartsConfig : darkThemeHighchartsConfig;

      const leftYAxisDefaults: Highcharts.YAxisOptions = {
        labels: {
          formatter: function () {
            if (isLeftPercentage) {
              const value = +this.value;
              if (isNumber(value)) {
                return value.toFixed(1) + "%";
              }
            }
            return labelFormatter(this.value, leftAxisConfigFormat || {});
          },
        },
        title: {
          text: chartOptions?.yAxis?.[YAxisId.LEFT]?.title?.text || dateTypeData.ylabel,
        },
      };
      const rightYAxisDefaults: Highcharts.YAxisOptions = {
        labels: {
          formatter: function () {
            if (isRightPercentage) {
              const value = +this.value;
              if (isNumber(value)) {
                return value.toFixed(1) + "%";
              }
            }
            return labelFormatter(this.value, rightAxisConfigFormat || {});
          },
        },
        title: {
          text: chartOptions?.yAxis?.[YAxisId.RIGHT]?.title?.text || dateTypeData.ylabel,
        },
      };

      const rightYAxis: Highcharts.YAxisOptions = Highcharts.merge(
        chartDefaultOptions.yAxis?.[YAxisId.RIGHT],
        chartOptions.yAxis?.[YAxisId.RIGHT],
        themeOptions.yAxis,
        rightYAxisDefaults
      );
      const leftYAxis = Highcharts.merge(
        chartDefaultOptions.yAxis?.[YAxisId.LEFT],
        chartOptions.yAxis?.[YAxisId.LEFT],
        themeOptions.yAxis,
        leftYAxisDefaults
      );

      // Median/mean logic
      const statisticPlotLines = getStatisticsPlotLines(
        config,
        series,
        rightAxisConfigFormat,
        theme
      );
      // Add statisticPlotLines to rightYAxis
      if (type === EWidgetType2.LINE_CHART || type === EWidgetType2.BAR_CHART) {
        rightYAxis.plotLines = statisticPlotLines;
      }
      if (type === EWidgetType2.SCATTER_CHART) {
        const extremes = calculateScatterExtremes(series);
        xAxisConfigFormat = getConfigFormat(dateTypeData, null, config);

        if (isNil(config?.xAxis?.min)) {
          chartOptions.xAxis.min = -extremes.maxX * 1.1;
        }

        if (isNil(config?.xAxis?.max)) {
          chartOptions.xAxis.max = extremes.maxX * 1.1;
        }

        if (isNil(config?.yAxis?.right?.min)) {
          rightYAxis.min = -extremes.maxY * 1.1;
        }

        if (isNil(config?.yAxis?.right?.max)) {
          rightYAxis.max = extremes.maxY * 1.1;
        }
      }

      const yAxes = [rightYAxis];

      const hasLeftAxis = Object.values(config?.seriesOptions ?? {})?.some(
        (series) => series.axis === YAxisId.LEFT
      );

      if (hasLeftAxis) {
        yAxes.push(leftYAxis);
      }

      const plotOptions: Highcharts.PlotOptions = Highcharts.merge(chartOptions.plotOptions, {
        series: {
          dataLabels: {
            formatter() {
              const axis = seriesOptions[this.series.name]?.axis ?? YAxisId.RIGHT;
              const isPercentage = axis === YAxisId.LEFT ? isLeftPercentage : isRightPercentage;
              const val = this.y;
              const fmt = axis === YAxisId.LEFT ? leftAxisConfigFormat : rightAxisConfigFormat;
              const valFormat =
                fmt.valFormat === EValFormat.CURRENCY ? EValFormat.NUMERIC : fmt.valFormat;
              if (config?.yAxis?.[axis]?.transformation === ETransformationType.STACK_PERCENT) {
                return this.percentage ? this.percentage.toFixed(1) + "%" : "";
              }
              if ((val || val === 0) && isPercentage) {
                return val.toFixed(1) + "%";
              }
              return labelFormatter(
                val?.toString(),
                {
                  ...fmt,
                  valFormat,
                },
                true
              );
            },
          },
        },
      } as Highcharts.PlotOptions);

      let plotLines: Highcharts.XAxisPlotLinesOptions[] = chartOptions.xAxis.plotLines ?? [];
      if (
        (config?.delineateForecasts && type === EWidgetType2.LINE_CHART) ||
        type === EWidgetType2.BAR_CHART
      ) {
        // Get the first non -1
        const startPeriod = dateTypeData.datasets?.find((dt) => dt.specifics?.startPeriod)
          ?.specifics?.startPeriod;
        if (startPeriod && startPeriod !== "0" && categories) {
          const minIndex = config?.categoryFilters?.min ?? 0;
          const maxIndex = config?.categoryFilters?.max ?? categories.length - 1;

          const xAxisCategories = categories.slice(minIndex, maxIndex + 1);
          let plotIdx: number;
          if (frequency === ChartDataFrequency.Quarterly) {
            plotIdx = xAxisCategories.findIndex(
              (cat) => cat.includes("Q4") && cat.includes(startPeriod)
            );
          } else {
            plotIdx = xAxisCategories.findIndex((cat) => cat.includes(startPeriod));
          }
          plotLines = [
            {
              value: plotIdx,
              color: theme === Themes.DARK ? Colors.WHITE : Colors.BLACK,
              dashStyle: "Dash",
              width: 1,
            },
          ];
        }
      }

      const options: Highcharts.Options = {
        accessibility: {
          enabled: false,
        },
        chart: {
          animation: false,
          backgroundColor: theme === Themes.DARK ? Colors.DARK_GRAY3 : Colors.WHITE,
        },
        plotOptions,
        series,
        legend: chartOptions.legend,
        navigator: {
          ...chartOptions.navigator,
          enabled: chartOptions?.navigator?.enabled && xAxisType !== "category",
          xAxis: {
            labels:
              xAxisType === "category"
                ? {
                    formatter() {
                      const categories = this.chart.xAxis[0].categories;
                      return categories[+this.value];
                    },
                  }
                : undefined,
          },
        },
        rangeSelector:
          xAxisType === "category"
            ? {
                ...chartOptions.rangeSelector,
                buttons: [],
                inputEnabled: false,
              }
            : {
                ...chartOptions.rangeSelector,
                selected: chartOptions.rangeSelector.enabled
                  ? chartOptions.activeRangeSelector
                  : undefined,
              },
        scrollbar: chartOptions.scrollbar,
        tooltip: Highcharts.merge(config?.tooltip, {
          formatter: function () {
            const points = (this.points || [this.point]) as Highcharts.Point[];

            const tooltips = points.map((point) => {
              const axisID = seriesOptions[point.series.name]?.axis ?? YAxisId.RIGHT;
              const axisFormat =
                axisID === YAxisId.LEFT ? leftAxisConfigFormat : rightAxisConfigFormat;
              const isPercentage = axisID === YAxisId.LEFT ? isLeftPercentage : isRightPercentage;
              return tooltipPointFormatter(point, axisFormat, type, isPercentage);
            });

            let label =
              xAxisType === "category"
                ? this.point.category
                : new Date(this.point.x).toLocaleDateString(undefined, {
                    day: "numeric",
                    weekday: undefined,
                    month: "short",
                    year: "numeric",
                  });
            if (type === EWidgetType2.SCATTER_CHART) {
              label = "";
            }
            return [label.toString(), ...tooltips];
          },
          split: true,
        } as Highcharts.TooltipOptions),
        xAxis: {
          ...chartOptions.xAxis,
          categories: xAxisType === "category" ? xAxisCategories : undefined,
          minTickInterval: xAxisType === "datetime" ? 24 * 3600 * 1000 : undefined,
          ordinal: false,
          plotLines,
          type: xAxisType,
          labels:
            type === EWidgetType2.SCATTER_CHART
              ? {
                  ...chartOptions.xAxis.labels,
                  formatter: function () {
                    return labelFormatter(this.value, xAxisConfigFormat || {});
                  },
                }
              : chartOptions.xAxis.labels,
          title: {
            text: chartOptions?.xAxis?.title?.text || dateTypeData.xlabel,
          },

          tickPositioner() {
            // eslint-disable-next-line
            // @ts-ignore
            const axisWidth = this.width; // HC TS incorrect error
            let positions = this.tickPositions || [];

            // Check the ratio between axis length and amount of ticks
            if (
              xAxisType === "category" &&
              axisWidth &&
              axisWidth / positions.length < ChartTickGap
            ) {
              positions = positions.filter((_, index) => index % 2 === 1);
            }
            return positions;
          },
          events: {
            afterSetExtremes: (
              e: Highcharts.AxisSetExtremesEventObject & {
                rangeSelectorButton?: Highcharts.RangeSelectorButtonsOptions;
              }
            ) => {
              // seems like this is the way to hook intp when rangeSelector button or input is triggered
              // and the selected rangeSelector or input is set in the config using the button index
              if (e.trigger === "rangeSelectorButton" && e.rangeSelectorButton) {
                onRangeSelectorButtonChange(e.rangeSelectorButton);
              }
            },
          },
        },
        yAxis: yAxes,
      };
      return Highcharts.merge(options, themeOptions);
    }
  }, [
    categories,
    chartDefaultOptions,
    config,
    dateTypeData,
    parsedAndTrimmedData,
    frequency,
    type,
    theme,
    xAxisType,
    onRangeSelectorButtonChange,
  ]);

  if ((!data.datasets || data.datasets.length === 0) && !loading) {
    // eslint-disable-next-line no-console
    console.warn("Missing data required to create chart.", data);
    return <Callout intent={Intent.WARNING} title="Missing data required to create chart." />;
  }

  if (!chartOptsMemo && !loading) {
    // eslint-disable-next-line no-console
    console.warn("Unable to build chart.", data);
    return <Callout intent={Intent.WARNING} title="Unable to build chart." />;
  }

  return (
    <SizeMe refreshMode="throttle" refreshRate={300}>
      {(props) => (
        <Grid fullHeight fullWidth rows="1fr auto">
          <HighchartsReact
            constructorType={
              xAxisType === "category" || xAxisType === "linear" ? undefined : "stockChart"
            }
            containerProps={{ style: { height: "100%", width: props.size.width } }}
            highcharts={Highcharts}
            options={chartOptsMemo}
            ref={chartComponentRef}
            callback={(chart: Highcharts.Chart) => {
              setCurrentChartState({ ...chart } as Highcharts.Chart);
            }}
          />
          {config?.correlationMetrics && metricsAvailable ? metricsRibbon : <div />}
        </Grid>
      )}
    </SizeMe>
  );
};

export default ChartRenderer;
