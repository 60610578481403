import ModuleTree from "components/model/Explorer";
import DataEntryTable from "components/model/DataEntryTable";
import HistoricalValuationTable from "components/dashboard/model/CPP_HistoricalValuationTable";
import {
  EDashboardType,
  EWidgetType,
  IGenericWidget,
  IVSDashboard,
  IVSWidget,
  TWidgetKey,
} from "types/dashboard";
import KeyRatios from "components/model/KeyRatios";
import SourceView from "components/model/SourceView";
import EventHistory from "components/model/EventHistory";
import { WidgetKeys } from "./widgetKeys";
import CPP_MarketData from "components/dashboard/model/CPP_MarketData";
import CPP_TargetPrice from "components/dashboard/model/CPP_TargetPrice";
import CPP_Scenarios from "components/dashboard/model/CPP_Scenarios";
import CPP_ValuationSummary from "components/dashboard/model/CPP_ValuationSummary";
import CPP_ExpectedReturn from "components/dashboard/model/CPP_ExpectedReturn";
import CPP_TotalReturn from "components/dashboard/model/CPP_TotalReturn";
import CPP_FullScenarios from "components/dashboard/model/CPP_FullScenarios";
import CPP_Multiples from "components/dashboard/model/CPP_Multiples";

export const WidgetKeyToComponentMap = {
  // CPP Valuation Sheet
  [WidgetKeys.CPP_HISTORICAL_VALUATION]: HistoricalValuationTable,
  [WidgetKeys.CPP_MARKET_DATA]: CPP_MarketData,
  [WidgetKeys.CPP_SCENARIOS]: CPP_Scenarios,
  [WidgetKeys.CPP_VALUATION_SUMMARY]: CPP_ValuationSummary,
  [WidgetKeys.CPP_EXPECTED_RETURN]: CPP_ExpectedReturn,
  [WidgetKeys.CPP_TOTAL_RETURN]: CPP_TotalReturn,
  [WidgetKeys.CPP_TARGET_PRICE]: CPP_TargetPrice,
  [WidgetKeys.CPP_FULL_SCENARIOS]: CPP_FullScenarios,
  [WidgetKeys.CPP_MULTIPLES]: CPP_Multiples,

  // DATA
  [WidgetKeys.TREE]: ModuleTree,
  [WidgetKeys.TABLE]: DataEntryTable,
  [WidgetKeys.KEY_RATIOS]: KeyRatios,
  [WidgetKeys.SOURCE_VIEW]: SourceView,
  [WidgetKeys.EVENT_HISTORY]: EventHistory,
  // eslint-disable-next-line
} as { [key in TWidgetKey]: any };

export const GenericWidgets: { [key: string]: IGenericWidget } = {
  // CPP
  [WidgetKeys.CPP_HISTORICAL_VALUATION]: {
    component: HistoricalValuationTable,
    dashboardType: EDashboardType.View,
    displayName: "Historical Valuation - 5yr",
    widgetKey: WidgetKeys.CPP_HISTORICAL_VALUATION,
    type: EWidgetType.Table,
  },
  [WidgetKeys.CPP_MARKET_DATA]: {
    component: CPP_MarketData,
    dashboardType: EDashboardType.View,
    displayName: "Market Data",
    widgetKey: WidgetKeys.CPP_MARKET_DATA,
    type: EWidgetType.Table,
  },
  [WidgetKeys.CPP_TARGET_PRICE]: {
    component: CPP_TargetPrice,
    dashboardType: EDashboardType.View,
    displayName: "Target Price",
    widgetKey: WidgetKeys.CPP_TARGET_PRICE,
    type: EWidgetType.Chart,
  },
  [WidgetKeys.CPP_SCENARIOS]: {
    component: CPP_Scenarios,
    dashboardType: EDashboardType.View,
    displayName: "Scenarios",
    widgetKey: WidgetKeys.CPP_SCENARIOS,
    type: EWidgetType.Table,
  },
  [WidgetKeys.CPP_VALUATION_SUMMARY]: {
    component: CPP_ValuationSummary,
    dashboardType: EDashboardType.View,
    displayName: "Valuation Summary",
    widgetKey: WidgetKeys.CPP_VALUATION_SUMMARY,
    type: EWidgetType.Table,
  },
  [WidgetKeys.CPP_EXPECTED_RETURN]: {
    component: CPP_ExpectedReturn,
    dashboardType: EDashboardType.View,
    displayName: "Expected Return (%)",
    widgetKey: WidgetKeys.CPP_EXPECTED_RETURN,
    type: EWidgetType.Table,
  },
  [WidgetKeys.CPP_TOTAL_RETURN]: {
    component: CPP_TotalReturn,
    dashboardType: EDashboardType.View,
    displayName: "Total Return Attribution ",
    widgetKey: WidgetKeys.CPP_TOTAL_RETURN,
    type: EWidgetType.Table,
  },
  [WidgetKeys.CPP_FULL_SCENARIOS]: {
    component: CPP_FullScenarios,
    dashboardType: EDashboardType.View,
    displayName: "Scenarios",
    widgetKey: WidgetKeys.CPP_FULL_SCENARIOS,
    type: EWidgetType.Table,
  },
  [WidgetKeys.CPP_MULTIPLES]: {
    component: CPP_Multiples,
    dashboardType: EDashboardType.View,
    displayName: "Peer Multiples - Current",
    widgetKey: WidgetKeys.CPP_MULTIPLES,
    type: EWidgetType.Table,
  },

  // DATA
  [WidgetKeys.TREE]: {
    component: ModuleTree,
    dashboardType: EDashboardType.Edit,
    displayName: "Module Tree",
    widgetKey: WidgetKeys.TREE,
    type: EWidgetType.VS,
  },
  [WidgetKeys.TABLE]: {
    component: DataEntryTable,
    dashboardType: EDashboardType.Edit,
    displayName: "Model Edit Table",
    widgetKey: WidgetKeys.TABLE,
    type: EWidgetType.Table,
  },
};
export const DefaultWidgetTemplates: { [key: string]: IVSWidget } = {
  [WidgetKeys.TABLE]: {
    displayName: "",
    baseWidget: GenericWidgets[WidgetKeys.TABLE],
    fetchesOwnData: true,
    fullHeight: true,
    gridKey: WidgetKeys.TABLE,
    widgetContainerStyles: { height: "100%", zIndex: 2 },
    type: EWidgetType.Table,
    layout: {
      i: WidgetKeys.TABLE,
      x: 0,
      y: 0,
      w: 12,
      h: 10,
      minH: 2,
    },
  },
  [WidgetKeys.CPP_PEER_MULTIPLES]: {
    displayName: "Peer Multiples - Current",
    baseWidget: GenericWidgets[WidgetKeys.CPP_PEER_MULTIPLES],
    gridKey: WidgetKeys.CPP_PEER_MULTIPLES,
    widgetContainerStyles: {},
    type: EWidgetType.Table,
    layout: {
      i: WidgetKeys.CPP_PEER_MULTIPLES,
      x: 0,
      y: 16,
      w: 6,
      h: 10,
    },
  },
  [WidgetKeys.CPP_MARKET_DATA]: {
    baseWidget: GenericWidgets[WidgetKeys.CPP_MARKET_DATA],
    displayName: "Market Data",
    fetchesOwnData: true,
    gridKey: WidgetKeys.CPP_MARKET_DATA,
    layout: {
      i: WidgetKeys.CPP_MARKET_DATA,
      x: 0,
      y: 0,
      w: 6,
      h: 12,
    },
    type: EWidgetType.Table,
    widgetContainerStyles: {},
  },
  [WidgetKeys.CPP_SCENARIOS]: {
    baseWidget: GenericWidgets[WidgetKeys.CPP_SCENARIOS],
    displayName: "Scenarios",
    fetchesOwnData: true,
    gridKey: WidgetKeys.CPP_SCENARIOS,
    layout: {
      i: WidgetKeys.CPP_SCENARIOS,
      x: 0,
      y: 0,
      w: 6,
      h: 245,
    },
    type: EWidgetType.Chart,
    widgetContainerStyles: {},
  },
  [WidgetKeys.CPP_HISTORICAL_VALUATION]: {
    displayName: "Historical Valuation - 5 year",
    baseWidget: GenericWidgets[WidgetKeys.CPP_HISTORICAL_VALUATION],
    fetchesOwnData: true,
    gridKey: WidgetKeys.CPP_HISTORICAL_VALUATION,
    widgetContainerStyles: {},
    type: EWidgetType.Table,
    layout: {
      i: WidgetKeys.CPP_HISTORICAL_VALUATION,
      x: 6,
      y: 0,
      w: 6,
      h: 24,
    },
  },
  [WidgetKeys.CPP_MULTIPLES]: {
    baseWidget: GenericWidgets[WidgetKeys.CPP_MULTIPLES],
    displayName: "Peer Multiples - Current",
    fetchesOwnData: true,
    gridKey: WidgetKeys.CPP_MULTIPLES,
    layout: {
      i: WidgetKeys.CPP_MULTIPLES,
      x: 6,
      y: 0,
      w: 6,
      h: 24,
    },
    type: EWidgetType.Table,
    widgetContainerStyles: {},
  },
  [WidgetKeys.CPP_VALUATION_SUMMARY]: {
    baseWidget: GenericWidgets[WidgetKeys.CPP_VALUATION_SUMMARY],
    displayName: "Valuation Summary",
    fetchesOwnData: true,
    gridKey: WidgetKeys.CPP_VALUATION_SUMMARY,
    layout: {
      i: WidgetKeys.CPP_VALUATION_SUMMARY,
      x: 6,
      y: 0,
      w: 6,
      h: 40,
    },
    type: EWidgetType.Table,
    widgetContainerStyles: {},
  },
  [WidgetKeys.CPP_EXPECTED_RETURN]: {
    baseWidget: GenericWidgets[WidgetKeys.CPP_EXPECTED_RETURN],
    displayName: "Expected Return (%)",
    fetchesOwnData: true,
    gridKey: WidgetKeys.CPP_EXPECTED_RETURN,
    layout: {
      i: WidgetKeys.CPP_EXPECTED_RETURN,
      x: 6,
      y: 0,
      w: 6,
      h: 19,
    },
    type: EWidgetType.Table,
    widgetContainerStyles: {},
  },
  [WidgetKeys.CPP_TOTAL_RETURN]: {
    baseWidget: GenericWidgets[WidgetKeys.CPP_TOTAL_RETURN],
    displayName: "Total Return Attribution",
    fetchesOwnData: true,
    gridKey: WidgetKeys.CPP_TOTAL_RETURN,
    layout: {
      i: WidgetKeys.CPP_TOTAL_RETURN,
      x: 6,
      y: 0,
      w: 6,
      h: 20,
    },
    type: EWidgetType.Table,
    widgetContainerStyles: {},
  },
  [WidgetKeys.CPP_TARGET_PRICE]: {
    baseWidget: GenericWidgets[WidgetKeys.CPP_TARGET_PRICE],
    displayName: "Target Price",
    fetchesOwnData: true,
    gridKey: WidgetKeys.CPP_TARGET_PRICE,
    layout: {
      i: WidgetKeys.CPP_TARGET_PRICE,
      x: 6,
      y: 0,
      w: 6,
      h: 40,
    },
    type: EWidgetType.Chart,
    widgetContainerStyles: {},
  },
  [WidgetKeys.CPP_FULL_SCENARIOS]: {
    baseWidget: GenericWidgets[WidgetKeys.CPP_FULL_SCENARIOS],
    displayName: "Scenarios",
    fetchesOwnData: true,
    gridKey: WidgetKeys.CPP_FULL_SCENARIOS,
    layout: {
      i: WidgetKeys.CPP_FULL_SCENARIOS,
      x: 6,
      y: 0,
      w: 6,
      h: 205,
    },
    type: EWidgetType.Table,
    widgetContainerStyles: {},
  },
};

export const DefaultDashboards: Array<IVSDashboard> = [
  { name: "Data", isDefault: true, type: EDashboardType.Edit, widgets: [] },
  {
    name: "Valuation Sheet",
    isDefault: false,
    type: EDashboardType.View,
    widgets: [
      DefaultWidgetTemplates[WidgetKeys.CPP_MARKET_DATA],
      DefaultWidgetTemplates[WidgetKeys.CPP_SCENARIOS],
      DefaultWidgetTemplates[WidgetKeys.CPP_HISTORICAL_VALUATION],
      DefaultWidgetTemplates[WidgetKeys.CPP_MULTIPLES],
      DefaultWidgetTemplates[WidgetKeys.CPP_VALUATION_SUMMARY],
      DefaultWidgetTemplates[WidgetKeys.CPP_EXPECTED_RETURN],
      DefaultWidgetTemplates[WidgetKeys.CPP_TOTAL_RETURN],
      DefaultWidgetTemplates[WidgetKeys.CPP_TARGET_PRICE],
      DefaultWidgetTemplates[WidgetKeys.CPP_FULL_SCENARIOS],
    ],
  },
];
