import { CSSProperties } from "react";

export enum EUnit {
  RAW = "Raw",
  TRILLIONS = "Trillions",
  BILLIONS = "Billions",
  MILLIONS = "Millions",
  THOUSANDS = "Thousands",
}

export enum EValFormat {
  CURRENCY = "Currency",
  MULTIPLE = "Multiple",
  NUMERIC = "Numeric",
  PERCENTAGE = "Percentage",
  PLAINTEXT = "Plain text",
}

export interface IFormatObject extends CSSProperties {
  currency?: string;
  decimalPlaces?: number;
  hasSuffix?: boolean;
  locale?: string;
  percentageTransform?: boolean;
  type?: string;
  unit?: EUnit;
  valFormat?: EValFormat;
}

export const BaseFormatObject: IFormatObject = {
  currency: "USD",
  decimalPlaces: 1,
  fontWeight: "normal",
  fontStyle: "normal",
  textAlign: "right",
  textDecoration: "none",
  type: "",
  unit: EUnit.RAW,
  valFormat: EValFormat.NUMERIC,
};

export const SuffixToUnitMap = {
  [EUnit.RAW]: "",
  [EUnit.TRILLIONS]: "T",
  [EUnit.BILLIONS]: "B",
  [EUnit.MILLIONS]: "M",
  [EUnit.THOUSANDS]: "K",
};

export const SuffixToThresholdMap = [
  { suffix: "T", threshold: 1e12 },
  { suffix: "B", threshold: 1e9 },
  { suffix: "M", threshold: 1e6 },
  { suffix: "K", threshold: 1e3 },
  { suffix: "", threshold: 1 },
];
