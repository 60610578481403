import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Callout, Classes, Dialog, Intent, UL } from "@blueprintjs/core";
import { Themes } from "constants/uiConstants";

import { PaddedContent } from "components/utility/StyledComponents";
import DataEntryModuleSelect from "./DataEntryModuleSelect";
import { ILineItem, IModule, IModuleInfo } from "types/model";
import { TNormalizedImportLineItem, TNormalizedImportModule } from "types/import";
import AppContext from "context/appContext";

interface IDeleteModuleDialogProps {
  currentModule: IModuleInfo;
  deleteModule: (moduleID: string) => void;
  deleting: boolean;
  isOpen: boolean;
  isImport?: boolean;
  lineItems: Record<string, ILineItem> | Record<string, TNormalizedImportLineItem>;
  modules: Record<string, IModule> | Record<string, TNormalizedImportModule>;
  setOpen: (open: boolean) => void;
}

const DeleteModuleDialog: React.FC<IDeleteModuleDialogProps> = React.memo(
  ({
    currentModule,
    deleteModule,
    deleting,
    isOpen,
    isImport = false,
    lineItems,
    modules,
    setOpen,
  }) => {
    const {
      config: { theme },
    } = useContext(AppContext);
    const [modToDelete, setModToDelete] = useState<IModuleInfo | undefined>(currentModule);

    const deleteableModules = useMemo(() => {
      if (modules) {
        return Object.values(modules).filter((mod: IModule | TNormalizedImportModule) => {
          if (!isImport && mod.lineItems) {
            return !mod.lineItems.some((li) => lineItems[li].tags);
          }
          return true;
        });
      }
      return [];
    }, [isImport, lineItems, modules]);

    useEffect(() => {
      setOpen(false);
    }, [modules, setOpen]);

    useEffect(() => {
      if (deleteableModules.some((mod) => mod?.uid === currentModule?.uid)) {
        setModToDelete(currentModule);
      } else if (deleteableModules.length > 0) {
        setModToDelete(deleteableModules[0]);
      } else {
        setModToDelete(undefined);
      }
    }, [currentModule, deleteableModules]);

    const close = useCallback(() => setOpen(false), [setOpen]);

    return (
      <Dialog
        autoFocus={false}
        enforceFocus={false}
        canEscapeKeyClose={false}
        className={theme === Themes.DARK ? Classes.DARK : undefined}
        icon="cube-remove"
        isCloseButtonShown={!deleting}
        isOpen={isOpen}
        onClose={close}
        title="Delete Module"
      >
        {!modToDelete ? (
          <PaddedContent padding="10px">
            <Callout intent={Intent.DANGER} title="No deleteable modules!" />
          </PaddedContent>
        ) : null}
        <div className={Classes.DIALOG_BODY}>
          {isImport ? (
            <p className={Classes.RUNNING_TEXT}>
              Select a module from the list below to delete it.
            </p>
          ) : (
            <>
              <p className={Classes.RUNNING_TEXT}>
                Select a module from the list below to delete it. Though this can be undone, doing
                so may take a long time.
              </p>
              <p>Modules cannot be deleted if they:</p>
              <UL>
                <li>Have child modules.</li>
                <li>Contain tagged line items.</li>
              </UL>
            </>
          )}
          <DataEntryModuleSelect
            currentModule={modToDelete}
            disabled={!modToDelete}
            id="delete-module"
            label="Deleting module:"
            moduleList={deleteableModules}
            onItemSelect={(item: IModuleInfo) => setModToDelete(item)}
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button disabled={deleting} onClick={close} text="Cancel" />
            <Button
              autoFocus
              disabled={!currentModule || !modToDelete}
              loading={deleting}
              onClick={() => {
                if (modToDelete) {
                  deleteModule(modToDelete.uid);
                }
              }}
              intent={Intent.DANGER}
              text="Delete"
            />
          </div>
        </div>
      </Dialog>
    );
  }
);

DeleteModuleDialog.displayName = "DeleteModuleDialog";

export default DeleteModuleDialog;
