import { Themes } from "constants/uiConstants";
import {
  Button,
  ButtonGroup,
  Callout,
  Checkbox,
  Classes,
  Colors,
  Dialog,
  DialogBody,
  DialogFooter,
  Icon,
  Intent,
  MenuItem,
  Switch,
  TagInput,
} from "@blueprintjs/core";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "context/appContext";
import { ItemRenderer, MultiSelect, Select } from "@blueprintjs/select";
import { IconNames } from "@blueprintjs/icons";
import { Selector, SelectorTypes } from "types/vsl";
import isFunction from "lodash.isfunction";
import styled from "styled-components/macro";
import { Flex } from "components/utility/StyledComponents";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Widget } from "types/widget";
import {
  AbsolutePeriodSelectorType,
  LineItemTagSelectorType,
  RelativePeriodSelectorType,
} from "constants/vsl";
import LoadingIndicator from "components/utility/LoadingIndicator";
import { IResponse } from "types";
import { parseVSLQuerySelectors } from "utils/vsl";
import { FETCH_SELECTOR_TYPES } from "constants/apiConstants";
import { swrApi } from "utils/api";
import useSWRImmutable from "swr/immutable";
import range from "lodash.range";
import {
  ABSOLUTE_PERIOD_END_YEAR,
  ABSOLUTE_PERIOD_START_YEAR,
  RELATIVE_PERIOD_YEARS_GENERATED,
} from "constants/appConstants";

export interface EditWidgetSelectorsDialogProps {
  onClose: () => void;
  name?: string;
  isOpen: boolean;
  isView?: boolean;
  selectors?: Selector[];
  updateWidget?: (details: Partial<Widget>, modified?: boolean) => void;
  queries: string[];
}

/** to support drag and drop we need the parent component not to be scrollable */
const StyledNonScrollableDialogBody = styled(DialogBody)`
  overflow: hidden;
`;

const StyledSelectorCard = styled.div`
  display: flex;
  border-radius: 5px;
  border: 1px solid ${Colors.DARK_GRAY3};
  padding: 10px;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
`;

const StyledSelectorErrorMessage = styled.span`
  color: ${Colors.GOLD3};
`;

const StyledSelectorErrorIDHighlight = styled.span`
  color: ${Colors.GOLD1};
`;

const EditWidgetSelectorsDialog: React.FC<EditWidgetSelectorsDialogProps> = ({
  onClose,
  isOpen,
  isView,
  name,
  selectors,
  updateWidget,
  queries,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);

  const [localSelectors, setLocalSelectors] = useState<Selector[]>(selectors ?? []);
  const [activeFilterByDropdown, setActiveFilterByDropdown] = useState<string | null>(null);

  const updateLocalSelector = <K extends keyof Selector>(
    selectorId: string,
    propName: K,
    value: Selector[K]
  ) => {
    const selectorIdx = localSelectors.findIndex((ls) => ls.id === selectorId);
    setLocalSelectors((prevLocalSelectors) => {
      const newSelectors = [...prevLocalSelectors];
      const newSelector = { ...localSelectors[selectorIdx] };
      newSelector[propName] = value;
      newSelectors[selectorIdx] = newSelector;
      return newSelectors;
    });
  };

  /**
   * make sure selectors have available options if AbsolutePeriodSelectorType or RelativePeriodSelectorType
   * TODO: can all be removed after FEAT-1283 is completed on the backend
   */
  useEffect(() => {
    const updateLocalSelector = (localSelectors ?? []).reduce((result, selector) => {
      return (
        result ||
        (!selector.available_options &&
          (selector.type === AbsolutePeriodSelectorType ||
            selector.type === RelativePeriodSelectorType))
      );
    }, false);
    if (updateLocalSelector) {
      setLocalSelectors(
        localSelectors?.map((selector) => {
          if (!selector.available_options && selector.type === AbsolutePeriodSelectorType) {
            return {
              ...selector,
              available_options: Array.from(
                range(ABSOLUTE_PERIOD_START_YEAR, ABSOLUTE_PERIOD_END_YEAR, 1),
                (val) => String(val)
              ),
            };
          }

          if (!selector.available_options && selector.type === RelativePeriodSelectorType) {
            return {
              ...selector,
              available_options: Array.from(
                { length: RELATIVE_PERIOD_YEARS_GENERATED },
                (_, idx) => `LFY+${idx + 1}`
              ),
            };
          }

          return selector;
        })
      );
    }
  }, [localSelectors]);

  /** always sync the local selectors with the provided selectors if they change outside */
  useEffect(() => {
    setLocalSelectors(selectors ?? []);
  }, [selectors, setLocalSelectors]);

  const {
    data: selectorTypes,
    error: selectorTypesError,
    isLoading: loadingSelectorTypes,
  } = useSWRImmutable<IResponse<SelectorTypes>>([FETCH_SELECTOR_TYPES], swrApi);

  const handleClose = () => {
    onClose();
  };

  // TODO: display invalid ones because of inconsistent props for same id
  const querySelectors = parseVSLQuerySelectors(queries);
  const allRequiredSelectorsAdded = !querySelectors.some(
    (qs) => qs.required && !localSelectors.find((ls) => ls.id === qs.id)
  );

  const renderAddItem: ItemRenderer<Selector> = (item, { modifiers, handleClick }) => {
    const inUse = localSelectors.some((sel) => sel.id === item.id);
    return (
      <MenuItem
        active={modifiers.active}
        disabled={inUse}
        key={item.id}
        onClick={handleClick}
        text={`${item.id} (${item.type} ${item.source_id ? ": " + item.source_id : ""})`}
        label={isView ? "" : item.required ? "Required" : "Optional"}
        icon={inUse && IconNames.TICK}
      />
    );
  };

  const filterByRenderer: ItemRenderer<string> = (item, { handleClick, modifiers }) => {
    return <MenuItem active={modifiers.active} key={item} onClick={handleClick} text={item} />;
  };

  const handleDeleteSelector = (selectorIdx: number) => {
    setLocalSelectors((prevSelectors) => {
      const newSelectors = [...prevSelectors];
      newSelectors.splice(selectorIdx, 1);
      return newSelectors;
    });
  };

  const handleDragEnd = (dropResult: DropResult) => {
    setLocalSelectors((prevLocalSelectors) => {
      if (dropResult.destination?.index === undefined) return prevLocalSelectors;
      const newSelectors = [...prevLocalSelectors];
      const sel = newSelectors.splice(dropResult.source.index, 1)[0];
      newSelectors.splice(dropResult.destination.index, 0, sel);
      return newSelectors;
    });
  };

  const selectorErrors = (
    selector: Selector,
    idx: number
  ): {
    filteredByThisNotInOrder: string[];
    idsThisFiltersNotInOrder: string[];
    missingId: boolean;
  } | null => {
    const filteredByThisNotInOrder = localSelectors.reduce((ids: string[], localSel, localIdx) => {
      if (localSel.filtered_by?.includes(selector.id) && idx > localIdx) {
        return [...ids, localSel.id];
      }
      return ids;
    }, []);

    const idsThisFiltersNotInOrder = (selector.filtered_by ?? []).reduce(
      (ids: string[], filterId) => {
        const matchingSelectorIdx = localSelectors.findIndex(
          (localSel) => localSel.id === filterId
        );
        if (matchingSelectorIdx > idx) {
          return [...ids, filterId];
        }
        return ids;
      },
      []
    );

    const missingId = !querySelectors.map((qs) => qs.id).includes(selector.id);

    if (
      filteredByThisNotInOrder.length === 0 &&
      idsThisFiltersNotInOrder.length === 0 &&
      !missingId
    ) {
      return null;
    }

    return {
      missingId,
      filteredByThisNotInOrder,
      idsThisFiltersNotInOrder,
    };
  };

  const hasAnyErrors = localSelectors
    .map((localSelector, idx) => selectorErrors(localSelector, idx))
    .some((error) => !!error);

  const renderSelector = (selector: Selector, idx: number) => {
    const errors = selectorErrors(selector, idx);

    const borderColor = errors ? Colors.GOLD3 : Colors.LIGHT_GRAY3;

    return (
      <Draggable key={selector.id} draggableId={selector.id} index={idx}>
        {(provided, snapshot) => (
          <StyledSelectorCard
            className={`${Classes.ELEVATION_2} ${Classes.CARD}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            key={selector.id}
            style={{
              ...provided.draggableProps.style,
              boxShadow: snapshot.isDragging ? "0 4px 8px 0 rgba(0,0,0,0.5)" : "none",
              borderColor: snapshot.isDragging ? Colors.BLUE3 : borderColor,
            }}
          >
            <Flex flexDirection="row" fullWidth justifyContent="space-between">
              <Icon icon={IconNames.DRAG_HANDLE_VERTICAL} {...provided.dragHandleProps} />
              {`${selector.id} (${selector.type}${
                selector.source_id ? " : " + selector.source_id : ""
              })`}
              <ButtonGroup style={{ marginLeft: "auto" }}>
                <Button
                  icon={selector.hidden ? IconNames.EYE_OFF : IconNames.EYE_OPEN}
                  minimal
                  onClick={() => updateLocalSelector(selector.id, "hidden", !selector.hidden)}
                />
                <Button icon={IconNames.TRASH} minimal onClick={() => handleDeleteSelector(idx)} />
              </ButtonGroup>
            </Flex>

            {errors ? (
              <Callout intent={Intent.WARNING}>
                <Flex
                  flexDirection="column"
                  fullWidth
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  {errors.idsThisFiltersNotInOrder.length > 0 ? (
                    <StyledSelectorErrorMessage>
                      This item is filtered by{" "}
                      <StyledSelectorErrorIDHighlight>
                        {errors.idsThisFiltersNotInOrder.join(",")}
                      </StyledSelectorErrorIDHighlight>
                      , please reorder to below{" "}
                      <StyledSelectorErrorIDHighlight>
                        {errors.idsThisFiltersNotInOrder.join(",")}
                      </StyledSelectorErrorIDHighlight>{" "}
                      to continue.
                    </StyledSelectorErrorMessage>
                  ) : null}
                  {errors.filteredByThisNotInOrder.length > 0 ? (
                    <StyledSelectorErrorMessage>
                      <StyledSelectorErrorIDHighlight>
                        {errors.filteredByThisNotInOrder.join(",")}
                      </StyledSelectorErrorIDHighlight>{" "}
                      is filtered by this item, please reorder to above{" "}
                      <StyledSelectorErrorIDHighlight>
                        {errors.filteredByThisNotInOrder.join(",")}
                      </StyledSelectorErrorIDHighlight>{" "}
                      to continue.
                    </StyledSelectorErrorMessage>
                  ) : null}
                  {errors.missingId ? (
                    <StyledSelectorErrorMessage>
                      {`${selector.id} `}is not a valid selector ID.
                    </StyledSelectorErrorMessage>
                  ) : null}
                </Flex>
              </Callout>
            ) : null}

            {!!selectorTypes?.data.selector_types[selector.type]?.length && (
              <MultiSelect<string>
                disabled={!selector}
                placeholder="Filter by..."
                items={
                  querySelectors
                    .filter(
                      (sel) =>
                        sel.id !== selector.id && //  can't filter by itself
                        localSelectors.find((localSelector) => localSelector?.id === sel.id) && // only existing selectors
                        selectorTypes?.data.selector_types[selector.type].includes(sel.type) // supported type
                      // TODO: avoid circular dependencies
                    )
                    .map((sel) => sel.id) || []
                }
                selectedItems={selector.filtered_by || []}
                itemRenderer={filterByRenderer}
                noResults={<MenuItem text="No options available" />}
                onItemSelect={(item) => {
                  // add dependency and reorder if necessary to have selectors used as filters coming before where they're used
                  setLocalSelectors((prevLocalSelectors) => {
                    return prevLocalSelectors.map((prevSelector) => {
                      if (
                        selector.id === prevSelector.id &&
                        !prevSelector.filtered_by?.includes(item)
                      ) {
                        return {
                          ...prevSelector,
                          filtered_by: [...(prevSelector.filtered_by || []), item],
                        };
                      }

                      return prevSelector;
                    });
                  });
                  setActiveFilterByDropdown(null);
                }}
                onRemove={(item) => {
                  setLocalSelectors((prevLocalSelectors) => {
                    return prevLocalSelectors.map((prevSelector) => {
                      if (
                        prevSelector.id === selector.id &&
                        prevSelector.filtered_by?.includes(item)
                      ) {
                        return {
                          ...prevSelector,
                          filtered_by: prevSelector.filtered_by.filter(
                            (filterItem) => filterItem !== item
                          ),
                        };
                      }

                      return prevSelector;
                    });
                  });
                }}
                popoverProps={{
                  isOpen: activeFilterByDropdown === selector.id,
                  onInteraction: (state) => setActiveFilterByDropdown(state ? selector.id : null),
                  minimal: true,
                }}
                resetOnSelect={true}
                fill
                tagRenderer={(item) => item}
              />
            )}

            <TagInput
              fill
              onAdd={(items) => {
                const selectors = [
                  ...(localSelectors.find((sel) => sel.id === selector.id)?.available_options ||
                    []),
                  ...items,
                ];
                updateLocalSelector(selector.id, "available_options", selectors);
              }}
              onRemove={(_, index) => {
                const selectors = [...(selector?.available_options || [])];
                selectors.splice(index, 1);
                updateLocalSelector(selector.id, "available_options", selectors);
              }}
              placeholder="Overwrite selector options..."
              separator="\n"
              values={selector.available_options ?? []}
            />
            <Switch
              label="Allow multiple entries"
              checked={selector.multiselect ?? false}
              onChange={() => {
                updateLocalSelector(selector.id, "multiselect", !selector.multiselect);
              }}
            />
            <Switch
              label="Break line"
              checked={selector.breakline}
              onChange={() => {
                updateLocalSelector(selector.id, "breakline", !selector.breakline);
              }}
            />
            {selector.type === LineItemTagSelectorType && (
              <Flex flexDirection={"row"} fullWidth gap={12} justifyContent="flex-start">
                <Checkbox
                  checked={!!selector?.kpi}
                  disabled={!selector}
                  key={`kpi ${selector}`}
                  label="KPI"
                  onChange={() => {
                    updateLocalSelector(selector.id, "kpi", !selector.kpi);
                  }}
                />
                <Checkbox
                  checked={!!selector?.root_tags_only}
                  disabled={!selector}
                  key={`root_tags_only ${selector}`}
                  label="Root tag"
                  onChange={() => {
                    updateLocalSelector(selector.id, "root_tags_only", !selector?.root_tags_only);
                  }}
                />
                <Checkbox
                  checked={!!selector?.case_only}
                  disabled={!selector}
                  key={`case_only ${selector}`}
                  label="Case tag"
                  onChange={() => {
                    updateLocalSelector(selector.id, "case_only", !selector?.case_only);
                  }}
                />
                <Checkbox
                  checked={!!selector?.tracked}
                  disabled={!selector}
                  key={`tracked ${selector}`}
                  label="Tracked"
                  onChange={() => {
                    updateLocalSelector(selector.id, "tracked", !selector?.tracked);
                  }}
                />
              </Flex>
            )}
          </StyledSelectorCard>
        )}
      </Draggable>
    );
  };

  return (
    <Dialog
      className={theme === Themes.DARK ? Classes.DARK : ""}
      icon={IconNames.FILTER_LIST}
      isOpen={isOpen}
      onClose={handleClose}
      title={`Edit Selectors (${name})`}
    >
      {loadingSelectorTypes && (
        <LoadingIndicator
          status={{ intent: Intent.PRIMARY, message: "Loading..." }}
        ></LoadingIndicator>
      )}
      {(selectorTypesError || selectorTypes?.error) && (
        <Callout intent={Intent.DANGER} title={"Couldn't load selectors"}></Callout>
      )}
      {!loadingSelectorTypes && !selectorTypesError && !selectorTypes?.error && (
        <StyledNonScrollableDialogBody>
          <Select
            items={querySelectors.sort((qs1) => (qs1.required ? -1 : 1)) || []}
            itemRenderer={renderAddItem}
            filterable={false}
            onItemSelect={(item) => {
              setLocalSelectors((prevSelectors) => [
                ...prevSelectors,
                {
                  id: item.id,
                  type: item.type,
                  required: item.required,
                  ...(item.source_id && { source_id: item.source_id }),
                },
              ]);
            }}
            popoverProps={{ minimal: true }}
          >
            <Button
              disabled={!querySelectors?.length}
              fill
              intent={Intent.SUCCESS}
              rightIcon={IconNames.PLUS}
              text={"Add Selector"}
              style={{ marginBottom: "15px" }}
            />
          </Select>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="selectors" direction="vertical">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    overflowY: "auto",
                    maxHeight: "calc(70vh - 100px)",
                  }}
                  onScroll={() => setActiveFilterByDropdown(null)}
                >
                  {localSelectors.map((selector, idx) => renderSelector(selector, idx))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </StyledNonScrollableDialogBody>
      )}
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button className={Classes.DIALOG_CLOSE_BUTTON} onClick={handleClose} text="Cancel" />
          <Button
            disabled={(!isView && !allRequiredSelectorsAdded) || hasAnyErrors}
            className={Classes.DIALOG_CLOSE_BUTTON}
            intent={Intent.PRIMARY}
            onClick={() => {
              if (isFunction(updateWidget)) {
                updateWidget({ selectors: localSelectors });
              }
              onClose();
            }}
            text="Update"
          />
        </div>
      </DialogFooter>
    </Dialog>
  );
};

export default EditWidgetSelectorsDialog;
