import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Icon,
  Intent,
  Position,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Themes } from "constants/uiConstants";
import { IActionReturnType, IAppStatus, TTheme } from "types";
import ModelSettingsDialog from "components/model/ModelSettingsDialog";
import PreviousVersionsDialog from "components/model/PreviousVersionsDialog";
import ModelSettings from "components/dashboard/model/ModelSettings";
import { Flex } from "components/utility/StyledComponents";
import ModelBarTabs from "components/dashboard/model/ModelBarTabs";
import colors from "styles/colors.module.scss";
import { IBasicModelInfo, IModelInfo } from "types/model";
import ShareModelDialog from "components/model/ShareModelDialog";
import LoadingIndicator from "components/utility/LoadingIndicator";
import { editModelProperties, exportToExcel } from "actions/modelActions";
import AppContext from "context/appContext";
import ModelContext from "context/modelContext";
import ModelWarningsDialog from "components/dashboard/model/ModelWarningsDialog";
import { validatorWarning } from "utils/data";
import { checkConnection } from "utils/api";
import { IconNames } from "@blueprintjs/icons";
import { lockUI } from "actions/dataEntryUIActions";
import { recalculateModel } from "actions/dataEntryActions";
import { View } from "types/view";
import SantiseModelData from "components/model/SanitiseModelData";

interface IStyledControlBarProps {
  $theme: TTheme;
}

const StyledControlBar = styled.div<IStyledControlBarProps>`
  height: 40px;
  padding: 0;
  background-color: ${({ $theme }) =>
    $theme === (Themes.DARK as TTheme)
      ? colors.darkModuleBackgroundColor
      : colors.moduleBackgroundColor};
  border-bottom: ${({ $theme }) =>
    $theme === (Themes.DARK as TTheme)
      ? "1px solid " + colors.darkGray3
      : "1px solid " + colors.lightGray3};
  // flex properties
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface IModelBarProps {
  addView: (view: View) => Promise<boolean>;
  appStatus: IAppStatus;
  dashboardId: string | undefined;
  editModelProperties: (
    modelID: string,
    edit: Partial<IBasicModelInfo<Date>>
  ) => IActionReturnType<string, { modelID: string; edit: IBasicModelInfo<Date> }>;
  exportToExcel: () => IActionReturnType<string, undefined>;
  loadingModel: boolean;
  locale?: string;
  lockUI: () => IActionReturnType<string, undefined>;
  modelInfo: IModelInfo | undefined;
  recalculateModel: () => IActionReturnType<string, undefined>;
  removeView: (viewId: string) => void;
  updatingDashboard: boolean;
  viewOnly: boolean;
  views: View[];
  warnings: validatorWarning[];
}

const ModelBar: React.FC<IModelBarProps> = ({
  addView,
  appStatus,
  dashboardId,
  editModelProperties,
  exportToExcel,
  loadingModel,
  locale,
  lockUI,
  modelInfo,
  recalculateModel,
  removeView,
  updatingDashboard,
  viewOnly,
  views,
  warnings,
}) => {
  const {
    config: { debugMode, theme },
  } = useContext(AppContext);
  const {
    locked,
    previousVersions: { activeVersion, availableVersions, setVersion },
  } = useContext(ModelContext);
  const navigate = useNavigate();
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [previousVersionsDialogOpen, setPreviousVersionsDialogOpen] = useState(false);
  const [sanitiseDataDialogOpen, setSanitiseDataDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [warningsDialogOpen, setWarningsDialogOpen] = useState(false);
  const firstWarn = useRef(false);
  const connected = checkConnection(appStatus);
  /** Effect to launch warnings dialog on start on load */
  useEffect(() => {
    if (!firstWarn.current && warnings?.length) {
      firstWarn.current = true;
      setWarningsDialogOpen(true);
    }
  }, [warnings]);

  return (
    <div>
      <ModelWarningsDialog
        setOpen={setWarningsDialogOpen}
        isOpen={warningsDialogOpen}
        warnings={warnings}
      />
      <PreviousVersionsDialog
        isOpen={previousVersionsDialogOpen}
        setOpen={setPreviousVersionsDialogOpen}
      />
      {modelInfo?.id && (
        <SantiseModelData
          isOpen={sanitiseDataDialogOpen}
          modelId={modelInfo.id}
          onClose={() => setSanitiseDataDialogOpen(false)}
        />
      )}
      {modelInfo?.id ? (
        <ModelSettingsDialog
          editModelProperties={editModelProperties}
          modelInfo={modelInfo}
          isOpen={settingsDialogOpen}
          setSettingsDialogOpen={setSettingsDialogOpen}
        />
      ) : null}
      {modelInfo?.id && shareDialogOpen ? (
        <ShareModelDialog
          isOpen={shareDialogOpen}
          modelInfo={modelInfo}
          setOpen={setShareDialogOpen}
        />
      ) : null}
      <StyledControlBar $theme={theme}>
        <Flex>
          {!modelInfo?.id ? (
            connected && <span style={{ paddingLeft: "10px" }}>Loading model...</span>
          ) : (
            <ModelSettings
              editModelProperties={editModelProperties}
              exportToExcel={exportToExcel}
              locked={locked}
              lockUI={lockUI}
              modelInfo={modelInfo}
              hasPreviousVersions={availableVersions.length > 0}
              recalculateModel={recalculateModel}
              settingsDialogOpen={settingsDialogOpen}
              setSettingsDialogOpen={setSettingsDialogOpen}
              setPreviousVersionsDialogOpen={setPreviousVersionsDialogOpen}
              setSanitiseDataDialogOpen={setSanitiseDataDialogOpen}
              setShareDialogOpen={setShareDialogOpen}
            />
          )}
          {viewOnly && (
            <Flex justifyContent="flex-start" gap={8}>
              <Tooltip
                content={
                  activeVersion
                    ? "You are viewing a previous version of this model. Click View Latest to enable editing."
                    : "Your permissions level for this model is read only"
                }
                position={Position.BOTTOM}
              >
                <Tag rightIcon={IconNames.EYE_OPEN} large={true} intent={Intent.PRIMARY}>
                  Read only
                </Tag>
              </Tooltip>
              {activeVersion && (
                <>
                  <b>Viewing as at {activeVersion.date.toLocaleDateString(locale)}</b>
                  <Button
                    minimal
                    icon={IconNames.FAST_FORWARD}
                    text="View Latest"
                    onClick={() => setVersion(null)}
                  />
                </>
              )}
            </Flex>
          )}
          {debugMode && !warnings.length && (
            <Tooltip content="Debug mode activated. No warnings.">
              <Icon icon="bug" />
            </Tooltip>
          )}
          {debugMode && warnings.length ? (
            <Tooltip content="Model data warnings list" openOnTargetFocus={false}>
              <AnchorButton
                icon={IconNames.WARNING_SIGN}
                intent={Intent.WARNING}
                minimal
                onClick={() => setWarningsDialogOpen(true)}
              />
            </Tooltip>
          ) : null}
        </Flex>
        {!loadingModel ? (
          <Flex>
            <ModelBarTabs
              addView={addView}
              dashboardId={dashboardId}
              locked={locked}
              removeView={removeView}
              updatingDashboard={updatingDashboard}
              views={views}
            />
          </Flex>
        ) : null}
        <Flex>
          {appStatus.loading || !connected ? (
            <div style={{ paddingRight: "10px" }}>
              <LoadingIndicator loading={appStatus.loading} status={appStatus} />
            </div>
          ) : (
            <ButtonGroup>
              <Button icon={IconNames.LOG_OUT} minimal onClick={() => navigate("/")} text="Close" />
            </ButtonGroup>
          )}
        </Flex>
      </StyledControlBar>
    </div>
  );
};

// eslint-disable-next-line
function mapStateToProps(state: any) {
  return {
    locale: state.ui.global.localeString,
    appStatus: state.ui.global.appStatus,
    warnings: state.model.warnings,
  };
}

const mapDispatchToProps = {
  editModelProperties,
  exportToExcel,
  lockUI,
  recalculateModel,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelBar);
