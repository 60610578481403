import { Intent } from "@blueprintjs/core";
import {
  DASHBOARD_LINK_ROUTE,
  DASHBOARD_ROUTE,
  DASHBOARD_UNLINK_ROUTE,
  EMethods,
  EStatus,
} from "constants/apiConstants";
import { useDebouncedCallback } from "hooks/useDebounce";
import { useCallback, useState } from "react";
import useSWR, { KeyedMutator } from "swr";
import { IResponse } from "types";
import { Dashboard } from "types/dashboard";
import { View } from "types/view";
import api, { swrApi } from "utils/api";
import { multiToaster } from "utils/toaster";

export interface IDashboardTab {
  id: string;
  pageId: string;
  dashboardId: string;
  name: string;
}

export type Callback = (err?: string, tab?: IDashboardTab) => void;

interface IHookReturn {
  addView: (view: View) => Promise<boolean>;
  dashboard?: Dashboard;
  loading?: boolean;
  mutate: KeyedMutator<IResponse<Dashboard>>;
  removeView: (viewId: string) => Promise<boolean>;
  updateDashboard: (details: Partial<Dashboard>, updateRemote?: boolean) => void;
  updating: boolean;
}

export default function useDashboard(dashboardId?: string): IHookReturn {
  const [updating, setUpdating] = useState(false);
  // fetch + cache
  const { data, isLoading, mutate } = useSWR<IResponse<Dashboard>>(
    dashboardId ? [DASHBOARD_ROUTE, { dashboardId }] : null,
    swrApi
  );

  // Add View to Dashboard
  const addView = useCallback(
    async (view: View) => {
      if (!data?.data) return false;
      setUpdating(true);
      const response = await api(DASHBOARD_LINK_ROUTE, {
        method: EMethods.PATCH,
        dashboardId,
        body: { viewIds: [view.id] },
      });
      if (response.status === EStatus.success) {
        await mutate();
        setUpdating(false);
        return true;
      } else {
        multiToaster.show({ message: response.error, intent: Intent.WARNING });
        setUpdating(false);
        return false;
      }
    },
    [dashboardId, data, mutate]
  );

  // Remove View from Dashboard
  const removeView = useCallback(
    async (viewId: string) => {
      if (!data?.data) return false;
      const nextViews = [...(data.data?.views || [])];
      const removeIndex = nextViews.findIndex((view) => view.id === viewId);
      nextViews.splice(removeIndex, 1);
      mutate({ ...data, data: { ...data.data, views: nextViews } }, false);

      const response = await api(DASHBOARD_UNLINK_ROUTE, {
        dashboardId,
        method: EMethods.DELETE,
        viewId,
      });

      if (response.status !== EStatus.success) {
        multiToaster.show({ intent: Intent.WARNING, message: response.error });
        mutate();
        return false;
      } else {
        return true;
      }
    },
    [dashboardId, data, mutate]
  );

  const saveDashboard = useCallback(
    async (details: Partial<Dashboard>) => {
      if (!data?.data) return;

      const nextDashboard = {
        ...data.data,
        ...details,
      };

      const response = await api(DASHBOARD_ROUTE, {
        dashboardId,
        method: EMethods.PUT,
        body: nextDashboard,
      });

      if (response.status !== EStatus.success) {
        multiToaster.show({ message: response.error, intent: Intent.WARNING });
      }
    },
    [dashboardId, data?.data]
  );

  const debouncedSaveDashboard = useDebouncedCallback(saveDashboard);

  // Update Dashboard name
  const updateDashboard = useCallback(
    (details: Partial<Dashboard>, updateRemote = true) => {
      if (!data?.data) return;
      mutate({ ...data, data: { ...data?.data, ...details } }, false);
      if (updateRemote) debouncedSaveDashboard(details);
    },
    [data, debouncedSaveDashboard, mutate]
  );

  return {
    addView,
    dashboard: data?.data,
    loading: isLoading,
    mutate,
    removeView,
    updateDashboard,
    updating,
  };
}
