import {
  Alignment,
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  MenuItem,
  Popover,
  Position,
  Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, ItemRendererProps, MultiSelect, Select } from "@blueprintjs/select";
import { Flex } from "components/utility/StyledComponents";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import styled from "styled-components";
import { Selector, SelectorContext, SelectorOption } from "types/vsl";
import { sortItemsByMatch } from "utils/generic";

interface VSLSelectorProps {
  context: SelectorContext;
  disabled?: boolean;
  enableEditableSelectors?: boolean;
  error?: string;
  loading: boolean;
  options: SelectorOption[];
  onSelect: (selector: Selector) => void;
  selector: Selector;
  isInvalid?: boolean;
  setSelectorsDialogOpen?: Dispatch<SetStateAction<boolean>>;
}
const VSLSelector = ({
  context,
  disabled,
  enableEditableSelectors = true,
  error,
  loading,
  onSelect,
  options,
  selector,
  isInvalid = false,
  setSelectorsDialogOpen,
}: VSLSelectorProps): JSX.Element => {
  const [query, setQuery] = useState("");
  // TODO item server side search once BE is performant
  //const [serverOptions, setServerOptions] = useState<SelectorOption[]>([]);

  /*const fetchOptions = async (selector: Selector, query: string) => {
    const selectorOptionsResponse = await api<SelectorOption[]>(SELECTORS_ROUTE, {
      method: EMethods.POST,
      body: {
        selectors: [
          {
            id: selector.id,
            search_term: query,
            type: selector.type,
          },
        ],
      },
    });
    if (selectorOptionsResponse.status === EStatus.failed) return null;
    return selectorOptionsResponse.data;
  };

  // Debounced function for fetching options
  const debouncedFetchOptions = useMemo(
    () =>
      debounce(async (selector, query) => {
        const filteredOptions = await fetchOptions(selector, query);
        setServerOptions(filteredOptions || []);
      }, 300),
    []
  );

  const handleSetQuery = useCallback(
    (selector: Selector, query: string) => {
      if (selector.type === LineItemTagSelectorType) {
        setServerOptions((prevServerOptions) => {
          if (prevServerOptions?.[0]?.value !== "loading...") {
            return [{ value: "loading...", label: "loading..." }];
          } else {
            return prevServerOptions;
          }
        });
        setQuery(query);
        debouncedFetchOptions(selector, query);
      } else setQuery(query);
    },
    [debouncedFetchOptions]
  ); */

  const onItemSelect = useCallback(
    (item: SelectorOption, singleOption: boolean) => {
      if (!item) return;
      setQuery("");

      if (singleOption) {
        onSelect({ ...selector, selected_options: [item] });
        return;
      }

      // multiselect
      const newOptions = selector.selected_options ? [...selector.selected_options] : [];
      if (
        selector.selected_options &&
        selector.selected_options.map((opt) => opt?.value).includes(item.value)
      ) {
        const removeIndex = newOptions.findIndex((option) => option.value === item.value);
        newOptions.splice(removeIndex, 1);
      } else {
        newOptions.push(item);
      }
      onSelect({ ...selector, selected_options: newOptions });
    },
    [onSelect, selector]
  );

  const renderMultiSelectItem = (
    item: string,
    { handleClick, modifiers }: ItemRendererProps,
    activeItems: string[]
  ) => {
    return (
      <MenuItem
        active={modifiers.active}
        icon={activeItems.indexOf(item) !== -1 ? IconNames.TICK : IconNames.BLANK}
        key={item}
        onClick={handleClick}
        text={item}
      />
    );
  };

  const renderSelect: ItemRenderer<SelectorOption> = (item, { handleClick, modifiers }) => {
    // used for server-side options
    // if (item.value === "loading...") return <Spinner size={SpinnerSize.SMALL} />;
    if (!modifiers.matchesPredicate) return null;
    return (
      <MenuItem
        active={modifiers.active}
        key={item.value}
        onClick={handleClick}
        text={item.label}
        roleStructure="listoption"
        selected={
          options.length === 1
            ? options[0].value === item.value
            : selector.selected_options?.[0]?.value === item.value
        }
      />
    );
  };

  let selectorContent: JSX.Element;
  if (isInvalid || error) {
    selectorContent = (
      <InputGroup
        disabled={true}
        defaultValue={isInvalid ? "Invalid selector" : selector.id}
        leftIcon={IconNames.WarningSign}
        intent={Intent.WARNING}
      />
    );
  } else if (selector.multiselect) {
    selectorContent = (
      <span>
        <MultiSelect<SelectorOption>
          disabled={
            disabled || !enableEditableSelectors || !!error || options.length === 1 || loading
          }
          placeholder={`${selector.name ?? selector.id}...`}
          items={sortItemsByMatch(query, options, 5)}
          itemRenderer={(item, props) =>
            renderMultiSelectItem(
              item.label,
              props,
              selector.selected_options?.map((selOpt) => selOpt.label) || []
            )
          }
          noResults={<MenuItem text="No options available" />}
          onItemSelect={(item) => onItemSelect(item, false)}
          onRemove={(itemToRemove) => {
            if (!selector.selected_options) return;
            onSelect({
              ...selector,
              selected_options: [
                ...selector.selected_options.filter((option) => {
                  return option.label !== itemToRemove.label;
                }),
              ],
            });
          }}
          popoverProps={{ minimal: true, targetTagName: "div" }}
          resetOnSelect={true}
          resetOnQuery={true}
          // TODO: this will fail for server side searched options
          selectedItems={options.length === 1 ? [options[0]] : selector.selected_options || []}
          tagInputProps={{
            tagProps: { minimal: true },
          }}
          tagRenderer={(tag: SelectorOption) => tag?.label}
          onQueryChange={setQuery}
          query={query}
        />
      </span>
    );
  } else {
    selectorContent = (
      <Select<SelectorOption>
        //items={serverOptions.length ? serverOptions : options}
        items={sortItemsByMatch(query, options, 5)}
        itemRenderer={renderSelect}
        onItemSelect={(item) => onItemSelect(item, true)}
        resetOnSelect={true}
        //onQueryChange={(query) => handleSetQuery(selector, query)}
        onQueryChange={setQuery}
        query={query}
      >
        {context === SelectorContext.WIDGET ? (
          <Button
            disabled={
              disabled || !enableEditableSelectors || !!error || options.length === 1 || loading
            }
            alignText={Alignment.LEFT}
            rightIcon={IconNames.DOUBLE_CARET_VERTICAL}
            style={{ minWidth: "150px" }} // Blueprintjs multiselect min width
            text={
              (options.length === 1 ? options[0]?.label : selector.selected_options?.[0]?.label) ||
              "Choose " + selector.id
            }
          />
        ) : (
          <Button
            disabled={
              disabled || !enableEditableSelectors || !!error || options.length === 1 || loading
            }
            alignText={Alignment.LEFT}
            rightIcon={IconNames.DOUBLE_CARET_VERTICAL}
            style={{ minWidth: "150px" }} // Blueprintjs multiselect min width
            text={
              (options.length === 1 ? options[0]?.label : selector.selected_options?.[0]?.label) ||
              "Choose " + selector.id
            }
          />
        )}
      </Select>
    );
  }

  if (isInvalid) {
    return (
      <Popover
        content={
          <StyledInvalidPopoverContent>
            <Flex flexDirection="column" gap={10} alignItems="start">
              <span>
                The VSL for this selector has been changed or removed.
                {`${setSelectorsDialogOpen ? " Please click below to resolve." : ""}`}
              </span>
              {setSelectorsDialogOpen ? (
                <Button
                  intent={Intent.PRIMARY}
                  onClick={() => setSelectorsDialogOpen(true)}
                  text="Edit Selectors"
                />
              ) : null}
            </Flex>
          </StyledInvalidPopoverContent>
        }
        enforceFocus={false}
        openOnTargetFocus={false}
        hoverOpenDelay={200}
        hoverCloseDelay={400}
        position={Position.BOTTOM}
        interactionKind="hover"
      >
        {context === SelectorContext.VIEW ? (
          <FormGroup className={Classes.TEXT_SMALL} label={selector.id} style={{ marginBottom: 0 }}>
            {selectorContent}
          </FormGroup>
        ) : (
          selectorContent
        )}
      </Popover>
    );
  }

  if (error) {
    return (
      <Tooltip
        content={error}
        enforceFocus={false}
        openOnTargetFocus={false}
        hoverOpenDelay={200}
        key={selector.id}
        position={Position.TOP}
      >
        {context === SelectorContext.VIEW ? (
          <FormGroup className={Classes.TEXT_SMALL} label={selector.id} style={{ marginBottom: 0 }}>
            {selectorContent}
          </FormGroup>
        ) : (
          selectorContent
        )}
      </Tooltip>
    );
  }

  if (context === SelectorContext.WIDGET) {
    return selector.multiselect ? (
      selectorContent
    ) : (
      <Tooltip
        content={selector.id}
        enforceFocus={false}
        openOnTargetFocus={false}
        hoverOpenDelay={200}
        key={selector.id}
        position={Position.TOP}
      >
        {selectorContent}
      </Tooltip>
    );
  } else {
    return (
      <FormGroup className={Classes.TEXT_SMALL} label={selector.id} style={{ marginBottom: 0 }}>
        {selectorContent}
      </FormGroup>
    );
  }
};

const StyledInvalidPopoverContent = styled.div`
  max-width: 250px;
  padding: 20px;

  > button {
    margin-top: 10px;
  }
`;

export default VSLSelector;
