import { useCallback, useState } from "react";
import useSWR, { KeyedMutator, mutate as globalMutate } from "swr";
import {
  COPY_WIDGET_ROUTE,
  EMethods,
  EStatus,
  GET_CONFIGS,
  WIDGET_ROUTE,
} from "constants/apiConstants";
import api, { swrApi } from "utils/api";
import { IResponse } from "types";
import { Widget } from "types/widget";
import { multiToaster } from "utils/toaster";
import { Intent } from "@blueprintjs/core";

type TUseWidget = (widgetId?: string) => {
  copyWidgetToView: (viewId: string) => Promise<IResponse<unknown>>;
  delete: () => Promise<boolean>;
  error?: boolean;
  loading: boolean;
  mutate: KeyedMutator<IResponse<Widget>>;
  save?: (nextWidget: Widget) => Promise<IResponse<unknown>>;
  saving?: boolean;
  setName?: (value: string) => void;
  setDescription?: (value: string) => void;
  update?: (details: Partial<Widget>, modified?: boolean) => Promise<boolean>;
  widget?: Widget;
};

const useWidget: TUseWidget = (widgetId) => {
  const [savingWidget, setSavingWidget] = useState(false);

  const {
    data: widget,
    isLoading,
    error,
    mutate,
  } = useSWR<IResponse<Widget>>(widgetId ? [WIDGET_ROUTE, { widgetId }] : null, swrApi);

  const saveWidget = useCallback(
    async (nextWidget: Widget) => {
      if (!widget?.data) return { data: null, status: EStatus.failed }; // To avoid ts error

      if (!nextWidget?.name) {
        multiToaster.show({
          message: "Please provide a name for the widget",
          intent: Intent.DANGER,
        });
        return { data: null, status: EStatus.failed };
      }

      const prevWidget = widget; // Store for failure case
      await mutate(
        {
          ...widget,
          status: EStatus.success,
          data: nextWidget,
        },
        false
      );

      setSavingWidget(true);

      const response = await api(WIDGET_ROUTE, {
        method: EMethods.PUT,
        widgetID: widget.data.id,
        body: nextWidget,
      });

      if (response?.status === EStatus.success) {
        await globalMutate(GET_CONFIGS);
        setSavingWidget(false);
      } else {
        multiToaster.show({ message: response.error || response.message, intent: Intent.WARNING });
        await mutate(prevWidget);
        setSavingWidget(false);
      }
      return response;
    },
    [mutate, widget]
  );

  /** Used to update a local widgets top level properties. */
  const updateWidget = useCallback(
    async (details: Partial<Widget>) => {
      if (!widget) return false;
      const nextWidget = {
        ...widget.data,
        ...details,
      };
      const response = await saveWidget(nextWidget);
      return response?.status === EStatus.success;
    },
    [saveWidget, widget]
  );

  const deleteWidget = async (): Promise<boolean> => {
    if (!widget?.data) return false;
    if (!confirm(`Permanently delete widget ${widget.data.name}?`)) return false;
    const response = await api(WIDGET_ROUTE, {
      method: EMethods.DELETE,
      widgetID: widget.data.id,
    });
    if (response.status === EStatus.success) {
      await globalMutate(GET_CONFIGS);
      return true;
    } else {
      multiToaster.show({ intent: Intent.WARNING, message: response.error });
      return false;
    }
  };

  function setName(value: string) {
    updateWidget({ name: value });
  }

  function setDescription(value: string) {
    updateWidget({ description: value });
  }

  const copyWidgetToView = useCallback(
    async (viewId: string) => {
      return await api(COPY_WIDGET_ROUTE, { method: EMethods.POST, viewId, widgetId });
    },
    [widgetId]
  );

  return {
    copyWidgetToView,
    delete: deleteWidget,
    error,
    loading: isLoading,
    mutate,
    save: saveWidget,
    saving: savingWidget,
    setDescription,
    setName,
    update: updateWidget,
    widget: widget?.data,
  };
};

export default useWidget;
