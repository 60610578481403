import {
  Button,
  Classes,
  DialogFooter,
  DialogProps,
  EditableText,
  FormGroup,
  H5,
  H6,
  Intent,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  DialogBodyWithHeight,
  Flex,
  FullWidthDialog,
  Grid,
  NormalAlignDivider,
} from "components/utility/StyledComponents";
import AppContext from "context/appContext";
import React, { useContext, useEffect, useState } from "react";
import VSLEditor from "components/dashboard/vsl/VSLEditor";
import { Widget as TWidget } from "types/widget";
import Widget from "./Widget";
import LoadingIndicator from "components/utility/LoadingIndicator";
import useView from "hooks/dashboard/useView";
import { Themes } from "constants/uiConstants";
import styled from "styled-components";
import { ViewWidget } from "types/view";

const StyledTitleEditableText = styled(EditableText)`
  max-width: 350px;
`;

interface EditWidgetDialogProps extends DialogProps {
  onClose: () => void;
  saving?: boolean;
  updateWidget?: (details: Partial<ViewWidget> & { id: string }) => void;
  viewId?: string;
  widget: TWidget;
}

const EditWidgetDialog: React.FC<EditWidgetDialogProps> = ({
  onClose,
  saving,
  updateWidget,
  viewId,
  widget,
  ...dialogProps
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const { mutate: mutateView } = useView(viewId);
  const [localWidget, setLocalWidget] = useState<TWidget>();
  const [localQuery, setLocalQuery] = useState("");

  const hasUnsavedQuery = localWidget && localWidget.query !== localQuery;

  const hasUnsavedChanges =
    localWidget?.name !== widget?.name ||
    localWidget?.description !== widget?.description ||
    localWidget?.query !== widget?.query;

  const confirmAndClose = () => {
    if (
      (!hasUnsavedChanges && !hasUnsavedQuery) ||
      confirm("you have unsaved changes, are you sure you want to continue?")
    ) {
      onClose();
    }
  };

  useEffect(() => {
    /**
     * We are storing a copy of the Widget in local state. This is because users may not want
     * to save a VSL query they are experimenting with.
     * */
    if (!localWidget) setLocalWidget(widget);
    if (!localQuery) setLocalQuery(widget?.query || "");
  }, [localQuery, localWidget, setLocalQuery, widget]);

  const runQuery = () => {
    if (localWidget) {
      setLocalWidget({ ...localWidget, query: localQuery });
    }
  };

  const saveWidget = async () => {
    if (!hasUnsavedChanges || !updateWidget || !localWidget) {
      onClose();
    } else if (
      !hasUnsavedQuery ||
      confirm(
        "You have made changes to the query and not run query, are you sure you want to continue?"
      )
    ) {
      await updateWidget(localWidget);
      mutateView();
      onClose();
    }
  };

  return (
    <FullWidthDialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      icon={IconNames.COG}
      onClose={confirmAndClose}
      title={`Edit Widget:  ${widget?.name}`}
      {...dialogProps}
    >
      <DialogBodyWithHeight useOverflowScrollContainer={true}>
        <Grid rows="auto auto 1fr">
          <Flex
            alignItems="flex-start"
            alignContent="start"
            flex="0 1 75%"
            flexDirection="column"
            justifyContent="space-between"
            style={{ minWidth: 0 }}
          >
            <FormGroup helperText="Name" style={{ marginBottom: "10px" }}>
              <H5 style={{ marginBottom: 5 }}>
                <Flex justifyContent="flex-start">
                  <StyledTitleEditableText
                    defaultValue={localWidget?.name || ""}
                    key={localWidget?.name}
                    placeholder="Enter a name (required)"
                    maxLength={80}
                    onConfirm={(value) =>
                      localWidget && setLocalWidget({ ...localWidget, name: value })
                    }
                  />
                </Flex>
              </H5>
            </FormGroup>
            <FormGroup helperText="Description" style={{ marginBottom: "10px" }}>
              <H6 style={{ marginBottom: 5 }}>
                <Flex flexWrap="wrap" justifyContent="flex-start">
                  <StyledTitleEditableText
                    value={localWidget?.description || ""}
                    placeholder="Enter a description (optional)"
                    maxLength={120}
                    onChange={(value) =>
                      localWidget && setLocalWidget({ ...localWidget, description: value })
                    }
                  />
                </Flex>
              </H6>
            </FormGroup>
          </Flex>
          <div style={{ flex: 1, marginBottom: 10 }}>
            <VSLEditor
              theme={theme}
              onChange={setLocalQuery}
              minHeight="100px"
              maxHeight="250px"
              value={localQuery || ""}
            />
            <Flex fullWidth justifyContent="flex-end">
              <Button
                icon={IconNames.REFRESH}
                onClick={runQuery}
                text="Run Query"
                style={{ marginTop: "10px" }}
                disabled={!hasUnsavedQuery}
              />
            </Flex>
            <NormalAlignDivider style={{ margin: "10px 0px" }} />
          </div>
          {localWidget ? (
            <div style={{ height: "400px" }}>
              <Widget
                enableTableColumnConfigEditing={false}
                enableEditWidgetDialog={false}
                enableTableSort={false}
                enableTitleMenu={false}
                savingWidget={saving}
                showControlRibbon={false}
                widget={localWidget}
              />
            </div>
          ) : (
            <LoadingIndicator status={{ intent: Intent.PRIMARY, message: "Loading Widget..." }} />
          )}
        </Grid>
      </DialogBodyWithHeight>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={confirmAndClose} text="Cancel" />
          <Button
            disabled={!localWidget || !hasUnsavedChanges}
            intent={Intent.PRIMARY}
            onClick={saveWidget}
            text="Update"
          />
        </div>
      </DialogFooter>
    </FullWidthDialog>
  );
};

export default EditWidgetDialog;
