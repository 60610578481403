import { EMethods, VSL_ROUTE } from "constants/apiConstants";
import { swrApi } from "utils/api";
import useSWR from "swr";
import { IVSLConfig, VSLResponse } from "types/vsl";
import { IResponse } from "types";

const useVSL = (config: IVSLConfig | undefined) => {
  const {
    data: response,
    error,
    isLoading,
    mutate,
  } = useSWR<IResponse<VSLResponse>>(
    config?.query ? [VSL_ROUTE, { body: config, method: EMethods.POST }] : null,
    swrApi,
    { revalidateOnFocus: false }
  );

  return {
    error: error || (response?.error ? new Error(response.error) : null),
    data: response?.data,
    loading: isLoading,
    mutate,
  };
};

export default useVSL;
