import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  Classes,
  Colors,
  ControlGroup,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogProps,
  Divider,
  FormGroup,
  H4,
  H6,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  NonIdealState,
  NonIdealStateIconSize,
} from "@blueprintjs/core";
import {
  Outlet,
  ParamParseKey,
  PathMatch,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { LibrarySidebar } from "../DashboardStyledComponents";
import { Flex, NormalAlignDivider } from "components/utility/StyledComponents";
import { IconNames } from "@blueprintjs/icons";
import { EStatus, EMethods, Status, WIDGET_ROUTE } from "constants/apiConstants";
import api from "utils/api";
import { Widget } from "types/widget";
import LoadingIndicator from "components/utility/LoadingIndicator";
import AppContext from "context/appContext";
import { Themes } from "constants/uiConstants";
import { multiToaster } from "utils/toaster";
import { MainContainer } from "components/utility/StyledDashboardComponents";
import useListConfigs from "hooks/useListConfigs";

type OutletContext = {
  handleAdd: () => void;
  widgetRouteMatch: PathMatch<ParamParseKey<"/settings/widgets/:widgetId">> | null;
};

const WidgetLibrary: React.FC = () => {
  const [addWidgetDialogOpen, setAddWidgetDialogOpen] = useState<boolean>(false);
  const [searchWidgetsPredicate, setSearchWidgetsPredicate] = useState("");
  const { widgetId } = useParams();
  const navigate = useNavigate();
  const {
    config: { theme },
  } = useContext(AppContext);

  const { error, isLoading: loadingConfigurations, mutateConfigs, widgets } = useListConfigs();

  const sortedWidgets = widgets
    ? [...widgets].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    : undefined;

  const filteredWidgets = sortedWidgets
    ? sortedWidgets.filter((widget) =>
        widget.name.toLowerCase().includes(searchWidgetsPredicate.toLowerCase())
      )
    : undefined;

  let listContent = null;

  if (error) {
    listContent = (
      <NonIdealState
        action={
          <Button icon={IconNames.REFRESH} text="Reload..." onClick={() => mutateConfigs()} />
        }
        description={error.message}
        icon={IconNames.WARNING_SIGN}
        iconSize={NonIdealStateIconSize.SMALL}
        title="Unable to load Widgets!"
      />
    );
  } else {
    if (loadingConfigurations) {
      listContent = <LoadingIndicator status={{ message: "Loading Widgets..." }} />;
    } else {
      if (widgets && filteredWidgets) {
        listContent = (
          <Card
            style={{
              borderRadius: 4,
              flex: "1 1 1px",
              overflow: "hidden",
              padding: 1,
              width: "100%",
            }}
          >
            <div style={{ height: "100%", overflow: "auto", width: "100%" }}>
              <Menu role="list">
                {filteredWidgets.map((widget) => (
                  <MenuItem
                    active={widget.id === widgetId}
                    onClick={() => navigate(`/settings/widgets/${widget.id}`)}
                    key={widget.id}
                    style={{ width: "100%" }}
                    role="listitem"
                    text={
                      <Flex
                        alignItems="flex-start"
                        flexDirection="column"
                        fullWidth
                        style={{ textOverflow: "ellipsis", whiteSpace: "break-spaces" }}
                      >
                        <H6>{widget.name}</H6>
                        {widget.description ? (
                          <p
                            style={{
                              color: theme === Themes.DARK ? Colors.WHITE : Colors.BLACK,
                              marginBottom: 0,
                            }}
                          >
                            {widget.description}
                          </p>
                        ) : (
                          <p className={Classes.TEXT_MUTED} style={{ marginBottom: 0 }}>
                            No description
                          </p>
                        )}
                        <p className={Classes.TEXT_SMALL} style={{ marginBottom: 0 }}>
                          <span className={Classes.TEXT_MUTED}>Created:</span>{" "}
                          {widget.createdAt.toLocaleString()}
                        </p>
                      </Flex>
                    }
                  />
                ))}
              </Menu>
            </div>
          </Card>
        );
      } else {
        listContent = (
          <NonIdealState
            icon={IconNames.SEARCH}
            description="No Widgets are available to your user. Widgets that you create will appear here."
            iconSize={NonIdealStateIconSize.SMALL}
            title="No Widgets available!"
          />
        );
      }
    }
  }

  return (
    <MainContainer>
      <CreateWidgetDialog
        isOpen={addWidgetDialogOpen}
        onClose={() => setAddWidgetDialogOpen(false)}
      />
      <Flex alignItems="flex-start" flexDirection="row" fullHeight justifyContent="flex-start">
        <LibrarySidebar
          alignItems="flex-start"
          flex="1 auto"
          fullHeight
          justifyContent="flex-start"
        >
          <Flex
            alignItems="flex-start"
            flexDirection="column"
            fullHeight
            justifyContent="flex-start"
            style={{ maxWidth: "100%" }}
          >
            <Flex flex="0" fullWidth justifyContent="space-between">
              <H4 className={Classes.TEXT_MUTED} style={{ flex: "1 0 auto" }}>
                Widget Library
              </H4>
              <Button
                icon={IconNames.ADD}
                intent={Intent.SUCCESS}
                onClick={() => setAddWidgetDialogOpen(true)}
                small
                text="New"
              />
            </Flex>
            <Divider />
            <div style={{ width: "100%", marginBottom: "10px" }}>
              <ControlGroup>
                <InputGroup
                  fill
                  leftIcon={IconNames.SEARCH}
                  onChange={(e) => setSearchWidgetsPredicate(e.currentTarget.value)}
                  placeholder="Search widgets..."
                  type="search"
                  value={searchWidgetsPredicate}
                />
              </ControlGroup>
            </div>
            {listContent}
          </Flex>
        </LibrarySidebar>
        <NormalAlignDivider style={{ margin: "0 15px" }} />
        <Outlet context={{ handleAdd: () => setAddWidgetDialogOpen(true) }} />
      </Flex>
    </MainContainer>
  );
};

export const WidgetLibraryHome: React.FC = () => {
  const { handleAdd } = useOutletContext<OutletContext>();

  return (
    <NonIdealState
      title="Widget Library"
      description="Select a widget from the menu on the left, or create a new one."
      action={
        <Button
          icon={IconNames.ADD}
          intent={Intent.SUCCESS}
          onClick={handleAdd}
          text="New widget"
        />
      }
    />
  );
};

const CreateWidgetDialog: React.FC<DialogProps & { onClose: () => void }> = ({
  onClose,
  ...dialogProps
}) => {
  const navigate = useNavigate();
  const {
    config: { theme },
  } = useContext(AppContext);

  const { configs, widgets, mutateConfigs } = useListConfigs();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const createWidget = async () => {
    const response = await api<Widget>(WIDGET_ROUTE, {
      body: {
        description,
        layout: {
          x: 0,
          y: 0,
          w: 4,
          h: 8,
          maxW: 12,
          minH: 5,
        },
        name,
      },
      method: EMethods.POST,
    });
    if (response.status === Status.SUCCESS) {
      const nextWidgets = widgets ? [...widgets, response.data] : [response.data];
      mutateConfigs({
        ...configs,
        status: EStatus.success,
        data: {
          ...configs?.data,
          widgets: nextWidgets.map((widget) => ({
            ...widget,
            createdAt: JSON.stringify(widget.createdAt),
          })),
        },
      });
      navigate(`/settings/widgets/${response.data.id}`);
      setName("");
      setDescription("");
      onClose();
    } else {
      multiToaster.show({ intent: Intent.WARNING, message: response.error || response.message });
    }
  };
  return (
    <Dialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      icon={IconNames.ADD}
      title="Add Widget"
      onClose={onClose}
      {...dialogProps}
    >
      <DialogBody>
        <FormGroup helperText="(Required)" label="Name">
          <InputGroup
            value={name}
            maxLength={70}
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </FormGroup>
        <FormGroup helperText="(Optional)" label="Description">
          <InputGroup
            maxLength={120}
            onChange={(e) => setDescription(e.currentTarget.value)}
            value={description}
            type="textarea"
          />
        </FormGroup>
      </DialogBody>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose} text="Cancel" />
          <Button disabled={!name} intent={Intent.PRIMARY} onClick={createWidget} text="Create" />
        </div>
      </DialogFooter>
    </Dialog>
  );
};

export default WidgetLibrary;
