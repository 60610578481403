import styled from "styled-components/macro";
import { Flex, Grid } from "components/utility/StyledComponents";
import List from "components/utility/List";

export const LibraryWrapper = styled(Grid)`
  height: 100%;
`;

export const LibrarySidebar = styled(Flex)`
  max-width: 300px;
  min-height: 0;
  min-width: 220px;
`;

export const LibrarySidebarHeader = styled(Flex)`
  gap: 12px;
  padding: 12px 12px 8px 12px;
`;

export const LibrarySidebarTitle = styled.div`
  font-size: 1.5em;
  margin-bottom: 2px;
`;

export const LibraryList = styled(List)`
  flex: 1 1 auto;
  min-height: 0;
`;

export const LibraryLoadingOrError = styled.div`
  padding: 12px;
`;

export const SelectorsContainer = styled(Flex)`
  gap: 5px;
`;
