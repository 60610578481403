import React, { useState } from "react";
import { SketchPicker } from "react-color";

interface ColorPickerProps {
  color?: string;
  colors: string[];
  onChange: (hex: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, colors, onChange }) => {
  const [localColor, setLocalColor] = useState(color);
  return (
    <SketchPicker
      color={localColor}
      presetColors={colors}
      onChange={(colorObj) => setLocalColor(colorObj.hex)}
      onChangeComplete={(colorObj) => onChange(colorObj.hex)}
      disableAlpha={true}
    />
  );
};

export default ColorPicker;
