import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import styled from "styled-components/macro";
import ConfigureModelContext, { withConfigureModelContext } from "context/configureModelContext";
import { Classes, Panel, PanelStack2 as PanelStack } from "@blueprintjs/core";

import { createModel, flushCreate } from "actions/createModelActions";
import SelectModelTemplate from "components/new/SelectModelTemplate";
import { IModelConfiguration, TCompanyInfo } from "types/createModel";
import { Flex, VSPage } from "components/utility/StyledComponents";
import { IResponse } from "types";
import useSetPageTitle from "hooks/useSetPageTitle";
import { FETCH_COMPANY_CONFIG_OPTIONS_ROUTE } from "constants/apiConstants";
import { swrApi } from "utils/api";
import { OutletContext as IncomingContext } from "ValsysApp";
import { DEFAULT_MODEL_TAB } from "constants/uiConstants";

const StyledPanelStack = styled(PanelStack)`
  display: flex;
  height: 100%;
  min-height: 460px;
  max-height: 750px;
  width: 600px;
`;

interface ICreateModelProps {
  building: {
    buildSucceeded: boolean;
    buildFailed: boolean;
    isBuilding: boolean;
    modelID: string;
    modelName: string;
    progress: number;
    step: string;
    steps: string[];
  };
}

const SelectTemplateWithContext = withConfigureModelContext(SelectModelTemplate);

const CreateModel: React.FC<ICreateModelProps> = ({ building }) => {
  const { appSize } = useOutletContext<IncomingContext>();
  useSetPageTitle("Create Model");
  const [config, setConfig] = useState<IModelConfiguration>({
    blank: false,
    companyName: "",
    companyType: "Public",
    currency: { ISO: "USD", name: "United States Dollar" },
    periodType: "Annual",
    numHistoricalYears: 1,
    numForecastYears: 1,
    startDate: new Date(),
    startPeriod: new Date().getFullYear() + 0.75,
    variables: [],
  });

  const dispatch = useDispatch();
  // Local state to hold the selected companies fo reach type, 't have these in the component as they will be reset on
  const [company, setCompany] = useState<TCompanyInfo>();
  const [blankCompany, setBlankCompany] = useState<Partial<TCompanyInfo>>({
    companyName: "",
    ticker: "",
  });

  const { data: companyConfigOpts, error: configOptsError } = useSWR<
    IResponse<{
      cik: string;
      companyName: string;
      currency: string;
      geography: string;
      industry: string;
      newestReportedYear: number;
      oldestReportedYear: number;
      periodType: "Annual" | "Quarterly";
      ticker: string;
    }>
  >(
    !config.blank && config?.template && company?.ticker
      ? [
          FETCH_COMPANY_CONFIG_OPTIONS_ROUTE,
          {
            templateID: config.template.uid,
            ticker: company.ticker,
          },
        ]
      : null,
    swrApi
  );

  const loadingConfigOpts = !companyConfigOpts && !configOptsError;

  useEffect(() => {
    if (companyConfigOpts?.data) {
      setConfig((conf) => ({
        ...conf,
        ...(companyConfigOpts && {
          ...companyConfigOpts.data,
          startPeriod: companyConfigOpts.data?.newestReportedYear,
          currency: companyConfigOpts.data.currency
            ? { ISO: companyConfigOpts.data.currency, name: "" }
            : { ISO: "USD", name: "United States Dollar" },
        }),
      }));
    }
  }, [companyConfigOpts]);

  const navigate = useNavigate();

  // Change the page title.
  const onConfigure = () => {
    dispatch(createModel(config));
  };

  useEffect(() => {
    if (building?.buildSucceeded) {
      navigate(`/model/uid=${building.modelID}/${DEFAULT_MODEL_TAB}`);
      dispatch(flushCreate());
    }
  }, [dispatch, building, navigate]);

  return (
    <VSPage
      $height={appSize?.height ? appSize.height - 66 : undefined}
      $width={appSize?.width ? appSize.width - 40 : undefined}
    >
      <ConfigureModelContext.Provider
        value={{
          blankCompany,
          config,
          company,
          onConfigure,
          configuring: false,
          loadingConfigOpts,
          setBlankCompany,
          setCompany,
          setConfig,
        }}
      >
        <Flex alignItems="flex-start" justifyContent="center" fullHeight>
          <StyledPanelStack
            className={Classes.ELEVATION_0}
            initialPanel={
              {
                renderPanel: SelectTemplateWithContext,
                props: { enabled: true, panelNumber: 1 },
                title: "Select Model Template",
              } as Panel<object>
            }
            showPanelHeader={!building.isBuilding}
          />
        </Flex>
      </ConfigureModelContext.Provider>
    </VSPage>
  );
};

// TODO: type state and remove the any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => {
  return {
    building: state.create.metaData.building,
  };
};

export default connect(mapStateToProps)(CreateModel);
