import React, { useContext } from "react";
import {
  Alignment,
  Button,
  Classes,
  Colors,
  Divider,
  Intent,
  Menu,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  PopoverPosition,
  Position,
  Tooltip,
} from "@blueprintjs/core";
import { IconName, IconNames } from "@blueprintjs/icons";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { TTheme } from "types";
import { styledScrollBar } from "./StyledComponents";

interface IStyledSideBarTooltipProps {
  $collapsed: boolean;
}

const StyledMenuButtonTooltip = styled(Tooltip)<IStyledSideBarTooltipProps>`
  ${({ $collapsed }) =>
    $collapsed &&
    css`
      height: 34px;
      width: 30px;
    `}
  width: ${({ $collapsed }) => ($collapsed ? "30px" : "100%")};
  & > span {
    height: 100%;
    width: 100%;
  }
`;

interface IStyledMenuProps {
  $collapsed: boolean;
  $theme: TTheme;
}

const StyledMenu = styled(Menu)<IStyledMenuProps>`
  &&& {
    padding: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: ${({ $collapsed, $theme }) =>
      $collapsed ? ($theme === Themes.DARK ? Colors.DARK_GRAY3 : Colors.WHITE) : Colors.DARK_GRAY4};
  }
`;

interface IStyledMenuButtonProps {
  $collapsed: boolean;
  $location: string;
  $itemRoute: string;
  $openMenuItem: boolean;
  $disabled: boolean;
}

export const StyledMenuButton = styled(Button)<IStyledMenuButtonProps>`
  &&& {
    padding: 9px 7px;
    margin-top: ${({ $collapsed }) => ($collapsed ? "0px" : "15px")};
    border-left: ${({ $itemRoute, $location }) =>
      $itemRoute === $location ? `2px solid ${Colors.WHITE}` : "2px solid transparent"};

    &:hover {
      color: ${Colors.WHITE};
      background: ${Colors.DARK_GRAY4};
    }

    &:hover {
      border-left: 2px solid ${Colors.WHITE};
    }

    svg {
      fill: ${Colors.WHITE};
    }

    ${({ $disabled }) =>
      !$disabled &&
      css`
        color: ${Colors.WHITE};
      `}

    ${({ $collapsed, $openMenuItem }) =>
      !$collapsed &&
      $openMenuItem &&
      css`
        background-color: ${Colors.DARK_GRAY4};
        border-left: 2px solid ${Colors.WHITE};
      `}
  }
`;

interface IStyledMenuItemProps {
  $showBorderLeft: boolean;
  $collapsed: boolean;
}

const SyledMenuItem = styled(MenuItem)<IStyledMenuItemProps>`
  &&& {
    ${({ $collapsed }) =>
      !$collapsed &&
      css`
        color: ${Colors.WHITE};
      `}
    padding: 7px 8px;
    font-size: 12px;
    border-left: ${({ $showBorderLeft }) =>
      $showBorderLeft ? `2px solid ${Colors.GREEN3}` : `2px solid transparent`};
  }

  // allow for multi-line text items
  :nth-child(1) .${Classes.TEXT_OVERFLOW_ELLIPSIS} {
    white-space: normal;
  }
`;

const StyledScrollBar = styled.div`
  padding: 5px;
  max-height: 50vh;
  ${styledScrollBar}
  overflow-x: hidden;
`;

export interface MenuButtonItemProps {
  icon?: IconName;
  intent?: Intent;
  text: string;
  to: string;
  disabled?: boolean;
  uid: string;
}

export interface IMenuButtonProps {
  collapsed: boolean;
  disabled?: boolean;
  icon: IconName | undefined;
  loading?: boolean;
  menuItems?: MenuButtonItemProps[];
  handleSelectedMenu: (id: string) => void;
  onClick?:
    | (((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) &
        React.MouseEventHandler<HTMLButtonElement>)
    | undefined;
  selected: boolean;
  text: string;
  to?: string;
  style?: React.CSSProperties | undefined;
}
/**
 * Menu item is used for the navigation menu buttons and supports menu items (sub menus) both for collapsed and non-collapsed side bar
 */
export const MenuButton: React.FC<IMenuButtonProps> = ({
  collapsed,
  disabled = false,
  icon,
  loading = false,
  selected = false,
  menuItems,
  handleSelectedMenu,
  onClick,
  text,
  to,
  style,
}) => {
  const { dashboardId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    config: { theme },
  } = useContext(AppContext);

  const handleOnClick = (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    handleSelectedMenu(selected ? "" : text);
    onClick && onClick(event);
  };

  const menuButton = (
    <StyledMenuButton
      icon={icon}
      loading={loading}
      text={collapsed ? null : text}
      disabled={disabled}
      onClick={
        menuItems && !collapsed
          ? () => handleSelectedMenu(selected ? "" : text)
          : (event) => handleOnClick(event)
      }
      $disabled={disabled}
      $openMenuItem={selected}
      $collapsed={collapsed}
      $location={location.pathname}
      $itemRoute={to || ""}
      fill
      minimal
      alignText={Alignment.LEFT}
      rightIcon={
        !collapsed &&
        (menuItems && selected ? IconNames.CHEVRON_DOWN : menuItems && IconNames.CHEVRON_RIGHT)
      }
      style={style}
    />
  );

  const items = menuItems && (selected || collapsed) && (
    <StyledMenu $theme={theme} $collapsed={collapsed}>
      {menuItems.map((item) => {
        const showBorderLeft = location.pathname === item.to || dashboardId === item.uid;
        return (
          <div key={item.uid}>
            <SyledMenuItem
              key={item.uid}
              intent={item.intent}
              icon={item.icon ? item.icon : null}
              $collapsed={collapsed}
              onClick={() => navigate(item.to)}
              $showBorderLeft={showBorderLeft}
              text={item.text}
              disabled={item.disabled}
            />
            {item.intent === Intent.PRIMARY && <Divider />}
          </div>
        );
      })}
    </StyledMenu>
  );

  return (
    <div style={collapsed ? { marginTop: 15 } : {}}>
      {collapsed && items ? (
        <Popover
          fill
          interactionKind={PopoverInteractionKind.HOVER}
          placement={PopoverPosition.RIGHT}
          shouldReturnFocusOnClose={false}
          content={<StyledScrollBar $theme={theme}>{items}</StyledScrollBar>}
        >
          {to ? <Link to={to}>{menuButton}</Link> : menuButton}
        </Popover>
      ) : (
        <StyledMenuButtonTooltip
          $collapsed={collapsed}
          disabled={!collapsed}
          content={text}
          position={Position.RIGHT}
          openOnTargetFocus={false}
        >
          <>
            {to ? <Link to={to}>{menuButton}</Link> : menuButton} {items}
          </>
        </StyledMenuButtonTooltip>
      )}
    </div>
  );
};
