import { getValidJSON } from "./api";
import { IOutputsData, ISimulation, TRawOutputsData } from "types/scenarioAnalytics";
import { IRawFact } from "types/model";
import colors from "styles/colors.module.scss";
import { BaseFormatObject, IFormatObject } from "types/format";
import { TableValue } from "components/table/Table";
import { MISSING_VALUE } from "constants/appConstants";

/**
 * Converts a period to the LFY format
 */
export function periodToLFY(period: number, startPeriod: number) {
  const diff = period - startPeriod;
  if (diff > 0) return "LFY+" + diff;
  else if (diff < 0) return "LFY" + diff;
  else return "LFY";
}
/**
 * Validates if a certain period is valid in a groups range of historics and forecasts
 */
export function isValidGroupPeriod(
  period: number,
  startPeriod: number,
  historicLength: number,
  forecastLength: number
) {
  const relativeIdx = startPeriod - period;
  if (relativeIdx > 0) {
    return relativeIdx <= historicLength;
  } else {
    return Math.abs(relativeIdx) <= forecastLength;
  }
}

export const mergeOutputsData = (
  outputsData: IOutputsData<IRawFact<IFormatObject, string>>[],
  nextOutputsData: IOutputsData<IRawFact<IFormatObject, string>>[]
): IOutputsData<IRawFact<IFormatObject, string>>[] => {
  const nextData = nextOutputsData?.map((model) => {
    const mergeModel = outputsData.find((mergeMod) => mergeMod.id === model.id);
    if (model.lineItems && mergeModel) {
      return {
        ...model,
        lineItems: mergeModel.lineItems
          ? model.lineItems.concat(mergeModel?.lineItems)
          : model.lineItems,
      };
    }
    return model;
  });
  return nextData;
};

/** Utility to convert any absolute periods ('2017') to relative periods ('LFY-2')  */
export const processOutputsData = (
  outputsData?: TRawOutputsData[]
): IOutputsData<IRawFact<IFormatObject, string>>[] => {
  if (!outputsData) return [];
  const dt = outputsData.map((model) => {
    return {
      ...model,
      ...(model.lineItems && {
        lineItems: model.lineItems.map((lineItem) => ({
          ...lineItem,
          facts: lineItem?.edges.facts.map((fact) => {
            return {
              ...fact,
              format: getValidJSON<IFormatObject>(fact.format)
                ? { ...BaseFormatObject, ...getValidJSON<IFormatObject>(fact.format) }
                : (BaseFormatObject as IFormatObject),
              period: periodToLFY(fact.period, model.startPeriod),
            };
          }),
        })),
      }),
    };
  });
  return dt as unknown as IOutputsData<IRawFact<IFormatObject, string>>[];
};

/**
 * Utility to calculate the diff between a original line item and a simulated one.
 */
export const calculateSimulationDifference = (
  originalFacts: IRawFact<IFormatObject, string>[],
  simulatedFacts: IRawFact<IFormatObject, string>[]
): IRawFact<IFormatObject, string>[] => {
  const facts = originalFacts.map((fact) => {
    const originalValue = Number(fact.value);
    const simFact = simulatedFacts.find((sFact) => sFact.id === fact.id);
    const simulatedValue = Number(simFact?.value);
    if (!Number.isNaN(originalValue) && !Number.isNaN(simulatedValue)) {
      const diff = simulatedValue - originalValue;
      return {
        ...fact,
        format: {
          ...fact.format,
          color: diff > 0 ? colors.intentSuccess : diff < 0 ? colors.intentDanger : undefined,
        },
        value: diff.toString(),
      };
    }
    return fact;
  });
  return facts;
};

/** Utility to remove an output item from an IOutputsData[]or an ISimulation[]  */
export const removeSimDataOutputItem = (
  data: ISimulation[] | IOutputsData<IRawFact<IFormatObject, string>>[],
  item: string
): ISimulation[] | IOutputsData<IRawFact<IFormatObject, string>>[] => {
  const nextData = data?.map((model) => {
    const { lineItems: items } = model;
    // TODO: fix union type call
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const nextLis = items ? items.filter((li) => !li?.tags?.includes(item)) : undefined;
    return {
      ...model,
      lineItems: nextLis,
    };
  });
  return nextData;
};

/**
 * Utility to build a row for the SimulationOutputsTable. Handles
 * assigning the model id depending on which key is present.
 *
 * */
export function buildSimulationOutputRow(entry: {
  id: string;
  [key: string]: TableValue | undefined;
}) {
  const res: { [key: string]: TableValue<string>; id: string } = { id: "" };
  for (const propKey in entry) {
    const prop = entry[propKey];
    if (!prop) res[propKey] = "";
    else {
      res[propKey] = prop;
    }
  }
  if (entry?.id) res.modelID = entry.id;
  if (entry?.modelId) res.modelID = entry.modelId;

  return res;
}

/**
 * Utility to build a CSV string from a processed simulation outputs response.
 * Handles a simulation or an outputsData object if appropriate.
 * */
export const buildOutputsDataCSV = (
  availablePeriods: string[],
  outputsDataOrSimulation: IOutputsData<IRawFact<IFormatObject, string>>[],
  simulation?: ISimulation[],
  prependEncoding = true
): string => {
  let csvContent = prependEncoding ? "data:text/csv;charset=utf-8," : "";
  // Build the headers based on the availablePeriods. Two blank cells at the start.
  csvContent += ",,," + availablePeriods.join(",") + "\n";
  if (simulation) {
    simulation.forEach((company) => {
      csvContent += `${company.ticker},`;
      company.lineItems?.forEach((lineItem, index) => {
        if (index > 0) csvContent += ",";
        csvContent += `"${lineItem.name}",`;
        csvContent += "Original,";
        availablePeriods.forEach((period) => {
          const fact = lineItem.before.find((f) => f.period === period);
          csvContent += `${fact?.value || MISSING_VALUE},`;
        });
        csvContent += "\n,,Simulated,";
        availablePeriods.forEach((period) => {
          const fact = lineItem.after.find((f) => f.period === period);
          csvContent += `${fact?.value || MISSING_VALUE},`;
        });
        csvContent += "\n,,Difference,";
        availablePeriods.forEach((period) => {
          const fact = lineItem.difference.find((f) => f.period === period);
          csvContent += `${fact?.value || MISSING_VALUE},`;
        });
        csvContent += "\n";
      });
    });
  } else {
    outputsDataOrSimulation.forEach((company) => {
      csvContent += `${company.ticker},`;
      company.lineItems?.forEach((lineItem, index) => {
        if (index > 0) csvContent += ",";
        csvContent += `"${lineItem.name}",`;
        availablePeriods.forEach((period) => {
          const fact = lineItem.facts.find((f) => f.period === period);
          csvContent += `${fact?.value || MISSING_VALUE},`;
        });
        csvContent += "\n";
      });
    });
  }
  return csvContent;
};
