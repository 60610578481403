import React from "react";
import { Layout as ILayout } from "react-grid-layout";
import { WidgetKeys } from "constants/widgetKeys";
import { ColumnDef } from "@tanstack/react-table";
import { View } from "./view";

/**
 * New dashboard type. A Dashboard is a collection of Views,
 */
export interface Dashboard {
  contextId: string;
  createdAt: string;
  createdBy: string;
  description: string;
  id: string;
  name: string;
  views?: View[];
  visibility: string;
}

export enum EDashboardType {
  Any = "Any",
  Custom = "Custom",
  Edit = "Edit",
  Import = "Import",
  View = "View",
}

export enum EWidgetType {
  Table = "Table",
  Chart = "Chart",
  VS = "VS",
}

export interface IVSWidget {
  displayName: string;
  fetchesOwnData?: boolean;
  fullHeight?: boolean;
  baseWidget: IGenericWidget;
  gridKey: string; // The key of the widget. Should be unique.
  // CSS styles to be applied to the widget's container
  layout: ILayout;
  // DATA: IVSSchema;
  type: EWidgetType;
  widgetContainerStyles?: { [key: string]: string | number };
}

export interface IVSDashboard {
  name: string; // Name and key, must be unique.
  disabled?: boolean; // Whether the tab is available.
  widgets: Array<IVSWidget>;
  isDefault?: boolean; // Whether the tab is a default tab.
  type: EDashboardType;
}

export type TWidgetKey = keyof typeof WidgetKeys;

export interface IGenericWidget {
  component: React.ReactNode;
  displayName: string;
  fetchesOwnData?: boolean;
  widgetKey: TWidgetKey;
  dashboardType: EDashboardType;
  type: EWidgetType;
}

export type TVSTableData = ((string | number)[] | null)[];

export type TVSTableDataColumn = ColumnDef<{ [key: string]: string }>;
