import React from "react";
import VSHTMLTable from "components/dashboard/VSHTMLTable";
import { cppTableWidths } from "utils/cpp";
import "styles/sensitivity-analysis.scss";
import useSWR from "swr";
import { IResponse } from "types";
import { GET_MODEL_MULTIPLES_TABLE, Status } from "constants/apiConstants";
import { swrApi } from "utils/api";
import { TVSTableData } from "types/dashboard";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import { useParams } from "react-router-dom";

const CPP_Multiples = () => {
  const params = useParams();
  const modelID = params?.model?.substring(4);
  const { data, error, isLoading } = useSWR<IResponse<TVSTableData>>(
    modelID ? [GET_MODEL_MULTIPLES_TABLE, { modelID }] : null,
    swrApi
  );

  if (isLoading) return <Spinner />;
  if (error || data?.status === Status.FAILURE)
    return <NonIdealState title="Couldn't load model multiples" />;
  if (!data?.data) return <NonIdealState title="No multiples data available" />;
  return <VSHTMLTable data={data.data} widths={cppTableWidths} />;
};

export default CPP_Multiples;
