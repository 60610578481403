import React, { useContext } from "react";
import { Button, Intent, Menu, MenuItem, Text } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import styled from "styled-components";
import { styledScrollBar } from "components/utility/StyledComponents";
import AppContext from "context/appContext";

const StyledScrollContainer = styled.div`
  overflow: auto;
  ${styledScrollBar};
`;

interface ISelectedCompaniesTable {
  prefixId: string;
  watchlistSelection: { ticker: string; companyName: string }[];
  removeTicker?: (ticker: string) => void;
}

export const SelectedCompaniesTable: React.FC<ISelectedCompaniesTable> = ({
  prefixId,
  watchlistSelection,
  removeTicker,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);

  const noCompaniesAdded = watchlistSelection.length === 0;

  const handleRemoveTicker = (ticker: string) => {
    removeTicker ? removeTicker(ticker) : null;
  };

  return noCompaniesAdded ? (
    <Text style={{ padding: "5px 0 5px 0" }}>Add more companies to the right</Text>
  ) : (
    <StyledScrollContainer $theme={theme}>
      <Menu>
        {watchlistSelection.map((item) => (
          <MenuItem
            style={{ alignItems: "center" }}
            role="listitem"
            labelElement={
              <Button
                icon={IconNames.REMOVE}
                minimal
                intent={Intent.DANGER}
                onClick={() => handleRemoveTicker(item.ticker)}
              />
            }
            key={`${prefixId + item.ticker}`}
            tagName="div"
            text={item.ticker}
          />
        ))}
      </Menu>
    </StyledScrollContainer>
  );
};
