import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import GridLayout from "react-grid-layout";
import { IResponse, ISize, TTheme } from "types";
import {
  Callout,
  Card,
  Classes,
  Code,
  Colors,
  Icon,
  Intent,
  NonIdealState,
  Spinner,
  SpinnerSize,
} from "@blueprintjs/core";
import { useParams } from "react-router-dom";
import { Flex, styledScrollBar } from "components/utility/StyledComponents";
import useVSLSelector from "hooks/dashboard/useVSLSelector";
import { IconNames } from "@blueprintjs/icons";
import { Selector, SelectorContext, SelectorOption, SelectorTypes } from "types/vsl";
import isFunction from "lodash.isfunction";
import { SizeMe } from "react-sizeme";
import Widget from "components/dashboard/widget/Widget";
import useView from "hooks/dashboard/useView";
import AppContext from "context/appContext";
import { GridMargin, Themes } from "constants/uiConstants";
import styled from "styled-components";
import { Widget as TWidget } from "types/widget";
import EditWidgetSelectorsDialog from "components/dashboard/libraries/EditWidgetSelectorsDialog";
import { parseVSLQuerySelectors } from "utils/vsl";
import { ViewWidget } from "types/view";
import usePrevious from "hooks/usePrevious";
import { sanitiseViewSelectorsOptions } from "utils/selectors";
import { SelectorsGroup } from "components/dashboard/SelectorsGroup";
import useSWRImmutable from "swr/immutable";
import { FETCH_SELECTOR_TYPES } from "constants/apiConstants";
import { swrApi } from "utils/api";

interface ViewProps {
  editSelectorsDialogOpen?: boolean;
  hideSelectors?: boolean;
  layoutEditable?: boolean;
  readonly?: boolean;
  setEditSelectorsDialogOpen?: Dispatch<SetStateAction<boolean>>;
  size?: ISize;
  viewId: string;
}

const View: React.FC<ViewProps> = ({
  hideSelectors,
  layoutEditable = false,
  readonly = false,
  editSelectorsDialogOpen = false,
  setEditSelectorsDialogOpen,
  viewId,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const { dashboardId } = useParams();

  const {
    error,
    loading,
    removeWidgetFromView,
    updateLayout,
    updateView,
    updateViewWidget,
    updateViewWidgets,
    view,
    widgets,
  } = useView(viewId);

  const previousWidgets = usePrevious(widgets);
  const previousTheme = usePrevious(theme);
  const prevRemoveWidgetFromView = usePrevious(removeWidgetFromView);
  const prevUpdateViewWidget = usePrevious(updateViewWidget);
  const prevViewId = usePrevious(view?.id);
  const prevViewSelectors = usePrevious(view?.selectors);
  const previousLayoutEditable = usePrevious(layoutEditable);
  const [gridWidgets, setGridWidgets] = useState<JSX.Element[]>();

  /** fetch selectortypes to pass to SelectorsGroup */
  const {
    data: selectorTypes,
    error: selectorTypesError,
    isLoading: loadingSelectorTypes,
  } = useSWRImmutable<IResponse<SelectorTypes>>([FETCH_SELECTOR_TYPES], swrApi);

  /**
   *  Returns an Element with a Widget wrapped in a div with the necessary GridLayout props
   *  This can't be a component (and leverage React.memo) because RDL doesn't support layout in children with custom component
   *  */
  const genGridWidget = useCallback(
    (
      widget: ViewWidget,
      layoutEditable,
      removeWidgetFromView,
      updateViewWidget,
      theme,
      viewId: string,
      viewSelectors?: Selector[]
    ) => {
      const injectSelectors: Record<string, SelectorOption[]> = {
        ...widget.selectors?.reduce((prev, curr) => {
          const viewSelector = viewSelectors?.find((sel) => sel.id === curr.id);
          if (!viewSelector) return prev;
          return {
            ...prev,
            [curr.id]: viewSelector?.selected_options ?? [],
          };
        }, {}),
      };

      return (
        <div
          data-grid={widget.layout}
          key={widget.id}
          style={{
            padding: 0,
            zIndex: 1,
          }}
        >
          <Widget
            injectedSelectors={injectSelectors}
            locked={layoutEditable}
            onRemove={removeWidgetFromView}
            updateWidget={
              isFunction(updateViewWidget)
                ? async (details: Partial<TWidget>) => {
                    // we add the id because the useView method needs it to retrieve the right widget
                    return updateViewWidget({ ...details, id: widget.id });
                  }
                : undefined
            }
            viewId={viewId}
            widget={widget}
          />
          {layoutEditable && (
            <StyledDragOverlay className={Classes.OVERLAY_BACKDROP} $theme={theme}>
              <StyledDragIcon icon={IconNames.MOVE} size={40} $theme={theme} />
            </StyledDragOverlay>
          )}
        </div>
      );
    },
    []
  );

  /**
   * Compute a map of memoized grid widgets. Recomputes a gridWidget when the widget changes.
   * If layoutEditable or theme change we need to recompute all gridWidgets.
   */
  useEffect(() => {
    if (!view || !widgets) return;

    setGridWidgets((prevGridWidgets) => {
      const _genGridWidget = (widget: ViewWidget) =>
        genGridWidget(
          widget,
          layoutEditable,
          removeWidgetFromView,
          updateViewWidget,
          theme,
          view.id,
          view.selectors
        );

      // compute all when no widgets or view changed or widget added or widget removed
      if (
        !prevGridWidgets ||
        view?.id !== prevViewId ||
        widgets.length !== prevGridWidgets.length
      ) {
        return widgets.map((widget) => _genGridWidget(widget));
      }

      // update changed widgets
      const nextGridWidgets = [...prevGridWidgets];
      let dirty = false;
      widgets.forEach((widget, idx) => {
        // update GridWidget if any of the view widget deps changed or if the widget object changed
        if (
          !previousWidgets?.[idx] ||
          layoutEditable !== previousLayoutEditable ||
          theme !== previousTheme ||
          removeWidgetFromView !== prevRemoveWidgetFromView ||
          updateViewWidget !== prevUpdateViewWidget ||
          view.id !== prevViewId ||
          view.selectors !== prevViewSelectors ||
          widget !== previousWidgets[idx]
        ) {
          dirty = true;
          nextGridWidgets[idx] = _genGridWidget(widget);
        }
      });
      return dirty ? nextGridWidgets : prevGridWidgets;
    });
  }, [
    genGridWidget,
    layoutEditable,
    previousWidgets,
    widgets,
    previousLayoutEditable,
    removeWidgetFromView,
    updateViewWidget,
    theme,
    view,
    previousTheme,
    prevRemoveWidgetFromView,
    prevUpdateViewWidget,
    prevViewId,
    prevViewSelectors,
  ]);

  /* map selectors to widgets that use them */
  /* update only the necessary object to trigger re-render of affected widgets */
  const selectorWidgetsMap: Record<string, string[]> | undefined = useMemo(() => {
    if (!widgets) return;
    const map: Record<string, string[]> = {};
    widgets?.forEach((widget) => {
      // parse import identifier from each widget's query
      if (!widget.query) return;
      parseVSLQuerySelectors([widget.query]).forEach((sel) => {
        if (!map[sel.id]) map[sel.id] = [widget.id];
        else map[sel.id].push(widget.id);
      });
    });
    return map;
  }, [widgets]);

  const {
    error: selectorsOptionsError,
    selectorsOptions,
    loading: loadingSelectorsOptions,
  } = useVSLSelector(view?.selectors, { viewId: view?.id, dashboardId: dashboardId });

  /** Given an array of selectors that changed, update all ViewWidgets that use them */
  const updateWidgetSelector = useCallback(
    (selectors: Selector[]) => {
      const nextWidgets: Record<string, ViewWidget> = {};
      selectors.forEach((selector) => {
        selectorWidgetsMap?.[selector.id]?.forEach((widgetId) => {
          const viewWidget = widgets?.find((widget) => widget.id === widgetId);
          if (!viewWidget) return;

          const widgetSelectorIndex = viewWidget?.selectors?.findIndex(
            (sel) => sel.id === selector.id
          );

          const nextWidgetSelectors = nextWidgets[viewWidget.id]?.selectors;
          // grab selectors from viewWidget if first usage for the widget, otherwise grab from nextWidgets
          const newWidgetSelectors = nextWidgetSelectors
            ? [...nextWidgetSelectors]
            : [...(viewWidget?.selectors || [])];
          newWidgetSelectors[widgetSelectorIndex ?? viewWidget?.selectors?.length ?? 0] = selector;
          nextWidgets[viewWidget.id] = { ...viewWidget, selectors: newWidgetSelectors };
        });
      });
      updateViewWidgets(Object.values(nextWidgets));
    },
    [selectorWidgetsMap, updateViewWidgets, widgets]
  );

  // Sanitise view selectors selected_options after re-fetching selectors options
  useEffect(() => {
    if (!selectorsOptions || !view?.selectors) return;
    const [nextViewSelectors, nextWidgetSelectors] = sanitiseViewSelectorsOptions(
      view.selectors,
      selectorsOptions
    );

    if (nextWidgetSelectors.length) {
      updateWidgetSelector(nextWidgetSelectors);
      updateView({
        selectors: nextViewSelectors,
      });
    }
  }, [selectorsOptions, updateView, updateWidgetSelector, view?.selectors]);

  const selectorOnSelect = useCallback(
    (selector: Selector) => {
      updateWidgetSelector([selector]);

      // update view selector
      const selectorIndex = view?.selectors?.findIndex((sel) => sel.id === selector.id);
      const newViewSelectors = [...(view?.selectors || [])];
      newViewSelectors[selectorIndex ?? view?.selectors?.length ?? 0] = selector;
      updateView({
        selectors: newViewSelectors,
      });
    },
    [updateView, updateWidgetSelector, view]
  );

  const gridResizeHandle = useMemo(
    () => (
      <div
        className="react-resizable-handle"
        style={{
          bottom: "5px",
          border: "none",
          position: "fixed",
          right: "5px",
          zIndex: 9,
        }}
      >
        <StyledResizeIcon icon={IconNames.MAXIMIZE} size={25} $theme={theme} />
      </div>
    ),
    [theme]
  );

  return (
    <Flex alignItems="flex-start" flexDirection="column" fullHeight fullWidth>
      {hideSelectors ||
      !updateView ||
      !view?.selectors ||
      !(Object.values(view.selectors).length > 0) ? (
        <div></div>
      ) : (
        <Card
          style={{
            border:
              theme === Themes.LIGHT
                ? `1px solid ${Colors.LIGHT_GRAY3}`
                : `1px solid ${Colors.DARK_GRAY3}`,
            borderRadius: 4,
            boxShadow: "none",
            margin: "20px 10px 10px 10px",
            padding: 10,
          }}
        >
          <SelectorsGroup
            selectors={view.selectors}
            context={SelectorContext.VIEW}
            setSelectorOnSelect={selectorOnSelect}
            loadingSelectorsOptions={loadingSelectorsOptions}
            selectorsOptions={selectorsOptions}
            selectorsOptionsError={selectorsOptionsError}
            widgets={widgets}
            availableSelectorTypes={selectorTypes?.data}
            isLoading={loadingSelectorTypes}
            error={selectorTypesError}
          />
        </Card>
      )}
      <StyledViewContainer $theme={theme}>
        {editSelectorsDialogOpen && (
          <EditWidgetSelectorsDialog
            onClose={() => {
              if (isFunction(setEditSelectorsDialogOpen)) setEditSelectorsDialogOpen(false);
            }}
            name={view?.name}
            isOpen={editSelectorsDialogOpen}
            isView={true}
            selectors={view?.selectors}
            updateWidget={updateView}
            queries={
              widgets
                ?.map((widget) => widget.query)
                .filter((query): query is string => query !== undefined) ?? []
            }
          />
        )}
        {error ? (
          <Callout intent={Intent.WARNING} title="Error loading View.">
            Unable to load View. The following error occurred:
            <br />
            <Code>error</Code>
          </Callout>
        ) : loading ? (
          <Spinner size={SpinnerSize.LARGE} />
        ) : !widgets || widgets?.length === 0 ? (
          <NonIdealState
            description="No Widgets have been added to this View yet. Use the Add Widget controls to add one."
            icon={IconNames.SEARCH}
            title="No Widgets attached to View."
          />
        ) : (
          <SizeMe refreshRate={300}>
            {({ size }) => {
              return (
                <GridLayout
                  cols={12}
                  isDraggable={!readonly && layoutEditable}
                  isResizable={!readonly && layoutEditable}
                  margin={GridMargin}
                  onResizeStop={updateLayout}
                  onDragStop={updateLayout}
                  resizeHandle={gridResizeHandle}
                  rowHeight={50}
                  width={size.width || 800}
                >
                  {gridWidgets}
                </GridLayout>
              );
            }}
          </SizeMe>
        )}
      </StyledViewContainer>
    </Flex>
  );
};

const StyledDragIcon = styled(Icon)<{ $theme: TTheme }>`
  color: ${({ $theme }) => ($theme === Themes.DARK ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY3)};
  left: 45%;
  opacity: 0.8;
  position: fixed;
  top: 45%;
  z-index: 9;
`;

const StyledDragOverlay = styled.div<{ $theme: TTheme }>`
  background-color: ${({ $theme }) =>
    $theme === Themes.DARK ? Colors.DARK_GRAY3 : Colors.LIGHT_GRAY3};
  z-index: 8;
  opacity: 0.7;
`;

const StyledResizeIcon = styled(Icon)<{ $theme: TTheme }>`
  bottom: 5px;
  color: ${({ $theme }) => ($theme === Themes.DARK ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY3)};
  opacity: 0.8;
  position: fixed;
  right: 5px;
  transform: rotate(90deg);
  z-index: 10;
`;

const StyledViewContainer = styled.div`
  ${styledScrollBar};
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;

export default View;
