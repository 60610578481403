import { Colors, Intent } from "@blueprintjs/core";
import { TTheme } from "types";

export const UPDATE_UI_STATE = "UPDATE_UI_STATE";
export const UPDATE_DATE_FORMAT = "UPDATE_DATE_FORMAT";

export const UPDATE_APP_STATUS = "UPDATE_APP_STATUS";

export const UPDATE_AVAILABLE_TABS = "UPDATE_AVAILABLE_TABS";
export const UPDATE_UNIT = "UPDATE_UNIT";

export const SET_USER_AGENT = "SET_USER_AGENT";

export const GLOBAL_ERROR = "GLOBAL_ERROR";
export const SET_BLOCKING = "SET_BLOCKING";
export const SET_GLOBAL_ERROR = "SET_GLOBAL_ERROR";
export const TOGGLE_DIAGRAM_OPEN = "TOGGLE_DIAGRAM_OPEN";
export const TOGGLE_THEME = "TOGGLE_THEME";
export const DEFAULT_MODEL_TAB = "key kpis";

export const Themes: { [key: string]: TTheme } = {
  LIGHT: "light",
  DARK: "dark",
};

export const StyledComponentThemes: {
  [K in TTheme]: {
    appBgColor: string;
    borderColor: string;
    moduleBgColor: string;
  };
} = {
  dark: {
    appBgColor: Colors.DARK_GRAY2,
    borderColor: Colors.DARK_GRAY5,
    moduleBgColor: Colors.DARK_GRAY3,
  },
  light: {
    appBgColor: Colors.LIGHT_GRAY5,
    borderColor: Colors.LIGHT_GRAY2,
    moduleBgColor: Colors.WHITE,
  },
};

export const MIN_APP_WIDTH = 768;
export const MAIN_SIDE_MENU_COLLAPSED_WIDTH = 50;
export const MAIN_SIDE_MENU_WIDTH = 220;
export const SIDE_MENU_WIDTH = 350;
export const CONTROL_BAR_HEIGHT = 40;
export const DATAENTRY_TOOLBAR_HEIGHT = 30;
export const DATAENTRY_DEFAULT_ROW_HEADER_WIDTH = 250;
export const DATAENTRY_DEFAULT_COLUMN_WIDTH = 130;
export const DATAENTRY_DEFAULT_MIN_COL_WIDTH = 80;
export const DATAENTRY_DEFAULT_MAX_COL_WIDTH = 300;
export const FX_EDITOR_HEIGHT = 30;
export const FX_EDITOR_SIM_HEIGHT = 32;
export const FX_EDITOR_LINE_HEIGHT = 22;
export const FX_EDITOR_MAX_HEIGHT = 200;
export const FX_EDITOR_ERROR_HEIGHT = 18;
export const FX_EDITOR_ERRORS_MAX_HEIGHT = 70;

export const ElevationBoxShadows = {
  ELEVATION_0: "0 0 0 1px rgb(16 22 26 / 15%), 0 0 0 rgb(16 22 26 / 0%), 0 0 0 rgb(16 22 26 / 0%)",
  ELEVATION_1:
    "0 0 0 1px rgb(16 22 26 / 10%), 0 0 0 rgb(16 22 26 / 0%), 0 1px 1px rgb(16 22 26 / 20%)",
  ELEVATION_2:
    "0 0 0 1px rgb(16 22 26 / 10%), 0 1px 1px rgb(16 22 26 / 20%), 0 2px 6px rgb(16 22 26 / 20%)",
};

export const colors = {
  primaryblue: "#447DE8",
  primarybluealpha: "#447DE826",
  primaryred: "#DD3058",
  primaryorange: "#424650",
  primarygreen: "#11997B",

  // GREYSCALE
  secondarywhite: "#FFF",
  secondarylightgrey: "#F3F3F4",
  secondarymedgrey: "#CFD0D3",
  secondarygrey: "#A0A2A7",
  secondarydark: "#424650",

  // TEXT
  textdark: "#424650",
  textgrey: "#70737B",
  textlightgrey: "#CFD0D3",
  textwhite: "#FFF",
  texthighlight: "rgba(255,255,0,0.4)",
};

// Page states that can be applied to any VS page
export const PageStates = {
  READY: { message: "READY", intent: Intent.PRIMARY, loading: false },
  WORKING: { message: "WORKING", intent: Intent.PRIMARY, loading: true },
  ERROR: { message: "ERROR!", intent: Intent.DANGER, loading: false },
};

export const GridMargin: [number, number] = [10, 10];

export type FontSize = "Small" | "Medium" | "Large";

export const FontSizes: { [key: string]: FontSize } = {
  SMALL: "Small",
  MEDIUM: "Medium",
  LARGE: "Large",
};
