import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Classes,
  Dialog,
  FormGroup,
  Intent,
  Label,
  NumericInput,
  Switch,
  Tooltip,
} from "@blueprintjs/core";
import AppContext from "context/appContext";
import ModelContext from "context/modelContext";
import { Themes } from "constants/uiConstants";
import { Flex, FullWidthDivider } from "components/utility/StyledComponents";
import { IBasicModelInfo, IModelInfo } from "types/model";
import { IActionReturnType } from "types";

interface IModelSettingsDialog {
  editModelProperties: (
    modelID: string,
    edit: Partial<IBasicModelInfo<Date>>
  ) => IActionReturnType<string, { modelID: string; edit: IBasicModelInfo<Date> }>;
  isOpen: boolean;
  modelInfo: IModelInfo;
  setSettingsDialogOpen: Dispatch<SetStateAction<boolean>>;
}

export const ModelSettingsDialog: React.FC<IModelSettingsDialog> = ({
  editModelProperties,
  isOpen,
  modelInfo,
  setSettingsDialogOpen,
}) => {
  const { iterations, precision, rollForward, updates } = modelInfo;
  const [circIterations, setCircIterations] = useState(iterations);
  const [circPrecision, setCircPrecision] = useState(precision);
  const [autoRollFwd, setAutoRollFwd] = useState(rollForward);
  const [autoUpdates, setAutoUpdates] = useState(updates);
  const [changes, setChanges] = useState(false);

  const {
    config: { theme },
  } = useContext(AppContext);
  const {
    config: { showErrors },
    setConfig,
  } = useContext(ModelContext);

  const handleClose = () => {
    setSettingsDialogOpen(false);
  };

  function submit() {
    editModelProperties(modelInfo.id, {
      iterations: circIterations,
      precision: circPrecision,
      rollForward: autoRollFwd,
      updates: autoUpdates,
    });
  }

  useEffect(() => {
    if (!isOpen) return;
    setChanges(false);
    setCircIterations(iterations);
    setCircPrecision(precision);
    setAutoRollFwd(rollForward);
    setAutoUpdates(updates);
  }, [isOpen, iterations, precision, rollForward, updates]);

  useEffect(() => {
    if (
      iterations !== circIterations ||
      precision !== circPrecision ||
      rollForward !== autoRollFwd ||
      updates !== autoUpdates
    )
      setChanges(true);
    else setChanges(false);
  }, [
    autoRollFwd,
    autoUpdates,
    circIterations,
    circPrecision,
    iterations,
    precision,
    rollForward,
    updates,
  ]);

  return (
    <Dialog
      icon="settings"
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      title="Model Settings"
      canOutsideClickClose={true}
      enforceFocus={true}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <Switch
            checked={showErrors}
            label="Show errors"
            onChange={() => {
              setConfig({ showErrors: !showErrors });
            }}
          />
          <FullWidthDivider style={{ marginBottom: "10px" }} />
          <Flex flexDirection="column" alignItems="flex-start">
            <Tooltip content="A Model Roll Forward occurs when a company releases new results for a fiscal period. If this is setting is on, the platform will overwrite the relevant period’s forecast formulae with the actual fiscal results. A new forecast period will also be appended to the end of the model.">
              <Checkbox checked={autoRollFwd} onChange={() => setAutoRollFwd(!autoRollFwd)}>
                Model roll forward
              </Checkbox>
            </Tooltip>
            <Tooltip content="A in-place Update occurs when a given item’s value changes in an external or internal database. If this is setting is on, the platform will overwrite the relevant item with its new value. For example, if consensus estimates have changed for a company’s Revenue in 2023 from $1.5B to $1.6B then this change would also occur in any model for that company which had the Inplace Update setting turned on.">
              <Checkbox checked={autoUpdates} onChange={() => setAutoUpdates(!autoUpdates)}>
                In-Place updates
              </Checkbox>
            </Tooltip>
          </Flex>
          <FullWidthDivider style={{ marginBottom: "10px" }} />
          <Label style={{ fontWeight: "bold" }}>Circular references calculation options:</Label>
          <FormGroup label="Iterations:" helperText="Min: 1; Max: 10000">
            <NumericInput
              id="circ-iter"
              allowNumericCharactersOnly={true}
              value={circIterations}
              max={10000}
              min={1}
              stepSize={10}
              majorStepSize={100}
              minorStepSize={1}
              onValueChange={(val) => setCircIterations(val)}
              clampValueOnBlur={true}
            />
          </FormGroup>
          <FormGroup label="Precision:" helperText="Min: 0.0001; Max: 0.1.">
            <NumericInput
              id="circ-precision"
              allowNumericCharactersOnly={true}
              value={circPrecision}
              max={0.1}
              min={0.0001}
              stepSize={0.001}
              majorStepSize={0.01}
              minorStepSize={0.0001}
              onValueChange={(val) => setCircPrecision(val)}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button disabled={!changes} intent={Intent.PRIMARY} type="submit" onClick={submit}>
              Apply
            </Button>
            <Button onClick={handleClose}>Close</Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default ModelSettingsDialog;
