/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  Button,
  ButtonGroup,
  Classes,
  Divider,
  H4,
  H5,
  Intent,
  MenuItem,
  Panel,
  PanelProps,
} from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";
import capitalize from "lodash.capitalize";

import { noop } from "utils/generic";
import { Flex, FullWidthFormGroup } from "components/utility/StyledComponents";
import StyledConfigurationPanel from "components/utility/StyledConfigurationPanel";
import TemplateEditorDialog from "components/model/TemplateEditorDialog";
import EditTemplateVariablesDialog from "./EditTemplateVariablesDialog";
import { IModelTemplate, IModelTemplateVariable } from "types/modelTemplate";
import { IConfigureModelContext, withConfigureModelContext } from "context/configureModelContext";
import useModelConfigOptions from "hooks/useModelConfigOptions";
import SelectOrInitialiseCompany from "components/new/SelectOrInitialiseCompany";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  width: 100%;
`;

export type TSelectModelTemplate = PanelProps<IConfigureModelContext>;

const SelectModelTemplate: React.FC<TSelectModelTemplate> = ({ config, openPanel, setConfig }) => {
  const [variableEditorOpen, setVariableEditorOpen] = useState(false);
  const [templateEditorOpen, setTemplateEditorOpen] = useState(false);

  const { templates } = useModelConfigOptions();
  const { template, variables } = config;

  const addTemplateVariable = () => {
    const nextVars = [...config.variables, { INTERNAL_SOURCE: "" }];
    setConfig({ ...config, variables: nextVars });
  };

  const editTemplateVariable = (key: string, nextVariable: IModelTemplateVariable) => {
    const nextVariables = [...config.variables];
    nextVariables.splice(
      nextVariables.findIndex((variable) => Object.keys(variable)[0] === key),
      1,
      nextVariable
    );
    setConfig({ ...config, variables: nextVariables });
  };

  const removeTemplateVariable = (key: string) => {
    setConfig({
      ...config,
      variables: config.variables.filter((variable) => Object.keys(variable)[0] !== key),
    });
  };

  const onDialogClose = () => {
    setVariableEditorOpen(false);
  };

  const templateRenderer: ItemRenderer<IModelTemplate> = (template, { handleClick, modifiers }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={template.uid}
        onClick={handleClick}
        text={template.template_name}
      />
    );
  };

  const onTemplateSelect = (template: IModelTemplate) => {
    setConfig({ ...config, template });
  };

  return (
    <StyledConfigurationPanel>
      <H4>Select Model Template</H4>
      <StyledForm
        onSubmit={(e) => {
          e.preventDefault();
          openPanel({
            renderPanel: withConfigureModelContext(SelectOrInitialiseCompany),
            props: { enabled: true, panelNumber: 2 },
            title: "Select Company",
          } as Panel<object>);
        }}
      >
        <Flex
          alignItems="flex-start"
          flex="1 1 0"
          flexDirection="column"
          fullWidth
          justifyContent="flex-start"
        >
          <FullWidthFormGroup label="Select template:">
            <ButtonGroup>
              <Select
                onItemSelect={onTemplateSelect}
                items={templates?.data || []}
                itemRenderer={templateRenderer}
              >
                <Button
                  loading={!templates}
                  rightIcon="caret-down"
                  text={template?.template_name || "Select template..."}
                />
              </Select>
              {template?.uid ? (
                <Button
                  onClick={() => setTemplateEditorOpen(true)}
                  rightIcon="manually-entered-data"
                />
              ) : null}
            </ButtonGroup>
          </FullWidthFormGroup>
          <Flex
            alignItems="flex-start"
            flex="0"
            flexDirection="column"
            fullWidth
            justifyContent="flex-start"
          >
            <H5 className={template?.uid ? "" : Classes.TEXT_MUTED}>Template Information</H5>
            {template?.uid ? (
              <>
                <p>
                  <span className={Classes.TEXT_MUTED}>Template name:</span>&nbsp;
                  <strong>{template.template_name}</strong>
                </p>
                <p>
                  <span className={Classes.TEXT_MUTED}>Created on:</span>&nbsp;
                  <strong>{new Date(template.createdAt).toLocaleString()}</strong>
                </p>
                <p>
                  <span className={Classes.TEXT_MUTED}>Created by:</span>
                  &nbsp;<strong>{template.createdBy}</strong>
                </p>
                <p>
                  <span className={Classes.TEXT_MUTED}>Public:</span>
                  &nbsp;<strong>{capitalize(template.public.toString())}</strong>
                </p>
              </>
            ) : (
              <p className={Classes.TEXT_MUTED}>Select a template to view more details.</p>
            )}
          </Flex>
          <Button
            active={variableEditorOpen}
            disabled={!template}
            icon="edit"
            onClick={() => setVariableEditorOpen(true)}
            text={
              <span>
                View/Edit Variables &nbsp;
                <span
                  className={template ? Classes.TEXT_MUTED + " " + Classes.TEXT_SMALL : undefined}
                >
                  ADVANCED
                </span>
              </span>
            }
          />
          <Divider />
          {variables.length >= 1 ? (
            <>
              <H5>Template Variables</H5>
              {variables.map((variable) => {
                const [key, value] = Object.entries(variable)[0];
                return (
                  <code key={key}>
                    <p className={Classes.RUNNING_TEXT}>{`${key} : ${value}`}</p>
                  </code>
                );
              })}
            </>
          ) : null}
        </Flex>
        <Button
          className={Classes.FIXED}
          disabled={!template?.uid}
          style={{ alignSelf: "flex-end" }}
          intent={Intent.PRIMARY}
          type="submit"
          onClick={noop}
          rightIcon="circle-arrow-right"
          text="Next"
        />
      </StyledForm>
      <TemplateEditorDialog
        isOpen={templateEditorOpen}
        setOpen={setTemplateEditorOpen}
        templateID={template?.uid}
      />
      <EditTemplateVariablesDialog
        addTemplateVariable={addTemplateVariable}
        editTemplateVariable={editTemplateVariable}
        isOpen={variableEditorOpen}
        onClose={onDialogClose}
        modelTemplate={template}
        removeTemplateVariable={removeTemplateVariable}
        variables={variables}
      />
    </StyledConfigurationPanel>
  );
};

export default SelectModelTemplate;
