import React, { useContext, useMemo } from "react";
import { Button, Card, Classes, H4 } from "@blueprintjs/core";
import { COLOR_PICKER_OPTIONS } from "constants/dataEntryUIConstants";
import { f } from "utils/formatter";
import ColorPicker from "components/utility/ColorPicker";
import AppContext from "context/appContext";
import "styles/val/data-entry/color-picker-popover.scss";
import { IFocusedCellStyle } from "types/dataEntry";
import { INormalizedImportFact } from "types/import";
import { IFact } from "types/model";

interface IColorPickerPopoverProps {
  focusedCellFact: IFact | INormalizedImportFact;
  focusedCellStyle: IFocusedCellStyle;
  onChange: (change: { type: string; change: string }) => void;
  prop: "color" | "backgroundColor";
}

const ColorPickerPopover: React.FC<IColorPickerPopoverProps> = ({
  focusedCellFact,
  focusedCellStyle,
  onChange,
  prop,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);

  const isDefault = useMemo(() => {
    return focusedCellStyle[prop] === "default";
  }, [focusedCellStyle, prop]);

  const handleColorChange = (color: string) => {
    onChange({ type: prop, change: color });
  };

  const title = prop === "color" ? "Text Color" : "Background Color";

  return (
    <Card className={`color-picker-popover ${theme}`}>
      <div className="header">
        <H4>{title}</H4>
      </div>
      <div className="information">
        {isDefault ? (
          <>
            <div>
              <span className={Classes.TEXT_MUTED}>(Default {title.toLowerCase()} for: </span>
              <span>&nbsp;{focusedCellStyle.type}</span>
              <span className={Classes.TEXT_MUTED}>)</span>
            </div>
            {/* TODO: Implement appearance configurator
            <Link disabled to="/settings/appearance">
              Modify defaults
            </Link>*/}
          </>
        ) : (
          <span className={Classes.TEXT_MUTED}>
            (Custom color: <span className="bold">{focusedCellStyle[prop]?.toUpperCase()}</span>)
          </span>
        )}
      </div>
      <ColorPicker
        onChange={handleColorChange}
        color={isDefault ? focusedCellStyle.default[prop] : focusedCellStyle[prop]}
        colors={COLOR_PICKER_OPTIONS[prop][theme]}
      />
      <div className="restore-default">
        <Button
          disabled={isDefault}
          onClick={() => onChange({ type: prop, change: "default" })}
          text="Restore default"
        />
        <span className="example" style={{ ...focusedCellStyle.default }}>
          <span className={Classes.TEXT_MUTED}>Default:&nbsp;</span>
          {focusedCellFact ? f(focusedCellFact, "en-US", focusedCellFact.format?.unit) : null}
        </span>
      </div>
    </Card>
  );
};

export default ColorPickerPopover;
