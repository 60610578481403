import type Highcharts from "highcharts/highstock";

export default function (H: typeof Highcharts) {
  H.addEvent(H.Chart, "render", function () {
    const legendItems = this.legend.allItems;
    const series = this.series;

    legendItems.forEach((item, i) => {
      // Casting to any since HC types don't work well for legend items
      // eslint-disable-next-line
      const group = (item as any)?.legendItem?.group;

      if (!group) return;

      if (group && !group.eventsToUnbind?.length) {
        group.eventsToUnbind = [];
      }

      // Remove event listeners...
      // eslint-disable-next-line
      group.eventsToUnbind.forEach((unbind: Function) => {
        // HC uses just Function type for "unbind"
        unbind();
        group.eventsToUnbind.length = 0;
      });

      // ...and add them again
      const unbind = H.addEvent(group.element, "mouseover", () => {
        // Handle pie differently, as it uses points instead of series
        if (series?.[0]?.options.type === "pie") {
          const points = series?.[0]?.points;
          if (points?.[i]?.visible) {
            this.tooltip.refresh(points?.[i]);
          }
        } else {
          if (series?.[i].visible) {
            const points = series?.[i].points;
            this.tooltip.refresh(points?.[0]);
          }
        }
      });

      group.eventsToUnbind.push(unbind);
    });
  });
}
