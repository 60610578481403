import { useCallback, useEffect, useState } from "react";

import { EDIT_MODEL_PROPERTIES_ROUTE, EStatus, EMethods } from "constants/apiConstants";
import { useApiCallback } from "hooks/useApiCallback";
import { IResponse } from "types";
import { IModelHistoryResponse, TModelHistoryMutator } from "hooks/useModelHistory2";
import { IFilterState, TModelHistoryTablePartialModel } from "types/modelHistory";
import { EDIT_MODEL_PROPERTIES } from "../constants/modelConstants";
import { updateModelHistory } from "utils/modelHistoryUtils";
import { IModelInfo } from "../types/model";
import useSuccessFailToast from "./useSuccessFailToast";

type TUseEditModelProperties = (config?: {
  data?: IModelHistoryResponse;
  mutate?: TModelHistoryMutator;
  filters?: IFilterState;
}) => {
  editModelProperties: TEditModelPropertiesCallback;
  fetching: boolean;
  response: IResponse<unknown> | null;
};

export type TEditModelPropertiesCallback = (
  modelInfo: IModelInfo | TModelHistoryTablePartialModel,
  edit: Partial<IModelInfo>
) => void;

/**
 * Custom hook to return a callback to edit a model's properties like tags or sharing permissions.
 * Both makes a request to the server and mutates the local swr cache.
 * */
const useEditModelProperties: TUseEditModelProperties = (config = {}) => {
  const { data, mutate } = config;
  const [modelInfo, setModelInfo] = useState<IModelInfo | TModelHistoryTablePartialModel | null>(
    null
  );
  // Grab a callback to interact with the vs api
  const { callback, clear, fetching, response } = useApiCallback();

  const editModelProperties = useCallback<TEditModelPropertiesCallback>(
    (modelInfo, edit) => {
      if (callback) {
        setModelInfo(modelInfo);

        const body = {
          action: EDIT_MODEL_PROPERTIES,
          iterations: modelInfo.iterations,
          modelID: modelInfo.id,
          precision: modelInfo.precision,
          rollForward: modelInfo.rollForward,
          styling: modelInfo.styling,
          tags: modelInfo.tags,
          updates: modelInfo.updates,
          ...edit,
        };

        if (typeof mutate === "function" && data) {
          const nextModels = updateModelHistory([{ ...edit, id: modelInfo.id }], data.data.models);
          mutate({ ...data, data: { ...data.data, models: nextModels } }, false);
        }
        callback(EDIT_MODEL_PROPERTIES_ROUTE, { method: EMethods.POST, body });
      }
    },
    [callback, data, mutate]
  );

  useSuccessFailToast(response, false);

  useEffect(() => {
    if (response && response.status === EStatus.failed) {
      clear();
      if (typeof mutate === "function" && data && modelInfo) {
        const nextModels = updateModelHistory(
          [{ id: modelInfo.id, tags: modelInfo.tags }],
          data.data.models
        );
        mutate({ ...data, data: { ...data.data, models: nextModels } }, false);
      }
    }
  }, [clear, data, response, modelInfo, mutate]);

  return { editModelProperties, fetching, modelInfo, response };
};

export default useEditModelProperties;
