import {
  Callout,
  Checkbox,
  Classes,
  Icon,
  Intent,
  Position,
  Radio,
  Spinner,
  SpinnerSize,
  Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  ColumnResizeMode,
  ColumnSizingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  SortingState,
  Table as TTable,
  Updater,
  useReactTable,
} from "@tanstack/react-table";
import { Flex, FullSizeDiv, StyledLink } from "components/utility/StyledComponents";
import {
  MaybeStickyTd,
  MaybeStickyTh,
  StyledTable,
} from "components/utility/StyledDashboardComponents";
import { customColumnObjects } from "constants/modelHistoryConstants";
import AppContext from "context/appContext";
import isDate from "lodash.isdate";
import isFunction from "lodash.isfunction";

import React, {
  memo,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components/macro";
import { ESortDirection, TTheme } from "types";
import { IModelPermissions, IRawFact } from "types/model";
import { TModelHistoryColumnDef, TModelHistoryTablePartialModel } from "types/modelHistory";
import { TableWidgetColumnConfig, TableWidgetConfig } from "types/widget";
import {
  generateSkeletonModels,
  renderFactOrValue,
  renderPermissions,
  savedColumnsToColumnDefs,
} from "utils/modelHistoryUtils";
import TagCell from "./TagCell";
import TableColumnConfigMenu from "./TableColumnConfigMenu";
import { createStyleMatrix } from "utils/table";
import { DEFAULT_MODEL_TAB, FontSizes } from "constants/uiConstants";
import { IModelTemplate, IModelTemplateVariable } from "types/modelTemplate";
import { IExchange } from "types/createModel";
import ColumnNotesDialog from "components/table/ColumnNotesDialog";

export type TableValue<Ft = string> =
  | boolean
  | string
  | number
  | Date
  | string[]
  | IModelPermissions
  | IRawFact<Ft>
  | IModelTemplate
  | IModelTemplateVariable[]
  | IExchange
  | { ISO: string; name: string };

export type TableStyleMatrix = (React.CSSProperties | undefined)[][];

export interface TableProps {
  allowSelectAll?: boolean; // enable select all checkbox/radio button
  allRowsSelected?: boolean;
  availableColumns?: TModelHistoryColumnDef[];
  // Supports both a fully defined Column config and an array of strings.
  columnTemplates?: TableWidgetColumnConfig[] | string[];
  // Optionally override column configurations. Takes precedence over configs supplied to columnTemplates
  configOverrides?: TableWidgetConfig;
  data: { id: string; [key: string]: TableValue }[] | null;
  displayRowIndex?: boolean;
  enableConfigOverrides?: boolean;
  enableSelection?: boolean;
  error?: boolean;
  excludeFromLoadingCols?: { [key: string]: boolean };
  id: string;
  loading: boolean;
  loadingRows?: number[];
  onColumnSizingChange?: (columnSizing: ColumnSizingState) => void;
  onTagEdit?: (modelInfo: TModelHistoryTablePartialModel) => void;
  paginationState?: PaginationState;
  pinnedColumns?: number;
  selectedRows?: { id: string }[];
  setAllRowsSelected?: (allSelected: boolean) => void;
  setColumnTemplates?: (cols: TableWidgetColumnConfig[]) => void;
  setSelectedRows?: (models: TModelHistoryTablePartialModel[]) => void;
  setSortingState?: OnChangeFn<SortingState>;
  sortingState?: SortingState;
  styleMatrix?: TableStyleMatrix;
  updateConfig?: (details: Partial<TableWidgetConfig>) => Promise<boolean>;
  useRadioButtons?: boolean;
}

const Table = ({
  allRowsSelected: propAllRowsSelected,
  availableColumns: _propAvailableColumns,
  columnTemplates,
  configOverrides,
  data,
  enableConfigOverrides = true,
  enableSelection = true,
  allowSelectAll = true,
  error = false,
  excludeFromLoadingCols,
  displayRowIndex = false,
  loading,
  loadingRows,
  onColumnSizingChange,
  onTagEdit,
  pinnedColumns = 0,
  paginationState,
  selectedRows: propSelectedRows,
  setAllRowsSelected: propSetAllRowsSelected,
  setSelectedRows: propSetSelectedRows,
  setSortingState,
  sortingState,
  styleMatrix = [],
  updateConfig,
  useRadioButtons = false,
}: TableProps) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const [columnSizingState, setColumnSizingState] = useState<ColumnSizingState | undefined>();
  const [activeHeader, setActiveHeader] = useState<string>();
  const [notesDialogColumn, setNotesDialogColumn] = useState<TableWidgetColumnConfig | null>(null);

  // Columns
  const [columnResizeMode] = useState<ColumnResizeMode>("onChange");

  let pageIndex: number | undefined,
    pageSize: number | undefined = undefined;

  if (paginationState) {
    pageIndex = paginationState.pageIndex;
    pageSize = paginationState.pageSize;
  }

  useEffect(() => {
    if (!columnSizingState && configOverrides?.columns) {
      const sizingState = configOverrides.columns.reduce((acc, col) => {
        if (col.width) {
          acc[col.id] = col.width;
        }
        return acc;
      }, {} as ColumnSizingState);

      setColumnSizingState(sizingState);
    } else if (!columnSizingState && !configOverrides?.columns) {
      setColumnSizingState({});
    }
  }, [columnSizingState, configOverrides, setColumnSizingState]);

  // Selection
  const [localSelectedRows, setLocalSelectedRows] = useState<TModelHistoryTablePartialModel[]>([]);
  const [localAllModelsSelected, setLocalAllModelsSelected] = useState(false);
  const selectedRows = propSelectedRows || localSelectedRows;
  const setSelectedRows = propSetSelectedRows || setLocalSelectedRows;
  const allRowsSelected = propAllRowsSelected || localAllModelsSelected;
  const setAllRowsSelected = propSetAllRowsSelected || setLocalAllModelsSelected;
  const [lastSelectedModel, setLastSelectedModel] = useState<string>();

  const onRowSelect = useCallback(
    (
      e: SyntheticEvent<HTMLInputElement>,
      rowData: TModelHistoryTablePartialModel,
      checked: boolean
    ) => {
      if (!selectedRows || !setSelectedRows || !setAllRowsSelected) return;
      if (data) {
        if (useRadioButtons) {
          setSelectedRows([rowData]);
          return;
        }
        // Compiler not recognising this existing prop
        // eslint-disable-next-line
        // @ts-ignore
        const { shiftKey } = e.nativeEvent;
        const targetIndex = data.findIndex((item) => item.id === rowData.id);
        if (shiftKey) {
          // If no model has been selected, then even if shift is pressed, just add the model to the selected list
          if (!lastSelectedModel) {
            setSelectedRows(selectedRows.concat([rowData]));
          } else {
            // Otherwise fill in indicies between last selected and target
            const lastSelectedIndex = data.findIndex((item) => item.id === lastSelectedModel);

            // const sliceIndicies = [targetIndex, lastSelectedIndex].sort((a, b) => a - b);
            const sliceIndicies =
              targetIndex > lastSelectedIndex
                ? [lastSelectedIndex, targetIndex]
                : [targetIndex, lastSelectedIndex];

            if (targetIndex > lastSelectedIndex) {
              sliceIndicies[0] += 1;
              sliceIndicies[1] += 1;
            }
            setSelectedRows(
              Array.from(new Set(selectedRows.concat([...data.slice(...sliceIndicies)])))
            );
          }
        } else {
          setAllRowsSelected(false);
          if (!checked) {
            setSelectedRows(selectedRows.concat([rowData]));
          } else {
            setSelectedRows(selectedRows.filter((model) => model.id !== rowData.id));
          }
        }
        setLastSelectedModel(rowData.id);
      }
    },
    [lastSelectedModel, data, selectedRows, setSelectedRows, setAllRowsSelected, useRadioButtons]
  );

  const handleSelectAllRows = useCallback(() => {
    if (!setAllRowsSelected || !setSelectedRows) return;
    if (!allRowsSelected) {
      setAllRowsSelected(true);
      // If flatModels not yet present then simply select an empty array
      setSelectedRows(data || []);
    } else {
      setSelectedRows([]);
      setAllRowsSelected(false);
    }
  }, [allRowsSelected, data, setAllRowsSelected, setSelectedRows]);

  const renderSelectModelCheckbox = useCallback(
    ({ row }) => {
      if (!selectedRows) return;
      const checked = selectedRows.findIndex((model) => model.id === row.original.id) !== -1;
      return useRadioButtons ? (
        <Radio
          large
          onChange={(e) => onRowSelect(e, row.original, checked)}
          checked={checked}
          style={{ margin: 0, paddingLeft: 40 }}
        />
      ) : (
        <Checkbox
          large
          onChange={(e) => onRowSelect(e, row.original, checked)}
          checked={checked}
          style={{ margin: 0, paddingLeft: 40 }}
        />
      );
    },
    [selectedRows, onRowSelect, useRadioButtons]
  );

  const isPaginated = !!(!Number.isNaN(pageIndex) && pageSize);

  const maybeSkeletonData = useMemo<{ id: string; [key: string]: TableValue }[]>(() => {
    if (loading && columnTemplates) {
      return generateSkeletonModels(savedColumnsToColumnDefs(columnTemplates), 25);
    } else if (data) {
      return data;
    }
    return [];
  }, [columnTemplates, data, loading]);

  const columns = useMemo(() => {
    if (!columnTemplates) return;
    const withConfigOverrides = columnTemplates
      .map((template) => {
        const columnConfig = configOverrides?.columns?.find(
          (col) => col.id === (typeof template === "string" ? template : template.id)
        );
        return columnConfig?.hidden ? undefined : template;
      })
      .filter((column): column is TableWidgetColumnConfig => column !== undefined) // filter undefined from hidden columns
      .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0));

    const maybeWithDefaultColumns = savedColumnsToColumnDefs(withConfigOverrides);

    if (displayRowIndex) {
      maybeWithDefaultColumns.unshift(customColumnObjects.rowIndex);
    }

    if (enableSelection) {
      maybeWithDefaultColumns.unshift(customColumnObjects.selectModel);
    }

    let linkColumnIdx = maybeWithDefaultColumns.findIndex(
      (col) =>
        col.id === "id" || col.id === "title" || col.id === "companyName" || col.id === "ticker"
    );
    if (linkColumnIdx === -1) {
      linkColumnIdx = maybeWithDefaultColumns.findIndex(
        (col) => col.id !== "permissions" && col.id !== "tags" && col.id !== "selectModel"
      );
    }
    // Add any state dependent custom renderers to the column objects.
    const withCustomRenderers: TModelHistoryColumnDef[] = maybeWithDefaultColumns.map(
      (col, idx) => {
        const configOverride = configOverrides?.columns?.find((c) => c.id === col.id);
        if (col.id === "selectModel") {
          return {
            ...col,
            meta: { ...configOverride },
            id: "selectModel",
            enableSorting: true,
            header: () => {
              return (
                allowSelectAll && (
                  <Checkbox
                    checked={allRowsSelected}
                    large
                    onChange={handleSelectAllRows}
                    style={{ margin: 0, paddingLeft: 40 }}
                  />
                )
              );
            },
            cell: renderSelectModelCheckbox,
          };
        }
        if (col.id === "rowIndex") {
          return {
            ...col,
            meta: { ...configOverride },
            cell: (cell) => (
              <StyledLink
                target="_blank"
                to={`/model/uid=${cell.row.original.id}/${DEFAULT_MODEL_TAB}`}
              >
                <strong className={Classes.TEXT_MUTED}>{cell.row.index + 1}</strong>
              </StyledLink>
            ),
          };
        }
        if (col.id === "tags") {
          return {
            ...col,
            meta: { ...configOverride },
            cell: (info) => <TagCell info={info} onTagEdit={onTagEdit} />,
          };
        }

        if (col.meta?.type === "date") {
          return {
            ...col,
            ...(col.id && { accessorKey: col.id }),
            accessorFn: (row) => {
              if (col?.id) {
                const dt = row[col.id];
                let date: Date;
                if (isRawFactOrOtherValue(dt) && dt.value) {
                  // Currently we assume that any value prop is a valid date string
                  date = new Date(dt.value);
                } else {
                  date = isDate(dt) ? dt : new Date(dt as string);
                }
                return isDate(date) ? date.toLocaleString("en-US") : "";
              }
            },
          };
        }

        if (col.meta?.type === "permissions") {
          return {
            ...col,
            accessorKey: "permissions",
            cell: renderPermissions,
            disableSortBy: false,
          };
        }
        return {
          ...col,
          cell: (cell) => {
            const textToHighlight = configOverrides?.filters?.allColumns ?? "";
            const nextValue = renderFactOrValue(
              {
                ...col,
                meta: {
                  // This spread is to include configOverrides when rendering
                  ...col.meta,
                  ...configOverride,
                  format: { ...col.meta?.format, ...configOverride?.format },
                },
              },
              cell.row.original,
              linkColumnIdx === idx || col.id === "Ticker",
              theme,
              textToHighlight
            );
            return nextValue;
          },
        };
      }
    );
    return withCustomRenderers;
  }, [
    columnTemplates,
    configOverrides?.columns,
    configOverrides?.filters?.allColumns,
    displayRowIndex,
    enableSelection,
    renderSelectModelCheckbox,
    allowSelectAll,
    allRowsSelected,
    handleSelectAllRows,
    onTagEdit,
    theme,
  ]);

  // compute color gradient columns
  const localStyleMatrix = useMemo(() => {
    if (!columns || !configOverrides?.columns) {
      return [];
    }

    return createStyleMatrix(columns, configOverrides.columns, data, theme, styleMatrix);
  }, [configOverrides?.columns, data, styleMatrix, theme, columns]);

  const handleColumnWidthChange = (info: Updater<ColumnSizingState>) => {
    if (columnSizingState) {
      setColumnSizingState(isFunction(info) ? info(columnSizingState) : info);
      if (onColumnSizingChange) onColumnSizingChange(columnSizingState);
    }
  };

  const table = useReactTable({
    data: maybeSkeletonData,
    defaultColumn: {
      size: 130,
      minSize: 50,
    },
    columns: columns || [],
    columnResizeMode,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: isPaginated,
    manualSorting: true,
    onColumnSizingChange: handleColumnWidthChange,
    onSortingChange: setSortingState,
    state: {
      columnSizing: columnSizingState || {},
      sorting: sortingState,
    },
  });

  /** get icon size based on font size in config */
  const getIconSize = () => {
    switch (configOverrides?.fontSize) {
      case FontSizes.SMALL:
        return 9;
      case FontSizes.LARGE:
        return 11;
      case FontSizes.MEDIUM:
      default:
        return 10;
    }
  };

  const columnHeaders = table
    .getHeaderGroups()
    .map((headerGroup) => {
      return headerGroup.headers.map((header, index) => {
        const configOverride = configOverrides?.columns?.find((col) => col.id === header.id);
        if (configOverride?.hidden) return;

        const layoutMode = configOverrides?.layoutMode ?? "fixed";
        const pinned = layoutMode === "fixed" ? index < pinnedColumns : false;
        const left = header.column.getStart();
        let renderedHeader = flexRender(header.column.columnDef.header, header.getContext());

        // TODO: Why? what is this differentiation?
        if (typeof renderedHeader === "string") {
          renderedHeader = renderedHeader.toUpperCase();
        }

        const selectColumn = header.id === "selectModel" || header.id === "rowIndex";

        return (
          <MaybeStickyTh
            id={header.id}
            key={header.id}
            $left={left}
            $pinned={pinned}
            onMouseOver={(e) => {
              if (!selectColumn) setActiveHeader(e.currentTarget.id);
            }}
            onMouseLeave={() => {
              if (!selectColumn) setActiveHeader(undefined);
            }}
            style={
              selectColumn
                ? {
                    verticalAlign: "middle",
                    width: header.getSize(),
                  }
                : {
                    cursor: header.column.getIsResizing() ? "col-resize" : undefined,
                    width: header.getSize(),
                  }
            }
            $theme={theme}
          >
            {selectColumn ? (
              renderedHeader
            ) : (
              <FullSizeDiv fullHeight style={{ padding: "0 5px", position: "relative" }}>
                <Flex
                  alignItems="center"
                  justifyContent="flex-start"
                  onClick={() => {
                    if (header.column.getCanSort()) {
                      const currentSort = header.column.getIsSorted();
                      if (!currentSort) {
                        header.column.toggleSorting();
                      } else {
                        if (currentSort === "asc") {
                          header.column.toggleSorting(true);
                        } else {
                          header.column.toggleSorting(false);
                        }
                      }
                    }
                  }}
                  style={{
                    cursor: header.column.getCanSort() ? "pointer" : undefined,
                    whiteSpace: "nowrap",
                  }}
                  fullHeight
                >
                  {enableConfigOverrides &&
                  header.id !== "selectModel" &&
                  header.id !== "rowIndex" &&
                  header.id !== "tags" ? (
                    <TableColumnConfigMenu
                      columnConfig={configOverride}
                      configOverrides={configOverrides}
                      defaultHeader={header.id}
                      id={header.column.id}
                      updateConfig={updateConfig}
                      setNotesDialogColumn={setNotesDialogColumn}
                      visible={activeHeader === header.id && !header.column.getIsResizing()}
                    />
                  ) : null}
                  <Flex
                    flex="1 0" // To allow the header to grow without default maximising space
                    fullHeight
                    fullWidth
                    justifyContent="flex-end"
                    style={{
                      padding: "6px 0",
                      flexShrink: 1,
                      overflow: "hidden",
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <span
                        className={loading ? Classes.SKELETON : undefined}
                        style={{
                          overflow: "hidden",
                          textAlign: "right",
                          textOverflow: "ellipsis",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        {renderedHeader}
                      </span>
                    )}
                  </Flex>
                  {header.column.getIsSorted() || configOverride?.notes ? (
                    <div style={{ paddingBottom: "2px" }}>
                      {configOverride?.notes && (
                        <Tooltip
                          content={configOverride?.notes.split("\n").map((line, idx) => (
                            <p key={idx}>{line}</p>
                          ))}
                          position={Position.BOTTOM}
                          openOnTargetFocus={false}
                          minimal
                          popoverClassName="custom-notes-tooltip"
                        >
                          <Icon
                            icon={IconNames.INFO_SIGN}
                            size={getIconSize() + 1}
                            style={{ marginLeft: "6px" }}
                            onClick={(ev) => {
                              ev.stopPropagation();
                            }}
                          ></Icon>
                        </Tooltip>
                      )}
                      {header.column.getIsSorted() && (
                        <Icon
                          icon={
                            header.column.getIsSorted() === ESortDirection.ASC
                              ? IconNames.ARROW_UP
                              : IconNames.ARROW_DOWN
                          }
                          size={getIconSize()}
                          style={{ marginLeft: "6px" }}
                        />
                      )}
                    </div>
                  ) : null}
                </Flex>
              </FullSizeDiv>
            )}
            {header.column.getCanResize() ? (
              <Resizer
                className={header.column.getIsResizing() ? "isResizing" : undefined}
                onMouseDown={header.getResizeHandler()}
                onTouchStart={header.getResizeHandler()}
              />
            ) : null}
          </MaybeStickyTh>
        );
      });
    })
    .filter((header) => header); // remove hidden ones that return void in map

  const renderTable = useCallback(() => {
    const hasRows = table.getRowModel().rows.length > 0;
    if (!loading && !hasRows) {
      let warningText = "";
      if (error) warningText = "Error loading rows. Please try again later or contact support.";
      if (!hasRows) warningText = "No rows available for selected table/filter combination";
      return (
        <Callout
          icon={IconNames.INFO_SIGN}
          intent={error ? Intent.DANGER : Intent.PRIMARY}
          title="No rows to show."
        >
          {warningText}
        </Callout>
      );
    }

    const layoutMode = configOverrides?.layoutMode ?? "fixed";
    const fontSize = configOverrides?.fontSize ?? FontSizes.MEDIUM;

    return (
      <>
        {notesDialogColumn && updateConfig ? (
          <ColumnNotesDialog
            columnConfig={notesDialogColumn}
            isOpen={!!notesDialogColumn}
            onClose={() => setNotesDialogColumn(null)}
            onSubmit={async (newColumn: TableWidgetColumnConfig) => {
              const newPartialConfig = {
                columns: [
                  ...(configOverrides?.columns?.filter((col) => col.id !== newColumn.id) ?? []),
                  newColumn,
                ],
              };
              return await updateConfig(newPartialConfig);
            }}
          />
        ) : null}
        <StyledTable
          style={{
            fontSize: TableFontMap[fontSize],
            fontWeight: 500,
            tableLayout: layoutMode,
            width: layoutMode === "fixed" ? table.getCenterTotalSize() : "100%",
          }}
          $theme={theme}
        >
          <thead>
            <HeaderTr>{columnHeaders}</HeaderTr>
          </thead>
          <TableBody
            excludeFromLoadingCols={excludeFromLoadingCols}
            loading={loading}
            loadingRows={loadingRows}
            models={data}
            pinnedColumns={layoutMode === "fixed" ? pinnedColumns : 0}
            selectedModels={selectedRows}
            sortingState={sortingState}
            styleMatrix={localStyleMatrix}
            table={table}
            theme={theme}
          />
        </StyledTable>
      </>
    );
  }, [
    table,
    loading,
    configOverrides?.layoutMode,
    configOverrides?.fontSize,
    configOverrides?.columns,
    notesDialogColumn,
    updateConfig,
    theme,
    columnHeaders,
    excludeFromLoadingCols,
    loadingRows,
    data,
    pinnedColumns,
    selectedRows,
    sortingState,
    localStyleMatrix,
    error,
  ]);

  return renderTable();
};

const HeaderTr = styled.tr`
  height: 100%;
  width: 100%;
`;

const Resizer = styled.div`
  background-color: grey;
  cursor: col-resize;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  touch-action: none;
  user-select: none;
  width: 2px;
  opacity: 0;
  &:hover {
    opacity: 1;
    width: 5px;
  }
  &.isResizing {
    opacity: 1;
    width: 5px;
  }
`;

Table.displayName = "ModelTable";

export default memo(Table);

const TableBody = React.memo(
  ({
    excludeFromLoadingCols,
    pinnedColumns,
    loading,
    loadingRows = [],
    styleMatrix,
    table,
    theme,
  }: {
    excludeFromLoadingCols?: { [key: string]: boolean };
    loading: boolean;
    loadingRows?: number[];
    models: { id: string; [key: string]: TableValue }[] | null;
    pinnedColumns: number;
    selectedModels?: { id: string }[];
    styleMatrix?: (React.CSSProperties | undefined)[][];
    sortingState?: SortingState;
    table: TTable<TModelHistoryTablePartialModel>;
    theme: TTheme;
  }) => {
    return (
      <tbody>
        {table.getRowModel().rows.map((row, rowIdx) => {
          return (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell, colIdx) => {
                const tdStyle = cell.column.columnDef?.meta?.tdStyle;
                const pinned = colIdx < pinnedColumns;
                const left = cell.column.getStart();
                const isLoadingRow =
                  loadingRows.indexOf(rowIdx) !== -1 && !excludeFromLoadingCols?.[cell.column.id];
                return (
                  <MaybeStickyTd
                    key={cell.id}
                    $left={left}
                    $pinned={pinned}
                    $theme={theme}
                    style={{
                      overflow: "hidden",
                      textAlign: "right", // Needed here for now for consistency with TableRenderer
                      textOverflow: "ellipsis",
                      verticalAlign: "middle",
                      ...(tdStyle && { ...tdStyle }), // column metadata styling
                      ...styleMatrix?.[colIdx]?.[rowIdx], // per cell style matrix
                    }}
                  >
                    {isLoadingRow && colIdx === 0 ? (
                      <Spinner size={SpinnerSize.SMALL} />
                    ) : cell.column.id === "selectModel" ? (
                      <Flex>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Flex>
                    ) : (
                      <span className={loading || isLoadingRow ? Classes.SKELETON : undefined}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </span>
                    )}
                  </MaybeStickyTd>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.excludeFromLoadingCols == nextProps.excludeFromLoadingCols &&
      prevProps.loading == nextProps.loading &&
      prevProps.loadingRows?.length == nextProps.loadingRows?.length &&
      prevProps.models == nextProps.models && // Models needed here to trigger a rerender on local models change
      prevProps.pinnedColumns == nextProps.pinnedColumns &&
      prevProps.selectedModels == nextProps.selectedModels &&
      prevProps.sortingState == nextProps.sortingState &&
      prevProps.styleMatrix == nextProps.styleMatrix &&
      prevProps.table == nextProps.table &&
      prevProps.theme == nextProps.theme
    );
  }
);

TableBody.displayName = "TableBody";

const TableFontMap = {
  Small: 11,
  Medium: 12,
  Large: 13,
};

/**
 * Narrowing function to test whether a value supplied to the table is a fact or a primitive value.
 * Allows us to decide which rendering logic to use and keeps the ts compiler happy.
 * */
export const isRawFactOrOtherValue = (f: TableValue | undefined | null): f is IRawFact =>
  !!(f as IRawFact)?.id;
