import {
  Button,
  ButtonGroup,
  Card,
  CardList,
  Classes,
  Colors,
  H4,
  H5,
  H6,
  InputGroup,
  Intent,
  NonIdealState,
  Tag,
} from "@blueprintjs/core";
import {
  Flex,
  StyledManageCollection,
  StyledScrollContainer,
} from "components/utility/StyledComponents";
import React, { useContext, useRef, useState } from "react";
import styled from "styled-components/macro";
import { MainContainer } from "components/utility/StyledDashboardComponents";
import useSetPageTitle from "hooks/useSetPageTitle";
import { ChevronRight, IconNames } from "@blueprintjs/icons";
import LoadingIndicator from "components/utility/LoadingIndicator";
import usePortfolios from "hooks/usePortfolios";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppContext from "context/appContext";
import usePortfolio from "hooks/usePortfolio";
import UpdateWatchlistModelsDialog from "./UpdateCollectionModelsDialog";
import Widget, { WidgetRef } from "components/dashboard/widget/Widget";
import useWidget from "hooks/dashboard/useWidget";

const PortfoliosManager = () => {
  const { portfolioId } = useParams();
  const { portfolios, loadingPortfolios } = usePortfolios();
  const widgetRef = useRef<WidgetRef>(null);
  const {
    editPortfolio,
    loadingPortfolio,
    matchingModels,
    portfolio: selectedPortfolio,
  } = usePortfolio(portfolioId);

  const { loading: loadingWidget, update, widget } = useWidget(selectedPortfolio?.previewWidgetId);

  const dashboardId = selectedPortfolio?.dashboardId;

  useSetPageTitle("Portfolios");

  const navigate = useNavigate();
  const {
    config: { theme },
  } = useContext(AppContext);

  const [portfolioPredicate, setPortfolioPredicate] = useState("");
  const [updateModelsDialogOpen, setUpdateModelsDialogOpen] = useState(false);

  const filteredPortfolios = portfolios
    ? portfolios
        .filter((portfolio) =>
          portfolio.name.toLowerCase().includes(portfolioPredicate.toLowerCase())
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    : undefined;

  return (
    <MainContainer rows="auto 1fr">
      <Flex
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
        style={{ marginBottom: 15 }}
      >
        <Flex>
          <StyledH4>Manage Portfolios</StyledH4>
          <Tag
            intent={Intent.PRIMARY}
            minimal
            round
            style={{ border: `1px solid ${Colors.BLUE3}` }}
          >
            Visible to everyone
          </Tag>
        </Flex>
        <UpdateWatchlistModelsDialog
          collection={selectedPortfolio}
          editCollection={editPortfolio}
          isOpen={updateModelsDialogOpen}
          loadingCollection={loadingPortfolio}
          matchingModels={matchingModels}
          onClose={() => {
            setUpdateModelsDialogOpen(false);
            widgetRef?.current?.rerunVSLQuery();
          }}
        />
      </Flex>
      <Flex
        flexDirection="row"
        alignItems="flex-start"
        flex="1"
        gap={10}
        style={{ overflow: "hidden", padding: 1 }}
      >
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          fullHeight
          gap={10}
          justifyContent="flex-start"
        >
          <InputGroup
            leftIcon={IconNames.SEARCH}
            onChange={(e) => setPortfolioPredicate(e.target.value)}
            placeholder="Search Portfolios..."
            style={{ width: 300 }}
            type="search"
            value={portfolioPredicate}
          />
          {loadingPortfolios ? (
            <LoadingIndicator
              status={{ intent: Intent.PRIMARY, message: "Loading Portfolios..." }}
            />
          ) : filteredPortfolios && filteredPortfolios.length > 0 ? (
            <CardList compact>
              {filteredPortfolios.map((portfolio) => (
                <Card
                  className={selectedPortfolio?.id === portfolio.id ? "selected" : ""}
                  interactive
                  onClick={() => navigate(`../${portfolio.id}`)}
                  key={portfolio.id}
                  selected={selectedPortfolio?.id === portfolio.id}
                >
                  <Flex flexDirection="row" fullWidth gap={10} justifyContent="space-between">
                    <H6 style={{ margin: 0 }}>{portfolio.name}</H6>
                    <Tag minimal>{portfolio.holdings?.length || 0} Tickers</Tag>
                  </Flex>
                  <ChevronRight className={Classes.TEXT_MUTED} />
                </Card>
              ))}
            </CardList>
          ) : (
            <NonIdealState title="No Portfolios available." />
          )}
        </Flex>
        <Flex
          alignItems="flex-start"
          flex="4 1 1px"
          flexDirection="column"
          fullHeight
          fullWidth
          justifyContent="flex-start"
          style={{ overflow: "hidden" }}
        >
          {portfolioId && selectedPortfolio ? (
            <StyledManageCollection
              className={`${Classes.CARD} ${Classes.ELEVATION_0}`}
              rows="auto 1fr auto"
              $theme={theme}
            >
              <Flex justifyContent="space-between" fullWidth style={{ padding: "10px" }}>
                <Flex gap={10} justifyContent="flex-start">
                  <H5 style={{ margin: 0 }}>{selectedPortfolio?.name}</H5>
                  <Link to={dashboardId || ""}>
                    <Button
                      icon={IconNames.DASHBOARD}
                      intent={Intent.PRIMARY}
                      text="View Dashboard"
                    />
                  </Link>
                </Flex>
                <ButtonGroup>
                  <Button
                    icon={IconNames.TH}
                    text="Update Portfolio Models"
                    onClick={() => setUpdateModelsDialogOpen(true)}
                  />
                </ButtonGroup>
              </Flex>
              <StyledScrollContainer $theme={theme}>
                {!loadingWidget && widget ? (
                  <Widget
                    bordered={false}
                    ref={widgetRef}
                    showTitleRibbon={false}
                    updateWidget={update}
                    widget={{
                      ...widget,
                      createdAt: String(new Date(widget.createdAt)),
                      selectorsHidden: true,
                    }}
                  />
                ) : null}
              </StyledScrollContainer>
            </StyledManageCollection>
          ) : !loadingPortfolio ? (
            <NonIdealState
              description="Select a Portfolio from the list on the left to manage it."
              icon={IconNames.SEARCH}
              title="No Portfolio selected."
            />
          ) : (
            <LoadingIndicator
              status={{ message: "Loading Portfolio...", intent: Intent.PRIMARY }}
            />
          )}
        </Flex>
      </Flex>
    </MainContainer>
  );
};

const StyledH4 = styled(H4)`
  &&& {
    margin: 0;
    margin-right: 10px;
  }
`;

export default PortfoliosManager;
