import {
  Button,
  ButtonGroup,
  Card,
  CardList,
  Classes,
  Colors,
  ControlGroup,
  DialogBody,
  DialogProps,
  DialogStep,
  FormGroup,
  H4,
  H5,
  H6,
  InputGroup,
  Intent,
  MultistepDialog,
  NonIdealState,
  Tag,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import LoadingIndicator from "components/utility/LoadingIndicator";
import { Flex, FullSizeDiv, Grid, NormalAlignDivider } from "components/utility/StyledComponents";
import { MainContainer } from "components/utility/StyledDashboardComponents";
import { DASHBOARD_LINK_ROUTE, DASHBOARD_ROUTE, EMethods, EStatus } from "constants/apiConstants";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import useListConfigs, { Configs } from "hooks/useListConfigs";
import useSetPageTitle from "hooks/useSetPageTitle";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { KeyedMutator } from "swr";
import { IResponse } from "types";
import { Dashboard } from "types/dashboard";
import { View } from "types/view";
import api from "utils/api";
import { multiToaster, singleToaster } from "utils/toaster";

interface DashboardsProps {
  type: "User" | "Shared";
}

/**
 * Top level container for a set of dashboards, either
 */
const DashboardManager: React.FC<DashboardsProps> = () => {
  const navigate = useNavigate();
  const [dashboardSearchQuery, setDashboardSearchQuery] = useState("");
  const [createDashboardDialogOpen, setCreateDashboardDialogOpen] = useState(false);
  const { dashboards, isLoading, mutateConfigs } = useListConfigs();

  const [searchParams, setSearchParams] = useSearchParams();

  useSetPageTitle("Dashboards");

  useEffect(() => {
    const openDialog = searchParams.get("openCreateDialog");
    if (openDialog === "1") {
      setCreateDashboardDialogOpen(true);
      setSearchParams({}, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  const deleteDashboard = async (dashboardId: string) => {
    if (!dashboards) return;
    if (confirm("Permanently delete Dashboard?")) {
      const response = await api(DASHBOARD_ROUTE, { dashboardId, method: EMethods.DELETE });
      if (response.status === EStatus.success) {
        multiToaster.show({ intent: Intent.PRIMARY, message: "Deleted dashboard." });
      } else {
        multiToaster.show({ intent: Intent.WARNING, message: response.message });
      }
      mutateConfigs();
    }
  };

  const closeCreateDashboardDialog = () => {
    setCreateDashboardDialogOpen(false);
  };

  const filteredAndSortedDashboards = dashboards
    ? dashboards
        .filter((dashboard) =>
          dashboard.name.toLowerCase().includes(dashboardSearchQuery.toLowerCase())
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    : undefined;

  const hasDashboards = dashboards && dashboards.length > 0;

  return (
    <MainContainer gap="10px" rows="auto auto 1fr">
      <CreateDashboardDialog
        handleClose={closeCreateDashboardDialog}
        isOpen={createDashboardDialogOpen}
        mutateConfigs={mutateConfigs}
        onClose={closeCreateDashboardDialog}
      />
      <Flex
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
        style={{ marginBottom: 15 }}
      >
        <Flex>
          <H4 style={{ marginBottom: 0, marginRight: 10 }}>Manage Dashboards</H4>
          <Tag
            round
            minimal
            intent={Intent.PRIMARY}
            style={{ border: `1px solid ${Colors.BLUE3}` }}
          >
            Visible to everyone
          </Tag>
        </Flex>
        <Button
          active={createDashboardDialogOpen}
          icon={IconNames.ADD}
          intent={Intent.SUCCESS}
          onClick={() => setCreateDashboardDialogOpen(true)}
          style={{ justifySelf: "start" }}
          text="New Dashboard"
        />
      </Flex>
      <InputGroup
        leftIcon={IconNames.SEARCH}
        onChange={(e) => setDashboardSearchQuery(e.currentTarget.value)}
        placeholder="Search Dashboards..."
        style={{ width: 300 }}
        type="search"
        value={dashboardSearchQuery}
      />
      <div style={{ minHeight: 0, overflow: "auto", padding: 1 }}>
        <Flex
          alignItems="flex-start"
          flexWrap="wrap"
          gap={10}
          fullWidth
          justifyContent="flex-start"
        >
          {isLoading ? (
            <LoadingIndicator
              status={{ intent: Intent.PRIMARY, message: "Loading dashboards..." }}
            />
          ) : null}
          {filteredAndSortedDashboards && filteredAndSortedDashboards.length > 0 ? (
            <CardList compact>
              {filteredAndSortedDashboards.map((dashboard) => {
                return (
                  <Card key={dashboard.id}>
                    <Flex flexDirection="column" fullWidth gap={10}>
                      <FullSizeDiv fullWidth>
                        <Flex fullWidth justifyContent="space-between">
                          <H6>{dashboard.name}</H6>
                          <Tag minimal>{dashboard.views?.length || 0} Views</Tag>
                        </Flex>
                      </FullSizeDiv>
                      <Flex fullWidth justifyContent="space-between">
                        <p className={Classes.TEXT_MUTED}>
                          Created: {new Date(dashboard.createdAt).toLocaleDateString()}
                        </p>
                        <ButtonGroup>
                          <Button
                            intent={Intent.DANGER}
                            icon={IconNames.DELETE}
                            minimal
                            onClick={() => deleteDashboard(dashboard.id)}
                            text="Delete"
                          />
                          <Button
                            intent={Intent.PRIMARY}
                            onClick={() => navigate(dashboard.id)}
                            rightIcon={IconNames.OPEN_APPLICATION}
                            text="Open"
                          />
                        </ButtonGroup>
                      </Flex>
                    </Flex>
                  </Card>
                );
              })}
            </CardList>
          ) : (
            hasDashboards && (
              <NonIdealState
                action={<Button onClick={() => setDashboardSearchQuery("")} text="Clear Search" />}
                icon={IconNames.SEARCH}
                title="No Dashboards matching search query."
              />
            )
          )}
        </Flex>
      </div>
    </MainContainer>
  );
};

const CreateDashboardDialog: React.FC<
  DialogProps & {
    handleClose: () => void;
    mutateConfigs: KeyedMutator<IResponse<Configs>>;
  }
> = ({ handleClose, mutateConfigs, ...dialogProps }) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const [dashboardName, setDashboardName] = useState("");
  const [selectedViews, setSelectedViews] = useState<View[]>([]);

  const createDashboardAndAddViews = async () => {
    const createDashboardResponse = await api<Dashboard>(DASHBOARD_ROUTE, {
      method: EMethods.POST,
      body: {
        name: dashboardName,
      },
    });
    if (createDashboardResponse.status === EStatus.success && selectedViews.length > 0) {
      multiToaster.show({ intent: Intent.PRIMARY, message: "Dashboard created. Adding views..." });
      // add views
      const dashboardId = createDashboardResponse.data.id;
      const viewLinkResponse = await api(DASHBOARD_LINK_ROUTE, {
        method: EMethods.PATCH,
        dashboardId,
        body: {
          viewIds: selectedViews.map((view) => {
            return view.id;
          }),
        },
      });
      if (viewLinkResponse.status === EStatus.success) {
        singleToaster.show({ intent: Intent.SUCCESS, message: "Successfully added views." });
        handleClose();
      } else {
        singleToaster.show({ message: viewLinkResponse.error, intent: Intent.WARNING });
      }
      setDashboardName("");
    } else if (createDashboardResponse.status === EStatus.success) {
      singleToaster.show({
        message: `Created Dashboard: ${dashboardName}.`,
        intent: Intent.SUCCESS,
      });
    } else {
      multiToaster.show({ intent: Intent.WARNING, message: createDashboardResponse.error });
    }
    mutateConfigs();
  };

  return (
    <MultistepDialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      icon={IconNames.ADD}
      finalButtonProps={{
        disabled: !dashboardName,
        text: "Create Dashboard",
        onClick: createDashboardAndAddViews,
      }}
      title="Create Dashboard"
      {...dialogProps}
    >
      <DialogStep
        id="setName"
        nextButtonProps={{
          disabled: !dashboardName,
          icon: IconNames.CIRCLE_ARROW_RIGHT,
          intent: Intent.PRIMARY,
          text: "Set Views",
        }}
        panel={<NamePanel dashboardName={dashboardName} setDashboardName={setDashboardName} />}
        title="Name"
      />
      <DialogStep
        backButtonProps={{ icon: IconNames.CIRCLE_ARROW_LEFT, text: "Back" }}
        id="views"
        panel={
          <ViewPanel
            dashboardName={dashboardName}
            setSelectedViews={setSelectedViews}
            selectedViews={selectedViews}
          />
        }
        title="Add Views"
      />
    </MultistepDialog>
  );
};

const ViewPanel: React.FC<{
  dashboardName: string;
  selectedViews: View[];
  setSelectedViews: Dispatch<SetStateAction<View[]>>;
}> = ({ dashboardName, selectedViews, setSelectedViews }) => {
  const [viewQuery, setViewQuery] = useState("");
  const { isLoading, views } = useListConfigs();
  const sortedViews =
    views &&
    [...views].sort((a, b) => {
      if (a.name === "Blank View" || b.name === "Blank View") {
        return -1;
      }
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

  const removeSelectedView = (i: number) => {
    const nextViews = [...selectedViews];
    nextViews.splice(i, 1);
    setSelectedViews(nextViews);
  };

  return (
    <DialogBody>
      <Grid cols="1fr auto 1fr" rows="auto 1fr">
        <div>
          <H5>{dashboardName}</H5>
          <NormalAlignDivider style={{ margin: "10px 5px" }} />
          <Flex
            alignItems="flex-start"
            fullWidth
            justifyContent="space-between"
            style={{ marginBottom: 10 }}
          >
            <H6 style={{ margin: 0 }}>Add from View Template</H6>
            <Link target="_blank" to="/settings/views">
              <Button icon={IconNames.SHARE} minimal small />
            </Link>
          </Flex>
          {isLoading ? <LoadingIndicator status={{ message: "Loading Views..." }} /> : null}
          <InputGroup
            leftIcon={IconNames.SEARCH}
            onChange={(e) => setViewQuery(e.currentTarget.value)}
            placeholder="Search views..."
            style={{ marginBottom: 10 }}
            type="Search"
            value={viewQuery}
          />
          <div style={{ maxHeight: 400, overflow: "auto", padding: 1 }}>
            {sortedViews
              ? sortedViews
                  .filter((view) => view.name.toLowerCase().includes(viewQuery.toLowerCase()))
                  .map((view) => {
                    const selected =
                      selectedViews.findIndex((selView) => selView.id === view.id) !== -1;

                    return (
                      <Card key={view.id} style={{ marginBottom: 10, padding: 5 }}>
                        <Flex fullWidth justifyContent="space-between" style={{ margin: 0 }}>
                          <Flex flexDirection="column" alignItems="flex-start">
                            <H6 style={{ margin: "0 0 0 5px" }}>{view.name}</H6>
                            <p style={{ margin: "0 0 0 5px" }}>{view.description}</p>
                          </Flex>
                          <ControlGroup>
                            <Link target="_blank" to={`/settings/views/${view.id}`}>
                              <Button icon={IconNames.DOCUMENT_OPEN} minimal />
                            </Link>
                            <Button
                              disabled={selected}
                              icon={IconNames.ADD}
                              intent={Intent.SUCCESS}
                              minimal
                              onClick={() => {
                                if (!selected) {
                                  setSelectedViews(selectedViews.concat(view));
                                }
                              }}
                            />
                          </ControlGroup>
                        </Flex>
                      </Card>
                    );
                  })
              : null}
          </div>
        </div>
        <NormalAlignDivider style={{ margin: "5px 10px" }} />
        <div>
          <H6>Added Views:</H6>
          {selectedViews.length > 0
            ? selectedViews.map((view, i) => {
                return (
                  <Card key={`${view.name}-${i}`} style={{ marginBottom: 10, padding: 5 }}>
                    <Flex justifyContent="space-between" role="listitem">
                      <H6 style={{ margin: "0 0 0 5px" }}>{view.name}</H6>
                      <Button
                        icon={IconNames.REMOVE}
                        intent={Intent.DANGER}
                        minimal
                        onClick={() => removeSelectedView(i)}
                      />
                    </Flex>
                  </Card>
                );
              })
            : null}
        </div>
      </Grid>
    </DialogBody>
  );
};

const NamePanel: React.FC<{
  dashboardName: string;
  setDashboardName: React.Dispatch<React.SetStateAction<string>>;
}> = ({ dashboardName, setDashboardName }) => {
  return (
    <DialogBody>
      <FormGroup label="Dashboard Name:">
        <InputGroup
          onChange={(e) => setDashboardName(e.currentTarget.value)}
          value={dashboardName}
        />
      </FormGroup>
    </DialogBody>
  );
};

export default DashboardManager;
