import React, {
  FunctionComponentElement,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { Intent } from "@blueprintjs/core";
import DataGrid, {
  CellStyles,
  Column,
  DataGridHandle,
  FillEvent,
  Position,
  RowsChangeData,
  TextEditor,
} from "@vsjs/react-data-grid";
import "styles/val/data-entry/data-entry-table.scss";
import { DataEntryEditMode } from "constants/dataEntryUIConstants";
import LoadingIndicator from "components/utility/LoadingIndicator";
import {
  DATAENTRY_DEFAULT_COLUMN_WIDTH,
  DATAENTRY_DEFAULT_MAX_COL_WIDTH,
  DATAENTRY_DEFAULT_MIN_COL_WIDTH,
  DATAENTRY_DEFAULT_ROW_HEADER_WIDTH,
  Themes,
} from "constants/uiConstants";
import { noop } from "utils/generic";
import { ICtxMenuPos, ITableCoordinates, ITableSelection } from "types/vsTable";
import AppContext from "context/appContext";

const TableContainer = styled.div`
  height: 100%;
  & > .rdg {
    height: 100%;
  }
`;

declare global {
  interface Window {
    vsContextMenuTarget: HTMLElement;
  }
}

interface IVSTableProps<Row> {
  autoAdjustColsWidth?: boolean;
  columnHeaders: string[];
  columnWidths?: number[];
  containerWidth?: number;
  contextMenu?: JSX.Element;
  dashboardWidth?: number;
  draggableCells?: boolean;
  editableRowHeaders?: boolean;
  editColumnWidth?: (idx: number, width?: number) => void;
  editMode: string;
  handleDrag?: (data: FillEvent<Row>) => null;
  handleRowHeaderUpdate?: (rows: Row[], edits: RowsChangeData<Row, unknown>) => void;
  locked?: boolean;
  onCellInput?: (e: KeyboardEvent) => void;
  fwdRef?: React.RefObject<IVSTableHandle>;
  reorderableRows?: boolean;
  rows: Row[];
  styledRegions?: CellStyles[][];
  updateEditMode?: (editType: string, commit: boolean) => void;
  updateFocusedCell?: (
    position: ITableCoordinates,
    selection: ITableSelection[],
    header?: boolean
  ) => void;
}

export interface IVSTableHandle {
  resetColWidths: () => void;
  focusGrid: () => void;
  selectCell: (position: Position, enableEditor: boolean) => void | undefined;
  clientWidth: number | undefined;
}

function VSTable<Row>({
  autoAdjustColsWidth = false,
  columnHeaders,
  columnWidths,
  containerWidth,
  contextMenu,
  dashboardWidth,
  draggableCells = false,
  editableRowHeaders = false,
  editColumnWidth,
  editMode,
  handleDrag,
  handleRowHeaderUpdate,
  locked = false,
  onCellInput,
  fwdRef,
  reorderableRows = false,
  rows,
  styledRegions,
  updateEditMode,
  updateFocusedCell,
}: IVSTableProps<Row>): JSX.Element {
  const {
    config: { theme },
  } = useContext(AppContext);

  const [ctxMenu, setCtxMenu] = useState<FunctionComponentElement<{ pos: ICtxMenuPos }> | null>(
    null
  );
  const [tableWidth, setTableWidth] = useState<number | undefined>();
  const [activeColumnWidths, setActiveColumnWidths] = useState<number[]>(columnWidths || []);
  const rdgRef = useRef<DataGridHandle>(null);

  // calculate table width considering scroll bar width
  useEffect(() => {
    if (!containerWidth || !dashboardWidth) return;
    let scrollBarWidth;
    if (rdgRef.current?.element?.clientWidth)
      scrollBarWidth = containerWidth - rdgRef.current?.element?.clientWidth - 2;
    setTableWidth(scrollBarWidth ? dashboardWidth - scrollBarWidth : dashboardWidth);
  }, [containerWidth, dashboardWidth]);

  useImperativeHandle(fwdRef, () => ({
    resetColWidths: resetColWidths,
    focusGrid: rdgRef.current?.focusGrid || noop,
    selectCell: rdgRef.current?.selectCell || noop,
    scrollToRow: rdgRef.current?.scrollToRow || noop,
    clientWidth: rdgRef.current?.element?.clientWidth,
  }));

  const closeContextMenu = useCallback((e: KeyboardEvent | MouseEvent) => {
    const KeyboardEvent = e as KeyboardEvent;
    if (KeyboardEvent?.code && KeyboardEvent.code !== "Escape") return;
    window.vsContextMenuTarget.style.overflow = "auto";
    setCtxMenu(null);
  }, []);

  useEffect(() => {
    return () => {
      document.removeEventListener("click", closeContextMenu);
      document.removeEventListener("keyup", closeContextMenu);
    };
  }, [closeContextMenu]);

  useEffect(() => {
    if (!onCellInput) return;
    document.addEventListener("keydown", onCellInput);

    return () => {
      document.removeEventListener("keydown", onCellInput);
    };
  }, [onCellInput]);

  const autoAdjust = useCallback(
    (widths: number[]) => {
      if (!tableWidth) return widths;
      const cw = columnWidths || [];

      const newColWidths = [];
      let setColWidths = 0;
      let nonSetColWidthsCount = columnHeaders.length + 1;
      cw.forEach((colWidth, idx) => {
        newColWidths[idx] = colWidth;
        if (colWidth) {
          setColWidths += colWidth;
          nonSetColWidthsCount -= 1;
        }
      });

      if (!cw[0]) {
        newColWidths[0] = DATAENTRY_DEFAULT_ROW_HEADER_WIDTH;
        setColWidths += DATAENTRY_DEFAULT_ROW_HEADER_WIDTH;
        nonSetColWidthsCount -= 1;
      }

      const availableWidth = tableWidth - setColWidths - 2;

      const nonSetColSize = Math.min(
        DATAENTRY_DEFAULT_MAX_COL_WIDTH,
        availableWidth / nonSetColWidthsCount
      );

      for (let i = 1; i < columnHeaders.length + 1; i++) {
        if (!newColWidths[i]) newColWidths[i] = nonSetColSize;
      }

      return newColWidths;
    },
    [columnHeaders.length, columnWidths, tableWidth]
  );

  useEffect(() => {
    setActiveColumnWidths(
      autoAdjustColsWidth ? autoAdjust(columnWidths || []) : columnWidths || []
    );
  }, [autoAdjust, autoAdjustColsWidth, columnHeaders.length, columnWidths, tableWidth]);

  const resetColWidths = useCallback(() => {
    if (!tableWidth) return;
    if (editColumnWidth) editColumnWidth(-1);
    else {
      // uncontrolled version
      const newWidths = [DATAENTRY_DEFAULT_ROW_HEADER_WIDTH];

      const evenWidth = Math.min(
        DATAENTRY_DEFAULT_MAX_COL_WIDTH,
        (tableWidth - DATAENTRY_DEFAULT_ROW_HEADER_WIDTH - 2) / columnHeaders.length
      );

      columnHeaders.forEach(() => {
        newWidths.push(evenWidth);
      });

      setActiveColumnWidths(newWidths);
    }
  }, [columnHeaders, tableWidth, editColumnWidth]);

  const handleColumnResize = useCallback(
    (idx: number, width: number, mouseDown: boolean) => {
      if (!editColumnWidth) return; // uncontrolled
      if (!mouseDown) {
        editColumnWidth(idx, Math.max(width, DATAENTRY_DEFAULT_MIN_COL_WIDTH));
      }
    },
    [editColumnWidth]
  );

  const handleSelectionChange = useCallback(
    (data: Position) => {
      // on click in formula mode, go to normal mode
      if (updateEditMode && editMode === DataEntryEditMode.FORMULA) {
        updateEditMode(DataEntryEditMode.NORMAL, true);
      }
      let rowStart, rowEnd, colStart, colEnd;
      if (data.sel) {
        rowStart = data.sel.rowStart;
        rowEnd = data.sel.rowEnd;
        colStart = data.sel.colStart <= 0 ? 0 : data.sel.colStart - 1;
        colEnd = data.sel.colEnd - 1;
      } else {
        rowStart = data.rowIdx;
        rowEnd = data.rowIdx;
        colStart = data.idx <= 0 ? 0 : data.idx - 1;
        colEnd = data.idx - 1;
      }
      if (updateFocusedCell)
        updateFocusedCell(
          {
            row: data.rowIdx,
            col: data.idx ? data.idx - 1 : 0,
          },
          [
            {
              rows: [rowStart, rowEnd],
              cols: [colStart, colEnd],
            },
          ],
          data.idx === 0
        );
    },
    [editMode, updateEditMode, updateFocusedCell]
  );

  const createContextMenu = useCallback(
    (e) => {
      if (!contextMenu) return;

      e.preventDefault();
      const target = e.target as HTMLElement;
      const currentTarget = e.currentTarget as HTMLElement;
      if (!target || !currentTarget) return;
      if (
        target &&
        (target.getAttribute("role") !== "gridcell" || target.getAttribute("aria-colindex") === "1")
      )
        return;
      document.addEventListener("click", closeContextMenu, { once: true });
      document.addEventListener("keyup", closeContextMenu, { once: true });

      const currentTargetChild = currentTarget.children[0] as HTMLElement;
      currentTargetChild.style.overflow = "hidden";
      window.vsContextMenuTarget = currentTargetChild;
      const ctxMenuPos = {
        posX: e.clientX,
        posY: e.clientY,
        posClientX: e.clientX,
        posClientY: e.clientY,
      };
      const elem = React.cloneElement(contextMenu, {
        pos: ctxMenuPos,
        close: () => {
          window.vsContextMenuTarget.style.overflow = "auto";
          setCtxMenu(null);
        },
      });
      setCtxMenu(elem);
    },
    [closeContextMenu, contextMenu]
  );

  const scrollConfig = useMemo(() => {
    return { maxPixels: 60, baseSpeed: 10, acceleration: 1.01 };
  }, []);

  const columns = useMemo(() => {
    if (!columnHeaders) return [];
    const cols: Column<Row>[] = columnHeaders.map((head, idx) => ({
      key: head,
      name: head,
      width: activeColumnWidths?.[idx + 1] || DATAENTRY_DEFAULT_COLUMN_WIDTH,
      resizable: true,
    }));
    cols.unshift({
      key: "rowHeader",
      name: "",
      width: activeColumnWidths?.[0] || DATAENTRY_DEFAULT_ROW_HEADER_WIDTH,
      frozen: true,
      resizable: true,
      editor: editableRowHeaders && !locked ? TextEditor : null,
    });
    return cols;
  }, [activeColumnWidths, columnHeaders, editableRowHeaders, locked]);

  if (!rows)
    return (
      <LoadingIndicator loading status={{ intent: Intent.PRIMARY, message: "LOADING DATA" }} />
    );
  else
    return (
      <TableContainer onContextMenu={contextMenu ? createContextMenu : undefined}>
        <DataGrid<Row>
          cellStyles={styledRegions}
          className={theme === Themes.LIGHT ? "rdg-light" : "rdg-dark"}
          columns={columns}
          onFill={
            draggableCells && editMode === DataEntryEditMode.NORMAL && !locked ? handleDrag : null
          }
          onRowsChange={handleRowHeaderUpdate}
          onSelectedCellChange={handleSelectionChange}
          onColumnResize={handleColumnResize}
          reorderRows={reorderableRows && !locked}
          ref={rdgRef}
          rows={rows}
          scrollDragOptions={scrollConfig}
        />
        {ctxMenu ? ctxMenu : null}
      </TableContainer>
    );
}

VSTable.displayName = "VSTable";

export default VSTable;
