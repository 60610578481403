import React from "react";
import { Classes } from "@blueprintjs/core";

interface ICustomIconProps {
  pathsOrSVG: string[] | JSX.Element;
  iconName: string;
  large?: boolean;
  customViewBox?: string | undefined;
}

const CustomIcon: React.FC<ICustomIconProps> = ({
  pathsOrSVG,
  iconName,
  large = false,
  customViewBox,
}) => {
  if (Array.isArray(pathsOrSVG)) {
    return (
      <span className={Classes.ICON}>
        <svg
          viewBox={customViewBox ? customViewBox : large ? "0 0 20 20" : "0 0 16 16"}
          width={large ? 20 : 16}
          height={large ? 20 : 16}
          xmlns="http://www.w3.org/2000/svg"
        >
          <desc>{iconName}</desc>
          {pathsOrSVG.map((p, i) => (
            <path d={p} key={`${iconName}-${i}`} />
          ))}
        </svg>
      </span>
    );
  }
  return pathsOrSVG;
};

export default CustomIcon;
