import { Colors } from "@blueprintjs/core";
import colors from "styles/colors.module.scss";

export const UPDATE_HISTORICAL_PRICE_CHART_RANGE = "UPDATE_HISTORICAL_PRICE_CHART_RANGE";

// HISTORIC PRICE DATA
export const FETCH_HISTORICAL_SHARE_PRICE_DATA = "FETCH_HISTORICAL_SHARE_PRICE_DATA";
export const FETCH_HISTORICAL_SHARE_PRICE_DATA_SUCCESS =
  "FETCH_HISTORICAL_SHARE_PRICE_DATA_SUCCESS";
export const FETCH_HISTORICAL_SHARE_PRICE_DATA_FAILURE =
  "FETCH_HISTORICAL_SHARE_PRICE_DATA_FAILURE";
export const FLUSH_SHARE_PRICE_REDUCER = "FLUSH_SHARE_PRICE_REDUCER";
export const UPDATE_PREVIEW_CHART_TICKER = "UPDATE_PREVIEW_CHART_TICKER";

// DISPLAY
export const UPDATE_ACTIVE_SHARE_PRICE_LINES = "UPDATE_ACTIVE_SHARE_PRICE_LINES";
export const TOGGLE_AUTO_SHARE_PRICE_LINE = "TOGGLE_AUTO_SHARE_PRICE_LINE";
export const FRIDAY = 5;

// CASE PREFERNCE ORDER
export const preferenceOrder = ["Base", "Bull", "Bear", "Preview"];

// Legend colors
export const graphColors = {
  Actual: colors.blue5,
  Bull: colors.green4,
  Base: colors.blue1,
  Bear: colors.red4,
  Preview: colors.orange4,
};

export const GraphSeriesColors = [
  Colors.CERULEAN3,
  Colors.FOREST3,
  Colors.GOLD3,
  Colors.ROSE3,
  Colors.INDIGO3,
  Colors.CERULEAN5,
  Colors.FOREST5,
  Colors.GOLD5,
  Colors.ROSE5,
  Colors.INDIGO5,
  Colors.CERULEAN1,
  Colors.FOREST1,
  Colors.GOLD1,
  Colors.ROSE1,
  Colors.INDIGO1,
  Colors.TURQUOISE3,
  Colors.LIME3,
  Colors.SEPIA3,
  Colors.VERMILION3,
  Colors.VIOLET3,
  Colors.TURQUOISE5,
  Colors.LIME5,
  Colors.SEPIA5,
  Colors.VERMILION5,
  Colors.VIOLET5,
  Colors.TURQUOISE1,
  Colors.LIME1,
  Colors.SEPIA1,
  Colors.VERMILION1,
  Colors.VIOLET1,
];
