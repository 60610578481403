import { TCardinality } from "types/vsTable";
import { TDraftModule, THeaderType, TImportViewMode } from "types/import";
import { EUnit } from "../types/format";

export const CoordRegexp = /^[A-Z]{1,}\d{1,}$/;

export const CoordValidationErrors = {
  InvalidString: "The supplied coordinate is invalid!",
  OutOfBounds: "The supplied coordinate is outside the sheet bounds!",
  None: "",
};

export const SelectionCardinality: { [key: string]: TCardinality } = {
  ROW: "ROW",
  COL: "COL",
};

export const HeaderTypes: { [key: string]: THeaderType } = {
  LineItems: "line_items",
  TimeSeries: "time_series",
};

export const ViewModes: { [key: string]: TImportViewMode } = {
  Sheet: "sheet",
  Preview: "preview",
};

export const TimeSeriesStates = {
  Valid: "Time series valid",
  Invalid: "invalid timeseries data",
};

export const DraftModule: TDraftModule = {
  columnHeaders: {
    cellRange: ["None", "None"],
    headerType: HeaderTypes.TimeSeries,
    breaks: {
      direction: SelectionCardinality.COL,
      coordinateRanges: [],
    },
    edits: [],
    headerID: null,
    rangeType: "start_stop",
  },
  rowHeaders: {
    cellRange: ["None", "None"],
    headerType: HeaderTypes.LineItems,
    breaks: {
      direction: SelectionCardinality.ROW,
      coordinateRanges: [],
    },
    edits: [],
    headerID: null,
    rangeType: "start_stop",
  },
  committed: false,
  timeSeries: {
    format: "PPYYYY",
  },
  unlinked: true,
  units: EUnit.RAW,
};
