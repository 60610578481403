import { useCallback, useMemo } from "react";
import useSWR from "swr";
import { IAppConfig, IResponse } from "types";
import { useApiCallback } from "hooks/useApiCallback";
import {
  DELETE_CONFIG_ROUTE,
  EMethods,
  EStatus,
  PULL_CONFIG_ROUTE,
  PUSH_CONFIG_ROUTE,
  Status,
} from "constants/apiConstants";
import { defaultAppConfig } from "context/appContext";
import api, { swrApi } from "utils/api";
import { multiToaster } from "utils/toaster";
import { ITableConfigColumn } from "types/modelHistory";
import { defaultModelConfig, IModelConfig } from "context/modelContext";

const fallback: {
  [key: string]: IAppConfig | { availableFields: ITableConfigColumn[] } | IModelConfig;
} = {
  appConfig: defaultAppConfig,
  availableFields: { availableFields: [] },
  modelConfig: defaultModelConfig,
};

interface IHookReturn<ConfigType> {
  config: ConfigType | null;
  defaultConfig: ConfigType | null;
  fetchingConfig: boolean;
  isDefault: boolean;
  resetKeyedConfig: () => void;
  savingConfig: boolean;
  update: (nextConfig: Partial<ConfigType>) => void;
}

/**
 * Returns an object containing data for the specified key and a curried set function to update that variable on the server.
 */
const useSetAndGetConfig = <ConfigType,>(
  key: "appConfig" | "modelConfig" | "availableFields", // The key to fetch, i.e. appConfig
  id?: string | null, // The value for the id, will assume current user if none provided
  idType?: string | null // User or model or none, defaults to "userID"
): IHookReturn<ConfigType> => {
  // If id & idType properties are present then we know we are getting/setting keyed configs
  const fetchKeyedConfig = !!(id && idType);

  const {
    data: defaultConfig,
    isLoading: loadingDefaultConfig,
    mutate: mutateDefaultConfig,
  } = useSWR<IResponse<ConfigType | null>>([PULL_CONFIG_ROUTE, { key }], swrApi, {
    revalidateOnFocus: false,
  });

  const {
    data: keyedConfig,
    isLoading: loadingKeyedConfig,
    mutate: mutateKeyedConfig,
  } = useSWR<IResponse<ConfigType | null>>(
    fetchKeyedConfig ? [PULL_CONFIG_ROUTE, { key, [idType]: id }] : null,
    swrApi,
    { revalidateOnFocus: false }
  );

  const { callback: saveConfigCallback, fetching: savingConfig } = useApiCallback<ConfigType>();

  const { config, isDefault } = useMemo(() => {
    if (keyedConfig?.data && fetchKeyedConfig) {
      // Keyed config si requested and present, i.e. userID or modelID
      return { config: keyedConfig?.data || null, isDefault: false };
    } else if (!fetchKeyedConfig || (keyedConfig && !keyedConfig?.data && defaultConfig?.data)) {
      // No keyed config but default server config present, revert to that.
      return { config: defaultConfig?.data || null, isDefault: true };
    } else if (keyedConfig && !keyedConfig?.data && defaultConfig && !defaultConfig?.data) {
      // Both config fetches have failed, revert to defaults.
      return { config: fallback[key] as ConfigType, isDefault: true };
    }
    // Otherwise return null;
    return { config: null, isDefault: false };
  }, [defaultConfig, fetchKeyedConfig, key, keyedConfig]);

  const update = useCallback(
    (partial: Partial<ConfigType>) => {
      if (fetchKeyedConfig) {
        const nextConfig = {
          ...keyedConfig?.data,
          ...partial,
        };
        mutateKeyedConfig(
          { status: keyedConfig?.status || EStatus.success, data: nextConfig as ConfigType },
          { revalidate: false }
        );
      } else {
        const nextConfig = {
          ...defaultConfig?.data,
          ...partial,
        };
        mutateDefaultConfig(
          { status: defaultConfig?.status || EStatus.success, data: nextConfig as ConfigType },
          { revalidate: false }
        );
      }
      const nextConfig = { ...config, ...partial };
      saveConfigCallback(PUSH_CONFIG_ROUTE, {
        body: nextConfig,
        key,
        method: EMethods.POST,
        ...(idType && { [idType]: id }),
      });
    },
    [
      config,
      defaultConfig,
      fetchKeyedConfig,
      id,
      idType,
      key,
      keyedConfig,
      mutateKeyedConfig,
      mutateDefaultConfig,
      saveConfigCallback,
    ]
  );

  const resetKeyedConfig = useCallback(() => {
    (async () => {
      const res = await api(DELETE_CONFIG_ROUTE, {
        method: EMethods.DELETE,
        key,
        ...(fetchKeyedConfig && { [idType]: id }),
      });
      if (res.status === Status.SUCCESS) {
        mutateKeyedConfig();
      } else {
        multiToaster.show({ message: "Unable to " });
      }
    })();
  }, [fetchKeyedConfig, id, idType, key, mutateKeyedConfig]);

  return {
    config,
    defaultConfig: defaultConfig?.data || (fallback[key] as ConfigType),
    fetchingConfig: loadingDefaultConfig || loadingKeyedConfig,
    isDefault,
    resetKeyedConfig,
    savingConfig: savingConfig,
    update,
  };
};

export default useSetAndGetConfig;
