import * as consts from "constants/modelConstants";
import * as dataEntryConsts from "constants/dataEntryConstants";
import { getValidJSON } from "utils/api";
import {
  cleanEdges,
  deepMerge,
  factArrayToObject,
  genTempFact,
  genTempLineItem,
  loadAllDependantCells,
  mergeEntities,
  mergeModelEntities,
  orderModelLis,
  validateFacts,
  validateLineItems,
  validateModules,
} from "utils/data";
import { schemaLevel, vsNormalize } from "utils/normalizr";
import {
  removeInvalidDepsAndPrecs,
  safelyDeleteColumn,
  safelyDeleteLineItem,
  swapReorder,
  updateModulePeriods,
} from "utils/modelGrid";
import { refactorLineItemDeps } from "utils/formulas";
import { produce } from "immer";
import range from "lodash.range";
import { isDebugMode } from "components/dashboard/model/ModelDashboard";

const DEFAULT_STATE = {
  loading: false,
  error: undefined,
  allTags: [],
  cases: {},
  currentCaseUid: null,
  currentModule: null,
  gridChangeOperations: [],
  keyMetrics: ["Current share price", "Implied share price", "Implied premium"],
  logoUrl: undefined,
  modelInfo: {},
  parsedSources: {},
  sources: [],
  ticker: "",
  warnings: [],
};

const runValidator = (draft, entities) => {
  draft.warnings = [];

  const modules = entities ? entities.modules : draft.modules;
  const lineItems = entities ? entities.lineItems : draft.lineItems;
  const facts = entities ? entities.facts : draft.facts;

  validateModules(modules, draft.warnings);
  validateLineItems(modules, lineItems, draft.warnings);
  validateFacts(modules, lineItems, facts, draft.warnings);
};

export const modelReducer = produce((draft, action) => {
  switch (action.type) {
    case consts.PULL_MODEL: {
      delete draft.error;
      draft.loading = true;
      break;
    }

    case consts.PULL_MODEL_SUCCESS: {
      const {
        data: { model },
      } = action.payload;
      draft.loading = false;
      delete draft.error;
      draft.modelInfo = { ...model };
      delete draft.modelInfo.edges;
      draft.currentCase = model.edges.cases[0];

      cleanEdges(model);

      const normalizedCase = vsNormalize({ cases: model.cases[0] }, schemaLevel.CASE);
      const { cases, facts, lineItems, modules } = normalizedCase.entities;

      // Need to do a deep-ish merge here as the case is set on model info success.
      const nextCases = Object.keys(cases)
        .map((cse) => ({
          ...draft.cases[cse],
          ...cases[cse],
          keyMetricUids: {},
        }))
        .reduce((acc, curr) => {
          return {
            ...acc,
            [curr.uid]: curr,
          };
        }, {});

      // Cache the uids of the key metric facts on the case object to save expensive ops on edit
      Object.values(nextCases).forEach((cse) => {
        Object.values(lineItems).forEach((li) => {
          if (!li.tags) return;
          const matchedTag = li.tags.find((tag) => {
            return (
              li.caseUid === cse.uid &&
              draft.keyMetrics.findIndex((metric) => {
                return tag.toLowerCase().includes(metric.toLowerCase());
              }) !== -1
            );
          });
          if (matchedTag) {
            li.facts.forEach((fact) => {
              if (facts[fact].period === cse.startPeriod) {
                cse.keyMetricUids[matchedTag] = fact;
              }
            });
          }
        });
      });

      if (isDebugMode()) runValidator(draft, normalizedCase.entities);

      draft.cases = nextCases;
      draft.facts = { ...draft.facts, ...facts };
      draft.lineItems = { ...draft.lineItems, ...lineItems };
      draft.modules = { ...draft.modules, ...modules };
      break;
    }

    // Keep this failure as we don't resync after a failed model pull
    case consts.PULL_MODEL_FAILURE: {
      const { error } = action.payload;
      draft.loading = false;
      draft.error = error;
      draft.cases = { error: {} };
      draft.modelInfo = { error };
      draft.currentCase = { uid: "error" };
      break;
    }

    case dataEntryConsts.RECALCULATE_MODEL_SUCCESS: {
      const { data } = action.payload;
      mergeEntities(data.facts, draft.facts);
      break;
    }

    case dataEntryConsts.ADD_LINE_ITEM_SUCCESS_REDUX:
    case dataEntryConsts.DELETE_LINE_ITEM_SUCCESS:
    case dataEntryConsts.ADD_COLUMN_SUCCESS:
    case dataEntryConsts.DELETE_COLUMN_SUCCESS:
    case dataEntryConsts.ADD_MODULE_SUCCESS: {
      const { data } = action.payload;
      if (data?.module) {
        const mod = data.module;
        cleanEdges(mod);
        mod.caseUid = Object.keys(draft.cases)[0];

        // different levels of normalization need parent ids
        // module needs parent if not 0
        if (mod.lineItems && mod.depth) mod.parentUid = draft.modules[mod.uid].parentUid;
        // lineItem needs parent
        if (mod.facts) mod.parentUid = draft.lineItems[mod.uid].parentUid;
        // fact needs parent
        if (mod.period) {
          const stateFact = draft.facts[mod.uid];
          mod.moduleUid = stateFact.moduleUid;
          mod.parentUid = stateFact.parentUid;
        }

        const { entities } = vsNormalize(mod, schemaLevel.MODULE);

        // Clean up temps and merge entities on ADD ops
        if (action.type.includes("ADD")) {
          // Remove temp item on add item
          if (action.type === dataEntryConsts.ADD_LINE_ITEM_SUCCESS_REDUX) {
            for (const newModuleID in entities.modules) {
              draft.modules[newModuleID].lineItemsNames.forEach((liName) => {
                const tempId = "temp-" + newModuleID + "-" + liName;
                if (draft.lineItems[tempId]) {
                  draft.lineItems[tempId].facts.forEach((factId) => delete draft.facts[factId]);
                  delete draft.lineItems[tempId];
                }
              });
            }
          }

          // remove temp facts on add column
          if (action.type === dataEntryConsts.ADD_COLUMN_SUCCESS) {
            for (const lineItemID in entities.lineItems) {
              const stateLineItem = draft.lineItems[lineItemID];
              stateLineItem?.facts.forEach((factId) => {
                if (factId.includes("temp-" + lineItemID)) delete draft.facts[factId];
              });
            }
          }

          mergeModelEntities(entities, draft);
        }

        // Merge edited facts
        if (data?.facts) mergeEntities(data.facts, draft.facts);

        if (action.type.includes("DELETE")) {
          for (const entKey in entities) {
            const serverEnt = entities[entKey];
            for (const itemKey in serverEnt) {
              draft[entKey][itemKey] = {
                ...draft[entKey][itemKey],
                ...serverEnt[itemKey],
              };
            }
          }
        }
      }

      if (isDebugMode()) runValidator(draft);

      draft.gridChangeOperations.pop();
      break;
    }

    // MODULE METADATA
    case dataEntryConsts.EDIT_MODULE_NAME: {
      const { newName, module } = action.payload;
      draft.modules[module.uid].name = newName;
      break;
    }

    // merge facts that were renamed
    case dataEntryConsts.EDIT_MODULE_NAME_SUCCESS: {
      if (action.payload.data.facts) {
        mergeEntities(action.payload.data.facts, draft.facts);
      }
      break;
    }

    case consts.UPDATE_SENSITIVITY_INCREMENT_SUCCESS: {
      const { caseUid, nextAnalysis } = action.payload;
      const analyses = draft.cases[caseUid].SENSITIVITY_ANALYSES;
      draft.cases[caseUid]["SENSITIVITY_ANALYSES"].sensitivities = analyses.sensitivities.map(
        (analysis) =>
          analysis.name !== nextAnalysis.sensitivity.name
            ? analysis
            : { ...analysis, ...nextAnalysis.sensitivity }
      );
      break;
    }
    case consts.UPDATE_SENSITIVITY_INCREMENT_FAILURE: {
      const { caseUid, oldAnalysis } = action.payload;
      const analyses = draft.cases[caseUid].SENSITIVITY_ANALYSES;
      draft.cases[caseUid]["SENSITIVITY_ANALYSES"].sensitivities = analyses.sensitivities.map(
        (analysis) =>
          analysis.name !== oldAnalysis.name ? analysis : { ...analysis, ...oldAnalysis }
      );
      break;
    }

    case consts.UPDATE_CURRENT_ANALYSIS_TYPE: {
      const { caseUid, nextAnalysisType } = action.payload;
      draft.cases[caseUid].currentSensitivityAnalysisType = nextAnalysisType;
      break;
    }

    case dataEntryConsts.ADD_LINE_ITEM: {
      const { lineItemName: name, order, caseID, moduleID } = action.payload.params;

      if (draft.modules[moduleID]?.lineItems) {
        orderModelLis(draft.modules[moduleID].lineItems, draft.lineItems, draft.facts, order);
      }

      const tempLiUid = "temp-" + moduleID + "-" + name;
      const tempLi = genTempLineItem(name, order, caseID, moduleID, [], tempLiUid);
      draft.lineItems[tempLi.uid] = tempLi;

      // iterate module periods to gen temp facts
      const mod = draft.modules[moduleID];
      const periods = range(
        mod.moduleStart,
        mod.moduleEnd + mod.forecastIncrement,
        mod.forecastIncrement
      );
      periods.forEach((period) => {
        const tempFact = genTempFact(period, tempLi.uid, moduleID, caseID, tempLi.order);
        draft.facts[tempFact.uid] = tempFact;
        tempLi.facts.push(tempFact.uid);
      });

      // add to draft module line items and lineItemsNames
      if (!mod.lineItems) mod.lineItems = [];
      mod.lineItems.push(tempLiUid);
      if (!mod.lineItemsNames) mod.lineItemsNames = [];
      mod.lineItemsNames.push(name);

      draft.gridChangeOperations.push(consts.GridChangeType.ADD_ROW);
      break;
    }

    case dataEntryConsts.ADD_QUICK_CALCULATION_FINISHED: {
      const { module } = action.payload.data;
      const stateModule = { ...draft.modules[module.uid] };
      const normalizedData = vsNormalize(
        { ...module, caseUid: stateModule.caseUid, parentUid: module.parentUid },
        schemaLevel.MODULE
      );
      const { facts, lineItems, modules } = normalizedData.entities;
      const deepMergeLis = deepMerge(lineItems, draft.lineItems);
      const deepMergeFacts = deepMerge(facts, draft.facts);
      Object.values(deepMergeFacts).forEach((fact) => {
        if (fact.format) fact.format = getValidJSON(fact.format) || {};
      });
      Object.values(deepMergeLis).forEach((li) => {
        if (li.format) li.format = getValidJSON(li.format) || {};
      });
      // Because only the relevant module is returned we need to add the missing line item to the existing full module
      const newModule = { ...stateModule, ...Object.values(modules)[0] };

      newModule.lineItemsNames = Array.from(
        new Set([...newModule.lineItemsNames, ...stateModule.lineItemsNames])
      ).filter((name) => name);
      draft.modules[newModule.uid] = newModule;

      draft.facts = { ...draft.facts, ...deepMergeFacts };
      draft.lineItems = { ...draft.lineItems, ...deepMergeLis };

      break;
    }

    case dataEntryConsts.DELETE_LINE_ITEM: {
      draft.gridChangeOperations.push(consts.GridChangeType.DELETE_ROW);
      const { facts, lineItems, modules } = draft;
      const { lineItemId, moduleID } = action.payload.params;
      const { restoreFacts } = action.payload.reverseParams;
      safelyDeleteLineItem(facts, lineItems, modules, moduleID, lineItemId);
      removeInvalidDepsAndPrecs(restoreFacts, facts, true);
      break;
    }

    case dataEntryConsts.ADD_COLUMN: {
      draft.gridChangeOperations.push(consts.GridChangeType.ADD_COLUMN);
      const { facts, lineItems, modules } = draft;
      let { newPeriod, moduleID } = action.payload.params;
      newPeriod = Number(newPeriod);

      const mod = modules[moduleID];
      updateModulePeriods(modules[moduleID], newPeriod);

      if (mod?.lineItems) {
        mod.lineItems.forEach((liUid) => {
          const li = lineItems[liUid];
          const newFact = genTempFact(newPeriod, li.uid, li.parentUid, li.caseUid, li.order);
          li.facts.push(newFact.uid);
          facts[newFact.uid] = newFact;
        });
      }
      break;
    }

    case dataEntryConsts.DELETE_COLUMN: {
      draft.gridChangeOperations.push(consts.GridChangeType.DELETE_COLUMN);
      const { facts, lineItems, modules } = draft;
      const { period, moduleID } = action.payload.params;
      const { facts: deletedFacts } = action.payload.reverseParams;

      safelyDeleteColumn(facts, lineItems, modules, moduleID, Number(period));
      removeInvalidDepsAndPrecs(deletedFacts, facts, true);
      break;
    }

    case dataEntryConsts.DELETE_MODULE: {
      const { uid: moduleID, parentModuleID } = action.payload.params;
      const { facts, lineItems, modules } = draft;
      const toRemove = modules[moduleID];

      // delete line items and facts from deleted line items
      if (toRemove?.lineItems) {
        toRemove.lineItems.forEach((lineItem) => {
          lineItems[lineItem].facts.forEach((fact) => delete facts[fact]);
          delete lineItems[lineItem];
        });
      }

      // remove module from parent module and from modules tree
      const parentModule = modules[parentModuleID];
      const childModuleIndex = parentModule.childModules.indexOf(moduleID);
      parentModule.childModules.splice(childModuleIndex, 1);
      modules[parentModuleID] = parentModule;
      delete modules[moduleID];

      break;
    }

    // Mutating the target object triggers closes the dialog in loading state
    case dataEntryConsts.ADD_MODULE_FAILURE:
    case dataEntryConsts.DELETE_MODULE_FAILURE:
      draft.modules = { ...draft.modules };
      break;

    case consts.FETCH_WIDGET_DATA: {
      const { widgetName } = action.payload;

      let cse = draft.cases[draft.currentCase.uid];
      if (!cse) cse = draft.cases[draft.currentCase.uid] = {};
      let widget = cse[widgetName];
      if (!widget) widget = cse[widgetName] = {};

      widget.loading = true;

      break;
    }
    case consts.FETCH_WIDGET_DATA_SUCCESS: {
      const { currentCaseUid, data } = action.payload;
      const newData = { ...data.data };
      newData.error = null;
      newData.loading = false;

      let cse = draft.cases[currentCaseUid];
      cse[data.name] = newData;
      break;
    }

    case consts.FETCH_WIDGET_DATA_FAILURE: {
      const { currentCaseUid, widgetName, error } = action.payload;
      let cse = draft.cases[currentCaseUid];
      if (!cse) cse = draft.cases[currentCaseUid] = {};
      let widget = cse[widgetName];
      if (!widget) widget = cse[widgetName] = {};
      widget.error = error;
      widget.loading = false;
      break;
    }

    // We need _REDUX because it has a preFX
    case dataEntryConsts.EDIT_FORMULA_REDUX: {
      const { facts } = draft;
      let { facts: editedFacts } = action.payload.params;

      let loading;
      if (editedFacts && action.type === dataEntryConsts.EDIT_FORMULA_REDUX) {
        const hits = new Set();
        editedFacts.forEach((ef) => {
          loadAllDependantCells(facts[ef.uid], facts, hits, true);
        });
        loading = true;
        removeInvalidDepsAndPrecs(editedFacts, facts);
      }
      const toMerge = factArrayToObject(facts, editedFacts, { resetErrors: false, loading });
      draft.facts = { ...facts, ...toMerge };
      break;
    }

    case dataEntryConsts.EDIT_FORMULA_SUCCESS_REDUX: {
      const { facts } = draft;
      let { facts: editedFacts } = action.payload.data;

      if (!editedFacts) return draft;

      const hits = new Set();
      editedFacts.forEach((ef) => {
        cleanEdges(ef);
        const fact = facts[ef.id];
        loadAllDependantCells(fact, facts, hits, false);
        fact.loading = 0;
      });

      const toMerge = factArrayToObject(facts, editedFacts, { loading: false, resetErrors: true });
      draft.facts = { ...facts, ...toMerge };
      break;
    }

    case dataEntryConsts.EDIT_FORMAT: {
      const { facts } = draft;
      let toMerge;
      let { facts: editedFacts } = action.payload.params;
      toMerge = factArrayToObject(facts, editedFacts, { resetErrors: false, loading: false });
      draft.facts = { ...facts, ...toMerge };
      break;
    }

    /** Reorder each lineitem in a module */
    case dataEntryConsts.REORDER_MODULE: {
      const { modules, lineItems, facts } = draft;
      const { order, parentUid, prevOrder } = action.payload.params;
      const module = modules[parentUid];

      const firstIndex = prevOrder;
      const lastIndex = order;
      module.lineItems.forEach((liUid) => {
        const li = lineItems[liUid];
        li.order = swapReorder(li.order, firstIndex, lastIndex);
        li.facts.forEach((factUid) => (facts[factUid].parentOrder = li.order));
      });
      break;
    }

    case dataEntryConsts.EDIT_LINE_ITEMS: {
      const { modules, lineItems, facts } = draft;
      const { lineItems: editedLineItems, moduleID } = action.payload.params;

      editedLineItems.forEach((li) => {
        const format = getValidJSON(li.format) || {};
        lineItems[li.uid] = { ...lineItems[li.uid], ...li, format: { ...format } };
      });

      // name change
      editedLineItems.forEach((editedLi) => {
        const li = lineItems[editedLi.uid];
        li.renaming = false;
        if (editedLi.name && editedLi.name !== editedLi.oldName) {
          // change module refs
          const module = modules[moduleID];
          module.lineItemsNames = module.lineItemsNames.map((liName) => {
            return liName === editedLi.oldName ? editedLi.name : liName;
          });
          if (li.facts) {
            refactorLineItemDeps(
              { ...li, name: editedLi.oldName }, // requires li with old name
              facts,
              module.name,
              null,
              editedLi.name
            );
          }
        }
      });
      break;
    }

    case dataEntryConsts.EDIT_LINE_ITEM_TAGS: {
      const { lineItemID, tags } = action.payload;
      draft.lineItems[lineItemID].tags = tags;
      break;
    }

    case dataEntryConsts.EDIT_LINE_ITEM_TAGS_FAILURE: {
      const { lineItemID, tags } = action.payload;
      draft.lineItems[lineItemID].tags = tags;
      break;
    }

    case consts.FETCH_LOGO_SUCCESS:
      draft.logoUrl = action.payload.logoUrl;
      break;
    case consts.FETCH_LOGO_FAILURE:
      draft.logoUrl = undefined;
      break;

    case consts.UPDATE_CURRENT_MODULE_REDUCER:
      draft.currentModuleUid = action.payload.module;
      break;

    case consts.UPDATE_MODEL_CALCULATIONS_REDUX:
    case consts.UPDATE_MODEL_CALCULATIONS_FAILURE:
      draft.iterations = action.payload.iterations;
      draft.precision = action.payload.precision;
      break;

    case dataEntryConsts.PULL_SOURCE_SUCCESS: {
      const { ref, data } = action.payload;
      draft.parsedSources[ref] = data;
      break;
    }
    case dataEntryConsts.PULL_SOURCE_FAILURE: {
      const { ref } = action.payload;
      draft.parsedSources[ref] = null;
      break;
    }

    case consts.UPDATE_MODULE_TREE: {
      const { nodes } = action.payload;
      draft.tree.nodes = nodes;
      break;
    }

    case consts.EDIT_MODEL_PROPERTIES_REDUX: {
      const { edit } = action.payload;
      if (draft.modelInfo.id) draft.modelInfo = { ...draft.modelInfo, ...edit };
      break;
    }
    case consts.EDIT_MODEL_PROPERTIES_FAILURE:
      draft.modelInfo = action.payload.modelInfo;
      break;

    case consts.FLUSH_MODEL_REDUCER:
      return { ...DEFAULT_STATE };

    case consts.FETCH_DATA_SUCCESS: {
      const { identifier, data } = action.payload;
      draft[identifier] = data;
      break;
    }

    default:
      return draft;
  }
}, DEFAULT_STATE);

export default modelReducer;
