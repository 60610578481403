import React, { memo, useCallback, useContext, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { Intent } from "@blueprintjs/core";
import { editLineItem } from "actions/dataEntryActions";
import { updateEditMode, updateFocusedCell } from "actions/dataEntryUIActions";
import { FullSizeDiv } from "components/utility/StyledComponents";
import LoadingIndicator from "components/utility/LoadingIndicator";
import VSTable from "components/model/VSTable";
import DataEntryTableContextMenu from "./DataEntryTableContextMenu";
import {
  caseModulesSelector,
  currentCaseSelector,
  currentModuleSelector,
} from "selectors/modelSelectors";
import { multiToaster } from "utils/toaster";
import "styles/val/data-entry/data-entry-table.scss";
import ModelContext from "context/modelContext";
import { isConstant, isEqualSelector, selectorObject, selectorToTableCoords } from "utils/formulas";
import { DataEntryEditMode } from "constants/dataEntryUIConstants";
import {
  addCustomClassCell,
  buildCellStyleObject,
  expandDefaults,
  isSelectionCell,
} from "utils/modelGrid";
import { squareStyle } from "utils/generic";
import AppContext from "context/appContext";
import { getPrecsFromFx, rangesToArrays } from "utils/data";
import { focusedCellSelector } from "selectors/dataEntrySelectors";
import { formulaInsertDataSelector } from "selectors/dataEntryUISelectors";
import { EValFormat } from "types/format";

const DataEntryTable = ({
  autoAdjustColsWidth,
  copy,
  columnHeaders,
  constantStyles,
  currentCase,
  currentFormula,
  currentModule,
  dashboardSize,
  deleteLineItem,
  drag,
  editLineItem,
  editMode,
  facts,
  focusedCell,
  formulaInsertData,
  formulaStyles,
  handleRowsReordered,
  lineItems,
  locked,
  onCellInput,
  modules,
  paste,
  rowHeaderFocus,
  rows,
  selectedRegions,
  tableRef,
  updateEditMode,
  updateFocusedCell,
}) => {
  const tableContainerRef = useRef();
  const {
    config: { theme },
  } = useContext(AppContext);
  const {
    config: { columnWidths },
    setConfig,
  } = useContext(ModelContext);

  const handleRowHeaderUpdate = useCallback(
    (rows, data) => {
      const idx = data.indexes[0];
      let value = rows[idx].rowHeader;
      const lineItem = lineItems[rows[idx].uid];

      if (lineItem.name === value) {
        return;
      }

      if (rows.map((row) => row.uid !== lineItem.uid && row.rowHeader).includes(value)) {
        multiToaster.show({
          message: "Line item names must be unique",
          icon: "warning-sign",
          intent: Intent.DANGER,
        });
      } else if (!value) {
        multiToaster.show({
          message: "Line item name can't be blank",
          icon: "warning-sign",
          intent: Intent.DANGER,
        });
      } else if (lineItem.name !== value || lineItem.uid.indexOf("temp") === -1)
        // name must have change and li can't be temp. Temp is fixed in addLineItemSFX
        editLineItem(currentCase.uid, currentModule.uid, [
          {
            uid: lineItem.uid,
            id: lineItem.uid,
            name: value,
            oldName: lineItem.name,
          },
        ]);
    },
    [currentCase, currentModule, editLineItem, lineItems]
  );

  const editColumnWidth = useCallback(
    (idx, width) => {
      const widths = columnWidths?.[currentModule.uid] || [];
      let newWidths;
      if (idx === -1) {
        newWidths = [];
      } else {
        newWidths = [...widths];
        newWidths[idx] = width;
      }
      const newColumnWidths = {
        ...columnWidths,
        [currentModule.uid]: newWidths,
      };
      setConfig({ columnWidths: newColumnWidths });
    },
    [columnWidths, currentModule, setConfig]
  );

  const handleDrag = useCallback(
    // Todo: use new drag result object when we upgrade RDG
    (data) => {
      if (
        !Object.hasOwn(data, "fromRow") ||
        !Object.hasOwn(data, "toRow") ||
        !Object.hasOwn(data, "fromCol") ||
        !Object.hasOwn(data, "toCol") ||
        !Object.hasOwn(data, "direction")
      )
        return null;
      if (data.fromCol === 0 && data.toCol === 0) {
        // hack: RDG css for reverse reorder doesn't change
        const end = data.fromRow > data.toRow ? data.toRow + 1 : data.toRow;
        handleRowsReordered(data.fromRow, end);
      } else {
        const start = data.direction === "row" ? data.fromRow : data.fromCol - 1;
        const end = data.direction === "row" ? data.toRow : data.toCol - 1;
        drag(data.direction, start, end);
      }
      return null;
    },
    [drag, handleRowsReordered]
  );

  const contextMenu = useMemo(
    () => (
      <DataEntryTableContextMenu
        copy={copy}
        deleteLineItem={deleteLineItem}
        locked={locked}
        paste={paste}
      />
    ),
    [copy, deleteLineItem, locked, paste]
  );

  const moduleLineItems = useMemo(() => {
    if (!currentModule) return;
    if (!currentModule.lineItems) return [];
    return currentModule.lineItems.map((li) => lineItems[li]);
  }, [currentModule, lineItems]);

  // generic styles matrix for all cells
  const styledRegions = useMemo(() => {
    if (!rows?.length) return [];
    // TODO: When typing this refactor existing type IRDGCustom
    const constStyles = constantStyles[theme];
    const fxStyles = formulaStyles[theme];
    const stylesMatrix = [];
    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
      const lineItem = moduleLineItems.find((li) => {
        return li.uid === rows[rowIndex].uid;
      });
      const styleRow = stylesMatrix[rowIndex] || (stylesMatrix[rowIndex] = []);
      let defaultStyles;
      const rowSize = Object.keys(rows[0]).filter((prop) => !Number.isNaN(Number(prop))).length;
      for (let colIndex = 0; colIndex < rowSize; colIndex++) {
        const fact = rows[rowIndex][colIndex];
        defaultStyles =
          isConstant(fact.formula) || fact.format.valFormat === EValFormat.PLAINTEXT
            ? constStyles
            : fxStyles;
        const format = expandDefaults(defaultStyles, fact.format);
        styleRow[colIndex + 1] = {
          ...((fact.loading || fact.uid.includes("temp")) && { loading: true }),
          ...styleRow[colIndex + 1],
          style: buildCellStyleObject({ ...defaultStyles, ...format }, fact.error, false),
          ...((editMode === DataEntryEditMode.FORMULA || editMode === DataEntryEditMode.INSERT) && {
            classes: "insert-mode-focus rdg-cell-drag-handle-remove",
          }),
        };
      }

      stylesMatrix[rowIndex][0] = {
        style: {
          ...constStyles,
          ...lineItem?.format,
          textAlign: "left",
        },
      };
    }
    return stylesMatrix;
  }, [constantStyles, editMode, formulaStyles, moduleLineItems, rows, theme]);

  // styles matrix with focused cell related styles included
  const currentStyledRegions = useMemo(() => {
    if (!rows?.length || rowHeaderFocus) return styledRegions;
    const styles = [...styledRegions];

    // INSERT Mode Styling
    const needInsertCell =
      !formulaInsertData?.fact || formulaInsertData.fact.moduleUid === currentModule.uid;

    // INSERT Cell
    if (editMode !== DataEntryEditMode.NORMAL && formulaInsertData.cell && needInsertCell)
      addCustomClassCell(
        formulaInsertData.cell.row,
        formulaInsertData.cell.col,
        styles,
        "insert-mode-cell"
      );

    // Focus and selection while in INSERT mode
    if (editMode === DataEntryEditMode.INSERT) {
      if (!needInsertCell || !isSelectionCell(formulaInsertData.cell, selectedRegions[0])) {
        const selReg = selectedRegions[0];
        for (let i = selReg.rows[0]; i <= selReg.rows[1]; i++) {
          for (let j = selReg.cols[0]; j <= selReg.cols[1]; j++) {
            addCustomClassCell(i, j, styles, "insert-mode-selection");
          }
        }
      }
    }

    // Precedents Styling
    if (!focusedCell) return styles;
    const fact = focusedCell.fact;
    let precedents;
    if (editMode !== DataEntryEditMode.NORMAL) {
      precedents = getPrecsFromFx(
        currentFormula,
        facts,
        lineItems,
        modules,
        currentModule,
        currentCase.uid
      );
    } else {
      if (!fact?.precedentCells) return styles;
      precedents = rangesToArrays(
        fact.formula,
        fact.precedentCells,
        facts,
        lineItems,
        modules,
        currentCase.uid
      );
    }

    let idxs = [];
    precedents.forEach((precs) => {
      const dep = facts[precs[0]];
      if (currentModule.uid !== dep?.moduleUid) return;

      let sel;
      const firstPrec = facts[precs[0]];
      const lineItem = lineItems[firstPrec.parentUid].name;
      if (precs.length > 1) {
        const lastPrec = facts[precs[precs.length - 1]];
        const lastLineItem = lineItems[lastPrec.parentUid].name;
        sel = selectorObject(
          currentModule.name,
          lineItem,
          "" + firstPrec.period,
          lastLineItem,
          "" + lastPrec.period
        );
      } else sel = selectorObject(currentModule.name, lineItem, "" + firstPrec.period);

      if (formulaInsertData.tempInsertion && isEqualSelector(sel, formulaInsertData.tempInsertion))
        return;

      const rowsNames = rows.map((rH) => rH.rowHeader);
      idxs.push(selectorToTableCoords(sel, columnHeaders, rowsNames));
    });

    if (!idxs) return styledRegions;
    else {
      idxs.forEach((idx) => {
        const squareStyles = {
          all: "formula-sel-cell",
          top: "formula-sel-cell-top",
          bottom: "formula-sel-cell-bottom",
          left: "formula-sel-cell-left",
          right: "formula-sel-cell-right",
        };
        squareStyle(idx.rows[0], idx.rows[1], idx.columns[0], idx.columns[1], squareStyles, styles);
      });
    }

    return styles;
  }, [
    columnHeaders,
    currentCase,
    currentFormula,
    currentModule,
    editMode,
    facts,
    focusedCell,
    formulaInsertData.cell,
    formulaInsertData.fact,
    formulaInsertData.tempInsertion,
    lineItems,
    modules,
    rowHeaderFocus,
    rows,
    selectedRegions,
    styledRegions,
  ]);

  if (!columnHeaders) {
    return (
      <LoadingIndicator loading status={{ intent: Intent.PRIMARY, message: "LOADING DATA" }} />
    );
  } else
    return (
      <FullSizeDiv style={{ height: dashboardSize.tableHeight }} fullWidth ref={tableContainerRef}>
        <VSTable
          autoAdjustColsWidth={autoAdjustColsWidth}
          columnHeaders={columnHeaders}
          containerWidth={tableContainerRef?.current?.clientWidth}
          contextMenu={contextMenu}
          dashboardWidth={dashboardSize.width}
          fwdRef={tableRef}
          columnWidths={columnWidths?.[currentModule.uid]}
          editableRowHeaders={true}
          editColumnWidth={editColumnWidth}
          editMode={editMode}
          draggableCells={true}
          handleDrag={handleDrag}
          handleRowsReordered={handleRowsReordered}
          handleRowHeaderUpdate={handleRowHeaderUpdate}
          onCellInput={onCellInput}
          locked={locked}
          reorderableRows={true}
          rows={rows}
          styledRegions={currentStyledRegions}
          updateEditMode={updateEditMode}
          updateFocusedCell={updateFocusedCell}
        />
      </FullSizeDiv>
    );
};

function mapStateToProps(state) {
  return {
    constantStyles: state.dataEntry.ui.constantStyles,
    currency: state.ui.global.currency,
    currentCase: currentCaseSelector(state),
    currentFormula: state.dataEntry.ui.formula,
    currentModule: currentModuleSelector(state),
    editMode: state.dataEntry.ui.editMode,
    facts: state.model.facts,
    focusedCell: focusedCellSelector(state),
    formulaInsertData: formulaInsertDataSelector(state),
    formulaStyles: state.dataEntry.ui.formulaStyles,
    lineItems: state.model.lineItems,
    locale: state.ui.global.localeString,
    modules: caseModulesSelector(state),
    rowHeaderFocus: state.dataEntry.ui.rowHeader,
    selectedRegions: state.dataEntry.ui.selectedRegions,
    unit: state.ui.global.unit,
  };
}

const mapDispatchToProps = {
  editLineItem,
  updateEditMode,
  updateFocusedCell,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(DataEntryTable));
