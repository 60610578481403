import { Button, FormGroup, H6, InputGroup, Intent } from "@blueprintjs/core";
import ModelFilters, { InitialFilterState } from "components/table/ModelFilters";
import {
  Flex,
  Grid,
  NonScrollableDialogBody,
  styledScrollBar,
} from "components/utility/StyledComponents";
import React, { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import Table from "components/table/Table";
import { TModelHistoryTablePartialModel, IFilterState } from "types/modelHistory";
import { SelectedCompaniesTable } from "./WatchlistsSelectedCompaniesTable";
import AppContext from "context/appContext";
import { IResponse } from "types";
import CsvUpload from "./CSVUpload";
import { GET_COVERAGE_COMPANIES_ROUTE } from "constants/apiConstants";
import api from "utils/api";
import { TCompanyInfo } from "types/createModel";
import useSWRImmutable from "swr/immutable";
import styled from "styled-components";
import { Watchlist } from "types/collections";
import { SortingState } from "@tanstack/react-table";

interface WatchlistCompanySelectionProps {
  isNameValid?: boolean;
  setWatchlist: Dispatch<SetStateAction<Partial<Watchlist> | undefined>>;
  watchlist: Partial<Watchlist> | undefined;
}

const SetWatchlistNameAndCompanies: React.FC<WatchlistCompanySelectionProps> = ({
  isNameValid,
  setWatchlist,
  watchlist,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const [sortingState, setSortingState] = useState<SortingState>([{ id: "ticker", desc: false }]);

  const [filterState, setFilterState] = useState<IFilterState>({
    ...InitialFilterState,
    tagFilters: [],
    tagFilterType: "And",
  });

  const { data: companies, isLoading: fetchingCompanies } = useSWRImmutable<
    IResponse<TCompanyInfo[]>
  >(GET_COVERAGE_COMPANIES_ROUTE, api);

  const handleSelectCompanies = (selection: TModelHistoryTablePartialModel[]) => {
    const nextCompanies = selection.map((s) => ({
      ...s,
      companyName: s.companyName ?? "",
      ticker: s.ticker ?? "",
    }));

    setWatchlist((prevState) => ({ ...prevState, companies: nextCompanies }));
  };

  const resetTickers = () => {
    handleSelectCompanies([]);
  };

  const removeTicker = (ticker: string) => {
    if (watchlist?.companies) {
      setWatchlist({
        ...watchlist,
        companies: watchlist.companies.filter((s) => s.ticker !== ticker),
      });
    }
  };

  const handleUploadTickersFromCSV = (tickers: string[]) => {
    handleSelectCompanies(
      tickers.map((ticker) => ({
        id: ticker,
        ticker,
      }))
    );
  };

  // Selected companies array for the left hand side table displaying the currently selected tickers
  const selectedCompanies = useMemo(() => {
    return watchlist?.companies
      ? watchlist.companies.map((m) => ({
          ticker: m.ticker || "",
          companyName: m.companyName || "",
        }))
      : [];
  }, [watchlist?.companies]);

  // Build the selection object for the <Table />
  const selectedRows = useMemo(() => {
    return watchlist?.companies?.map((company) => ({
      ...company,
      companyId: company.id,
      id: company.ticker,
      ticker: company?.ticker,
    }));
  }, [watchlist?.companies]);

  const columnTemplates = useMemo(() => {
    return [
      {
        header: "Ticker",
        id: "ticker",
        meta: {
          type: "link",
        },
        width: 60,
      },
      {
        enableSorting: false,
        header: "Company name",
        id: "companyName",
      },
      { enableSorting: false, header: "Geography", id: "geography" },
      { enableSorting: false, header: "Industry", id: "industry" },
      { enableSorting: false, header: "Associated Models", id: "numberOfModels" },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (companies?.data) {
      const tableData = companies.data
        .map((company) => ({ ...company, id: company.ticker }))
        .sort((a, b) => a.ticker.localeCompare(b.ticker))
        .filter((company) => {
          if (
            !filterState.predicate ||
            company.ticker.toLowerCase().includes(filterState.predicate.toLowerCase())
          ) {
            if (filterState.indFilters.length > 0) {
              return filterState.indFilters.includes(company.industry);
            }
            return true;
          }
          return false;
        });
      if (sortingState[0]?.desc) {
        return tableData.reverse();
      }
      return tableData;
    }
  }, [companies, filterState, sortingState]);

  return (
    <NonScrollableDialogBody>
      {watchlist ? (
        <Grid fullHeight rows="auto 1fr">
          <FormGroup
            helperText={!isNameValid ? "Name must be present and unique." : ""}
            intent={!isNameValid ? Intent.WARNING : undefined}
            label="Watchlist Name:"
          >
            <InputGroup
              onChange={(e) => {
                setWatchlist((prevState) => ({ ...prevState, name: e.currentTarget.value }));
              }}
              value={watchlist?.name ?? ""}
            />
          </FormGroup>
          <Grid cols="auto 1fr" gap="20px" style={{ overflow: "hidden" }}>
            <Grid rows="auto auto 1fr" style={{ overflow: "hidden" }}>
              {companies?.data && (
                <CsvUpload
                  uploadTickersFromCSV={handleUploadTickersFromCSV}
                  companies={companies?.data}
                />
              )}
              <Flex alignItems="center" justifyContent="space-between">
                <H6 style={{ marginBottom: 0 }}>Watchlist Companies</H6>
                <Button minimal intent={Intent.PRIMARY} onClick={() => resetTickers()}>
                  Clear All
                </Button>
              </Flex>
              <SelectedCompaniesTable
                prefixId="companySelection"
                removeTicker={removeTicker}
                watchlistSelection={selectedCompanies}
              />
            </Grid>
            <Grid
              // fullHeight
              gap="10px"
              rows="auto 1fr"
              style={{ maxWidth: "100%", overflow: "hidden" }}
            >
              <ModelFilters
                allTags={[]}
                filterState={filterState}
                setFilterState={setFilterState}
                tagFilters={filterState?.tagFilters}
                advancedFiltersEnabled={true}
                companiesFilterConfig={true}
              />
              <StyledTableScrollContainer $theme={theme}>
                <Table
                  id="watchlistTable"
                  columnTemplates={columnTemplates}
                  configOverrides={{ layoutMode: "auto", pinnedColumns: 0 }}
                  sortingState={sortingState}
                  setSortingState={setSortingState}
                  enableConfigOverrides={false}
                  enableSelection={true}
                  loading={fetchingCompanies}
                  data={tableData ?? []}
                  setSelectedRows={handleSelectCompanies}
                  selectedRows={selectedRows}
                />
              </StyledTableScrollContainer>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </NonScrollableDialogBody>
  );
};

const StyledTableScrollContainer = styled.div`
  overflow: auto;
  ${styledScrollBar};
`;

export default SetWatchlistNameAndCompanies;
