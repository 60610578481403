import React from "react";
import { Button, Intent, Menu, MenuDivider, MenuItem, Popover, Tooltip } from "@blueprintjs/core";
import { IActionReturnType } from "types";
import { IBasicModelInfo, IModelInfo } from "types/model";
import { DEFAULT_ITERATION_OPTIONS, DEFAULT_PRECISION_OPTIONS } from "constants/modelConstants";
import useCopyModel from "hooks/useCopyModel";
import { useNavigate } from "react-router-dom";
import { IconNames } from "@blueprintjs/icons";
import { singleToaster, ToasterSpinner } from "utils/toaster";

interface IModelSettingsProps {
  editModelProperties: (
    modelID: string,
    edit: Partial<IBasicModelInfo<Date>>
  ) => IActionReturnType<string, { modelID: string; edit: IBasicModelInfo<Date> }>;
  exportToExcel: () => IActionReturnType<string, undefined>;
  locked: boolean;
  lockUI: () => IActionReturnType<string, undefined>;
  modelInfo: IModelInfo;
  hasPreviousVersions: boolean;
  recalculateModel: () => IActionReturnType<string, undefined>;
  settingsDialogOpen: boolean;
  setSettingsDialogOpen: (isOpen: boolean) => void;
  setPreviousVersionsDialogOpen: (isOpen: boolean) => void;
  setSanitiseDataDialogOpen: (isOpen: boolean) => void;
  setShareDialogOpen: (isOpen: boolean) => void;
}

const ModelSettings: React.FC<IModelSettingsProps> = ({
  editModelProperties,
  exportToExcel,
  locked,
  lockUI,
  modelInfo,
  hasPreviousVersions,
  recalculateModel,
  setSettingsDialogOpen,
  setPreviousVersionsDialogOpen,
  setSanitiseDataDialogOpen,
  setShareDialogOpen,
}) => {
  const { copyModel } = useCopyModel();
  const navigate = useNavigate();

  return (
    <Popover
      disabled={locked}
      content={
        <Menu>
          <MenuItem
            icon={IconNames.CIRCLE}
            tagName="span"
            text="Circular References"
            popoverProps={{ hoverCloseDelay: 500 }}
            shouldDismissPopover={false}
          >
            <MenuItem popoverProps={{ hoverCloseDelay: 500 }} tagName="span" text="Iterations">
              {DEFAULT_ITERATION_OPTIONS.map((option) => (
                <MenuItem
                  active={modelInfo.iterations === option}
                  icon={modelInfo.iterations === option ? IconNames.TICK : null}
                  key={option}
                  tagName="span"
                  onClick={() => {
                    editModelProperties(modelInfo.id, { iterations: option });
                  }}
                  shouldDismissPopover={false}
                  text={option}
                />
              ))}
              <MenuDivider />
              <MenuItem
                active={!DEFAULT_ITERATION_OPTIONS.includes(modelInfo.iterations)}
                icon={
                  !DEFAULT_ITERATION_OPTIONS.includes(modelInfo.iterations)
                    ? IconNames.TICK
                    : IconNames.SETTINGS
                }
                tagName="span"
                onClick={() => {
                  setSettingsDialogOpen(true);
                }}
                text={
                  !DEFAULT_ITERATION_OPTIONS.includes(modelInfo.iterations) ? (
                    <span>
                      Custom <span>({modelInfo.iterations})</span>
                    </span>
                  ) : (
                    "Custom"
                  )
                }
              />
            </MenuItem>
            <MenuItem
              tagName="span"
              shouldDismissPopover={false}
              text="Precision"
              popoverProps={{ hoverCloseDelay: 500 }}
            >
              {DEFAULT_PRECISION_OPTIONS.map((option) => (
                <MenuItem
                  active={modelInfo.precision === option}
                  icon={modelInfo.precision === option ? IconNames.TICK : null}
                  key={option}
                  tagName="span"
                  onClick={() => {
                    editModelProperties(modelInfo.id, { precision: option });
                  }}
                  shouldDismissPopover={false}
                  text={option}
                />
              ))}
              <MenuDivider />
              <MenuItem
                active={!DEFAULT_PRECISION_OPTIONS.includes(modelInfo.precision)}
                icon={
                  !DEFAULT_PRECISION_OPTIONS.includes(modelInfo.precision)
                    ? IconNames.TICK
                    : IconNames.SETTINGS
                }
                tagName="span"
                onClick={() => {
                  setSettingsDialogOpen(true);
                }}
                shouldDismissPopover={false}
                text={
                  !DEFAULT_PRECISION_OPTIONS.includes(modelInfo.precision) ? (
                    <span>
                      Custom <span>({modelInfo.precision})</span>
                    </span>
                  ) : (
                    "Custom"
                  )
                }
              />
            </MenuItem>
          </MenuItem>
          <MenuDivider />
          <MenuItem
            icon={IconNames.SHARE}
            onClick={() => setShareDialogOpen(true)}
            text="Share Model"
          />
          <MenuItem
            disabled={!modelInfo}
            icon={IconNames.DUPLICATE}
            shouldDismissPopover={false}
            onClick={() => copyModel(modelInfo)}
            text="Duplicate Model"
          />
          <MenuItem icon={IconNames.EXPORT} onClick={exportToExcel} text="Export to Excel" />
          <MenuDivider />
          <MenuItem
            icon={IconNames.SETTINGS}
            onClick={() => {
              setSettingsDialogOpen(true);
            }}
            text="Advanced Settings"
          />
          <MenuDivider />
          <MenuItem
            icon={IconNames.REFRESH}
            onClick={() => {
              recalculateModel();
              lockUI();
              singleToaster.show({
                message: "Recalculating model...",
                icon: <ToasterSpinner intent={Intent.PRIMARY} />,
                intent: Intent.PRIMARY,
                timeout: -1,
              });
            }}
            text="Recalculate Model"
          />
          <MenuDivider />
          <Tooltip
            content={!hasPreviousVersions ? "No previous versions available" : ""}
            fill={true}
          >
            <MenuItem
              disabled={!hasPreviousVersions}
              icon={IconNames.HISTORY}
              onClick={() => {
                setPreviousVersionsDialogOpen(true);
              }}
              text="Previous Versions"
            />
          </Tooltip>
          <Tooltip fill={true}>
            <MenuItem
              icon={IconNames.CLEAN}
              onClick={() => {
                setSanitiseDataDialogOpen(true);
              }}
              text="Sanitize Data"
            />
          </Tooltip>
          <MenuDivider />
          <MenuItem
            disabled={locked}
            icon={IconNames.LOG_OUT}
            onClick={() => navigate("/")}
            text="Close Model"
          />
        </Menu>
      }
    >
      <Button disabled={locked} rightIcon={IconNames.MENU} minimal text={modelInfo.companyName} />
    </Popover>
  );
};

export default ModelSettings;
