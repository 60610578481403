import * as consts from "constants/dataEntryUIConstants";

/**
 * Lock the UI to prevent any user interaction
 * */
export function lockUI() {
  return {
    type: consts.LOCK_UI,
  };
}

/**
 * Unlock the UI to allow user interaction
 * */
export function unlockUI() {
  return {
    type: consts.UNLOCK_UI,
  };
}

/**
 * Updates the position of the focused cell position
 * */
export function updateFocusedCellBounds(bounds) {
  return {
    type: consts.UPDATE_FOCUSED_CELL_BOUNDS,
    payload: {
      bounds,
    },
  };
}
/**
 * Starts the saga that updates the current position of the focusedCell
 */
export function updateFocusedCell(cell, selection, rowHeader) {
  return {
    type: consts.UPDATE_FOCUSED_CELL,
    payload: {
      cell,
      selection,
      rowHeader,
    },
  };
}

/**
 * Updates the current position of the focusedCell in state
 */
export function updateFocusedCellReducer(cell, rowHeader) {
  return {
    type: consts.UPDATE_FOCUSED_CELL_REDUCER,
    payload: {
      cell,
      rowHeader,
    },
  };
}

/**
 * Starts the selected regions update saga
 */
export function updateSelectedRegions(selection) {
  return {
    type: consts.UPDATE_SELECTED_REGIONS,
    payload: {
      selection,
    },
  };
}

/**
 * Updates the currently selected regions in state
 */
export function updateSelectedRegionsReducer(selection) {
  return {
    type: consts.UPDATE_SELECTED_REGIONS_REDUCER,
    payload: {
      selection,
    },
  };
}

/**
 * Updates the current formula
 */
export function updateFormula(formula, postSelection) {
  return {
    type: consts.UPDATE_FORMULA,
    payload: {
      formula,
      postSelection,
    },
  };
}

/**
 * Updates the current formula
 */
export function updateFormulaRedux(formula) {
  return {
    type: consts.UPDATE_FORMULA_REDUX,
    payload: {
      formula,
    },
  };
}

/**
 * Starts the edit mode update saga
 */
export function updateEditMode(mode, commit, tableRef) {
  return {
    type: consts.UPDATE_EDIT_MODE,
    payload: {
      mode,
      commit,
      tableRef,
    },
  };
}

/**
 * Updates the edit mode in state
 */
export function updateEditModeReducer(mode, formulaInsertData, currentModuleUid) {
  return {
    type: consts.UPDATE_EDIT_MODE_REDUCER,
    payload: {
      mode,
      formulaInsertData,
      currentModuleUid,
    },
  };
}

/**
 * Updates the insert temporary selector
 */
export function updateFormulaTempInsert(tempInsertion, currentModuleUid) {
  return {
    type: consts.UPDATE_FORMULA_TEMP_INSERT,
    payload: {
      tempInsertion,
      currentModuleUid,
    },
  };
}

export function setNavCellCoords(coords) {
  return {
    type: consts.NAV_CELL_COORDS,
    payload: {
      coords,
    },
  };
}
