import React from "react";
import "styles/sensitivity-analysis.scss";
import VSHTMLTable from "components/dashboard/VSHTMLTable";
import { useSelector } from "react-redux";
import { cppTableWidths, fetchTagsData, getEffectivePeriodRange } from "utils/cpp";
import { lineItemsSelector, modelInfoSelector } from "selectors/modelSelectors";
import { factsSelector } from "selectors/dataEntrySelectors";
import { tagLineItemsMapSelector } from "selectors/cpp_selectors";

const totalAttributionTags = [
  "EPS Total Return Attribution (%) {Valuation} (Base)",
  "Multiple Total Return Attribution (%) {Valuation} (Base)",
  "Dividends Total Return Attribution (%) {Valuation} (Base)",
  "Total Return Attribution (%) {Valuation} (Base)",
];

const rowHeaders = ["EPS", "Multiple", "Dividends", "Total"];
const CPP_TotalReturn = () => {
  const tagLineItemsMap = useSelector(tagLineItemsMapSelector);
  const lineItems = useSelector(lineItemsSelector);
  const facts = useSelector(factsSelector);
  const modelInfo = useSelector(modelInfoSelector);
  const startPeriod = modelInfo?.startPeriod;

  const header: number[] = getEffectivePeriodRange(startPeriod, tagLineItemsMap, lineItems, facts);

  const totalReturnData = [
    ["", ...header],
    ...fetchTagsData(totalAttributionTags, header, tagLineItemsMap, lineItems, facts, rowHeaders),
  ];

  return <VSHTMLTable data={totalReturnData} widths={cppTableWidths} />;
};

export default CPP_TotalReturn;
