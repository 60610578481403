import React, { FormEvent } from "react";
import {
  AnchorButton,
  Button,
  Classes,
  Dialog,
  Divider,
  FormGroup,
  H4,
  HTMLSelect,
  InputGroup,
  Intent,
  Tooltip,
} from "@blueprintjs/core";
import { IModelTemplate, IModelTemplateVariable } from "types/modelTemplate";
import { Flex, noMarginComponent } from "components/utility/StyledComponents";

const Colon = noMarginComponent(H4);

interface IEditTemplateVariablesDialog {
  addTemplateVariable: () => void;
  editTemplateVariable: (key: string, nextVariable: IModelTemplateVariable) => void;
  isOpen: boolean;
  modelTemplate?: IModelTemplate;
  onClose: () => void;
  removeTemplateVariable: (key: string) => void;
  variables: IModelTemplateVariable[];
}

const EditTemplateVariablesDialog: React.FC<IEditTemplateVariablesDialog> = ({
  addTemplateVariable,
  editTemplateVariable,
  isOpen,
  onClose,
  removeTemplateVariable,
  variables,
}) => {
  const availableVariables = ["INTERNAL_SOURCE"].filter(
    (variable) => variables.findIndex((currVar) => Object.keys(currVar)[0] === variable) === -1
  );
  const keyChangeHandler = (e: FormEvent<HTMLSelectElement>, key: string, value: string) => {
    editTemplateVariable(key, { [e.currentTarget.value]: value });
  };
  const valueChangeHandler = (e: FormEvent<HTMLInputElement>, key: string) => {
    editTemplateVariable(key, { [key]: e.currentTarget.value });
  };
  return (
    <Dialog icon="edit" isOpen={isOpen} onClose={onClose} title="Edit Template Variables">
      <div className={Classes.DIALOG_BODY}>
        <p>Use the boxes below to edit and add template variables.</p>
        {variables.map((variable, i) => {
          const key = Object.keys(variable)[0];
          const value = variable[key];
          const valid = value.match(/^\w+(?:\|\w+){3}$/);
          return (
            <FormGroup
              intent={!valid ? Intent.WARNING : undefined}
              key={i}
              helperText={!valid && "Invalid string variable."}
              style={{ width: "100%" }}
            >
              <Flex justifyContent="flex-start" style={{ marginBottom: 8 }} fullWidth>
                <code>
                  <HTMLSelect
                    onChange={(e) => keyChangeHandler(e, key, value)}
                    value={key}
                    options={availableVariables.concat([key])}
                  />
                </code>{" "}
                <Colon>:</Colon>
                <code style={{ flex: 1 }}>
                  <InputGroup
                    style={{ fontStyle: "monospace" }}
                    onChange={(e) => valueChangeHandler(e, key)}
                    value={value}
                  />
                </code>
                <Divider />
                <Button
                  intent={Intent.DANGER}
                  icon="delete"
                  onClick={() => removeTemplateVariable(key)}
                />
              </Flex>
            </FormGroup>
          );
        })}
        <Flex>
          <Tooltip
            disabled={availableVariables.length >= 1}
            content="All available variables have been used!"
          >
            <AnchorButton
              disabled={availableVariables.length < 1}
              icon="plus"
              onClick={addTemplateVariable}
              text="Add Variable"
            />
          </Tooltip>
        </Flex>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" onClick={onClose} text="Close" />
        </div>
      </div>
    </Dialog>
  );
};

export default EditTemplateVariablesDialog;
