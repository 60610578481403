import React, { useMemo } from "react";
import { Classes, Spinner, SpinnerSize, Tooltip } from "@blueprintjs/core";
import styled, { css } from "styled-components";
import { Themes } from "constants/uiConstants";
import colors from "styles/colors.module.scss";
import { ISize, TTheme } from "types";

interface IStyledProfileImage {
  theme: TTheme;
  $loading: boolean;
  $fontSize: number;
  $fill: boolean;
  size: ISize;
}

export const StyledProfileImage = styled.div<IStyledProfileImage>`
  align-items: center;
  background-color: ${({ theme }) => (theme === Themes.LIGHT ? colors.lightGray5 : colors.gray2)};
  color: ${({ theme }) => (theme === Themes.LIGHT ? colors.textColor : colors.textColorDark)};
  border-radius: 50%;
  display: flex;
  font-size: ${({ $fontSize }) => `${$fontSize}px`};
  justify-content: ${({ $loading }) => ($loading ? "flex-start" : "center")};
  ${({ $fill, size }) =>
    css`
      height: ${$fill ? "100%" : `${size.height}px`};
      width: ${$fill ? "100%" : `${size.width}px`};
    `}
`;

interface IProfileImage {
  fullname?: string;
  fill?: boolean;
  fontSize?: number;
  size?: ISize;
  theme: TTheme;
  tooltip?: boolean;
}

const ProfileImage: React.FC<IProfileImage> = ({
  fullname,
  fill = false,
  fontSize = 11,
  size = { height: 20, width: 20, position: null },
  theme,
  tooltip = false,
}) => {
  const initials = useMemo(() => {
    if (fullname) {
      const matches = fullname.match(/\b(\w)/g);
      if (matches) return [matches[0], matches[matches?.length - 1]].join("").toUpperCase();
    }
    return null;
  }, [fullname]);

  if (tooltip) {
    return (
      <Tooltip content={`Created by: ${fullname}`}>
        <StyledProfileImage
          className={Classes.ELEVATION_0}
          $fontSize={fontSize}
          $fill={fill}
          $loading={!initials}
          size={size}
          theme={theme}
        >
          {initials || <Spinner size={SpinnerSize.SMALL} />}
        </StyledProfileImage>
      </Tooltip>
    );
  }
  return (
    <StyledProfileImage
      className={Classes.ELEVATION_0}
      $fontSize={fontSize}
      $fill={fill}
      $loading={!initials}
      size={size}
      theme={theme}
    >
      {initials || <Spinner size={SpinnerSize.SMALL} />}
    </StyledProfileImage>
  );
};

export default ProfileImage;
