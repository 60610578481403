import React, { useContext } from "react";
import { Icon, Position } from "@blueprintjs/core";
import colors from "styles/colors.module.scss";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import SideBar, { SideBarItem, SideBarItemIcon, SideBarTooltip } from "./SideBar";
import { Milieu } from "constants/appConstants";

const ModelSideBar = ({ appContext, activeSideMenu, setActiveSideMenu }) => {
  const {
    config: { theme },
  } = useContext(AppContext);

  const itemClick = (idx) => {
    if (activeSideMenu === idx) {
      setActiveSideMenu(0);
    } else {
      setActiveSideMenu(idx);
    }
  };

  const itemColor = (idx) => {
    if (idx === activeSideMenu) return theme === Themes.LIGHT ? colors.blue3 : colors.blue4;
    else return theme === Themes.LIGHT ? colors.gray2 : colors.gray4;
  };

  return (
    <SideBar>
      {
        appContext === Milieu.ModelData ? (
          <>
            <SideBarTooltip content="Explorer" position={Position.RIGHT} openOnTargetFocus={false}>
              <SideBarItem active={activeSideMenu === 1} onClick={() => itemClick(1)} theme={theme}>
                <Icon color={itemColor(1)} icon="diagram-tree" size={20} />
              </SideBarItem>
            </SideBarTooltip>

            <SideBarTooltip content="Search" position={Position.RIGHT} openOnTargetFocus={false}>
              <SideBarItem active={activeSideMenu === 2} onClick={() => itemClick(2)} theme={theme}>
                <SideBarItemIcon color={itemColor(2)} icon="search" size={20} />
              </SideBarItem>
            </SideBarTooltip>

            {/* TODO: Disabled until we reimplement history
          <SideBarTooltip content="History" position={Position.RIGHT} openOnTargetFocus={false}>
            <SideBarItem active={activeSideMenu === 3} onClick={() => itemClick(3)} theme={theme}>
              <Icon color={itemColor(3)} icon="history" size={20} />
            </SideBarItem>
          </SideBarTooltip>*/}
          </>
        ) : null
        /* TODO: reimplement view management or delete
        {
          <SideBarItem active={activeSideMenu === 1} onClick={() => itemClick(1)} theme={theme}>
            <SideBarTooltip content="Widgets" position={Position.RIGHT} openOnTargetFocus={false}>
              <Icon color={itemColor(1)} icon="new-grid-item" size={20} />
            </SideBarTooltip>
          </SideBarItem>
        } */
      }
    </SideBar>
  );
};

export default ModelSideBar;
