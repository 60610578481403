import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SizeMe } from "react-sizeme";
import { Classes, Drawer, Position } from "@blueprintjs/core";
import { connect } from "react-redux";

import DagreGraph from "components/utility/DagreGraph";
import { moduleDiagramSelector } from "selectors/dataEntrySelectors";
import { toggleDiagramOpen } from "actions/globalUIActions";
import AppContext from "../../context/appContext";
import { Themes } from "../../constants/uiConstants";
import { vsNavigate } from "../../utils/generic";

const StyledSizedGraphWrapper = styled.div`
  height: calc(100% - 70px);
  margin: 15px;
  width: 100%;
`;

const ModuleDiagram = ({ currentModuleUid, data, diagramOpen, modules, toggleDiagramOpen }) => {
  const navigate = useNavigate();
  const {
    config: { theme },
  } = useContext(AppContext);

  return (
    <Drawer
      className={theme === Themes.DARK ? Classes.DARK : null}
      hasBackdrop={false}
      icon="layout-hierarchy"
      isOpen={diagramOpen}
      onClose={toggleDiagramOpen}
      position={Position.BOTTOM}
      title="Module Hierarchy"
    >
      <SizeMe monitorHeight>
        {({ size }) => (
          <StyledSizedGraphWrapper>
            {data ? (
              <DagreGraph
                currentModule={currentModuleUid}
                height={size.height}
                links={data.links}
                nodes={data.nodes}
                onClick={(moduleId) => navigate(vsNavigate(modules[moduleId].name))}
                theme={theme}
                unlinked={data.unlinked}
                width={size.width - 30}
              />
            ) : null}
          </StyledSizedGraphWrapper>
        )}
      </SizeMe>
    </Drawer>
  );
};

function mapStateToProps(state) {
  return {
    currentModuleUid: state.model.currentModuleUid,
    data: moduleDiagramSelector(state),
    diagramOpen: state.ui.global.diagramOpen,
    modules: state.model.modules,
  };
}

const mapDispatchToProps = { toggleDiagramOpen };

export default connect(mapStateToProps, mapDispatchToProps)(ModuleDiagram);
