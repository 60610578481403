import React, { Dispatch, SetStateAction, useContext } from "react";
import { Menu, MenuItem } from "@blueprintjs/core";

import { ILineItem, IModule } from "types/model";
import { TNormalizedImportLineItem, TNormalizedImportModule } from "types/import";
import TreeContext from "context/treeContext";

interface IExplorerContextMenuProps {
  lineItem?: ILineItem | TNormalizedImportLineItem;
  moduleInfo?: IModule | TNormalizedImportModule;
  setEditTagDialogOpen?: Dispatch<SetStateAction<boolean>>;
}

const ExplorerContextMenu: React.FC<IExplorerContextMenuProps> = ({
  lineItem,
  moduleInfo,
  setEditTagDialogOpen,
}) => {
  const { locked, setEditingNode } = useContext(TreeContext);
  return (
    <Menu>
      <MenuItem
        disabled={locked}
        onClick={(e) => {
          e.stopPropagation();
          if (moduleInfo) {
            setEditingNode({ uid: moduleInfo.uid, type: "name" });
          } else if (lineItem) {
            setEditingNode({ uid: lineItem.uid, type: "name" });
          }
        }}
        shouldDismissPopover={true}
        style={{ alignItems: "center" }}
        text="Rename"
      />
      {lineItem && setEditTagDialogOpen ? (
        <MenuItem
          disabled={locked}
          onClick={() => {
            setEditTagDialogOpen(true);
            setEditingNode({ uid: lineItem.uid, type: "tags" });
          }}
          text="Edit Tags"
        />
      ) : null}
    </Menu>
  );
};

export default ExplorerContextMenu;
