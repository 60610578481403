import { captureException } from "@sentry/react";
import { Intent } from "@blueprintjs/core";
import {
  actionChannel,
  all,
  call,
  cancel,
  delay,
  flush,
  fork,
  join,
  put,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import { getToken } from "utils/auth";
import * as consts from "constants/dataEntryConstants";
import {
  BUFFERED_SOCKET_ACTIONS,
  BUFFERED_SOCKET_RESPONSE_ACTIONS,
  CLOSE_SOCKET,
  OPEN_SOCKET,
  SOCKET_ACTIONS,
} from "constants/dataEntryConstants";
import {
  createDataEventChannel,
  createWebSocketConnection,
  restart,
  StatusMap,
} from "utils/sockets";
import { modelUidSelector } from "selectors/modelSelectors";
import { updateAppStatus } from "actions/globalUIActions";
import { closeSocket, committed, committing, setCommitError } from "actions/dataEntryActions";
import {
  appStates,
  EDIT_SOCKET_ROUTE,
  socketMessages,
  Status as statusTypes,
} from "constants/apiConstants";
import { lockUI, unlockUI } from "actions/dataEntryUIActions";
import { multiToaster, singleToaster } from "utils/toaster";
import { addLineItemSFX, editFormulaPreFX } from "./dataEntrySagas";
import { checkConnection } from "utils/api";

/**
 * Feedback the current app status. Utility function to improve saga readability.
 * */
export function* changeAppStatus(intent, nextState, loading, icon, isCreate) {
  const message =
    typeof nextState === "string"
      ? socketMessages[nextState.replace("_", " ")] || nextState
      : nextState;
  if (isCreate) singleToaster.show({ intent, message, icon });
  /* for now stop showing socket toasts, we have other UI elements to convey appStatus
  else socketToaster.show({ intent, message, icon });
   */
  yield put(
    updateAppStatus({
      intent,
      loading,
      message: nextState,
      icon,
    })
  );
}

/**
 * Gets a socket. Can't just use the "retry" effect as we want to show more sophisticated feedback to
 * the user.
 *
 * Retry pattern from: https://redux-saga.js.org/docs/recipes/
 * */
function* getSocket(modelID) {
  let error;
  for (let i = 0; i < 5; i++) {
    try {
      const token = yield call(getToken, "accessToken");
      return yield call(createWebSocketConnection, `${EDIT_SOCKET_ROUTE}/${token}/${modelID}`);
    } catch (err) {
      if (i === 3) {
        yield call(changeAppStatus, Intent.PRIMARY, appStates.RETRYING, true);
      }
      if (i < 5) {
        yield delay(i * 600);
      }
      error = err;
    }
  }
  return error;
}

/**
 * Refetch the undo history
 * */
/*function* refetchUndoHistory() {
  try {
    const modelID = yield select(modelUidSelector);
    const currentCase = yield select(currentCaseSelector);
    yield put(fetchUndoHistory(modelID, currentCase.uid));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}*/
/**
 * Helper function to handle the resync flow
 * */
export function* resyncWithServer() {
  // Update UI
  yield call(changeAppStatus, Intent.WARNING, appStates.RESYNCING, true, "warning-sign");
  yield all([put(committed(true)), put(setCommitError(false))]);

  // Refetech the active case
  //const currentCase = yield select(currentCaseSelector);
  //yield call(fetchCase, { payload: { caseUid: currentCase.uid } });
  //yield call(refetchUndoHistory);

  // Update UI
  yield call(changeAppStatus, Intent.SUCCESS, appStates.CONNECTED, false, null);
  yield put(unlockUI());
}

/** Helper to handle message errors */
function* handleMessageError(error) {
  yield call(resyncWithServer);
  multiToaster.show({ icon: "error", intent: Intent.DANGER, message: error });
  yield all([put(committed(false)), put(setCommitError(true))]);
}

/**
 * Handle any side effects before sending action to server
 * */
function* preProcessAction(action) {
  try {
    const { type } = action;
    switch (type) {
      case consts.EDIT_FORMULA:
        yield editFormulaPreFX(action);
        break;

      case consts.EDIT_LINE_ITEMS: {
        // cancel if trying to name a temp
        const li = action.payload.params?.lineItems?.[0];
        if (li && li.uid.indexOf("temp") !== -1) yield cancel();
        break;
      }

      default:
        break;
    }

    const actionRedux = { ...action };
    actionRedux.type += "_REDUX";
    yield put(actionRedux);

    /* In case we need to do something after the reducer runs
    switch (type) {
      default:
        break;
    }*/

    return action;
  } catch (err) {
    //eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
  }
}

/** Handle any side effects if the response action */
function* handleResponseSideEffects(action) {
  try {
    const { type } = action;
    switch (type) {
      case consts.EDIT_FORMULA_SUCCESS: {
        if (action.payload?.originalAction?.customSuccessMsg) {
          multiToaster.show({
            intent: Intent.SUCCESS,
            message: action.payload.originalAction.customSuccessMsg,
          });
        }
        action.type += "_REDUX";
        yield put(action);
        break;
      }
      case consts.RECALCULATE_MODEL_SUCCESS: {
        yield put(unlockUI());
        const { data } = action.payload;
        let factsLength;
        if (data?.facts?.length) factsLength = data?.facts?.length;
        singleToaster.show({
          message: `Model sucessfully recalculated.${
            !Number.isNaN(factsLength) ? ` ${factsLength} fact(s) updated.` : ""
          }`,
          intent: Intent.SUCCESS,
        });
        break;
      }
      case consts.RECALCULATE_MODEL_FAILURE: {
        singleToaster.show({
          message: "Server error when recalculating model. Resyncing.",
          intent: Intent.SUCCESS,
        });
        yield call(resyncWithServer());
        break;
      }
      case consts.ADD_LINE_ITEM_SUCCESS: {
        action.type += "_REDUX";
        yield call(addLineItemSFX, action.payload.data);
        yield put(action);
        break;
      }
      default:
        yield put(action);
        break;
    }
  } catch (err) {
    //eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
  }
}

/**
 * Special handler to synchronously dispatch and receive undo/redo responses.
 *
 * Actions are proxied through this handler prior to being dispatched to the reducer. This allows
 * us to ensure otherwise async actions are blocking when in response to an undo
 * */
/*function* undoRedoHandler(socket) {
  const undoChannel = yield actionChannel([UNDO, REDO]);
  const undoRespActionChannel = yield actionChannel(Object.values(UNDO_RESPONSE_ACTIONS));
  try {
    while (true) {
      const undo = yield take(undoChannel);
      yield put(lockUI());
      try {
        yield call(sendMessage, socket, undo);
      } catch (err) {
        // Cleanup
        // yield all([flush(undoChannel), flush(undoRespActionChannel)]);
        throw new Error(err.message);
      }

      // Take the next undo response from the buffer.
      const [undoRespSuccessAction, successTimeout] = yield race([
        take(undoRespActionChannel),
        delay(25000),
      ]);

      if (undoRespSuccessAction) {
        const { status: successStatus } = undoRespSuccessAction;
        if (successStatus === statusTypes.SUCCESS) {
          const [undoRespAction, finishedTimeout] = yield race([
            take(undoRespActionChannel),
            delay(25000),
          ]);
          if (undoRespAction) {
            const { payload, status } = undoRespAction;
            if (status === statusTypes.FINISHED) {
              // Type doesn't need _UNDO, we use the undoRedo flag inside if needed
              const action = deriveActionFromMessage(payload.data, status, false);
              if (payload.data.buffered) {
                action.type = `${action.type}_REDUX`;
              }
              yield call(handleResponseSideEffects, action);
            } else {
              // TODO - Something has gone wrong
              yield call(resyncWithServer);
            }
          } else if (finishedTimeout) {
            yield flush(undoChannel);
            yield flush(undoRespActionChannel);
            yield call(resyncWithServer);
          }
        } else if (successStatus === statusTypes.FAILED) {
          yield put(setCommitError(false));
          yield flush(undoChannel);
          yield flush(undoRespActionChannel);
        } else {
          // Something bad has happened.
          yield call(resyncWithServer);
        }
      } else if (successTimeout) {
        yield flush(undoChannel);
        yield flush(undoRespActionChannel);
        yield call(resyncWithServer);
      }
      yield put(unlockUI());
    }
  } catch (err) {
    //eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
  }
}*/

/**
 * Generic message handler and dispatcher. Creates the appropriate action type and destroys
 * */
function* receiveMessage(payload) {
  let { action, data, error, resync, status } = JSON.parse(payload.data);
  if (error) {
    yield call(handleMessageError, error, data);
  }

  if (resync) {
    yield call(resyncWithServer);
    yield cancel();
  }

  const respAction = { type: action + "_SUCCESS", status: StatusMap[status], payload: { data } };
  switch (status) {
    case statusTypes.SUCCESS: {
      yield all([put(committed(false)), put(setCommitError(false))]);
      yield call(handleResponseSideEffects, respAction);
      break;
    }
    case statusTypes.FAILED:
      yield all([put(committed(false)), put(setCommitError(true))]);
      break;
    default:
      yield all([put(committed(false)), put(setCommitError(false))]);
      yield put(respAction);
  }
}

/**
 * Listen to all socket actions and send a message via the socket.
 * Uses a while loop to set up an always-on listener.
 * */
function* sendMessage(socket, action) {
  const modelID = yield select(modelUidSelector);
  const token = yield call(getToken, "accessToken");
  const processedAction = yield preProcessAction(action);
  const { payload, type } = processedAction;

  let message = {
    action: type,
    modelID,
    token,
  };

  if (payload && payload.params) message = { ...message, ...payload.params };

  if (socket.readyState === WebSocket.OPEN) {
    yield put(committing());
    socket.send(JSON.stringify(message));
  } else {
    yield call(changeAppStatus, Intent.DANGER, appStates.CONNECTION_ERROR, false, "warning-sign");
    yield call(resyncWithServer);
    if (socket?.close) socket.close();
    yield put(closeSocket());
  }
}

/**
 * Send buffered messages down the socket. No message will be sent until the previous message has
 * been completed
 * */
function* sendBufferedMessage(socket) {
  const actionChan = yield actionChannel(Object.values(BUFFERED_SOCKET_ACTIONS));
  const respActionChan = yield actionChannel(Object.values(BUFFERED_SOCKET_RESPONSE_ACTIONS));

  while (true) {
    const action = yield take(actionChan);

    try {
      yield call(sendMessage, socket, action);
    } catch (err) {
      // Cleanup
      yield all([flush(actionChan), flush(respActionChan)]);
      throw new Error(err.message);
    }
    /*const successAction = yield take(respActionChan);
    if (successAction.status === statusTypes.SUCCESS)
      yield call(handleResponseSideEffects, successAction);*/
  }
}

function* closeChannel(channel) {
  yield takeEvery(CLOSE_SOCKET, function* () {
    yield call(channel.close);
  });
}

/**
 * Used to create a model socket context
 * */
function* createSocketContext(modelID) {
  try {
    let appStatus = yield select((state) => state.ui.global.appStatus);
    if (!checkConnection(appStatus)) {
      yield call(changeAppStatus, Intent.DANGER, appStates.CONNECTION_ERROR, false);
      return;
    }
    yield call(changeAppStatus, Intent.PRIMARY, appStates.CONNECTING, true);
    const socket = yield call(getSocket, modelID);
    if (socket?.type === "error") {
      yield call(changeAppStatus, Intent.DANGER, appStates.CONNECTION_ERROR, false);
      return;
    } else {
      yield call(changeAppStatus, Intent.SUCCESS, appStates.CONNECTED, false);
      yield put(unlockUI());
    }

    // Channels
    const channel = yield call(createDataEventChannel, socket);

    const handlers = [
      { saga: closeChannel, args: [channel] },
      { saga: sendBufferedMessage, args: [socket, channel] },
      {
        saga: function* sendMessageWrapper() {
          yield takeEvery(Object.values(SOCKET_ACTIONS), sendMessage, socket);
        },
        args: [],
      },
      {
        saga: function* receiveMessageWrapper() {
          let receiveMessageTask;
          try {
            receiveMessageTask = yield takeEvery(channel, receiveMessage);
            yield join(receiveMessageTask);
          } finally {
            // TODO: this should be handled elsewhere once we improve socket flows
            // distinguish between close or crash/drop. isRunning is true if it's a close socket req
            if (!receiveMessageTask.isRunning) {
              yield call(changeAppStatus, Intent.DANGER, appStates.CONNECTION_ERROR, false);
              yield put(lockUI());
            }
            yield cancel();
          }
        },
        args: [],
      },
      /*{
        saga: undoRedoHandler,
        args: [socket],
      },*/
    ];
    yield all(handlers.map((saga) => fork(restart, saga)));
  } catch (err) {
    yield call(changeAppStatus, Intent.DANGER, appStates.CONNECTION_ERROR, false);
    yield cancel();
  }
}

/**
 * Called in the rootSaga. Starts the processes necessary to open a socket.
 **/
function* connect(action) {
  try {
    const { modelID } = action.payload;
    let socketContextTask = yield fork(createSocketContext, modelID);
    yield take([CLOSE_SOCKET, OPEN_SOCKET]);
    yield cancel(socketContextTask);
    yield cancel();
  } catch (err) {
    yield cancel();
    yield call(changeAppStatus, Intent.DANGER, appStates.CONNECTION_ERROR, false, "warning-sign");
  }
}

export default connect;
