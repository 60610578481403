import { combineReducers } from "redux";
import createModelReducer from "reducers/createModelReducer";
import modelReducer from "reducers/modelReducer";
import uiRootReducer from "reducers/uiRootReducer";
import sharePriceReducer from "reducers/sharePriceReducer";
import authReducer from "reducers/authReducer";
import eventHistoryReducer from "reducers/eventHistoryReducer";
import dataEntryRootReducer from "reducers/dataEntryRootReducer";

const appReducer = combineReducers({
  auth: authReducer,
  create: createModelReducer,
  dataEntry: dataEntryRootReducer,
  eventHistory: eventHistoryReducer,
  model: modelReducer,
  sharePrice: sharePriceReducer,
  ui: uiRootReducer,
});

export type TRootState = ReturnType<typeof appReducer> | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: TRootState, action: any) => {
  // When a SIGN_OUT action is dispatched, the redux store will reset to its initial state
  if (action.type === "SIGN_OUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
