import { useCallback, useState } from "react";
import { ViewWidget } from "types/view";
import { TableFilters, TableWidgetConfig } from "types/widget";
import { useDebouncedCallback } from "hooks/useDebounce";

export const useTableFilter = (
  tableConfig?: TableWidgetConfig,
  updateWidget?: (details: Partial<ViewWidget>) => Promise<boolean>
) => {
  const [tableFilters, setTableFilters] = useState<TableFilters>(tableConfig?.filters ?? {});

  const setAndUpdate = useCallback(
    async (newFilters: TableFilters) => {
      setTableFilters(newFilters);
      if (updateWidget) {
        return await updateWidget({
          config: { ...tableConfig, filters: newFilters },
        });
      }

      return true;
    },
    [tableConfig, updateWidget]
  );

  const toggleTableFilter = async (columnId: string, filter: string | undefined) => {
    const matchingFilter = (tableFilters?.columnFilters?.[columnId] ?? []).find(
      (existingFilter) => existingFilter === filter
    );

    if (matchingFilter) {
      return await removeTableFilter(columnId, filter);
    } else {
      return await addTableFilter(columnId, filter);
    }
  };

  const addTableFilter = async (columnId: string, filter: string | undefined): Promise<boolean> => {
    if (!filter) {
      return false;
    }

    const newFilters: TableFilters = {
      ...tableFilters,
      columnFilters: {
        ...tableFilters.columnFilters,
        [columnId]: [...(tableFilters?.columnFilters?.[columnId] ?? []), filter],
      },
    };

    return await setAndUpdate(newFilters);
  };

  const removeTableFilter = async (
    columnId: string,
    filter: string | undefined
  ): Promise<boolean> => {
    if (!filter || !tableFilters?.columnFilters?.[columnId]) {
      return false;
    }

    const filterIndex = (tableFilters?.columnFilters?.[columnId] ?? []).findIndex(
      (existingFilter) => existingFilter === filter
    );

    if (filterIndex >= 0) {
      const newFilters: TableFilters = {
        ...tableFilters,
        columnFilters: {
          ...tableFilters.columnFilters,
          [columnId]: [
            ...[...tableFilters.columnFilters[columnId]].splice(0, filterIndex),
            ...[...tableFilters.columnFilters[columnId]].splice(filterIndex + 1),
          ],
        },
      };

      return await setAndUpdate(newFilters);
    }

    return true;
  };

  const clearTableFilters = async () => {
    return await setAndUpdate({});
  };

  const setGlobalTableFilter = useDebouncedCallback(
    useCallback(
      async (globalFilter: string) => {
        return await setAndUpdate({ allColumns: globalFilter });
      },
      [setAndUpdate]
    )
  );

  return {
    tableFilters,
    toggleTableFilter,
    addTableFilter,
    removeTableFilter,
    clearTableFilters,
    setGlobalTableFilter,
  };
};
