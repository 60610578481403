import { Button, NumericInput } from "@blueprintjs/core";
import { Flex } from "components/utility/StyledComponents";
import React, { useState } from "react";
import { ChartWidgetConfig } from "types/widget";
import { YAxisId } from "constants/chartConstants";
import { EValFormat, IFormatObject, SuffixToUnitMap } from "types/format";
import { applyUnit, getRawValueFromUnit } from "utils/formatter";
import { isNil } from "lodash";
import { isYAxisPercentage } from "utils/chartUtils";

interface YAxisMinMaxMenuProps {
  axis: YAxisId;
  chartRendererConfig?: ChartWidgetConfig;
  configFormat?: Partial<IFormatObject>;
  chartYAxisMin?: number | null;
  chartYAxisMax?: number | null;
  updateConfig: (details: Partial<ChartWidgetConfig>) => void;
}

const YAxisMinMaxMenu: React.FC<YAxisMinMaxMenuProps> = ({
  axis,
  chartRendererConfig,
  configFormat,
  chartYAxisMin,
  chartYAxisMax,
  updateConfig,
}) => {
  const yAxisConfig = chartRendererConfig?.yAxis?.[axis];
  const configYAxisMin = yAxisConfig?.min;
  const configYAxisMax = yAxisConfig?.max;

  const suffix = `${configFormat?.unit ? SuffixToUnitMap[configFormat?.unit] : ""}${
    configFormat?.valFormat === EValFormat.PERCENTAGE ? "%" : ""
  }`;

  const [minValue, setMinValue] = useState(configYAxisMin);
  const [maxValue, setMaxValue] = useState(configYAxisMax);

  // Returns value formatted by unit and percentage.
  const getFormattedValue = (value: number): number => {
    const valueWithUnit = applyUnit(value, configFormat?.unit);
    const formattedValue =
      configFormat?.valFormat === EValFormat.PERCENTAGE ? valueWithUnit * 100 : valueWithUnit;

    return parseFloat(formattedValue.toFixed(10));
  };

  // Returns value without unit/percentage formatting.
  const getRawValue = (value: number): number => {
    const valueWithoutUnit = getRawValueFromUnit(value, configFormat?.unit);

    return configFormat?.valFormat === EValFormat.PERCENTAGE
      ? valueWithoutUnit / 100
      : valueWithoutUnit;
  };

  const handleMinChange = (value?: number, isRaw?: boolean) => {
    const rawValue = value && !isRaw ? getRawValue(value) : value;

    if (rawValue || rawValue === 0 || isRaw) {
      setMinValue(rawValue);
      updateConfig({
        yAxis: {
          ...(chartRendererConfig?.yAxis && { ...chartRendererConfig?.yAxis }),
          [axis]: { ...yAxisConfig, min: rawValue },
        },
      });
    }
  };

  const handleMaxChange = (value?: number, isRaw?: boolean) => {
    const rawValue = value && !isRaw ? getRawValue(value) : value;

    if (rawValue || rawValue === 0 || isRaw) {
      setMaxValue(rawValue);
      updateConfig({
        yAxis: {
          ...(chartRendererConfig?.yAxis && { ...chartRendererConfig?.yAxis }),
          [axis]: { ...yAxisConfig, max: rawValue },
        },
      });
    }
  };

  const isPercentageTransformation = isYAxisPercentage(axis, chartRendererConfig);

  return (
    <>
      <Flex justifyContent="space-between">
        <Button
          active={configYAxisMin !== void 0}
          minimal
          disabled={isPercentageTransformation}
          onClick={() =>
            handleMinChange(configYAxisMin === void 0 ? chartYAxisMin || 0 : void 0, true)
          }
          outlined
          small
          style={{ marginRight: 5, width: 70 }}
          text="Min"
        />
        <NumericInput
          allowNumericCharactersOnly
          asyncControl={true}
          disabled={configYAxisMin === void 0}
          onValueChange={(value) => handleMinChange(value)}
          small
          style={{ width: 130 }}
          value={isNil(minValue) ? "Click Min to activate" : getFormattedValue(minValue)}
          rightElement={
            !isNil(minValue) ? <div style={{ margin: "3px 5px 0px 0px" }}>{suffix}</div> : null
          }
        />
      </Flex>
      <Flex justifyContent="space-between" style={{ marginTop: 5 }}>
        <Button
          active={configYAxisMax !== void 0}
          minimal
          disabled={isPercentageTransformation}
          onClick={() =>
            handleMaxChange(configYAxisMax === void 0 ? chartYAxisMax || void 0 : void 0, true)
          }
          outlined
          small
          style={{ marginRight: 5, width: 70 }}
          text="Max"
        />
        <NumericInput
          style={{ width: 130 }}
          asyncControl={true}
          value={isNil(maxValue) ? "Click Max to activate" : getFormattedValue(maxValue)}
          onValueChange={(value) => handleMaxChange(value)}
          disabled={configYAxisMax === void 0}
          small
          allowNumericCharactersOnly
          rightElement={
            !isNil(maxValue) ? <div style={{ margin: "3px 5px 0px 0px" }}>{suffix}</div> : null
          }
        />
      </Flex>
    </>
  );
};

export default YAxisMinMaxMenu;
