import { Intent } from "@blueprintjs/core";
import { IS_VALID_CSV } from "constants/regexContants";
import { multiToaster } from "utils/toaster";

const MAX_REJECTED_TICKERS_TO_DISPLAY = 5;

interface GroupedTickers {
  matchingTickers: string[];
  unMatchedTickers: string[];
}

/**
 * group tickers from file for those matching and those unmatching
 */
export const groupTickersFromFile = async (
  file: File,
  tickersToMatch: string[]
): Promise<GroupedTickers> => {
  const tickers = await readTickersFromFile(file);

  if (tickers.length === 0) return { matchingTickers: [], unMatchedTickers: [] };

  return tickers.reduce(
    (results: GroupedTickers, ticker) => {
      const matchingTicker = tickersToMatch.find(
        (val) => val.toLowerCase() === ticker.toLowerCase()
      );

      if (matchingTicker) {
        return {
          ...results,
          matchingTickers: [...results.matchingTickers, ticker],
        };
      }

      return {
        ...results,
        unMatchedTickers: [...results.unMatchedTickers, ticker],
      };
    },
    { matchingTickers: [], unMatchedTickers: [] }
  );
};

/**
 * helper function for displaying toasts depending on matched or unMatched tickers
 */
export const showToastsFromTickers = (
  matchingTickers: string[],
  unmatchedTickers: string[]
): void => {
  if (matchingTickers.length === 0 && unmatchedTickers.length === 0) {
    return;
  }

  if (matchingTickers.length === 0) {
    multiToaster.show({
      intent: Intent.WARNING,
      message: "None of the tickers provided were available.",
    });

    return;
  }

  if (unmatchedTickers.length > 0) {
    multiToaster.show({
      intent: Intent.WARNING,
      message: unMatchedTickersMessage(unmatchedTickers),
    });
  }

  multiToaster.show({
    intent: Intent.SUCCESS,
    message: `Your tickers were successfully uploaded.`,
  });
};

const unMatchedTickersMessage = (unmatchedTickers: string[]): string =>
  `The following tickers are not available: ${unmatchedTickers
    .slice(0, MAX_REJECTED_TICKERS_TO_DISPLAY)
    .join(",")}${unmatchedTickers.length > MAX_REJECTED_TICKERS_TO_DISPLAY ? "..." : ""}`;

/**
 * read tickers from csv file, cleans up, and returns string[]
 */
const readTickersFromFile = async (file: File): Promise<string[]> => {
  const data = await file.text();

  if (!IS_VALID_CSV.test(data)) {
    multiToaster.show({
      intent: Intent.WARNING,
      message:
        "Invalid CSV format. Please use either comma-separated or newline-separated tickers.",
    });

    return [];
  }

  //strip out all " characters and then split on either , or newline chars
  return data
    .replace(/"/g, "")
    .split(/[,\n]/)
    .map((ticker) => ticker.trim())
    .filter((t) => t !== "");
};
