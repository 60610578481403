import { IResponse } from "types";
import { swrApi } from "utils/api";
import { FETCH_MODEL_VERSIONS_ROUTE } from "constants/apiConstants";
import useSWRImmutable from "swr/immutable";

interface IPreviousVersionsResponse {
  commitHistory: {
    modelid: string;
    commits: {
      hash: string;
      author: string;
      when: string;
      action: string;
    }[];
  };
}

const usePreviousVersions = (modelID: string) => {
  const { data, error, isLoading } = useSWRImmutable<
    IResponse<IPreviousVersionsResponse | undefined>
  >([FETCH_MODEL_VERSIONS_ROUTE, { modelID }], swrApi);

  const today = new Date().toDateString();
  let versions;
  if (data?.data?.commitHistory?.commits) {
    versions = data?.data?.commitHistory?.commits
      .map((commit) => {
        return {
          ...commit,
          date: new Date(commit.when),
        };
      })
      .filter((commit) => commit.date.toDateString() !== today);
  }

  return {
    isLoading,
    data: versions,
    error: error || (data?.error ? new Error(data.error) : null),
  };
};

export default usePreviousVersions;
