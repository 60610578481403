import React, { Fragment } from "react";
import { Button, Divider, Intent, Tag } from "@blueprintjs/core";

import { Flex } from "components/utility/StyledComponents";
import { TModelHistoryTablePartialModel } from "types/modelHistory";
import { CellContext } from "@tanstack/react-table";
import { IconNames } from "@blueprintjs/icons";

interface ITagCellProps {
  info: CellContext<TModelHistoryTablePartialModel, unknown>;
  onTagEdit?: (modelInfo: TModelHistoryTablePartialModel) => void;
}

const TagCell: React.FC<ITagCellProps> = ({ info, onTagEdit }) => {
  const tags = info.getValue<string[] | undefined>();
  const onAdd = onTagEdit ? () => onTagEdit(info.row.original) : undefined;
  return tags ? (
    <Flex justifyContent="space-between" flexWrap="wrap" rowGap={2}>
      <Flex flexWrap="wrap" justifyContent="flex-start" rowGap={2}>
        {tags.map((tag) => (
          <Fragment key={tag}>
            <Tag intent={Intent.PRIMARY} minimal round>
              {tag}
            </Tag>
            <Divider />
          </Fragment>
        ))}
      </Flex>
      {onAdd ? <Button icon={IconNames.EDIT} onClick={onAdd} outlined small /> : null}
    </Flex>
  ) : onAdd ? (
    <Button icon={IconNames.ADD} onClick={onAdd} outlined small />
  ) : null;
};

export default TagCell;
