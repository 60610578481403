import React from "react";
import VSHTMLTable from "components/dashboard/VSHTMLTable";
import { useSelector } from "react-redux";
import { cppTableWidths, fetchTagsData, getEffectivePeriodRange } from "utils/cpp";
import { lineItemsSelector, modelInfoSelector } from "selectors/modelSelectors";
import { factsSelector } from "selectors/dataEntrySelectors";
import { tagLineItemsMapSelector } from "selectors/cpp_selectors";
import "styles/sensitivity-analysis.scss";

const expectedReturnTags = [
  "Expected Return (%), Valuation (Base)",
  "Expected Return (%), Valuation (Upside)",
  "Expected Return (%), Valuation (Downside)",
  "Expected Return (%), Valuation (Consensus)",
];

const rowHeaders = ["Base", "Upside", "Downside", "Consensus"];

const CPP_ExpectedReturn = () => {
  const tagLineItemsMap = useSelector(tagLineItemsMapSelector);
  const lineItems = useSelector(lineItemsSelector);
  const facts = useSelector(factsSelector);
  const modelInfo = useSelector(modelInfoSelector);
  const startPeriod = modelInfo?.startPeriod;

  const header: number[] = getEffectivePeriodRange(startPeriod, tagLineItemsMap, lineItems, facts);

  const expectedReturnData = [
    ["", ...header],
    ...fetchTagsData(expectedReturnTags, header, tagLineItemsMap, lineItems, facts, rowHeaders),
  ];

  return <VSHTMLTable data={expectedReturnData} widths={cppTableWidths} />;
};

export default CPP_ExpectedReturn;
