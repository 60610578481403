import React, { useMemo, useRef } from "react";
import styled from "styled-components/macro";
import { Classes } from "@blueprintjs/core";
import GridLayout, { Layout as ILayout } from "react-grid-layout";
import { ISize } from "types";
import Legacy_Widget from "components/dashboard/model/Legacy_Widget";
import { EDashboardType, IVSWidget } from "types/dashboard";

interface IDashboardProps {
  childArray?: Array<{ element: JSX.Element; widget: IVSWidget }>;
  layout?: ILayout[];
  onLayoutChange?: (layouts: ILayout[]) => void;
  size: ISize;
  type: EDashboardType;
  widgets?: Array<IVSWidget>;
}

const StyledScrollContainer = styled.div<{
  $maxHeight: number;
  $width: number;
}>`
  max-height: ${({ $maxHeight }) => $maxHeight - 15 + "px"};
  min-height: 300px;
  width: ${({ $width }) => $width + "px"};
`;

const Dashboard = ({
  childArray,
  layout,
  onLayoutChange,
  size,
  type,
  widgets,
}: IDashboardProps): JSX.Element => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const children = useMemo(() => {
    if (!widgets) return;
    const widgetsCollection = childArray
      ? childArray
      : widgets.map((widget) => {
          return { widget: widget, element: null };
        });
    return widgetsCollection.map(({ widget, element }) => {
      return (
        <div
          className={`${Classes.ELEVATION_2} ${Classes.CARD}`}
          data-grid={widget.layout}
          key={widget.gridKey}
        >
          {childArray ? element : <Legacy_Widget key={widget.gridKey} {...widget} />}
        </div>
      );
    });
  }, [childArray, widgets]);

  let maxHeight = 50;
  if (size?.height) {
    maxHeight = type === EDashboardType.Edit ? size.height - 133 : size.height - 40;
  }

  const isScrollBarVisible = scrollContainerRef.current
    ? scrollContainerRef.current.scrollHeight >
      (size?.height || scrollContainerRef.current?.clientHeight)
    : false;

  let dashWidth = 800;
  if (size?.width) dashWidth = size?.width;
  if (isScrollBarVisible) dashWidth -= 25;

  return (
    <StyledScrollContainer $maxHeight={maxHeight} ref={scrollContainerRef} $width={dashWidth}>
      <GridLayout
        isDraggable={false}
        isResizable={false}
        layout={layout}
        onLayoutChange={onLayoutChange}
        isDroppable={true}
        rowHeight={1}
        // TODO: use same fix as for data tab, scroll bar width varies
        width={dashWidth}
      >
        {children}
      </GridLayout>
    </StyledScrollContainer>
  );
};

export default Dashboard;
