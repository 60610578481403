import React, { useCallback, useEffect, useMemo } from "react";
import { Auth, Hub } from "aws-amplify";
import { AuthState } from "constants/appConstants";
import { Classes, HotkeysProvider } from "@blueprintjs/core";
import { getNextAuthState } from "utils/auth";
import CompleteNewPassword from "components/user/CompleteNewPassword";
import Login from "components/user/Login";
import SetupTOTP from "components/user/SetupTOTP";
import TOTP from "components/user/TOTP";
import ForgotPassword from "components/user/ForgotPassword";
import { getDefaultTheme } from "utils/generic";
import { Themes } from "constants/uiConstants";
import { ValsysAppWrapper } from "components/utility/StyledComponents";
import { Outlet, useOutletContext } from "react-router-dom";

const Authentication = () => {
  const { authState, setAuthState, setUser, size, user } = useOutletContext();

  const handleHubChange = useCallback(
    (message) => {
      const nextAuthState = getNextAuthState(message);
      const { data } = message.payload;
      if (data?.userDataKey) {
        setUser(data);
      }
      if (nextAuthState) {
        setAuthState(nextAuthState);
      }
    },
    [setAuthState, setUser]
  );

  useEffect(() => {
    let isMounted = true;
    Auth.currentAuthenticatedUser()
      .then((resp) => {
        if (isMounted) {
          setUser(resp);
          setAuthState(AuthState.SignedIn);
        }
      })
      .catch(() => {
        // No Sentry here as we are handling.
        if (isMounted) {
          setUser(null);
          setAuthState(AuthState.SignIn);
        }
      });

    Hub.listen("auth", handleHubChange);

    return () => {
      isMounted = false;
      Hub.remove("auth", handleHubChange);
    };
  }, [handleHubChange, setAuthState, setUser]);

  // used to style non signed in
  const classes = useMemo(() => {
    if (getDefaultTheme() === Themes.DARK) {
      return `${Classes.DARK} dark-mode`;
    } else return "light-mode";
  }, []);

  // If we're signed in, or if REACT_APP_ENVIRONMENT is present and set as 'local', render the app
  if (authState === AuthState.SignedIn || window.__env__.REACT_APP_ENVIRONMENT === "local") {
    return (
      <HotkeysProvider>
        <Outlet context={{ size }} />
      </HotkeysProvider>
    );
  }

  // Otherwise we are in some AuthState. Handle appropriately.
  let authComponent;
  switch (authState) {
    case AuthState.SignIn:
      authComponent = <Login authState={authState} setAuthState={setAuthState} setUser={setUser} />;
      break;

    case AuthState.ConfirmSignIn:
      authComponent = <TOTP setAuthState={setAuthState} user={user} setUser={setUser} />;
      break;

    case AuthState.ForgotPassword:
      authComponent = (
        <ForgotPassword
          authState={authState}
          setAuthState={setAuthState}
          setUser={setUser}
          user={user}
        />
      );
      break;

    case AuthState.ConfirmSignUp:
      authComponent = <CompleteNewPassword user={user} setAuthState={setAuthState} />;
      break;

    // Adding out own AuthState to allow for SetupTOTP post initial login.
    case "setupTOTP":
      authComponent = <SetupTOTP setAuthState={setAuthState} inSettingsPage={false} />;
      break;

    default:
      authComponent = <Login authState={authState} setAuthState={setAuthState} setUser={setUser} />;
      break;
  }

  return (
    <ValsysAppWrapper className={classes} theme={getDefaultTheme()}>
      {authComponent}
    </ValsysAppWrapper>
  );
};

export default Authentication;
