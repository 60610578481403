import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { Button, Classes, Dialog, FormGroup, Intent, Label } from "@blueprintjs/core";
import { DateInput3 } from "@blueprintjs/datetime2";
import { IconNames } from "@blueprintjs/icons";
import AppContext from "context/appContext";
import ModelContext from "context/modelContext";
import { Themes } from "constants/uiConstants";
import keyby from "lodash.keyby";
import { IModelVersion } from "types/model";
import { multiToaster } from "utils/toaster";
import { useOnChangeFromTo } from "hooks/useOnChange";
import { connect } from "react-redux";

interface IPreviousVersionsDialog {
  locale: string;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  modelLoading: boolean;
  modelError: string | undefined;
}

export const PreviousVersionsDialog: React.FC<IPreviousVersionsDialog> = ({
  locale,
  isOpen,
  setOpen,
  modelLoading,
  modelError,
}) => {
  const today = new Date();
  const {
    config: { theme },
  } = useContext(AppContext);
  const {
    previousVersions: { activeVersion, setVersion, availableVersions },
  } = useContext(ModelContext);
  const effectiveDate = activeVersion?.date || today;
  const [localDate, setLocalDate] = useState<Date>(effectiveDate);
  const [changed, setChanged] = useState(false);
  const dates = availableVersions
    .map((version) => version.date)
    .sort((a, b) => a.valueOf() - b.valueOf());

  const availableVersionsByDate: Record<string, IModelVersion> = keyby(
    availableVersions,
    (version) => version.date.toDateString()
  );

  useOnChangeFromTo<boolean>(isOpen, false, true, () => {
    setLocalDate(effectiveDate);
    setChanged(false);
  });

  useOnChangeFromTo<boolean>(modelLoading, true, false, () => {
    if (!isOpen || !activeVersion) return;
    if (modelError) {
      multiToaster.show({
        message: "Failed to fetch model",
        intent: Intent.DANGER,
      });
      setVersion(null);
    } else {
      setOpen(false);
    }
  });

  const handleClose = () => {
    setOpen(false);
  };

  function submit() {
    const version =
      localDate.toLocaleDateString() === today.toLocaleDateString()
        ? null
        : availableVersionsByDate[localDate.toDateString()];
    if (!version) setOpen(false);
    setVersion(version);
  }

  const isDayDisabled = (day: Date) => {
    return ![...dates, today].some(
      (enabledDate) =>
        day.getDate() === enabledDate.getDate() &&
        day.getMonth() === enabledDate.getMonth() &&
        day.getFullYear() === enabledDate.getFullYear()
    );
  };

  const setDate = (date: string | null, isUserChange: boolean) => {
    if (!date) return;
    if (isUserChange) {
      setLocalDate(new Date(date));
      setChanged(true);
    }
  };

  return (
    <Dialog
      icon={IconNames.HISTORY}
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      title="Previous Versions"
      canOutsideClickClose={true}
      enforceFocus={true}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <Label style={{ fontWeight: "bold" }}>
            Select a date to view the model as at end-of-day on that date.
          </Label>
          <Label>
            Only past dates on which the model was updated will be available for selection.
          </Label>
          <FormGroup
            label="Date"
            helperText="Select today's date to view the up-to-date editable model."
          >
            <DateInput3
              canClearSelection={false}
              closeOnSelection={true}
              value={localDate.toISOString()}
              minDate={dates[0] || today}
              maxDate={today}
              onChange={setDate}
              formatDate={(date) => date.toLocaleDateString(locale)}
              parseDate={(str) => new Date(str)}
              dayPickerProps={{ disabled: isDayDisabled }}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              disabled={!changed}
              loading={modelLoading}
              intent={Intent.PRIMARY}
              type="submit"
              onClick={submit}
            >
              Apply
            </Button>
            <Button onClick={handleClose}>Close</Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

// TODO: - Replace this with the full state type.
// eslint-disable-next-line
function mapStateToProps(state: any) {
  return {
    locale: state.ui.global.localeString,
    modelLoading: state.model.loading,
    modelError: state.model.error,
  };
}

export default connect(mapStateToProps)(PreviousVersionsDialog);
