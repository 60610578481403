// Resync model
export const RESYNC_MODEL = "RESYNC_MODEL";

// Edit a model's properties
export const EDIT_MODEL_PROPERTIES = "EDIT_MODEL_PROPERTIES";
export const EDIT_MODEL_PROPERTIES_REDUX = "EDIT_MODEL_PROPERTIES_REDUX";
export const EDIT_MODEL_PROPERTIES_FAILURE = "EDIT_MODEL_PROPERTIES_FAILURE";

// Used to pull a model
export const PULL_MODEL = "PULL_MODEL";
export const PULL_MODEL_SUCCESS = "PULL_MODEL_SUCCESS";
export const PULL_MODEL_FAILURE = "PULL_MODEL_FAILURE";

// Used to fetch and store generic data
export const FETCH_DATA = "FETCH_DATA";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";

// CREATE VALUATION
export const FETCH_LOGO = "FETCH_LOGO";
export const FETCH_LOGO_SUCCESS = "FETCH_LOGO_SUCCESS";
export const FETCH_LOGO_FAILURE = "FETCH_LOGO_FAILURE";

// WIDGETS
export const UPDATE_SENSITIVITY_INCREMENT = "UPDATE_SENSITIVITY_INCREMENT";
export const UPDATE_SENSITIVITY_INCREMENT_REDUX = "UPDATE_SENSITIVITY_INCREMENT_REDUX";
export const UPDATE_SENSITIVITY_INCREMENT_SUCCESS = "UPDATE_SENSITIVITY_INCREMENT_SUCCESS";
export const UPDATE_SENSITIVITY_INCREMENT_FAILURE = "UPDATE_SENSITIVITY_INCREMENT_FAILURE";
export const UPDATE_SENSITIVITY_ANALYSIS_TYPE = "UPDATE_SENSITIVITY_ANALYSIS_TYPE";
export const UPDATE_CURRENT_ANALYSIS_TYPE = "UPDATE_CURRENT_ANALYSIS_TYPE";

// GENERIC WIDGET DATA, CONFIG BUILT INTO TO SELECTOR
export const FETCH_WIDGET_DATA = "FETCH_WIDGET_DATA";
export const FETCH_WIDGET_DATA_FAILURE = "FETCH_WIDGET_DATA_FAILURE";
export const FETCH_WIDGET_DATA_SUCCESS = "FETCH_WIDGET_DATA_SUCCESS";

// UPDATE METHODS
export const UPDATE_CURRENT_MODULE_REDUCER = "UPDATE_CURRENT_MODULE_REDUCER";

// CALCULATIONS
export const UPDATE_MODEL_CALCULATIONS = "UPDATE_MODEL_CALCULATIONS";
export const UPDATE_MODEL_CALCULATIONS_REDUX = "UPDATE_MODEL_CALCULATIONS_REDUX";
export const UPDATE_MODEL_CALCULATIONS_SUCCESS = "UPDATE_MODEL_CALCULATIONS_SUCCESS";
export const UPDATE_MODEL_CALCULATIONS_FAILURE = "UPDATE_MODEL_CALCULATIONS_FAILURE";

// MODULE TREE
export const UPDATE_MODULE_TREE = "UPDATE_MODULE_TREE";
export const UPDATE_EXPANDED_NODES = "UPDATE_EXPANDED_NODES";

// Consts for valuation data sub requests
export const COMPANY_INFORMATION = "COMPANY_INFORMATION";
export const SHARE_PRICE = "SHARE_PRICE";
export const CASH_FLOW = "CASH_FLOW";
export const TERMINAL_VALUE = "TERMINAL_VALUE";
export const SENSITIVITY_ANALYSES = "SENSITIVITY_ANALYSES";
export const SENSITIVITY_TABLE_ENTERPRISE = "SENSITIVITY_TABLE_ENTERPRISE";
export const SENSITIVITY_TABLE_IMPLIED = "SENSITIVITY_TABLE_IMPLIED";
export const IMPLIED_FORWARD_MULTIPLES = "IMPLIED_FORWARD_MULTIPLES";
export const COMPS = "COMPS";

// FLUSH
export const FLUSH_MODEL_REDUCER = "FLUSH_MODEL_REDUCER";

// OTHER
export const EXPORT_TO_EXCEL = "EXPORT_TO_EXCEL";
export const DEFAULT_ITERATION_OPTIONS = [10, 100, 1000, 9999];
export const DEFAULT_PRECISION_OPTIONS = [0.1, 0.01, 0.001, 0.0001];

export type TGridChangeOpt = "ADD_COLUMN" | "DELETE_COLUMN" | "ADD_ROW" | "DELETE_ROW";

export const GridChangeType: { [key: string]: TGridChangeOpt } = {
  ADD_COLUMN: "ADD_COLUMN",
  DELETE_COLUMN: "DELETE_COLUMN",
  ADD_ROW: "ADD_ROW",
  DELETE_ROW: "DELETE_ROW",
};
