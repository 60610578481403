import * as consts from "constants/eventHistoryConstants";
import { DefaultFormatComparisonObject } from "constants/eventHistoryConstants";
import { safeFactFormatJSONtoObject } from "utils/data";

const InitialState = {
  currentId: 0,
  fetchHistoryFailed: false,
  events: [],
  past: [],
  future: [],
  tagFilters: [],
  refetchingUndoHistory: false,
};

function eventHistoryReducer(state = InitialState, action) {
  switch (action.type) {
    case consts.UNDO_HISTORY:
      return {
        ...state,
        fetchingUndoHistory: true,
      };
    case consts.UNDO_HISTORY_SUCCESS: {
      const parsedEvents =
        action.payload.data.events &&
        action.payload.data.events.map((event, index) => ({
          ...event,
          ...(event.redo.facts && {
            redo: {
              ...event.redo,
              facts: safeFactFormatJSONtoObject(event.redo.facts, DefaultFormatComparisonObject),
            },
          }),
          ...(event.undo.facts && {
            undo: {
              ...event.undo,
              facts: safeFactFormatJSONtoObject(event.undo.facts, DefaultFormatComparisonObject),
            },
          }),
          index,
          displayDate: new Date(event.timestamp).toLocaleDateString(),
          displayAction: event.action.replace(/_/g, " "),
        }));
      return {
        ...state,
        events: parsedEvents,
        noHistory: !parsedEvents,
        fetchingUndoHistory: false,
      };
    }
    case consts.UNDO_HISTORY_FAILURE:
      return {
        ...state,
        fetchHistoryFailed: true,
      };
    case consts.ADD_EVENT_TAG_FILTER:
      return {
        ...state,
        tagFilters: Array.from(new Set([...state.tagFilters, action.payload.tag])),
      };
    case consts.CLEAR_EVENT_TAG_FILTERS:
      return {
        ...state,
        tagFilters: [],
      };
    case consts.REMOVE_EVENT_TAG_FILTER:
      return {
        ...state,
        tagFilters: state.tagFilters.filter((tag) => tag !== action.payload.tag),
      };
    case consts.FLUSH_EVENT_HISTORY_REDUCER:
      return InitialState;
    default:
      return state;
  }
}
export default eventHistoryReducer;
