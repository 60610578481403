import React, { useContext } from "react";
import styled from "styled-components/macro";
import { Alignment, Button, Divider, Icon, Position, Tooltip } from "@blueprintjs/core";
import AppContext from "context/appContext";
import { Themes } from "constants/uiConstants";
import { Link } from "react-router-dom";
import colors from "styles/colors.module.scss";
import { Flex } from "components/utility/StyledComponents";
import ValsysLogo from "./ValsysLogo";
import { TTheme } from "types";

const SideBar: React.FC = ({ children }) => {
  const {
    config: { theme },
    setLogoutDialogOpen,
    toggleTheme,
  } = useContext(AppContext);
  return (
    <StyledSideBar theme={theme}>
      <Flex flexDirection="column" justifyContent="space-between" fullHeight fullWidth>
        <Link to="/">
          <Flex style={{ marginTop: "10px", marginBottom: "-1px" }}>
            <ValsysLogo initials size={{ width: "20px", height: "20px" }} theme={theme} />
          </Flex>
        </Link>
        <Flex flexDirection="column" fullHeight fullWidth justifyContent="space-between">
          <div>{children}</div>
          <div>
            <SideBarTooltip
              content="Toggle Theme"
              position={Position.RIGHT}
              openOnTargetFocus={false}
            >
              <Button
                alignText={Alignment.LEFT}
                fill
                icon={theme === Themes.DARK ? "moon" : "flash"}
                minimal
                onClick={toggleTheme}
                style={{ height: "100%", width: "100%" }}
              />
            </SideBarTooltip>
            <Divider />
            <SideBarTooltip content="Settings" position={Position.RIGHT} openOnTargetFocus={false}>
              <Link to="/settings/profile">
                <Button alignText={Alignment.LEFT} fill icon="cog" minimal />
              </Link>
            </SideBarTooltip>
            <Divider />
            <SideBarTooltip content="Logout" position={Position.RIGHT} openOnTargetFocus={false}>
              <Button
                alignText={Alignment.LEFT}
                fill
                icon="log-out"
                onClick={() => setLogoutDialogOpen(true)}
                minimal
              />
            </SideBarTooltip>
          </div>
        </Flex>
      </Flex>
    </StyledSideBar>
  );
};

export default SideBar;

const StyledSideBar = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) =>
    theme === Themes.LIGHT ? colors.moduleBackgroundColor : colors.darkGray5};
  border-right: ${({ theme }) =>
    theme === Themes.LIGHT ? "1px solid " + colors.lightGray2 : "1px solid " + colors.darkGray3};
  display: flex;
  flex-direction: column;
  min-height: 340px;
  padding: 0 0;
  justify-content: space-between;
  width: 50px;
  z-index: 10;
`;

export const SideBarTooltip = styled(Tooltip)`
  & > span {
    height: 100%;
    width: 100%;
  }
`;

interface ISideBarItemProps {
  active: boolean;
  theme: TTheme;
}

export const SideBarItem = styled.div<ISideBarItemProps>`
  width: 50px;
  height: 50px;
  &:hover {
    width: 50px;
    border-left: ${({ theme }) =>
      theme === Themes.LIGHT ? "3px solid " + colors.blue3 : "3px solid " + colors.blue4};
    background-color: ${({ theme }) =>
      theme === Themes.LIGHT ? colors.lightGray3 : colors.darkGray4};
  }
  border-left: ${({ active, theme }) =>
    active
      ? theme === Themes.LIGHT
        ? "3px solid " + colors.blue3
        : "3px solid " + colors.blue4
      : null};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover ${SideBarTooltip} {
    margin-left: ${({ active }) => (!active ? "-3px" : null)};
  }
`;

export const SideBarItemIcon = styled(Icon)`
  &:hover {
    color: #48afef;
  }
`;
