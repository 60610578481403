import { Tag } from "@blueprintjs/core";

import { bold, DefaultFormatComparisonObject, excluded } from "constants/eventHistoryConstants";
import * as consts from "constants/dataEntryConstants";
import { FormatDisplayNames } from "constants/dataEntryUIConstants";

import { Span } from "components/utility/HTMLWrappers";

/**
 * Utility to get the first differential between an object and its predecessor.
 * */
const getDiffs = (prevObj, obj) => {
  const types = Object.keys(prevObj).filter((key) => {
    return !excluded[key] && obj[key] && prevObj[key] !== obj[key];
  });
  return types.map((type) => ({
    type,
    displayValue: FormatDisplayNames[type],
    from: prevObj[type],
    to: obj[type],
  }));
};

/**
 * Generate a lookup object to print a single value from an event.
 *
 * @param {Array} lookupPath - The path along which to lookup the value.
 * */
export const lookup = (lookupPath, Element = Tag, modifiers = {}) => ({
  Element,
  lookup: lookupPath,
  style: { ...bold, ...modifiers.style },
  elementProps: { interactive: true, minimal: true, ...modifiers.elementProps },
  tagName: "tag",
});

const getSliceText = (items, start, endIndex, lookupProp) => {
  let value;
  const startFact = items[start];
  if (start === endIndex) {
    value = startFact[lookupProp];
  } else {
    const end = endIndex === "last" ? items.length - 1 : endIndex;
    const endFact = items[end];
    value = `${startFact[lookupProp]}:${endFact[lookupProp]}`;
  }
  return value;
};

/**
 * Renders either a range of facts or just a single fact.
 *
 * start and endIndex will determine which slice from the sorted
 * fact array to display.
 *
 * setting the start and endIndex as the same value will display
 * just the single fact at that position.
 * */
const slice = (facts, start = 0, endIndex = "last", lookupProp = "identifier", element = Tag) => {
  const sorted = facts.sort((a, b) => a.period - b.period);
  let value = getSliceText(sorted, start, endIndex, lookupProp);
  return {
    Element: element,
    style: { ...bold },
    elementProps: { interactive: true, minimal: true },
    tagName: "tag",
    value,
  };
};

/**
 * Custom formula range renderer.
 * */
export const renderEditFormulaEvent = (event) => {
  const lookupProp = event.state === consts.UNDO ? "undo" : "redo";
  const isRange = event[lookupProp].facts.length > 1;
  let template;
  if (isRange) {
    template = [
      lookup([{ key: "fullname" }]),
      { Element: Span, value: "edited formulas for range" },
      slice(event[lookupProp].facts),
      { Element: Span, value: "with starting fact" },
      slice(event[lookupProp].facts, 0, 0),
      { Element: Span, value: "changed from" },
      slice(event.undo.facts, 0, 0, "formula"),
      { Element: Span, value: "to" },
      slice(event.redo.facts, 0, 0, "formula"),
    ];
  } else {
    template = [
      lookup([{ key: "fullname" }]),
      { Element: Span, value: "edited formula for fact" },
      slice(event[lookupProp].facts, 0, 0),
      { Element: Span, value: "from" },
      slice(event.undo.facts, 0, 0, "formula"),
      { Element: Span, value: "to" },
      slice(event.redo.facts, 0, 0, "formula"),
    ];
  }
  return template.map((t) => ({
    ...t,
    style: { ...(t.style && { ...t.style }), flexBasis: "auto", flexShrink: 0 },
  }));
};

/**
 * Custom renderer for an edit line item event
 * */
export const renderEditLineItemEvent = (event) => {
  const lookupProp = event.state === consts.UNDO ? "undo" : "redo";
  const isRange = event[lookupProp].lineItems.length > 1;
  const firstUndo = event.undo.lineItems[0];
  const firstRedo = event.redo.lineItems[0];

  const undoFormat = firstUndo?.format
    ? { ...DefaultFormatComparisonObject, ...JSON.parse(event.undo.lineItems[0].format) }
    : DefaultFormatComparisonObject;
  const redoFormat = firstRedo?.format
    ? { ...DefaultFormatComparisonObject, ...JSON.parse(event.redo.lineItems[0].format) }
    : DefaultFormatComparisonObject;
  const diff = getDiffs(
    { name: firstUndo.name, ...undoFormat },
    { name: firstRedo.name, ...redoFormat }
  )[0];
  let template;

  if (isRange) {
    template = [
      lookup([{ key: "fullname" }]),
      { Element: Span, value: "changed" },
      {
        Element: Tag,
        value: diff.type,
        displayValue: diff.displayValue,
        elementProps: { interactive: true, minimal: true },
        style: { ...bold },
        tagName: "tag",
      },
      { Element: Span, value: "for line items" },
      slice(event[lookupProp].lineItems, 0, "last", "name"),
      { Element: Span, value: "from" },
      {
        Element: Tag,
        value: diff.from,
        displayValue: diff.displayValue,
        elementProps: { interactive: true, minimal: true },
        style: { ...bold },
        tagName: "tag",
      },
      { Element: Span, value: "to" },
      {
        Element: Tag,
        value: diff.to,
        displayValue: diff.displayValue,
        elementProps: { interactive: true, minimal: true },
        style: { ...bold },
        tagName: "tag",
      },
    ];
  } else {
    template = [
      lookup([{ key: "fullname" }]),
      { Element: Span, value: "changed line item" },
      {
        Element: Tag,
        value: diff.type,
        displayValue: diff.displayValue,
        elementProps: { interactive: true, minimal: true },
        style: { ...bold },
        tagName: "tag",
      },
      { Element: Span, value: "from" },
      {
        Element: Tag,
        value: diff.from,
        displayValue: diff.displayValue,
        style: { ...bold },
        elementProps: { interactive: true, minimal: true },
        tagName: "tag",
      },
      { Element: Span, value: "to" },
      {
        Element: Tag,
        value: diff.to,
        displayValue: diff.displayValue,
        style: { ...bold },
        elementProps: { interactive: true, minimal: true },
        tagName: "tag",
      },
    ];
  }
  return template;
};

/**
 * Derives an event template for a given edit format event
 * */
export const renderEditFormatEvent = (event) => {
  let template = [];
  const lookupProp = event.state === consts.UNDO ? "undo" : "redo";
  const isRange = event[lookupProp].facts.length > 1;
  const undoFacts = event.undo.facts;
  const redoFacts = event.redo.facts;
  /**
   * Figure out what the actual diff is. This will *probably* be the common format change between
   * all facts that have changed.
   * */
  const diffs = undoFacts.map((fact, i) => {
    const factDiffs = getDiffs(fact.format, redoFacts[i].format);
    return factDiffs;
  });
  const mostDiffIndex = diffs.reduce(
    (mostDiffIndex, curr, index) => (curr.length > mostDiffIndex ? index : mostDiffIndex),
    -1
  );

  // TODO - replace this with a prop from the server as there are some false positives here.
  const editDiff = diffs[mostDiffIndex].find(
    (diffObj) =>
      diffs
        .filter((diffArray) => diffArray.length > 0)
        .every((diffArray) => diffArray.map((d) => d.type).includes(diffObj.type)) ||
      diffs[mostDiffIndex][0]
  );

  if (!editDiff) {
    return [];
  }

  const isColor = editDiff.type === "color" || editDiff.type === "backgroundColor";
  if (isColor) {
    editDiff.style = {};
    editDiff.style.from = { color: editDiff.from };
    editDiff.style.to = { color: editDiff.to };
  }
  if (isRange) {
    template = [
      lookup([{ key: "fullname" }]),

      { Element: Span, value: "changed" },
      {
        Element: Tag,
        value: editDiff.type,
        displayValue: editDiff.displayValue,
        elementProps: { interactive: true, minimal: true },
        style: { ...bold },
        tagName: "tag",
      },
      { Element: Span, value: "for facts" },
      slice(event[lookupProp].facts),
      { Element: Span, value: "from" },
      {
        Element: Tag,
        value: editDiff.from,
        style: { ...bold, ...editDiff.style?.from },
        elementProps: { interactive: true, minimal: true },
        tagName: "tag",
      },
      { Element: Span, value: "to" },
      {
        Element: Tag,
        value: editDiff.to,
        style: { ...bold, ...editDiff.style?.to },
        elementProps: { interactive: true, minimal: true },
        tagName: "tag",
      },
    ];
  } else {
    template = [
      lookup([{ key: "fullname" }]),
      { Element: Span, value: "changed" },
      {
        Element: Tag,
        value: editDiff.type,
        displayValue: editDiff.displayValue,
        elementProps: { interactive: true, minimal: true },
        style: { ...bold },
        tagName: "tag",
      },
      { Element: Span, value: "for fact" },
      slice(event[lookupProp].facts, 0, 0),
      { Element: Span, value: "from" },
      {
        Element: Tag,
        value: editDiff.from,
        style: { ...bold, ...editDiff.style?.from },
        elementProps: { interactive: true, minimal: true },
        tagName: "tag",
      },
      { Element: Span, value: "to" },
      {
        Element: Tag,
        value: editDiff.to,
        style: { ...bold, ...editDiff.style?.to },
        elementProps: { interactive: true, minimal: true },
        tagName: "tag",
      },
    ];
  }
  return template;
};

/**
 * Get available filters from an event.
 * */
export const getEventFilters = (event) => {
  let filters = [event.fullname, event.moduleName, event.state, event.displayAction];
  const lookupProp = event.state === consts.UNDO ? "undo" : "redo";
  switch (event.action) {
    case consts.EDIT_FORMAT:
    case consts.EDIT_FORMULA: {
      const isRange = event[lookupProp].facts.length > 1;
      let slice;
      if (isRange) {
        const items = event[lookupProp].facts.sort((a, b) => a.period - b.period);
        slice = getSliceText(items, 0, "last", "identifier");
      }
      let diff = {};
      if (event.action === consts.EDIT_FORMAT) {
        const undoFormat = event.undo.facts[0]?.format
          ? { ...DefaultFormatComparisonObject, ...event.undo.facts[0].format }
          : DefaultFormatComparisonObject;
        const redoFormat = event.redo.facts[0]?.format
          ? { ...DefaultFormatComparisonObject, ...event.redo.facts[0].format }
          : DefaultFormatComparisonObject;
        diff = getDiffs(undoFormat, redoFormat)[0];
      }
      const undoFacts = Object.values(event.undo.facts)
        .map((f) => [f.identifier, f.formula])
        .flat();
      const redoFacts = Object.values(event.redo.facts)
        .map((f) => [f.identifier, f.formula])
        .flat();
      filters = filters.concat(undoFacts, redoFacts, slice, ...Object.values(diff));
      break;
    }
    case consts.EDIT_LINE_ITEMS: {
      const isRange = event[lookupProp].lineItems.length > 1;
      let slice;
      if (isRange) {
        const items = event[lookupProp].lineItems;
        slice = getSliceText(items, 0, "last", "name");
      }
      const undoLis = Object.values(event.undo.lineItems)
        .map((li) => [li.name, ...Object.keys(li)])
        .flat();
      const redoLis = Object.values(event.redo.lineItems).map((li) => li.name);
      filters = filters.concat(undoLis, redoLis, slice);
      break;
    }
    default:
      break;
  }
  return Array.from(new Set(filters.filter((f) => f)));
};
