import { EWidgetType2 } from "types/vsl";
import type Highcharts from "highcharts/highstock";
import { IFormatObject } from "types/format";

export enum ESeriesStatistic {
  NONE = "None",
  MEDIAN = "Median",
  MEAN = "Mean",
}

export enum ChartDataFrequency {
  "Annual" = "Annual",
  "Quarterly" = "Quarterly",
  "Raw" = "Raw",
}

export enum ChartPeriodType {
  "Calendar" = "Calendar",
  "Fiscal" = "Fiscal",
  "Raw" = "Raw",
}

export enum YAxisId {
  LEFT = "left",
  RIGHT = "right",
}

export enum ETransformationType {
  ABS = "Abs",
  YOY = "YoY %",
  SEQ = "Seq %",
  STACK = "Stack",
  STACK_PERCENT = "Stack %",
}

export const DashStyles: Highcharts.DashStyleValue[] = [
  "Solid",
  "DashDot",
  "Dash",
  "Dot",
  "LongDash",
  "ShortDot",
  "ShortDash",
  "LongDashDot",
  "ShortDashDot",
  "LongDashDotDot",
];

export interface ChartSeriesOptions {
  axis?: YAxisId;
  color?: string;
  dashStyle?: Highcharts.DashStyleValue;
  dataLabels?: Highcharts.DataLabelsOptions;
  lineWidth?: number;
  endLabel?: boolean;
  statistic?: ESeriesStatistic;
  statisticPlotLine?: Highcharts.YAxisPlotLinesOptions;
}

export interface YAxisOptions
  extends Highcharts.YAxisOptions,
    Pick<IFormatObject, "valFormat" | "unit" | "decimalPlaces"> {
  transformation?: ETransformationType;
}

export interface ChartOptions {
  correlationMetrics?: boolean;
  delineateForecasts?: boolean;
  enableTransformationOptions?: boolean;
  legend?: Highcharts.LegendOptions;
  navigator: Highcharts.NavigatorOptions;
  frequency?: ChartDataFrequency;
  periodType?: ChartPeriodType;
  plotOptions?: Highcharts.PlotOptions;
  rangeSelector: Highcharts.RangeSelectorOptions;
  scrollbar: Highcharts.ScrollbarOptions;
  seriesOptions?: {
    // Key here is the dataset label.
    [key: string]: ChartSeriesOptions;
  };
  tooltip?: Highcharts.TooltipOptions;
  type: "packedbubble" | "line" | "column" | "scatter" | "pie";
  xAxis: Highcharts.XAxisOptions;
  yAxis?: { [key in YAxisId]?: YAxisOptions };
  categoryFilters?: { min: number; max: number };
  activeRangeSelector?: number;
}

export const VSChartToHighchartsSeriesMap: {
  [key: string]: "line" | "column" | "scatter" | "pie";
} = {
  [EWidgetType2.LINE_CHART]: "line",
  [EWidgetType2.BAR_CHART]: "column",
  [EWidgetType2.SCATTER_CHART]: "scatter",
  [EWidgetType2.PIE_CHART]: "pie",
};

// HC Chart defaults
export const chartDefaults: { [key in EWidgetType2]: ChartOptions } = {
  [EWidgetType2.LINE_CHART]: {
    type: "line",
    legend: { enabled: false },
    navigator: {
      enabled: true,
    },
    rangeSelector: {
      enabled: true,
    },
    plotOptions: {
      line: {
        dataLabels: { enabled: false },
        lineWidth: 2,
      },
    },
    scrollbar: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
    },
    xAxis: {
      visible: true,
      type: "datetime",
      plotLines: [],
      labels: {
        formatter: undefined,
      },
    },
    yAxis: {
      [YAxisId.RIGHT]: {
        id: YAxisId.RIGHT,
        opposite: true,
        visible: true,
        labels: {
          align: "left",
          formatter: undefined,
        },
        plotLines: [],
      },
      [YAxisId.LEFT]: {
        id: YAxisId.LEFT,
        opposite: false,
        visible: true,
        labels: {
          align: "right",
          formatter: undefined,
        },
        plotLines: [],
      },
    },
  },
  [EWidgetType2.BAR_CHART]: {
    type: "column",
    legend: { enabled: false },
    navigator: {
      enabled: true,
    },
    rangeSelector: {
      enabled: true,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    scrollbar: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
    },
    xAxis: {
      visible: true,
      type: "datetime",
      plotLines: [],
      labels: {
        formatter: undefined,
      },
    },
    yAxis: {
      [YAxisId.RIGHT]: {
        id: YAxisId.RIGHT,
        opposite: true,
        visible: true,
        labels: { align: "left" },
        plotLines: [],
      },
      [YAxisId.LEFT]: {
        id: YAxisId.LEFT,
        opposite: false,
        visible: true,
        labels: { align: "right" },
        plotLines: [],
      },
    },
  },
  [EWidgetType2.SCATTER_CHART]: {
    type: "scatter",
    legend: { enabled: false },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    plotOptions: {
      scatter: {
        dataLabels: {
          enabled: false,
        },
        marker: {
          symbol: "circle",
          fillColor: "transparent",
          lineWidth: 2,
        },
      },
    },
    scrollbar: {
      enabled: false,
    },
    xAxis: {
      visible: true,
      type: "linear",
      tickWidth: 0,
      lineWidth: 0,
      gridLineWidth: 1,
      plotLines: [
        {
          value: 0,
          zIndex: 2,
          width: 1,
          color: "#8F99A8",
        },
      ],
      labels: {
        formatter: undefined,
      },
    },
    yAxis: {
      [YAxisId.RIGHT]: {
        id: YAxisId.RIGHT,
        visible: true,
        type: "linear",
        opposite: true,
        showLastLabel: true,
        labels: { align: "left" },
        gridLineWidth: 1,
        plotLines: [
          {
            value: 0,
            zIndex: 2,
            width: 1,
            color: "#8F99A8",
          },
        ],
      },
      [YAxisId.LEFT]: {
        id: YAxisId.LEFT,
        visible: true,
        type: "linear",
        labels: { align: "right" },
        opposite: false,
        showLastLabel: true,
        plotLines: [
          {
            value: 0,
            zIndex: 2,
            width: 1,
            color: "#8F99A8",
          },
        ],
      },
    },
  },
  [EWidgetType2.PIE_CHART]: {
    type: "pie",
    legend: { enabled: false },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          distance: "-50%",
          format: "{point.percentage:.1f} %",
        },
        showInLegend: true,
      },
    },
    xAxis: {
      visible: false,
      plotLines: [],
      labels: {
        formatter: undefined,
      },
    },
    yAxis: {
      [YAxisId.RIGHT]: {
        id: YAxisId.RIGHT,
        plotLines: [],
        visible: false,
      },
      [YAxisId.LEFT]: {
        id: YAxisId.LEFT,
        plotLines: [],
        visible: false,
      },
    },
  },
  [EWidgetType2.TABLE]: {
    // TODO - decide what to do with this one
    type: "line",
    navigator: {},
    legend: { enabled: false },
    rangeSelector: {},
    scrollbar: {},
    plotOptions: {},
    xAxis: {},
    yAxis: { [YAxisId.LEFT]: {}, [YAxisId.RIGHT]: {} },
  },
};

export const ChartTickGap = 60;
// Set to 13 as Highcharts cannot differentiate higher precisions when calculating axis extremes
export const MaxChartPrecision = 13;

export const MSELabel = "MSE";
export const RMSELabel = "RMSE";
export const MAPELabel = "MAPE";
export const SMAPELabel = "SMAPE";
export const MinDiffLabel = "Min Diff";
export const MaxDiffLabel = "Max Diff";
export const CorrelationLabel = "Correlation";
