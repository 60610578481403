import { f, formulaPeriodToNumber, periodFormatter } from "./formatter";
import { getValidJSON } from "utils/api";
import {
  selectorObject,
  selectorToString,
  stringToSelector,
  vsRangeRegex,
  vsVariableRegex,
} from "./formulas";
import {
  ICase,
  IFact,
  ILineItem,
  IModel,
  IModule,
  IRawFact,
  IRawLineItem,
  isFactEntity,
  IVSCompsTable,
  TVSEntity,
} from "types/model";
import { IFormatChange, IRawSensitivityAnalyses, TResponseKey } from "types/dataEntry";
import { IVSSelector } from "types/formulas";
import { ISheetStructure } from "types/vsTable";
import { schemaLevel } from "./normalizr";
import { EValFormat, IFormatObject } from "types/format";

export interface validatorWarning {
  id?: string;
  identifier?: string;
  moduleName?: string;
  rowIdx?: number;
  colIdx?: number;
  msg: string;
  type: TVSEntity;
}

/** Deep merge to extend objects from first array that exist in the second
 * Requires uid prop matching object key
 * Can exclude keys from first array objects
 * */
export function deepMerge<Entity extends { uid: string }>(
  toMerge: Record<string, Entity>,
  inheritFrom: Record<string, Entity>,
  excludeKeys: (keyof Entity)[] = []
) {
  return Object.keys(toMerge)
    .map((key) => {
      excludeKeys.forEach((excludeKey) => delete toMerge[key][excludeKey]);
      return { ...inheritFrom[key], ...toMerge[key] };
    })
    .reduce((acc, curr) => ({ ...acc, [curr.uid]: curr }), {});
}

/**
 * Safely convert fact format objects from JSON to JS objects. Facts with no format prop
 * will be set to additional formats (defaults to {})
 * */
export const safeFactFormatJSONtoObject = (facts: IRawFact[], additionalFormats = {}) => {
  return facts.map((fact) => {
    const factFormat = getValidJSON(fact.format) as unknown as IFormatObject;
    return {
      ...fact,
      format: factFormat ? { ...additionalFormats, ...factFormat } : additionalFormats,
    };
  });
};
/** Utility to safely convert to a number. On fail returns supplied value */
export function safelyToNumber(value: unknown) {
  const converted = Number(value);
  return Number.isNaN(converted) ? value : converted;
}

/** Transform a facts array into an obj that can be merged into the store normalized facts object */
export function factArrayToObject(
  stateFacts: Record<string, IFact>,
  facts: IRawFact[],
  config: {
    loading?: boolean;
    resetErrors?: boolean;
    clearDepsAndPrecs?: boolean;
    skipProps?: (keyof IRawFact)[];
  } = {}
) {
  if (!facts) return {};
  const { loading, resetErrors, clearDepsAndPrecs, skipProps } = config;
  return facts.reduce((factObject, fact) => {
    const stateFact = stateFacts[fact.uid];
    if (stateFact) {
      if (loading) fact.loading = stateFact.loading ? (stateFact.loading += 1) : 1;
      else fact.loading = stateFact.loading ? (stateFact.loading -= 1) : 0;
    }

    if (skipProps) skipProps.forEach((prop) => delete fact[prop]);

    const format = getValidJSON(fact.format) || {};

    /* Spread order is important to preserve a prop when server doesn't return it */
    return {
      ...factObject,
      [fact.uid]: {
        ...stateFact,
        ...(resetErrors && { error: "" }),
        ...(clearDepsAndPrecs && { dependantCells: [], precedentCells: [] }),
        ...fact,
        format,
      },
    };
  }, {});
}

/** A utility to check whether a blob of data is errored or loading */
export function isDataReady(data: { loading: boolean; error: string }) {
  return !(data?.loading || data?.error);
}

/** Generic object formatter to derive the correct props from a format change object */
export function formatVSObjects(
  vsObjects: { uid: string; identifier?: string; format: IFormatObject }[],
  formatChange: IFormatChange,
  force?: boolean
) {
  if (!formatChange) return vsObjects;
  const { change, type } = formatChange;
  let newValue = change;

  if ((type === "fontWeight" || type === "fontStyle" || type === "textDecoration") && !force) {
    const setChange = vsObjects.some((fact) => {
      return fact.format[type] !== change;
    });
    newValue = setChange ? change : false;
  }

  let newObjects = [...vsObjects];
  if (newValue || newValue === 0) {
    newObjects = newObjects.map((obj) => {
      return {
        uid: obj.uid,
        ...(obj.identifier && { identifier: obj.identifier }),
        format: {
          ...obj.format,
          [type]: newValue,
        },
      };
    });
  } else {
    newObjects = newObjects.map((obj) => {
      const newFormat = { ...obj.format };
      delete newFormat[type];
      return {
        uid: obj.uid,
        ...(obj.identifier && { identifier: obj.identifier }),
        format: newFormat,
      };
    });
  }
  return newObjects;
}

/** Transform a sensitivity analysis for display to the user */
export function transformSensitivityAnalyses(
  analyses: IRawSensitivityAnalyses[],
  defaultFormat: IFormatObject
) {
  return analyses.map((analysis) => {
    const cols = analysis.cols[0] ? [NaN, ...analysis.cols] : analysis.cols;
    const grid = flipGrid(analysis.grid).map((row) =>
      row.map((cell) =>
        f({ value: cell, format: { ...defaultFormat, ...JSON.parse(analysis.format) } })
      )
    );
    return {
      ...analysis,
      cols,
      grid,
    };
  });
}

/** Flip a 2 * 2 grid, ex a sensitivity analysis */
function flipGrid(grid: unknown[][]) {
  return grid.map((col, i) => {
    return col.map((item, j) => {
      return grid[j][i];
    });
  });
}

interface IWidgetLineItem extends IRawLineItem {
  value?: string;
}

/** Format a LineItem object */
export function transformLineItems(lineItems: IWidgetLineItem[], defaultFormat: IFormatObject) {
  return [...lineItems]
    .sort((a, b) => a.order - b.order)
    .map((lineItem) => {
      let formatObj = { ...defaultFormat };
      if (lineItem?.format) {
        const parsed = JSON.parse(lineItem.format);
        formatObj = {
          ...formatObj,
          ...parsed,
          decimalPlaces: parsed.valFormat === EValFormat.CURRENCY ? 2 : parsed.decimalPlaces || 1,
        };
      }
      return {
        ...lineItem,
        ...(lineItem?.value && {
          value: f({ value: lineItem?.value, format: formatObj }),
        }),
        ...(lineItem.facts && {
          facts: [...lineItem.facts]
            .sort((a, b) => a.period - b.period)
            .map((fact) => {
              if (fact.format) {
                const parsed = JSON.parse(fact.format);
                formatObj = {
                  ...formatObj,
                  ...parsed,
                  decimalPlaces:
                    parsed.valFormat === EValFormat.CURRENCY ? 2 : parsed.decimalPlaces || 1,
                };
              }
              return {
                ...fact,
                value: f({
                  ...fact,
                  format: formatObj,
                }),
              };
            }),
        }),
        styleObject: formatObj,
      };
    });
}

/** Gets the relevant transform method for a vs response key */
export const getTransformMethod = (key: TResponseKey) => {
  switch (key) {
    case TResponseKey.LINE_ITEMS:
      return transformLineItems;
    case TResponseKey.SENSITIVITIES:
      return transformSensitivityAnalyses;
    default:
      return (data: unknown) => data;
  }
};

/** Recursive method to traverse module tree to get all modules nested in case */
export function listCaseModules(
  moduleUid: string,
  allModules: Record<string, IModule>,
  caseModules?: IModule[]
) {
  if (!caseModules) caseModules = [];
  const currentModule = allModules[moduleUid];
  caseModules.push(currentModule);
  if (currentModule.childModules)
    currentModule.childModules.forEach((modUid) =>
      listCaseModules(modUid, allModules, caseModules)
    );
  return caseModules;
}

/** Generates temporary lineItem */
export function genTempLineItem(
  name: string,
  order: number,
  caseUid: string,
  parentUid: string,
  facts: IFact[],
  tempUid: string
) {
  return {
    uid: tempUid,
    name: name,
    facts: facts?.map((fact) => fact.uid),
    order: order,
    caseUid: caseUid,
    parentUid: parentUid,
    renaming: true,
  };
}

/** Generates temporary fact */
export function genTempFact(
  period: number,
  parentUid: string,
  currentModuleUid: string,
  caseUid: string,
  parentOrder: number
) {
  return {
    period: period,
    parentUid: parentUid,
    moduleUid: currentModuleUid,
    caseUid: caseUid,
    parentOrder: parentOrder,

    value: 0,
    formula: "0",
    uid: "temp-" + parentUid + "-" + period,
    error: "",

    textColor: "default",
    fillColor: "default",
    bold: false,
    italic: false,
    underlined: false,
    indented: false,
    decimalPlaces: "default",
    format: "default",
    currency: "default",
    unit: "default",
  };
}

/** Returns a module by name when provided the case uid */
export function findModuleByName(name: string, caseUid: string, modules: Record<string, IModule>) {
  const moduleUid = Object.keys(modules).find((modUid) => {
    const module = modules[modUid];
    return module.name === name && module.caseUid === caseUid;
  });
  return moduleUid ? modules[moduleUid] : null;
}

/**
 * Parses a formula and for each range returns a selector object for each individual fact
 * Returns and object with an array of starting and ending indexes and another with all the refs
 */
function refsFromFxRanges(
  formula: string,
  lineItems: Record<string, ILineItem>,
  modules: Record<string, IModule>,
  caseUid: string
) {
  const refs: { startIdx: number | undefined; endIdx: number; refs: IVSSelector[] }[] = [];
  if (!formula) return refs;
  const ranges = formula.matchAll(new RegExp(vsRangeRegex, "g"));
  for (const match of ranges) {
    const rangeRefs: IVSSelector[] = [];
    const sel: IVSSelector | undefined = stringToSelector(match[0]);
    if (!sel) continue;
    const module = findModuleByName(sel.module, caseUid, modules);
    if (!module) continue;

    let rangeLineItems;
    // line item range
    if (sel.lineItem !== sel.endLineItem) {
      const moduleLineItems = module.lineItems?.map((liUid) => lineItems[liUid]);
      const startLi = moduleLineItems?.find((li) => {
        return li.name === sel.lineItem && li.parentUid === module.uid;
      });
      const endLi = moduleLineItems?.find((li) => {
        return li.name === sel.endLineItem && li.parentUid === module.uid;
      });
      if (!startLi || !endLi) continue; // invalid line item
      rangeLineItems = moduleLineItems?.filter(
        (li) => li.order >= startLi.order && li.order <= endLi.order
      );
      rangeLineItems?.forEach((rli) => {
        rangeRefs.push(selectorObject(module.name, rli.name, sel.period));
      });
    }

    // period range
    if (sel.period !== sel.endPeriod) {
      let currentPeriod = formulaPeriodToNumber(sel.period);
      const endPeriod = formulaPeriodToNumber(sel.endPeriod);
      if (currentPeriod && endPeriod) {
        for (; currentPeriod <= endPeriod; currentPeriod += module.forecastIncrement) {
          const selPeriod = periodFormatter(currentPeriod, null, module.forecastIncrement);
          rangeRefs.push(selectorObject(sel.module, sel.lineItem, selPeriod));
        }
      }
    }
    const endIdx = match.index ? match.index + match[0].length : -1;
    refs.push({ startIdx: match.index, endIdx, refs: rangeRefs });
  }
  return refs;
}

/**
 * Iterates range refs and finds uids for all referenced facts
 * Uses formula fact precs to achieve this (can't use if precs are not present or updated)
 * getPrecsFromFx can perform same action without precs but it's less efficient
 */
export function rangesToArrays(
  formula: string,
  precedents: IFact[],
  facts: Record<string, IFact>,
  lineItems: Record<string, ILineItem>,
  modules: Record<string, IModule>,
  caseUid: string
) {
  const newFactsUids: string[][] = [];
  let oldFacts = precedents.map((prec) => prec.uid);
  const rangesRefs = refsFromFxRanges(formula, lineItems, modules, caseUid);
  if (!rangesRefs) return [];
  rangesRefs.forEach((range) => {
    const rangeFacts: IFact[] = [];
    range.refs.forEach((ref) => {
      const rangeFact = precedents.find((prec) => {
        const fact = facts[prec.uid];
        if (!fact) return false; // deleted
        const lineItem = lineItems[fact.parentUid];
        const module = modules[fact.moduleUid];
        return (
          module.name === ref.module &&
          lineItem.name === ref.lineItem &&
          fact.period === formulaPeriodToNumber(ref.period)
        );
      });
      if (!rangeFact) return [];
      rangeFacts.push(facts[rangeFact.uid]);
    });

    // remove old facts that were added to range
    const newUids = rangeFacts.flat().map((fact) => fact.uid);
    oldFacts = oldFacts.filter((factUid) => newUids.indexOf(factUid) < 0);

    // sort range items by parentOrder if vertical or by period if horizonal and map to uids
    // ensuring order is important to avoid iterating all facts when ranging
    newFactsUids.push(
      rangeFacts
        .sort((a, b) => {
          if (a.parentOrder) return a.parentOrder - b.parentOrder;
          else if (formulaPeriodToNumber(a.period) && formulaPeriodToNumber(b.period)) {
            const aNumber = formulaPeriodToNumber(a.period) || 0;
            const bNumber = formulaPeriodToNumber(b.period) || 0;
            return aNumber - bNumber;
          }
          return 0;
        })
        .map((fact) => fact.uid)
    );
  });
  return [...oldFacts.map((of) => [of]), ...newFactsUids];
}

/**
 * Takes a fact and new fact with formula and internalFormula fixed if necessary
 * Fixing fact: sort correctly by line item and by period
 * Fix internal formula: Generate new internal formula of the expansion of ranges of formula
 */
export function fixFactFx(
  fact: IFact,
  facts: Record<string, IFact>,
  lineItems: Record<string, ILineItem>,
  modules: Record<string, IModule>,
  currentCase: ICase
): Partial<IFact> | undefined {
  let newInternalFx = "";
  if (!fact.formula) return;
  const fx = sortRanges(fact.formula, modules, lineItems, facts, currentCase);
  const rangesRefs = refsFromFxRanges(fx, lineItems, modules, currentCase.uid);
  if (!rangesRefs?.length) return;
  let startIdx = 0;
  rangesRefs.forEach((range) => {
    if (!range.refs.length) return;
    if (range.refs[0].period !== range.refs[range.refs.length - 1].period) return; // don't expand horizontal ranges
    newInternalFx += fx.substring(startIdx, range.startIdx);
    range.refs.forEach((ref, i) => {
      if (i !== 0) newInternalFx += ", ";
      newInternalFx += selectorToString(ref.module, ref.lineItem, ref.period);
    });
    newInternalFx += fx.substring(range.endIdx);
    startIdx = range.endIdx;
  });
  if (fact.formula !== fx || newInternalFx) {
    return {
      uid: fact.uid,
      formula: fx,
      internalFormula: newInternalFx,
    };
  }
}

/**
 * Recursively increase/decrease loading prop of all dependant cells of a fact
 * Loading prop is a counter because it can be a dependant of multiple facts that have been edited simultaneously
 */
export function loadAllDependantCells(
  fact: IFact,
  facts: Record<string, IFact>,
  hits: Set<string>,
  increaseLoadingCounter: boolean
) {
  if (!fact.dependantCells || hits.has(fact.uid)) return;
  else {
    hits.add(fact.uid);
    fact.dependantCells.forEach((dep) => {
      const depFact = facts[dep.uid];
      if (!depFact) {
        // eslint-disable-next-line no-console
        console.warn("Fact " + fact.uid + "dependant cell " + dep.uid + " broken");
        return;
      }
      if (increaseLoadingCounter) depFact.loading = depFact.loading ? (depFact.loading += 1) : 1;
      else depFact.loading = depFact.loading ? (depFact.loading -= 1) : 0;
      loadAllDependantCells(depFact, facts, hits, increaseLoadingCounter);
    });
  }
}

/** Gets the fact uid for a VS variable */
export function getUidFromVariable(
  variable: IVSSelector,
  facts: Record<string, IFact>,
  lineItems: Record<string, ILineItem>,
  modules: Record<string, IModule>,
  caseUid: string
) {
  const module = Object.values(modules).find((mod) => {
    return mod.name === variable.module && mod.caseUid === caseUid;
  });
  if (!module) return null;
  const liUid = module.lineItems?.find((liUid) => {
    return lineItems[liUid].name === variable.lineItem;
  });
  if (!liUid) return null;
  const li = lineItems[liUid];
  const period =
    typeof variable.period === "number" ? variable.period : formulaPeriodToNumber(variable.period);
  const factUid = li.facts.find((factUid) => facts[factUid].period === period);
  if (!factUid) return null;
  else return factUid;
}

/**
 * Gets precedents uids of a formula
 * Used to highlight precs in table during insert mode when we don't have server precs
 */
export function getPrecsFromFx(
  formula: string,
  facts: Record<string, IFact>,
  lineItems: Record<string, ILineItem>,
  modules: Record<string, IModule>,
  currentModule: IModule,
  caseUid: string
) {
  if (!formula) return [];
  const vars = [...formula.matchAll(new RegExp(vsVariableRegex, "g"))];
  const rangesSelectors = refsFromFxRanges(formula, lineItems, modules, caseUid);

  const result: string[][] = [];
  const precs = new Set();
  // go through all selectors in ranges first because individual highlight is ignored if in range
  if (rangesSelectors) {
    rangesSelectors.forEach((range) => {
      const rangeUids: string[] = [];
      range.refs.forEach((rangeRef) => {
        // if ref module !== current module return
        const uid = getUidFromVariable(rangeRef, facts, lineItems, modules, caseUid);
        if (!uid) return;
        precs.add(uid);
        rangeUids.push(uid);
      });
      result.push(rangeUids);
    });
  }

  if (vars) {
    vars.forEach((variable) => {
      const sel = stringToSelector(variable[0]);
      if (!sel) return;
      const uid = getUidFromVariable(sel, facts, lineItems, modules, caseUid);
      if (!uid || precs.has(uid)) return;
      result.push([uid]);
      precs.add(uid);
    });
  }

  return result;
}

/** Gets a Set of all dependant cells of all the facts of a lineItem in a given module */
export function getDepsFromRows(
  module: IModule,
  lineItems: Record<string, ILineItem>,
  facts: Record<string, IFact>,
  lineItemIdxs: number[]
) {
  const deps: Set<string> = new Set();
  lineItemIdxs.forEach((idx) => {
    const prevLiUid = module.lineItems?.find((liUid) => lineItems[liUid].order === idx);
    if (!prevLiUid) return;
    const li = lineItems[prevLiUid];
    if (!li) return; // could've been deleted and hasn't updated yet
    li.facts.forEach((liFactUid: string) => {
      const fact = facts[liFactUid];
      if (fact.dependantCells) fact.dependantCells.forEach((dep) => deps.add(dep.uid));
    });
  });
  return deps;
}

/**
 * Iterates all deps of all facts of line items of a given module
 * Checks if the expansion of ranges in each of those deps has changed
 * Deps with changed ranges are returned with a new internal formula
 */
export function getRangeFactsToUpdate(
  rows: number[],
  module: IModule,
  modules: Record<string, IModule>,
  lineItems: Record<string, ILineItem>,
  facts: Record<string, IFact>,
  caseData: ICase
) {
  const adjDeps = getDepsFromRows(module, lineItems, facts, rows);
  const updatedFacts: Partial<IFact>[] = [];
  adjDeps.forEach((depUid) => {
    const depFact = facts[depUid];
    // dep might need to order ranges and/or re-expand ranges
    const newDepFact = fixFactFx(depFact, facts, lineItems, modules, caseData);
    if (newDepFact) updatedFacts.push(newDepFact);
  });
  return updatedFacts;
}

/**
 * Iterates ranges of a formula and if the order of edge item or period are inverted corrects it
 * eg. [mod[li3[2010]]]:[mod[li2[2010]]] -> [mod[li2[2010]]]:[mod[li3[2010]]]
 * eg. [mod[li2[2011]]]:[mod[li2[2010]]] -> [mod[li2[2010]]]:[mod[li2[2011]]]
 */
export function sortRanges(
  fx: string,
  modules: Record<string, IModule>,
  lineItems: Record<string, ILineItem>,
  facts: Record<string, IFact>,
  caseData: ICase
) {
  const ranges = fx.matchAll(new RegExp(vsRangeRegex, "g"));
  if (!ranges) return fx;
  let newFx = "";
  let lastIdx = 0;
  for (const range of ranges) {
    newFx += fx.substring(lastIdx, range.index);
    const refs = range[0].split("]:[");
    const var1 = refs[0] + "]";
    const var2 = "[" + refs[1];
    const sel1 = stringToSelector(var1);
    const sel2 = stringToSelector(var2);
    if (!sel1 || !sel2) return fx;
    const uid0 = getUidFromVariable(sel1, facts, lineItems, modules, caseData.uid);
    const uid1 = getUidFromVariable(sel2, facts, lineItems, modules, caseData.uid);
    if (!uid0 || !uid1) return fx;
    const fact0 = facts[uid0];
    const fact1 = facts[uid1];

    // lineItem check and invert
    const lineItem0 = lineItems[fact0.parentUid];
    const lineItem1 = lineItems[fact1.parentUid];
    let currentSel = "";
    if (lineItem0.order > lineItem1.order) {
      currentSel = var2 + ":" + var1;
    } else {
      currentSel = range[0];
    }

    // period check and invert
    if (fact0.period > fact1.period) {
      currentSel = currentSel.replace(String(fact1.period), String(fact0.period));
      currentSel = currentSel.replace(String(fact0.period), String(fact1.period));
    }
    newFx += currentSel;

    if (range.index) lastIdx = range.index + range[0].length;
  }
  if (lastIdx && newFx.length < fx.length) newFx += fx.substring(lastIdx, fx.length);

  return newFx;
}

/** Fix model line items order */
export const orderModelLis = (
  moduleLineItemsIDs: string[],
  lineItems: Record<string, ILineItem>,
  facts: Record<string, IFact>,
  order: number
) => {
  moduleLineItemsIDs.forEach((liUid) => {
    const li = lineItems[liUid];
    if (li.order >= order) {
      li.order += 1;
      li.facts.forEach((factUid) => {
        facts[factUid].parentOrder = li.order;
      });
    }
  });
};

/** Convert a column formatted table from server into a row matrix table format */
export function formatTable(data: IVSCompsTable, computeAvg: boolean) {
  let colCount = data.table.length + 1;
  if (computeAvg) colCount += 1;
  const rowCount = Object.values(data.table[0].values).length + 1;

  const newTable = Array(rowCount)
    .fill(undefined)
    .map(() => Array(colCount).fill(undefined));

  data.table.map((col, j) => {
    // col headers
    newTable[0][j + 1] = col.colName;

    // row headers
    Object.keys(col.values).map((header, i) => {
      newTable[i + 1][0] = header;
    });

    // values
    Object.values(col.values).map((value, i) => {
      newTable[i + 1][j + 1] = Number(value).toFixed(1) + "x";
    });
  });

  // avg
  if (computeAvg) {
    newTable[0][colCount - 1] = "Average";
    for (let i = 1; i < rowCount; i++) {
      let avg: number | string = 0;
      for (let j = 1; j < colCount - 1; j++) {
        avg += Number(newTable[i][j].slice(0, -1));
      }
      avg = (avg / (colCount - 2)).toFixed(1) + "x";
      newTable[i][colCount - 1] = avg;
    }
  }
  return newTable;
}

const warnValidation = (warn: validatorWarning, warnings?: validatorWarning[]) => {
  warnings?.push(warn);
  // eslint-disable-next-line no-console
  console.warn(warn.msg);
};
export function validateData(structure: ISheetStructure<IFact>, warnings?: validatorWarning[]) {
  const { body, columnHeaders, rowHeaders } = structure;
  const dataWarn = {
    type: schemaLevel.CASE,
    identifier: "Base",
    msg: "",
  };

  // check dimensions
  if (body.length !== columnHeaders.length) {
    dataWarn.msg =
      "Column count " +
      body.length +
      " doesn't match column headers size of " +
      columnHeaders.length;
    warnValidation(dataWarn, warnings);
  }

  body.forEach((line, idx) => {
    if (line.length !== rowHeaders.length) {
      dataWarn.msg = `Item ${idx} facts count (${line.length}) doesn't match row headers size of ${rowHeaders.length}`;
      warnValidation({ ...dataWarn }, warnings);
    }
  });
}

export function validateModules(modules: Record<string, IModule>, warnings: validatorWarning[]) {
  const mandatoryProps: (keyof IModule)[] = [
    "caseUid",
    "name",
    "moduleStart",
    "moduleEnd",
    "forecastIncrement",
    "startPeriod",
  ];
  Object.values(modules).forEach((mod) => {
    const modWarn = {
      type: schemaLevel.MODULE,
      id: mod.uid,
      identifier: mod.name,
      moduleName: mod.name,
      msg: "",
    };

    mandatoryProps.forEach((prop) => {
      if (!mod[prop]) {
        modWarn.msg = `Missing key "${prop}"`;
        warnValidation({ ...modWarn }, warnings);
      }
    });

    if (!mod.depth) return; // root module doesn't need further parent validations

    if (!mod.parentUid && mod.depth !== 0) {
      modWarn.msg = `Missing prop "parentUid"`;
      warnValidation(modWarn, warnings);
      return;
    }

    const parent = modules[mod.parentUid];
    if (!parent) {
      modWarn.msg = `Invalid parent module (${mod.parentUid})`;
      warnValidation(modWarn, warnings);
      return;
    }
    if (!parent.childModules || (parent.childModules && !parent.childModules.includes(mod.uid))) {
      modWarn.msg = `Missing from parent module ${mod.parentUid} child modules`;
      warnValidation(modWarn, warnings);
    }
  });
}

export function validateLineItems(
  modules: Record<string, IModule>,
  items: Record<string, ILineItem>,
  warnings: validatorWarning[]
) {
  const mandatoryProps: (keyof ILineItem)[] = ["caseUid", "parentUid", "name"];

  Object.values(modules).forEach((module) => {
    const factsCount = (module.moduleEnd - module.moduleStart + 1) * (1 / module.forecastIncrement);
    let count = 1;
    module.lineItems
      ?.map((liUid) => items[liUid])
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .forEach((item) => {
        const liWarn = {
          type: schemaLevel.LINE_ITEM,
          id: item.uid,
          identifier: `[${module.name}[${item.name}]]`,
          name: item.name,
          moduleName: module.name,
          rowIdx: item.order - 1,
          msg: "",
        };

        if (!item) {
          liWarn.msg = `An item in this module can't be found in state`;
          warnValidation(liWarn, warnings);
        }

        if (!item.facts || item.facts.length !== factsCount) {
          liWarn.msg = `item facts count (${item.facts?.length}) doesn't match expected period count (${factsCount})`;
          warnValidation(liWarn, warnings);
        }

        if (item.order !== count && count !== -1) {
          liWarn.msg = `Item order ${item.order} was expected to be ${count}.
            Orders: ${module.lineItems
              ?.map((liUid) => items[liUid].order)
              .sort((a, b) => a - b)
              .join(", ")}`;
          warnValidation(liWarn, warnings);
          count = -1;
        } else if (count !== -1) count += 1;

        mandatoryProps.forEach((prop) => {
          if (!item[prop]) {
            liWarn.msg = `Missing key "${prop}"`;
            warnValidation({ ...liWarn }, warnings);
          }
        });

        if (!modules[item.parentUid]) {
          liWarn.msg = `Invalid parent module ${item.parentUid}`;
          warnValidation(liWarn, warnings);
        }
      });
  });
}

export function validateFacts(
  modules: Record<string, IModule>,
  lineItems: Record<string, ILineItem>,
  facts: Record<string, IFact>,
  warnings: validatorWarning[]
) {
  const mandatoryProps: (keyof IFact)[] = ["caseUid", "parentUid", "moduleUid"];
  Object.values(facts).forEach((fact) => {
    const module = modules[fact.moduleUid];
    const factWarn = {
      type: schemaLevel.FACT,
      id: fact.id,
      identifier: fact.identifier,
      moduleName: module.name,
      msg: "",
      rowIdx: fact.parentOrder,
      colIdx: fact.period - module.moduleStart,
    };

    mandatoryProps.forEach((prop) => {
      if (!fact[prop]) {
        factWarn.msg = `Missing key "${prop}"`;
        warnValidation({ ...factWarn }, warnings);
      }
    });

    if (!modules[fact.moduleUid]) {
      factWarn.msg = `Invalid module ${fact.moduleUid}`;
      warnValidation(factWarn, warnings);
    }
    if (!lineItems[fact.parentUid]) {
      factWarn.msg = `Invalid parent item ${fact.parentUid}`;
      warnValidation(factWarn, warnings);
    }

    // deps and precs
    if (!fact.formula) return;
    const factModule = modules[fact.moduleUid];
    const precs = getPrecsFromFx(fact.formula, facts, lineItems, modules, factModule, fact.caseUid);
    precs.forEach((entry) => {
      entry.forEach((prec) => {
        const selector = stringToSelector(prec);
        if (!selector) return;
        const precUid = getUidFromVariable(selector, facts, lineItems, modules, fact.caseUid);
        if (!precUid) {
          factWarn.msg = `Missing store fact for variable "${selector}"`;
          warnValidation(factWarn, warnings);
          return;
        }
        if (!fact.precedentCells?.find((prec) => prec.uid === precUid)) {
          factWarn.msg = `Missing precedent cell ${precUid} in fact ${fact.uid}`;
          warnValidation(factWarn, warnings);
          return;
        }
        if (!facts[precUid].dependantCells?.find((dep) => dep.uid === fact.uid)) {
          factWarn.msg = `Missing dependant cell ${fact.uid} in fact ${precUid}`;
          warnValidation(factWarn, warnings);
        }
      });
    });
  });
}

/**
 * Clean nested edges property from case-modules-childModules-lineItems-facts tree structure
 * Also fixes id prop to old uid convention
 */
interface Entity {
  uid: string;
  id: string;
  edges?: Record<string, unknown>;
  [key: string]: unknown;
}
export const cleanEdges = (entity: Entity) => {
  // uid
  entity.uid = entity.id;
  // edges
  if (!entity.edges) return;
  for (const entityKey in entity.edges) {
    const edgeEntities = entity.edges[entityKey] as Entity[];
    if (!edgeEntities) return;
    entity[entityKey] = edgeEntities;
    edgeEntities.forEach((subEdgeEntity) => {
      if (subEdgeEntity.edges) {
        cleanEdges(subEdgeEntity);
      }
    });
  }
  delete entity.edges;
};

/**
 * Merge models: newly normalized entities with existing ones in state
 */
export const mergeModelEntities = (entities: Partial<IModel>, state: IModel) => {
  for (const entKey in entities) {
    if (entKey !== "modules" && entKey !== "lineItems" && entKey !== "facts") return;
    const stateEnt = state[entKey];
    const serverEnt = entities[entKey];
    for (const itemKey in serverEnt) {
      const stateItem = stateEnt[itemKey];
      const serverItem = serverEnt[itemKey];
      if (stateItem) {
        // merge
        const newItem = {
          ...stateItem,
          ...serverItem,
        };
        // loading prop for facts only
        if (isFactEntity(stateItem) && isFactEntity(newItem)) {
          newItem.loading = stateItem.loading ? stateItem.loading - 1 : 0;
        }
        stateEnt[itemKey] = newItem;
      } else stateEnt[itemKey] = serverItem; // add new
    }
  }
};

export const sortModulesByOrder = (modules: IModule[]) => {
  if (!modules) return [];
  const sorted: IModule[] = [];
  const addChildren = (mod: IModule) => {
    if (mod.childModules) {
      const childMods: IModule[] = mod.childModules
        .map((childModuleId) => modules.find((m) => m.uid === childModuleId))
        .filter((m): m is IModule => !!m)
        .sort((a, b) => a.order - b.order);
      childMods.forEach((childMod) => {
        sorted.push(childMod);
        if (childMod?.childModules) {
          addChildren(childMod);
        }
      });
    }
  };
  const rootModules = modules.filter((mod) => mod.depth === 0).sort((a, b) => a.order - b.order);

  rootModules.forEach((rootMod) => {
    sorted.push(rootMod);
    if (rootMod.childModules) {
      addChildren(rootMod);
    }
  });
  return sorted;
};

/** merge entities array into existing state entity */
export const mergeEntities = (entities: Entity[], stateEntities: Record<string, Entity>) => {
  if (!entities || !stateEntities) return;
  entities.forEach((ent) => {
    const stateEntity = stateEntities[ent.id];
    if (!stateEntity) {
      // eslint-disable-next-line no-console
      console.warn("Couldn't merge entity because there wasn't a corresponding ID in state", ent);
      return;
    }
    const newEntity = {
      ...stateEntity,
      ...ent,
    };

    // facts have loading prop that is numeric and might need to just be decremented
    if ("loading" in stateEntity) {
      const loading = stateEntity.loading;
      if (typeof loading === "number") {
        newEntity.loading = loading > 0 ? loading - 1 : 0;
      }
    }

    stateEntities[ent.id] = newEntity;
  });
};
