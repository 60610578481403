import Auth from "@aws-amplify/auth";
import { captureException } from "@sentry/react";
import { Intent } from "@blueprintjs/core";
import { AuthState } from "constants/appConstants";

import { ONE_DIGIT, ONE_LOWERCASE, ONE_UPPERCASE } from "constants/regexContants";
import { multiToaster } from "utils/toaster";
import { userErrors } from "constants/errors";

if (!window.__env__) window.__env__ = process.env;

Auth.configure({
  region: window.__env__.REACT_APP_COGNITOREGION,
  userPoolId: window.__env__.REACT_APP_COGNITOUSERPOOLID,
  userPoolWebClientId: window.__env__.REACT_APP_COGNITOAPPCLIENTID,
  authenticationFlowType: "USER_SRP_AUTH",
});

export async function getToken(tokenType) {
  try {
    const session = await Auth.currentSession();
    return session[tokenType].jwtToken;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    captureException(err);
    multiToaster?.show({
      message: userErrors.SESSION_EXPIRED,
      intent: Intent.PRIMARY,
    });
    Auth.signOut();
    return err;
  }
}

export function signOut() {
  try {
    Auth.signOut();
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    captureException(err);
  }
}

/**
 * Validates that a proposed password meets the Valsys password requriements
 * @param {string} password - The password to validate
 * */
export function validateProposedPassword(password) {
  const validationTags = [];
  if (password.length < 8) {
    validationTags.push("8 characters");
  }
  if (!password.match(ONE_UPPERCASE)) {
    validationTags.push("One uppercase");
  }
  if (!password.match(ONE_LOWERCASE)) {
    validationTags.push("One lowercase");
  }
  if (!password.match(ONE_DIGIT)) {
    validationTags.push("One digit");
  }
  return validationTags;
}

export function getNextAuthState(message) {
  const { event } = message.payload;
  switch (event) {
    case "signIn":
      return AuthState.SignedIn;
    case "signOut":
      return AuthState.SignIn;
    case "forgotPassword_failure":
      return AuthState.ForgotPassword;
    case "forgotPassword":
      return AuthState.ForgotPassword;
    default:
      return null;
  }
}

/**
 * Utility to get the current authenticated user's Cognito information
 *
 * @param {Object} config - AWS Cognito config object.
 * */
export async function currentUser(config = {}) {
  try {
    const user = await Auth.currentAuthenticatedUser(config);
    return user;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
  }
}

/**
 * Utility to get a supplied user's MFA information
 *
 * @param {Object} user - An AWS Cognito user object.
 * */
export async function getPreferredMFA(user, config = {}) {
  try {
    const MFAType = await Auth.getPreferredMFA(user, config);
    return MFAType;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
  }
}

export async function setPreferredMFA(user, type) {
  try {
    let userObject = user;
    if (!userObject) userObject = await currentUser({ bypassCache: true });
    const res = await Auth.setPreferredMFA(user, type);
    return res;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
    multiToaster.show({
      intent: Intent.DANGER,
      message: "Something went wrong, please try again!",
    });
  }
}

export default Auth;
