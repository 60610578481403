import { Colors, Intent, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { Dispatch, SetStateAction, useCallback, useContext } from "react";
import { EWidgetType2, IVSLTable, VSLResponse } from "types/vsl";
import { Widget } from "types/widget";
import HighchartsReact from "highcharts-react-official";
import { downloadFile } from "utils/generic";
import { vslTableToCSVString } from "utils/table";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import { isFunction, noop } from "lodash";

interface WidgetTitleMenuProps {
  chartComponentRef?: React.RefObject<HighchartsReact.RefObject>;
  copyToCurrentView: () => void;
  data?: VSLResponse;
  enableEditWidgetDialog?: boolean;
  onRemove?: (widget: Widget) => void;
  inView: boolean;
  setCopyWidgetDialogOpen: Dispatch<SetStateAction<boolean>>;
  setEditWidgetDialogOpen: Dispatch<SetStateAction<boolean>>;
  setSelectorsDialogOpen: Dispatch<SetStateAction<boolean>>;
  widget?: Widget;
}

const WidgetTitleMenu: React.FC<WidgetTitleMenuProps> = ({
  copyToCurrentView,
  chartComponentRef,
  data,
  enableEditWidgetDialog,
  onRemove,
  inView,
  setCopyWidgetDialogOpen,
  setEditWidgetDialogOpen,
  setSelectorsDialogOpen,
  widget,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const widgetType = widget?.widgetType;

  const handleDownloadTableAsCSV = useCallback(() => {
    if (!data?.data) return;
    const csvContent = vslTableToCSVString(data.data as IVSLTable);
    downloadFile(csvContent, `${widget?.name}.csv`);
  }, [data?.data, widget?.name]);

  const handleDownloadChartAsCSV = useCallback(() => {
    if (chartComponentRef?.current?.chart) {
      chartComponentRef.current.chart.downloadCSV();
    }
  }, [chartComponentRef]);

  const handleDownloadChart = useCallback(
    (type: Highcharts.ExportingMimeTypeValue) => {
      if (chartComponentRef?.current?.chart.exportChartLocal) {
        chartComponentRef?.current?.chart.exportChartLocal({
          chartOptions: {
            chart: { backgroundColor: theme === Themes.DARK ? Colors.WHITE : Colors.DARK_GRAY3 },
          },
          filename: widget?.name ?? "Valsys Chart",
          type,
        });
      }
    },
    [chartComponentRef, theme, widget?.name]
  );
  return (
    <Menu>
      <MenuItem
        disabled={!enableEditWidgetDialog}
        icon={IconNames.COG}
        onClick={() => setEditWidgetDialogOpen(true)}
        text="Edit Widget"
      />
      <MenuItem
        icon={IconNames.FILTER_LIST}
        onClick={() => setSelectorsDialogOpen(true)}
        text="Edit Widget Selectors"
      />
      <MenuDivider />
      <MenuItem
        disabled={!inView}
        icon={IconNames.DUPLICATE}
        onClick={copyToCurrentView}
        text="Copy Widget to this View"
      />
      <MenuItem
        icon={IconNames.SEND_TO}
        onClick={() => setCopyWidgetDialogOpen(true)}
        text="Copy Widget to other View"
      />
      <MenuDivider />
      {widgetType === EWidgetType2.TABLE ? (
        <MenuItem
          icon={IconNames.DOWNLOAD}
          onClick={handleDownloadTableAsCSV}
          text="Download raw values as CSV"
        />
      ) : (
        <MenuItem icon={IconNames.DOWNLOAD} text="Download chart">
          <MenuItem
            onClick={() => {
              handleDownloadChart("image/png");
            }}
            text="Download as PNG"
          />
          <MenuItem
            onClick={() => {
              handleDownloadChart("image/svg+xml");
            }}
            text="Download as SVG"
          />
          <MenuItem
            onClick={() => {
              handleDownloadChart("image/jpeg");
            }}
            text="Download as JPEG"
          />
          <MenuDivider />
          <MenuItem onClick={handleDownloadChartAsCSV} text="Download raw values as CSV" />
        </MenuItem>
      )}
      <MenuDivider />
      <MenuItem
        icon={IconNames.REMOVE}
        intent={Intent.DANGER}
        disabled={!onRemove}
        onClick={() => (isFunction(onRemove) && widget ? onRemove(widget) : noop())}
        text="Remove Widget from View"
      />
    </Menu>
  );
};

export default WidgetTitleMenu;
