import React, { useEffect, useState } from "react";
import { Callout, Classes, FormGroup, H6, InputGroup, Intent, Tag } from "@blueprintjs/core";

import LockButton from "components/utility/LockButton";
import { Flex, PaddedContent } from "components/utility/StyledComponents";
import { validateProposedPassword } from "utils/auth";

const NewPasswordForm = ({
  children,
  password,
  passwordConfirmation,
  // Only ever set helper text on the first input
  setPasswordHelperText,
  passwordHelperText,
  passwordConfirmationHelperText,
  setPassword,
  setPasswordConfirmation,
  passwordValid,
  setPasswordValid,
  passwordsMatch,
  setPasswordConfirmationHelperText,
  setPasswordsMatch,
}) => {
  // LockButton state
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [passwordValidationTags, setPasswordValidationTags] = useState([
    "8 characters",
    "One uppercase",
    "One lowercase",
    "One number",
  ]);

  useEffect(() => {
    const remainingValidations = validateProposedPassword(password);
    const passwordsMatch = password === passwordConfirmation;
    const passwordValid = remainingValidations.length === 0;
    setPasswordValidationTags(remainingValidations);

    // Validation variables

    // Password helper text
    if (password.length > 0) {
      if (passwordValid) {
        setPasswordHelperText("Password conforms to policy.");
      } else {
        setPasswordHelperText("Password does not conform to policy.");
      }
    } else {
      setPasswordHelperText("");
    }

    // Password confirmation helper text
    if (passwordConfirmation.length > 0) {
      if (passwordsMatch) {
        setPasswordConfirmationHelperText("Passwords match.");
      } else {
        setPasswordConfirmationHelperText("Passwords do not match.");
      }
    } else {
      setPasswordConfirmationHelperText("");
    }

    setPasswordValid(passwordValid);
    setPasswordsMatch(passwordsMatch);
  }, [
    password,
    passwordConfirmation,
    setPasswordConfirmationHelperText,
    setPasswordHelperText,
    setPasswordValidationTags,
    setPasswordValid,
    setPasswordsMatch,
  ]);

  return (
    <>
      {children}
      <p className={Classes.RUNNING_TEXT}>
        Passwords must be at least 8 characters in length and contain one uppercase letter, one
        lowercase letter and one number.
      </p>
      {passwordValid && passwordsMatch ? (
        <PaddedContent padding="0 0 5px 0">
          <Callout intent={Intent.SUCCESS}>
            <H6>Passwords valid. Click confirm to proceed.</H6>
          </Callout>
        </PaddedContent>
      ) : (
        <PaddedContent padding="7px 0 17px 0">
          <Flex fullWidth justifyContent="flex-start">
            {passwordValid ? (
              <Tag intent={Intent.SUCCESS} icon="tick" minimal>
                Password conforms to policy
              </Tag>
            ) : (
              passwordValidationTags.map((tag) => (
                <Tag key={tag} intent={Intent.PRIMARY} minimal style={{ marginRight: "4px" }}>
                  {tag}
                </Tag>
              ))
            )}
          </Flex>
        </PaddedContent>
      )}
      <FormGroup
        helperText={passwordHelperText}
        intent={password.length > 0 ? (passwordValid ? Intent.SUCCESS : Intent.WARNING) : null}
        label="New password:"
        labelInfo="(required)"
      >
        <InputGroup
          intent={password.length > 0 ? (passwordValid ? Intent.SUCCESS : Intent.WARNING) : null}
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value.trim());
          }}
          rightElement={
            <LockButton
              handleLockClick={() => setShowPassword(!showPassword)}
              showPassword={showPassword}
            />
          }
        />
      </FormGroup>
      <FormGroup
        helperText={passwordConfirmationHelperText}
        intent={
          passwordConfirmation.length > 0
            ? passwordValid && passwordsMatch
              ? Intent.SUCCESS
              : Intent.WARNING
            : null
        }
        label="Confirm new password:"
        labelInfo="(required)"
      >
        <InputGroup
          intent={
            passwordConfirmation.length > 0
              ? passwordValid && passwordsMatch
                ? Intent.SUCCESS
                : Intent.WARNING
              : null
          }
          type={showPasswordConfirmation ? "text" : "password"}
          value={passwordConfirmation}
          onChange={(e) => {
            setPasswordConfirmation(e.target.value.trim());
          }}
          rightElement={
            <LockButton
              handleLockClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
              showPassword={showPasswordConfirmation}
            />
          }
        />
      </FormGroup>
    </>
  );
};

export default NewPasswordForm;
