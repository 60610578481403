import useSWR, { KeyedMutator, mutate as globalMutate } from "swr";
import {
  COPY_VIEW_ROUTE,
  EMethods,
  EStatus,
  GET_CONFIGS,
  GET_WIDGETS,
  VIEW_ROUTE,
  WIDGET_LINK_ROUTE,
  WIDGET_ROUTE,
} from "constants/apiConstants";
import { IResponse } from "types";
import { View, ViewWidget } from "types/view";
import { useCallback, useMemo, useState } from "react";
import api, { swrApi } from "utils/api";
import { multiToaster } from "utils/toaster";
import { Intent } from "@blueprintjs/core";
import { Widget } from "types/widget";
import { Layout } from "react-grid-layout";

const MAX_WIDGET_WIDTH = 12;
const MIN_WIDGET_WIDTH = 3;
const MIN_WIDGET_HEIGHT = 5;

/*const defaultWidgetLayout = {
  x: 0,
  y: 99,
  w: 6,
  h: 10,
  maxW: MAX_WIDGET_WIDTH
  minW: MIN_WIDGET_WIDTH,
  minH: MIN_WIDGET_HEIGHT,
};*/

type TUseView = (viewId?: string) => {
  addWidgetToView: (widget: Widget) => Promise<boolean>;
  copyViewToDashboard: (dashboardId: string) => Promise<IResponse<unknown>>;
  deleteView: () => void;
  error?: { message: string };
  loading: boolean;
  mutate: KeyedMutator<IResponse<View>>;
  removeWidgetFromView: (widget: Widget) => void;
  savingView: boolean;
  setDescription: (value: string) => void;
  setName: (value: string) => void;
  updateLayout: (layouts: Layout[], oldLayout: Layout, newLayout: Layout) => void;
  updateView: (details: Partial<View>) => Promise<boolean>;
  updateViewWidget: (details: Partial<ViewWidget> & { id: string }) => Promise<boolean>;
  updateViewWidgets: (nextWidgets: ViewWidget[]) => void;
  view?: View;
  widgets?: ViewWidget[];
};

/**
 * Hook to interact with an individual view. handles local modification and saving to the server.
 * */
const useView: TUseView = (viewId) => {
  const [savingView, setSavingView] = useState(false);

  const {
    data: view,
    error,
    isLoading: loadingView,
    mutate,
  } = useSWR<IResponse<View>>(
    viewId
      ? [
          VIEW_ROUTE,
          {
            viewId,
          },
        ]
      : null,
    swrApi
  );

  const {
    data: viewWidgetsResponse,
    isLoading: loadingWidgets,
    mutate: mutateWidgets,
  } = useSWR<IResponse<ViewWidget[]>>(
    viewId
      ? [
          GET_WIDGETS,
          {
            viewId,
          },
        ]
      : null,
    swrApi
  );

  const widgets = useMemo(() => {
    if (viewWidgetsResponse?.status === EStatus.success && !viewWidgetsResponse?.data) return [];
    return (
      viewWidgetsResponse?.data
        // only render widgets that have a layout
        ?.filter((widget) => widget.layout)
        // enforce layout max and min dimensions
        .map((viewWidget) => {
          const newViewWidget = { ...viewWidget };
          const newLayout = { ...newViewWidget.layout };
          newLayout.maxW = MAX_WIDGET_WIDTH;
          newLayout.minW = MIN_WIDGET_WIDTH;
          newLayout.minH = MIN_WIDGET_HEIGHT;
          delete newLayout.maxH;
          newViewWidget.layout = newLayout;
          return newViewWidget;
        })
    );
  }, [viewWidgetsResponse]);

  const saveView = useCallback(
    async (viewToSave: View) => {
      if (!viewToSave) return;

      setSavingView(true);

      const response = await api(VIEW_ROUTE, {
        method: EMethods.PUT,
        viewId,
        body: viewToSave,
      });

      if (response?.status === EStatus.success) {
        await globalMutate(GET_CONFIGS);
        setSavingView(false);
      } else {
        multiToaster.show({ message: response.error || response.message, intent: Intent.WARNING });
        setSavingView(false);
        await mutate();
      }
    },
    [mutate, viewId]
  );

  const updateView = useCallback(
    async (details: Partial<View>, updateRemote = true) => {
      if (!view || !viewId) return false;
      const nextView = {
        ...view.data,
        ...details,
        createdAt: String(new Date(view.data.createdAt)),
      };
      if (!nextView.name) {
        multiToaster.show({
          message: "Please provide a name for the view.",
          intent: Intent.DANGER,
        });
      }

      const nextData = {
        ...view,
        status: EStatus.success,
        data: nextView,
      };
      await mutate(nextData, false);
      if (updateRemote) await saveView(nextView);
      return true;
    },
    [mutate, saveView, view, viewId]
  );

  const saveWidget = useCallback(async (nextWidget: ViewWidget): Promise<boolean> => {
    const response = await api(WIDGET_ROUTE, {
      method: EMethods.PUT,
      widgetId: nextWidget.id,
      body: nextWidget,
    });

    if (response.status !== EStatus.success) {
      multiToaster.show({ intent: Intent.WARNING, message: response.error });
      return false;
    } else {
      return true;
    }
  }, []);

  /** Persist a view widget and mutate it locally */
  const updateViewWidget = useCallback(
    async (details: Partial<ViewWidget> & { id: string }) => {
      await mutateWidgets((previousData) => {
        if (!previousData?.data) return;
        const widgets = previousData.data;
        const widgetIdx = widgets.findIndex((w) => w.id === details.id);
        const nextWidget = { ...widgets[widgetIdx], ...details } as ViewWidget;
        // TODO: we need access to the result of saveWidget promise outside to return function promise
        saveWidget(nextWidget);

        // mutate widgets
        const nextWidgets = [...widgets];
        nextWidgets[widgetIdx] = nextWidget;
        return {
          ...previousData,
          status: EStatus.success,
          data: nextWidgets ?? [],
        };
      }, false);
      return true;
    },
    [mutateWidgets, saveWidget]
  );

  /** Batch persist and mutate locally view widgets */
  const updateViewWidgets = useCallback(
    (changedWidgets: ViewWidget[]) => {
      if (!changedWidgets) return;

      mutateWidgets((prevData) => {
        if (!prevData?.data) return;
        const widgets = prevData.data;
        const nextWidgets = [...widgets];

        // merge changed widgets into existing view widgets
        changedWidgets.forEach((changedWidget) => {
          const changedWidgetIdx = nextWidgets.findIndex(
            (nextWidget) => nextWidget.id === changedWidget.id
          );
          nextWidgets[changedWidgetIdx] = changedWidget;
          saveWidget(changedWidget);
        });

        return {
          ...prevData,
          status: EStatus.success,
          data: nextWidgets,
        };
      }, false);
    },
    [mutateWidgets, saveWidget]
  );

  function setName(value: string) {
    updateView({ name: value });
  }

  function setDescription(value: string) {
    updateView({ description: value });
  }

  const deleteView = async () => {
    if (!view?.data || !viewId) return;

    if (!confirm(`Delete dashboard ${view.data.name}?`)) return;

    const response = await api(VIEW_ROUTE, {
      method: EMethods.DELETE,
      viewId,
    });

    if (response?.status === EStatus.success) {
      multiToaster.show({ intent: Intent.SUCCESS, message: "Successfully deleted View!" });
      await globalMutate(GET_CONFIGS);
    } else {
      multiToaster.show({ intent: Intent.WARNING, message: response.error });
    }
  };

  const addWidgetToView = useCallback(
    async (widget: Widget): Promise<boolean> => {
      if (!view?.data || !viewId) return false;
      const response = await api(WIDGET_LINK_ROUTE, {
        method: EMethods.PATCH,
        viewId,
        widgetId: widget.id,
      });

      if (response.status === EStatus.success) {
        // TODO: when we get a widget id from the server we can mutate eagerly
        /*const saveableWidget: ViewWidget = {
          ...widget,
          layout: { ...defaultWidgetLayout, i: widget.id },
        };

        const nextWidgets = widgets ? [...widgets, saveableWidget] : [saveableWidget];
        const nextData = {
          ...viewWidgetsResponse,
          status: EStatus.success,
          data: nextWidgets ?? [],
        };*/
        await mutateWidgets();

        return true;
      } else {
        multiToaster.show({ intent: Intent.WARNING, message: response?.error });
        return false;
      }
    },
    [mutateWidgets, view?.data, viewId]
  );

  const removeWidgetFromView = useCallback(
    async (widget: Widget) => {
      if (!viewId) return;
      const response = await api(WIDGET_LINK_ROUTE, {
        method: EMethods.DELETE,
        viewId,
        widgetId: widget.id,
      });
      if (response.status === EStatus.success) {
        mutateWidgets((previousData) => {
          if (!previousData?.data) return;
          const widgets = previousData.data;
          const nextWidgets = [...widgets];
          const deleteIndex = nextWidgets?.findIndex((w) => w.id === widget.id);
          nextWidgets.splice(deleteIndex, 1);
          return {
            ...previousData,
            status: EStatus.success,
            data: nextWidgets ?? [],
          };
        });
      }
    },
    [mutateWidgets, viewId]
  );

  /** Handles updating the grid layout in ViewWidgets */
  const updateLayout = useCallback(
    (nextLayouts: Layout[]) => {
      mutateWidgets((prevData) => {
        if (!prevData?.data) return;
        const widgets = prevData.data;
        const nextWidgets = widgets.map((widget) => {
          const nextLayout = nextLayouts.find((layout) => layout.i === widget.id);
          const nextWidget = { ...widget, ...(nextLayout && { layout: nextLayout }) };
          saveWidget(nextWidget);
          return nextWidget;
        });
        return {
          ...prevData,
          status: EStatus.success,
          data: nextWidgets,
        };
      }, false);
    },
    [mutateWidgets, saveWidget]
  );

  const copyViewToDashboard = useCallback(
    async (dashboardId: string) => {
      return await api(COPY_VIEW_ROUTE, { dashboardId, method: EMethods.POST, viewId });
    },
    [viewId]
  );

  const loading = loadingView || loadingWidgets;

  return {
    addWidgetToView,
    copyViewToDashboard,
    deleteView,
    error,
    loading,
    mutate,
    removeWidgetFromView,
    savingView,
    setDescription,
    setName,
    updateLayout,
    updateView,
    updateViewWidget,
    updateViewWidgets,
    view: loading ? undefined : view?.data,
    widgets: loading ? undefined : widgets,
  };
};

export default useView;
