import {
  Button,
  Callout,
  Classes,
  DialogFooter,
  DialogProps,
  FormGroup,
  HTMLSelect,
  Intent,
} from "@blueprintjs/core";
import {
  Flex,
  FullWidthDialog,
  Grid,
  NonScrollableDialogBody,
  StyledScrollContainer,
} from "components/utility/StyledComponents";
import {
  DELETE_MODEL_REVISIONS,
  EMethods,
  EStatus,
  GET_MODEL_REVISIONS,
  errorMessages,
} from "constants/apiConstants";
import React, { useContext, useMemo, useState } from "react";
import api, { swrApi } from "utils/api";
import useSWR from "swr";
import AppContext from "context/appContext";
import { Themes } from "constants/uiConstants";
import { Scenarios } from "constants/appConstants";
import { IconNames } from "@blueprintjs/icons";
import { IRawFact } from "types/model";
import Table, { TableValue } from "components/table/Table";
import { multiToaster } from "utils/toaster";
import { IResponse } from "types";
import LoadingIndicator from "components/utility/LoadingIndicator";

interface SantiseModelDataProps extends DialogProps {
  modelId: string;
}

interface TrackedFactsResponse {
  revisions: {
    factTags: { [key: string]: IRawFact };
    revision: string;
    trackedFactIds: string[];
    updateType: "Unknown" | "Dynamic Update";
  }[];
}

const ForecastPeriodLength = 12;

const RootTags = ["Revenue", "Operating Income (EBIT)", "Expected Return (%), Valuation"];
const Periods = new Array(ForecastPeriodLength)
  .fill("")
  .map((_, i) => (i === 0 ? "LFY" : `LFY+${i}`));

const SantiseModelData: React.FC<SantiseModelDataProps> = ({ modelId, ...dialogProps }) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const [period, setPeriod] = useState<string>("LFY");
  const [scenario, setScenario] = useState<string>("Base");
  const [selectedRows, setSelectedRows] = useState<
    { id: string; [key: string]: TableValue | undefined }[]
  >([]);

  const factTags: string[] = RootTags.map((rootTag) => {
    return `[${rootTag} (${scenario})[${period}]]`;
  });

  const { data, error, isLoading, mutate } = useSWR<IResponse<TrackedFactsResponse>>(
    [GET_MODEL_REVISIONS, { body: { factTags }, method: EMethods.POST, modelId }],
    swrApi
  );

  const columnTemplates = useMemo(() => {
    return [
      { header: "Revision Date", id: "revision" },
      { header: "Type of Update", id: "updateType" },
      ...factTags.map((tag) => ({ header: tag, id: tag })),
    ];
  }, [factTags]);

  const tableData = useMemo(() => {
    if (data?.data?.revisions) {
      const sortedData = data.data.revisions;
      sortedData.sort((a, b) => new Date(b.revision).getTime() - new Date(a.revision).getTime());
      const tableData = sortedData.map((revision) => {
        return {
          id: revision.revision,
          revision: new Date(revision.revision).toLocaleString(),
          updateType: revision.updateType,
          ...factTags.reduce((acc, tag) => ({ ...acc, [tag]: revision.factTags[tag] }), {}),
        };
      });
      return tableData;
    }
    return [];
  }, [data, factTags]);

  const deleteRevisions = async () => {
    if (
      confirm(
        "Deleting revisions is permanent and cannot be undone. Are you sure you wish to proceed?"
      )
    ) {
      const revisions = selectedRows.map((row) => row.id);

      if (revisions.length === 0 || !modelId) {
        return;
      }

      const response = await api(DELETE_MODEL_REVISIONS, {
        method: EMethods.DELETE,
        body: { modelId, revisions },
      });

      if (response?.status === EStatus.success) {
        multiToaster.show({ intent: Intent.SUCCESS, message: "Successfully deleted revisions." });
        mutate();
      } else {
        multiToaster.show({
          intent: Intent.DANGER,
          message: `Unable to delete revisions: ${response.message}`,
        });
      }
    }
  };

  return (
    <FullWidthDialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      title="Data Sanitization"
      {...dialogProps}
    >
      <NonScrollableDialogBody>
        <Grid fullHeight gap="10px" rows="auto auto 1fr auto" style={{ overflow: "hidden" }}>
          <Callout intent={Intent.WARNING} title="Potentially destructive operation.">
            <p className={Classes.RUNNING_TEXT}>
              Deleting data cannot be undone. Please do not delete anything without prior approval.
            </p>
          </Callout>
          <Flex fullWidth columnGap={10} justifyContent="flex-start">
            <FormGroup label="Scenario:">
              <HTMLSelect
                onChange={(e) => setScenario(e.currentTarget.value)}
                options={Scenarios}
                value={scenario}
              />
            </FormGroup>
            <FormGroup label="Period:">
              <HTMLSelect
                onChange={(e) => setPeriod(e.currentTarget.value)}
                options={Periods}
                value={period}
              />
            </FormGroup>
          </Flex>
          <StyledScrollContainer $theme={theme}>
            {isLoading && (
              <LoadingIndicator
                status={{ intent: Intent.PRIMARY, message: "Loading revisions..." }}
              />
            )}
            {!isLoading && error && (
              <Callout icon={IconNames.ERROR}>
                {error?.message ?? errorMessages.SOMETHING_WENT_WRONG}
              </Callout>
            )}
            {!isLoading && !error && (
              <Table
                columnTemplates={columnTemplates}
                configOverrides={{ layoutMode: "auto" }}
                enableConfigOverrides={false}
                data={tableData}
                id="data-sanitization-table"
                loading={isLoading}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            )}
          </StyledScrollContainer>
          <Flex justifyContent="flex-start">
            <Button
              disabled={selectedRows.length === 0 || isLoading}
              intent={Intent.DANGER}
              icon={IconNames.TRASH}
              onClick={deleteRevisions}
              text="Permanently Delete Selected Revisions"
            />
          </Flex>
        </Grid>
      </NonScrollableDialogBody>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={dialogProps?.onClose} text="Close" />
        </div>
      </DialogFooter>
    </FullWidthDialog>
  );
};

export default SantiseModelData;
