import { IEditingNode } from "components/model/Explorer";
import { createContext, Dispatch, SetStateAction } from "react";

interface ITreeContext {
  locked: boolean;
  setEditingNode: Dispatch<SetStateAction<IEditingNode | undefined>>;
}

const TreeContext = createContext<ITreeContext>({
  locked: false,
  setEditingNode: () => {
    throw new Error("setEditingNode function must be set!");
  },
});

export default TreeContext;
