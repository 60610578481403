import React from "react";
import { Button, MenuDivider, MenuItem } from "@blueprintjs/core";
import { ChartWidgetConfig } from "types/widget";
import { IconNames } from "@blueprintjs/icons";
import { Flex } from "components/utility/StyledComponents";
import { EUnit, EValFormat } from "types/format";

interface XAxisLabelFormatMenuProps {
  chartRendererConfig?: ChartWidgetConfig;
  updateConfig: (details: Partial<ChartWidgetConfig>) => void;
}

const XAxisLabelFormatMenu: React.FC<XAxisLabelFormatMenuProps> = ({
  chartRendererConfig,
  updateConfig,
}) => {
  const decimalPlaces = chartRendererConfig?.xAxisFormat?.decimalPlaces;
  const valFormat = chartRendererConfig?.xAxisFormat?.valFormat;
  const unit = chartRendererConfig?.xAxisFormat?.unit;

  return (
    <>
      <MenuDivider title="Label Format" />
      <MenuItem shouldDismissPopover={false} text="Format">
        <MenuItem
          icon={!valFormat && IconNames.TICK}
          onClick={() => {
            updateConfig({
              ...chartRendererConfig,
              xAxisFormat: { ...chartRendererConfig?.xAxisFormat, valFormat: undefined },
            });
          }}
          shouldDismissPopover={false}
          text="Default"
        />
        <MenuDivider />
        {Object.values(EValFormat).map((vF) => (
          <MenuItem
            icon={valFormat === vF && IconNames.TICK}
            key={vF}
            onClick={() => {
              let unit = chartRendererConfig?.unit;
              if (vF !== EValFormat.NUMERIC && vF !== EValFormat.CURRENCY) {
                unit = undefined;
              }
              updateConfig({
                ...chartRendererConfig,
                xAxisFormat: { ...chartRendererConfig?.xAxisFormat, valFormat: vF, unit },
              });
            }}
            shouldDismissPopover={false}
            text={vF}
          />
        ))}
      </MenuItem>
      <MenuItem shouldDismissPopover={false} text="Unit">
        <MenuItem
          icon={!unit && IconNames.TICK}
          onClick={() => {
            updateConfig({
              ...chartRendererConfig,
              xAxisFormat: { ...chartRendererConfig?.xAxisFormat, unit: undefined },
            });
          }}
          shouldDismissPopover={false}
          text="Default"
        />
        <MenuDivider />
        {Object.values(EUnit).map((ut) => (
          <MenuItem
            icon={unit === ut && IconNames.TICK}
            key={ut}
            onClick={() => {
              updateConfig({
                ...chartRendererConfig,
                xAxisFormat: { ...chartRendererConfig?.xAxisFormat, unit: ut },
              });
            }}
            shouldDismissPopover={false}
            text={ut}
          />
        ))}
      </MenuItem>
      <MenuItem
        shouldDismissPopover={false}
        text="Decimal Places:"
        labelElement={
          <Flex>
            <Button
              icon={IconNames.SMALL_MINUS}
              onClick={() => {
                if (decimalPlaces == undefined) {
                  updateConfig({
                    ...chartRendererConfig,
                    xAxisFormat: { ...chartRendererConfig?.xAxisFormat, decimalPlaces: 1 },
                  });
                  return;
                }
                if (decimalPlaces - 1 < 0) return;
                updateConfig({
                  ...chartRendererConfig,
                  xAxisFormat: {
                    ...chartRendererConfig?.xAxisFormat,
                    decimalPlaces: decimalPlaces - 1,
                  },
                });
              }}
              small
              minimal
            />
            <Button
              icon={IconNames.SMALL_PLUS}
              onClick={() => {
                if (decimalPlaces == undefined) {
                  updateConfig({
                    ...chartRendererConfig,
                    xAxisFormat: { ...chartRendererConfig?.xAxisFormat, decimalPlaces: 1 },
                  });
                  return;
                }
                updateConfig({
                  ...chartRendererConfig,
                  xAxisFormat: {
                    ...chartRendererConfig?.xAxisFormat,
                    decimalPlaces: decimalPlaces + 1,
                  },
                });
              }}
              small
              minimal
            />
          </Flex>
        }
      />
    </>
  );
};

export default XAxisLabelFormatMenu;
