import React from "react";
import { Menu, MenuItem } from "@blueprintjs/core";
import { f } from "utils/formatter";
import { EValFormat } from "types/format";

const FormatOptions = ({ fact, formatAndEditCells }) => {
  const { format } = fact;
  return (
    <Menu>
      {Object.values(EValFormat).map((vForm) => (
        <MenuItem
          active={format.valFormat === vForm}
          icon={format.valFormat === vForm ? "tick" : null}
          key={vForm}
          label={f({ ...fact.format, valFormat: vForm })}
          onClick={() => formatAndEditCells({ type: "valFormat", change: vForm })}
          text={vForm}
        />
      ))}
    </Menu>
  );
};

export default FormatOptions;
