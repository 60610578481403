// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  factIdentifier = 48,
  Program = 1,
  Statement = 2,
  VariableDeclaration = 3,
  Var = 4,
  Identifier = 5,
  Equals = 6,
  String = 7,
  Number = 8,
  Array = 9,
  Boolean = 13,
  UnaryExpression = 14,
  BinaryExpression = 17,
  ParenthesizedExpression = 24,
  FunctionCall = 27,
  NamedArg = 28,
  FactRef = 29,
  FactIdentifier = 30,
  FactPeriod = 31,
  Function = 32,
  Func = 33,
  FunctionParam = 34,
  DirectiveChain = 38,
  Directive = 39,
  DirectiveName = 40,
  Dot = 41
