import { createContext, Dispatch, SetStateAction } from "react";

interface IEnvContext {
  env: EEnv | undefined;
  setEnv: Dispatch<SetStateAction<EEnv | undefined>>;
}

export enum EEnv {
  PUBLIC_CLOUD,
  PRIVATE_CLOUD,
  DEVELOPMENT,
}

const EnvContext = createContext<IEnvContext>({
  env: undefined,
  setEnv: (): void => {
    throw new Error("setEnv function must be overridden!");
  },
});

export default EnvContext;
