import React from "react";
import { Tooltip, Button } from "@blueprintjs/core";

const LockButton = ({ handleLockClick, intent, showPassword }) => (
  <Tooltip content={`${showPassword ? "Hide" : "Show"} password`}>
    <Button
      minimal
      icon={showPassword ? "unlock" : "lock"}
      intent={intent}
      onClick={handleLockClick}
    />
  </Tooltip>
);

export default LockButton;
