import { Classes, DialogProps, DialogStep, Intent, UL } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import React, { useContext, useState } from "react";
import SetWatchlistNameAndCompanies from "./SetWatchlistNameAndCompanies";
import { MatchingModel, Watchlist } from "types/collections";
import {
  FullWidthMultiStepDialog,
  NonScrollableDialogBody,
} from "components/utility/StyledComponents";
import useWatchlists from "hooks/useWatchlists";
import { EStatus, errorMessages } from "constants/apiConstants";
import LoadingIndicator from "components/utility/LoadingIndicator";
import { useNavigate } from "react-router-dom";
import CollectionModelSelection from "./CollectionModelSelection";
import useWatchlist from "hooks/useWatchlist";
import { multiToaster } from "utils/toaster";

const Steps = {
  SetNameAndCompanies: "1",
  SetModels: "2",
};

interface CreateWatchlistDialogProps extends DialogProps {
  onClose: () => void;
}

const CreateWatchlistDialog: React.FC<CreateWatchlistDialogProps> = ({
  onClose,
  ...dialogProps
}) => {
  const navigate = useNavigate();
  const [newWatchlist, setNewWatchlist] = useState<Partial<Watchlist> | undefined>({});
  const [matchingModels, setMatchingModels] = useState<MatchingModel[]>();

  const { createWatchlist, creatingWatchlist, watchlists } = useWatchlists();
  const { editWatchlist } = useWatchlist(newWatchlist?.id);

  const {
    config: { theme },
  } = useContext(AppContext);

  const handleStepChange = async (step: string) => {
    if (step === Steps.SetModels) {
      const toCreate = {
        ...newWatchlist,
        companies: newWatchlist?.companies?.map((c) => ({ ticker: c.ticker })),
      } as Watchlist;
      const createResponse = await createWatchlist(toCreate);
      if (createResponse.status === EStatus.success) {
        if (createResponse.data?.matchingModels) {
          // Begin the matching model flow
          setNewWatchlist(createResponse.data.watchlist);
          setMatchingModels(createResponse.data?.matchingModels);
        } else {
          // Close the dialog
          navigate(`../${createResponse?.data.watchlist.id}`);
          onClose();
        }
      }
    }
  };

  const handleAssignModelToTicker = (ticker: string, modelId: string) => {
    if (newWatchlist?.companies) {
      const index = newWatchlist.companies.findIndex((c) => c.ticker === ticker);
      if (index !== -1) {
        const nextCompanies = [...newWatchlist.companies];
        nextCompanies.splice(index, 1, {
          ...newWatchlist.companies[index],
          modelId: modelId,
        });
        setNewWatchlist((prev) => ({ ...prev, companies: nextCompanies }));
      }
    }
  };

  const handleSaveModels = async () => {
    if (newWatchlist) {
      const response = await editWatchlist(newWatchlist);
      if (response?.status === EStatus.success) {
        setNewWatchlist(undefined);
        setMatchingModels(undefined);
        onClose();
      } else {
        multiToaster.show({
          intent: Intent.WARNING,
          message: response?.error ?? errorMessages.SOMETHING_WENT_WRONG,
        });
      }
    }
  };

  const isWatchlistNameValid = !!(
    newWatchlist?.name &&
    (!watchlists || watchlists?.every((w) => w.name !== newWatchlist.name))
  );

  return (
    <FullWidthMultiStepDialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      finalButtonProps={{ onClick: handleSaveModels, text: "Save Models" }}
      icon={IconNames.ADD}
      onChange={handleStepChange}
      onClose={onClose}
      $showSteps={false}
      title="Create Watchlist"
      {...dialogProps}
    >
      <DialogStep
        id={Steps.SetNameAndCompanies}
        nextButtonProps={{
          disabled:
            !newWatchlist?.companies ||
            newWatchlist?.companies?.length === 0 ||
            !isWatchlistNameValid,
          rightIcon: IconNames.CIRCLE_ARROW_RIGHT,
        }}
        panel={
          <SetWatchlistNameAndCompanies
            isNameValid={isWatchlistNameValid}
            setWatchlist={setNewWatchlist}
            watchlist={newWatchlist}
          />
        }
      />
      <DialogStep
        id={Steps.SetModels}
        panel={
          <NonScrollableDialogBody>
            {creatingWatchlist && (
              <LoadingIndicator status={{ message: "Creating model...", intent: Intent.PRIMARY }} />
            )}
            {matchingModels && matchingModels.length > 0 && (
              <>
                <p className={Classes.RUNNING_TEXT}>
                  The following tickers have more than one model available. Click &quot;Next&quot;
                  to assign models
                </p>
                <UL>
                  {matchingModels.map((ticker) => (
                    <li key={ticker.ticker}>
                      {ticker.ticker}{" "}
                      <span className={Classes.TEXT_MUTED}>
                        ({ticker.models.length} models available)
                      </span>
                    </li>
                  ))}
                </UL>
              </>
            )}
          </NonScrollableDialogBody>
        }
      />
      {matchingModels &&
        matchingModels.map((ticker, i) => (
          <DialogStep
            id={Steps.SetModels}
            key={ticker.ticker}
            panel={
              <NonScrollableDialogBody>
                {matchingModels && matchingModels?.length > 0 && (
                  <CollectionModelSelection
                    setModel={(modelId) => handleAssignModelToTicker(ticker.ticker, modelId)}
                    selectedModelId={
                      newWatchlist?.companies?.find((c) => c.ticker === ticker.ticker)?.modelId
                    }
                    ticker={matchingModels[i]}
                  />
                )}
              </NonScrollableDialogBody>
            }
            title={`Select Model: ${ticker.ticker}`}
          />
        ))}
    </FullWidthMultiStepDialog>
  );
};

export default CreateWatchlistDialog;
