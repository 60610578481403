import * as consts from "constants/modelConstants";

/** Edit a model's top level properties */
export function editModelProperties(modelID, edit) {
  return {
    type: consts.EDIT_MODEL_PROPERTIES,
    payload: {
      modelID,
      edit,
    },
  };
}

/** Edit a model's top level properties */
export function editModelPropertiesRedux(modelID, edit) {
  return {
    type: consts.EDIT_MODEL_PROPERTIES_REDUX,
    payload: {
      modelID,
      edit,
    },
  };
}

/**
 * Dispatched on unsuccessful edit of a model's properties
 * */
export function editModelPropertiesFailure(modelInfo) {
  return {
    type: consts.EDIT_MODEL_PROPERTIES_FAILURE,
    payload: {
      modelInfo,
    },
  };
}

/** Pull a model's data */
export function pullModel(modelId, previousVersionHash = undefined) {
  return {
    type: consts.PULL_MODEL,
    payload: {
      modelId,
      previousVersionHash,
    },
  };
}

/** Successful model data pull */
export function pullModelSuccess(data) {
  return {
    type: consts.PULL_MODEL_SUCCESS,
    payload: {
      data,
    },
  };
}

/** Unsuccessful model data pull */
export function pullModelFailure(error) {
  return {
    type: consts.PULL_MODEL_FAILURE,
    payload: {
      error,
    },
  };
}

/**
 * Dispatched to fetch default widget data.
 *
 * @param {string} caseUid  The uid of th case for which the data is being fetched.
 * @param {string} widgetName - The name/key of the widget for which data is being requested.
 * */
export function fetchWidgetData(caseUid, widgetName) {
  return {
    type: consts.FETCH_WIDGET_DATA,
    payload: {
      caseUid,
      widgetName,
    },
  };
}

/**
 * Dispatched on successful fetch of widget data.
 *
 * @param {Object} data - The data to be used by the Widget.
 * @param {string} currentCaseUid - The uid of the case for which the data was fetched.
 * */
export function fetchWidgetDataSuccess(data, currentCaseUid) {
  return {
    type: consts.FETCH_WIDGET_DATA_SUCCESS,
    payload: {
      data,
      currentCaseUid,
    },
  };
}

/**
 * Dispatched on unsuccessful fetching of widget data.
 *
 * @param {string} widgetName - The name of the widget being fetched.
 * @param {string} error - The error the fetch failed on.
 * @param {string} currentCaseUid  - The uid of the case for which the fetch attempt was made.
 * */
export function fetchWidgetDataFailure(widgetName, error, currentCaseUid) {
  return {
    type: consts.FETCH_WIDGET_DATA_FAILURE,
    payload: {
      widgetName,
      error,
      currentCaseUid,
    },
  };
}

/**
 * Returns an action that flushes the valuation reducer back to its default state.
 */
export function flushModelReducer() {
  return {
    type: consts.FLUSH_MODEL_REDUCER,
  };
}

/**
 * Download an Excel file
 * */
export function exportToExcel() {
  return {
    type: consts.EXPORT_TO_EXCEL,
  };
}

export function updateModelCalculationsRedux(iterations, precision) {
  return {
    type: consts.UPDATE_MODEL_CALCULATIONS_REDUX,
    payload: {
      iterations,
      precision,
    },
  };
}

export function updateModelCalculationsSuccess() {
  return {
    type: consts.UPDATE_MODEL_CALCULATIONS_SUCCESS,
  };
}

export function updateModelCalculationsFailure(iterations, precision) {
  return {
    type: consts.UPDATE_MODEL_CALCULATIONS_FAILURE,
    payload: {
      iterations,
      precision,
    },
  };
}

/**
 * Update a sensitivity analysis.
 * @param {string} caseUid - The case for which the analysis is being edited.
 * @param {Object} change - The change to make to the current analysis
 * @param {Object} currentAnalysis - The analysis being modified
 **/
export function updateSensitivityIncrement(caseUid, change, currentAnalysis) {
  return {
    type: consts.UPDATE_SENSITIVITY_INCREMENT,
    payload: {
      caseUid,
      change,
      currentAnalysis,
    },
  };
}

/**
 * Dispatched on successful update of a sensitivity increment
 *
 * @param {Object} nextAnalysis - The next analysis
 * @param {string} caseUid - The case for which the analysis is being edited.
 **/
export function updateSensitivityIncrementRedux(caseUid, nextAnalysis) {
  return {
    type: consts.UPDATE_SENSITIVITY_INCREMENT_REDUX,
    payload: {
      caseUid,
      nextAnalysis,
    },
  };
}

/**
 * Dispatched on successful update of a sensitivity increment
 *
 * @param {string} caseUid - The case for which the analysis is being edited.
 * @param {Object} nextAnalysis - The next analysis
 **/
export function updateSensitivityIncrementSuccess(caseUid, nextAnalysis) {
  return {
    type: consts.UPDATE_SENSITIVITY_INCREMENT_SUCCESS,
    payload: {
      caseUid,
      nextAnalysis,
    },
  };
}

/**
 * Dispatched on unsuccessful update of a sensitivity increment
 *
 * @param {string} caseUid - The case for which the analysis is being edited.
 * @param {Object} oldAnalysis - The increment being updated
 **/
export function updateSensitivityIncrementFailure(caseUid, oldAnalysis) {
  return {
    type: consts.UPDATE_SENSITIVITY_INCREMENT_FAILURE,
    payload: {
      caseUid,
      oldAnalysis,
    },
  };
}

/**
 * Dispatched on unsuccessful update of a sensitivity increment
 *
 * @param {string} caseUid - The case for which the analysis is being edited.
 * @param {Object} nextAnalysisType - The  next analysis type to use
 **/
export function updateCurrentAnalysisType(caseUid, nextAnalysisType) {
  return {
    type: consts.UPDATE_CURRENT_ANALYSIS_TYPE,
    payload: {
      caseUid,
      nextAnalysisType,
    },
  };
}

/**
 * Generic action to fetch model data
 */
export function fetchData(route, identifier, headers) {
  return {
    type: consts.FETCH_DATA,
    payload: {
      route,
      identifier,
      headers,
    },
  };
}

export function fetchDataSuccess(identifier, data) {
  return {
    type: consts.FETCH_DATA_SUCCESS,
    payload: {
      identifier,
      data,
    },
  };
}

export function resync() {
  return {
    type: consts.RESYNC_MODEL,
  };
}
