import React, { useContext } from "react";
import styled from "styled-components/macro";
import { Link, Outlet, useOutletContext } from "react-router-dom";
import { Alignment, Button, H5 } from "@blueprintjs/core";
import { updatePageTitle } from "utils/generic";
import { Flex, NormalAlignDivider } from "components/utility/StyledComponents";
import AppContext from "context/appContext";

const StyledSettingsMenu = styled.div`
  min-width: 160px;
  flex-grow: 0;
`;

const FullWidthLink = styled(Link)`
  width: 100%;
`;

const StyledSettings = styled.div`
  height: ${({ appSize }) => `${appSize.height - 20}px`};
  margin: 20px;
`;

const SettingsLayout = () => {
  const { appSize } = useOutletContext();
  const { setLogoutDialogOpen } = useContext(AppContext);
  updatePageTitle("Settings");

  return (
    <StyledSettings appSize={appSize}>
      <Flex alignItems="flex-start" fullHeight justifyContent="flex-start">
        <Flex flex="0 0 180px" alignItems="normal" flexDirection="row">
          <StyledSettingsMenu>
            <Flex alignItems="flex-start" flexDirection="column" fullWidth>
              <H5>Profile</H5>
              <FullWidthLink to="/settings/profile">
                <Button alignText={Alignment.LEFT} fill minimal small text="Profile" />
              </FullWidthLink>
              <FullWidthLink to="/settings/interface">
                <Button alignText={Alignment.LEFT} fill minimal small text="Interface" />
              </FullWidthLink>
              <Button
                alignText={Alignment.LEFT}
                fill
                minimal
                onClick={() => {
                  setLogoutDialogOpen(true);
                }}
                small
                text="Logout"
              />
              <NormalAlignDivider />
              <H5>Security</H5>
              <FullWidthLink to="/settings/change-password">
                <Button alignText={Alignment.LEFT} fill minimal small text="Change password" />
              </FullWidthLink>
              <FullWidthLink to="/settings/two-step-auth">
                <Button
                  alignText={Alignment.LEFT}
                  fill
                  minimal
                  small
                  text="Two-step verification"
                />
              </FullWidthLink>
              <NormalAlignDivider />
              <H5>Advanced</H5>
              <FullWidthLink to="/settings/developer">
                <Button alignText={Alignment.LEFT} fill minimal small text="Developer" />
              </FullWidthLink>
              <FullWidthLink to="/settings/widgets">
                <Button alignText={Alignment.LEFT} fill minimal small text="Widgets" />
              </FullWidthLink>
              <FullWidthLink to="/settings/views">
                <Button alignText={Alignment.LEFT} fill minimal small text="Views" />
              </FullWidthLink>
              <NormalAlignDivider />
              <H5>Legal</H5>
              <FullWidthLink to="/settings/terms-of-use">
                <Button alignText={Alignment.LEFT} fill minimal small text="Terms of use" />
              </FullWidthLink>
            </Flex>
          </StyledSettingsMenu>
        </Flex>
        <NormalAlignDivider />
        <Flex alignItems="flex-start" fullHeight fullWidth justifyContent="flex-start">
          <Outlet />
        </Flex>
      </Flex>
    </StyledSettings>
  );
};

export default SettingsLayout;
