import { Colors, Tooltip } from "@blueprintjs/core";
import styled, { css } from "styled-components/macro";
import { Themes } from "constants/uiConstants";
import colors from "styles/colors.module.scss";
import { TTheme } from "types";
import { Grid, styledScrollBar } from "components/utility/StyledComponents";

// A standard grid container for a Valsys page
export const MainContainer = styled(Grid)<{ $padding?: number }>`
  height: 100vh;
  padding: ${({ $padding = 20 }) => `${$padding}px`};
`;

// A Page title component
export const StyledPageTitle = styled.div`
  background-color: ${(props) => props.theme.moduleBgColor};
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  padding: 10px;
  h5 {
    margin: 0;
  }
`;

export const StyledWidgetWrapper = styled.div<{ $bordered?: boolean; $theme: TTheme }>`
  background-color: ${({ $theme }) =>
    $theme === Themes.LIGHT ? Colors.WHITE : colors.darkModuleBackgroundColor};
  border: ${({ $bordered = true, $theme }) => {
    if ($bordered) {
      return $theme === Themes.LIGHT
        ? `1px solid ${Colors.LIGHT_GRAY3}`
        : `1px solid ${Colors.DARK_GRAY3}`;
    }
  }};
  border-radius: 4px;
  box-shadow: none;
  height: 100%;
  overflow-y: hidden;
  width: 100%;
`;

export const StyledTable = styled.table<{ $theme: TTheme; $topBorderRadius?: boolean }>`
  background-color: ${({ $theme }) =>
    $theme === Themes.LIGHT ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5};
  border-radius: 4px;
  border-spacing: 0;
  overflow: auto;
  table-layout: fixed;
  ${({ $topBorderRadius }) =>
    $topBorderRadius &&
    css`
      thead > tr {
        & > th:first-child {
          border-radius: 4px 0 0 0;
        }
        & > th:last-child {
          border-radius: 0 4px 0 0;
        }
      }
    `}
  thead {
    th {
      padding: 2px 5px;
      border-top: ${({ $theme }) =>
        $theme === Themes.LIGHT
          ? `1px solid ${Colors.LIGHT_GRAY3}`
          : `1px solid ${Colors.DARK_GRAY5}`};
      border-right: ${({ $theme }) =>
        $theme === Themes.LIGHT
          ? `1px solid ${Colors.LIGHT_GRAY3}`
          : `1px solid ${Colors.DARK_GRAY5}`};
      border-bottom: ${({ $theme }) =>
        $theme === Themes.LIGHT
          ? `1px solid ${Colors.LIGHT_GRAY3}`
          : `1px solid ${Colors.DARK_GRAY5}`};
    }
  }
  tbody {
    tr:last-child {
      td:first-child {
        border-radius: 0 0 0 4px;
      }
      td:last-child {
        border-radius: 0 0 4px 0;
      }
    }
  }
  td {
    padding: 2px 5px;
    border-right: ${({ $theme }) =>
      $theme === Themes.LIGHT
        ? `1px solid ${Colors.LIGHT_GRAY3}`
        : `1px solid ${Colors.DARK_GRAY5}`};
  }
  tbody {
    tr:not(:first-child) {
      td {
        border-top: ${({ $theme }) =>
          $theme === Themes.LIGHT
            ? `1px solid ${Colors.LIGHT_GRAY3}`
            : `1px solid ${Colors.DARK_GRAY5}`};
      }
    }
    tr:last-child {
      td {
        border-bottom: ${({ $theme }) =>
          $theme === Themes.LIGHT
            ? `1px solid ${Colors.LIGHT_GRAY3}`
            : `1px solid ${Colors.DARK_GRAY5}`};
      }
    }
  }
`;

interface IMaybeStickyThProps {
  $left?: number;
  $pinned?: boolean;
  $theme: TTheme;
}

interface IHeaderTrProps {
  $theme: TTheme;
}

export const HeaderTr = styled.tr<IHeaderTrProps>`
  height: 100%;
  width: 100%;
`;

export const MaybeStickyTh = styled.th<IMaybeStickyThProps>`
  background-color: ${({ $theme }) =>
    $theme === Themes.LIGHT ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5} !important;
  height: 100%;
  padding: 0 !important;
  z-index: 2; // To account for the model tags defaulting to the top of the headers on y scroll
  position: sticky !important;
  top: 0;
  vertical-align: middle !important;
  ${({ $left, $pinned }) =>
    $pinned &&
    css`
      left: ${$left}px;
      z-index: 3; // To ensure pinned headers stay above their unpinned colleagues on x scroll
    `};
`;

export const MaybeStickyTd = styled.td<IMaybeStickyThProps>`
  background-color: ${({ $theme }) =>
    $theme === Themes.LIGHT ? "#fff" : colors.darkModuleBackgroundColor};
  left: ${({ $left }) => $left && `${$left}px`};
  position: ${({ $pinned }) => ($pinned ? "sticky" : "unset")};
  z-index: 1;
`;

export const StyledWidgetScrollContainer = styled.div<{ $extraPadding: boolean; $theme: TTheme }>`
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;
  ${({ $extraPadding }) =>
    $extraPadding &&
    css`
      padding: 10px;
    `}
  ${styledScrollBar}
`;

export const FullWidthTooltip = styled(Tooltip)`
  width: 100%;
`;
