import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { withSize } from "react-sizeme";
import { useDispatch, useSelector } from "react-redux";
import { H6, Intent, Tag } from "@blueprintjs/core";
import { fetchWidgetData } from "actions/modelActions";
import AppContext from "context/appContext";
import LoadingIndicator from "components/utility/LoadingIndicator";
import { Flex } from "components/utility/StyledComponents";
import { currentCaseSelector, widgetDataSelector } from "selectors/modelSelectors";
import { IResponse } from "types";
import { IVSWidget } from "types/dashboard";
import { WidgetKeyToComponentMap } from "constants/widgets";

interface IWidgetData {
  loading: boolean;
  data: IResponse<unknown>;
  error?: string;
}

const StyledWidget = styled.div`
  max-height: 100%;
  & > div {
    max-height: 100%;
  }
`;

/**
 * A self-contained unit of content to be rendered in a Valsys Dashboard
 * */
const Widget = React.memo(
  forwardRef<HTMLDivElement, IVSWidget>(
    (
      {
        baseWidget,
        children,
        displayName,
        fetchesOwnData,
        fullHeight: _widgetFullHeight,
        gridKey,
        widgetContainerStyles,
      },
      ref
    ) => {
      const [_prevHeight, _setPrevHeight] = useState<number | null>(null);
      const dispatch = useDispatch();
      const {
        config: { theme },
      } = useContext(AppContext);
      const selector = useCallback(
        (state) => {
          if (!baseWidget?.widgetKey) return null;
          // TODO: [VFE-868] - Correctly typecast the selectors to let ts know that they can accept a second arg.
          // eslint-disable-next-line
          // @ts-ignore
          return widgetDataSelector(state, { widgetKey: baseWidget.widgetKey, theme });
        },
        [baseWidget, theme]
      );
      const data: IWidgetData | undefined = useSelector(selector);
      const currentCase = useSelector(currentCaseSelector);

      useEffect(() => {
        if (currentCase && !fetchesOwnData) {
          dispatch(fetchWidgetData(currentCase.uid, baseWidget.widgetKey));
        }
      }, [baseWidget, currentCase, dispatch, fetchesOwnData]);

      const Component = useMemo(() => {
        if (children) return null;
        if ((data && !data.loading) || fetchesOwnData) {
          if (!baseWidget?.widgetKey) return null;
          const Comp = WidgetKeyToComponentMap[baseWidget.widgetKey];
          const element = () => <Comp data={data} gridKey={gridKey} />;
          if (fetchesOwnData) return element;
          return withSize({ monitorHeight: true, noPlaceholder: true, refreshRate: 64 })(element);
        }
        return null;
      }, [baseWidget, children, data, fetchesOwnData, gridKey]);

      const content = useMemo(() => {
        if (children) return children;
        if (data?.error) return <Tag intent={Intent.DANGER}>{data.error}</Tag>;
        if (Component) return <Component />;
      }, [children, Component, data]);

      if (data?.loading) {
        return (
          <LoadingIndicator
            loading={true}
            status={{ intent: Intent.PRIMARY, message: "Loading widget data..." }}
          />
        );
      }

      return (
        <StyledWidget ref={ref} style={{ ...widgetContainerStyles }}>
          <Flex
            alignItems="flex-start"
            fullWidth
            fullHeight
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Flex justifyContent="space-between" fullWidth>
              {displayName ? <H6>{displayName}</H6> : null}
            </Flex>
            {content}
          </Flex>
        </StyledWidget>
      );
    }
  )
);

Widget.displayName = "Widget";

export default Widget;
