import { useEffect, useRef } from "react";

/** when the value changes, call callback */
export default function useOnChange<T>(value: T, callback: (prev: T, current: T) => void) {
  const latestValue = useRef(value);
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    if (latestValue.current !== value) {
      callbackRef.current(latestValue.current, value);
    }
    latestValue.current = value;
  }, [value]);
}

/** when the value changes from "from" to "to", call callback */
export function useOnChangeFromTo<T>(value: T, from: T, to: T, callback: () => void) {
  const latestValue = useRef(value);
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    if (latestValue.current === from && value === to) {
      callbackRef.current();
    }
    latestValue.current = value;
  }, [value, from, to]);
}

/** when the value changes from any other value to "to", call callback */
export function useOnChangeTo<T>(value: T, to: T, callback: (prev: T) => void) {
  const latestValue = useRef(value);
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    if (latestValue.current !== to && value === to) {
      callbackRef.current(latestValue.current);
    }
    latestValue.current = value;
  }, [value, to]);
}

/** when the value changes from one that doesn't satisfy the predicate (match) to one that does, call callback */
export function useOnChangeToMatch<T>(
  value: T,
  match: (current: T) => boolean,
  callback: (prev: T) => void
) {
  const latestValue = useRef(value);
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    if (!match(latestValue.current) && match(value)) {
      callbackRef.current(latestValue.current);
    }
    latestValue.current = value;
  }, [value, match]);
}
