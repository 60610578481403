import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Classes, Tab, Tabs } from "@blueprintjs/core";
import { IAppConfig } from "types";
import useUser from "hooks/useUser";
import ModelHistoryTable from "./ModelHistoryTable";
import { IModelHistoryTable, ITableConfigColumn } from "types/modelHistory";
import { parseStoredModelTables } from "utils/modelHistoryUtils";
import useSetAndGetConfig from "hooks/useSetAndGetConfig";
import { useSearchParams } from "react-router-dom";
import useSetPageTitle from "hooks/useSetPageTitle";
import Page from "components/utility/Page";

const StyledTabContainer = styled.div`
  height: 100%;
  padding: 10px;
  width: 100%;
  & > div.${Classes.TABS} {
    height: 100%;
    display: flex;
    flex-direction: column;
    & > div.${Classes.TAB_PANEL} {
      flex: 1 1 0;
      height: 100%;
      margin-top: 10px;
    }
  }
  div.${Classes.TAB_LIST} {
    overflow-x: auto;
  }
`;

const EquityScreen: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("table") || "";

  const { user } = useUser();
  const { config, update } = useSetAndGetConfig<IAppConfig>(
    "appConfig",
    user?.uid || null,
    "userID"
  );

  useSetPageTitle("Equity Screen");

  const setActiveTab = (tab: string) => {
    setSearchParams({ table: tab });
  };

  useEffect(() => {
    if (
      config?.home &&
      Array.isArray(config.home) &&
      (!activeTab || (activeTab && !config.home.find((table) => table.uid === activeTab)))
    ) {
      setSearchParams({ table: config.home[0]?.uid }, { replace: true });
    }
  }, [activeTab, config, setSearchParams]);

  const home = config?.home || null;

  const editTableProperties = (edit: Partial<IModelHistoryTable>) => {
    if (Array.isArray(home)) {
      const nextTables = [...home];
      const index = nextTables.findIndex((table) => table.uid === activeTab);

      nextTables.splice(index, 1, {
        ...nextTables[index],
        ...edit,
        ...(edit.name && {
          uid: `${(edit.name as string).toLowerCase().replaceAll(" ", "-")}`,
        }),
      });
      update({ home: nextTables });
    }
  };

  const editColumns = (nextColumns: ITableConfigColumn[]) => {
    if (config && Array.isArray(home)) {
      const nextModelTables = [...home];
      const index = nextModelTables.findIndex((table) => table.uid === activeTab);
      nextModelTables.splice(index, 1, {
        ...nextModelTables[index],
        columns: nextColumns,
      });
      const nextConfig: IAppConfig = { ...config, home: nextModelTables };
      update(nextConfig);
    }
  };

  return (
    <Page title="Equity Screen">
      {Array.isArray(home) ? (
        <StyledTabContainer>
          <Tabs selectedTabId={activeTab} onChange={setActiveTab}>
            {parseStoredModelTables(home).map((table) => (
              <Tab
                id={table.uid}
                key={table.uid}
                panel={
                  <ModelHistoryTable
                    editColumns={editColumns}
                    editKey="home"
                    editTableProperties={editTableProperties}
                    modelControlsActive={false}
                    modelTable={table}
                  />
                }
                title={table.name}
              />
            ))}
          </Tabs>
        </StyledTabContainer>
      ) : null}
    </Page>
  );
};

export default EquityScreen;
