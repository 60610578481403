import { MutableRefObject, useEffect, useRef } from "react";

/**
 * The usePrevious hook accepts a single value, usually a prop,
 * memorizes the previous version.
 *
 * @param value - The value to be saved
 *
 * @return ref.current - The value at the time.
 */
const usePrevious = <T>(value: T): MutableRefObject<T | undefined>["current"] => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;
