import { useMemo } from "react";
import { Selector, SelectorOptionsResponse } from "types/vsl";
import useSWR from "swr";
import { IResponse } from "types";
import { EMethods, SELECTORS_ROUTE } from "constants/apiConstants";
import { swrApi } from "utils/api";
import { selectorToSelectorRequest } from "utils/vsl";

const useVSLSelector: (
  selectors?: Selector[],
  metaInfo?: Record<string, string | undefined>
) => {
  error?: string;
  loading: boolean;
  selectorsOptions?: Record<string, SelectorOptionsResponse>;
} = (selectors, metaInfo) => {
  const selectorsReqBody = useMemo(() => {
    if (!selectors) return metaInfo ?? {};
    return { selectors: selectors.map((sel) => selectorToSelectorRequest(sel)), ...metaInfo };
  }, [metaInfo, selectors]);

  const { data: selectorsOptionsResponse, isLoading: loadingSelectorsOptions } = useSWR<
    IResponse<Record<string, SelectorOptionsResponse>>
  >(
    selectors ? [SELECTORS_ROUTE, { body: selectorsReqBody, method: EMethods.POST }] : null,
    swrApi,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    error: selectorsOptionsResponse?.error,
    loading: loadingSelectorsOptions,
    selectorsOptions: selectorsOptionsResponse?.data,
  };
};

export default useVSLSelector;
