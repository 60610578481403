import { GET_USER_CONFIGS_ROUTE } from "constants/apiConstants";
import useSWR from "swr";
import { swrApi } from "utils/api";
import useUser from "./useUser";
import { IAppConfig, IResponse } from "types";
import { View } from "types/view";

interface UserConfig {
  appConfig: IAppConfig;
  home: {
    contextId: string;
    createdAt: string;
    createdBy: string;
    description: string;
    id: string;
    name: string;
    views: View[] | null;
    visibility: string;
  };
  id: string;
  kpiTrends: {
    contextId: string;
    createdAt: string;
    createdBy: string;
    description: string;
    id: string;
    name: string;
    views: View[] | null;
    visibility: string;
  };
}

interface HookReturn extends Partial<UserConfig> {
  error: Error;
  isLoading: boolean;
}

type TUserUserConfig = () => HookReturn;

const useUserConfig: TUserUserConfig = () => {
  const { user } = useUser();
  const {
    data: userConfig,
    error,
    isLoading,
  } = useSWR<IResponse<UserConfig>>(
    user ? [GET_USER_CONFIGS_ROUTE, { userId: user?.uid }] : null,
    swrApi
  );
  return {
    ...userConfig?.data,
    error,
    isLoading,
  };
};

export default useUserConfig;
