import * as Sentry from "@sentry/react";
import { cancel, spawn, takeEvery, takeLatest } from "redux-saga/effects";

import * as modelConsts from "constants/modelConstants";
import * as dataEntryConsts from "constants/dataEntryConstants";
import * as dataEntryUIConsts from "constants/dataEntryUIConstants";
import {
  UPDATE_EDIT_MODE,
  UPDATE_FOCUSED_CELL,
  UPDATE_SELECTED_REGIONS,
} from "constants/dataEntryUIConstants";
import * as createConsts from "constants/createModelConstants";
import * as priceChartConsts from "constants/sharePriceConstants";
import {
  editModelProperties,
  exportToExcel,
  fetchData,
  fetchWidgetData,
  pullModel,
  updateSensitivityIncrement,
} from "sagas/modelSagas";
import {
  editLineItemTags,
  editModuleName,
  fetchKeyRatioData,
  fetchQuickCalculations,
  fetchUndoHistory,
  pullSource,
  updateCurrentModule,
} from "sagas/dataEntrySagas";
import { fetchHistoricalSharePriceData } from "sagas/sharePriceSagas";
import {
  updateEditMode,
  updateFocusedCell,
  updateFormula,
  updateSelectedRegions,
} from "./dataEntryUISagas";
import connect, { resyncWithServer } from "sagas/socketSagas";
import {
  createModel,
  fetchAllConfigOptions,
  fetchAvailableTickers,
  fetchCompanyConfigOptions,
  fetchModelTemplates,
} from "sagas/createModelSagas";

export default function* rootSaga() {
  // CREATE MODEL
  yield takeLatest(createConsts.CREATE_MODEL, createModel);
  yield takeEvery(createConsts.FETCH_AVAILABLE_TICKERS, fetchAvailableTickers);
  yield takeEvery(createConsts.FETCH_COMPANY_CONFIG_OPTIONS, fetchCompanyConfigOptions);
  yield takeEvery(createConsts.FETCH_ALL_CONFIG_OPTIONS, fetchAllConfigOptions);
  yield takeEvery(createConsts.FETCH_MODEL_TEMPLATES, fetchModelTemplates);

  // EDIT MODEL
  yield takeEvery(modelConsts.EDIT_MODEL_PROPERTIES, editModelProperties);

  // PULL MODEL
  yield takeEvery(modelConsts.PULL_MODEL, pullModel);

  // yield takeEvery(confConsts.FETCH_CONFIG_ITEM_OPTIONS, fetchConfigOptions);
  yield takeEvery(modelConsts.FETCH_WIDGET_DATA, fetchWidgetData);

  yield takeLatest(modelConsts.EXPORT_TO_EXCEL, exportToExcel);
  yield takeLatest(modelConsts.UPDATE_SENSITIVITY_INCREMENT, updateSensitivityIncrement);

  yield takeEvery(modelConsts.FETCH_DATA, fetchData);
  yield takeEvery(modelConsts.RESYNC_MODEL, resyncWithServer);

  /**
   * Spawn a listener to allow for retries by redispatching of OPEN_SOCKET
   * */
  yield spawn(function* openSocketListener() {
    while (true) {
      try {
        let connectTask;
        if (connectTask) {
          yield cancel(connectTask);
        }
        connectTask = yield takeEvery(dataEntryConsts.OPEN_SOCKET, connect);
        break;
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  });

  // Data Entry
  yield takeEvery(dataEntryConsts.UNDO_HISTORY, fetchUndoHistory);
  yield takeEvery(dataEntryConsts.FETCH_KEY_RATIO_DATA, fetchKeyRatioData);
  yield takeEvery(dataEntryConsts.UPDATE_CURRENT_MODULE, updateCurrentModule);
  yield takeLatest(dataEntryConsts.EDIT_MODULE_NAME, editModuleName);
  yield takeEvery(dataEntryConsts.FETCH_QUICK_CALCULATIONS, fetchQuickCalculations);
  yield takeEvery(dataEntryConsts.PULL_SOURCE, pullSource);
  yield takeEvery(dataEntryUIConsts.UPDATE_FORMULA, updateFormula);
  yield takeEvery(dataEntryConsts.EDIT_LINE_ITEM_TAGS, editLineItemTags);
  // yield takeEvery(dataEntryConsts.DELETE_MODULE, deleteModule);

  // Price Chart
  yield takeEvery(
    priceChartConsts.FETCH_HISTORICAL_SHARE_PRICE_DATA,
    fetchHistoricalSharePriceData
  );

  // User
  yield takeEvery(UPDATE_EDIT_MODE, updateEditMode);
  yield takeEvery(UPDATE_FOCUSED_CELL, updateFocusedCell);
  yield takeEvery(UPDATE_SELECTED_REGIONS, updateSelectedRegions);
}
