import {
  Button,
  ButtonGroup,
  Card,
  CardList,
  Classes,
  Colors,
  H4,
  H5,
  H6,
  InputGroup,
  Intent,
  NonIdealState,
  Tag,
} from "@blueprintjs/core";
import { ChevronRight, IconNames } from "@blueprintjs/icons";
import LoadingIndicator from "components/utility/LoadingIndicator";
import { Flex, StyledManageCollection, styledScrollBar } from "components/utility/StyledComponents";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import { TTheme } from "types";

import { MainContainer } from "components/utility/StyledDashboardComponents";
import AppContext from "context/appContext";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Widget, { WidgetRef } from "components/dashboard/widget/Widget";
import EditWatchlistDialog from "./EditWatchlistDialog";

import useWidget from "hooks/dashboard/useWidget";
import useCollections from "hooks/useCollections";
import useSetPageTitle from "hooks/useSetPageTitle";
import { Watchlist } from "types/collections";
import useWatchlists from "hooks/useWatchlists";
import CreateWatchlistDialog from "./CreateWatchlistDialog";
import UpdateWatchlistModelsDialog from "./UpdateCollectionModelsDialog";
import useWatchlist from "hooks/useWatchlist";

const WatchlistsManager = () => {
  const { watchlistId } = useParams();
  const navigate = useNavigate();
  const { fetchingWatchlist, fetchWatchlistError, watchlistConfig } = useCollections();
  const widgetRef = useRef<WidgetRef>(null);

  const {
    deleteWatchlist,
    error: fetchWatchlistsError,
    loadingWatchlists,
    watchlists,
  } = useWatchlists();

  const {
    editWatchlist,
    editingWatchlist,
    loadingWatchlist,
    matchingModels,
    watchlist: selectedWatchlist,
  } = useWatchlist(watchlistId);

  const [predicate, setPredicate] = useState("");
  const [createNewWatchlistDialogOpen, setCreateNewWatchlistDialogOpen] = useState(false);
  const [editWatchlistDialogOpen, setEditWatchlistDialogOpen] = useState(false);
  const [updateModelsDialogOpen, setUpdateModelsDialogOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  useSetPageTitle("Watchlists");

  useEffect(() => {
    const openDialog = searchParams.get("openCreateDialog");
    if (openDialog === "1") {
      setCreateNewWatchlistDialogOpen(true);
      setSearchParams({}, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  /**
   * TODO: Ticket: FEAT-484
   * Eventually, this will be moved to the Watchlists service. The full widget
   * will be available on the initial swr call above.
   *
   * Currently, we are getting the table (safely) from the watchlist config.
   */
  const {
    loading: loadingWidget,
    mutate,
    update,
    widget,
  } = useWidget(selectedWatchlist?.previewWidgetId);

  const dashboardId = watchlistConfig?.dashboard?.id;

  const {
    config: { theme },
  } = useContext(AppContext);

  if (fetchWatchlistsError || fetchWatchlistError) {
    return <span>ERROR</span>;
  }

  const filteredAndSortedWatchlists = watchlists
    ? watchlists
        .filter((watchlist) => watchlist.name.toLowerCase().includes(predicate.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name))
    : undefined;

  const toggleCreateNewWatchlistDialog = (): void => {
    setCreateNewWatchlistDialogOpen(!createNewWatchlistDialogOpen);
  };

  const closeCreateNewWatchlistDialog = (): void => {
    setCreateNewWatchlistDialogOpen(false);
  };

  const toggleEditWatchlistDialog = (): void => {
    setEditWatchlistDialogOpen(!editWatchlistDialogOpen);
  };

  const closeEditWatchlistDialog = (): void => {
    setEditWatchlistDialogOpen(false);
  };

  const deleteSelectedWatchlist = async (watchlist: Watchlist) => {
    deleteWatchlist(watchlist);
  };

  return (
    <MainContainer rows="auto 1fr">
      <EditWatchlistDialog
        editingWatchlist={editingWatchlist}
        editWatchlist={editWatchlist}
        isOpen={editWatchlistDialogOpen}
        matchingModels={matchingModels}
        mutate={mutate}
        onClose={closeEditWatchlistDialog}
        watchlist={selectedWatchlist}
        widgetRef={widgetRef}
      />
      <Flex
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
        style={{ marginBottom: 15 }}
      >
        <Flex>
          <StyledH4>Manage Watchlists</StyledH4>
          <Tag
            intent={Intent.PRIMARY}
            minimal
            round
            style={{ border: `1px solid ${Colors.BLUE3}` }}
          >
            Visible to everyone
          </Tag>
        </Flex>
        <Button
          icon={IconNames.ADD}
          intent={Intent.SUCCESS}
          onClick={() => toggleCreateNewWatchlistDialog()}
          text="New Watchlist"
        />
        <CreateWatchlistDialog
          isOpen={createNewWatchlistDialogOpen}
          onClose={closeCreateNewWatchlistDialog}
        />
        <UpdateWatchlistModelsDialog
          collection={selectedWatchlist}
          editCollection={editWatchlist}
          isOpen={updateModelsDialogOpen}
          loadingCollection={loadingWatchlist}
          matchingModels={matchingModels}
          onClose={() => {
            setUpdateModelsDialogOpen(false);
            widgetRef?.current?.rerunVSLQuery();
          }}
        />
      </Flex>
      <Flex
        flexDirection="row"
        alignItems="flex-start"
        flex="1"
        gap={10}
        style={{ overflow: "hidden", padding: 1 }}
      >
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          fullHeight
          gap={5}
          justifyContent="flex-start"
        >
          <InputGroup
            leftIcon={IconNames.SEARCH}
            onChange={(e) => setPredicate(e.target.value)}
            placeholder="Search watchlists..."
            style={{ width: 300 }}
            type="search"
            value={predicate}
          />
          {loadingWatchlists ? (
            <LoadingIndicator
              status={{ intent: Intent.PRIMARY, message: "Loading Watchlists..." }}
            />
          ) : filteredAndSortedWatchlists && filteredAndSortedWatchlists.length > 0 ? (
            <CardList compact>
              {filteredAndSortedWatchlists.map((watchlist) => (
                <Card
                  className={selectedWatchlist?.id === watchlist.id ? "selected" : ""}
                  interactive
                  onClick={() => navigate(`../${watchlist.id}`)}
                  key={watchlist.id}
                  selected={selectedWatchlist?.id === watchlist.id}
                >
                  <Flex flexDirection="row" fullWidth justifyContent="space-between">
                    <H6 style={{ margin: 0 }}>{watchlist.name}</H6>
                    <Tag minimal>{watchlist.companies?.length || 0} Tickers</Tag>
                  </Flex>
                  <ChevronRight className={Classes.TEXT_MUTED} />
                </Card>
              ))}
            </CardList>
          ) : (
            <NonIdealState title="No Watchlists available." />
          )}
        </Flex>
        <Flex
          alignItems="flex-start"
          flex="4 1 1px"
          flexDirection="column"
          fullHeight
          fullWidth
          justifyContent="flex-start"
          style={{ overflow: "hidden" }}
        >
          {watchlistId && selectedWatchlist ? (
            <StyledManageCollection
              className={`${Classes.CARD} ${Classes.ELEVATION_0}`}
              rows="auto 1fr auto"
              $theme={theme}
            >
              <Flex justifyContent="space-between" fullWidth style={{ padding: "10px" }}>
                <Flex gap={10} justifyContent="flex-start">
                  <H5 style={{ margin: 0 }}>{selectedWatchlist?.name}</H5>
                  <Link to={dashboardId || ""}>
                    <Button
                      icon={IconNames.DASHBOARD}
                      intent={Intent.PRIMARY}
                      text="View Dashboard"
                    />
                  </Link>
                </Flex>
                <ButtonGroup>
                  <Button
                    icon={IconNames.TH}
                    text="Update Watchlist Models"
                    onClick={() => setUpdateModelsDialogOpen(true)}
                  />
                  <Button
                    icon={IconNames.COG}
                    text="Edit Tickers"
                    onClick={() => toggleEditWatchlistDialog()}
                  />
                  <Button
                    icon={IconNames.DELETE}
                    intent={Intent.DANGER}
                    text="Delete"
                    onClick={() => {
                      if (confirm("Permanently delete Watchlist?")) {
                        deleteSelectedWatchlist(selectedWatchlist);
                      }
                    }}
                  />
                </ButtonGroup>
              </Flex>
              <StyledScrollContainer $theme={theme}>
                {!loadingWidget && widget ? (
                  <Widget
                    bordered={false}
                    ref={widgetRef}
                    showTitleRibbon={false}
                    updateWidget={update}
                    widget={{
                      ...widget,
                      createdAt: String(new Date(widget.createdAt)),
                      selectorsHidden: true,
                    }}
                  />
                ) : null}
              </StyledScrollContainer>
            </StyledManageCollection>
          ) : !fetchingWatchlist ? (
            <NonIdealState
              description="Select a Watchlist from the list on the left to manage it."
              icon={IconNames.SEARCH}
              title="No Watchlist selected."
            />
          ) : (
            <LoadingIndicator
              status={{ message: "Loading Watchlist...", intent: Intent.PRIMARY }}
            />
          )}
        </Flex>
      </Flex>
    </MainContainer>
  );
};

const StyledH4 = styled(H4)`
  &&& {
    margin: 0;
    margin-right: 10px;
  }
`;

interface IStyledScrollContainerProps {
  $theme: TTheme;
}

const StyledScrollContainer = styled.div<IStyledScrollContainerProps>`
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  flex: 1 1 1px;
  width: 100%;
  ${styledScrollBar}
`;

export default WatchlistsManager;
