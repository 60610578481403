import React, { useEffect, useMemo, useState } from "react";
import { InputGroup, MenuItem, OptionProps } from "@blueprintjs/core";
import { TableWidgetConfig } from "types/widget";
import { IVSLTable } from "types/vsl";
import { IconNames } from "@blueprintjs/icons";
import {
  Flex,
  StyledHTMLSelectSmall,
  StyledMultiSelectSmall,
} from "components/utility/StyledComponents";
import { useTableFilter } from "hooks/useTableFilters";
import { ViewWidget } from "types/view";
import { getColumnValues, getTableSearchColumnKeys } from "utils/tableFilterUtils";

interface TableSearchProps {
  config?: TableWidgetConfig;
  data?: IVSLTable;
  updateWidget?: (details: Partial<ViewWidget>) => Promise<boolean>;
  disableGlobalFilter?: boolean;
}

const ALL_COLUMN_VALUE = "ALL_COLUMNS";

export const TableFilters: React.FunctionComponent<TableSearchProps> = ({
  config,
  data,
  updateWidget,
  disableGlobalFilter = false,
}) => {
  const {
    tableFilters,
    removeTableFilter,
    toggleTableFilter,
    clearTableFilters,
    setGlobalTableFilter,
  } = useTableFilter(config, updateWidget);
  const filteredColumn = config?.filters?.allColumns
    ? ALL_COLUMN_VALUE
    : Object.keys(config?.filters?.columnFilters ?? {})[0] ?? "";

  const [selectedColumn, setSelectedColumn] = useState(filteredColumn);
  const [tableSearchQuery, setTableSearchQuery] = useState("");
  const [globalFilter, setGlobalFilter] = useState(tableFilters.allColumns ?? "");

  const [columnValues, setColumnValues] = useState<string[]>([]);

  const columnOptions: OptionProps<string>[] = getTableSearchColumnKeys(data).map((columnKey) => ({
    value: columnKey,
    label: columnKey,
  }));

  const filterOptions: OptionProps<string>[] = useMemo(() => {
    return !disableGlobalFilter
      ? [{ value: ALL_COLUMN_VALUE, label: "All" }, ...columnOptions]
      : columnOptions;
  }, [disableGlobalFilter, columnOptions]);

  useEffect(() => {
    if (!selectedColumn && filterOptions.length > 0) {
      setSelectedColumn(filterOptions[0].value);
    }
  }, [selectedColumn, filterOptions]);

  useEffect(() => {
    if (selectedColumn) {
      setColumnValues(getColumnValues(selectedColumn, data));
    }
  }, [selectedColumn, data]);

  return (
    <Flex>
      <StyledHTMLSelectSmall
        options={filterOptions}
        onChange={(e) => {
          setSelectedColumn(e.target.value);
          clearTableFilters();
          setGlobalFilter("");
        }}
        value={selectedColumn}
      />
      {selectedColumn === ALL_COLUMN_VALUE ? (
        <InputGroup
          value={globalFilter}
          onChange={(e) => {
            const value = e.target.value;
            setGlobalTableFilter(value);
            setGlobalFilter(value);
          }}
          leftIcon="search"
          small
        />
      ) : (
        <StyledMultiSelectSmall<string>
          itemRenderer={(item, { modifiers, handleClick }) => (
            <MenuItem
              active={modifiers.active}
              icon={
                tableFilters?.columnFilters?.[selectedColumn]?.some((val) => val === item)
                  ? IconNames.TICK
                  : undefined
              }
              key={item}
              onClick={handleClick}
              text={item}
            />
          )}
          items={columnValues}
          onQueryChange={setTableSearchQuery}
          onItemSelect={(item) => {
            toggleTableFilter(selectedColumn, item);
            setTableSearchQuery("");
          }}
          onRemove={(_, i) => {
            removeTableFilter(selectedColumn, tableFilters.columnFilters?.[selectedColumn]?.[i]);
          }}
          query={tableSearchQuery}
          placeholder=""
          selectedItems={tableFilters.columnFilters?.[selectedColumn] ?? []}
          tagRenderer={(item) => item}
          tagInputProps={{ tagProps: { minimal: true }, leftIcon: IconNames.Filter }}
          itemPredicate={(query, item, _idx, _exactMatch) => {
            return item.toLocaleLowerCase().includes(query.toLocaleLowerCase());
          }}
        />
      )}
    </Flex>
  );
};
