import { Selector, SelectorOption, SelectorOptionsResponse } from "types/vsl";

/** Selectors that have invalid options selected get those removed.
 *  Only exception is if that selector option has been externally injected.
 */
export const sanitiseWidgetSelectorsOptions = (
  selectors: Record<string, SelectorOption[]>,
  selectorsOptions: Record<string, SelectorOptionsResponse>,
  injectedSelectors?: Record<string, SelectorOption[]>
) => {
  let changes = false;
  const newActiveSelectors: Record<string, SelectorOption[]> = { ...selectors };

  Object.entries(selectors).forEach(([selectorId, selectedOptions]) => {
    const options = selectorsOptions[selectorId]?.available_options ?? [];
    const optionsStrings = options.map((option) => option.value);

    if (!selectedOptions) return;
    if (optionsStrings.length === 1 && selectedOptions[0]?.value !== optionsStrings[0]) {
      // auto-select if there's only one option available
      newActiveSelectors[selectorId] = [options[0]];
      changes = true;
    } else
      for (let i = selectedOptions.length - 1; i >= 0; i--) {
        if (!optionsStrings.includes(selectedOptions[i].value)) {
          const injectedSelector = injectedSelectors?.[selectorId];
          // clear only if it hasn't been externally injected
          if (!injectedSelector) {
            newActiveSelectors[selectorId] = [];
            changes = true;
          } else {
            newActiveSelectors[selectorId] = injectedSelector;
          }
        }
      }
  });
  if (!changes) return null;
  return newActiveSelectors;
};

/** View and Widget selectors that have invalid options selected get those removed.
 *  We return all the selectors because the View mutates the whole selectors object.
 *  We return only the changed selectors to update View Widgets surgically.
 */
export const sanitiseViewSelectorsOptions = (
  selectors: Selector[],
  selectorsOptions: Record<string, SelectorOptionsResponse>
) => {
  const nextWidgetSelectors: Selector[] = [];
  const nextViewSelectors: Selector[] = [];

  selectors.forEach((selector, idx) => {
    const options = selectorsOptions[selector.id].available_options ?? [];
    const optionsStrings = options.map((option) => option.value);

    nextViewSelectors[idx] = selector;

    if (
      optionsStrings.length === 1 &&
      selector.selected_options?.[0]?.value !== optionsStrings[0]
    ) {
      // if only one available option we auto select it
      const newSelector = { ...selector, selected_options: [options[0]] };
      nextWidgetSelectors.push(newSelector);
      nextViewSelectors[idx] = newSelector;
    } else if (!selector?.selected_options) {
      // nothing to do if no options were selected
      return;
    } else {
      // we iterate through the options and validate each. we flag if something changed to trigger an update
      let optionsChanged = false;
      const newSelectedOptions: SelectorOption[] = [];
      // push all options that are still valid
      for (let i = selector.selected_options.length - 1; i >= 0; i--) {
        if (optionsStrings.includes(selector.selected_options[i].value)) {
          newSelectedOptions.unshift(selector.selected_options[i]);
        } else optionsChanged = true;
      }
      if (optionsChanged) {
        const newSelector = { ...selector, selected_options: newSelectedOptions };
        nextWidgetSelectors.push(newSelector);
        nextViewSelectors[idx] = newSelector;
      }
    }
  });

  return [nextViewSelectors, nextWidgetSelectors];
};
