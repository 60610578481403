import React, { useState } from "react";
import { Auth } from "aws-amplify";
import propTypes from "prop-types";
import { Button, Elevation, H5, Intent } from "@blueprintjs/core";
import { Flex } from "components/utility/StyledComponents";
import NewPasswordForm from "components/user/NewPasswordForm";
import { multiToaster } from "utils/toaster";
import { AuthContainer, AuthForm } from "components/user/StyledAuthComponents";

const CompleteNewPassword = ({ setAuthState, user }) => {
  //Passwords
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [passwordConfirmationHelperText, setPasswordConfirmationHelperText] = useState("");

  function completeNewPassword() {
    if (!passwordsMatch) {
      setPasswordHelperText("Passwords do not match.");
      return;
    }
    Auth.completeNewPassword(user, password)
      .then(() => {
        setAuthState("setupTOTP");
        multiToaster.show({
          message: "Password successfully updated!",
          intent: Intent.SUCCESS,
        });
      })
      .catch((err) => {
        const error = err.message ? err.message : err;
        multiToaster.show({
          message: error,
          intent: Intent.WARNING,
        });
        setPasswordConfirmation("");
        setPasswordHelperText(error?.text);
      });
  }
  return (
    <AuthContainer elevation={Elevation.TWO}>
      <H5>Create new password</H5>
      <AuthForm
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <NewPasswordForm
          password={password}
          passwordConfirmation={passwordConfirmation}
          setPassword={setPassword}
          setPasswordConfirmation={setPasswordConfirmation}
          passwordsMatch={passwordsMatch}
          setPasswordsMatch={setPasswordsMatch}
          passwordValid={passwordValid}
          setPasswordValid={setPasswordValid}
          setPasswordHelperText={setPasswordHelperText}
          passwordHelperText={passwordHelperText}
          passwordConfirmationHelperText={passwordConfirmationHelperText}
          setPasswordConfirmationHelperText={setPasswordConfirmationHelperText}
        />
        <Flex flexDirection="column">
          <Button
            icon="confirm"
            intent={Intent.PRIMARY}
            style={{ alignSelf: "flex-end" }}
            text="Confirm"
            type="submit"
            onClick={completeNewPassword}
          />
        </Flex>
      </AuthForm>
    </AuthContainer>
  );
};

CompleteNewPassword.propTypes = {
  user: propTypes.shape({}),
  authState: propTypes.string,
  onStateChange: propTypes.func,
};

CompleteNewPassword.defaultProps = {
  user: {},
  authState: "signIn",
  onStateChange: () => {},
};

export default CompleteNewPassword;
