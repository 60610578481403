import React, { Dispatch, SetStateAction, useCallback, useContext } from "react";
import {
  AnchorButton,
  Button,
  Callout,
  Classes,
  HTMLTable,
  Intent,
  Tooltip,
} from "@blueprintjs/core";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import { Flex, FullHeightDialogBody, FullWidthDialog } from "components/utility/StyledComponents";
import { validatorWarning } from "utils/data";
import { schemaLevel } from "utils/normalizr";
import { useNavigate, useParams } from "react-router-dom";
import { vsNavigate } from "utils/generic";
import { multiToaster } from "utils/toaster";

interface IModelWarningsDialogProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  warnings: validatorWarning[];
}

const ModelWarningsDialog: React.FC<IModelWarningsDialogProps> = ({
  isOpen,
  setOpen,
  warnings,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const {
    config: { theme },
  } = useContext(AppContext);

  const entityNav = useCallback(
    (warn: validatorWarning) => {
      setOpen(false);
      const fullPath = "/model/" + params.model + "/data/";
      let modulePath;
      switch (warn.type) {
        case schemaLevel.MODULE:
          modulePath = vsNavigate(warn.moduleName);
          break;
        case schemaLevel.LINE_ITEM:
          modulePath = vsNavigate(warn.moduleName, { row: warn.rowIdx, col: 0 });
          break;
        case schemaLevel.FACT:
          modulePath = vsNavigate(warn.moduleName, { row: warn.rowIdx, col: warn.colIdx });
          break;
      }
      navigate(fullPath + modulePath);
    },
    [navigate, params.model, setOpen]
  );

  const copyEntityId = (id: string | undefined) => {
    if (!id) return;
    navigator.clipboard.writeText(id).then(
      () => {
        multiToaster.show({
          message: "Entity ID copied to clipboard",
          intent: Intent.SUCCESS,
        });
      },
      () => {
        multiToaster.show({
          message: "There was an error when copying to clipboard",
          intent: Intent.WARNING,
        });
      }
    );
  };

  return (
    <FullWidthDialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      icon="bug"
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      shouldReturnFocusOnClose={false}
      title="Model data warnings"
    >
      <FullHeightDialogBody>
        <Callout
          intent={Intent.WARNING}
          title={`Debug mode data validation has ${warnings.length} warnings `}
        ></Callout>
        <br />
        <HTMLTable compact striped style={{ width: "100%", overflowY: "scroll" }}>
          <tbody>
            {warnings.map((warn, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <Flex flexDirection="row" justifyContent="space-between">
                      <Flex flexDirection="column" alignItems="flex-start">
                        <p>
                          <b>
                            {" "}
                            {warn.type}: {warn.identifier}
                          </b>
                        </p>
                        <p>message: {warn.msg}</p>
                      </Flex>
                      <Flex justifyContent="space-between">
                        <Tooltip content="Navigate to entity" openOnTargetFocus={false}>
                          <AnchorButton
                            icon="locate"
                            minimal
                            onClick={() => entityNav(warn)}
                          ></AnchorButton>
                        </Tooltip>
                        {warn.id ? (
                          <Tooltip content="Copy entity id" openOnTargetFocus={false}>
                            <AnchorButton
                              icon="duplicate"
                              minimal
                              onClick={() => copyEntityId(warn.id)}
                            ></AnchorButton>
                          </Tooltip>
                        ) : null}
                      </Flex>
                    </Flex>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </HTMLTable>
      </FullHeightDialogBody>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => setOpen(false)} text="Close" />
        </div>
      </div>
    </FullWidthDialog>
  );
};

export default ModelWarningsDialog;
