import React, { useRef } from "react";
import { FileUpload } from "components/utility/FileUpload";
import { TCompanyInfo } from "types/createModel";
import { groupTickersFromFile, showToastsFromTickers } from "utils/csvTickerUtils";

interface ICsvUploadProps {
  uploadTickersFromCSV: (tickers: string[]) => void;
  companies: TCompanyInfo[];
}

export const CsvUpload: React.FC<ICsvUploadProps> = ({ uploadTickersFromCSV, companies }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const setUploadedTickers = async (file: File) => {
    const tickersToMatch = companies.map((company) => company.ticker);
    const { matchingTickers, unMatchedTickers } = await groupTickersFromFile(file, tickersToMatch);

    showToastsFromTickers(matchingTickers, unMatchedTickers);

    if (matchingTickers.length > 0) {
      uploadTickersFromCSV(matchingTickers);
    }
  };

  const filesAdded = (files: File[]) => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    if (files.length > 0) {
      setUploadedTickers(files[0]);
    }
  };

  return (
    <FileUpload
      title="Upload your CSV file"
      infoIconText="Use either comma-separated or newline-separated tickers."
      onFilesAdded={filesAdded}
      ref={fileInputRef}
    />
  );
};

export default CsvUpload;
