import { Intent } from "@blueprintjs/core";

import { appStates } from "constants/apiConstants";
import { UPDATE_APP_STATUS } from "constants/uiConstants";
import { singleToaster } from "./toaster";
import { LOCK_UI, UNLOCK_UI } from "../constants/dataEntryUIConstants";
import { OPEN_SOCKET } from "../constants/dataEntryConstants";

export default function addGlobalEventListeners(store) {
  if (!window.navigator.onLine) {
    store.dispatch({ type: LOCK_UI });
    store.dispatch({
      type: UPDATE_APP_STATUS,
      payload: {
        nextStatus: {
          icon: "warning-sign",
          intent: Intent.WARNING,
          loading: false,
          message: appStates.OFFLINE,
        },
      },
    });
    singleToaster?.show({
      message: "You are offline. Check your internet connection",
      intent: Intent.WARNING,
      timeout: -1,
      isCloseButtonShown: false,
    });
  }

  window.addEventListener("offline", () => {
    store.dispatch({
      type: UPDATE_APP_STATUS,
      payload: {
        nextStatus: {
          icon: "warning-sign",
          intent: Intent.WARNING,
          loading: false,
          message: appStates.OFFLINE,
        },
      },
    });
    store.dispatch({ type: LOCK_UI });
    singleToaster?.show({
      message: "You are offline. Check your internet connection",
      intent: Intent.WARNING,
      timeout: -1,
      isCloseButtonShown: false,
    });
  });

  window.addEventListener("online", () => {
    const modelID = window.location.href.match(/uid=[\w-]+/)?.[0].split("=")?.[1];
    if (modelID) {
      store.dispatch({
        type: OPEN_SOCKET,
        payload: {
          modelID,
        },
      });
    }
    store.dispatch({
      type: UPDATE_APP_STATUS,
      payload: {
        nextStatus: {
          intent: Intent.SUCCESS,
          loading: false,
          message: appStates.CONNECTED,
        },
      },
    });
    store.dispatch({ type: UNLOCK_UI });
    singleToaster.show({
      message: "Connection re-established",
      intent: Intent.SUCCESS,
    });
  });
}
