import { ExternalTokenizer } from "@lezer/lr";
import { factIdentifier } from "./vsl.parser.terms";

/*
 * custom logic to match fact refs, which can look a lot like arrays
 *
 * because this is imported into the grammar file before the @tokens block,
 * fact refs have higher precedence, so constructions that could match either
 * a fact ref or an array (like [id,[LFY + 1]]) will be interpreted as a fact
 * ref
 */

export const matchFactIdentifier = new ExternalTokenizer((input) => {
  // we start after the opening [, as that's where a factIdentifier can occur
  // skip over everything that isn't an opening or closing bracket
  // then record the end position - this marks the end of the possible fact
  // identifier token
  let hasIdentifier = false;

  while (input.next !== -1 && !"[]".includes(String.fromCodePoint(input.next))) {
    if (String.fromCodePoint(input.next).match(/\S/)) {
      hasIdentifier = true;
    }

    input.advance();
  }

  if (!hasIdentifier) {
    return;
  }

  const end = input.pos;

  // look ahead to see if the token looks like a fact ref

  // it should be directly followed by [LFY

  for (const ch of "[LFY") {
    if (input.next === -1 || String.fromCodePoint(input.next) !== ch) {
      return;
    }

    input.advance();
  }

  // if it's just [LFY], we've matched the token

  if (input.next !== -1 && String.fromCodePoint(input.next) === "]") {
    input.acceptToken(factIdentifier, end - input.pos);

    return;
  }

  // otherwise possible whitespace, +/-, and a number

  while (input.next !== -1 && String.fromCodePoint(input.next).match(/\s/)) {
    input.advance();
  }

  if (input.next !== -1 && "+-".includes(String.fromCodePoint(input.next))) {
    input.advance();
  } else {
    return;
  }

  while (input.next !== -1 && String.fromCodePoint(input.next).match(/\s/)) {
    input.advance();
  }

  let hasNumber = false;

  while (input.next !== -1 && String.fromCodePoint(input.next).match(/\d/)) {
    input.advance();

    hasNumber = true;
  }

  if (!hasNumber || input.next === -1 || String.fromCodePoint(input.next) !== "]") {
    return;
  }

  input.acceptToken(factIdentifier, end - input.pos);
});
