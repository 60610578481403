import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  AnchorButton,
  Button,
  Divider,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Tab,
  Tabs,
  Tooltip,
} from "@blueprintjs/core";
import { Flex } from "components/utility/StyledComponents";
import AddViewDialog from "components/dashboard/AddViewDialog";
import { View } from "types/view";
import { IconNames } from "@blueprintjs/icons";
import FormDialog from "components/utility/FormDialog";
import { mutate as globalMutate } from "swr/_internal";
import { DASHBOARD_ROUTE } from "constants/apiConstants";
import useView from "hooks/dashboard/useView";

interface IModelBarTabsProps {
  addView: (view: View) => Promise<boolean>;
  dashboardId: string | undefined;
  locked: boolean;
  removeView: (viewId: string) => void;
  updatingDashboard: boolean;
  views: View[];
}

export enum ECustomTabs {
  "DATA" = "Data",
  "CONTROL_PANEL" = "Control Panel",
  "VALUATION_SHEET" = "Valuation Sheet",
  "FINANCIAL_KPIS" = "Financial KPIs",
  "KEY_KPIS" = "Key KPIs",
}

const ModelBarTabs: React.FC<IModelBarTabsProps> = ({
  addView,
  dashboardId,
  locked,
  removeView,
  updatingDashboard,
  views,
}) => {
  const params = useParams();
  const [tabId, setTabId] = useState("");
  const [addViewDialogOpen, setAddViewDialogOpen] = useState(false);
  const [editViewDialogId, setEditViewDialogId] = useState<string>();
  const { updateView, view } = useView(editViewDialogId);

  useEffect(() => {
    if (!params?.tab || params.tab.includes("data")) {
      setTabId("data");
    } else {
      setTabId(params.tab);
    }
  }, [params]);

  const tabs = [
    ECustomTabs.DATA,
    ECustomTabs.CONTROL_PANEL,
    ECustomTabs.VALUATION_SHEET,
    ...(views.map((view) => view.name) || []),
  ];

  const removeTab = (tab: string) => {
    const view = views.find((view) => view.name === tab);
    if (view && confirm(`Remove ${view.name} view from dashboard?`)) {
      removeView(view.id);
    }
  };

  return (
    <Flex flex="0 1 0" justifyContent="flex-start">
      {!!editViewDialogId && (
        <FormDialog<View>
          failureToastMessage="Couldn't update view"
          fields={[{ id: "name", label: "Name", type: "text" }]}
          isOpen={!!editViewDialogId}
          title={`Edit View "${view?.name}"`}
          setDialogOpen={(isOpen) => {
            if (!isOpen) setEditViewDialogId(undefined);
          }}
          submit={async (nextView) => {
            const success = await updateView(nextView);
            if (success) {
              globalMutate([DASHBOARD_ROUTE, { dashboardId }]);
              return true;
            }
          }}
          successToastMessage="View updated successfully"
          values={view}
        />
      )}
      <AddViewDialog
        addView={addView}
        isOpen={addViewDialogOpen}
        onClose={() => setAddViewDialogOpen(false)}
        updating={updatingDashboard}
      />
      <Tabs large selectedTabId={tabId}>
        {tabs.map((tab) => {
          return (
            <Tab disabled={locked} key={tab} id={tab.toLowerCase()}>
              <Flex>
                <Link to={locked ? "#" : `/model/${params.model}/${tab.toLowerCase()}`}>{tab}</Link>
                {tab !== ECustomTabs.DATA &&
                  tab !== ECustomTabs.CONTROL_PANEL &&
                  tab !== ECustomTabs.VALUATION_SHEET &&
                  tab !== ECustomTabs.FINANCIAL_KPIS &&
                  tab !== ECustomTabs.KEY_KPIS && (
                    <Popover
                      content={
                        <Menu>
                          <MenuItem
                            icon={IconNames.EDIT}
                            onClick={() =>
                              setEditViewDialogId(views.find((view) => view.name === tab)?.id)
                            }
                            text="Rename View"
                          />
                          <MenuDivider />
                          <MenuItem
                            icon={IconNames.DELETE}
                            intent={Intent.DANGER}
                            onClick={() => removeTab(tab)}
                            text="Delete View"
                          />
                        </Menu>
                      }
                    >
                      <Button
                        icon={IconNames.MORE}
                        minimal
                        small
                        style={{ marginLeft: 2, transform: "rotate(90deg)" }}
                      />
                    </Popover>
                  )}
              </Flex>
            </Tab>
          );
        })}
      </Tabs>
      <Divider />
      {
        <Tooltip content="Add view" openOnTargetFocus={false}>
          <AnchorButton
            disabled={locked}
            minimal
            icon={IconNames.ADD}
            intent={Intent.PRIMARY}
            onClick={() => setAddViewDialogOpen(true)}
          />
        </Tooltip>
      }
    </Flex>
  );
};

export default ModelBarTabs;
