import React from "react";
import { HTMLTable } from "@blueprintjs/core";
import { TVSTableData } from "../../types/dashboard";
import styled from "styled-components/macro";

interface IVSHTMLTable {
  data: TVSTableData;
  widths?: number[];
}

interface TRowHeader {
  key: number;
  widths: number[] | undefined;
}

export const StyledRowHeader = styled.td<TRowHeader>`
  width: ${(props) => (props.widths ? `${props.widths[0]}px` : "initial")};
`;

export const StyledRowCell = styled.td<TRowHeader>`
  width: ${(props) => (props.widths ? `${props.widths[1]}px` : "initial")};
  text-align: right !important;
`;

const VSHTMLTable = ({ data, widths }: IVSHTMLTable) => {
  return (
    <HTMLTable compact striped style={{ width: "100%" }}>
      {data[0] ? (
        <thead>
          <tr>
            {data[0].map((colHeader, i) =>
              i === 0 ? (
                <StyledRowHeader widths={widths} key={i}>
                  <b>{colHeader}</b>
                </StyledRowHeader>
              ) : (
                <StyledRowCell widths={widths} key={i}>
                  <b>{colHeader}</b>
                </StyledRowCell>
              )
            )}
          </tr>
        </thead>
      ) : null}
      <tbody>
        {data.slice(1).map((row, i) => (
          <tr key={i}>
            {row?.map((value, j) =>
              j === 0 ? (
                <StyledRowHeader widths={widths} key={j}>
                  {value}
                </StyledRowHeader>
              ) : (
                <StyledRowCell widths={widths} key={j}>
                  {value}
                </StyledRowCell>
              )
            )}
          </tr>
        ))}
      </tbody>
    </HTMLTable>
  );
};

export default VSHTMLTable;
