/**
 * Utilities to be used largely in conjunction with Table.tsx, both
 * in a VSL context and an independant context.
 *
 * */
import { isRawFactOrOtherValue, TableStyleMatrix, TableValue } from "components/table/Table";
import { IVSLTable, TableRow } from "types/vsl";
import shortid from "shortid";
import { IRawFact } from "types/model";
import { TableFilters, TableWidgetColumnConfig } from "types/widget";
import { TTheme } from "types";
import { Themes } from "constants/uiConstants";
import { Colors } from "@blueprintjs/core";
import { TModelHistoryColumnDef } from "types/modelHistory";
import { colorizeNumeric } from "utils/generic";

export const vslTableDataToTableData = (
  raw: TableRow[] | null,
  columns: string[],
  tableFilters: TableFilters = {}
): { id: string; [key: string]: TableValue }[] => {
  const newData: { id: string; [key: string]: TableValue }[] = [];

  if (!raw) return newData;
  // transform data
  const hasFilters =
    Boolean(tableFilters.allColumns) ||
    Object.keys(tableFilters.columnFilters ?? {}).some(
      (key) => tableFilters.columnFilters && tableFilters.columnFilters[key].length > 0
    );

  for (const row of raw) {
    if (hasFilters) {
      const hasMatchingFilter = row.cells.some((cell) => {
        if (tableFilters.allColumns) {
          return cell.value.toLowerCase().includes(tableFilters.allColumns.toLowerCase());
        }
        return Object.keys(tableFilters.columnFilters ?? {}).some(
          (colId) =>
            cell.key === colId &&
            tableFilters.columnFilters?.[colId]
              .map((v) => v.toLowerCase())
              .includes(cell.value.toLowerCase())
        );
      });

      if (!hasMatchingFilter) {
        continue;
      }
    }

    const newRow: { id: string; [key: string]: TableValue } = { id: "" };
    for (let idx = 0; idx < row.cells.length; idx++) {
      const col = row.cells[idx];
      newRow[columns[idx]] = {
        value: col.value,
        // TODO: We use 'noID' here because the formatting logic expects a Fact-like object
        // this must have a truthy value.
        id: col.identifier || "noID", // Assign a Fact id , if one is present.
        format: col.format,
      } as IRawFact<string>;
      if (row.identifier) newRow.modelID = row.identifier || "";
    }
    if (!newRow?.id) newRow.id = row.identifier || shortid();
    newData.push(newRow);
  }
  return newData;
};

/**
 * Converts a vsl table to a csv string, that can then be downloaded if necessary.
 * Every value is wrapped in double quotes for safety.
 * */
export const vslTableToCSVString = (
  data: IVSLTable,
  prependEncoding = true
): string | undefined => {
  if (!data?.rows) return;
  let csvContent = prependEncoding ? "data:text/csv;charset=utf-8," : "";
  csvContent += data.columns.join(",") + "\n";
  csvContent += data.rows
    .map((row) => row.map((item) => `"${item.replaceAll('"', `"""`)}"`).join(","))
    .join("\n");
  return csvContent;
};

export function getColorGradientRGBA(value: number, minValue: number, maxValue: number): string {
  if (Number.isNaN(value) || value === 0) return "";
  if (value < 0) maxValue = 0;
  if (value > 0) minValue = 0;
  // Ensure the value is within the specified range
  value = Math.max(minValue, Math.min(value, maxValue));

  // Map the value to a percentage between -100 and 100
  const percentage: number = (value / (maxValue - minValue)) * 100;

  // Calculate the alpha channel based on the percentage
  const alpha: number = Math.abs(percentage) / 100;

  // Set red and green components based on the sign of the percentage
  const red: number = value < 0 ? 255 : 0;
  const green: number = value > 0 ? 140 : 0;

  // Return RGBA color with red, green, and alpha
  return `rgba(${red},${green},5,${alpha.toFixed(2)})`;
}

export const toNumericOrNaN = (entry: TableValue<string>) => {
  const value = isRawFactOrOtherValue(entry) ? entry?.value : entry;
  return value === "" ? NaN : Number(value);
};

export const getAlphaFromRGBA = (rgba: string) => {
  return Number.parseFloat(rgba.split(",")?.[3]) || 0;
};

/**
 * gets the style matrix for the table
 * @param columns
 * @param columnConfigs
 * @param data
 * @param theme
 * @param parentMatrix
 * @returns
 */
export const createStyleMatrix = (
  columns: TModelHistoryColumnDef[],
  columnConfigs: TableWidgetColumnConfig[],
  data: { id: string; [key: string]: TableValue }[] | null,
  theme: TTheme,
  parentMatrix?: TableStyleMatrix
): TableStyleMatrix => {
  const styleMatrix = parentMatrix ? [...parentMatrix] : [];
  if (!data) return styleMatrix;

  columnConfigs.forEach((colConfig) => {
    const colIdx = columns.findIndex((column) => column.id === colConfig.id);

    // for now we are only interested in if they have cell color gradient or colorizeNumerics
    if ((!colConfig.applyRelativeCellColorGradient && !colConfig.colorizeNumerics) || colIdx < 0) {
      return;
    }

    const colData: number[] = [];
    data.forEach((rowData) => {
      colData.push(toNumericOrNaN(rowData[colConfig.id]));
    });

    const minVal = Math.min(...colData.filter((v) => !Number.isNaN(v)));
    const maxVal = Math.max(...colData.filter((v) => !Number.isNaN(v)));

    styleMatrix[colIdx] = colData.map((colValue) => {
      // set color gradient for cells
      if (colConfig.applyRelativeCellColorGradient) {
        const backgroundColor =
          !Number.isNaN(minVal) && !Number.isNaN(maxVal)
            ? getColorGradientRGBA(colValue, minVal, maxVal)
            : undefined;

        // invert font color depending on the background
        const color =
          backgroundColor && theme === Themes.LIGHT && getAlphaFromRGBA(backgroundColor) > 0.55
            ? Colors.WHITE
            : undefined;

        return {
          backgroundColor,
          color,
        };
      }

      // colorize numerics for cells
      if (colConfig.colorizeNumerics) {
        return {
          color: colorizeNumeric(colValue || 0, theme),
        };
      }
    });
  });

  return styleMatrix;
};
