import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Page from "components/utility/Page";
import useSetPageTitle from "hooks/useSetPageTitle";
import {
  Button,
  Callout,
  Checkbox,
  Collapse,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  MenuItem,
  Section,
  SectionCard,
  SegmentedControl,
} from "@blueprintjs/core";
import {
  Flex,
  Grid,
  PaddedContent,
  StyledButtonLeftAligned,
  StyledScrollContainer,
} from "components/utility/StyledComponents";
import usePortfolios from "hooks/usePortfolios";
import useWatchlists from "hooks/useWatchlists";
import { Portfolio, Watchlist } from "types/collections";
import { MultiSelect, Select } from "@blueprintjs/select";
import { IconNames } from "@blueprintjs/icons";
import {
  GET_SIMULATION_OUTPUTS_ROUTE,
  GET_SIMULATIONS_COLLECTIONS_CONFIG,
  GET_SIMULATIONS_COLLECTIONS_TARGET_PERIODS,
  EMethods,
  SIMULATION_ROUTE,
} from "constants/apiConstants";
import useSWR from "swr";
import { swrApi } from "utils/api";
import Table, { TableValue, isRawFactOrOtherValue } from "components/table/Table";
import { ESortDirection, IResponse } from "types";
import { IRawFact } from "types/model";
import SimulationOutputsTable from "components/multimodel/SimulationOutputsTable";
import { IModelHistoryInfo } from "types/modelHistory";
import { IOutputsData, ISimulation, TRawOutputsData } from "types/scenarioAnalytics";
import { useApiCallback } from "hooks/useApiCallback";
import { BaseFormatObject, IFormatObject } from "types/format";
import {
  buildOutputsDataCSV,
  // buildOutputsDataCSV,
  buildSimulationOutputRow,
  calculateSimulationDifference,
  processOutputsData,
} from "utils/scenarioAnalytics";
import { shiftSimFx } from "utils/formulas";
import { singleToaster } from "utils/toaster";
import LoadingIndicator from "components/utility/LoadingIndicator";
import AppContext from "context/appContext";
import styled from "styled-components";
import resultsTableColumns from "TempSimulationColumns.json";
import { SortingState } from "@tanstack/react-table";
import { sortModels } from "utils/modelHistoryUtils";
import TableBottomControlRibbon from "components/dashboard/widget/table/TableBottomControlRibbon";
import { TableWidgetConfig } from "types/widget";
import { FontSizes } from "constants/uiConstants";
import { vslTableToCSVString } from "utils/table";
import { MISSING_VALUE } from "constants/appConstants";
import { downloadFile } from "utils/generic";

type SimulationTarget = "Watchlist" | "Portfolio" | "Company";
enum TargetItemType {
  ControlPanelKPIs = "Control Panel KPIs",
  NonKeyDrivers = "Non-Key Drivers",
  FinancialAssumptions = "Financial Assumptions",
}

const SimulationTargets: { [key: string]: SimulationTarget } = {
  Watchlist: "Watchlist",
  Portfolio: "Portfolio",
};

interface TargetItem {
  companyCount: number;
  id: string;
}
interface CollectionsData {
  collectionsItems:
    | {
        [key: string]: TableValue;
        id: string;
      }[]
    | null;
  targetItems: { [key: string]: TargetItem[] } | null;
  outputItems: string[] | null;
}

interface SimulationResult {
  groupData: { fields: { [key: string]: IRawFact }; model: IModelHistoryInfo }[];
  simulation: TRawOutputsData[];
}

const defaultFormula = "$FORMULA";

const StyledResultsTableContainer = styled(Grid)`
  height: calc(100% - 40px);
`;

const ScenarioAnalytics: React.FC = () => {
  useSetPageTitle("Scenario Analytics");

  const {
    config: { theme },
  } = useContext(AppContext);

  const [controlsCollapsed, setControlsCollapsed] = useState(false);
  const [query, setQuery] = useState("");
  const [outputsQuery, setOutputsQuery] = useState("");
  const [simulationTarget, setSimulationTarget] = useState<SimulationTarget>(
    SimulationTargets.Watchlist
  );
  const [selectedCollection, setSelectedCollection] = useState<Watchlist | Portfolio>();
  const { portfolios, loadingPortfolios } = usePortfolios();
  const { watchlists, loadingWatchlists } = useWatchlists();

  const [selectedTargetItemType, setSelectedTargetItemType] = useState<string>();
  const [selectedTargetItem, setSelectedTargetItem] = useState<string>();
  const [selectedTargetPeriod, setSelectedTargetPeriod] = useState<string>();
  const [selectedOutputItems, setSelectedOutputItems] = useState<string[]>([]);
  // outputsData is the line items for each model group.
  const [outputsData, setOutputsData] = useState<IOutputsData<IRawFact<IFormatObject, string>>[]>();
  const [simulation, setSimulation] = useState<ISimulation[]>();
  const [formula, setFormula] = useState<string>(defaultFormula);
  const [applyToAllPeriods, setApplyToAllPeriods] = useState<boolean>(false);

  const [sortingState, setSortingState] = useState<SortingState>([]);

  let collections: (Watchlist | Portfolio)[] | undefined = undefined;
  if (simulationTarget === SimulationTargets.Watchlist && watchlists) {
    collections = [...watchlists].sort((a, b) => a.name.localeCompare(b.name));
  } else if (simulationTarget === SimulationTargets.Portfolio && portfolios) {
    collections = [...portfolios].sort((a, b) => a.name.localeCompare(b.name));
  }

  const loadingCollectionOpts = loadingPortfolios || loadingWatchlists;

  // TODO: POssibly store this config somewhere on the server.
  const [resultsTableConfig, setResultsTableConfig] = useState<TableWidgetConfig>({
    layoutMode: "auto",
  });

  const clear = () => {
    setSelectedCollection(undefined);
    setSelectedTargetPeriod(undefined);
    setSelectedTargetItem(undefined);
    setSelectedOutputItems([]);
    setOutputsData(undefined);
    setSimulation(undefined);
    setFormula(defaultFormula);
    clearSimResponse();
  };
  const modelIds: string[] | undefined = useMemo(() => {
    if (!selectedCollection) return;
    if ("companies" in selectedCollection) {
      return selectedCollection.companies
        .map((company) => company.modelId)
        .filter((company): company is string => !!company);
    } else {
      return selectedCollection.holdings.map((holding) => holding.modelId);
    }
  }, [selectedCollection]);

  const readyToFetchCollectionData = simulationTarget && selectedCollection && modelIds;

  const { data: collectionData, isLoading: isLoadingCollectionsData } = useSWR<
    IResponse<CollectionsData>
  >(
    readyToFetchCollectionData
      ? [
          GET_SIMULATIONS_COLLECTIONS_CONFIG,
          {
            collectionType: simulationTarget.toLowerCase(),
            collectionId: selectedCollection.id,
            modelIds,
            ...(selectedTargetItem &&
              selectedTargetItemType && {
                targetItem: selectedTargetItem,
                targetCategory: selectedTargetItemType,
              }),
          },
        ]
      : null,
    swrApi
  );

  const { data: targetPeriods, isLoading: isLoadingTargetPeriodsData } = useSWR<
    IResponse<string[]>
  >(
    readyToFetchCollectionData
      ? [
          GET_SIMULATIONS_COLLECTIONS_TARGET_PERIODS,
          {
            collectionType: simulationTarget.toLowerCase(),
            collectionId: selectedCollection.id,
            modelIds,
          },
        ]
      : null,
    swrApi
  );

  const {
    callback: getOutputsDataCallback,
    response: outputsDataRes,
    fetching: fetchingOutputsData,
    clear: _clearOutputsData,
  } = useApiCallback<TRawOutputsData[]>();

  const getOutputsData = useCallback(() => {
    if (!selectedOutputItems.length) return;
    getOutputsDataCallback(GET_SIMULATION_OUTPUTS_ROUTE, {
      method: EMethods.POST,
      body: {
        modelIds,
        outputVariables: selectedOutputItems,
      },
      ...(selectedTargetItem &&
        selectedTargetItemType && {
          targetItem: selectedTargetItem,
          targetCategory: selectedTargetItemType,
        }),
    });
  }, [
    getOutputsDataCallback,
    modelIds,
    selectedOutputItems,
    selectedTargetItem,
    selectedTargetItemType,
  ]);

  /** fetch outputs data when they change */
  useEffect(() => {
    if (selectedOutputItems.length) {
      getOutputsData();
    }
  }, [getOutputsData, selectedOutputItems]);

  /** process outputs data when we get them from the server */
  useEffect(() => {
    if (outputsDataRes) setOutputsData(processOutputsData(outputsDataRes.data));
  }, [outputsDataRes]);

  const {
    callback: runSimulationCallback,
    response: simulationResult,
    fetching: simulating,
    clear: clearSimResponse,
  } = useApiCallback<SimulationResult>();

  const resultsTableData = useMemo(() => {
    if (!collectionData) return [];

    let models = simulationResult?.data
      ? simulationResult.data.groupData.map((entry) => ({ ...entry.model, ...entry.fields }))
      : collectionData.data?.collectionsItems;

    if (!models) return [];

    if (sortingState[0]) {
      models = sortModels(
        models,
        sortingState[0].id,
        sortingState[0].desc ? ESortDirection.DESC : ESortDirection.ASC
      );
    }

    return models.map((model) => {
      return buildSimulationOutputRow(model);
    });
  }, [collectionData, simulationResult, sortingState]);

  const runSimulation = useCallback(() => {
    if (!formula) setFormula(defaultFormula);
    let edits = [{ formula: formula ?? defaultFormula, timePeriod: selectedTargetPeriod }];

    // apply edit to all periods after target period
    if (targetPeriods && selectedTargetPeriod && applyToAllPeriods) {
      edits = targetPeriods.data
        .slice(targetPeriods.data.indexOf(selectedTargetPeriod))
        .map((period, idx) => ({ formula: shiftSimFx(formula, idx), timePeriod: period }));
    }

    runSimulationCallback(SIMULATION_ROUTE, {
      method: EMethods.POST,
      collectionType: simulationTarget.toLowerCase(),
      collectionId: selectedCollection?.id,
      modelIds,
      body: {
        applyToAllPeriods,
        modelIds,
        edits,
        outputVariables: selectedOutputItems,
        targetCategory: selectedTargetItemType,
        targetItem: selectedTargetItem,
      },
    });
  }, [
    formula,
    modelIds,
    selectedTargetPeriod,
    targetPeriods,
    applyToAllPeriods,
    runSimulationCallback,
    simulationTarget,
    selectedCollection?.id,
    selectedOutputItems,
    selectedTargetItem,
    selectedTargetItemType,
  ]);
  /** recompute outputs table when outputs or simulation data change */
  useEffect(() => {
    if (!outputsData || !simulationResult) return;
    if (simulationResult.error) {
      singleToaster.show({
        message: "Error running simulation: " + simulationResult.error,
        intent: Intent.DANGER,
      });
      return;
    }
    const simulation = processOutputsData(simulationResult.data.simulation);
    const structure: ISimulation[] = outputsData
      .map((model) => {
        const result = simulation.find((sim) => sim.id === model.id);
        if (result?.lineItems) {
          const merged = {
            ...result,
            outputItems: result.lineItems.map((item) => {
              return {
                ...item,
                format: {},
                facts: item.facts.map((fact) => {
                  return {
                    ...fact,
                    period: fact.period,
                    format: fact.format
                      ? { ...BaseFormatObject, ...fact.format }
                      : BaseFormatObject,
                  };
                }),
              };
            }),
          };
          return {
            ...model,
            lineItems: model?.lineItems
              ? model.lineItems.map((lineItem) => {
                  const simulatedLineItem = merged?.outputItems
                    ? merged?.outputItems.find((simLineItem) => {
                        return simLineItem.id === lineItem.id;
                      })
                    : undefined;
                  return {
                    ...lineItem,
                    before: lineItem.facts,
                    after: simulatedLineItem?.facts || [],
                    difference: simulatedLineItem
                      ? calculateSimulationDifference(lineItem.facts, simulatedLineItem?.facts)
                      : [],
                  };
                })
              : [],
          };
        }
      })
      .filter((s): s is ISimulation => s != undefined);
    setSimulation(structure);
  }, [simulationResult, outputsData]);

  const resultsTable = useMemo(() => {
    if (!collectionData?.data?.collectionsItems || collectionData.error || targetPeriods?.error) {
      return;
    }
    const columns = simulationResult?.data
      ? resultsTableColumns.simulatedColumns
      : resultsTableColumns.viewColumns;
    return (
      <Section
        compact
        key="results-table"
        rightElement={
          <Button
            icon={IconNames.DOWNLOAD}
            minimal
            onClick={() => {
              // Build a VSLTable like object to allow downloading using the standard
              // utility. Not making a custom utility as this is only usage of this
              // response.
              const csvContent = vslTableToCSVString({
                columns: columns.map((c) => c.header),
                // OK to leave raw blank here as we don't use it for downloading as csv.
                raw: [],
                rows: resultsTableData.map((row) => [
                  ...Object.entries(row)
                    .filter(([key]) => columns.findIndex((col) => col.id === key) !== -1)
                    .map(([_, v]) =>
                      isRawFactOrOtherValue(v)
                        ? v.value
                          ? v.value.toString()
                          : MISSING_VALUE
                        : v.toString() ?? MISSING_VALUE
                    ),
                ]),
              });
              downloadFile(csvContent, `Simulation Results - ${selectedCollection?.name}`);
            }}
            text="Download as CSV"
          />
        }
        style={{ height: "100%", overflow: "hidden", padding: 1 }}
        title="Results"
      >
        <StyledResultsTableContainer rows="1fr">
          <StyledScrollContainer $theme={theme}>
            <Table
              columnTemplates={columns}
              configOverrides={resultsTableConfig}
              data={resultsTableData}
              enableSelection={false}
              id="simulation-results-table"
              loading={isLoadingCollectionsData}
              setSortingState={setSortingState}
              sortingState={sortingState}
              updateConfig={async (details) => {
                setResultsTableConfig({ ...resultsTableConfig, ...details });
                return true;
              }}
            />
          </StyledScrollContainer>
          <TableBottomControlRibbon
            columns={columns.map((c) => c.id)}
            enableColumnEditing={false}
            fontSize={resultsTableConfig?.fontSize ?? FontSizes.MEDIUM}
            layoutMode={resultsTableConfig.layoutMode ?? "auto"}
            numberOfEntries={resultsTableData.length}
            updateFontSize={(nextFontSize) => {
              setResultsTableConfig({ ...resultsTableConfig, fontSize: nextFontSize });
            }}
            updateConfig={async (details) => {
              setResultsTableConfig({ ...resultsTableConfig, ...details });
              return true;
            }}
          />
        </StyledResultsTableContainer>
      </Section>
    );
  }, [
    collectionData?.data,
    collectionData?.error,
    isLoadingCollectionsData,
    resultsTableConfig,
    resultsTableData,
    selectedCollection?.name,
    simulationResult?.data,
    setSortingState,
    sortingState,
    targetPeriods?.error,
    theme,
  ]);

  const targetItems = useMemo(() => {
    if (selectedTargetItemType) {
      const categoryItems = collectionData?.data?.targetItems?.[selectedTargetItemType] ?? [];

      categoryItems.sort((a, b) => {
        return b.companyCount - a.companyCount;
      });

      return categoryItems;
    }
    return [];
  }, [collectionData?.data?.targetItems, selectedTargetItemType]);

  const outputsTable = useMemo(() => {
    if (!selectedOutputItems?.length || !outputsData?.length) return null;
    return (
      <Section
        compact
        key="outputs-table"
        rightElement={
          <Button
            icon={IconNames.DOWNLOAD}
            minimal
            onClick={() => {
              const csvContent = buildOutputsDataCSV(
                targetPeriods?.data ?? [],
                outputsData,
                simulation
              );
              downloadFile(csvContent, `Simulation Outputs - ${selectedCollection?.name}`);
            }}
            text="Download as CSV"
          />
        }
        title="Outputs"
      >
        {fetchingOutputsData ? (
          <PaddedContent padding={"10px"}>
            <LoadingIndicator status={{ message: "Loading outputs..." }} />
          </PaddedContent>
        ) : (
          <SimulationOutputsTable
            availablePeriods={targetPeriods?.data ?? []}
            outputs={selectedOutputItems}
            outputsData={outputsData}
            simulating={simulating}
            simulation={simulation}
            sortedModelIds={resultsTableData?.map((row) => row?.modelID as string)}
          />
        )}
      </Section>
    );
  }, [
    outputsData,
    fetchingOutputsData,
    resultsTableData,
    selectedCollection?.name,
    selectedOutputItems,
    simulating,
    simulation,
    targetPeriods?.data,
  ]);

  return (
    <Page title="Scenario Analytics">
      <Grid
        fullHeight
        gap="10px"
        rows={outputsTable ? "auto 1fr 1fr" : "auto 1fr"}
        style={{ padding: 10, overflowY: "auto" }}
      >
        <Section
          compact
          title="Simulation Controls"
          rightElement={
            <Button
              icon={controlsCollapsed ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
              minimal
              onClick={() => setControlsCollapsed(!controlsCollapsed)}
            />
          }
        >
          <Collapse isOpen={!controlsCollapsed}>
            <SectionCard padded={true}>
              <Flex flexDirection="column">
                <Flex fullWidth gap={20} justifyContent="flex-start">
                  <FormGroup label="Type:">
                    <SegmentedControl
                      onValueChange={(nextSimulationType) => {
                        clear();
                        setSimulationTarget(nextSimulationType as SimulationTarget);
                      }}
                      options={Object.values(SimulationTargets).map((sT) => ({
                        label: sT,
                        value: sT,
                      }))}
                      value={simulationTarget}
                    />
                  </FormGroup>
                  <FormGroup
                    disabled={loadingCollectionOpts}
                    label={`${simulationTarget}:`}
                    style={{ flex: 1 }}
                  >
                    <Select<Portfolio | Watchlist>
                      disabled={loadingCollectionOpts}
                      onItemSelect={(collection) => {
                        setSelectedCollection(collection);
                        setSelectedTargetItemType(undefined);
                        setSelectedTargetItem(undefined);
                        setSelectedTargetPeriod(undefined);
                        setSelectedOutputItems([]);
                        clearSimResponse();
                      }}
                      items={collections ?? []}
                      itemPredicate={(query, item) =>
                        item.name.toLowerCase().includes(query.toLowerCase())
                      }
                      itemRenderer={(item, { modifiers, handleClick }) => (
                        <MenuItem
                          active={modifiers.active}
                          key={item.id}
                          onClick={handleClick}
                          text={item.name}
                        />
                      )}
                      noResults={<MenuItem disabled text={`No matching ${simulationTarget}s`} />}
                      onQueryChange={setQuery}
                      query={query}
                      popoverProps={{
                        onClose: () => setQuery(""),
                      }}
                    >
                      <StyledButtonLeftAligned
                        fill
                        loading={loadingCollectionOpts}
                        rightIcon={IconNames.DOUBLE_CARET_VERTICAL}
                        text={selectedCollection?.name ?? `Select ${simulationTarget}`}
                      />
                    </Select>
                  </FormGroup>
                  <FormGroup disabled={!selectedCollection} label="Item Category:">
                    <Select<TargetItemType>
                      disabled={!selectedCollection}
                      filterable={false}
                      onItemSelect={(targetItemType) => {
                        setSelectedTargetItem(undefined);
                        setSelectedTargetItemType(targetItemType);
                      }}
                      items={Object.values(TargetItemType)}
                      itemRenderer={(item, { handleClick, modifiers }) => {
                        const numberOfItems = collectionData?.data?.targetItems?.[item]?.length;
                        return (
                          <MenuItem
                            active={modifiers.active}
                            disabled={!numberOfItems}
                            key={item}
                            onClick={handleClick}
                            text={item}
                            label={
                              numberOfItems
                                ? `${numberOfItems} items available.`
                                : "No items available."
                            }
                          />
                        );
                      }}
                    >
                      <Button
                        disabled={!selectedCollection}
                        rightIcon={IconNames.DOUBLE_CARET_VERTICAL}
                        text={selectedTargetItemType ?? "Select Target Item type..."}
                      />
                    </Select>
                  </FormGroup>
                  <FormGroup
                    label="Target Item:"
                    disabled={!collectionData || isLoadingCollectionsData}
                    style={{ flex: 1 }}
                  >
                    <Select<TargetItem>
                      noResults={<MenuItem disabled text="No matching items." />}
                      onItemSelect={(targetItem) => {
                        setSelectedTargetItem(targetItem.id);
                        setSelectedOutputItems((prevState) => {
                          if (prevState.includes(targetItem.id)) return prevState;
                          else return [...prevState, targetItem.id];
                        });
                      }}
                      items={targetItems ?? []}
                      itemPredicate={(query, item) =>
                        item.id.toLowerCase().includes(query.toLowerCase())
                      }
                      itemRenderer={(item, { modifiers, handleClick }) => (
                        <MenuItem
                          active={modifiers.active}
                          key={item.id}
                          onClick={handleClick}
                          text={item.id}
                          label={`${item.companyCount} ${
                            item.companyCount === 1 ? "company" : "companies"
                          }`}
                        />
                      )}
                      onQueryChange={setQuery}
                      query={query}
                      popoverProps={{
                        onClose: () => setQuery(""),
                      }}
                    >
                      <StyledButtonLeftAligned
                        disabled={!collectionData || isLoadingCollectionsData}
                        rightIcon={IconNames.DOUBLE_CARET_VERTICAL}
                        text={selectedTargetItem ?? "Select target item..."}
                        fill
                      />
                    </Select>
                  </FormGroup>
                  <FormGroup
                    label="Target Period:"
                    disabled={!collectionData || isLoadingCollectionsData}
                    style={{ flex: 1 }}
                  >
                    <Select<string>
                      noResults={<MenuItem disabled text="No matching periods." />}
                      onItemSelect={(period) => {
                        setSelectedTargetPeriod(period);
                      }}
                      items={targetPeriods?.data ?? []}
                      itemPredicate={(query, item) =>
                        item.toLowerCase().includes(query.toLowerCase())
                      }
                      itemRenderer={(item, { modifiers, handleClick }) => (
                        <MenuItem
                          active={modifiers.active}
                          key={item}
                          onClick={handleClick}
                          text={item}
                        />
                      )}
                      onQueryChange={setQuery}
                      query={query}
                      popoverProps={{
                        onClose: () => setQuery(""),
                      }}
                    >
                      <StyledButtonLeftAligned
                        disabled={!collectionData || isLoadingTargetPeriodsData}
                        rightIcon={IconNames.DOUBLE_CARET_VERTICAL}
                        text={selectedTargetPeriod ?? "Select target period..."}
                        fill
                      />
                    </Select>
                  </FormGroup>
                  <Checkbox
                    checked={applyToAllPeriods}
                    onChange={() => setApplyToAllPeriods((prevState) => !prevState)}
                    label="Apply to all subsequent forecast periods"
                    style={{ marginTop: "18px" }}
                  />
                </Flex>
                <Flex fullWidth gap={20} justifyContent="flex-start">
                  <FormGroup
                    label="Output Items:"
                    disabled={!collectionData || isLoadingCollectionsData}
                    style={{ flex: 1 }}
                  >
                    <MultiSelect<string>
                      disabled={!collectionData || isLoadingCollectionsData}
                      itemRenderer={(item, { handleClick }) => {
                        const isSelected = selectedOutputItems?.indexOf(item) !== -1;
                        return (
                          <MenuItem
                            icon={isSelected ? IconNames.TICK : undefined}
                            key={item}
                            text={item}
                            onClick={handleClick}
                          />
                        );
                      }}
                      itemPredicate={(query: string, item: string) =>
                        item.toLowerCase().includes(query.toLowerCase())
                      }
                      tagRenderer={(tag) => tag}
                      onItemSelect={(item) => {
                        setOutputsQuery("");
                        setSelectedOutputItems((prevState) => {
                          if (prevState.includes(item)) return prevState;
                          else return [...(prevState ?? []), item];
                        });
                      }}
                      onRemove={(itemRemoved) => {
                        setSelectedOutputItems((prevState) =>
                          prevState.filter((item) => item !== itemRemoved)
                        );
                        setSimulation(undefined);
                        clearSimResponse();
                      }}
                      items={collectionData?.data?.outputItems ?? []}
                      selectedItems={selectedOutputItems}
                      placeholder=""
                      onQueryChange={setOutputsQuery}
                      query={outputsQuery}
                    />
                  </FormGroup>
                  <FormGroup
                    label={"Formula"}
                    disabled={!collectionData || isLoadingCollectionsData}
                    style={{ flex: 1 }}
                  >
                    <Flex flexDirection="row">
                      <Icon icon={IconNames.FUNCTION} style={{ marginRight: "5px" }} />
                      <InputGroup
                        disabled={!collectionData || isLoadingCollectionsData}
                        value={formula}
                        fill
                        onChange={(ev) => setFormula(ev.target.value)}
                      />
                    </Flex>
                  </FormGroup>
                </Flex>

                <Flex fullWidth gap={5} justifyContent="flex-end">
                  <Button text={"Clear"} onClick={clear} />
                  <Button
                    disabled={
                      !selectedTargetItem || !selectedTargetPeriod || !selectedOutputItems?.length
                    }
                    loading={simulating}
                    intent={Intent.PRIMARY}
                    text={"Run"}
                    onClick={runSimulation}
                  />
                </Flex>
              </Flex>
            </SectionCard>
          </Collapse>
        </Section>
        {isLoadingCollectionsData && (
          <div style={{ marginTop: "10px" }}>
            <LoadingIndicator
              status={{
                message: `Loading ${
                  selectedCollection?.name
                } ${simulationTarget.toLowerCase()} models data...`,
                intent: Intent.PRIMARY,
              }}
            />
          </div>
        )}
        {(collectionData?.error || targetPeriods?.error) && (
          <Callout intent={Intent.WARNING} title={`Error loading ${simulationTarget} `} />
        )}
        {resultsTable}
        {outputsTable}
      </Grid>
    </Page>
  );
};

export default ScenarioAnalytics;
