export const SAVE_LAYOUTS = "SAVE_LAYOUTS";
export const ENTER_EDIT_MODE = "ENTER_EDIT_MODE";
export const UPDATE_LAYOUT = "UPDATE_LAYOUT";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const SET_WIDGET_INTRINSIC_HEIGHT = "SET_WIDGET_INTRINSIC_HEIGHT";
export const SET_USER_ADJUSTED = "SET_USER_ADJUSTED";
export const SET_DASHBOARD_HEIGHT = "SET_DASHBOARD_HEIGHT";
export const MAKE_USER_ADJUSTMENT = "MAKE_USER_ADJUSTMENT";
export const UPDATE_CURRENT_BREAKPOINT = "UPDATE_CURRENT_BREAKPOINT";
export const REMOVE_WIDGET = "REMOVE_WIDGET";
export const ADD_WIDGET = "ADD_WIDGET";

export const BREAKPOINTS = { lg: 1200, md: 996, sm: 768 };
