import {
  Alignment,
  Boundary,
  Button,
  Menu,
  MenuDivider,
  MenuItem,
  OverflowList,
  Popover,
  Switch,
  Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Flex, NormalAlignDivider, PaddedContent } from "components/utility/StyledComponents";
import Highcharts from "highcharts/highstock";
import React, { useEffect, useState } from "react";
import { EUnit, EValFormat, IFormatObject } from "types/format";
import { EWidgetType2, VSLChart } from "types/vsl";
import { ChartWidgetConfig, Widget } from "types/widget";
import {
  chartDefaults,
  ChartPeriodType,
  VSChartToHighchartsSeriesMap,
  YAxisId,
} from "constants/chartConstants";
import EditChartDialog from "./EditChartDialog";
import {
  getConfigFormat,
  getCurrentChartConfig,
  getXAxisType,
  isYAxisPercentage,
} from "utils/chartUtils";
import capitalize from "lodash.capitalize";
import YAxisMinMaxMenu from "./YAxisMinMaxMenu";
import XAxisMinMaxMenu from "components/dashboard/widget/chart/XAxisMinMaxMenu";
import XAxisLabelFormatMenu from "components/dashboard/widget/chart/XAxisLabelFormatMenu";
import styled from "styled-components";
import { DEFAULT_DECIMAL_PLACES } from "utils/formatter";
import usePrevious from "hooks/usePrevious";

interface ChartBottomControlRibbonProps {
  chartRendererConfig?: ChartWidgetConfig;
  data: VSLChart;
  defaultYAxisBounds: {
    [key in YAxisId]: {
      min?: number | null;
      max?: number | null;
    };
  };
  locked?: boolean;
  type:
    | EWidgetType2.BAR_CHART
    | EWidgetType2.LINE_CHART
    | EWidgetType2.PIE_CHART
    | EWidgetType2.SCATTER_CHART;
  updateWidget: (details: Partial<Widget>) => Promise<boolean>;
  updateWidgetConfig: (details: Partial<ChartWidgetConfig>) => Promise<boolean>;
  widget: Widget;
  xAxisChartState: {
    min?: number | null;
    max?: number | null;
  };
}

const ChartBottomControlRibbon: React.FC<ChartBottomControlRibbonProps> = ({
  chartRendererConfig,
  data,
  defaultYAxisBounds,
  locked = false,
  type,
  updateWidget,
  updateWidgetConfig,
  widget,
  xAxisChartState,
}) => {
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const defaults = chartDefaults[type];
  const prevData = usePrevious(data);

  useEffect(() => {
    if (data != prevData) {
      // Reset or otherwise modify elements of the config given a data change.
      updateWidgetConfig({
        yAxis: {
          ...chartRendererConfig?.yAxis,
          [YAxisId.LEFT]: {
            ...chartRendererConfig?.yAxis?.[YAxisId.LEFT],
            max: undefined,
            min: undefined,
          },
          [YAxisId.RIGHT]: {
            ...chartRendererConfig?.yAxis?.[YAxisId.RIGHT],
            max: undefined,
            min: undefined,
          },
        },
      });
    }
  }, [chartRendererConfig, data, prevData, updateWidgetConfig]);

  const {
    correlationMetricsEnabled,
    dataLabelsEnabled,
    navigatorEnabled,
    legendEnabled,
    rangeSelectorEnabled,
    tooltipEnabled,
  } = getCurrentChartConfig(defaults, type, chartRendererConfig);

  const rightAxisFormat = getConfigFormat(data, YAxisId.RIGHT, chartRendererConfig);
  const leftAxisFormat = getConfigFormat(data, YAxisId.LEFT, chartRendererConfig);
  const xAxisConfigFormat = getConfigFormat(data, null, chartRendererConfig);

  const showAdditionalYAxisOptions = [
    EWidgetType2.BAR_CHART,
    EWidgetType2.LINE_CHART,
    EWidgetType2.SCATTER_CHART,
  ].includes(type);

  const periodType = data?.opts?.time
    ? ChartPeriodType.Raw
    : chartRendererConfig?.periodType ?? ChartPeriodType.Fiscal;
  const xAxisType = getXAxisType(type, periodType);

  const metricsAvailable = data?.datasets?.length === 2 && xAxisType === "category";

  const hasLeftAxis = Object.values(chartRendererConfig?.seriesOptions ?? {})?.some(
    (series) => series.axis === YAxisId.LEFT
  );
  const showXAxisOptions = type === EWidgetType2.SCATTER_CHART;

  const transformationEnabled = !!chartRendererConfig?.enableTransformationOptions;

  const renderChartControls = (): JSX.Element => (
    <Popover
      content={
        <Menu>
          <MenuItem
            labelElement={
              <Switch
                alignIndicator={Alignment.RIGHT}
                checked={dataLabelsEnabled}
                onClick={(e) => e.stopPropagation()}
                readOnly
              />
            }
            onClick={() => {
              updateWidgetConfig({
                plotOptions: {
                  [VSChartToHighchartsSeriesMap[type]]: {
                    dataLabels: { enabled: !dataLabelsEnabled },
                  },
                },
              });
            }}
            shouldDismissPopover={false}
            text="Show Data Labels"
          />
          <MenuItem
            labelElement={
              <Switch
                alignIndicator={Alignment.RIGHT}
                checked={legendEnabled}
                onClick={(e) => e.stopPropagation()}
                readOnly
              />
            }
            onClick={() => {
              updateWidgetConfig({
                legend: { enabled: !legendEnabled },
              });
            }}
            shouldDismissPopover={false}
            text="Show Legend"
          />
          <MenuItem
            labelElement={
              <Switch
                alignIndicator={Alignment.RIGHT}
                checked={tooltipEnabled}
                onClick={(e) => e.stopPropagation()}
                readOnly
              />
            }
            onClick={() => {
              updateWidgetConfig({
                tooltip: { enabled: !tooltipEnabled },
              });
            }}
            shouldDismissPopover={false}
            text="Enable Mouseover"
          />
          <MenuItem
            disabled={!(type === EWidgetType2.LINE_CHART) && !(type === EWidgetType2.BAR_CHART)}
            labelElement={
              <Switch
                alignIndicator={Alignment.RIGHT}
                checked={transformationEnabled}
                disabled={!(type === EWidgetType2.LINE_CHART) && !(type === EWidgetType2.BAR_CHART)}
                onClick={(e) => e.stopPropagation()}
                readOnly
              />
            }
            onClick={() => {
              updateWidgetConfig({
                enableTransformationOptions: !transformationEnabled,
              });
            }}
            shouldDismissPopover={false}
            text="Enable Transformation Options"
          />
          <MenuItem
            disabled={!metricsAvailable}
            labelElement={
              <Switch
                alignIndicator={Alignment.RIGHT}
                checked={correlationMetricsEnabled}
                disabled={!metricsAvailable}
                onClick={(e) => e.stopPropagation()}
                readOnly
              />
            }
            onClick={() => {
              updateWidgetConfig({
                correlationMetrics: !correlationMetricsEnabled,
              });
            }}
            shouldDismissPopover={false}
            text="Enable Correlation Metrics"
          />
        </Menu>
      }
      key="Chart Area"
    >
      <StyledControlButton
        disabled={locked}
        minimal
        small
        style={{ flexShrink: 0 }}
        text="Chart Area"
      />
    </Popover>
  );
  const renderXAxisControls = (): JSX.Element => (
    <Popover
      content={
        <Menu>
          {showXAxisOptions && (
            <>
              <MenuDivider title="Axis Bounds" />
              <XAxisMinMaxMenu
                chartRendererConfig={chartRendererConfig}
                configFormat={xAxisConfigFormat}
                chartXAxisMax={xAxisChartState.max}
                chartXAxisMin={xAxisChartState.min}
                updateConfig={updateWidgetConfig}
              />
              <XAxisLabelFormatMenu
                chartRendererConfig={chartRendererConfig}
                updateConfig={updateWidgetConfig}
              />
            </>
          )}
          {(type === EWidgetType2.LINE_CHART || type === EWidgetType2.BAR_CHART) && (
            <>
              <MenuDivider title="Time Series" />
              <MenuItem
                onClick={() => {
                  updateWidgetConfig({
                    rangeSelector: { enabled: !rangeSelectorEnabled },
                  });
                }}
                shouldDismissPopover={false}
                labelElement={
                  <Switch
                    alignIndicator={Alignment.RIGHT}
                    checked={rangeSelectorEnabled}
                    onClick={(e) => e.stopPropagation()}
                    readOnly
                  />
                }
                text="Enable Range Selector:"
              />
              <MenuItem
                disabled={xAxisType === "category"}
                labelElement={
                  <Switch
                    alignIndicator={Alignment.RIGHT}
                    checked={navigatorEnabled}
                    disabled={xAxisType === "category"}
                    onClick={(e) => e.stopPropagation()}
                    readOnly
                  />
                }
                onClick={() => {
                  updateWidgetConfig({
                    navigator: { enabled: !navigatorEnabled },
                  });
                }}
                shouldDismissPopover={false}
                text="Enable Navigator"
              />
            </>
          )}
        </Menu>
      }
      key="X Axis"
    >
      <StyledControlButton disabled={locked} minimal small text="X Axis" />
    </Popover>
  );

  const renderYAxisControls = (axis: YAxisId): JSX.Element => {
    const isPercentageTransformation = isYAxisPercentage(axis, chartRendererConfig);

    const axisFormat =
      axis === YAxisId.LEFT ? chartRendererConfig?.yAxis?.left : chartRendererConfig?.yAxis?.right;
    const updateYAxis = (details: Highcharts.YAxisOptions & Partial<IFormatObject>) => {
      updateWidgetConfig({
        yAxis: {
          ...chartRendererConfig?.yAxis,
          [axis]: { ...chartRendererConfig?.yAxis?.[axis], ...details },
        },
      });
    };
    return (
      <Popover
        content={
          <Menu>
            {showAdditionalYAxisOptions && (
              <>
                <MenuDivider title="Axis Bounds" />
                <PaddedContent padding="5px 7px">
                  <YAxisMinMaxMenu
                    axis={axis}
                    chartRendererConfig={chartRendererConfig}
                    chartYAxisMax={defaultYAxisBounds[axis].max}
                    chartYAxisMin={defaultYAxisBounds[axis].min}
                    configFormat={axis === YAxisId.LEFT ? leftAxisFormat : rightAxisFormat}
                    updateConfig={updateWidgetConfig}
                  />
                </PaddedContent>
              </>
            )}
            <MenuDivider title="Format" />
            <MenuItem shouldDismissPopover={false} text="Format">
              <MenuItem
                icon={(isPercentageTransformation || !axisFormat?.valFormat) && IconNames.TICK}
                disabled={isPercentageTransformation}
                onClick={() => {
                  updateYAxis({ valFormat: undefined });
                }}
                shouldDismissPopover={false}
                text="Default"
              />
              <MenuDivider />
              {Object.values(EValFormat).map((vF) => {
                return (
                  <MenuItem
                    icon={
                      !isPercentageTransformation && axisFormat?.valFormat === vF && IconNames.TICK
                    }
                    key={vF}
                    disabled={isPercentageTransformation}
                    onClick={() => {
                      updateYAxis({ valFormat: vF });
                    }}
                    shouldDismissPopover={false}
                    text={vF}
                  />
                );
              })}
            </MenuItem>
            <MenuItem shouldDismissPopover={false} text="Unit">
              <MenuItem
                icon={(isPercentageTransformation || !axisFormat?.unit) && IconNames.TICK}
                disabled={isPercentageTransformation}
                onClick={() => {
                  updateYAxis({ unit: undefined });
                }}
                shouldDismissPopover={false}
                text="Default"
              />
              <MenuDivider />
              {Object.values(EUnit).map((ut) => {
                return (
                  <MenuItem
                    icon={!isPercentageTransformation && axisFormat?.unit === ut && IconNames.TICK}
                    key={ut}
                    disabled={isPercentageTransformation}
                    onClick={() => {
                      updateYAxis({ unit: ut });
                    }}
                    shouldDismissPopover={false}
                    text={ut}
                  />
                );
              })}
            </MenuItem>
            <MenuItem
              shouldDismissPopover={false}
              text="Decimal Places:"
              disabled={isPercentageTransformation}
              labelElement={
                <Flex>
                  <Button
                    icon={IconNames.SMALL_MINUS}
                    disabled={isPercentageTransformation}
                    onClick={() => {
                      if (axisFormat?.decimalPlaces == undefined) {
                        updateYAxis({ decimalPlaces: DEFAULT_DECIMAL_PLACES - 1 });
                        return;
                      }
                      if (axisFormat?.decimalPlaces - 1 < 0) return;
                      updateYAxis({ decimalPlaces: axisFormat.decimalPlaces - 1 });
                    }}
                    small
                    minimal
                  />
                  <Button
                    icon={IconNames.SMALL_PLUS}
                    disabled={isPercentageTransformation}
                    onClick={() => {
                      if (axisFormat?.decimalPlaces == undefined) {
                        updateYAxis({ decimalPlaces: DEFAULT_DECIMAL_PLACES + 1 });
                        return;
                      }
                      updateYAxis({ decimalPlaces: axisFormat.decimalPlaces + 1 });
                    }}
                    small
                    minimal
                  />
                </Flex>
              }
            />
          </Menu>
        }
        hoverCloseDelay={1000}
        key={hasLeftAxis ? `Y Axis (${capitalize(axis)})` : "Y Axis"}
      >
        <StyledControlButton
          disabled={locked}
          minimal
          small
          text={hasLeftAxis ? `Y Axis (${capitalize(axis)})` : "Y Axis"}
        />
      </Popover>
    );
  };

  const controlButtons = [
    <Tooltip content="Advanced Chart Settings" key="advanced-settings" openOnTargetFocus={false}>
      <Button
        disabled={locked}
        minimal
        small
        icon={IconNames.SERIES_CONFIGURATION}
        onClick={() => setSettingsDialogOpen(true)}
      />
    </Tooltip>,
    renderChartControls(),
  ];

  if (type !== EWidgetType2.PIE_CHART) {
    controlButtons.push(renderXAxisControls());
    if (hasLeftAxis) controlButtons.push(renderYAxisControls(YAxisId.LEFT));
    controlButtons.push(renderYAxisControls(YAxisId.RIGHT));
  }

  return (
    <div style={{ minWidth: 0 }}>
      <EditChartDialog
        chartXAxisMax={xAxisChartState.max}
        chartXAxisMin={xAxisChartState.min}
        chartRendererConfig={chartRendererConfig}
        data={data}
        defaultYAxisBounds={defaultYAxisBounds}
        isOpen={settingsDialogOpen}
        onClose={() => setSettingsDialogOpen(false)}
        type={type}
        updateConfig={updateWidgetConfig}
        updateWidget={updateWidget}
        widget={widget}
      />
      <NormalAlignDivider style={{ margin: 0 }} />
      <Flex
        alignItems="center"
        flex="1 0 auto"
        fullWidth
        justifyContent="space-between"
        style={{ padding: "5px" }}
      >
        <OverflowList
          collapseFrom={Boundary.END}
          items={controlButtons}
          minVisibleItems={0}
          observeParents={true}
          overflowRenderer={(overflowItems) => {
            return (
              <Popover
                content={
                  <Menu>
                    {overflowItems.map((menuItem) => (
                      <MenuItem key={menuItem.key} text={menuItem.key}>
                        {menuItem.props.content}
                      </MenuItem>
                    ))}
                  </Menu>
                }
              >
                <Button icon={IconNames.MORE} minimal />
              </Popover>
            );
          }}
          style={{ minWidth: 30 }}
          visibleItemRenderer={(item) => item}
        />
      </Flex>
    </div>
  );
};

const StyledControlButton = styled(Button)`
  text-wrap: nowrap;
`;

export default ChartBottomControlRibbon;
