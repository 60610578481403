import React from "react";
import { Classes, H5, OL, UL } from "@blueprintjs/core";
const TermsOfUse = () => {
  return (
    <div>
      <p className={Classes.RUNNING_TEXT}>
        You are required to read our Terms of Use carefully before you use our website or
        application. Valsys Inc. (hereinafter referred to as &quot;our&quot;, &quot;us&quot;,
        &quot;we&quot;, &quot;Valsys&quot;, or the &quot;Company&quot;) provides its content and
        tools on its website (collectively referred to as the &quot;Company Service&quot;) to you,
        an individual user (&quot;you&quot;) for your individual usage, subject to compliance with
        the terms and conditions set forth herein.
      </p>
      <H5>Agreement</H5>
      <p className={Classes.RUNNING_TEXT}>
        By using the Company Service, you agree to be bound by our Terms of Use. If you do not agree
        to the terms and conditions contained in the Terms of Use, we do not consent to provide you
        with access to the Company Service, and you should cease your use of it.
      </p>
      <H5>Changes to the Terms of Use</H5>
      <p className={Classes.RUNNING_TEXT}>We reserve the right at any time to:</p>
      <OL>
        <li className={Classes.RUNNING_TEXT}>Change the Terms of Use; or:</li>
        <li className={Classes.RUNNING_TEXT}>
          Change the Company Service, including eliminating or discontinuing any content or feature
          of the Company Service.
        </li>
      </OL>
      <H5>Registration Data and Account Security</H5>
      <p className={Classes.RUNNING_TEXT}>
        By using the Company Service, you agree to (a) provide accurate, current and complete
        information about you as may be prompted by any registration forms on the Company Service
        (&quot;Registration Data&quot;); (b) maintain the security of your password and
        identification; (c) maintain and promptly update the Registration Data, and any other
        information you provide to us, to keep it accurate, current and complete; and (d) be fully
        responsible for all use of your account and for any actions that take place using your
        account.
      </p>
      <H5>Disclaimer</H5>
      <p className={Classes.RUNNING_TEXT}>
        Valsys Inc. is not operated by a broker, a dealer, a registered investment adviser or any
        kind of professional adviser. You understand that no content published on the Company
        Service from Valsys constitutes a recommendation that any particular security, portfolio of
        securities, transaction or investment strategy is suitable for any specific person. You
        further understand that we are not advising you personally concerning the nature, potential,
        value or suitability of any particular security, portfolio of securities, transaction,
        investment strategy or other matter. To the extent any of the content published on the
        Company Service may be deemed to be investment advice or recommendations in connection with
        a particular security, such information is impersonal and not tailored to the investment
        needs of any specific person. You understand that an investment in any security is subject
        to a number of risks and that discussions of any security published on the Company Service
        will not contain a list or description of relevant risk factors. In addition, please note
        that some of the stocks as to which content is published on the Company Service may have a
        low market capitalization and/or insufficient public float. Such stocks are subject to more
        risk than stocks of larger companies, including greater volatility, lower liquidity and less
        publicly available information. Postings or content on the Company Service which may or may
        not be deemed by you to be recommendations may have an effect on their stock prices. You
        understand that performance data is supplied by sources believed to be reliable, that the
        calculations herein are made using such data, and that such calculations are not guaranteed
        by these sources or any other person or entity, and may not be complete.
      </p>
      <p className={Classes.RUNNING_TEXT}>
        All content on the Company Service is presented only as of the date published or indicated,
        and may be superseded by subsequent market events or for other reasons. In addition, you are
        responsible for setting the cache settings on your browser to ensure you are receiving the
        most recent data.
      </p>
      <H5>Not an Investment Recommendation or Professional Advice</H5>
      <p className={Classes.RUNNING_TEXT}>
        Valsys Inc. is not operated by a broker, a dealer, a registered investment adviser or any
        kind of professional adviser. The Company Service is not intended to provide tax, legal,
        insurance or investment advice, and nothing on the Company Service should be construed as an
        offer to sell, a solicitation of an offer to buy, or a recommendation for any security by
        Valsys or any third party. You alone are solely responsible for determining whether any
        investment, security or strategy, or any other product or service, is appropriate or
        suitable for you based on your investment objectives and personal and financial situation.
        You should consult an attorney or tax professional regarding your specific legal or tax
        situation.
      </p>
      <p className={Classes.RUNNING_TEXT}>
        You specifically acknowledge that Valsys will in no way be held liable for losses or gains
        arising out of use of the content or user provided content (&quot;User Content&quot;) of any
        type on the Company Service. You acknowledge that your investment decisions/recommendations
        are made entirely at your election and that Valsys content or User Content is solely one
        additional source of information in assisting you make those determinations. You similarly
        acknowledge that you will not hold any other Valsys User liable for losses or gains arising
        out of Content or User Content of any type on the Company Service.
      </p>
      <H5>Copyright</H5>
      <p className={Classes.RUNNING_TEXT}>
        The Company Service and the content contained herein, as well as all copyrights, are the
        property of Valsys. You may access and use the content, and download and/or print out copies
        of any content from the Company Service, solely for your personal, non-commercial use. You
        acknowledge that you do not acquire any ownership rights by using the Company Service.
      </p>
      <H5>User Conduct</H5>
      <p className={Classes.RUNNING_TEXT}>
        You may not republish, upload, post, transmit or distribute content from the Company Service
        to online bulletin boards, message boards, newsgroups, chat rooms, or in other any manner,
        without our prior written permission. Modification of the content or use of the content for
        any purpose other than your own personal, non-commercial use is a violation of our copyright
        and other proprietary rights and can subject you to legal liability.
      </p>
      <p className={Classes.RUNNING_TEXT}>
        Specifically, you will not submit information that would be a violation of your
        employer&apos;s company policies, nor will you submit information that violates any
        confidentiality agreements, insider trading regulations, or SEC regulations.
      </p>
      <p className={Classes.RUNNING_TEXT}>
        In addition, in connection with your use of the Company Service, you agree not to:
      </p>
      <UL>
        <li className={Classes.RUNNING_TEXT}>
          Restrict or inhibit any other visitor from using the Company Service, including, without
          limitation, by means of &quot;hacking&quot; or defacing any portion of the Company
          Service.
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Use the Company Service for any unlawful or unauthorized purpose.{" "}
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Modify, adapt, sublicense, translate, sell, reverse engineer, decompile or disassemble any
          portion of the Company Service.
        </li>
        <li className={Classes.RUNNING_TEXT}>
          &quot;Frame&quot; or &quot;mirror&quot; any part of the Company Service without our prior
          written authorization.
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Use any robot, spider, site search/retrieval application, or other manual or automatic
          device or process to download, retrieve, index, &quot;data mine,&quot; or in any way
          reproduce or circumvent the navigational structure or presentation of the Company Service
          or its contents.
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Harvest or collect information about visitors to the Company Service without their express
          consent.
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Distribute or otherwise furnish the Valsys content to any person (other than a team
          member) or firm without prior written approval from Valsys.
        </li>
      </UL>
      <p className={Classes.RUNNING_TEXT}>
        You also agree to comply with all applicable laws, rules and regulations in connection with
        your use of the Company Service and the content made available therein.
      </p>
      <H5>Rules of Conduct</H5>
      <p className={Classes.RUNNING_TEXT}>
        By posting any content, modifying historical or forecast data or engaging in any other form
        of content upload (individually or collectively “Contents”) on the Company Service, You
        agree to:
      </p>
      <UL>
        <li className={Classes.RUNNING_TEXT}>
          Warrant and represent that you are the owner of your Contents or you have all rights
          necessary to upload, post, transmit and otherwise use any such Contents for your intended
          purpose.
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Post Contents in both tone and content that contribute in a positive and high quality
          manner to the substantive exchange of information and the subject matter of the Company
          Service.
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Automatically grant - yourself or because you have the unrestricted right from the actual
          owner – Valsys a royalty-free, perpetual, non-exclusive and fully transferable and
          sublicensable right to use, reproduce, modify, adapt, publish, translate, create
          derivative works from, distribute, perform and display the Contents you post (in whole or
          in part) worldwide and/or to incorporate any one of those Contents in other works now or
          in the future, with or without attribution of such content directly to you.
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Waive any and all rights against Valsys and hold Valsys harmless in connection with any
          claims relating to any action taken by Valsys as part of its investigation of a suspected
          violation or result of its conclusion that a violation of these Terms of Use has occurred,
          including but not limited to the removal of posts from the Company Service or a suspension
          or termination of your access to the Company Service;
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Maintain and promptly update your registration data to keep it true, accurate, current and
          complete.
        </li>
      </UL>
      <p className={Classes.RUNNING_TEXT}>You agree not to:</p>
      <UL>
        <li className={Classes.RUNNING_TEXT}>
          Post any Contents that you either know or should know is false, deceptive or misleading,
          or misrepresent or deceive others as to the source, accuracy, integrity or completeness of
          any Contents you post.
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Post any Contents that is unlawful, harmful or injurious to others, threatening, abusive,
          offensive, harassing, derisive, defamatory, vulgar, obscene, libelous, hatefully,
          racially, ethnically or otherwise tortious or objectionable.
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Post any Contents that does or may invade the privacy or violate or infringe on any rights
          of others, including, without limitation, copyrights and other intellectual property
          rights.
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Impersonate any person or entity, falsely or deceptively state, infer or otherwise
          misrepresent your affiliation with or connection to any person or entity.
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Post any Contents which, either the act of posting or the Contents itself, you do not have
          a right to do under any law, regulation or order of any court, or as a result of an
          employment, contractual, fiduciary or other legal obligation or relationship.
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Post any advertising, promotional materials, so called &quot;chain letters,&quot;
          &quot;pyramid&quot; or other schemes or invitations to participate in these or any other
          form of solicitation or promotion.
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Post any non-public or otherwise restricted, confidential or proprietary information
          without authorization.
        </li>
        <li className={Classes.RUNNING_TEXT}>
          Violate any local, state, national or international law, regulation or order of any court,
          including but not limited to regulations of the U.S. Securities and Exchange Commission,
          any rules of any securities exchange, including without limitation, the New York Stock
          Exchange, the American Stock Exchange or The Nasdaq Stock Market.
        </li>
      </UL>
      <H5>Deleting Content</H5>
      <p className={Classes.RUNNING_TEXT}>
        Valsys has the right in its sole discretion, to edit, move, delete, and/or remove any
        Contents posted on its Company Service at any time. Without limiting the foregoing, Valsys
        has the right to delete any Contents that it believes, in its sole discretion, does or may
        violate the Terms of Use.
      </p>
      <H5>Responsibility for Content</H5>
      <p className={Classes.RUNNING_TEXT}>
        Each individual who post Contents is solely responsible for her or his own acts, including
        the content, context or information in the Contents he or she posts. This means that each
        individual, and not Valsys, is entirely responsible for anything and everything she or he
        posts. Valsys does not and does not intend to pre-screen any Contents posted on its Company
        Service and Valsys cannot and does not guarantee the accuracy, integrity or quality of
        anything that may appear on its Company Service.
      </p>
      <H5>Disclaimer of Warranties</H5>
      <p className={Classes.RUNNING_TEXT}>
        THE SITE, AND ANY PRODUCT OR SERVICE OBTAINED OR ACCESSED THROUGH THE SITE, IS PROVIDED
        &quot;AS IS&quot; AND WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESS OR
        IMPLIED. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, VALSYS, ITS OFFICERS, DIRECTORS,
        EMPLOYEES, AFFILIATES, SUPPLIERS, ADVERTISERS, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS,
        IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE AND
        NON-INFRINGEMENT, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
        PURPOSE, AND ALL WARRANTIES RELATING TO THE ADEQUACY, ACCURACY OR COMPLETENESS OF ANY
        INFORMATION ON OUR SITE.
      </p>
      <p className={Classes.RUNNING_TEXT}>
        VALSYS AND ITS AFFILIATES, SUPPLIERS, AGENTS AND SPONSORS DO NOT WARRANT THAT YOUR USE OF
        THE SITE WILL BE UNINTERRUPTED, ERROR-FREE OR SECURE, OR THAT THE SITE OR THE SERVER(S) ON
        WHICH THE SITE IS HOSTED ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ASSUME TOTAL
        RESPONSIBILITY AND RISK FOR YOUR USE OF THE SITE AND YOUR RELIANCE THEREON. NO OPINION,
        ADVICE, OR STATEMENT OF VALSYS OR ITS AFFILIATES, SUPPLIERS, AGENTS, MEMBERS, OR VISITORS,
        WHETHER MADE ON THE SITE OR OTHERWISE, SHALL CREATE ANY WARRANTY.
      </p>
      <H5>Limitations of Liability</H5>
      <p className={Classes.RUNNING_TEXT}>
        NEITHER VALSYS NOR ITS AFFILIATES AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES,
        SUPPLIERS, ADVERTISERS, AGENTS AND SPONSORS ARE LIABLE FOR ANY INDIRECT, INCIDENTAL,
        CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES UNDER ANY CONTRACT, NEGLIGENCE,
        STRICT LIABILITY OR OTHER THEORY ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE AND/OR
        ANY CONTENT CONTAINED THEREIN, OR ANY PRODUCT OR SERVICE USED OR PURCHASED THROUGH VALSYS.
        YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE IS TO STOP USING IT. THE SOLE AND
        EXCLUSIVE MAXIMUM LIABILITY TO VALSYS FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER
        IN CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE), OR OTHERWISE) SHALL BE THE
        TOTAL AMOUNT PAID TO US BY YOU, IF ANY, FOR ACCESS TO THE SITE.
      </p>
      <H5>Indemnification</H5>
      <p className={Classes.RUNNING_TEXT}>
        As a condition of your use of the Company Service, you agree to indemnify, defend and hold
        us, our officers, directors, employees, agents and representatives harmless from and against
        any and all claims, damages, losses, costs (including reasonable attorneys&apos; fees), or
        other expenses that arise directly or indirectly out of or from (a) your violation of the
        Terms Of Use; (b) your use of the Company Service; or (c) your violation of the rights of
        any third party.
      </p>
      <H5>Termination</H5>
      <p className={Classes.RUNNING_TEXT}>
        You understand and agree that Valsys may, under any circumstances and without prior notice
        to you, terminate your access to and use of the Company Service.
      </p>
      <H5>Copyright Policy</H5>
      <p className={Classes.RUNNING_TEXT}>
        Valsys respects the intellectual property of others, and we ask our users to do the same.
        Valsys may, in appropriate circumstances and at its discretion, terminate the account or
        access of users who infringe the intellectual property rights of others.
      </p>
      <H5>Miscellaneous</H5>
      <p className={Classes.RUNNING_TEXT}>
        The Company Service is directed solely to individuals residing in jurisdictions in which
        provision of the Company Service&apos;s content is legal. We make no representation that
        materials provided on the Company Service are appropriate or available for use in other
        locations. Those who choose to access the Company Service from other locations do so on
        their own initiative and at their own risk, and are responsible for compliance with local
        laws, if and to the extent applicable. We reserve the right to limit the availability of the
        Company Service to any person, geographic area, or jurisdiction we so desire, at any time
        and in our sole discretion, and to limit the quantities of any such service or product that
        we provide.
      </p>
      <p className={Classes.RUNNING_TEXT}>
        The Terms Of Use together with all Valsys policies referred to herein, constitutes the
        entire agreement between you and Valsys relating to your use of the Company Service and
        supersedes and any all prior or contemporaneous written or oral agreements on that subject
        between us.
      </p>
    </div>
  );
};

export default TermsOfUse;
