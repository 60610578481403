if (!window.__env__) window.__env__ = process.env;

const URL = window.__env__.REACT_APP_URL;
const SOCKET_URL = window.__env__.REACT_APP_SOCKET_URL;
export const IMPORT_URL = `${URL}import/`;

export const CREATE_SOCKET_ROUTE = `${SOCKET_URL}modeling/connect/create`;
export const EDIT_SOCKET_ROUTE = `${SOCKET_URL}modeling/connect/edit`;

// MODEL HISTORY
export const MODEL_HISTORY_ROUTE = `${URL}users/filter-history-with-fields`;
export const SHARED_MODELS_ROUTE = `${URL}users/shared-history`;
export const EDIT_MODEL_PROPERTIES_ROUTE = `${URL}modeling/edit/model-properties`;

// USER
export const FETCH_USER_INFORMATION_ROUTE = `${URL}users/information`;
export const UPDATE_TERMS_AND_CONDITIONS_STATUS_ROUTE = `${URL}users/accepted-terms`;
export const DELETE_MODEL_ROUTE = `${URL}users/models`;
export const SHARE_MODELS_ROUTE = `${URL}users/share-model`;
export const SEARCH_USERS_ROUTE = `${URL}users/search`;
export const MODEL_PERMISSIONS_ROUTE = `${URL}users/model-permissions`;

// TODO: this should be an admin only request but this level of permissions hasn't been implemented yet
export const FETCH_USERS_ROUTE = `${URL}users/admin/users`;

// GROUPS/SIM
export const FETCH_MODEL_GROUPS_ROUTE = `${URL}users/groups`; // Gets a list of all groups available to the user.
export const CREATE_DELETE_MODEL_GROUP_ROUTE = `${URL}users/group`;
export const MODEL_GROUP_UPDATE_ROUTE = `${URL}users/update-group`;

export const GROUP_METADATA_ROUTE = `${URL}modeling/simulations/group-metadata`;
export const FETCH_GROUP_MODELS_ROUTE = `${URL}modeling/simulations/group-data`;
export const TARGET_FX_ROUTE = `${URL}modeling/simulations/target-formula`;

// IMPORT
export const FETCH_USER_IMPORTS_ROUTE = `${IMPORT_URL}audit/user`;
export const POLL_SPREADSHEET_UPLOAD_PROGRESS_ROUTE = `${IMPORT_URL}spreadsheet/status`;
export const IMPORT_SPREADSHEET_ROUTE = `${IMPORT_URL}spreadsheet`;
export const UPLOAD_SHEET_ROUTE = `${IMPORT_URL}spreadsheet/2`;
export const FETCH_PARSED_SHEET_ROUTE = `${IMPORT_URL}spreadsheet/sheet`;
export const FETCH_SPREADSHEET_CONFIGURATION = `${IMPORT_URL}spreadsheet/configuration`;
export const FETCH_MODULES_ROUTE = `${IMPORT_URL}spreadsheet/modules`;
export const MODULE_ROUTE = `${IMPORT_URL}spreadsheet/module/`;
export const PREVIEW_MODULE_ROUTE = `${IMPORT_URL}preview/module`;
export const PREVIEW_MODEL_ROUTE = `${IMPORT_URL}preview/model`;
export const FETCH_MODULE_TIME_SERIES_ROUTE = `${IMPORT_URL}timeseries/`;
export const FETCH_AVAILABLE_TIME_SERIES_FORMATS_ROUTE = `${IMPORT_URL}timeseries/formats`;
export const VALIDATE_TIME_SERIES_ROUTE = `${IMPORT_URL}timeseries/validator`;
export const EDIT_MODULE_ROUTE = `${IMPORT_URL}module/edit`;
export const GENERATE_MODEL_ROUTE = `${IMPORT_URL}generate-model`;

// NEW MODEL
export const GET_MODEL_TEMPLATES_ROUTE = `${URL}users/model-templates`;
export const GET_COMPANIES_ROUTE = `${URL}templates/available-companies`;
export const GET_COVERAGE_COMPANIES_ROUTE = `${URL}templates/coverage-companies`;

export const GET_SUPPORTED_INDUSTRIES_ROUTE = `${URL}templates/available-industries`;
export const GET_SUPPORTED_GEOGRAPHIES_ROUTE = `${URL}templates/available-geographies`;
export const GET_SUPPORTED_CURRENCIES_ROUTE = `${URL}templates/available-currencies`;
export const GET_SUPPORTED_EXCHANGES_ROUTE = `${URL}templates/available-exchanges`;

export const FETCH_COMPANY_CONFIG_OPTIONS_ROUTE = `${URL}templates/configuration`;
export const COPY_MODEL_ROUTE = `${URL}modeling/edit/copy-model`;

export const HISTORICAL_SHARE_PRICE_ROUTE = `${URL}templates/historical-share-price`;
//TODO: Potentially reinclude logos.
export let FETCH_LOGO_ROUTE: string;

// MODEL
export const PULL_MODEL_ROUTE = `${URL}modeling/view/model`;
export const GET_MODEL_MULTIPLES_TABLE = `${URL}modeling/view/multiples`;
export const GET_MODEL_REVISIONS = `${URL}modeling/track/prepareDataSanitization`;
export const DELETE_MODEL_REVISIONS = `${URL}modeling/track/revisions`;

export const EXPORT_TO_EXCEL_ROUTE = `${URL}modeling/export`;
export const FETCH_WIKI_LINK_ROUTE = `${URL}modeling/wiki-links`;

// VIEW MODEL
export const CASE_INFORMATION_ROUTE = `${URL}modeling/case-information`;
export const SUMMARY_ROUTE = `${URL}modeling/summary`;
export const FCFF_ROUTE = `${URL}modeling/fcff`;
export const TERMINAL_VALUE_ROUTE = `${URL}modeling/terminal-valuation`;
export const SENSITIVITY_ANALYSES_ROUTE = `${URL}modeling/sensitivities`;
export const IMPLIED_FORWARD_MULTIPLES_ROUTE = `${URL}modeling/multiples`;
export const UPDATE_SENSITIVITY_ROUTE = `${URL}modeling/sensitivity`;

// VIEW PREVIOUS VERSIONS
export const FETCH_MODEL_VERSIONS_ROUTE = `${URL}modeling/versioning/history`;
export const PULL_MODEL_PREVIOUS_VERSION_ROUTE = `${URL}modeling/versioning/model`;

// DATA ENTRY
export const EDIT_MODULE_NAME_ROUTE = `${URL}modeling/edit/module`;
export const FETCH_KEY_RATIOS_ROUTE = `${URL}modeling/key-ratios`;
export const FETCH_QUICK_CALCULATIONS_ROUTE = `${URL}modeling/standard-items`;
export const ADD_QUICK_CALCULATIONS_ROUTE = `${URL}modeling/quick-calculation`;
export const GROUP_COLUMNS_ROUTE = `${URL}modeling/group-cols`;
export const UNGROUP_COLUMNS_ROUTE = `${URL}modeling/ungroup-cols`;
export const GROUP_ROWS_ROUTE = `${URL}modeling/group-rows`;
export const UNGROUP_ROWS_ROUTE = `${URL}modeling/ungroup-rows`;
export const FETCH_UNDO_HISTORY_ROUTE = `${URL}modeling/undo-history`;

// CONFIGURATIONS
export const PULL_CONFIG_ROUTE = `${URL}configurations/data/pull`;
export const PUSH_CONFIG_ROUTE = `${URL}configurations/data/save`;
export const DELETE_CONFIG_ROUTE = `${URL}configurations/data/delete`;

// CONFIGURATIONS V2
export const GET_CONFIGURATION_ROUTE = `${URL}users/configurations/pull`;
export const POST_CONFIGURATION_ROUTE = `${URL}users/configurations/save`;
export const POST_CONFIGURATION_DEFAULT_ROUTE = `${URL}users/configurations/default/save`;

// CONFIGURATIONS V3
export const GET_CONFIGS = `${URL}configs/list`;
export const DASHBOARD_ROUTE = `${URL}configs/dashboard`;
export const DASHBOARD_LINK_ROUTE = `${URL}configs/dashboard-link-views`;
export const DASHBOARD_UNLINK_ROUTE = `${URL}configs/dashboard-link-view`;
export const WATCHLIST_CONFIG_ROUTE = `${URL}configs/watchlist`;
export const FETCH_WATCHLISTS_CONFIG_ROUTE = `${URL}configs/watchlists`;
export const FETCH_PORTFOLIOS_CONFIG_ROUTE = `${URL}configs/portfolios`;
export const REORDER_VIEWS_ROUTE = `${URL}configs/view/reorder`;
export const COPY_VIEW_ROUTE = `${URL}configs/view/copy`;
export const COPY_WIDGET_ROUTE = `${URL}configs/widget/copy`;
export const GET_USER_CONFIGS_ROUTE = `${URL}configs/user`;

export const GET_WIDGETS = `${URL}configs/widgets`;
export const WIDGET_ROUTE = `${URL}configs/widget`;
export const VIEW_ROUTE = `${URL}configs/view`;
export const WIDGET_LINK_ROUTE = `${URL}configs/view-link-widget`;
export const GET_MODEL_CONFIG = `${URL}configs/model`;

export const UPDATE_TAGS_ROUTE = `${URL}modeling/edit/update-tags`;

// SIMULATION
export const GET_SIMULATIONS_COLLECTIONS_DATA = `${URL}modeling/simulations/collections-data`;
export const GET_SIMULATIONS_COLLECTIONS_CONFIG = `${URL}modeling/simulations/configuration`;
export const GET_SIMULATIONS_COLLECTIONS_TARGET_ITEMS = `${URL}modeling/simulations/target-items`;
export const GET_SIMULATIONS_COLLECTIONS_TARGET_PERIODS = `${URL}modeling/simulations/target-periods`;
export const GET_SIMULATION_OUTPUTS_ROUTE = `${URL}modeling/simulations/output-variables`;
export const SIMULATION_ROUTE = `${URL}modeling/simulations/simulation`;
export const SIMULATION_CONTROL_PANEL_CONFIG_ROUTE = `${URL}modeling/simulations/control-panel/configuration`;
export const SIMULATION_CONTROL_PANEL_ROUTE = `${URL}modeling/simulations/control-panel`;

// SOURCES
export const PULL_SOURCE_ROUTE = `${URL}scanner/pull-source`;

// TEMPLATES
export const FETCH_MODULE_TEMPLATES_ROUTE = `${URL}users/saved-modules`;
export const SAVE_MODULE_TEMPLATE_ROUTE = `${URL}modeling/save-module`;
export const EDIT_TEMPLATE_ROUTE = `${URL}modeling/edit-template`;
export const ADD_TEMPLATE_LINE_ITEM_ROUTE = `${URL}modeling/add-item`;

export const TEMPLATE_TICKERS_ROUTE = `${URL}templates/available-companies`;
export const DATA_SOURCES_ROUTE = `${URL}templates/datasources`;
export const IDENTIFIERS_ROUTE = `${URL}templates/identifiers`;
export const PULL_TEMPLATE_ROUTE = `${URL}templates/template`;
export const UPDATE_TEMPLATE_ROUTE = `${URL}templates/update-template`;

export const COMPS_ROUTE = `${URL}templates/comparables`;
export const HISTORICAL_VALUATION_ROUTE = `${URL}templates/historical-valuation`;

// VSL
export const VSL_ROUTE = `${URL}modeling/vsl/query`;
export const SELECTORS_ROUTE = `${URL}modeling/vsl/selectors`;
export const FETCH_SELECTOR_TYPES = `${URL}modeling/vsl/selectors/types`;

// COLLECTIONS
export const FETCH_WATCHLISTS_ROUTE = `${URL}collections/watchlist/list`;
export const WATCHLIST_ROUTE = `${URL}collections/watchlist`;
export const FETCH_PORTFOLIOS_ROUTE = `${URL}collections/portfolio/list`;
export const PORTFOLIO_ROUTE = `${URL}collections/portfolio`;

export enum EStatus {
  success = "success",
  failure = "failure",
  failed = "failed",
  finished = "finished",
}

export enum EMethods {
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
}

// STATUS types we can receive from the server
export const Status = {
  SUCCESS: "success",
  FAILURE: "failure",
  FAILED: "failed",
  FINISHED: "finished",
};

export const appStates = {
  // App is working fine outside a model context
  NORMAL: "NORMAL",
  // App is connected to a socket in a model context
  CONNECTED: "CONNECTED",
  // App is attempting to open a socket to the back end
  CONNECTING: "CONNECTING",
  // App has failed to connect to the server
  CONNECTION_ERROR: "CONNECTION ERROR",
  // App is has been deliberately disconnected
  DISCONNECTED: "DISCONNECTED",
  // App is attempting to open a socket after failure
  RETRYING: "RETRYING",
  // Something has gone wrong and the app needs to resync with the server
  RESYNCING: "RESYNCING",
  // There's no internet connection
  OFFLINE: "OFFLINE",
};

// Messages to use in a toast or display in the status indicator.
export const socketMessages = {
  CONNECTED: "Connected to server!",
  CONNECTING: "Establishing communication with the server...",
  "CONNECTION ERROR": "Unable to connect to server!",
  DISCONNECTED: "Gracefully disconnected from server!",
  RETRYING: "Connection error, retrying...",
  RESYNCING: "Resyncing with server, please wait...",
};

export const errorMessages = {
  SOMETHING_WENT_WRONG: "Something went wrong! Please try again!",
  INTERNAL_SERVER_ERROR: "Internal Server Error. Valsys support has been notified.",
  CANT_ESTABLISH_CONNECTION: "Couldn't establish connection with the server.",
  SERVICE_ERROR: "Service error. Please try again later.",
  INVALID_RESPONSE: "Invalid response received. Please contact support.",
  INVALID_RESPONSE_DATA: "Invalid response data.",
};
