import {
  AnchorButton,
  Boundary,
  Button,
  Icon,
  Menu,
  MenuItem,
  OverflowList,
  Popover,
  Position,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  Flex,
  NormalAlignDivider,
  StyledHTMLSelectSmall,
} from "components/utility/StyledComponents";
import { capitalize } from "lodash";
import React from "react";
import { TableWidgetConfig } from "types/widget";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { updateNestedArray } from "utils/vsl";
import { reorder } from "utils/generic";
import isFunction from "lodash.isfunction";
import { FontSize, FontSizes } from "constants/uiConstants";

interface TableBottomControlRibbonProps {
  columns: string[];
  enableColumnEditing?: boolean;
  fontSize: FontSize;
  layoutMode: "fixed" | "auto";
  numberOfEntries: number;
  pinnedColumns?: number;
  tableRendererConfig?: TableWidgetConfig;
  updateConfig?: (details: Partial<TableWidgetConfig>) => void;
  updateFontSize: (nextFontSize: FontSize) => void;
  updatePinnedColumns?: (pinnedColumns: number) => void;
}

const TableBottomControlRibbon: React.FC<TableBottomControlRibbonProps> = ({
  columns,
  enableColumnEditing = true,
  fontSize,
  layoutMode,
  numberOfEntries,
  tableRendererConfig,
  updateConfig,
  updateFontSize,
}) => {
  const cols = columns
    .map((col) => {
      const colConfig = tableRendererConfig?.columns?.find((cfgCol) => cfgCol.id === col);
      return { id: col, header: col, ...colConfig };
    })
    .sort((a, b) => (a?.order || 0) - (b?.order || 0));

  const handleDragEnd = (dropResult: DropResult) => {
    if (dropResult.destination && isFunction(updateConfig)) {
      const reorderedColumns = reorder(
        cols,
        dropResult.source.index,
        dropResult.destination.index
      ).map((col, idx) => {
        return { ...col, order: idx };
      });
      updateConfig({
        columns: reorderedColumns,
      });
    }
  };

  const columnManagerButton = (
    <Popover
      disabled={!enableColumnEditing || !isFunction(updateConfig)}
      key="Configure Columns"
      minimal
      position={Position.TOP_LEFT}
      content={
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="columnsMenu" direction="vertical">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Menu>
                  {cols.map((column, idx) => {
                    const columnConfig = tableRendererConfig?.columns?.find(
                      (col) => col.id === column.id
                    );
                    return (
                      <Draggable key={column.id} draggableId={column.id} index={idx}>
                        {(provided) => (
                          <Flex
                            flexDirection="row"
                            justifyContent="space-between"
                            fullWidth
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              left: "auto !important",
                              top: "auto !important",
                            }}
                          >
                            <Icon
                              icon={IconNames.DRAG_HANDLE_VERTICAL}
                              {...provided.dragHandleProps}
                            />
                            <div style={{ marginLeft: "5px", marginRight: "auto", height: "100%" }}>
                              {column.id}
                            </div>
                            <Tooltip
                              content={columnConfig?.hidden ? "Show column" : "Hide column"}
                              position={Position.RIGHT}
                            >
                              <AnchorButton
                                minimal
                                icon={columnConfig?.hidden ? IconNames.EYE_OFF : IconNames.EYE_OPEN}
                                onClick={() => {
                                  const nextConfig = updateNestedArray(
                                    tableRendererConfig || {},
                                    "columns",
                                    {
                                      ...(columnConfig || {
                                        id: column.id,
                                        header: column.header,
                                      }),
                                      hidden: !columnConfig?.hidden,
                                    },
                                    "id",
                                    idx
                                  );
                                  if (isFunction(updateConfig)) {
                                    updateConfig(nextConfig);
                                  }
                                }}
                              />
                            </Tooltip>
                          </Flex>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Menu>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      }
    >
      <Tooltip
        content={
          enableColumnEditing && isFunction(updateConfig)
            ? "Configure table columns"
            : "Column editing unavailable"
        }
        hoverOpenDelay={1000}
      >
        <AnchorButton
          disabled={!enableColumnEditing || !isFunction(updateConfig)}
          icon={IconNames.ColumnLayout}
          small
          text="Edit Columns"
        />
      </Tooltip>
    </Popover>
  );

  const controlButtons = [
    columnManagerButton,
    <Tooltip
      content="Layout Mode"
      fill
      hoverOpenDelay={1000}
      key="Layout Mode"
      onClose={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Button
        key="Layout Mode"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const nextLayoutMode = layoutMode === "fixed" ? "auto" : "fixed";
          if (isFunction(updateConfig)) {
            updateConfig({ layoutMode: nextLayoutMode });
          }
        }}
        small
        text={capitalize(layoutMode)}
      />
    </Tooltip>,
    <Tooltip content="Font Size" hoverOpenDelay={1000} key="Font Size">
      <StyledHTMLSelectSmall
        options={Object.values(FontSizes).map((fontSize) => ({ label: fontSize, value: fontSize }))}
        onChange={(e) => updateFontSize(e.currentTarget.value as FontSize)}
        value={fontSize}
      />
    </Tooltip>,
  ];

  return (
    <div style={{ minWidth: 0 }}>
      <NormalAlignDivider style={{ margin: 0 }} />
      <Flex
        alignItems="center"
        flex="1 0 auto"
        fullWidth
        columnGap={5}
        justifyContent="space-between"
        style={{ padding: "5px" }}
      >
        <OverflowList
          collapseFrom={Boundary.END}
          items={controlButtons}
          minVisibleItems={1}
          observeParents={true}
          overflowRenderer={(overflowItems) => {
            return (
              <Popover
                content={
                  <Menu>
                    {overflowItems.map((menuItem) => {
                      return (
                        <MenuItem
                          disabled // disabled to prevent double highlight coming from the MenuItem
                          key={menuItem?.key}
                          role="menuitem"
                          shouldDismissPopover={false}
                          text={menuItem}
                        />
                      );
                    })}
                  </Menu>
                }
              >
                <Button icon={IconNames.MORE} minimal small />
              </Popover>
            );
          }}
          style={{ columnGap: 10, minWidth: 30 }}
          visibleItemRenderer={(item) =>
            item ? <span style={{ flex: "0 0 auto" }}>{item}</span> : <span />
          }
        />
        <Flex flex="0 1 auto" flexWrap="nowrap" justifyContent="flex-end">
          <Tag minimal>
            <strong>{numberOfEntries}</strong> {numberOfEntries === 1 ? "entry" : "entries"}{" "}
            retrieved
          </Tag>
        </Flex>
      </Flex>
    </div>
  );
};

export default TableBottomControlRibbon;
