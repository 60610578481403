import camelCase from "lodash.camelcase";
import { TVSTableData } from "types/dashboard";
import { IFact, ILineItem } from "types/model";
import { f } from "./formatter";
import { differenceInDays } from "date-fns";
import range from "lodash.range";
import { Scenarios } from "constants/appConstants";

export interface IValuationSheetCases {
  base: Record<string, TVSTableData>;
  upside: Record<string, TVSTableData>;
  downside: Record<string, TVSTableData>;
  consensus: Record<string, TVSTableData>;
}

export interface IVSTable {
  name: string;
  header: boolean;
  headerLabel?: boolean;
  tags: string[];
  altTagsIdxs?: number[];
}

export const cppTableWidths = [150, 100];

// utility that builds the data [][] based on the array of tags
export const fetchTagsData = (
  tags: string[],
  periods: number[],
  map: Record<string, string[]>,
  lineItems: Record<string, ILineItem>,
  facts: Record<string, IFact>,
  rowHeaders?: string[]
): TVSTableData => {
  const data: TVSTableData = [];
  tags.forEach((tag, idx) => {
    const lineItemId = map?.[tag]?.[0];
    if (!lineItemId) {
      const header = [rowHeaders?.[idx] || tag];
      data.push([...header, ...periods.map(() => "NA")]);
      return;
    }
    const newDataRow = [];
    const lineItem: ILineItem = lineItems[lineItemId];
    newDataRow.push(rowHeaders?.[idx] || lineItem.name);
    periods.forEach((period) => {
      lineItem.facts.forEach((factId) => {
        const fact = facts[factId];
        if (fact.period === period) {
          if (!fact.value) newDataRow.push("NA");
          else newDataRow.push(f(fact));
          return;
        }
      });
    });
    data.push(newDataRow);
  });
  return data;
};

export const fetchTagData = (
  tag: string,
  period: number,
  map: Record<string, string[]>,
  lineItems: Record<string, ILineItem>,
  facts: Record<string, IFact>,
  skipFormatting?: boolean
): string => {
  let value = "NA";
  const lineItemId = map?.[tag]?.[0];
  if (!lineItemId) return value;
  const lineItem: ILineItem = lineItems[lineItemId];

  lineItem.facts.forEach((factId) => {
    const fact = facts[factId];
    if (fact.period === period && fact.value) value = skipFormatting ? fact.value : f(fact);
  });
  return value;
};

export const buildScenarios = (
  tables: IVSTable[],
  header: number[],
  map: Record<string, string[]>,
  lineItems: Record<string, ILineItem>,
  facts: Record<string, IFact>,
  rowHeaders?: string[][]
): IValuationSheetCases => {
  const result: Record<string, Record<string, TVSTableData>> = {};
  Scenarios.forEach((scenario) => {
    result[scenario.toLowerCase()] = {};
    tables.forEach((table, tableIdx) => {
      const data = fetchTagsData(
        table.tags.map((tag, idx) => {
          const scenarioTag = table.altTagsIdxs?.includes(idx)
            ? ` {Valuation} (${scenario})`
            : `, Valuation (${scenario})`;
          return tag + scenarioTag;
        }),
        header,
        map,
        lineItems,
        facts,
        rowHeaders?.[tableIdx]
      );
      result[scenario.toLowerCase()][camelCase(table.name)] = table.header
        ? [[table.headerLabel ? table.name : "", ...header], ...data]
        : [null, ...data];
    });
  });

  return result as unknown as IValuationSheetCases;
};

export const getEffectivePeriodRange = (
  startPeriod: number,
  map: Record<string, string[]>,
  lineItems: Record<string, ILineItem>,
  facts: Record<string, IFact>
) => {
  const datesBetween = fetchTagsData(
    ["Expected Return Date {Valuation} (Base)"],
    [startPeriod, startPeriod + 1],
    map,
    lineItems,
    facts
  );

  // if can't get dates from model, do default
  if (!datesBetween[0]?.[1] || !datesBetween[0]?.[2])
    return range(startPeriod + 1, startPeriod + 6, 1);

  const daysDifference = Math.abs(
    differenceInDays(new Date(datesBetween[0]?.[1] ?? ""), new Date(datesBetween[0]?.[2] ?? ""))
  );
  let effectiveStartPeriod = 1;
  let effectiveEndPeriod = 6;
  if (daysDifference < 110) {
    effectiveStartPeriod += 1;
    effectiveEndPeriod += 1;
  }

  return range(startPeriod + effectiveStartPeriod, startPeriod + effectiveEndPeriod, 1);
};
