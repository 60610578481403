import { put, select } from "redux-saga/effects";

import { DataEntryEditMode } from "constants/dataEntryUIConstants";
import {
  updateEditModeReducer,
  updateFocusedCell as updateFocusedCellAction,
  updateFocusedCellReducer,
  updateFormulaRedux,
  updateSelectedRegionsReducer,
} from "actions/dataEntryUIActions";
import {
  currentFormulaSelector,
  editModeSelector,
  formulaInsertDataSelector,
  selectedRegionsSelector,
} from "selectors/dataEntryUISelectors";
import { focusedCellSelector } from "selectors/dataEntrySelectors";
import { editFormula } from "actions/dataEntryActions";
import { isSingleCellSelection } from "utils/modelGrid";
import { currentCaseDataSelector, currentModuleSelector } from "selectors/modelSelectors";
import { equalRegions } from "../utils/modelGrid";

/**
 * Update edit mode
 * Depending on the mode it might need to make changes to insert data
 */
export function* updateEditMode(action) {
  const { mode, commit } = action.payload;
  const editMode = yield select(editModeSelector);
  const currentModule = yield select(currentModuleSelector);
  const currentCase = yield select(currentCaseDataSelector);
  if (editMode === mode) return;
  switch (mode) {
    case DataEntryEditMode.NORMAL: {
      if (commit) {
        // Select the required data
        const currentFormula = yield select(currentFormulaSelector);
        const { fact } = yield select(formulaInsertDataSelector);
        const { formula: oldFormula, uid } = fact;

        if (currentFormula !== oldFormula) {
          const newFact = {
            uid,
            formula: currentFormula,
          };
          yield put(editFormula(currentCase.uid, fact.moduleUid, [newFact]));
        }
      }
      yield put(updateEditModeReducer(mode, {}, currentModule.uid));
      break;
    }
    case DataEntryEditMode.FORMULA:
    case DataEntryEditMode.INSERT: {
      let formulaInsertData = yield select(formulaInsertDataSelector);
      const focusedCell = yield select(focusedCellSelector);
      if (editMode === DataEntryEditMode.NORMAL && focusedCell) {
        formulaInsertData = {
          cell: { row: focusedCell.row, col: focusedCell.col },
          fact: focusedCell.fact,
          tempInsertion:
            editMode === DataEntryEditMode.FORMULA ? "" : formulaInsertData.tempInsertion,
        };
      }
      if (editMode === DataEntryEditMode.INSERT && mode === DataEntryEditMode.FORMULA) {
        formulaInsertData.tempInsertion = null;
      }
      yield put(updateEditModeReducer(mode, formulaInsertData, currentModule?.uid));
      break;
    }
    default:
      break;
  }
}

/**
 * Update focused cell
 * Depending on the edit mode it might need to make changes to the formula, insert data or edit line items
 */
export function* updateFocusedCell(action) {
  const { cell, selection, rowHeader } = action.payload;
  if (!cell) return;
  const currentFocusedCell = yield select(focusedCellSelector);
  const currentSelection = yield select(selectedRegionsSelector);
  const currentHeader = yield select((state) => state.dataEntry.ui.rowHeader);

  if (
    !currentFocusedCell ||
    currentFocusedCell.row !== cell.row ||
    currentFocusedCell.col !== cell.col ||
    currentHeader !== rowHeader
  )
    yield put(updateFocusedCellReducer(cell, rowHeader));
  if (!currentSelection || (selection && !equalRegions(selection, currentSelection))) {
    yield put(updateSelectedRegionsReducer(selection));
  }
}

/**
 * Update selected regions
 * Depending on the edit mode it might need to make changes to the formula and insert data
 */
export function* updateSelectedRegions(action) {
  const { selection } = action.payload;

  const editMode = yield select(editModeSelector);
  switch (editMode) {
    case DataEntryEditMode.NORMAL:
      yield put(updateSelectedRegionsReducer(selection));
      break;
    case DataEntryEditMode.INSERT: {
      // hack to change focused cell when in insert mode and clicking (focusedCell is disabled...)
      if (isSingleCellSelection(selection[0])) {
        const cell = { row: selection[0].rows[0], col: selection[0].cols[0] };
        yield put(updateFocusedCellAction(cell));
      }
      yield put(updateSelectedRegionsReducer(selection));
      break;
    }
    default:
      break;
  }
}

export function* updateFormula(action) {
  const { formula } = action.payload;
  yield put(updateFormulaRedux(formula));
  /* TODO: implement using code mirror when we fix anchor toggle and insert mode
  if (postSelection) {
    const { editor, index, mode, range } = postSelection;
    if (mode === DataEntryEditMode.FORMULA) {
      editor.selection.setRange(range);
    } else if (mode === DataEntryEditMode.INSERT) {
      const cursorPos = editor.getCursorPosition();
      editor.gotoLine(cursorPos.row + 1, index, false);
      // hack to update selection anchor because aceEditor doesn't
      editor.selection.anchor.column = index;
    }
  }*/
}
