import React, { Dispatch, SetStateAction, useContext } from "react";
import { Icon, Position } from "@blueprintjs/core";

import { Themes } from "constants/uiConstants";
import SideBar, { SideBarItem, SideBarTooltip } from "components/utility/SideBar";
import AppContext from "context/appContext";
import colors from "styles/colors.module.scss";

interface IImportSideBar {
  activeSideMenu: number;
  setActiveSideMenu: Dispatch<SetStateAction<number>>;
}

const ImportSideBar: React.FC<IImportSideBar> = ({ activeSideMenu, setActiveSideMenu }) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const itemClick = (idx: number) => {
    if (activeSideMenu === idx) {
      setActiveSideMenu(0);
    } else {
      setActiveSideMenu(idx);
    }
  };

  const itemColor = (idx: number) => {
    if (idx === activeSideMenu) return theme === Themes.LIGHT ? colors.blue3 : colors.blue4;
    else return theme === Themes.LIGHT ? colors.gray2 : colors.gray4;
  };
  return (
    <SideBar>
      <SideBarTooltip content="Explorer" disabled position={Position.RIGHT}>
        <SideBarItem active={activeSideMenu === 1} onClick={() => itemClick(1)} theme={theme}>
          <Icon color={itemColor(1)} icon="diagram-tree" size={20} />
        </SideBarItem>
      </SideBarTooltip>
      <SideBarTooltip content="Add or edit import module" position={Position.RIGHT}>
        <SideBarItem active={activeSideMenu === 2} onClick={() => itemClick(2)} theme={theme}>
          <Icon color={itemColor(2)} icon="cube" size={20} />
        </SideBarItem>
      </SideBarTooltip>
    </SideBar>
  );
};

export default ImportSideBar;
