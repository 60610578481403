import range from "lodash.range";

import * as consts from "constants/createModelConstants";
import { FilterTypes } from "constants/createModelConstants";
import * as valuationConstants from "constants/modelConstants";

/**
 * Config object for server live under configuration.
 * Available configuration options are under configurationOptions
 * */
export const DEFAULT_STATE = {
  fetchingCompanyConfiguration: false,
  configuration: {
    cashFlowType: consts.CASH_FLOW_TYPES[0].value,
    companyName: "",
    companyType: "Public",
    currency: "USD",
    exitMultipleTarget: 10,
    exitMultipleType: "",
    geography: "",
    historicalPeriod: 3,
    industry: "",
    marginOfSafetyEnabled: false,
    marginOfSafety: 0,
    periodType: consts.PeriodTypes.ANNUAL,
    projectionPeriod: 5,
    startDate: null,
    startPeriod: null,
    quarterlyStartPeriods: [],
    quarterlyProjectionPeriods: [],
    startYears: [],
    // TODO - reinclude once templates include this information
    // targetVariable: consts.TARGET_VARIABLES.IMPLIED_SHARE_PRICE.value,
    // terminalValuationCalcType: consts.TV_CALC_TYPES.PERPETUAL_GROWTH,
    ticker: "",
    valuationType: "default",
    variables: [{ INTERNAL_SOURCE: "" }, { CASE: "" }],
  },
  configurationOptions: {
    availableForecasts: { min: 1, max: 2 },
    availableStartYears: [],
    availableHistoricals: { min: 2, max: 3 },
    availableTickers: [],
    availableIndustries: [],
    availableGeographies: [],
    availableCurrencies: [],
  },
  filters: {
    [consts.FilterTypes.INDUSTRY]: [],
    [consts.FilterTypes.GEOGRAPHY]: [],
  },
  filterOptions: {},
  metaData: {
    advancedControls: {
      config: false,
      time: false,
    },
    building: {
      buildSucceeded: false,
      buildFailed: false,
      isBuilding: false,
      progress: 0,
      step: "",
      steps: [],
    },
    companySelectActiveItem: null, // Used for controlled usage on the CompanySelect component
    error: null,
    logoUrl: undefined,
    validationErrors: {},
  },
};

export default function createModelReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    // CONFIGURATION
    case consts.UPDATE_CONFIGURATION:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          [action.payload.configItem]: action.payload.newValue,
        },
      };
    case consts.UPDATE_EXIT_MULTIPLE_TARGET:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          exitMultipleTarget: action.payload.value,
        },
      };
    case consts.TOGGLE_MARGIN_OF_SAFETY:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          marginOfSafetyEnabled:
            !state.configuration.marginOfSafetyEnabled &&
            state.configuration.targetVariable === "Implied share price",
        },
      };
    case consts.UPDATE_MARGIN_OF_SAFETY:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          marginOfSafety: action.payload.value,
        },
      };
    case consts.UPDATE_COMPANY_TYPE:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          companyType: action.payload.type,
        },
      };
    case consts.UPDATE_SELECTED_COMPANY: {
      // For default companies. Data is all contained in the company object
      return {
        ...state,
        configuration: {
          ...state.configuration,
          ...action.payload.company,
          projectionPeriod: 5,
          startDate: new Date(new Date().setHours(0, 0, 0, 0)),
        },
        metaData: {
          ...DEFAULT_STATE.metaData,
        },
      };
    }
    case consts.FETCH_COMPANY_CONFIG_OPTIONS:
      return {
        ...state,
        fetchingCompanyConfiguration: true,
      };
    case consts.FETCH_COMPANY_CONFIG_OPTIONS_SUCCESS: {
      const { historicalMax, historicalMin } = action.payload.data;
      const availableHistoricals = {
        min: 1,
        max: historicalMin - historicalMax + 1,
      };
      return {
        ...state,
        fetchingCompanyConfiguration: false,
        configuration: {
          ...state.configuration,
          ...action.payload.data,
          startDate: new Date(new Date().setHours(0, 0, 0, 0)),
          startPeriod: historicalMin,
          startYears: range(historicalMax, historicalMin + 1),
          historicalPeriod: Math.min(availableHistoricals.max, 3),
          projectionPeriod: 5,
          quarterlyStartPeriods: range(historicalMax, historicalMin + 1, 0.25),
        },
        configurationOptions: {
          ...state.configurationOptions,
          availableHistoricals,
          availableForecasts: { min: 2, max: 15 },
          startYears: range(historicalMax, historicalMin + 1),
          quarterlyStartPeriods: range(historicalMax, historicalMin + 1, 0.25),
        },
      };
    }
    case consts.FETCH_COMPANY_CONFIG_OPTIONS_FAILURE:
      return {
        ...state,
        fetchingCompanyConfiguration: false,
      };
    case consts.UPDATE_COMPANY_NAME: // Manual updating of companyName, for blank valuations or manual overrides
      return {
        ...state,
        configuration: {
          ...state.configuration,
          companyName: action.payload.name,
        },
      };
    case consts.UPDATE_COMPANY_TICKER:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          ticker: action.payload.ticker,
        },
      };
    case consts.SET_VALUATION_TYPE: {
      const { type } = action.payload;
      const thisYear = new Date().getFullYear();
      const available =
        state.configuration.periodType === consts.PeriodTypes.ANNUAL
          ? { min: 2, max: 15 }
          : { min: 1, max: 15 };
      if (type === "blank") {
        return {
          ...state,
          configuration: {
            ...state.configuration,
            startDate: new Date(new Date().setHours(0, 0, 0, 0)),
            startPeriod: thisYear,
            valuationType: type,
          },
          configurationOptions: {
            availableForecasts: available,
            availableHistoricals: available,
            startYears: range(thisYear - 20, thisYear + 20),
          },
        };
      } else {
        return {
          ...state,
          configuration: {
            ...state.configuration,
            valuationType: type,
          },
        };
      }
    }
    case consts.UPDATE_INDUSTRY:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          industry: action.payload.industry,
        },
      };
    case consts.UPDATE_HISTORICALS:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          historicalPeriod: action.payload.years,
        },
      };
    case consts.UPDATE_FORECASTS:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          projectionPeriod: action.payload.years,
        },
      };
    case consts.UPDATE_CURRENCY:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          currency: action.payload.currency,
        },
      };
    case consts.UPDATE_TERMINAL_VALUATION_TYPE: {
      const { type } = action.payload;
      return {
        ...state,
        configuration: {
          ...state.configuration,
          ...(type === consts.TV_CALC_TYPES.EXIT_MULTIPLE && {
            exitMultipleType: state.configuration.exitMultipleType || consts.EXIT_MULTIPLE_TYPES[0],
          }),
          terminalValuationCalcType: action.payload.type,
        },
      };
    }
    case consts.UPDATE_START_DATE:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          startDate: action.payload.date,
        },
      };
    case consts.UPDATE_START_PERIOD: {
      const { startPeriod } = action.payload;
      const { quarterlyStartPeriods, startYears, historicalPeriod, periodType, valuationType } =
        state.configuration;
      /**
       * When the start date is adjusted, we need to account for that change in the available
       * and selected forecast and historical period.
       * */
      let remainingPeriods;
      if (periodType === consts.PeriodTypes.QUARTERLY) {
        remainingPeriods = quarterlyStartPeriods.filter((period) => period <= startPeriod);
      } else {
        remainingPeriods = startYears.filter((period) => period <= startPeriod);
      }

      const maxRemaining = Math.max(...remainingPeriods) - Math.min(...remainingPeriods) + 1;
      const newHistoricalPeriod =
        maxRemaining > 1 && historicalPeriod > maxRemaining ? maxRemaining : historicalPeriod;
      const availableHistoricals = {
        min: 1,
        max:
          valuationType === "default"
            ? Math.max(...remainingPeriods) - Math.min(...remainingPeriods) + 1
            : 15,
      };
      return {
        ...state,
        configuration: {
          ...state.configuration,
          historicalPeriod: newHistoricalPeriod,
          startPeriod,
        },
        configurationOptions: {
          ...state.configurationOptions,
          availableHistoricals,
        },
      };
    }
    case consts.UPDATE_PERIOD_TYPE: {
      const { periodType } = action.payload;
      const {
        historicalPeriod,
        projectionPeriod,
        startPeriod,
        startYears,
        quarterlyStartPeriods,
        valuationType,
      } = state.configuration;
      // Init. new variables
      let newStartPeriod, availableForecasts, availableHistoricals;
      let newHistoricalPeriod = historicalPeriod;
      let newProjectionPeriod = projectionPeriod;
      /**
       * If changing from annual to quarterly:
       * - Round the startPeriod to the closest available whole year from startYears, rounding to
       *   up to the latest Q available
       * - Set the availableForcasts to: { min: 1, max: quarterlyProjectionPeriods }
       * - Set the availableHistoricals to: { min: 1, max: startPeriodGap }
       * */
      if (periodType === consts.PeriodTypes.QUARTERLY) {
        newStartPeriod =
          valuationType === "default"
            ? Math.max(
                ...quarterlyStartPeriods.filter((period) => Number.parseInt(period) === startPeriod)
              )
            : startPeriod;

        availableForecasts = {
          min: 1,
          max: valuationType === "default" ? 10 : 15,
        };
        const remainingPeriods = quarterlyStartPeriods.filter((period) => period <= newStartPeriod);
        availableHistoricals = {
          min: 1,
          max: Math.floor(Math.max(...remainingPeriods) - Math.min(...remainingPeriods) + 1),
        };
      } else {
        /**
         * If changing from quarterly to annual:
         * - Round the startPeriod to the nearest possible whole year.
         * - Set the avilableForecasts to: { min: 1, max: projectionPeriod },
         * - Set the availableHistoricals to: { min: 1, max: startPeriodGap }
         * */
        newStartPeriod =
          startYears.find((year) => year === Number.parseInt(startPeriod)) ||
          Number.parseInt(startPeriod);
        availableForecasts = {
          min: 1,
          max: 10,
        };
        newHistoricalPeriod = Number.parseInt(historicalPeriod);
        newProjectionPeriod = Number.parseInt(projectionPeriod);
        const remainingPeriods = startYears.filter((year) => year <= newStartPeriod);

        availableHistoricals = {
          min: 1,
          max: Math.max(...remainingPeriods) - Math.min(...remainingPeriods) + 1,
        };
      }

      return {
        ...state,
        configuration: {
          ...state.configuration,
          historicalPeriod: newHistoricalPeriod,
          projectionPeriod: newProjectionPeriod,
          periodType: action.payload.periodType,
          startPeriod: newStartPeriod,
        },
        configurationOptions: {
          ...state.configurationOptions,
          availableForecasts: availableForecasts,
          availableHistoricals: availableHistoricals,
        },
      };
    }
    // FILTERS
    case consts.ADD_FILTER: {
      const { filter, item } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [filter]: state.filters[filter].concat([item]),
        },
      };
    }
    case consts.REMOVE_FILTER: {
      const { filter, item } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [filter]: state.filters[FilterTypes[filter]].filter((filt) => filt !== item),
        },
      };
    }
    case consts.CLEAR_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filter]: [],
        },
      };
    case consts.FETCH_CONFIG_ITEM_OPTIONS_SUCCESS:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          [action.payload.type]: action.payload.values,
        },
      };

    // CONFIGURATION OPTIONS
    case consts.FETCH_AVAILABLE_TICKERS_SUCCESS: {
      const { data } = action.payload;
      const tickers = data.data || data;
      return {
        ...state,
        availableTickers: tickers,
        companySelectActiveItem: data[0],
      };
    }

    case consts.FETCH_MODEL_TEMPLATES_SUCCESS: {
      const { templates } = action.payload;
      return {
        ...state,
        configurationOptions: {
          ...state.configurationOptions,
          templates,
        },
      };
    }

    case consts.ADD_TEMPLATE_VARIABLE: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          variables: [...state.configuration.variables, { NEW_VARIABLE: "" }],
        },
      };
    }

    case consts.EDIT_TEMPLATE_VARIABLE: {
      const { key, nextVariable } = action.payload;
      const nextVariables = [...state.configuration.variables];
      // delete nextVariables[key];
      // const replaceIndex = nextVariables.findIndex((variable) => Object.keys(variable)[0] === key);
      // console.log(replaceIndex);
      nextVariables.splice(
        nextVariables.findIndex((variable) => Object.keys(variable)[0] === key),
        1,
        nextVariable
      );
      return {
        ...state,
        configuration: {
          ...state.configuration,
          variables: nextVariables,
        },
      };
    }

    case consts.REMOVE_TEMPLATE_VARIABLE: {
      const { key } = action.payload;
      return {
        ...state,
        configuration: {
          ...state.configuration,
          variables: state.configuration.variables.filter(
            (variable) => Object.keys(variable)[0] !== key
          ),
        },
      };
    }

    // METADATA
    case consts.UPDATE_BUILDING_STATUS: {
      const { isBuilding, status, step } = action.payload;
      const { progress, steps } = state.metaData.building;
      const success = status === "success";
      const nextProgress = !success ? progress : isBuilding ? progress + 0.1666 : 1;
      const nextBuilding = {
        isBuilding,
        progress: nextProgress,
        status,
        step,
        steps: steps.concat([step]),
      };
      return {
        ...state,
        metaData: {
          ...state.metaData,
          building: nextBuilding,
        },
      };
    }
    case valuationConstants.FETCH_LOGO_SUCCESS:
      return {
        ...state,
        metaData: {
          ...state.metaData,
          logoUrl: action.payload.logoUrl,
        },
      };
    case consts.UPDATE_COMPANY_SELECT_ACTIVE_ITEM:
      return {
        ...state,
        companySelectActiveItem: action.payload.companySelectActiveItem,
      };
    case consts.UPDATE_UNIT:
      return { ...state, unit: action.payload.unit };
    case consts.CREATE_MODEL:
      return {
        ...state,
        metaData: {
          ...state.metaData,
          building: {
            ...DEFAULT_STATE.metaData.building,
            isBuilding: true,
          },
        },
      };
    case consts.CREATE_MODEL_SUCCESS:
      return {
        ...state,
        metaData: {
          ...state.metaData,
          building: {
            buildSucceeded: true,
            // Temp solution to handle redirects.
            modelName: action.payload.model.companyName,
            modelID: action.payload.model.id,
          },
        },
      };
    case consts.CREATE_MODEL_FAILURE:
      return {
        ...state,
        error: action.payload.err,
        validationErrors: {
          ...state.modelidationErrors,
          ...action.payload.err,
        },
        metaData: {
          ...state.metaData,
          building: {
            ...state.metaData.building,
            buildFailed: true,
          },
        },
      };
    case consts.UPDATE_ADVANCED_CONTROLS_ACTIVE:
      return {
        ...state,
        metaData: {
          ...state.metaData,
          advancedControls: {
            ...state.metaData.advancedControls,
            [action.payload.panel]: action.payload.isActive,
          },
        },
      };
    // Clears the configuration.
    case consts.FLUSH_CONFIG:
      return {
        ...state,
        configuration: {
          ...DEFAULT_STATE.configuration,
          template: state.configuration.template,
        },
      };
    // Completely flushes the reducer.
    case consts.FLUSH_CREATE:
      return {
        ...DEFAULT_STATE,
      };
    default:
      return state;
  }
}
