import { Intent } from "@blueprintjs/core";
import { Span } from "components/utility/HTMLWrappers";
import {
  lookup,
  renderEditFormatEvent,
  renderEditFormulaEvent,
  renderEditLineItemEvent,
} from "utils/eventHistoryUtils";
import {
  ADD_LINE_ITEM,
  DELETE_LINE_ITEM,
  EDIT_FORMULA,
  REORDER_MODULE,
} from "constants/dataEntryConstants";
import { BaseFormatObject } from "types/format";

export const ADD_EVENT_TAG_FILTER = "ADD_EVENT_TAG_FILTER";
export const REMOVE_EVENT_TAG_FILTER = "REMOVE_EVENT_TAG_FILTER";
export const UNDO_HISTORY = "UNDO_HISTORY";
export const UNDO_HISTORY_FAILURE = "UNDO_HISTORY_FAILURE";
export const UNDO_HISTORY_SUCCESS = "UNDO_HISTORY_SUCCESS";
export const CLEAR_EVENT_TAG_FILTERS = "CLEAR_EVENT_TAG_FILTERS";
export const FLUSH_EVENT_HISTORY_REDUCER = "FLUSH_EVENT_HISTORY_REDUCER";

/**
 *  Object to compare format objects to get a from or to string if the property is not present of
 *  the format object, i.e. it has been added for the first time or removed
 * */
export const DefaultFormatComparisonObject = {
  ...BaseFormatObject,
  backgroundColor: "Theme default",
  color: "Theme default",
};

export const bold = { fontWeight: "bold" };
export const excluded = {
  identifier: "identifer",
  uid: "uid",
};

export const groupActions = new Map();
groupActions.set(REORDER_MODULE, EDIT_FORMULA);
groupActions.set(ADD_LINE_ITEM, EDIT_FORMULA);
groupActions.set(DELETE_LINE_ITEM, EDIT_FORMULA);

export const RenderTemplates = {
  CURRENT: [
    {
      Element: Span,
      value: "The current model state.",
      elementProps: { intent: Intent.PRIMARY },
      style: { ...bold },
    },
  ],
  ADD_COLUMN: [
    lookup([{ key: "fullname" }]),
    { Element: Span, value: "added column" },
    lookup([{ key: "undo" }, { key: "period" }]),
    { Element: Span, value: "to module" },
    lookup([{ key: "moduleName" }]),
  ],
  ADD_LINE_ITEM: [
    lookup([{ key: "fullname" }]),
    { Element: Span, value: "added line item" },
    lookup([{ key: "redo" }, { key: "name" }]),
    { Element: Span, value: "to module" },
    lookup([{ key: "moduleName" }]),
  ],
  ADD_MODULE: [
    lookup([{ key: "fullname" }]),
    { Element: Span, value: "added module" },
    lookup([{ key: "redo" }, { key: "name" }]),
  ],
  ADD_QUICK_CALCULATION: [
    lookup([{ key: "fullname" }]),
    { Element: Span, value: "added quick calculation" },
    lookup([{ key: "redo" }, { key: "function" }]),
  ],
  DELETE_MODULE: [
    lookup([{ key: "fullname" }]),
    { Element: Span, value: "deleted module" },
    lookup([{ key: "undo" }, { key: "name" }]),
  ],
  DELETE_COLUMN: [
    lookup([{ key: "fullname" }]),
    { Element: Span, value: "deleted column" },
    lookup([{ key: "redo" }, { key: "period" }]),
    { Element: Span, value: "from module" },
    lookup([{ key: "redo" }, { key: "moduleName" }]),
  ],
  DELETE_LINE_ITEM: [
    lookup([{ key: "fullname" }]),
    { Element: Span, value: "deleted line item" },
    lookup([{ key: "undo" }, { key: "name" }]),
    { Element: Span, value: "to module" },
    lookup([{ key: "moduleName" }]),
  ],
  EDIT_MODULE: [
    lookup([{ key: "fullname" }]),
    { Element: Span, value: "renamed module from" },
    lookup([{ key: "undo" }, { key: "name" }]),
    { Element: Span, value: "to" },
    lookup([{ key: "redo" }, { key: "name" }]),
  ],
  REORDER_MODULE: [
    lookup([{ key: "fullname" }]),
    { Element: Span, value: "reordered a line item." },
  ],
  EDIT_FORMULA: [{ customRenderer: renderEditFormulaEvent }],
  EDIT_FORMAT: [{ customRenderer: renderEditFormatEvent }],
  EDIT_LINE_ITEMS: [{ customRenderer: renderEditLineItemEvent }],
};
