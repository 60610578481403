import React, { RefObject, useContext, useEffect, useState } from "react";
import { Classes, DialogBody, DialogStep, Intent, UL } from "@blueprintjs/core";

import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import { FullWidthMultiStepDialog } from "components/utility/StyledComponents";
import { IResponse } from "types";

import { KeyedMutator } from "swr";
import { Widget } from "types/widget";
import SetWatchlistNameAndCompanies from "./SetWatchlistNameAndCompanies";
import { MatchingModel, Watchlist } from "types/collections";
import { IconNames } from "@blueprintjs/icons";
import LoadingIndicator from "components/utility/LoadingIndicator";
import { EStatus } from "constants/apiConstants";
import { multiToaster } from "utils/toaster";
import CollectionModelSelection from "./CollectionModelSelection";
import { WidgetRef } from "components/dashboard/widget/Widget";
import usePrevious from "hooks/usePrevious";
import useWatchlists from "hooks/useWatchlists";

interface ICreateNewWatchlistDialogProps {
  editingWatchlist: boolean;
  editWatchlist: (edit: Partial<Watchlist>) => Promise<
    IResponse<{
      matchingModels: MatchingModel[] | null;
      watchlist: Watchlist;
    }>
  >;
  isOpen: boolean;
  matchingModels: MatchingModel[] | null | undefined;
  mutate: KeyedMutator<IResponse<Widget>>;
  onClose: () => void;
  watchlist?: Watchlist;
  widgetRef: RefObject<WidgetRef>;
}

const Steps = {
  SetNameAndCompanies: "1",
  EditWatchlist: "2",
  SetModels: "3",
};

const EditWatchlistDialog: React.FC<ICreateNewWatchlistDialogProps> = ({
  editWatchlist,
  editingWatchlist,
  isOpen,
  matchingModels,
  onClose,
  watchlist,
  widgetRef,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const [localWatchlist, setLocalWatchlist] = useState<Partial<Watchlist>>();
  const [newMatchingModels, setNewMatchingModels] = useState<MatchingModel[]>();
  const prevWatchlistId = usePrevious(watchlist?.id);

  const { watchlists } = useWatchlists();

  const handleDialogClose = () => {
    setNewMatchingModels(undefined);
    widgetRef?.current?.rerunVSLQuery();
    onClose();
  };

  // Mirror the watchlist on the server locally for editing and comparison.
  useEffect(() => {
    if ((watchlist && !prevWatchlistId) || watchlist?.id !== prevWatchlistId) {
      setLocalWatchlist(watchlist);
    }
  }, [prevWatchlistId, watchlist]);

  const handleDialogChange = async (step: string) => {
    if (step === Steps.EditWatchlist && localWatchlist) {
      const nextWatchlist = {
        ...localWatchlist,
        companies: localWatchlist?.companies?.map((c) => ({
          companyName: c.companyName,
          ticker: c.ticker,
          modelId: c.modelId,
        })),
      };
      const editResponse = await editWatchlist(nextWatchlist);
      if (editResponse.status === EStatus.success && matchingModels) {
        // Begin the matching model flow.
        // Filter only by matches that aren't already matched with a model...
        const newMatches = editResponse?.data?.matchingModels
          ? editResponse.data.matchingModels.filter(
              (m) => matchingModels.findIndex((wL) => wL.ticker === m.ticker) === -1
            )
          : [];
        if (newMatches.length > 0) {
          setNewMatchingModels(newMatches);
        } else {
          // We have no model matches, close the Dialog.
          multiToaster.show({ intent: Intent.SUCCESS, message: "Successfully edited Watchlist." });
          handleDialogClose();
        }
      }
    }
  };

  const handleAssignModelToTicker = (ticker: string, modelId: string) => {
    if (localWatchlist?.companies) {
      const index = localWatchlist.companies.findIndex((c) => c.ticker === ticker);
      if (index !== -1) {
        const nextCompanies = [...localWatchlist.companies];
        nextCompanies.splice(index, 1, {
          ...localWatchlist.companies[index],
          modelId: modelId,
        });
        setLocalWatchlist((prev) => {
          return { ...prev, companies: nextCompanies };
        });
      }
    }
  };

  const handleSaveModels = async () => {
    if (localWatchlist) {
      const nextWatchlist = {
        ...localWatchlist,
        companies: localWatchlist?.companies?.map((c) => ({
          companyName: c.companyName,
          ticker: c.ticker,
          modelId: c.modelId,
        })),
      };
      const response = await editWatchlist(nextWatchlist);
      if (response?.status === EStatus.success) {
        // TODO Rerun the VSL query.
        multiToaster.show({ intent: Intent.SUCCESS, message: "Successfully edited Watchlists" });
        handleDialogClose();
      } else {
        multiToaster.show({ intent: Intent.WARNING, message: response?.error });
      }
    }
  };

  const isWatchlistNameValid = !!(
    localWatchlist?.name &&
    watchlists?.every((w) => w.id === localWatchlist.id || w.name !== localWatchlist.name)
  );

  return (
    <FullWidthMultiStepDialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      finalButtonProps={{ onClick: handleSaveModels, text: "Save Models" }}
      onClose={handleDialogClose}
      onChange={handleDialogChange}
      isOpen={isOpen}
      $showSteps={false}
      title="Edit Watchlist"
    >
      <DialogStep
        id={Steps.SetNameAndCompanies}
        nextButtonProps={{
          disabled:
            !isWatchlistNameValid ||
            !localWatchlist?.companies ||
            localWatchlist?.companies.length === 0,
          rightIcon: IconNames.CIRCLE_ARROW_RIGHT,
        }}
        panel={
          <SetWatchlistNameAndCompanies
            isNameValid={isWatchlistNameValid}
            setWatchlist={setLocalWatchlist}
            watchlist={localWatchlist}
          />
        }
      />
      <DialogStep
        id={Steps.EditWatchlist}
        panel={
          <DialogBody>
            {editingWatchlist && (
              <LoadingIndicator status={{ message: "Editing model...", intent: Intent.PRIMARY }} />
            )}
            {newMatchingModels && newMatchingModels.length > 0 && (
              <>
                <p className={Classes.RUNNING_TEXT}>
                  The following tickers have more than one model available. Click &quot;Next&quot;
                  to assign models
                </p>
                <UL>
                  {newMatchingModels.map((ticker) => (
                    <li key={ticker.ticker}>
                      {ticker.ticker}{" "}
                      <span className={Classes.TEXT_MUTED}>
                        ({ticker.models.length} models available)
                      </span>
                    </li>
                  ))}
                </UL>
              </>
            )}
          </DialogBody>
        }
      />
      {newMatchingModels &&
        newMatchingModels.map((ticker, i) => (
          <DialogStep
            id={Steps.SetModels}
            key={ticker.ticker}
            panel={
              <DialogBody>
                {newMatchingModels && newMatchingModels?.length > 0 && (
                  <CollectionModelSelection
                    setModel={(modelId) => handleAssignModelToTicker(ticker.ticker, modelId)}
                    selectedModelId={
                      localWatchlist?.companies?.find((c) => c.ticker === ticker.ticker)?.modelId
                    }
                    ticker={newMatchingModels[i]}
                  />
                )}
              </DialogBody>
            }
            title={`Select Model: ${ticker.ticker}`}
          />
        ))}
    </FullWidthMultiStepDialog>
  );
};

export default EditWatchlistDialog;
