import * as Highcharts from "highcharts/highstock";

declare module "highcharts" {
  interface Chart {
    maxEndLabelWidth?: number;
  }

  interface SVGElement {
    doTransform?: boolean;
    y?: number;
    height?: number;
    width?: number;
  }

  interface SeriesOptions {
    endLabel?: boolean;
  }

  interface EndLabelStyle {
    backgroundColor?: string;
    color?: string;
  }

  interface PlotLineOptions {
    endLabelStyle?: EndLabelStyle;
  }

  interface Series {
    endLabel?: SVGElement | null;
  }

  interface Point {
    isInside?: boolean;
  }

  interface Axis {
    width?: number;
  }
}

export default function (
  H: typeof Highcharts,
  STROKE_WIDTH = 2,
  HIGHLIGHT_ANIMATION_DURATION = 150
) {
  function addLabelEvents(label: Highcharts.SVGElement, series: Highcharts.Series) {
    label.on("mouseover", () => {
      highlightEndLabel(series);
      series.onMouseOver();
      series.chart.series.forEach((s) => {
        if (s !== series) {
          s.setState("inactive");
        }
      });
    });

    label.on("mouseout", () => {
      series.onMouseOut();
      removeHighlightEndLabel(series);
    });
  }

  function unmuteEndLabels(series: Highcharts.Series[]) {
    for (const s of series) {
      if (s.endLabel) {
        s.endLabel.animate({ opacity: 1 }, { duration: HIGHLIGHT_ANIMATION_DURATION });
      }
    }
  }

  function muteOtherEndLabels(hoveredSeries: Highcharts.Series, series: Highcharts.Series[]) {
    for (const s of series) {
      if (s !== hoveredSeries && s.endLabel) {
        s.endLabel.animate({ opacity: 0.25 }, { duration: HIGHLIGHT_ANIMATION_DURATION });
      }
    }
  }

  // Animates the "highlight" effect of the end-label
  function highlightEndLabel(series: Highcharts.Series) {
    series?.endLabel?.animate(
      {
        "stroke-width": STROKE_WIDTH + 2,
        fill: series.color,
      },
      { duration: HIGHLIGHT_ANIMATION_DURATION }
    );

    muteOtherEndLabels(series, series.chart.series);
  }

  // Animates back to the default state of the end-label
  function removeHighlightEndLabel(series: Highcharts.Series) {
    series?.endLabel?.animate(
      {
        "stroke-width": STROKE_WIDTH,
        fill:
          series.chart.options?.plotOptions?.line?.endLabelStyle?.backgroundColor || series.color,
      },
      { duration: HIGHLIGHT_ANIMATION_DURATION }
    );

    unmuteEndLabels(series.chart.series);
  }

  function alignEndLabel(series: Highcharts.Series, maxLabelWidth: number) {
    if (!series.endLabel) return;

    if (!series.visible) {
      series.endLabel.destroy();
      delete series.endLabel;
      return;
    }

    const points = series.points;
    if (!points.length) return;

    const lastPoint = findLastVisiblePoint(series);

    series.endLabel?.attr({
      x: series.chart.chartWidth - 10 - maxLabelWidth,
      y: series.chart.plotTop + (lastPoint?.plotY || 0) - 10,
    });
  }

  function findLastVisiblePoint(series: Highcharts.Series) {
    if (series && series.points) {
      for (let i = series.points.length - 1; i >= 0; i--) {
        if (series.points[i] && series.points[i].isInside) {
          return series.points[i];
        }
      }
    }
  }

  H.addEvent(H.Series, "render", function () {
    const points = this.points,
      endLabel = this.options.endLabel,
      isNavigatorSeries = this.name.startsWith("Navigator");

    if (this.endLabel) {
      this.endLabel.destroy();
      this.endLabel = undefined;
    }

    if (!isNavigatorSeries && endLabel && points.length > 0) {
      const lastPoint = findLastVisiblePoint(this);
      if (!lastPoint) return;

      const formatText = () => {
        const ctx = {
          value: lastPoint.y || 0,
          axis: this.yAxis,
          chart: this.yAxis.chart,
          isFirst: false,
          isLast: false,
        } as Highcharts.AxisLabelsFormatterContextObject;

        return `${this.name} [${this.yAxis.options.labels?.formatter?.call(ctx, ctx) || ""}]`;
      };

      const endLabelStyle = this.chart.options.plotOptions?.line?.endLabelStyle;
      this.endLabel = this.chart.renderer
        .label(formatText(), lastPoint.plotX || Infinity, lastPoint.plotY)
        .attr({
          fill: endLabelStyle?.backgroundColor || this.color,
          stroke: this.color,
          "stroke-width": STROKE_WIDTH,
          "stroke-location": "outside",
          color: endLabelStyle?.color,
          padding: 2.5,
          r: 3,
          zIndex: 100,
        })
        .css({
          fontSize: "10px",
          color: endLabelStyle?.color,
        })
        .add();

      addLabelEvents(this.endLabel, this);
    }
  });

  function isOverlapping(labelA: Highcharts.SVGElement, labelB: Highcharts.SVGElement) {
    const aY = labelA.y || 0,
      aHeight = labelA.height || 0 + STROKE_WIDTH * 2,
      bY = labelB.y || 0,
      bHeight = labelB.height || 0 + STROKE_WIDTH * 2;

    if (aY + aHeight <= bY || bY + bHeight <= aY) {
      return false;
    }

    return true;
  }

  function isHigher(labelA: Highcharts.SVGElement, labelB: Highcharts.SVGElement) {
    if (labelA.y && labelB.y) {
      return labelA.y < labelB.y;
    } else {
      return true;
    }
  }

  function moveBelow(
    upperLabel: Highcharts.SVGElement,
    lowerLabel: Highcharts.SVGElement,
    chartBottom: number
  ) {
    if (upperLabel.y && upperLabel.height && lowerLabel.height) {
      lowerLabel.attr({ y: upperLabel.y + upperLabel.height + 2 });

      if (upperLabel.y + upperLabel.height + lowerLabel.height >= chartBottom) {
        lowerLabel.attr({ opacity: 0 });
      } else {
        lowerLabel.attr({ opacity: 1 });
      }
    }
  }

  function adjustLabel(labels: Highcharts.SVGElement[], index: number, chartBottom: number) {
    if (index + 1 < labels.length) {
      if (
        isOverlapping(labels[index], labels[index + 1]) ||
        isHigher(labels[index + 1], labels[index])
      ) {
        moveBelow(labels[index], labels[index + 1], chartBottom);
      }

      adjustLabel(labels, index + 1, chartBottom);
    }
  }

  H.addEvent(H.Chart, "redraw", function () {
    let maxLabelWidth = 0;

    this.series.forEach((series) => {
      if (series.endLabel) maxLabelWidth = Math.max(maxLabelWidth, series.endLabel.width || 0);
    });

    this.maxEndLabelWidth = maxLabelWidth;

    if (this.maxEndLabelWidth) {
      this.update(
        {
          chart: {
            marginRight: 10 + maxLabelWidth,
          },
        },
        false
      );
    } else if (this.options?.chart?.marginRight) {
      this.update(
        {
          chart: {
            marginRight: undefined,
          },
        },
        false
      );
    }
  });

  H.addEvent(H.Chart, "render", function () {
    this.series.forEach((series) => {
      if (series.endLabel) alignEndLabel(series, this.maxEndLabelWidth || 0);
    });

    const labels = this.series
      .filter((series) => series.endLabel)
      .map((series) => series.endLabel)
      .toSorted((a, b) => (a?.y || 0) - (b?.y || 0)) as Highcharts.SVGElement[];

    adjustLabel(labels, 0, this.plotTop + this.plotHeight + 15);
  });
}
