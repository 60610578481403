import * as consts from "constants/uiConstants";

/**
 * Action to update the status text in the Navigation bar.
 * */
export function updateAppStatus(nextStatus) {
  return {
    type: consts.UPDATE_APP_STATUS,
    payload: {
      nextStatus,
    },
  };
}

export function updateDateFormat(format) {
  return {
    type: consts.UPDATE_DATE_FORMAT,
    payload: {
      format,
    },
  };
}

export function updateAvailableTabs(tab, operation) {
  return {
    type: consts.UPDATE_AVAILABLE_TABS,
    payload: {
      tab,
      operation,
    },
  };
}

export function toggleDiagramOpen() {
  return {
    type: consts.TOGGLE_DIAGRAM_OPEN,
  };
}
