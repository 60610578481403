import {
  Alignment,
  Button,
  ButtonGroup,
  Card,
  Classes,
  Colors,
  DialogFooter,
  DialogProps,
  Divider,
  H6,
  InputGroup,
  Intent,
  NonIdealState,
} from "@blueprintjs/core";
import { IconName, IconNames } from "@blueprintjs/icons";
import LoadingIndicator from "components/utility/LoadingIndicator";
import {
  Flex,
  FullHeightDialogBody,
  FullWidthDialog,
  Grid,
  NormalAlignDivider,
  styledScrollBar,
} from "components/utility/StyledComponents";
import useView from "hooks/dashboard/useView";
import useListConfigs from "hooks/useListConfigs";
import React, { useContext, useState } from "react";
import styled, { css } from "styled-components/macro";
import { EWidgetType2 } from "types/vsl";
import { Widget as TWidget } from "types/widget";
import Widget from "./widget/Widget";
import AppContext from "context/appContext";
import { Themes } from "constants/uiConstants";
import { TTheme } from "types";
import useWidget from "hooks/dashboard/useWidget";

interface AddWidgetToViewDialogProps extends DialogProps {
  onClose: () => void;
  viewId?: string;
}

const AddWidgetToViewDialog: React.FC<AddWidgetToViewDialogProps> = ({
  viewId,
  onClose,
  ...dialogProps
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const [widgetType, setWidgetType] = useState<"Tables" | "Charts">("Tables");
  const [chartType, setChartType] = useState(EWidgetType2.LINE_CHART);
  const [previewWidgetId, setPreviewWidgetId] = useState<string>();
  const [searchWidgetPredicate, setSearchWidgetPredicate] = useState("");

  const { update: updateWidget, widget: previewWidget } = useWidget(previewWidgetId);

  const { isLoading, widgets } = useListConfigs();

  const { addWidgetToView } = useView(viewId);

  const handleAddWidget = async () => {
    if (previewWidget) {
      const success = await addWidgetToView(previewWidget);
      if (success) {
        onClose();
      }
    }
  };

  const widgetsByType = (widgets ?? [])
    .filter(
      (widget: TWidget) =>
        widget.widgetType === (widgetType === "Tables" ? EWidgetType2.TABLE : chartType)
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const filteredWidgets = widgetsByType.filter((widget) =>
    widget.name.toLowerCase().includes(searchWidgetPredicate.toLowerCase())
  );

  if (
    widgetsByType.length > 0 &&
    previewWidget &&
    widgetsByType.findIndex((widget) => widget.id === previewWidget?.id) === -1
  ) {
    setPreviewWidgetId(widgetsByType[0].id);
  }

  return (
    <FullWidthDialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      icon={IconNames.ADD}
      onClose={onClose}
      title="Add Widget"
      {...dialogProps}
    >
      <FullHeightDialogBody>
        <Flex flexDirection="column" fullWidth alignItems="flex-start">
          <strong>
            <p className={Classes.TEXT_SMALL}>Widget Type</p>
          </strong>
          <ButtonGroup style={{ marginBottom: 10 }}>
            <Button
              active={widgetType === "Tables"}
              onClick={() => setWidgetType("Tables")}
              small
              text="Tables"
            />
            <Button
              active={widgetType === "Charts"}
              onClick={() => setWidgetType("Charts")}
              small
              text="Charts"
            />
          </ButtonGroup>
          <NormalAlignDivider style={{ margin: "0 0 10px 0" }} />
        </Flex>
        <StyledGrid cols="auto auto minmax(auto, 220px) auto 1fr" rows="1fr">
          <Flex justifyContent="flex-start" flexDirection="column" alignItems="flex-start">
            <strong>
              <p className={Classes.TEXT_SMALL}>
                {widgetType === "Charts" ? "Chart Type" : "Table Type"}
              </p>
            </strong>
            <Flex flexDirection="column" gap={5} justifyContent="flex-start">
              {widgetType === "Charts" ? (
                <>
                  {ChartTypes.map((type) => (
                    <Button
                      alignText={Alignment.LEFT}
                      fill
                      icon={ChartTypeToButtonDataMap[type]?.icon}
                      intent={chartType === type ? Intent.PRIMARY : undefined}
                      key={type}
                      minimal
                      onClick={() => setChartType(type)}
                      outlined={chartType === type}
                      text={ChartTypeToButtonDataMap[type]?.text}
                    />
                  ))}
                </>
              ) : (
                <Button
                  alignText={Alignment.LEFT}
                  fill
                  icon={IconNames.PANEL_TABLE}
                  intent={Intent.PRIMARY}
                  minimal
                  outlined={true}
                  text="Tables"
                />
              )}
            </Flex>
          </Flex>
          <NormalAlignDivider style={{ margin: "0 10px" }} />
          <StyledWidgetList
            alignItems="flex-start"
            flexDirection="column"
            justifyContent="flex-start"
            gap={5}
            $theme={theme}
          >
            <InputGroup
              leftIcon={IconNames.SEARCH}
              onChange={(e) => setSearchWidgetPredicate(e.currentTarget.value)}
              placeholder="Search Widgets..."
              value={searchWidgetPredicate}
              type="Search"
            />
            <Divider />
            {isLoading || !filteredWidgets ? (
              <LoadingIndicator status={{ intent: Intent.PRIMARY, message: "Loading..." }} />
            ) : filteredWidgets.length > 0 ? (
              filteredWidgets.map((widget) => (
                <StyledWidgetCard
                  $active={previewWidget?.id === widget.id}
                  key={widget.id}
                  onClick={() => setPreviewWidgetId(widget.id)}
                  $theme={theme}
                >
                  <p>{widget.name}</p>
                </StyledWidgetCard>
              ))
            ) : (
              <NonIdealState
                action={<Button onClick={() => setSearchWidgetPredicate("")} text="Clear" />}
                icon={IconNames.SEARCH}
                title="No Widgets match search."
              />
            )}
          </StyledWidgetList>
          <NormalAlignDivider style={{ margin: "0 10px" }} />
          {previewWidget ? (
            <Grid rows="auto auto 1fr" style={{ overflow: "hidden" }}>
              <H6>Widget Preview</H6>
              <p>{previewWidget.description}</p>
              <Widget
                enableEditWidgetDialog={false}
                enableTitleMenu={false}
                updateWidget={updateWidget}
                widget={{ ...previewWidget, selectorsHidden: false }}
              />
            </Grid>
          ) : (
            <NonIdealState
              icon={IconNames.SEARCH}
              title="Select a Widget from the list on the left to preview it"
            />
          )}
        </StyledGrid>
      </FullHeightDialogBody>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose} text="Cancel" />
          <Button
            disabled={!previewWidget}
            icon={IconNames.ADD}
            intent={Intent.PRIMARY}
            onClick={handleAddWidget}
            text="Add Widget"
          />
        </div>
      </DialogFooter>
    </FullWidthDialog>
  );
};

const ChartTypes = [
  EWidgetType2.LINE_CHART,
  EWidgetType2.BAR_CHART,
  EWidgetType2.PIE_CHART,
  EWidgetType2.SCATTER_CHART,
];

const StyledWidgetList = styled(Flex)`
  overflow: auto;
  padding: 1px;
  ${styledScrollBar}
`;

const StyledGrid = styled(Grid)`
  height: calc(100% - 71px);
`;

const StyledWidgetCard = styled(Card)<{ $active: boolean; $theme: TTheme }>`
  ${({ $active }) =>
    $active &&
    css`
      border: 1px solid ${Colors.BLUE3};
    `}
  padding: 10px;
  p {
    color: ${({ $active, $theme }) =>
      $active ? ($theme === Themes.DARK ? Colors.BLUE5 : Colors.BLUE2) : undefined};
    margin-bottom: 0;
  }
  width: 100%;
  cursor: pointer;
`;

const ChartTypeToButtonDataMap: { [key in EWidgetType2]?: { icon: IconName; text: string } } = {
  [EWidgetType2.LINE_CHART]: {
    icon: IconNames.TIMELINE_LINE_CHART,
    text: "Line Charts",
  },
  [EWidgetType2.BAR_CHART]: {
    icon: IconNames.TIMELINE_BAR_CHART,
    text: "Bar Charts",
  },
  [EWidgetType2.PIE_CHART]: {
    icon: IconNames.PIE_CHART,
    text: "Pie Charts",
  },
  [EWidgetType2.SCATTER_CHART]: {
    icon: IconNames.SCATTER_PLOT,
    text: "Scatter Charts",
  },
};

export default AddWidgetToViewDialog;
