import { EValFormat, IFormatObject } from "types/format";
import { IVSLTable } from "types/vsl";
import { getValidJSON } from "utils/api";
import { MISSING_VALUE } from "constants/appConstants";

//helper function to get a list of all the column keys that should be searchable
export const getTableSearchColumnKeys = (data?: IVSLTable): string[] => {
  return (data?.raw?.[0].cells ?? []).reduce((results: string[], cell, idx) => {
    const format = getValidJSON<IFormatObject>(cell.format);
    // this seems to be the best way to check if a column is a "string" field currently
    // might need to make this more robust in the future
    if (format && typeof format === "object" && format["valFormat"] === EValFormat.PLAINTEXT) {
      const columnName = cell.key.trim().length > 0 ? cell.key.trim() : `Column ${idx + 1}`;
      return [...results, columnName];
    }

    return results;
  }, []);
};

//get all values for a given column in a IVSLTable
export const getColumnValues = (column: string, data?: IVSLTable) => {
  return (data?.raw ?? [])
    .reduce((results: string[], row) => {
      const matchingCell = row.cells.find((cell) => cell.key === column);

      if (matchingCell && !results.some((val) => val === matchingCell.value)) {
        return [...results, matchingCell.value];
      }

      return results;
    }, [])
    .sort((a, b) => {
      if (b === MISSING_VALUE) return -1;
      if (a === MISSING_VALUE) return 1;

      return a.localeCompare(b);
    });
};
