import { Button, EditableText, Intent, Menu, MenuItem, Popover } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Flex, FullSizeDiv, Grid, NormalAlignDivider } from "components/utility/StyledComponents";
import useView from "hooks/dashboard/useView";
import React, { useState } from "react";
import AddWidgetToViewDialog from "./AddWidgetToViewDialog";
import View from "./View";
import EditWidgetSelectorsDialog from "components/dashboard/libraries/EditWidgetSelectorsDialog";
import { useNavigate, useParams } from "react-router-dom";
import CopyViewDialog from "./CopyViewDialog";
import { DASHBOARD_ROUTE, EStatus } from "constants/apiConstants";
import { multiToaster } from "utils/toaster";
import { mutate as globalMutate } from "swr/_internal";

interface ViewControlProviderProps {
  viewId: string;
  removeView: (viewId: string) => Promise<boolean>;
}

const ViewControls: React.FC<ViewControlProviderProps> = ({ removeView, viewId }) => {
  const [addWidgetDialogOpen, setAddWidgetDialogOpen] = useState(false);
  const [copyViewDialogOpen, setCopyViewDialogOpen] = useState(false);
  const [editSelectorsDialogOpen, setEditSelectorsDialogOpen] = useState(false);
  const [layoutEditable, setLayoutEditable] = useState(false);
  const { dashboardId } = useParams();
  const { copyViewToDashboard, updateView, view, widgets } = useView(viewId);
  const navigate = useNavigate();

  let rows = "auto";
  rows += " auto 1fr";

  return (
    <FullSizeDiv style={{ height: "100%", overflow: "hidden" }}>
      <CopyViewDialog
        isOpen={copyViewDialogOpen}
        onClose={() => setCopyViewDialogOpen(false)}
        viewId={viewId}
      />
      <AddWidgetToViewDialog
        isOpen={addWidgetDialogOpen}
        onClose={() => setAddWidgetDialogOpen(false)}
        viewId={viewId}
      />
      <Grid fullHeight fullWidth rows={rows} style={{ overflowX: "hidden", overflowY: "hidden" }}>
        <EditWidgetSelectorsDialog
          onClose={() => {
            setEditSelectorsDialogOpen(true);
          }}
          isOpen={editSelectorsDialogOpen}
          isView={true}
          name={view?.name}
          selectors={view?.selectors}
          updateWidget={updateView}
          queries={
            widgets
              ?.map((widget) => widget.query)
              .filter((query): query is string => query !== undefined) ?? []
          }
        />
        <Flex justifyContent="space-between">
          <Flex
            flex="1 2 auto"
            justifyContent="flex-start"
            style={{ flexShrink: 2, minWidth: 300, overflow: "hidden", padding: 4 }}
          >
            <EditableText
              maxLength={120}
              minWidth={250}
              onChange={(value) => updateView({ description: value })}
              placeholder="Edit View description..."
              value={view?.description || ""}
            />
          </Flex>
          <Flex flex="0 0 auto" justifyContent="flex-end" style={{ padding: "10px 0px" }}>
            <Button
              icon={IconNames.FILTER_LIST}
              minimal
              onClick={() => setEditSelectorsDialogOpen(true)}
              style={{ flexShrink: 0 }}
              text="Selectors"
            />
            <Popover
              content={
                <Menu>
                  <MenuItem
                    icon={IconNames.DUPLICATE}
                    onClick={async () => {
                      if (dashboardId) {
                        const response = await copyViewToDashboard(dashboardId);
                        if (response.status === EStatus.success) {
                          multiToaster.show({
                            intent: Intent.SUCCESS,
                            message: `Successfully copied ${view?.name} to this Dashboard.`,
                          });
                          await globalMutate([DASHBOARD_ROUTE, { dashboardId }]);
                        }
                      }
                    }}
                    text="Copy to this Dashboard"
                  />
                  <MenuItem
                    icon={IconNames.SEND_TO}
                    onClick={() => setCopyViewDialogOpen(true)}
                    text="Copy to a different Dashboard"
                  />
                </Menu>
              }
              minimal
            >
              <Button icon={IconNames.DUPLICATE} minimal text="Copy" />
            </Popover>
            <Button
              active={addWidgetDialogOpen}
              icon={IconNames.ADD}
              minimal
              onClick={() => setAddWidgetDialogOpen(true)}
              style={{ flexShrink: 0 }}
              text="Add Widget"
            />
            <Button
              icon={layoutEditable ? IconNames.UNLOCK : IconNames.LOCK}
              intent={layoutEditable ? Intent.PRIMARY : undefined}
              minimal
              onClick={() => setLayoutEditable(!layoutEditable)}
              text={layoutEditable ? "Unlocked" : "Locked"}
            />
            <Button
              icon={IconNames.TRASH}
              intent={Intent.DANGER}
              minimal
              onClick={async () => {
                if (confirm("Permanently remove View?")) {
                  if (await removeView(viewId)) navigate(`/dashboards/${dashboardId}`);
                }
              }}
              small
            />
          </Flex>
        </Flex>
        <NormalAlignDivider style={{ margin: "0" }} />
        <div style={{ overflow: "hidden" }}>
          <View
            editSelectorsDialogOpen={editSelectorsDialogOpen}
            layoutEditable={layoutEditable}
            setEditSelectorsDialogOpen={setEditSelectorsDialogOpen}
            viewId={viewId}
          />
        </div>
      </Grid>
    </FullSizeDiv>
  );
};

export default ViewControls;
