import React from "react";
import { MainContainer, StyledPageTitle } from "./StyledDashboardComponents";
import { H5 } from "@blueprintjs/core";

interface PageProps {
  description?: string;
  title: string;
}

const Page: React.FC<PageProps> = ({ children, description, title }) => {
  return (
    <MainContainer $padding={0} rows="auto 1fr">
      <StyledPageTitle>
        <H5>{title}</H5>
        {description && <p>{description}</p>}
      </StyledPageTitle>
      {children}
    </MainContainer>
  );
};

export default Page;
