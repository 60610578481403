export const userErrors = {
  SESSION_EXPIRED: "Session expired. Please login again",
};

export const cognitoErrors = {
  PASSWORD_RESET_REQUIRED: "PasswordResetRequiredException",
  INVALID_PARAMETER: "InvalidParameterException",
  USER_NOT_FOUND: "UserNotFoundException",
  NOT_AUTHORIZED: "NotAuthorizedException",
  INVALID_PASSWORD: "InvalidPasswordException",
  CODE_MISMATCH: "CodeMismatchException",
};
