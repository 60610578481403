import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Classes, useHotkeys } from "@blueprintjs/core";
import styled, { ThemeProvider, css } from "styled-components/macro";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import { mutate } from "swr";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  MAIN_SIDE_MENU_COLLAPSED_WIDTH,
  MAIN_SIDE_MENU_WIDTH,
  StyledComponentThemes,
  Themes,
} from "constants/uiConstants";
import {
  FETCH_USER_INFORMATION_ROUTE,
  UPDATE_TERMS_AND_CONDITIONS_STATUS_ROUTE,
} from "constants/apiConstants";
import { Milieu } from "constants/appConstants";
import AppContext, {
  dataRegexp,
  defaultAppConfig,
  importRegexp,
  viewRegexp,
} from "context/appContext";
import EnvContext, { EEnv } from "context/envContext";
import TermsAndConditionsDialog from "components/user/TermsAndConditionsDialog";
import usePrevious from "hooks/usePrevious";
import useUser from "hooks/useUser";
import { useApiCallback } from "hooks/useApiCallback";
import useSetAndGetConfig from "hooks/useSetAndGetConfig";
import colors from "styles/colors.module.scss";
import FallbackError from "components/utility/FallbackError";
import ModelHistoryContext from "context/modelHistoryContext";
import MainSideBar from "components/utility/MainSideBar";
import ModelSideBar from "components/utility/ModelSideBar";
import ImportSideBar from "components/import/ImportSideBar";
import LogoutDialog from "components/utility/Logout";
import { IAppConfig, ISize } from "types";
import { TModelHistoryMutator } from "hooks/useModelHistory";

// Removed to avoid confusion with sheets v2
// const ImportWrapper = lazy(() => import("components/import/ImportWrapper"));

const ValsysAppWrapper = styled.div`
  background-color: ${({ theme }) =>
    theme === Themes.LIGHT ? colors.appBackgroundColor : colors.darkAppBackground};
  display: flex;
  height: 100%;
  overflow: hidden;
`;

const AppBounds = styled.div<{ appSize: ISize }>`
  ${({ appSize }) =>
    appSize &&
    css`
      min-height: ${appSize.height}px;
      max-height: ${appSize.height}px;
      min-width: ${appSize.width}px;
      max-width: ${appSize.width}px;
    `}
`;

const LockOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.4;
  z-index: 20;
`;

// this comes from Authentication (jsx)
type IncomingContext = {
  size: { readonly width: number | null; readonly height: number | null };
};

export type OutletContext = {
  appSize: ISize;
  serverConfig: IAppConfig | null;
  fetchingConfig: boolean;
  setConfig: (config: Partial<IAppConfig>) => void;
  currentModelTable: string;
  setCurrentModelTable: Dispatch<SetStateAction<string>>;
  selectedModels: object;
  setSelectedModels: Dispatch<SetStateAction<object>>;
  activeSideMenu: number;
  setActiveSideMenu: Dispatch<SetStateAction<number>>;
};

const ValsysApp: React.FC = () => {
  const { size } = useOutletContext<IncomingContext>();
  const location = useLocation();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(
    window.localStorage?.getItem("mainSidebarState") === "collapsed"
  );
  const [currentModelTable, setCurrentModelTable] = useState(""); // hoisted to preserve link in SideMenu
  const [mutators, setMutators] = useState<{ mutator: TModelHistoryMutator; tableName: string }[]>(
    []
  );
  const [selectedModels, setSelectedModels] = useState({});
  const [config, _setConfig] = useState(defaultAppConfig); // object to be persisted
  const [activeSideMenu, setActiveSideMenu] = useState(1);
  // We use "milieu" here to avoid naming conflicts between contexts. It basically means the same thing.
  const [milieu, setMilieu] = useState(Milieu.Main);
  const prevMilieu = usePrevious(milieu);
  const prevActiveSideMenu = usePrevious(activeSideMenu);
  // Determines the environment, development, production or private cloud.
  const { env } = useContext(EnvContext);
  const { user, data } = useUser();
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  // eslint-disable-next-line
  const locked = useSelector((state: any) => state.dataEntry.ui.locked);

  const {
    update: updateConfig,
    config: serverConfig,
    fetchingConfig,
  } = useSetAndGetConfig<IAppConfig>("appConfig", user?.uid || null, "userID");

  // Helper that sets partial config and stores it if it should
  const setConfig = useCallback(
    (newConfig) => {
      _setConfig((config) => {
        const newFullConfig = { ...config, ...newConfig };
        // don't store default on server
        updateConfig(newFullConfig);
        return newFullConfig;
      });
    },
    [updateConfig]
  );

  // TODO: Remove this mirroring utility
  useEffect(() => {
    if (serverConfig?.theme && serverConfig?.theme !== config.theme) {
      _setConfig((config) => ({ ...config, theme: serverConfig.theme }));
    }
  }, [serverConfig?.theme, config.theme, setConfig]);

  const toggleTheme = useCallback(() => {
    const newTheme = config.theme === Themes.DARK ? Themes.LIGHT : Themes.DARK;
    dispatch({ type: "UPDATE_THEME", payload: { theme: newTheme } });
    _setConfig({ ...config, theme: newTheme });
    updateConfig({ theme: newTheme });
  }, [config, dispatch, updateConfig]);

  const classes = useMemo(() => {
    if (config.theme === Themes.DARK) {
      return `${Classes.DARK} dark-mode`;
    } else return "light-mode";
  }, [config.theme]);

  useEffect(() => {
    if (milieu === Milieu.ModelView && (!prevMilieu || prevMilieu === Milieu.Main)) {
      setActiveSideMenu(0);
    }
  }, [milieu, prevMilieu]);

  // Based on url, configure the app milieu (context).
  useEffect(() => {
    const { pathname } = location;
    let proposedMilieu;
    if (pathname.match(dataRegexp)) {
      proposedMilieu = Milieu.ModelData;
    } else if (pathname.match(viewRegexp)) {
      proposedMilieu = Milieu.ModelView;
    } else if (pathname.match(importRegexp)) {
      proposedMilieu = Milieu.Import;
    } else {
      proposedMilieu = Milieu.Main;
    }
    if (prevMilieu === proposedMilieu) return;
    setMilieu(proposedMilieu);
  }, [location, prevMilieu]);

  const fallbackComponent = (ev: { error: Error }) => (
    <FallbackError error={ev.error} showError={false} />
  );

  // setup sentry user
  useEffect(() => {
    if (!user) return;
    Sentry.setUser({
      id: user.uid,
      username: user.fullname,
      email: user.email,
    });
  }, [user]);

  const appSize = useMemo<ISize>(() => {
    return {
      height: size?.height,
      width: size?.width
        ? milieu === Milieu.Main
          ? size.width - (collapsed ? MAIN_SIDE_MENU_COLLAPSED_WIDTH : MAIN_SIDE_MENU_WIDTH)
          : size.width - 50
        : undefined,
    };
  }, [milieu, collapsed, size]);

  const { callback: tAndCCallback, response: tAndCResponse } = useApiCallback();

  useEffect(() => {
    if (tAndCResponse && tAndCResponse.status !== "success") {
      mutate(FETCH_USER_INFORMATION_ROUTE);
    }
  }, [tAndCResponse]);

  const updateTermsAndConditionsStatus = (status: boolean) => {
    tAndCCallback(UPDATE_TERMS_AND_CONDITIONS_STATUS_ROUTE, { status });
    mutate(
      FETCH_USER_INFORMATION_ROUTE,
      { ...data, data: { ...user, userHasAcceptedTerms: status } },
      false
    );
  };

  const userHasAcceptedTerms = !user || user?.userHasAcceptedTerms || env === EEnv.PRIVATE_CLOUD;

  const globalHotkeys = useMemo(() => {
    return [
      {
        allowInInput: true,
        combo: "meta + alt + b",
        global: true,
        label: "Open/Close the side bar",
        onKeyDown: () => {
          setActiveSideMenu(activeSideMenu ? 0 : prevActiveSideMenu || 1);
        },
        preventDefault: true,
      },
    ];
  }, [activeSideMenu, prevActiveSideMenu, setActiveSideMenu]);
  const { handleKeyDown: hotkeysKeyDown, handleKeyUp: hotkeysKeyUp } = useHotkeys(globalHotkeys);

  let sidebar: JSX.Element | undefined;

  switch (milieu) {
    case Milieu.ModelData:
    case Milieu.ModelView:
      sidebar = (
        <ModelSideBar
          activeSideMenu={activeSideMenu}
          appContext={milieu}
          setActiveSideMenu={setActiveSideMenu}
        />
      );
      break;
    case Milieu.Import:
      sidebar = (
        <ImportSideBar activeSideMenu={activeSideMenu} setActiveSideMenu={setActiveSideMenu} />
      );
      break;
    default:
      sidebar = (
        <MainSideBar
          collapsed={collapsed}
          modelTableID={currentModelTable}
          modelTables={serverConfig?.modelTables || null}
          setCollapsed={(collapsed) => {
            setCollapsed(collapsed);
            window.localStorage?.setItem("mainSidebarState", collapsed ? "collapsed" : "");
          }}
        />
      );
  }

  const scTheme = useMemo(() => {
    return StyledComponentThemes[config?.theme];
  }, [config?.theme]);

  return (
    <DndProvider backend={HTML5Backend}>
      {locked && milieu === Milieu.Main ? <LockOverlay /> : null}
      <AppContext.Provider
        value={{
          appSize,
          config,
          milieu,
          toggleTheme,
          setConfig,
          setLogoutDialogOpen,
          setActiveSideMenu,
        }}
      >
        <ThemeProvider theme={scTheme}>
          <LogoutDialog
            isOpen={logoutDialogOpen}
            onClose={() => {
              setLogoutDialogOpen(false);
            }}
          />
          <ModelHistoryContext.Provider
            value={{
              mutators,
              setMutators,
              selectedModels,
              setSelectedModels,
            }}
          >
            <ValsysAppWrapper
              className={classes}
              onKeyDown={hotkeysKeyDown}
              onKeyUp={hotkeysKeyUp}
              theme={config.theme}
            >
              <TermsAndConditionsDialog
                closed={env === EEnv.PRIVATE_CLOUD || userHasAcceptedTerms}
                theme={config.theme}
                updateTermsAndConditionsStatus={updateTermsAndConditionsStatus}
              />
              {sidebar}
              <Sentry.ErrorBoundary
                fallback={fallbackComponent}
                beforeCapture={(scope) => {
                  scope.setTag("component", "ValsysApp");
                }}
              >
                <AppBounds appSize={appSize}>
                  <Outlet
                    context={{
                      appSize,
                      serverConfig,
                      fetchingConfig,
                      setConfig,
                      currentModelTable,
                      setCurrentModelTable,
                      selectedModels,
                      setSelectedModels,
                      activeSideMenu,
                      setActiveSideMenu,
                    }}
                  />
                </AppBounds>
              </Sentry.ErrorBoundary>
            </ValsysAppWrapper>
          </ModelHistoryContext.Provider>
        </ThemeProvider>
      </AppContext.Provider>
    </DndProvider>
  );
};

export default ValsysApp;
