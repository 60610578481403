import {
  Alignment,
  Button,
  ButtonGroup,
  H6,
  HTMLSelect,
  Intent,
  NumericInput,
  Popover,
  Switch,
} from "@blueprintjs/core";
import { Flex, NormalAlignDivider } from "components/utility/StyledComponents";
import React from "react";
import { ChartDataset, EWidgetType2 } from "types/vsl";
import { ChartWidgetConfig } from "types/widget";
import ColorPicker from "components/utility/ColorPicker";
import { GraphSeriesColors } from "constants/sharePriceConstants";
import {
  ChartSeriesOptions,
  DashStyles,
  ESeriesStatistic,
  YAxisId,
} from "constants/chartConstants";
import { IconNames } from "@blueprintjs/icons";

interface ChartSeriesSettingsProps {
  chartRendererConfig?: ChartWidgetConfig;
  chartDatasets: ChartDataset[] | null;
  type:
    | EWidgetType2.BAR_CHART
    | EWidgetType2.LINE_CHART
    | EWidgetType2.PIE_CHART
    | EWidgetType2.SCATTER_CHART;
  updateConfig: (details: Partial<ChartWidgetConfig>) => void;
}

/**
 * Per series settings, to be rendered in the EditChartDialog.
 * */
const ChartSeriesSettings: React.FC<ChartSeriesSettingsProps> = ({
  chartRendererConfig,
  chartDatasets,
  type,
  updateConfig,
}) => {
  const updateSeriesOptions = (datasetLabel: string, details: Partial<ChartSeriesOptions>) => {
    const nextSeriesOpts = {
      ...chartRendererConfig?.seriesOptions,
      [datasetLabel]: { ...chartRendererConfig?.seriesOptions?.[datasetLabel], ...details },
    };
    updateConfig({ seriesOptions: nextSeriesOpts });
  };

  if (!chartDatasets) return null;

  return (
    <Flex alignItems="flex-start" flexDirection="column" fullWidth justifyContent="flex-start">
      {chartDatasets.map((dataset, i) => {
        const seriesOptions = chartRendererConfig?.seriesOptions?.[dataset.label];

        const axis = seriesOptions?.axis || YAxisId.RIGHT;
        const color = seriesOptions?.color || GraphSeriesColors[i];
        const dashStyle = seriesOptions?.dashStyle || "Solid";
        const statistic = seriesOptions?.statistic;
        const lineWidth = seriesOptions?.lineWidth || 2;
        const endLabel = seriesOptions?.endLabel || false;

        return (
          <React.Fragment key={dataset.label}>
            <Flex
              alignItems="flex-start"
              flexDirection="column"
              fullWidth
              justifyContent="space-between"
              rowGap={15}
            >
              <H6>{dataset.label}</H6>
              <Flex fullWidth justifyContent="space-between">
                Color:
                <ButtonGroup>
                  <Button
                    icon={IconNames.RESET}
                    minimal
                    onClick={() => {
                      if (color !== GraphSeriesColors[i]) {
                        updateSeriesOptions(dataset.label, {
                          ...seriesOptions,
                          color: GraphSeriesColors[i],
                        });
                      }
                    }}
                    small
                  />
                  <Popover
                    content={
                      <ColorPicker
                        color={color}
                        colors={GraphSeriesColors}
                        onChange={(color) => updateSeriesOptions(dataset.label, { color })}
                      />
                    }
                  >
                    <Button small style={{ backgroundColor: color }} />
                  </Popover>
                </ButtonGroup>
              </Flex>
              {(type === EWidgetType2.LINE_CHART || type === EWidgetType2.BAR_CHART) && (
                <>
                  <Flex fullWidth justifyContent="space-between">
                    Axis:
                    <ButtonGroup>
                      <Button
                        active={axis === YAxisId.LEFT}
                        intent={axis === YAxisId.LEFT ? Intent.PRIMARY : undefined}
                        onClick={() => {
                          if (axis !== YAxisId.LEFT) {
                            updateSeriesOptions(dataset.label, { axis: YAxisId.LEFT });
                          }
                        }}
                        small
                        text="Left"
                      />
                      <Button
                        active={axis === YAxisId.RIGHT}
                        intent={axis === YAxisId.RIGHT ? Intent.PRIMARY : undefined}
                        onClick={() => {
                          if (axis !== YAxisId.RIGHT) {
                            updateSeriesOptions(dataset.label, { axis: YAxisId.RIGHT });
                          }
                        }}
                        small
                        text="Right"
                      />
                    </ButtonGroup>
                  </Flex>
                  <Flex fullWidth justifyContent="space-between">
                    Statistics:
                    <ButtonGroup>
                      <HTMLSelect
                        onChange={(e) => {
                          const stType = e.target.value;
                          updateSeriesOptions(dataset.label, {
                            statistic: stType as ESeriesStatistic,
                          });
                        }}
                        options={Object.values(ESeriesStatistic)}
                        disabled={axis === YAxisId.LEFT}
                        value={statistic}
                      />
                    </ButtonGroup>
                  </Flex>
                </>
              )}
              {type === EWidgetType2.LINE_CHART && (
                <>
                  <Flex fullWidth justifyContent="space-between">
                    Line style:
                    <HTMLSelect
                      onChange={(e) => {
                        updateSeriesOptions(dataset.label, {
                          dashStyle: e.target.value as Highcharts.DashStyleValue,
                        });
                      }}
                      options={DashStyles}
                      value={dashStyle}
                    />
                  </Flex>
                  <Flex fullWidth justifyContent="space-between">
                    Line width:
                    <NumericInput
                      clampValueOnBlur={true}
                      max={5}
                      min={1}
                      onValueChange={(nextLineWidth) =>
                        updateSeriesOptions(dataset.label, { lineWidth: nextLineWidth })
                      }
                      small
                      stepSize={1}
                      value={lineWidth}
                    />
                  </Flex>
                  <Flex fullWidth justifyContent="space-between">
                    Series label:
                    <Switch
                      alignIndicator={Alignment.RIGHT}
                      checked={endLabel}
                      onChange={(e) => {
                        const checked = (e.target as HTMLInputElement).checked;
                        updateSeriesOptions(dataset.label, { endLabel: checked });
                      }}
                    />
                  </Flex>
                </>
              )}
            </Flex>
            <NormalAlignDivider style={{ margin: "10px 0 10px 0" }} />
          </React.Fragment>
        );
      })}
    </Flex>
  );
};

export default ChartSeriesSettings;
