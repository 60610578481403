import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Callout,
  Collapse,
  Colors,
  FormGroup,
  InputGroup,
  Intent,
  Section,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Flex } from "components/utility/StyledComponents";
import { formatFact, formatValue } from "utils/formatter";
import { IResponse } from "types";
import {
  EMethods,
  SIMULATION_CONTROL_PANEL_CONFIG_ROUTE,
  SIMULATION_CONTROL_PANEL_ROUTE,
  Status,
} from "constants/apiConstants";
import { getValidJSON, swrApi } from "utils/api";
import { IBasicModelInfo } from "types/model";
import { useParams } from "react-router-dom";
import Table, { TableStyleMatrix } from "components/table/Table";
import LoadingIndicator from "components/utility/LoadingIndicator";
import { useApiCallback } from "hooks/useApiCallback";
import { BaseFormatObject } from "types/format";
import useSWRImmutable from "swr/immutable";
import { colorizeNumeric, sortAlpha } from "utils/generic";
import styled from "styled-components/macro";
import { TableWidgetColumnConfig, TableWidgetConfig } from "types/widget";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import { MISSING_VALUE } from "constants/appConstants";

const StyledFormGroup = styled(FormGroup)<{
  $boldLabel?: boolean;
  $labelAlign?: string;
}>`
  padding-right: 10px;
  flex: 1;

  .bp5-label {
    text-align: ${({ $labelAlign }) => $labelAlign || "left"};
    font-weight: ${({ $boldLabel }) => ($boldLabel ? "bold" : "normal")};
  }
`;

const StyledInputGroup = styled(InputGroup)`
  .bp5-input {
    color: initial !important;
  }
`;

interface ScenarioConfig {
  kpis: string[];
  lineItems: ScenarioLineItem[];
  consensusItems: ScenarioLineItem[];
  model: IBasicModelInfo<string>;
}

interface ScenarioFact {
  id: string;
  period: number;
  value: string;
  format?: string;
}
interface ScenarioLineItem {
  id: string;
  name: string;
  edges: { facts: ScenarioFact[] };
  tags: string[];
  order: number;
}

interface ScenarioResult {
  simulation: ScenarioLineItem[];
}

interface ScenarioResultItem {
  id: string;
  outputs: string;
  [key: string]: string;
}

const CONTROL_PANEL_HISTORICAL_YEARS = 3;
const CONTROL_PANEL_FORECAST_YEARS = 5;
const RESULTS_TABLE_ROW_HEADER_SIZE = 200;

const ModelScenario: React.FC = () => {
  const params = useParams();
  const {
    config: { theme },
  } = useContext(AppContext);

  const [controlsCollapsed, setControlsCollapsed] = useState(false);
  const [localItems, setLocalItems] = useState<ScenarioLineItem[]>([]);

  const modelId = params?.model ? params.model.split("=")[1] : "";

  const { data: modelData, isLoading: isLoadingModel } = useSWRImmutable<IResponse<ScenarioConfig>>(
    [SIMULATION_CONTROL_PANEL_CONFIG_ROUTE, { modelId }],
    swrApi
  );

  const {
    callback: getScenarioCallback,
    response: scenarioRes,
    fetching: fetchingScenario,
  } = useApiCallback<ScenarioResult>();

  const getScenarioData = useCallback(() => {
    getScenarioCallback(SIMULATION_CONTROL_PANEL_ROUTE, {
      method: EMethods.POST,
      body: {
        modelId,
        edits: localItems,
      },
    });
  }, [getScenarioCallback, modelId, localItems]);

  const setupLocalModelKpis = useCallback(() => {
    if (!modelData?.data) return;
    const { startPeriod } = modelData.data.model;
    return modelData?.data.lineItems.map((li) => {
      const nextFacts: ScenarioFact[] = [];
      li.edges.facts.forEach((fact) => {
        if (
          fact.period <= startPeriod - CONTROL_PANEL_HISTORICAL_YEARS ||
          fact.period > startPeriod + CONTROL_PANEL_FORECAST_YEARS
        )
          return;
        nextFacts.push({
          id: fact.id,
          value: formatFact(fact.value, fact.format),
          period: fact.period,
          format: fact.format,
        });
      });

      return {
        id: li.id,
        name: li.name,
        tags: li.tags,
        edges: {
          facts: nextFacts,
        },
        order: li.order,
      };
    });
  }, [modelData?.data]);

  const onChangeKPIValue = (value: string, lineItem: ScenarioLineItem, fact: ScenarioFact) => {
    setLocalItems((prevItems) => {
      const lineItemIdx = prevItems.findIndex((item) => item.id === lineItem.id);
      const factIdx = lineItem.edges.facts.findIndex((f) => f.id === fact.id);
      const nextItems = [...prevItems];
      const nextLineItem = { ...prevItems[lineItemIdx] };

      nextLineItem.edges.facts[factIdx] = {
        ...fact,
        value: value,
      };
      nextItems[lineItemIdx] = nextLineItem;
      return nextItems;
    });
  };

  /** setup local state for items */
  useEffect(() => {
    if (!modelData) return;
    setLocalItems(setupLocalModelKpis() || []);
  }, [modelData, setupLocalModelKpis]);

  const resetToDefault = () => {
    setLocalItems(setupLocalModelKpis() || []);
  };

  let colTemplates: string[] = [];
  let tableConfig: TableWidgetConfig = {};
  if (modelData?.data) {
    colTemplates =
      scenarioRes?.data?.simulation[0].edges.facts
        .filter(
          (fact) =>
            fact.period > modelData.data.model.startPeriod - CONTROL_PANEL_HISTORICAL_YEARS &&
            fact.period <= modelData.data.model.startPeriod + CONTROL_PANEL_FORECAST_YEARS
        )
        .map((f) => String(f.period))
        .sort(sortAlpha) || [];
    colTemplates.unshift("outputs");

    const tableConfigCols: TableWidgetColumnConfig[] = colTemplates.map((colTemplate, idx) => ({
      id: colTemplate,
      header: idx === 0 ? "Outputs" : "FY " + colTemplate,
      ...(idx > 0 &&
        idx <= CONTROL_PANEL_HISTORICAL_YEARS &&
        Number(colTemplate) <= modelData.data.model.startPeriod && {
          meta: {
            tdStyle: {
              backgroundColor: theme === Themes.LIGHT ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY4,
            },
          },
        }),
    }));
    if (tableConfigCols.length) tableConfigCols[0].width = RESULTS_TABLE_ROW_HEADER_SIZE;
    tableConfig = {
      columns: tableConfigCols,
      layoutMode: "auto",
    };
  }

  const scenarioResItems = scenarioRes?.data?.simulation.map((item) => {
    const resItem: ScenarioResultItem = {
      outputs: item.name,
      id: item.id,
    };
    item.edges.facts.forEach((f) => {
      resItem[String(f.period)] =
        f.value && f.value !== MISSING_VALUE
          ? formatValue(f.value, {
              ...BaseFormatObject,
              ...getValidJSON(f.format || ""),
            })
          : f.value;
    });
    return resItem;
  });

  // results table styles
  const tableStyleMatrix: TableStyleMatrix = [];
  scenarioRes?.data?.simulation.forEach((li, liIdx) => {
    li.edges.facts.forEach((fact, factIdx) => {
      const colStyle = tableStyleMatrix[factIdx + 1] || (tableStyleMatrix[factIdx + 1] = []);
      // italic stripes
      if (liIdx % 2 === 0) colStyle[liIdx] = { ...colStyle[liIdx], fontStyle: "italic" };
      if (li.name.includes("Scenario")) {
        // colorize numerics for scenario line items
        colStyle[liIdx] = { ...colStyle[liIdx], color: colorizeNumeric(fact.value) };
      }
    });
  });

  return (
    <div style={{ padding: "20px", overflowY: "auto", height: "calc(100vh - 40px)" }}>
      <Section
        compact
        title="Scenario Controls"
        rightElement={
          <Button
            icon={controlsCollapsed ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_UP}
            minimal
            onClick={() => setControlsCollapsed(!controlsCollapsed)}
          />
        }
      >
        <Collapse isOpen={!controlsCollapsed}>
          {isLoadingModel && (
            <div style={{ padding: "30px 10px" }}>
              <LoadingIndicator
                status={{ intent: Intent.PRIMARY, message: "Loading model KPIs" }}
              />
            </div>
          )}
          {modelData?.status === Status.FAILED && (
            <Callout intent={Intent.WARNING} title="Error retrieving scenario configuration" />
          )}
          {modelData?.data ? (
            <div style={{ padding: "10px 15px", overflowY: "auto" }}>
              <hr
                style={{
                  border: "1px solid",
                  color: theme === Themes.LIGHT ? Colors.LIGHT_GRAY4 : Colors.DARK_GRAY5,
                  height: "calc(100% - 85px)",
                  position: "absolute",
                  left: "428px",
                  top: "10px",
                }}
              />
              {localItems.map((lineItem, itemIdx) => (
                <Flex
                  key={lineItem.id}
                  alignItems="flex-start"
                  flexDirection="row"
                  justifyContent="flex-start"
                >
                  <div style={{ width: "250px", marginRight: "10px", marginBottom: "25px" }}>
                    <StyledFormGroup label={`Control Panel KPI ${itemIdx + 1}`} $boldLabel={true}>
                      <StyledInputGroup disabled={true} defaultValue={lineItem.name} />
                    </StyledFormGroup>
                  </div>
                  <Flex flexDirection="column" style={{ width: "150px", marginRight: "20px" }}>
                    <StyledFormGroup label="Data Source">
                      <InputGroup disabled={true} />
                    </StyledFormGroup>
                    <span
                      style={{
                        marginTop: "-12px",
                        textAlign: "right",
                        width: "100%",
                        paddingRight: "10px",
                      }}
                    >
                      <i>Consensus, %</i>
                    </span>
                  </Flex>
                  {lineItem.edges.facts
                    .sort((a, b) => a.period - b.period)
                    .map((fact) => {
                      const { startPeriod } = modelData.data.model;
                      // map consensus item by order property
                      const consensusItem = modelData.data.consensusItems.find(
                        (item) => item.order === lineItem.order
                      );
                      // map consensus fact by period
                      const consensusFact = consensusItem?.edges.facts.find(
                        (consensusFact) => consensusFact.period === fact.period
                      );
                      return (
                        <StyledFormGroup
                          key={fact.id}
                          label={itemIdx === 0 ? `FY ${fact.period}` : null}
                          $boldLabel={fact.period > startPeriod}
                          $labelAlign="right"
                          style={{ ...(itemIdx !== 0 && { paddingTop: "24px" }) }}
                        >
                          <InputGroup
                            disabled={fact.period <= startPeriod}
                            value={fact.value}
                            onChange={(event) =>
                              onChangeKPIValue(event.target.value, lineItem, fact)
                            }
                            style={{ textAlign: "right" }}
                          />
                          {consensusFact && (
                            <span
                              style={{
                                display: "block",
                                width: "100%",
                                textAlign: "right",
                                fontStyle: "italic",
                                paddingTop: "3px",
                              }}
                            >
                              {formatFact(consensusFact.value, consensusFact.format)}
                            </span>
                          )}
                        </StyledFormGroup>
                      );
                    })}
                </Flex>
              ))}
            </div>
          ) : null}
          <Section style={{ padding: "10px" }}>
            <Flex flexDirection="row" justifyContent="flex-end">
              <Button
                disabled={!modelData?.data}
                icon={IconNames.RESET}
                onClick={resetToDefault}
                text="Reset to default"
                style={{ marginRight: "10px" }}
              />
              <Button
                disabled={!modelData?.data}
                text="Run"
                intent={Intent.PRIMARY}
                onClick={getScenarioData}
              />
            </Flex>
          </Section>
        </Collapse>
      </Section>
      <Section compact title="Results" style={{ marginTop: "15px" }}>
        {fetchingScenario && (
          <div style={{ padding: "30px 10px" }}>
            <LoadingIndicator status={{ intent: Intent.PRIMARY, message: "Running scenario..." }} />
          </div>
        )}
        {scenarioRes?.status === Status.FAILED && (
          <Callout intent={Intent.WARNING} title="Error running scenario" />
        )}
        {scenarioRes?.data && (
          <>
            <Table
              data={scenarioResItems || []}
              columnTemplates={colTemplates}
              configOverrides={tableConfig}
              enableConfigOverrides={false}
              enableSelection={false}
              id="simulation-results-table"
              loading={fetchingScenario}
              styleMatrix={tableStyleMatrix}
            />
            <div style={{ width: "100%", padding: "10px", textAlign: "right" }}>
              <Button disabled={true} intent={Intent.PRIMARY} text="Save" />
            </div>
          </>
        )}
      </Section>
    </div>
  );
};

export default ModelScenario;
