import React, { Dispatch, forwardRef, SetStateAction } from "react";
import styled from "styled-components/macro";

import DataEntryTableControls from "components/model/DataEntryTableControls";
import FormulaEditor from "components/formula/FormulaEditor";
import { FullWidthDivider, MaybeStickyDiv } from "components/utility/StyledComponents";
import { IVSTableHandle } from "components/model/VSTable";

import colors from "styles/colors.module.scss";
import { TGridChangeOpt } from "constants/modelConstants";
import { Themes } from "constants/uiConstants";

import { ISize, TTheme } from "types";
import { INormalizedImportFact } from "types/import";
import { ICase, IFact, IModule } from "types/model";
import { IFocusedCell, IFormulaConfig } from "types/dataEntry";

const StyledDataEntryHeader = styled.div<{ $theme: TTheme; $size: ISize }>`
  background-color: ${({ $theme }) =>
    $theme === Themes.DARK ? colors.darkModuleBackgroundColor : colors.moduleBackgroundColor};
  margin: 0 auto 0 auto;
  width: ${({ $size }) => $size.width + "px"};
`;

interface IDataEntryHeaderProps {
  addColumn: () => void;
  availableGridChangeOps?: { [key: string]: TGridChangeOpt };
  availableStateChanges?: { undo: boolean; redo: boolean };
  autoAdjustColsWidth: boolean;
  copy: () => void;
  currentCase: ICase;
  currentModule: IModule;
  diagramOpen: boolean;
  deleteColumn: (position: "start" | "end") => void;
  deleteLineItem: () => void;
  editDecimalPlaces: () => void;
  editMode: string;
  focusedCell: IFocusedCell<IFact | INormalizedImportFact> | null;
  formatAndEditCells: () => void;
  formulaConfig: IFormulaConfig;
  handleAddLineItem: () => void;
  locked: boolean;
  moduleList: IModule[];
  setAutoAdjustColsWidth: (autoAdjust: boolean) => void;
  paste: (raw: boolean) => void;
  rawValues: boolean | undefined;
  rowHeaderFocus: boolean;
  setFactError: (error: string) => void;
  setFormulaBarHeight: Dispatch<SetStateAction<number>>;
  setRawValues: Dispatch<SetStateAction<boolean>>;
  size: ISize;
  tableRef: React.MutableRefObject<IVSTableHandle | null>;
  theme: TTheme;
}

/**
 * Holds all the static headers of data entry that include {@link DataEntryTableControls} and {@link FormulaEditor}.
 * <p>Parent Component: {@link DataEntry}</p>
 * @category Data Entry
 */
const DataEntryHeader = React.memo(
  forwardRef<HTMLDivElement, IDataEntryHeaderProps>(
    (
      {
        addColumn,
        availableGridChangeOps,
        autoAdjustColsWidth,
        copy,
        currentCase,
        currentModule,
        deleteColumn,
        deleteLineItem,
        editDecimalPlaces,
        editMode,
        focusedCell,
        formatAndEditCells,
        formulaConfig,
        handleAddLineItem,
        locked,
        moduleList,
        paste,
        rawValues,
        rowHeaderFocus,
        setAutoAdjustColsWidth,
        setFactError,
        setFormulaBarHeight,
        setRawValues,
        size,
        tableRef,
        theme,
      },
      ref
    ) => {
      return (
        <MaybeStickyDiv ref={ref} pinned={false} pixels={101} position="top">
          <StyledDataEntryHeader $size={size} $theme={theme}>
            <DataEntryTableControls
              autoAdjustColsWidth={autoAdjustColsWidth}
              addColumn={addColumn}
              availableGridChangeOps={availableGridChangeOps}
              copy={copy}
              currentCase={currentCase}
              currentModule={currentModule}
              deleteColumn={deleteColumn}
              deleteLineItem={deleteLineItem}
              editDecimalPlaces={editDecimalPlaces}
              focusedCell={focusedCell}
              formatAndEditCells={formatAndEditCells}
              handleAddLineItem={handleAddLineItem}
              locked={locked}
              moduleList={moduleList}
              rawValues={rawValues}
              setAutoAdjustColsWidth={setAutoAdjustColsWidth}
              setRawValues={setRawValues}
              paste={paste}
              tableRef={tableRef}
              theme={theme}
            />
            <FullWidthDivider />
            <FormulaEditor
              // eslint-disable-next-line
              // @ts-ignore
              config={formulaConfig}
              disabled={locked || rowHeaderFocus}
              item={rowHeaderFocus ? null : focusedCell}
              mode={editMode}
              setFormulaBarHeight={setFormulaBarHeight}
              setFactError={setFactError}
              tableRef={tableRef}
            />
            <FullWidthDivider />
          </StyledDataEntryHeader>
        </MaybeStickyDiv>
      );
    }
  )
);

DataEntryHeader.displayName = "DataEntryHeader";
export default DataEntryHeader;
