import React from "react";
import styled from "styled-components/macro";
import {
  Button,
  Classes,
  FormGroup,
  H3,
  Intent,
  MenuItem,
  Panel,
  PanelProps,
} from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";

import StyledConfigurationPanel from "components/utility/StyledConfigurationPanel";
import { IModelConfiguration, TConfigureModelPanels } from "types/createModel";
import useModelConfigOptions from "hooks/useModelConfigOptions";
import { Flex } from "components/utility/StyledComponents";
import { IConfigureModelContext, withConfigureModelContext } from "context/configureModelContext";
import ModelTimeConfig from "./ModelTimeConfig";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  width: 100%;
`;

interface IConfigureCompanyProps {
  config: IModelConfiguration &
    Pick<IModelConfiguration, "companyName" | "ticker" | "exchange" | "variables">;
  isImport?: false;
  title?: string;
}

export type TConfigureCompany = PanelProps<
  IConfigureCompanyProps & Omit<IConfigureModelContext, "config">
>;

const ConfigureCompany: React.FC<TConfigureCompany> = ({
  config,
  isImport = false,
  openPanel,
  setConfig,
  title = "Configure Company",
}) => {
  const { currencies, geographies, industries } = useModelConfigOptions();

  const renderOptions: ItemRenderer<string> = (option, { modifiers, handleClick }) => {
    return <MenuItem active={modifiers.active} key={option} onClick={handleClick} text={option} />;
  };

  const renderCurrency: ItemRenderer<{ ISO: string; name: string }> = (
    currency,
    { modifiers, handleClick }
  ) => {
    const text = (
      <span>
        {currency.name} (<span>{currency.ISO}</span>)
      </span>
    );
    return (
      <MenuItem active={modifiers.active} key={currency.ISO} onClick={handleClick} text={text} />
    );
  };

  const nextPanel = () => {
    openPanel({
      props: {
        title: isImport ? "Import: Date and Time-Series" : "Date and Time-Series",
        isImport,
      },
      renderPanel: withConfigureModelContext(ModelTimeConfig as React.FC<TConfigureModelPanels>),
      title: isImport ? "Import: Date and Time-Series" : "Date and Time-Series",
      // eslint-disable-next-line @typescript-eslint/ban-types
    } as Panel<object>);
  };

  const canAdvance = config.geography && config.industry && config.currency;

  return (
    <StyledConfigurationPanel>
      <H3>{title}</H3>
      <p className={Classes.RUNNING_TEXT} style={{ fontWeight: "bold" }}>
        Configuring company: <span>{config.companyName}</span>{" "}
        <span className={Classes.TEXT_MUTED}>
          {!config.blank
            ? `(${
                config.exchange
                  ? `${config.exchange["Google Prefix"] || config.exchange["ISO MIC"]}:`
                  : ""
              }${config.ticker})`
            : ""}
        </span>
      </p>
      <StyledForm>
        <div>
          <FormGroup label="Geography:">
            <Select<string>
              itemRenderer={renderOptions}
              items={geographies?.data || []}
              onItemSelect={(geography) => setConfig({ ...config, geography })}
            >
              <Button rightIcon="caret-down" text={config.geography || "Select geography..."} />
            </Select>
          </FormGroup>
          <FormGroup label="Industry:">
            <Select<string>
              itemRenderer={renderOptions}
              items={industries?.data || []}
              onItemSelect={(industry) => setConfig({ ...config, industry })}
            >
              <Button rightIcon="caret-down" text={config.industry || "Select industry..."} />
            </Select>
          </FormGroup>
          <FormGroup label="Currency:">
            <Select<{ ISO: string; name: string }>
              itemRenderer={renderCurrency}
              items={currencies?.data || []}
              onItemSelect={(currency) => setConfig({ ...config, currency })}
            >
              <Button
                rightIcon="caret-down"
                text={
                  config?.currency ? (
                    <span>
                      {config.currency?.name ? (
                        <>
                          {config.currency?.name}{" "}
                          <span className={Classes.TEXT_MUTED}>({config.currency.ISO})</span>
                        </>
                      ) : (
                        config.currency?.ISO
                      )}
                    </span>
                  ) : (
                    "Select currency..."
                  )
                }
              />
            </Select>
          </FormGroup>
        </div>
        <Flex
          alignItems="flex-end"
          fullWidth
          justifyContent="space-between"
          style={{ alignSelf: "flex-end" }}
        >
          <Button className={Classes.FIXED} icon="delete" intent={Intent.DANGER} text="Clear" />
          <Button
            className={Classes.FIXED}
            disabled={!canAdvance}
            intent={Intent.PRIMARY}
            onClick={nextPanel}
            rightIcon="circle-arrow-right"
            text="Next"
          />
        </Flex>
      </StyledForm>
    </StyledConfigurationPanel>
  );
};

export default ConfigureCompany;
