import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Classes, Dialog, DialogProps, Intent, TagInput } from "@blueprintjs/core";
import { ILineItem } from "types/model";
import { TNormalizedImportLineItem } from "types/import";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import { editLineItemTags } from "actions/dataEntryActions";

interface IEditTagDialogProps extends DialogProps {
  lineItem?: ILineItem | TNormalizedImportLineItem;
  modelID: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const EditTagDialog: React.FC<IEditTagDialogProps> = ({ isOpen, lineItem, modelID, setIsOpen }) => {
  const dispatch = useDispatch();
  const [dirtyTagState, setDirtyTagState] = useState<string[] | undefined>(lineItem?.tags);
  const {
    config: { theme },
  } = useContext(AppContext);
  useEffect(() => {
    if (lineItem) {
      setDirtyTagState(lineItem?.tags);
    }
  }, [lineItem]);
  const onClose = () => {
    setIsOpen(false);
  };
  const handleAddTags = (tags: React.ReactNode[]) => {
    setDirtyTagState(dirtyTagState?.concat(tags as string[]) || (tags as string[]));
  };
  const handleTagRemove = (toRemove: React.ReactNode) => {
    const nextDirtyTags = dirtyTagState?.filter((tag) => tag !== toRemove);
    setDirtyTagState(nextDirtyTags);
  };

  const handleEditTags = () => {
    if (lineItem) {
      dispatch(editLineItemTags(lineItem.uid, modelID, dirtyTagState, lineItem.tags));
    }
  };

  return (
    <Dialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      isOpen={isOpen}
      onClose={onClose}
      title={`Edit Line Item Tags: ${lineItem?.name}`}
    >
      <div className={Classes.DIALOG_BODY}>
        <p className={Classes.RUNNING_TEXT}>
          Use the input below to add and remove tags from a model. Editing tags make break view
          widgets or model groups.
        </p>
        <TagInput
          onAdd={handleAddTags}
          onRemove={handleTagRemove}
          values={dirtyTagState ? dirtyTagState : []}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" onClick={onClose} text="Cancel" />
          <Button
            disabled={dirtyTagState?.length === lineItem?.tags?.length}
            icon="edit"
            intent={Intent.PRIMARY}
            onClick={handleEditTags}
            text="Edit Tags"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default EditTagDialog;
