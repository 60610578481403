import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { Colors, Icon, Spinner, SpinnerSize } from "@blueprintjs/core";
import usePrevious from "hooks/usePrevious";
import { DataEntryEditMode } from "constants/dataEntryUIConstants";
import {
  updateEditMode,
  updateFocusedCell,
  updateFormula,
  updateFormulaTempInsert,
  updateSelectedRegions,
} from "actions/dataEntryUIActions";
import { Flex } from "components/utility/StyledComponents";
import { selectedRegionsObjectSelector } from "selectors/dataEntrySelectors";
import { useLatest } from "hooks/useLatest";
import {
  equalCells,
  equalRegions,
  extendSelection,
  regionEqualsCell,
  selectionFromCell,
} from "utils/modelGrid";
import { selectorObjToString, vsTokens } from "utils/formulas";
import AppContext from "context/appContext";
import {
  FX_EDITOR_ERROR_HEIGHT,
  FX_EDITOR_ERRORS_MAX_HEIGHT,
  FX_EDITOR_HEIGHT,
  FX_EDITOR_LINE_HEIGHT,
  FX_EDITOR_MAX_HEIGHT,
  FX_EDITOR_SIM_HEIGHT,
  Themes,
} from "constants/uiConstants";
import { currentModuleSelector } from "selectors/modelSelectors";
import CodeMirror, { EditorSelection, EditorState, StateEffect } from "@uiw/react-codemirror";
import { EditorView, keymap } from "@codemirror/view";
import { defaultKeymap } from "@codemirror/commands";
import { Prec } from "@codemirror/state";

const StyledFormulaBar = styled.div`
  flex-direction: column;
  display: flex;
  height: ${({ mode }) => {
    if (mode === "import") return "unset";
    else return "100%";
  }};
  padding: ${({ mode }) => (mode === "simulation" ? "0 0 10px 0" : "0 2px 0 0")};
`;

/*const StyledErrorList = styled.div`
  height: ${({ mode }) => (mode === "simulation" ? "initial" : "100%")};
  max-height: 80px;
  overflow: scroll;
  margin-left: 35px;
  padding: 5px 0;
  cursor: default;
`;

const StyledError = styled.p`
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0;
`; */

const StyledFormulaInfo = styled.span`
  position: absolute;
  right: 0;
  font-style: italic;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  background-color: ${({ theme }) =>
    theme === Themes.DARK ? Colors.DARK_GRAY3 : Colors.LIGHT_GRAY4};
  cursor: default;
`;

const codeMirrorFxBasicSetup = {
  closeBrackets: false,
  allowMultipleSelections: false,
  rectangularSelection: false,
  highlightActiveLine: false,
  highlightSelectionMatches: false,
  bracketMatching: false,
  lineNumbers: false,
};

/**
 * Formula editor component that hosts AceEditor component.
 * @category Formula
 */
const FormulaEditor = React.memo(
  ({
    config,
    disabled,
    //enableLinting = true,
    fx,
    item,
    loading,
    mode,
    setFactError,
    setFormulaBarHeight,
    tableRef,
  }) => {
    /** TODO: Linting, FX anchor toggle and Insert mode have been disabled
     * Linting will require a tokenizer
     * FX anchor and Insert mode will require CodeMirror work and extensive testing
     */

    const {
      config: { theme },
    } = useContext(AppContext);
    const [errors, setErrors] = useState([]);
    const [errorDrawerOpen, setErrorDrawerOpen] = useState(false);
    const [barHeight, setBarHeight] = useState(FX_EDITOR_SIM_HEIGHT);
    const codeMirrorEditor = useRef(null);
    const leavingInsert = useRef(false);
    //const keepSelection = useRef(false);
    const formulaBarRef = useRef();
    const selectedRegionsObject = useSelector((state) => selectedRegionsObjectSelector(state));
    const currentModule = useSelector((state) => currentModuleSelector(state));
    const currentModuleLatest = useLatest(currentModule);
    const formulaInsertData = useSelector((state) => state.dataEntry.ui.formulaInsertData);
    const formulaInsertDataLatest = useLatest(formulaInsertData);
    const formula = useSelector((state) => state.dataEntry.ui.formula);
    const formulaLatest = useLatest(formula);
    const prevMode = usePrevious(mode);
    const modeLatest = useLatest(mode);
    const prevItem = usePrevious(item);
    const itemLatest = useLatest(item);
    const selectedRegionsProp = useSelector((state) => state.dataEntry.ui.selectedRegions);
    const prevSelectedRegions = usePrevious(selectedRegionsProp);
    const selectedRegions = useLatest(selectedRegionsProp);
    const dispatch = useDispatch();

    const fxLineCount = formula?.match(/\n/g)?.length + 1 || 1;

    /** enable/disable the editor */
    const toggleFormulaEditor = useCallback((flag) => {
      if (!codeMirrorEditor.current?.view) return;
      codeMirrorEditor.current.view.dispatch({
        effects: StateEffect.reconfigure.of(flag ? [EditorState.readOnly.of(true)] : []),
      });
    }, []);

    /**
     * Reset prop used for side effects logic while leaving insert mode
     */
    useEffect(() => {
      if (leavingInsert) leavingInsert.current = false;
    }, [mode]);

    /** Clear errors (loading state) when fx changes */
    useEffect(() => {
      if (!formula) setErrors([]);
      else setErrors(null);
    }, [formula, fx]);

    /**
     * Hook to grab focus on insert mode module change
     */
    useEffect(() => {
      if (!codeMirrorEditor.current) return;
      if (mode === DataEntryEditMode.INSERT) codeMirrorEditor.current.view.focus();
    }, [currentModule, mode]);

    /**
     * Hook to disable the formula editor
     * cases: focused cell is temp; controlled flag disabled or loading
     * Sets focusedCell in FormulaMode
     */
    useEffect(() => {
      //clearMarkers();
      if (disabled || loading) {
        toggleFormulaEditor(true);
        return;
      }

      if (!item || item.fact.loading) {
        toggleFormulaEditor(true);
        return;
      }

      toggleFormulaEditor(false);
    }, [disabled, item, loading, toggleFormulaEditor]);

    /**
     * Hook to deal with formula editor cursor and focus when modes change
     * Also to update the formula if going back to normal or if the focused cell formula changed
     * We move the cursor to the line start when back to normal mode because leaving it inactive mid formula is weird UI
     */
    useEffect(() => {
      if (!codeMirrorEditor.current?.state) return;
      const currentCursorPos = codeMirrorEditor.current.state.selection.main.head;
      if (fx) {
        codeMirrorEditor.current.view.dispatch({ selection: { anchor: currentCursorPos } });
        dispatch(updateFormula(fx));
        return;
      } else if (!item) return;
      if (prevMode && prevMode !== DataEntryEditMode.NORMAL && mode === DataEntryEditMode.NORMAL) {
        codeMirrorEditor.current.view.dispatch({ selection: { anchor: 0 } });
        dispatch(updateFormula(item.fact.formula));
        tableRef.current.selectCell({
          rowIdx: item.row,
          idx: item.col + 1,
        });
      } else if (
        item.fact.formula !== prevItem?.fact.formula &&
        mode === DataEntryEditMode.NORMAL
      ) {
        codeMirrorEditor.current.view.dispatch({ selection: { anchor: 0 } });
        dispatch(updateFormula(item.fact.formula));
      }

      if (prevMode === DataEntryEditMode.NORMAL && mode !== DataEntryEditMode.NORMAL) {
        codeMirrorEditor.current.view.focus();
      }
    }, [dispatch, mode, fx, prevMode, item, prevItem, tableRef]);

    /** update aceEditor and data entry height based on current mode and errors */
    useEffect(() => {
      if (!codeMirrorEditor.current) return;

      const height = config.mode === "simulation" ? FX_EDITOR_SIM_HEIGHT : FX_EDITOR_HEIGHT;
      if (mode === DataEntryEditMode.NORMAL || !formula) {
        setFormulaBarHeight?.(height);
        setBarHeight(height);
        return;
      }

      const editorHeight = Math.min(
        height + (fxLineCount - 1) * FX_EDITOR_LINE_HEIGHT,
        FX_EDITOR_MAX_HEIGHT
      );
      const errorsHeight =
        errors && errorDrawerOpen
          ? 10 + Math.min(errors.length * FX_EDITOR_ERROR_HEIGHT, FX_EDITOR_ERRORS_MAX_HEIGHT)
          : 0;
      setFormulaBarHeight?.(editorHeight + errorsHeight);
      setBarHeight(editorHeight);
    }, [config.mode, mode, errorDrawerOpen, errors, formula, setFormulaBarHeight, fxLineCount]);

    /** char length of all rows until given index **/
    const getRowsLength = function (rowIndex) {
      if (!codeMirrorEditor.current) return 0;
      let length = 0;
      for (let i = 0; i < rowIndex; i++) {
        const lineContent = codeMirrorEditor.current.getLine(i);
        length += lineContent.length;
      }
      return length;
    };

    /** clear temp insertion when formula or mode changes */
    useEffect(() => {
      if (!formulaInsertData.tempInsertion) return;
      const tempIns = selectorObjToString(formulaInsertData.tempInsertion);
      const cursorPos = codeMirrorEditor.current.state.selection.main.head;
      const absoluteCursorPos = getRowsLength(cursorPos.line) + cursorPos.ch;
      const lastIns = formula.substring(absoluteCursorPos - tempIns.length, absoluteCursorPos);

      // we only want to reset temp insert when something is typed, not if it was an actual temp insert
      if ((tempIns && lastIns !== tempIns) || mode !== DataEntryEditMode.INSERT) {
        if (currentModule.uid === formulaInsertData.fact.moduleUid) {
          tableRef.current.selectCell({
            rowIdx: formulaInsertData.cell.row,
            idx: formulaInsertData.cell.col + 1,
          });
        }
        dispatch(updateFormulaTempInsert(null, item.fact.moduleUid));
      }
      // temp insert always changes when formula and we want to clear on formula change
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentModule, dispatch, formula, item, mode, tableRef]);

    /**
     * Grab focus after every action on insert mode
     * Give back focus to formula bar after table clicks and arrow nav in insert mode
     */
    useEffect(() => {
      if (mode === DataEntryEditMode.INSERT) {
        codeMirrorEditor.current.view.focus();
      }
    }, [selectedRegionsProp, mode]);

    const exitInsertMode = useCallback(() => {
      // this ref changes the table focus first to then keep the focus in formula bar
      // with it we know we're leaving insert mode when the focusedCell prop starts firing effects
      leavingInsert.current = true;

      // set table focus back to insert cell when coming from insert mode
      if (formulaInsertDataLatest.current.cell) {
        const { row, col } = formulaInsertDataLatest.current.cell;
        tableRef.current.selectCell({ rowIdx: row, idx: col + 1 });
      }
      dispatch(updateEditMode(DataEntryEditMode.FORMULA));
    }, [dispatch, formulaInsertDataLatest, tableRef]);

    /* TODO: might be of use later
      const clearEditorSelection = useCallback(() => {
      codeMirrorEditor.current.dispatch(
        codeMirrorEditor.current.state.update({
          selection: EditorSelection.cursor(codeMirrorEditor.current.state.selection.main.head),
        })
      );
    }, []);*/

    const changeEditorPosition = useCallback((lineChange, chChange, selection) => {
      const editor = codeMirrorEditor.current;
      const cursorPos = codeMirrorEditor.current.state.selection.main.head;
      const newPos = { ...cursorPos };
      if (lineChange) newPos.line += lineChange;
      if (chChange) newPos.ch += chChange;

      let newSelection;
      if (selection) {
        EditorSelection.range(cursorPos, newPos);
      } else {
        EditorSelection.cursor(newPos);
      }

      if (newSelection) editor.dispatch(editor.state.update({ selection: newSelection }));
    }, []);

    useEffect(() => {
      const open =
        (mode === DataEntryEditMode.INSERT || mode === DataEntryEditMode.FORMULA) &&
        !!errors?.length;
      setErrorDrawerOpen(open);
    }, [errors, mode]);

    const navigateTable = useCallback(
      (direction, selectMode) => {
        let rowIncrement;
        let colIncrement;
        switch (direction) {
          case "left":
            rowIncrement = 0;
            colIncrement = -1;
            break;
          case "right":
            rowIncrement = 0;
            colIncrement = 1;
            break;
          case "up":
            rowIncrement = -1;
            colIncrement = 0;
            break;
          case "down":
            rowIncrement = 1;
            colIncrement = 0;
            break;
          default:
            rowIncrement = 0;
            colIncrement = 0;
            break;
        }

        // should break out of insert mode?
        let breakInsert = true;
        const cursorPos = codeMirrorEditor.current.state.selection.main.head;
        const currentLineIdx = codeMirrorEditor.current.state.doc.lineAt(cursorPos).number;
        const lineContent = codeMirrorEditor.current.state.doc.line(currentLineIdx).text;
        const cursorToken =
          cursorPos.ch < lineContent.length ? lineContent.charAt(cursorPos.ch) : "";
        if (
          !cursorToken || // empty fx
          formulaInsertDataLatest.current.tempInsertion ||
          cursorToken.type === vsTokens.Operator ||
          cursorToken.type === vsTokens.RangeOperator ||
          cursorToken.type === vsTokens.ComparisonOperator ||
          cursorToken.type === vsTokens.LeftParenthesis ||
          cursorToken.type === vsTokens.Punctuation
        )
          breakInsert = false;

        if (modeLatest.current === DataEntryEditMode.INSERT && !breakInsert) {
          let cell;
          let selection;
          if (!selectMode) {
            cell = {
              row: itemLatest.current.row + rowIncrement,
              col: itemLatest.current.col + colIncrement,
            };
            if (cell.row < 0) cell.row = 0;
            if (cell.col < 0) cell.col = 0;
            if (cell.row > currentModuleLatest.current.lineItems.length - 1)
              cell.row = currentModuleLatest.current.lineItems.length - 1;
            if (cell.col > currentModuleLatest.current.formattedPeriods.length - 1)
              cell.col = currentModuleLatest.current.formattedPeriods.length - 1;
          } else {
            selection = extendSelection(
              selectedRegions.current,
              itemLatest.current,
              rowIncrement,
              colIncrement,
              currentModuleLatest.current.lineItems.length - 1,
              currentModuleLatest.current.formattedPeriods.length - 1
            );
          }
          tableRef.current.selectCell({
            idx: cell?.col + 1 || itemLatest.current.col + 1,
            rowIdx: cell?.row > -1 ? cell.row : itemLatest.current.row,
            ...(selection && {
              sel: {
                rowStart: Math.min(selection[0].rows[0], selection[0].rows[1]),
                rowEnd: Math.max(selection[0].rows[1], selection[0].rows[0]),
                colStart: Math.min(selection[0].cols[0], selection[0].cols[1]) + 1,
                colEnd: Math.max(selection[0].cols[0], selection[0].cols[1]) + 1,
              },
            }),
          });
          if (selectMode) dispatch(updateSelectedRegions(selection));
          else dispatch(updateFocusedCell(cell, selectionFromCell(cell)));
          codeMirrorEditor.current.view.focus();
        } else {
          // needs to be done after changing state because changes in insert mode depend on the cursor position
          switch (direction) {
            case "left":
              if (selectMode) changeEditorPosition(null, -1, true);
              else {
                changeEditorPosition(null, -1);
              }
              break;
            case "right":
              if (selectMode) changeEditorPosition(null, 1, true);
              else {
                changeEditorPosition(null, 1);
              }
              break;
            case "up":
              if (selectMode) changeEditorPosition(-1, null, true);
              else changeEditorPosition(-1);
              break;
            case "down":
              if (selectMode) changeEditorPosition(1, null, true);
              else {
                changeEditorPosition(1);
              }
              break;
            default:
              break;
          }
        }
        if (breakInsert) dispatch(updateEditMode(DataEntryEditMode.FORMULA));
      },
      [
        changeEditorPosition,
        currentModuleLatest,
        dispatch,
        formulaInsertDataLatest,
        itemLatest,
        modeLatest,
        selectedRegions,
        tableRef,
      ]
    );

    /* TODO: implement using code mirror when we fix anchor toggle and insert mode
    const toggleFxAnchor = useCallback(
      (editorState) => {
        const fx = formulaLatest.current;
        const variables = getSelectionVariables(codeMirrorEditor.current, fx, modeLatest.current);
        if (!variables.length) return;
        const newFormula = anchorVariables(variables, fx);
        const charDiff = variables.reduce((acc, val) => acc + val.resize, 0);
        const firstVar = variables[0];
        const lastVar = variables[variables.length - 1];
        const cursorPos = editorState.selection.main.head;
        const selRange = {
          startRow: cursorPos.row,
          startColumn: firstVar.module.start - 1,
          endRow: cursorPos.row,
          endColumn: lastVar.endIndex + charDiff,
        };
        const postSelection = {
          editor: codeMirrorEditor.current,
          index: cursorPos.column + charDiff,
          mode: modeLatest.current,
          range: selRange,
        };
        if (newFormula) {
          keepSelection.current = true;
          dispatch(updateFormula(newFormula, postSelection));
        }
      },
      [dispatch, formulaLatest, modeLatest]
    );

    const toggleFxInsertMode = useCallback(
      (_state, _dispatch, view) => {
        if (config.mode === "simulation" || config.mode === "import") return;
        if (modeLatest.current === DataEntryEditMode.FORMULA) {
          dispatch(updateEditMode(DataEntryEditMode.INSERT));
        } else if (modeLatest.current === DataEntryEditMode.INSERT) {
          exitInsertMode();
          view.focus();
        }
      },
      [config.mode, dispatch, exitInsertMode, modeLatest]
    ); */

    const fxSubmit = useCallback(() => {
      if (config.mode === "import") {
        config.onCommit(formulaLatest.current, itemLatest.current);
        config.setEditMode(DataEntryEditMode.NORMAL);
        return;
      }
      if (config.mode === "simulation") return;
      // TODO: add custom mode for import with commit func to call here
      let currentRow = itemLatest.current.row;
      let currentCol = itemLatest.current.col;
      if (modeLatest.current === DataEntryEditMode.INSERT) {
        currentRow = formulaInsertDataLatest.current.cell.row;
        currentCol = formulaInsertDataLatest.current.cell.col;
      }
      dispatch(updateEditMode(DataEntryEditMode.NORMAL, true));
      setFactError(errors?.length);
      if (currentRow !== currentModuleLatest.current.lineItems.length - 1) currentRow += 1;
      dispatch(updateFocusedCell({ row: currentRow, col: currentCol }));
      tableRef.current.selectCell({
        rowIdx: currentRow,
        idx: currentCol + 1,
      });
    }, [
      config,
      currentModuleLatest,
      dispatch,
      errors?.length,
      formulaInsertDataLatest,
      formulaLatest,
      itemLatest,
      modeLatest,
      setFactError,
      tableRef,
    ]);

    const editorHotKeys = useMemo(() => {
      return [
        {
          key: "Escape",
          run: () => {
            if (config.mode === "simulation") return;
            if (config.mode === "import") {
              config.setEditMode(DataEntryEditMode.NORMAL);
              return;
            }
            dispatch(updateEditMode(DataEntryEditMode.NORMAL));
          },
        },
        {
          key: "Enter",
          run: fxSubmit,
          preventDefault: true,
        },
        /*{
          key: "F2",
          run: toggleFxInsertMode,
        },
        {
          key: "F4",
          run: toggleFxAnchor,
        },*/
        {
          key: "ArrowLeft",
          run: () => navigateTable("left", false),
        },
        {
          key: "Shift-ArrowLeft",
          run: () => {
            navigateTable("left", true);
          },
        },
        {
          key: "ArrowRight",
          run: () => {
            navigateTable("right", false);
          },
        },
        {
          key: "Shift-ArrowRight",
          run: () => {
            navigateTable("right", true);
          },
        },
        {
          key: "ArrowUp",
          run: () => {
            navigateTable("up", false);
          },
        },
        {
          key: "Shift-ArrowUp",
          run: () => {
            navigateTable("up", true);
          },
        },
        {
          key: "ArrowDown",
          run: () => {
            navigateTable("down", false);
          },
        },
        {
          key: "Shift-ArrowDown",
          run: () => {
            navigateTable("down", true);
          },
        },
      ];
    }, [config, dispatch, fxSubmit, navigateTable]);

    /** on load editor */
    const handleEditorCreate = useCallback(
      (editor) => {
        editor.dom.onmousedown = () => {
          if (modeLatest.current !== DataEntryEditMode.FORMULA) {
            exitInsertMode();
          }
        };

        editor.dom.onfocus = () => {
          if (modeLatest.current === DataEntryEditMode.NORMAL)
            updateEditMode(DataEntryEditMode.FORMULA);
          codeMirrorEditor.current.view.dispatch({
            selection: { anchor: codeMirrorEditor.current.state.selection.main.head },
          });
        };

        /** TODO: Tokenizer for errors
        session.bgTokenizer.addEventListener("update", () => {
        if (loadingLatest.current) return;
        if (keepSelection.current) {
          // hack needed because clear markers will kill selection (ace bug)
          keepSelection.current = false;
          return;
        }

        if (itemLatest?.current?.fact?.format === "plainText") {
          setErrors([]);
          clearMarkers();
          return;
        }

        const tokens = [].concat(...session.bgTokenizer.lines).filter((token) => token);
        const cursorPos = editor.getCursorPosition();
        const cursorToken = session.getTokenAt(cursorPos.row, cursorPos.column);
        const result = vsFormulaInterpreter(
          tokens,
          cursorToken,
          configRef.current.data,
          configRef.current.mode === "simulation"
        );

        // output errors
        setErrors(result.errors);
        clearMarkers();
        result.errors.forEach((e) => {
          if (e.start > -1) {
            addMarker(0, 0, e.start, e.end);
          }
        });
      }); */
      },
      [exitInsertMode, modeLatest]
    );

    /**
     * When table selection changes in insert mode the formula is updated with a selector/range
     */
    useEffect(() => {
      if (modeLatest.current !== DataEntryEditMode.INSERT || leavingInsert.current) return;
      if (!item) return;
      const isPrevSelection = equalRegions(prevSelectedRegions, selectedRegionsProp);
      // only if selection has changed (prevents updateSelection->formulaInsertData loop)
      if (isPrevSelection) return;
      const isOriginalFocus = equalCells(formulaInsertData.cell, item);
      const isOriginalSel = regionEqualsCell(selectedRegionsProp, formulaInsertData.cell);

      // restarting from original cell in different module: no insert temp
      if (
        formulaInsertData.fact.moduleUid !== currentModule.uid &&
        !formulaInsertData.tempInsertion &&
        // only necessary if last insert was a range selection it collapses to focused cell on tempInsert clear
        (prevSelectedRegions[0].rows[0] !== prevSelectedRegions[0].rows[1] ||
          prevSelectedRegions[0].cols[0] !== prevSelectedRegions[0].cols[1])
      )
        return;

      // restarting from original cell: no insert temp
      if (
        formulaInsertData.fact.moduleUid === currentModule.uid &&
        !formulaInsertData.tempInsertion &&
        isOriginalFocus &&
        isOriginalSel
      )
        return;

      const editor = codeMirrorEditor.current;
      const cursorPos = editor.getCursor();
      // If there was a temp insert we need to remove it from the formula at cursor position
      if (formulaInsertData.tempInsertion) {
        const tempInsString = selectorObjToString(formulaInsertData.tempInsertion);
        const initialCh = cursorPos.ch - tempInsString.length;
        editor.setCursor({ line: cursorPos.line, ch: initialCh });
        editor.replaceRange(
          "",
          { line: cursorPos.row, ch: initialCh },
          { line: cursorPos.row, ch: cursorPos.ch }
        );
      }
      // update temp insert in state and in formula bar
      // can't wait for redux update for arrow navigation. Probably can get rid of redux for tempInsertion later
      formulaInsertDataLatest.current.tempInsertion = selectedRegionsObject;
      dispatch(updateFormulaTempInsert(selectedRegionsObject, item.fact.moduleUid));
      editor.replaceRange(selectorObjToString(selectedRegionsObject), cursorPos, cursorPos);
      dispatch(updateFormula(editor.getValue()));
      editor.scrollIntoView(editor.getCursor());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      dispatch,
      item,
      formulaInsertData,
      modeLatest,
      prevSelectedRegions,
      selectedRegionsObject,
      selectedRegionsProp,
    ]);

    /** Returns the markup for the error list */
    /*function errorListRenderer() {
      if (!enableLinting || !errorDrawerOpen) return null;
      if (errors?.length) {
        return (
          <StyledErrorList mode={config.mode}>
            {errors.map((e, i) => {
              return (
                <StyledError className={Classes.LARGE} key={`${e}-${i}`}>
                  <Icon icon="symbol-circle" size={14} intent={Intent.DANGER} />
                  &nbsp;
                  {e.msg}
                </StyledError>
              );
            })}
          </StyledErrorList>
        );
      }
    } */

    /**
     * Helper function to add linter under-text markers at given position
     * tokenizer: todo
     function addMarker(rowStart, rowEnd, colStart, colEnd) {
      codeMirror.current.editor
        .getSession()
        .addMarker(
          new AceRange(rowStart, colStart, rowEnd, colEnd),
          "formula-error",
          "text",
          false
        );
    } */

    /** Helper function to clear all linter under-text markers
     * tokenizer: todo
    function clearMarkers() {
      const editor = codeMirror.current.editor;
      if (!editor || !editor.session) return;
      const markers = editor.session.getMarkers(false);
      if (!markers) return;
      for (let marker in markers) {
        editor.session.removeMarker(markers[marker].id);
      }
    }
     */

    const handleEditorOnChange = useCallback(
      (value) => {
        if (modeLatest.current !== DataEntryEditMode.NORMAL && formulaLatest.current !== value) {
          dispatch(updateFormula(value));
        }
      },
      [dispatch, formulaLatest, modeLatest]
    );

    const codeMirrorExtensions = useMemo(
      () => [EditorView.lineWrapping, Prec.high(keymap.of([...editorHotKeys, ...defaultKeymap]))],
      [editorHotKeys]
    );

    return (
      <StyledFormulaBar mode={config.mode} ref={formulaBarRef}>
        <Flex>
          {loading ? (
            <Spinner size={SpinnerSize.SMALL} />
          ) : (
            <Icon icon="function" size={18} style={{ margin: "auto 8px" }} />
          )}
          <CodeMirror
            extensions={codeMirrorExtensions}
            style={{ height: barHeight }}
            className="cm-fx"
            theme={theme === Themes.DARK ? "dark" : "light"}
            basicSetup={codeMirrorFxBasicSetup}
            onChange={handleEditorOnChange}
            onCreateEditor={handleEditorCreate}
            ref={codeMirrorEditor}
            value={loading ? "loading..." : item ? formula || item.fact.formula : ""}
          />
          {!errorDrawerOpen && fxLineCount > 1 && mode === DataEntryEditMode.NORMAL ? (
            <StyledFormulaInfo theme={theme}>
              {fxLineCount - 1 + (fxLineCount - 1 > 1 ? " lines more..." : " line more...")}
            </StyledFormulaInfo>
          ) : null}
          {/*errors ? (
            errors.length ? (
              <Tooltip
                content={`${errors.length} errors found! Click to ${
                  errorDrawerOpen ? "hide" : "show"
                } details`}
                position={Position.BOTTOM}
              >
                <AnchorButton
                  icon="error"
                  intent={Intent.DANGER}
                  minimal
                  onClick={() => setErrorDrawerOpen(!errorDrawerOpen)}
                />
              </Tooltip>
            ) : (
              <Tooltip content="No errors found" position={Position.BOTTOM}>
                <Icon icon="tick" intent={Intent.SUCCESS} style={{ margin: "0px 7px" }} />
              </Tooltip>
            )
          ) : (
            <div style={{ margin: "0px 5px" }}>
              <Spinner size={SpinnerSize.SMALL} />
            </div>
          )*/}
        </Flex>
        {/*errorListRenderer()*/}
      </StyledFormulaBar>
    );
  }
);

FormulaEditor.displayName = "FormulaEditor";

export default FormulaEditor;
