import React, { Dispatch, Fragment, SetStateAction } from "react";
import {
  Classes,
  ContextMenu,
  Divider,
  EditableText,
  Icon,
  Intent,
  Tag,
  Tooltip,
  TreeNodeInfo,
} from "@blueprintjs/core";
import ExplorerContextMenu from "components/model/ExplorerContextMenu";
import { ILineItem, IModule, IModuleInfo } from "types/model";
import { TNormalizedImportLineItem, TNormalizedImportModule } from "types/import";
import { Flex, PaddedContent } from "components/utility/StyledComponents";
import { IEditingNode } from "components/model/Explorer";
import { schemaLevel } from "./normalizr";

type TTreeNode = TreeNodeInfo<
  IModule | TNormalizedImportModule | ILineItem | TNormalizedImportLineItem
>;

/**
 * Pull a bottom-up list of all a module's parents.
 * */
export const getModuleParents = (
  currentModule: IModuleInfo,
  parentArray: string[],
  modules: Record<string, TNormalizedImportModule> | Record<string, IModule>
): string[] => {
  if (currentModule.unlinked) return parentArray;
  const newParents = parentArray.concat([currentModule.uid]);
  if (modules?.[currentModule?.parentUid]) {
    return getModuleParents(modules[currentModule.parentUid] as IModuleInfo, newParents, modules);
  }
  return newParents;
};

/**
 * Converts a module element to a tree node.
 * */
const moduleToNode = (
  moduleInfo: IModule | TNormalizedImportModule,
  parentDepth: number,
  editingNode: IEditingNode | undefined,
  onEditNameConfirm?: (nextName: string) => void
): TreeNodeInfo<IModule | TNormalizedImportModule> => {
  // If a module is not unlinked and has a parent uid, then set its depth relative to its parent
  const label = onEditNameConfirm ? (
    editingNode && moduleInfo.uid === editingNode?.uid ? (
      <PaddedContent padding="2px 1px 2px 2px" style={{ width: "100%" }}>
        <EditableText
          defaultValue={moduleInfo.name}
          className="rename-module-input"
          onConfirm={onEditNameConfirm}
          minWidth={120}
          selectAllOnFocus={true}
          intent={Intent.PRIMARY}
        >
          <p className={Classes.RUNNING_TEXT}>{moduleInfo.name}</p>
        </EditableText>
      </PaddedContent>
    ) : (
      <ContextMenu content={() => <ExplorerContextMenu moduleInfo={moduleInfo} />}>
        {moduleInfo.name}
      </ContextMenu>
    )
  ) : (
    moduleInfo.name
  );

  return {
    id: moduleInfo.uid,
    icon: "cube",
    label,
    nodeData: {
      ...moduleInfo,
      depth: !moduleInfo.unlinked && moduleInfo.parentUid ? parentDepth + 1 : moduleInfo.depth,
      type: "module",
    },
    ...(moduleInfo.depth === 0 && {
      secondaryLabel: (
        <Tooltip content="Root module">
          <ContextMenu content={() => <ExplorerContextMenu moduleInfo={moduleInfo} />}>
            <Icon icon="symbol-diamond" />
          </ContextMenu>
        </Tooltip>
      ),
    }),
  };
};

const lineItemToNode = (
  lineItem: ILineItem | TNormalizedImportLineItem,
  editingNode?: { uid: string; type: "tags" | "name" },
  onEditNameConfirm?: (nextName: string) => void,
  setEditTagDialogOpen?: Dispatch<SetStateAction<boolean>>
): TreeNodeInfo<ILineItem | TNormalizedImportLineItem> => {
  const label = onEditNameConfirm ? (
    editingNode && lineItem.uid === editingNode.uid && editingNode.type === "name" ? (
      <PaddedContent padding="2px 1px 2px 2px" style={{ width: "100%" }}>
        <EditableText
          defaultValue={lineItem.name}
          className="rename-module-input"
          onConfirm={onEditNameConfirm}
          minWidth={120}
          selectAllOnFocus={true}
          intent={Intent.PRIMARY}
        >
          <p className={Classes.RUNNING_TEXT}>{lineItem.name}</p>
        </EditableText>
      </PaddedContent>
    ) : (
      <ContextMenu
        content={() => (
          <ExplorerContextMenu lineItem={lineItem} setEditTagDialogOpen={setEditTagDialogOpen} />
        )}
      >
        {lineItem.name}
      </ContextMenu>
    )
  ) : (
    lineItem.name
  );

  const hasTags = lineItem.tags && lineItem.tags.length > 0;

  return {
    id: lineItem.uid,
    secondaryLabel: (
      <Tooltip
        content={
          hasTags ? (
            <Flex flexDirection="row">
              {(lineItem.tags as string[]).map((tag) => (
                <Fragment key={tag}>
                  <Tag key={tag}>{tag}</Tag>
                  <Divider />
                </Fragment>
              ))}
            </Flex>
          ) : (
            "No Tags"
          )
        }
      >
        <ContextMenu
          content={() => (
            <ExplorerContextMenu lineItem={lineItem} setEditTagDialogOpen={setEditTagDialogOpen} />
          )}
        >
          <Icon color={!hasTags ? "rgba(95, 107, 124, 0.6)" : undefined} icon="tag" />
        </ContextMenu>
      </Tooltip>
    ),
    nodeData: { ...lineItem, type: schemaLevel.LINE_ITEM },
    label,
    icon: "stadium-geometry",
  };
};

/**
 * Build the initial tree of modules.
 * */
export const buildNodes = (
  modules: Record<string, IModule | TNormalizedImportModule>,
  lineItems: Record<string, ILineItem | TNormalizedImportLineItem>,
  currentModuleUid: string,
  expandedNodes: string[],
  showLineItems: boolean,
  showUntaggedLineItems: boolean,
  setEditTagDialogOpen: Dispatch<SetStateAction<boolean>>,
  editingNode: IEditingNode | undefined,
  onEditNameConfirm?: (nextName: string) => void
): Array<TTreeNode> | undefined => {
  if (!modules) return;

  // First, coerce all unlinked mods to depth of 0.
  // Also filter any unlinked children
  const moduleArray = Object.values(modules).map((mod) => {
    return {
      ...mod,
      ...(mod.unlinked && { depth: 0 }),
      ...(mod.childModules && {
        childModules: mod.childModules.filter((child) => !modules[child]?.unlinked),
      }),
    };
  });

  // Recursively add children from the module list
  const addChildren = (mod: IModule | TNormalizedImportModule): Array<TTreeNode> | undefined => {
    let lineItemNodes: TreeNodeInfo<ILineItem | TNormalizedImportLineItem>[] | undefined = [];
    if (mod.lineItems && showLineItems) {
      let sortedLineItems = mod.lineItems
        .map((liUid) => lineItems[liUid])
        .sort((liA, liB) => liA.order - liB.order);
      if (!showUntaggedLineItems) {
        sortedLineItems = sortedLineItems.filter((li) => li.tags && li?.tags?.length > 0);
      }
      lineItemNodes = sortedLineItems.map((li) => {
        return lineItemToNode(li, editingNode, onEditNameConfirm, setEditTagDialogOpen);
      });
    }

    if (!mod.childModules && mod.lineItems && showLineItems) return lineItemNodes;
    if (!mod.childModules) return undefined;

    // normal case drills child modules
    return [
      ...mod.childModules
        .sort((a, b) => {
          const mod1 = modules[a];
          const mod2 = modules[b];
          if ("order" in mod1 && "order" in mod2) {
            return mod1.order - mod2.order;
          }
          return 1;
        })
        .map((moduleId) => {
          const depth =
            moduleArray.find((mod) => mod.uid === modules[moduleId].parentUid)?.depth || 0;
          return {
            ...moduleToNode(modules[moduleId], depth, editingNode, onEditNameConfirm),
            childNodes:
              mod.childModules || (mod.lineItems && showLineItems)
                ? addChildren(
                    moduleArray.find((mod) => mod.uid === moduleId) as
                      | IModule
                      | TNormalizedImportModule
                  )
                : undefined,
            isExpanded: expandedNodes.indexOf(moduleId) !== -1,
            isSelected: currentModuleUid === moduleId,
          };
        }),
      ...lineItemNodes,
    ];
  };

  const rootNodes = moduleArray
    .filter((module) => module.depth === 0)
    .sort((a, b) => {
      if ("order" in a && "order" in b) {
        return a.order - b.order;
      } else {
        if (a.unlinked && !b.unlinked) {
          return 1;
        } else if (!a.unlinked && b.unlinked) {
          return -1;
        } else {
          return 0;
        }
      }
    });

  return rootNodes.map((node, i) => {
    return {
      ...moduleToNode(node, 0, editingNode, onEditNameConfirm),
      childNodes: addChildren(node),
      isExpanded: expandedNodes.indexOf(node.uid) !== -1,
      isSelected: currentModuleUid ? currentModuleUid === node.uid : i === 0, // The first root node is the initial selection
    };
  });
};
