import React, { useContext, useEffect, useState } from "react";
import { AnchorButton, Callout, Divider, Intent, Tooltip } from "@blueprintjs/core";
import { connect } from "react-redux";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Flex, PaddedContent } from "components/utility/StyledComponents";
import LoadingIndicator from "components/utility/LoadingIndicator";
import ModelBar from "components/dashboard/model/ModelBar";
import { EDashboardType, IVSDashboard, IVSWidget } from "types/dashboard";
import { appStates, DATA_SOURCES_ROUTE, EMethods, GET_MODEL_CONFIG } from "constants/apiConstants";
import { DefaultDashboards } from "constants/widgets";
import { closeSocket, openSocket, updateCurrentModule } from "actions/dataEntryActions";
import { fetchData, flushModelReducer, pullModel } from "actions/modelActions";
import { flushHistoryReducer } from "actions/eventHistoryActions";
import { fetchAvailableTickers } from "actions/createModelActions";
import { updateAppStatus } from "actions/globalUIActions";
import { setNavCellCoords, unlockUI } from "actions/dataEntryUIActions";
import { coordsFromUrl, isModelViewOnly, updatePageTitle } from "utils/generic";
import usePreviousVersions from "hooks/usePreviousVersions";
import {
  caseModulesSelector,
  currentCaseDataSelector,
  currentModuleSelector,
} from "selectors/modelSelectors";
import { CONTROL_BAR_HEIGHT, DEFAULT_MODEL_TAB } from "constants/uiConstants";
import { vsWidgetsToRGL } from "utils/layoutUtils";
import { findModuleByName } from "utils/data";
import { ITableCoordinates } from "types/vsTable";
import { IActionReturnType, IAppStatus, IResponse, ISize } from "types";
import { ICase, IModelInfo, IModelVersion, IModule } from "types/model";
import ModelContext, {
  defaultModelConfig,
  IModelConfig,
  IPreviousVersionsContext,
} from "context/modelContext";
import AppContext from "context/appContext";
import { Milieu } from "constants/appConstants";
import usePermissions from "hooks/usePermissions";
import useUser from "hooks/useUser";
import api, { checkConnection, swrApi } from "utils/api";
import { useLatest } from "hooks/useLatest";
import { OutletContext as IncomingContext } from "ValsysApp";
import styled, { css } from "styled-components/macro";
import View from "components/dashboard/View";
import { View as TView } from "types/view";
import Legacy_Dashboard from "components/dashboard/model/Legacy_Dashboard";
import DataEntry from "components/model/DataEntry";
import useDashboard from "hooks/dashboard/useDashboard";
import { IconNames } from "@blueprintjs/icons";
import useView from "hooks/dashboard/useView";
import useSWRImmutable from "swr/immutable";
import AddWidgetToViewDialog from "components/dashboard/AddWidgetToViewDialog";
import ModelScenario from "components/dashboard/model/ModelScenario";

interface ModelDashboardProps {
  appStatus: IAppStatus;
  closeSocket: () => IActionReturnType<string, undefined>;
  currentCaseData: ICase;
  currentModule: IModule;
  fetchAvailableTickers: (templateID: string) => IActionReturnType<string, { templateID: string }>;
  pullModel: (
    modelId: string,
    previousVersionHash?: string
  ) => IActionReturnType<string, { modelId: string; previousVersionHash?: string }>;
  fetchData: (
    route: string,
    identifier: string,
    headers: Record<string, unknown>
  ) => IActionReturnType<
    string,
    { route: string; identifier: string; headers: Record<string, unknown> }
  >;
  flushHistoryReducer: () => IActionReturnType<string, undefined>;
  flushModelReducer: () => IActionReturnType<string, undefined>;
  uiLock: boolean;
  modelInfo: IModelInfo | undefined; // Technically this is of type IModelInfo | {}. However in this component undefined is a more accurate reflection.
  modules: Record<string, IModule>;
  openSocket: (modelID: string) => IActionReturnType<string, { modelID: string }>;
  setNavCellCoords: (coords: ITableCoordinates) => IActionReturnType<string, ITableCoordinates>;
  unlockUI: () => IActionReturnType<string, undefined>;
  updateAppStatus: (
    nextStatus: IAppStatus
  ) => IActionReturnType<string, { nextStatus: IAppStatus }>;
  updateCurrentModule: (moduleName: string) => IActionReturnType<string, IModule>;
}

let globalDebugMode = false;
export const isDebugMode = () => globalDebugMode;

const StyledViewContainer = styled.div<{ appSize: ISize }>`
  height: ${({ appSize }) =>
    appSize?.height
      ? css`
          ${appSize.height - CONTROL_BAR_HEIGHT}px
        `
      : css`unset`};
  overflow-y: auto;
`;

const ModelDashboard = ({
  appStatus,
  closeSocket,
  currentCaseData,
  currentModule,
  fetchAvailableTickers,
  fetchData,
  flushHistoryReducer,
  flushModelReducer,
  modelInfo,
  modules,
  openSocket,
  pullModel,
  setNavCellCoords,
  uiLock,
  unlockUI,
  updateCurrentModule,
}: ModelDashboardProps) => {
  const { activeSideMenu, appSize } = useOutletContext<IncomingContext>();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const {
    config: { debugMode },
    milieu,
  } = useContext(AppContext);
  const urlModuleName = params["*"];
  const [modelLocked, setModelLocked] = useState(true);
  const [previousVersion, setPreviousVersion] = useState<IModelVersion | null>(null);
  const modelID = params?.model ? params.model.split("=")[1] : "";
  const { user } = useUser();
  const { userPermissions } = usePermissions(modelID, user?.uid);
  const connected = checkConnection(appStatus);
  const latestAppStatus = useLatest(appStatus);
  const { data: availableVersions } = usePreviousVersions(modelID);
  const [layoutEditable, setLayoutEditable] = useState(false);
  const [addWidgetDialogOpen, setAddWidgetDialogOpen] = useState(false);
  const [selectorsDialogOpen, setSelectorsDialogOpen] = useState<{
    [key: string]: boolean;
  }>({});

  /** fetch model data */
  useEffect(() => {
    const modelId = params?.model ? params.model.split("=")[1] : "";
    if (modelId) pullModel(modelId);
  }, [params.model, pullModel]);

  /** fetch tickers */
  useEffect(() => {
    if (!modelInfo?.templateID) return;
    fetchAvailableTickers(modelInfo.templateID);
  }, [fetchAvailableTickers, modelInfo]);

  /** fetch template data */
  useEffect(() => {
    if (!modelInfo?.templateID) return;
    const { templateID } = modelInfo;
    fetchData(DATA_SOURCES_ROUTE, "datasources", { templateID });
    //fetchData(IDENTIFIERS_ROUTE, "identifiers", templateID);
  }, [fetchData, modelInfo]);

  /** Open the data entry socket */
  useEffect(() => {
    openSocket(modelID);
    return () => {
      closeSocket();
    };
  }, [closeSocket, openSocket, modelID]);

  if (modelInfo?.companyName) {
    updatePageTitle(modelInfo.companyName);
  }

  // Unmount
  useEffect(
    () => () => {
      flushHistoryReducer();
      flushModelReducer();
      // If it's an errored state from socket, main milieu shouldn't be locked
      if (latestAppStatus.current.message !== appStates.OFFLINE) unlockUI();
    },

    [flushHistoryReducer, flushModelReducer, latestAppStatus, unlockUI]
  );

  const previousVersions: IPreviousVersionsContext = {
    availableVersions: availableVersions || [],
    activeVersion: previousVersion,
    setVersion: (version) => {
      flushModelReducer();
      setPreviousVersion(version);
      pullModel(modelID, version?.hash);
    },
  };

  // NEW CONFIG V3
  const {
    data: modelConfig,
    error: modelConfigError,
    isLoading: loadingModelConfig,
    mutate: mutateModelConfig,
  } = useSWRImmutable<IResponse<IModelConfig>>([GET_MODEL_CONFIG, { modelID: modelID }], swrApi);

  const dashboard = modelConfig?.data.dashboard;

  const {
    addView,
    loading: loadingDashboard,
    removeView,
    updating: updatingDashboard,
  } = useDashboard(modelConfig?.data?.dashboard.id);

  // hack to expose debugMode to reducer
  useEffect(() => {
    if (typeof debugMode === "boolean") globalDebugMode = debugMode;
  }, [debugMode]);

  // Module navigation
  useEffect(() => {
    if (milieu !== Milieu.ModelData) return;
    if (!currentCaseData?.modules || !modules || Object.keys(modules).length === 0) return;

    let moduleName;
    if (urlModuleName) {
      moduleName = urlModuleName.replace(/-/g, " ");
    }

    if (!moduleName || !findModuleByName(moduleName, currentCaseData.uid, modules)) {
      // TODO: hard coded CPP default. We should have a generic mechanism to define this.
      if (findModuleByName("Financial Model (Base)", currentCaseData.uid, modules)) {
        moduleName = "Financial Model (Base)";
      } else {
        moduleName = modules[currentCaseData.modules[0]].name;
      }
      navigate(`${moduleName}`, { replace: true });
    } else {
      updateCurrentModule(moduleName);
      const urlCoords = coordsFromUrl(location.search);
      setNavCellCoords(urlCoords);
      return;
    }

    updateCurrentModule(moduleName);
  }, [
    currentCaseData,
    location,
    milieu,
    modules,
    navigate,
    setNavCellCoords,
    updateCurrentModule,
    urlModuleName,
  ]);

  /** UI Lock based on permissions or server status */
  useEffect(() => {
    setModelLocked(uiLock || isModelViewOnly(userPermissions, previousVersion));
  }, [uiLock, userPermissions, previousVersion]);

  // active view
  let activeView: IVSDashboard | TView | { name: string } | undefined;
  if (params.tab === "data") activeView = DefaultDashboards[0];
  else if (params.tab === "valuation sheet") activeView = DefaultDashboards[1];
  else if (params.tab === "control panel") activeView = { name: "Control Panel" };
  else activeView = dashboard?.views?.find((view) => view.name.toLowerCase() === params.tab);

  // TODO: This should be simplified once the backend ensures we always get the default model tab (key kpis)
  if (dashboard && modelConfig?.data && !activeView) {
    const dashboardTab = dashboard.views?.find(
      (view) => view.name.toLowerCase() === DEFAULT_MODEL_TAB
    )
      ? DEFAULT_MODEL_TAB
      : "data";
    navigate(`/model/${params.model}/${dashboardTab}`);
  }

  const { widgets: viewWidgets } = useView((activeView as TView)?.id);
  const widgets = activeView && "widgets" in activeView ? activeView.widgets : viewWidgets;

  const setConfig = async (details: Partial<IModelConfig>) => {
    if (!modelConfig) return;
    const newConfigData = { ...modelConfig.data, ...details };
    mutateModelConfig(
      { ...modelConfig, data: newConfigData },
      {
        revalidate: false,
      }
    );
    await api(GET_MODEL_CONFIG, {
      method: EMethods.PUT,
      modelID: modelID,
      body: newConfigData,
    });
  };

  const loadingModelData =
    !activeView ||
    !currentCaseData ||
    loadingModelConfig ||
    (activeView.name === "Data" && (!currentModule || !currentModule?.formattedPeriods));

  const renderActiveTab = () => {
    if (!activeView) return;
    switch (activeView.name) {
      case "Data":
        return (
          // TODO: DataEntry is not a valid View type, this needs work when we unify legacy Dashboard
          // eslint-disable-next-line
          // @ts-ignore
          <DataEntry activeMenu={activeSideMenu} appSize={appSize} modelLocked={modelLocked} />
        ) as JSX.Element;
      case "Control Panel":
        return <ModelScenario />;
      case "Valuation Sheet":
        return (
          <Legacy_Dashboard
            layout={vsWidgetsToRGL(widgets as IVSWidget[])}
            size={{
              ...appSize,
              // min width of 1400 to avoid multi line entries in the tables
              width: appSize.width ? (appSize.width < 1400 ? 1400 : appSize.width) : 800,
            }}
            type={EDashboardType.View}
            widgets={widgets as IVSWidget[]}
          />
        );
      default:
        return (
          <StyledViewContainer appSize={appSize}>
            {loadingModelConfig && (
              <LoadingIndicator
                loading
                status={{ intent: Intent.PRIMARY, message: "Loading view..." }}
              />
            )}
            {modelConfigError && <Callout intent={Intent.DANGER}>Error loading view</Callout>}
            <View
              editSelectorsDialogOpen={selectorsDialogOpen[(activeView as TView).id]}
              layoutEditable={layoutEditable}
              setEditSelectorsDialogOpen={() => {
                setSelectorsDialogOpen({ [(activeView as TView).id]: false });
              }}
              viewId={(activeView as TView).id}
            />
          </StyledViewContainer>
        );
    }
  };

  return (
    <ModelContext.Provider
      value={{
        config: modelConfig?.data || defaultModelConfig,
        locked: modelLocked,
        modelID,
        setConfig,
        previousVersions,
      }}
    >
      <AddWidgetToViewDialog
        isOpen={addWidgetDialogOpen}
        onClose={() => setAddWidgetDialogOpen(false)}
        viewId={(activeView as TView)?.id}
      />
      {activeView?.name !== "Data" && activeView?.name !== "Valuation Sheet" && (
        <div style={{ position: "absolute", top: "50px", left: "10px", zIndex: 10, width: "30px" }}>
          <Tooltip
            content={layoutEditable ? "Lock view layout" : "Unlock view layout"}
            openOnTargetFocus={false}
          >
            <AnchorButton
              minimal
              icon={layoutEditable ? IconNames.UNLOCK : IconNames.LOCK}
              onClick={() => setLayoutEditable(!layoutEditable)}
            />
          </Tooltip>
          <Divider />
          <Tooltip content="Add Widget" openOnTargetFocus={false}>
            <AnchorButton
              icon={IconNames.ADD}
              minimal
              onClick={() => setAddWidgetDialogOpen(true)}
            />
          </Tooltip>
          <Divider />
          <Tooltip content="Manage View Selectors" openOnTargetFocus={false}>
            <AnchorButton
              minimal
              icon={IconNames.FILTER_LIST}
              onClick={() => setSelectorsDialogOpen({ [(activeView as TView).id]: true })}
            />
          </Tooltip>
        </div>
      )}
      <div>
        <ModelBar
          addView={addView}
          dashboardId={modelConfig?.data?.dashboard.id}
          loadingModel={loadingDashboard || !currentCaseData || loadingModelConfig}
          modelInfo={modelInfo}
          removeView={removeView}
          updatingDashboard={updatingDashboard}
          viewOnly={isModelViewOnly(userPermissions, previousVersion)}
          views={dashboard?.views || []}
        />
        <Flex
          alignItems="flex-start"
          flexDirection="row"
          fullWidth
          justifyContent="flex-start"
          style={{ height: "100%" }}
        >
          <Flex alignItems="flex-start" flexDirection="row" justifyContent="flex-start">
            <div style={{ width: appSize?.width || undefined, overflow: "auto" }}>
              {loadingModelData
                ? connected && (
                    <PaddedContent padding="20px">
                      <LoadingIndicator
                        loading={true}
                        status={{ intent: Intent.PRIMARY, message: "Loading model data..." }}
                      />
                    </PaddedContent>
                  )
                : renderActiveTab()}
            </div>
          </Flex>
        </Flex>
      </div>
    </ModelContext.Provider>
  );
};

// TODO: - Replace this with the full state type.
// eslint-disable-next-line
function mapStateToProps(state: any) {
  return {
    appStatus: state.ui.global.appStatus,
    currentCaseData: currentCaseDataSelector(state),
    currentModule: currentModuleSelector(state),
    uiLock: state.dataEntry.ui.locked,
    modelInfo: state.model.modelInfo,
    modules: caseModulesSelector(state),
  };
}

const mapDispatchToProps = {
  closeSocket,
  fetchAvailableTickers,
  fetchData,
  flushHistoryReducer,
  flushModelReducer,
  openSocket,
  pullModel,
  setNavCellCoords,
  unlockUI,
  updateAppStatus,
  updateCurrentModule,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ModelDashboard);
