import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  Classes,
  Colors,
  ControlGroup,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogProps,
  Divider,
  FormGroup,
  H4,
  H6,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  NonIdealState,
  NonIdealStateIconSize,
} from "@blueprintjs/core";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Flex, Grid, NormalAlignDivider } from "components/utility/StyledComponents";
import AppContext from "context/appContext";
import { Themes } from "constants/uiConstants";
import { IconNames } from "@blueprintjs/icons";
import { LibrarySidebar } from "./DashboardStyledComponents";
import LoadingIndicator from "components/utility/LoadingIndicator";
import useListConfigs from "hooks/useListConfigs";
import api from "utils/api";
import { View } from "types/view";
import { EStatus, EMethods, Status, VIEW_ROUTE } from "constants/apiConstants";
import { multiToaster } from "utils/toaster";
import { MainContainer } from "components/utility/StyledDashboardComponents";

type OutletContext = {
  handleAdd: () => void;
};

const ViewLibrary: React.FC = () => {
  const [showingCreateDialog, setShowingCreateDialog] = useState<boolean>(false);
  const [searchViewPredicate, setSearchViewPredicate] = useState("");
  const { viewId } = useParams();
  const navigate = useNavigate();

  const {
    config: { theme },
  } = useContext(AppContext);

  const { error, isLoading: loadingConfigurations, mutateConfigs, views } = useListConfigs();

  const sortedViews = views
    ? [...views].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    : undefined;

  const filteredViews = sortedViews
    ? sortedViews.filter((view) =>
        view.name.toLowerCase().includes(searchViewPredicate.toLowerCase())
      )
    : undefined;

  let listContent = null;

  if (error) {
    listContent = (
      <NonIdealState
        action={
          <Button icon={IconNames.REFRESH} text="Reload..." onClick={() => mutateConfigs()} />
        }
        description={error.message}
        icon={IconNames.WARNING_SIGN}
        iconSize={NonIdealStateIconSize.SMALL}
        title="Unable to load Views!"
      />
    );
  } else {
    if (loadingConfigurations) {
      listContent = <LoadingIndicator status={{ message: "Loading Views..." }} />;
    } else {
      if (filteredViews) {
        listContent = (
          <Card
            style={{
              flex: "1 1 1px",
              overflow: "hidden",
              padding: 1,
              width: "100%",
            }}
          >
            <div style={{ height: "100%", overflow: "auto", width: "100%" }}>
              <Menu role="list">
                {filteredViews.map((view) => (
                  <MenuItem
                    active={view.id === viewId}
                    onClick={() => navigate(`/settings/views/${view.id}`)}
                    key={view.id}
                    style={{ width: "100%" }}
                    role="listitem"
                    text={
                      <Flex
                        alignItems="flex-start"
                        flexDirection="column"
                        fullWidth
                        style={{ textOverflow: "ellipsis", whiteSpace: "break-spaces" }}
                      >
                        <H6>{view.name}</H6>
                        {view.description ? (
                          <p
                            style={{
                              color: theme === Themes.DARK ? Colors.WHITE : Colors.BLACK,
                              marginBottom: 0,
                            }}
                          >
                            {view.description}
                          </p>
                        ) : (
                          <p className={Classes.TEXT_MUTED} style={{ marginBottom: 0 }}>
                            No description
                          </p>
                        )}
                        <p className={Classes.TEXT_SMALL} style={{ marginBottom: 0 }}>
                          <span className={Classes.TEXT_MUTED}>Created:</span>{" "}
                          {view.createdAt.toLocaleString()}
                        </p>
                      </Flex>
                    }
                  />
                ))}
              </Menu>
            </div>
          </Card>
        );
      } else {
        listContent = (
          <NonIdealState
            icon={IconNames.SEARCH}
            description="No Views are available to your user. Views that you create will appear here."
            iconSize={NonIdealStateIconSize.SMALL}
            title="No Views available!"
          />
        );
      }
    }
  }

  return (
    <MainContainer>
      <CreateViewDialog
        isOpen={showingCreateDialog}
        onClose={() => setShowingCreateDialog(false)}
      />
      <Grid fullHeight cols="auto auto 1fr" style={{ overflow: "hidden" }}>
        <LibrarySidebar
          alignItems="flex-start"
          flex="1 auto"
          fullHeight
          justifyContent="flex-start"
        >
          <Flex
            alignItems="flex-start"
            flexDirection="column"
            fullHeight
            justifyContent="flex-start"
            style={{ maxWidth: "100%" }}
          >
            <Flex flex="0" fullWidth justifyContent="space-between">
              <H4 className={Classes.TEXT_MUTED} style={{ flex: "1 0 auto" }}>
                View Library
              </H4>
              <Button
                icon={IconNames.ADD}
                intent={Intent.SUCCESS}
                onClick={() => setShowingCreateDialog(true)}
                small
                text="New"
              />
            </Flex>
            <Divider />
            <div style={{ width: "100%", marginBottom: "10px" }}>
              <ControlGroup>
                <InputGroup
                  fill
                  leftIcon={IconNames.SEARCH}
                  onChange={(e) => setSearchViewPredicate(e.currentTarget.value)}
                  placeholder="Search Views..."
                  type="search"
                  value={searchViewPredicate}
                />
              </ControlGroup>
            </div>
            {listContent}
          </Flex>
        </LibrarySidebar>
        <NormalAlignDivider style={{ margin: "0 15px" }} />
        <Outlet context={{ handleAdd: () => setShowingCreateDialog(true) }} />
      </Grid>
    </MainContainer>
  );
};

export default ViewLibrary;

export const ViewLibraryHome: React.FC = () => {
  const { handleAdd } = useOutletContext<OutletContext>();

  return (
    <NonIdealState
      title="View Library"
      description="Select a View from the menu on the left, or create a new one."
      action={
        <Button onClick={handleAdd} icon={IconNames.ADD} intent={Intent.SUCCESS} text="New" />
      }
    />
  );
};
const CreateViewDialog: React.FC<DialogProps & { onClose: () => void }> = ({
  onClose,
  ...dialogProps
}) => {
  const navigate = useNavigate();
  const {
    config: { theme },
  } = useContext(AppContext);

  const { configs, mutateConfigs, views } = useListConfigs();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const createView = async () => {
    const response = await api<View>(VIEW_ROUTE, {
      body: { description, name },
      method: EMethods.POST,
    });
    if (response.status === Status.SUCCESS) {
      const nextViews: View[] | undefined = [...(views ?? []), response.data];

      mutateConfigs({
        ...configs?.data,
        status: EStatus.success,
        data: {
          ...configs?.data,
          views: nextViews || [response.data],
        },
      });
      navigate(`/settings/views/${response.data.id}`);
      setName("");
      setDescription("");
      onClose();
    } else {
      multiToaster.show({ intent: Intent.WARNING, message: response.error || response.message });
    }
  };
  return (
    <Dialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      icon={IconNames.ADD}
      title="Add View"
      onClose={onClose}
      {...dialogProps}
    >
      <DialogBody>
        <FormGroup helperText="(Required)" label="Name">
          <InputGroup
            value={name}
            maxLength={70}
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </FormGroup>
        <FormGroup helperText="(Optional)" label="Description">
          <InputGroup
            maxLength={120}
            onChange={(e) => setDescription(e.currentTarget.value)}
            value={description}
            type="textarea"
          />
        </FormGroup>
      </DialogBody>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose} text="Cancel" />
          <Button
            disabled={!name}
            intent={Intent.PRIMARY}
            onClick={createView}
            text="Update View"
          />
        </div>
      </DialogFooter>
    </Dialog>
  );
};
