import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import {
  AnchorButton,
  Button,
  ButtonGroup,
  ControlGroup,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  MenuItem,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import { ItemRendererProps, MultiSelect } from "@blueprintjs/select";
import { useDebouncedCallback } from "hooks/useDebounce";
import ModelTagInput from "components/user/ModelTagInput";
import { Flex } from "components/utility/StyledComponents";
import { IFilterState, IFilterTypes, IModelHistoryInfo } from "types/modelHistory";
import useModelConfigOptions from "hooks/useModelConfigOptions";
import { IconNames } from "@blueprintjs/icons";
import { DateRangeInput3 } from "@blueprintjs/datetime2";

const Today = new Date();
const Tomorrow = new Date(Today);
Tomorrow.setDate(Today.getDate() + 1);
Tomorrow.setHours(0, 0, 0, 0);

export const InitialFilterState: IFilterState = {
  allActive: false,
  dateRange: [new Date(new Date().getFullYear() - 20, 0, 1), Tomorrow],
  filters: {
    Name: true,
    Ticker: true,
    Geography: false,
    Industry: false,
  },
  geoFilters: [],
  indFilters: [],
  predicate: "",
  tagFilters: [],
  tagFilterType: "And",
};

interface IModelFiltersProps {
  advancedFiltersEnabled?: boolean;
  allModels?: IModelHistoryInfo[];
  allTags?: string[];
  disabled?: boolean;
  filterState: IFilterState;
  locale?: string;
  companiesFilterConfig?: boolean;
  setFilterState: Dispatch<SetStateAction<IFilterState>>;
  setAdvancedFiltersEnabled?: Dispatch<SetStateAction<boolean>>;
  setTagFilters?: ((tagFilters: string[]) => void) | Dispatch<SetStateAction<string[]>>;
  tagFilters?: string[];
}
/**
 * Generic compoent to render filter controls to filter a list of models.
 * */
const ModelFilters: React.FC<IModelFiltersProps> = ({
  advancedFiltersEnabled = false,
  // allModels = [], TODO: Remove if unecessary
  allTags = [],
  disabled = false,
  filterState,
  locale = "en-US",
  setFilterState,
  setAdvancedFiltersEnabled,
  setTagFilters: propSetTagFilters,
  tagFilters = [], // Has to come from above because tags can be added from the table itself
  companiesFilterConfig = false,
}) => {
  // STATE
  const [predicate, setPredicate] = useState(filterState.predicate);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(advancedFiltersEnabled);
  const [industryQuery, setIndustryQuery] = useState("");

  const { geographies: _geographies, industries } = useModelConfigOptions();

  useEffect(() => setShowAdvancedFilters(advancedFiltersEnabled), [advancedFiltersEnabled]);

  const localSetTagFilters = (tagFilters: string[]) => {
    setFilterState({ ...InitialFilterState, tagFilters });
  };
  const setTagFilters = propSetTagFilters || localSetTagFilters;

  const toggleAdvancedFilters = () => {
    if (setAdvancedFiltersEnabled instanceof Function) {
      setAdvancedFiltersEnabled(!advancedFiltersEnabled);
    } else {
      setShowAdvancedFilters(!showAdvancedFilters);
    }
  };

  /**
   * Helper to improve readability in filterState modification calls
   * */
  const update = useCallback(
    (field, value) => {
      setFilterState({ ...filterState, [field]: value });
    },
    [filterState, setFilterState]
  );

  const debouncedUpdate = useDebouncedCallback(update);

  // EFFECTS
  // useEffect(() => {
  //   if (
  //     (allModels[0] && (!prevModels?.[0] || allModels.length > prevModels.length)) ||
  //     (allModels[0] && !filterState.dateRange[0])
  //   ) {
  //     const sorted = allModels.sort(
  //       (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  //     );
  //     const earliestDate = new Date(sorted[sorted.length - 1].createdAt);
  //     const latestDate = new Date(sorted[0].createdAt);
  //     if (
  //       !filterState.dateRange[0] ||
  //       filterState.dateRange[0]?.getTime() !== earliestDate.getTime() ||
  //       filterState.dateRange[1]?.getTime() !== latestDate.getTime()
  //     ) {
  //       setFilterState({
  //         ...filterState,
  //         dateRange: [earliestDate, latestDate],
  //       });
  //     }
  //   }
  // }, [allModels, prevModels, filterState, setFilterState]);

  // HELPERS
  /**
   * Helper to toggle a filter on or off.
   * */
  const _toggleFilter = (filter: keyof IFilterTypes) => {
    const nextFilters = {
      ...filterState.filters,
      [filter]: !filterState.filters[filter],
    };
    setFilterState({ ...filterState, filters: nextFilters });
  };

  // TODO: Reinclude this when we figure out how to make it work
  // const toggleTagFilterType = () => {
  //   update("tagFilterType", filterState.tagFilterType === "Or" ? "And" : "Or");
  // };

  /**
   * Clear all currently active filters
   * */
  const clearAllFilters = () => {
    setFilterState({ ...InitialFilterState, allActive: filterState.allActive });
    setTagFilters([]);
  };

  // CUSTOM RENDERERS
  /**
   * Renderer for items in the geography and industry MultiSelect filter lists.
   * */
  const renderMultiSelectItem = (
    item: string,
    { handleClick, modifiers }: ItemRendererProps,
    activeItems: string[]
  ) => {
    return (
      <MenuItem
        active={modifiers.active}
        icon={activeItems && activeItems.indexOf(item) !== -1 ? IconNames.TICK : IconNames.BLANK}
        key={item}
        onClick={handleClick}
        text={item}
      />
    );
  };

  const handleAddTag = (tag: string) => {
    setTagFilters(tagFilters.concat([tag]));
  };

  const handleTagRemove = (tag: string) => {
    setTagFilters(tagFilters.filter((tagFilter) => tagFilter !== tag));
  };

  return (
    <Flex flexDirection="column" fullWidth justifyContent="flex-start" flex="0 0">
      <Flex columnGap={20} flexDirection="row" fullWidth justifyContent="space-between">
        <Flex columnGap={10} flex="1 0" fullWidth justifyContent="flex-start">
          <ControlGroup style={{ marginBottom: showAdvancedFilters ? 10 : undefined }}>
            <InputGroup
              leftIcon="search"
              onChange={(e) => {
                setPredicate(e.target.value);
                debouncedUpdate("predicate", e.target.value);
              }}
              placeholder="Ticker..."
              rightElement={
                filterState.predicate ? (
                  <Button
                    minimal
                    onClick={() => {
                      setPredicate("");
                      setFilterState({ ...filterState, predicate: "" });
                    }}
                    icon="small-cross"
                  />
                ) : undefined
              }
              value={predicate}
            />
          </ControlGroup>
          {!companiesFilterConfig && (
            <Flex style={{ marginBottom: showAdvancedFilters ? 10 : undefined }}>
              <ControlGroup fill>
                <ModelTagInput
                  disabled={disabled}
                  allTags={allTags}
                  tags={tagFilters}
                  handleAddTag={handleAddTag}
                  handleTagRemove={handleTagRemove}
                />
                {/*TODO: Reinclude this when we figure out how to make it work. <Button onClick={toggleTagFilterType} text={filterState.tagFilterType} /> */}
              </ControlGroup>
            </Flex>
          )}
        </Flex>
        {!companiesFilterConfig && (
          <Flex
            flex="0 0 auto"
            justifyContent="flex-end"
            style={{ marginBottom: showAdvancedFilters ? 5 : undefined }}
          >
            <ButtonGroup>
              <Button
                icon={showAdvancedFilters ? IconNames.COLLAPSE_ALL : IconNames.EXPAND_ALL}
                onClick={toggleAdvancedFilters}
                style={{ alignSelf: "flex-end" }}
                text={showAdvancedFilters ? "Hide advanced filters" : "Show advanced filters"}
              />
              <Tooltip content="Clear all active filters.">
                <AnchorButton
                  icon="filter-remove"
                  intent={Intent.WARNING}
                  onClick={clearAllFilters}
                />
              </Tooltip>
            </ButtonGroup>
          </Flex>
        )}
      </Flex>
      {showAdvancedFilters ? (
        <Flex
          alignItems="flex-start"
          columnGap={10}
          flexDirection="row"
          flexWrap="wrap"
          fullWidth
          justifyContent="flex-start"
        >
          <FormGroup label="Industry filters:" style={{ flex: "2 0 0", marginBottom: 0 }}>
            <MultiSelect
              fill
              onQueryChange={setIndustryQuery}
              items={industries?.data}
              itemPredicate={(query, item) => item.toLowerCase().includes(query.toLowerCase())}
              itemRenderer={(item, props) =>
                renderMultiSelectItem(item, props, filterState.indFilters)
              }
              noResults={<MenuItem text="No results!" />}
              onItemSelect={(item) => {
                if (filterState?.indFilters && filterState.indFilters.indexOf(item) === -1) {
                  setFilterState({
                    ...filterState,
                    indFilters: filterState.indFilters.concat([item]),
                  });
                } else {
                  setFilterState({
                    ...filterState,
                    indFilters: filterState.indFilters.filter((filt) => filt !== item),
                  });
                }
              }}
              onRemove={(item) =>
                update(
                  "indFilters",
                  filterState.indFilters.filter((filt) => filt !== item)
                )
              }
              query={industryQuery}
              popoverProps={{ minimal: true }}
              resetOnSelect={true}
              selectedItems={filterState.indFilters}
              tagRenderer={(item) => item}
              tagInputProps={{ leftIcon: IconNames.OIL_FIELD }}
            />
          </FormGroup>
          {/* TODO: Re-include this when sensible geo filters are available
          <FormGroup label="Geography filters:" style={{ flex: "2 0 0" }}> */}
          {/*   <MultiSelect2 */}
          {/*     fill */}
          {/*     items={geographies?.data} */}
          {/*     itemRenderer={(item, props) => */}
          {/*       renderMultiSelectItem(item, props, filterState.geoFilters) */}
          {/*     } */}
          {/*     noResults={<MenuItem text="No results!" />} */}
          {/*     onItemSelect={(item) => { */}
          {/*       if (filterState.geoFilters.indexOf(item) === -1) { */}
          {/*         update("geoFilters", filterState.geoFilters.concat([item])); */}
          {/*       } else { */}
          {/*         update( */}
          {/*           "geoFilters", */}
          {/*           filterState.geoFilters.filter((filt) => filt !== item) */}
          {/*         ); */}
          {/*       } */}
          {/*     }} */}
          {/*     onRemove={(item) => */}
          {/*       update( */}
          {/*         "geoFilters", */}
          {/*         filterState.geoFilters.filter((filt) => filt !== item) */}
          {/*       ) */}
          {/*     } */}
          {/*     popoverProps={{ minimal: true }} */}
          {/*     selectedItems={filterState.geoFilters} */}
          {/*     tagInputProps={{ leftIcon: "globe" }} */}
          {/*     tagRenderer={renderMultiSelectTag} */}
          {/*   /> */}
          {/* </FormGroup> */}
          {!companiesFilterConfig && (
            <FormGroup
              disabled={!filterState.dateRange[0]}
              helperText={
                filterState.dateRange[0] ? (
                  <Flex justifyContent="flex-start">
                    <Tag intent={Intent.PRIMARY} minimal>
                      {filterState.dateRange[0]
                        ? filterState.dateRange[0].toLocaleDateString(locale, {
                            weekday: "long",
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          })
                        : null}
                    </Tag>
                    <Icon icon="arrow-right" style={{ marginLeft: 4, marginRight: 4 }} />
                    <Tag intent={Intent.PRIMARY} minimal>
                      {filterState.dateRange[1]
                        ? filterState.dateRange[1].toLocaleDateString(locale, {
                            weekday: "long",
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          })
                        : null}
                    </Tag>
                  </Flex>
                ) : null
              }
              label="Date range:"
              style={{ flex: "1 0 min-content", marginBottom: 0 }}
            >
              <div>
                <DateRangeInput3
                  allowSingleDayRange={true}
                  closeOnSelection={false}
                  fill
                  formatDate={(date) => date.toLocaleDateString(locale)}
                  onChange={(selectedRange) => update("dateRange", selectedRange)}
                  parseDate={(str) => new Date(str)}
                  value={filterState.dateRange}
                />
              </div>
            </FormGroup>
          )}
        </Flex>
      ) : null}
    </Flex>
  );
};

export default ModelFilters;
