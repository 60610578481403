import React from "react";
import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { signOut } from "utils/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOutRedux } from "actions/authActions";

interface ILogoutDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const LogoutDialog: React.FC<ILogoutDialogProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = () => {
    signOut();
    navigate("/");
    dispatch(signOutRedux());
  };
  return (
    <Dialog icon="log-out" isOpen={isOpen} title="Logout" onClose={onClose}>
      <div className={Classes.DIALOG_BODY}>Are you sure you want to logout?</div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" onClick={onClose} text="Cancel" />
          <Button icon="log-out" intent={Intent.PRIMARY} onClick={logout} text="Logout" />
        </div>
      </div>
    </Dialog>
  );
};

export default LogoutDialog;
