import styled, { css } from "styled-components";

const StyledConfigurationPanel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${({ justifyContent }) => justifyContent || css`space-between`};
  padding: 10px;
  width: 100%;
`;

export default StyledConfigurationPanel;
