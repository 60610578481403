/* eslint-disable react/display-name */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Alignment,
  AnchorButton,
  ButtonGroup,
  ControlGroup,
  Divider,
  Intent,
  Menu,
  MenuItem,
  mergeRefs,
  Popover,
  Position,
  Tooltip,
} from "@blueprintjs/core";
import { decreaseDecimalPlaces, increaseDecimalPlaces, ruler, textColor } from "assets/customIcons";
import CustomIcon from "components/utility/CustomIcon";
import ColorPickerPopover from "components/model/ColorPickerPopover";
import FormatOptions from "components/model/FormatOptions";
import { Flex } from "components/utility/StyledComponents";
import { titleCase } from "utils/formatter";
import "styles/val/data-entry/data-entry-table-controls.scss";
import DataEntryModuleSelect from "./DataEntryModuleSelect";
import { isConstant } from "utils/formulas";
import { constantStylesSelector, formulaStylesSelector } from "selectors/dataEntryUISelectors";
import { buildCellStyleObject } from "utils/modelGrid";
import { TGridChangeOpt } from "constants/modelConstants";
import { ICase, IFact, IModule } from "types/model";
import { IFocusedCell, IFocusedCellStyle } from "types/dataEntry";
import { IVSTableHandle } from "./VSTable";
import { TTheme } from "types";
import { IImportModule, INormalizedImportFact } from "types/import";
import { sortModulesByOrder } from "utils/data";
import { vsNavigate } from "utils/generic";
import { EUnit, IFormatObject } from "types/format";

interface IDataEntryTableControlsProps {
  addColumn?: (position: "start" | "end") => void;
  availableGridChangeOps?: { [key: string]: TGridChangeOpt };
  autoAdjustColsWidth: boolean;
  config?: {
    columnWidths: boolean;
    copyPaste: boolean;
    formatting: boolean;
    gridChangeOps: boolean;
    selectModule: boolean;
    textFormatting: boolean;
  };
  copy?: () => void;
  currentCase?: ICase;
  currentModule?: IModule | IImportModule;
  deleteColumn?: (position: "start" | "end") => void;
  deleteLineItem?: () => void;
  editDecimalPlaces?: (isIncrement: boolean) => void;
  focusedCell: IFocusedCell<IFact | INormalizedImportFact> | null;
  formatAndEditCells: (formatChange: { type: string; change: string }) => void;
  handleAddLineItem?: () => void;
  locked: boolean;
  moduleList?: IModule[];
  rawValues?: boolean | undefined;
  setAutoAdjustColsWidth: (autoAdjust: boolean) => void;
  setRawValues?: Dispatch<SetStateAction<boolean>>;
  paste?: (raw: boolean) => void;
  tableRef: React.MutableRefObject<IVSTableHandle | null>;
  theme: TTheme;
}

const DataEntryTableControls: React.FC<IDataEntryTableControlsProps> = React.memo(
  // Open issue with plugin, passing unnamed func to React.memo gives false positive
  ({
    addColumn,
    availableGridChangeOps,
    autoAdjustColsWidth,
    copy,
    config = {
      columnWidths: true,
      copyPaste: true,
      formatting: true,
      gridChangeOps: true,
      selectModule: true,
      textFormatting: true,
    },
    currentCase,
    currentModule,
    deleteLineItem,
    editDecimalPlaces,
    focusedCell,
    formatAndEditCells,
    handleAddLineItem,
    locked,
    moduleList,
    rawValues,
    setAutoAdjustColsWidth,
    setRawValues,
    paste,
    deleteColumn,
    tableRef,
    theme,
  }) => {
    const navigate = useNavigate();
    const [focusedCellStyle, setFocusedCellStyle] = useState<IFocusedCellStyle>();
    const constantStyles = useSelector(constantStylesSelector);
    const formulaStyles = useSelector(formulaStylesSelector);

    // format/style the focused cell
    useEffect(() => {
      const fact = focusedCell?.fact;
      if (!fact?.formula) return;
      const { format, formula } = fact;
      const isFormulaConstant = isConstant(formula);
      const color = format?.color || "default";
      const backgroundColor = format?.backgroundColor || "default";

      const defaultStyle = isFormulaConstant
        ? (constantStyles[theme] as IFormatObject)
        : (formulaStyles[theme] as IFormatObject);

      setFocusedCellStyle({
        ...buildCellStyleObject({ ...defaultStyle, ...format }, fact.error, true),
        default: defaultStyle,
        color,
        backgroundColor,
        type: isFormulaConstant ? "Constant" : "Formula",
        unit: format.unit || EUnit.RAW,
      });
    }, [constantStyles, focusedCell, formulaStyles, theme]);

    // TODO: pass explicitly allowed signatures
    // eslint-disable-next-line @typescript-eslint/ban-types
    const onClickHelper = (func?: Function) => {
      if (typeof func === "function") func();
      tableRef?.current?.focusGrid();
    };

    const sortedModules = moduleList ? sortModulesByOrder(moduleList) : [];

    return (
      <Flex flexDirection="row" justifyContent="space-between" style={{ height: 30 }}>
        <Flex justifyContent="space-between">
          {config.selectModule ? (
            <ControlGroup style={{ marginRight: 15 }}>
              <DataEntryModuleSelect
                id="control-bar"
                currentModule={currentModule}
                label=""
                moduleList={sortedModules}
                onItemSelect={(item) => {
                  navigate(vsNavigate(item.name));
                }}
              />
            </ControlGroup>
          ) : null}
          {config.copyPaste && copy && paste ? (
            <ButtonGroup>
              <Tooltip position={Position.TOP} content="Copy">
                <AnchorButton
                  disabled={!focusedCell}
                  icon="duplicate"
                  onClick={() => onClickHelper(copy)}
                  minimal
                />
              </Tooltip>
              <Tooltip position={Position.TOP} content="Paste">
                <AnchorButton
                  disabled={locked || !focusedCell}
                  icon="clipboard"
                  onClick={() => onClickHelper(() => paste(false))}
                  minimal
                />
              </Tooltip>
              <Divider />
            </ButtonGroup>
          ) : null}
          <Popover
            autoFocus={false}
            content={
              <FormatOptions fact={focusedCell?.fact} formatAndEditCells={formatAndEditCells} />
            }
            disabled={locked || !focusedCell}
            renderTarget={({ ref: popoverRef, isOpen: _isOpen, ...popoverProps }) => (
              <Tooltip content="Format" openOnTargetFocus={false} position={Position.TOP}>
                <AnchorButton
                  {...popoverProps}
                  alignText={Alignment.LEFT}
                  disabled={locked || !focusedCell}
                  ref={mergeRefs(popoverRef)}
                  minimal
                  icon="numerical"
                  style={{ minWidth: 125 }}
                  text={titleCase(focusedCell?.fact?.format?.valFormat)}
                />
              </Tooltip>
            )}
          />
          <Popover
            autoFocus={false}
            disabled={locked || !focusedCell}
            content={
              <Menu>
                {Object.values(EUnit).map((opt) => (
                  <MenuItem
                    icon={focusedCellStyle?.unit === opt ? "tick" : undefined}
                    intent={focusedCellStyle?.unit === opt ? Intent.PRIMARY : undefined}
                    onClick={() =>
                      onClickHelper(() => formatAndEditCells({ type: "unit", change: opt }))
                    }
                    key={opt}
                    text={opt}
                  />
                ))}
              </Menu>
            }
            renderTarget={({ ref: popoverRef, isOpen: _isOpen, ...popoverProps }) => (
              <Tooltip content="Unit" openOnTargetFocus={false} position={Position.TOP}>
                <AnchorButton
                  {...popoverProps}
                  alignText={Alignment.LEFT}
                  disabled={locked || !focusedCell}
                  ref={mergeRefs(popoverRef)}
                  minimal
                  icon={
                    <CustomIcon pathsOrSVG={ruler} customViewBox="0 0 24 24" iconName="ruler" />
                  }
                  style={{ minWidth: 115 }}
                  text={titleCase(focusedCell?.fact?.format?.unit)}
                />
              </Tooltip>
            )}
          />
          {editDecimalPlaces ? (
            <ButtonGroup>
              <Divider />
              <Tooltip position={Position.TOP} content="Decrease decimal places">
                <AnchorButton
                  disabled={locked || !focusedCell}
                  icon={
                    <CustomIcon
                      pathsOrSVG={decreaseDecimalPlaces}
                      iconName="decrease-decimal-places"
                    />
                  }
                  minimal
                  onClick={() => onClickHelper(() => editDecimalPlaces(false))}
                />
              </Tooltip>
              <Tooltip position={Position.TOP} content="Increase decimal places">
                <AnchorButton
                  disabled={locked || !focusedCell}
                  icon={
                    <CustomIcon
                      pathsOrSVG={increaseDecimalPlaces}
                      iconName="increase-decimal-places"
                    />
                  }
                  minimal
                  onClick={() => onClickHelper(() => editDecimalPlaces(true))}
                />
              </Tooltip>
            </ButtonGroup>
          ) : null}
          {config?.textFormatting ? (
            <ButtonGroup>
              <Divider />
              <Tooltip position={Position.TOP} content="Bold">
                <AnchorButton
                  active={focusedCell?.fact?.format?.fontWeight === "bold"}
                  disabled={locked || !focusedCell}
                  icon="bold"
                  minimal
                  onClick={() =>
                    onClickHelper(() => formatAndEditCells({ type: "fontWeight", change: "bold" }))
                  }
                />
              </Tooltip>
              <Tooltip position={Position.TOP} content="Italic">
                <AnchorButton
                  active={focusedCell?.fact?.format?.fontStyle === "italic"}
                  disabled={locked || !focusedCell}
                  icon="italic"
                  minimal
                  onClick={() =>
                    onClickHelper(() => formatAndEditCells({ type: "fontStyle", change: "italic" }))
                  }
                />
              </Tooltip>
              <Tooltip position={Position.TOP} content="Underline">
                <AnchorButton
                  active={focusedCell?.fact?.format?.textDecoration === "underline"}
                  disabled={locked || !focusedCell}
                  icon="underline"
                  minimal
                  onClick={() =>
                    onClickHelper(() =>
                      formatAndEditCells({ type: "textDecoration", change: "underline" })
                    )
                  }
                />
              </Tooltip>
              <Popover
                content={
                  focusedCell && focusedCellStyle ? (
                    <ColorPickerPopover
                      focusedCellFact={focusedCell?.fact}
                      focusedCellStyle={focusedCellStyle}
                      onChange={formatAndEditCells}
                      prop="color"
                    />
                  ) : undefined
                }
                disabled={locked || !focusedCell}
                onClosed={() => onClickHelper()}
              >
                <Tooltip position={Position.TOP} content="Text color">
                  <AnchorButton
                    disabled={locked || !focusedCell}
                    icon={<CustomIcon pathsOrSVG={textColor} iconName="text-color" />}
                    minimal
                  />
                </Tooltip>
              </Popover>
              <Popover
                content={
                  focusedCell && focusedCellStyle ? (
                    <ColorPickerPopover
                      focusedCellFact={focusedCell?.fact}
                      focusedCellStyle={focusedCellStyle}
                      onChange={formatAndEditCells}
                      prop="backgroundColor"
                    />
                  ) : undefined
                }
                disabled={!focusedCell || locked}
                onClosed={() => onClickHelper()}
              >
                <Tooltip position={Position.TOP} content="Fill color">
                  <AnchorButton disabled={locked || !focusedCell} minimal icon="tint" />
                </Tooltip>
              </Popover>
            </ButtonGroup>
          ) : null}
        </Flex>
        <Flex justifyContent="space-between">
          <ControlGroup>
            {setRawValues && (
              <>
                <Tooltip position={Position.TOP} content="Toggle show raw values">
                  <AnchorButton
                    active={rawValues}
                    icon="array-numeric"
                    minimal
                    onClick={() => setRawValues(!rawValues)}
                  />
                </Tooltip>
                <Divider />
              </>
            )}
            {config.columnWidths ? (
              <>
                <Tooltip position={Position.TOP} content="Reset column widths">
                  <AnchorButton
                    icon="merge-columns"
                    minimal
                    onClick={() => tableRef.current?.resetColWidths()}
                  />
                </Tooltip>
                <Tooltip position={Position.TOP} content="Auto fit column widths">
                  <AnchorButton
                    active={autoAdjustColsWidth}
                    icon="split-columns"
                    minimal
                    onClick={() => setAutoAdjustColsWidth(!autoAdjustColsWidth)}
                  />
                </Tooltip>
                <Divider />
              </>
            ) : null}
            {config?.gridChangeOps && addColumn && deleteColumn ? (
              <>
                <Tooltip position={Position.TOP} content="Add column to the beginning">
                  <AnchorButton
                    disabled={locked || !availableGridChangeOps?.ADD_COLUMN}
                    icon="add-column-left"
                    minimal
                    onClick={() => onClickHelper(() => addColumn("start"))}
                  />
                </Tooltip>
                <Tooltip position={Position.TOP} content="Add column to the end">
                  <AnchorButton
                    disabled={locked || !availableGridChangeOps?.ADD_COLUMN}
                    icon="add-column-right"
                    minimal
                    onClick={() => onClickHelper(() => addColumn("end"))}
                  />
                </Tooltip>
                <Tooltip position={Position.TOP} content="Remove leftmost column">
                  <AnchorButton
                    disabled={
                      locked ||
                      !availableGridChangeOps?.DELETE_COLUMN ||
                      (focusedCell && focusedCell?.fact.uid.indexOf("temp") > -1) ||
                      currentModule?.moduleStart === currentCase?.startPeriod ||
                      currentModule?.formattedPeriods.length === 1
                    }
                    icon="remove-column-left"
                    minimal
                    onClick={() => onClickHelper(() => deleteColumn("start"))}
                  />
                </Tooltip>
                <Tooltip position={Position.TOP} content="Remove rightmost column">
                  <AnchorButton
                    disabled={
                      locked ||
                      !availableGridChangeOps?.DELETE_COLUMN ||
                      (focusedCell && focusedCell?.fact.uid.indexOf("temp") > -1) ||
                      currentModule?.moduleEnd === currentCase?.startPeriod ||
                      currentModule?.formattedPeriods.length === 1
                    }
                    icon="remove-column-right"
                    minimal
                    onClick={() => onClickHelper(() => deleteColumn("end"))}
                  />
                </Tooltip>
                <Tooltip position={Position.TOP} content="Add line item below">
                  <AnchorButton
                    disabled={locked || !availableGridChangeOps?.ADD_ROW || !currentModule?.uid}
                    icon="add-row-bottom"
                    minimal
                    onClick={() => onClickHelper(handleAddLineItem)}
                  />
                </Tooltip>
                <Tooltip
                  position={Position.TOP}
                  content={
                    focusedCell &&
                    focusedCell?.fact?.parent.tags &&
                    focusedCell?.fact?.parent.tags.length > 0
                      ? "Cannot delete tagged line items"
                      : "Delete Line Item"
                  }
                >
                  <AnchorButton
                    icon="exclude-row"
                    disabled={
                      locked ||
                      !availableGridChangeOps?.DELETE_ROW ||
                      (focusedCell && focusedCell?.fact.uid.indexOf("temp") > -1) ||
                      !focusedCell ||
                      (focusedCell?.fact?.parent.tags && focusedCell?.fact?.parent.tags.length > 0)
                    }
                    intent={Intent.DANGER}
                    minimal
                    onClick={() => onClickHelper(deleteLineItem)}
                  />
                </Tooltip>
              </>
            ) : null}
          </ControlGroup>
        </Flex>
      </Flex>
    );
  }
);

export default DataEntryTableControls;
