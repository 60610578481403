import React, { useState } from "react";
import Auth from "@aws-amplify/auth";
import { Button, FormGroup, H5, InputGroup, Intent } from "@blueprintjs/core";

import { AuthContainer, AuthForm } from "components/user/StyledAuthComponents";
import { Flex } from "components/utility/StyledComponents";
import { multiToaster } from "utils/toaster";

const TOTP = ({ setUser, user }) => {
  const [totpToken, setTotpToken] = useState("");
  const [helperText, setHelperText] = useState("");

  function verifyTOTPToken() {
    try {
      Auth.confirmSignIn(user, totpToken, "SOFTWARE_TOKEN_MFA").then((data) => {
        setUser(data);
        multiToaster.show({
          message: "Successfully logged in.",
          intent: Intent.SUCCESS,
        });
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      const error = err.message ? err.message : err;
      multiToaster.show({
        message: error,
        intent: Intent.WARNING,
      });
      setHelperText(error);
    }
  }

  return (
    <AuthContainer className="totp auth-card">
      <H5>Enter code from verification device.</H5>
      <AuthForm
        onSubmit={(e) => {
          e.preventDefault();
          verifyTOTPToken();
        }}
      >
        <FormGroup
          className="form-group"
          label="Verification code:"
          labelFor="totp-token"
          helperText={helperText}
          intent={helperText ? Intent.WARNING : null}
        >
          <InputGroup
            id="totpToken"
            value={totpToken}
            type="text"
            onChange={(e) => setTotpToken(e.target.value.trim())}
          />
        </FormGroup>
        <Flex flexDirection="column">
          <Button
            intent={Intent.PRIMARY}
            rightIcon="circle-arrow-right"
            style={{ alignSelf: "flex-end" }}
            text="Verify"
            type="submit"
          />
        </Flex>
      </AuthForm>
    </AuthContainer>
  );
};
export default TOTP;
