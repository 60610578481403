import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Intent } from "@blueprintjs/core";
import AppContext from "context/appContext";
import {
  focusedCellSelector,
  rowsSelector,
  selectedRegionFactsSelector,
} from "selectors/dataEntrySelectors";
import {
  availableGridChangeOpsSelector,
  caseModulesSelector,
  currentCaseDataSelector,
  currentCaseSelector,
  currentModuleSelector,
  currentModuleUidSelector,
  modelUidSelector,
} from "selectors/modelSelectors";
import {
  addClipboardItem,
  addColumn as addColumnAction,
  addLineItem,
  deleteColumn as deleteColumnAction,
  deleteLineItem as deleteLineItemAction,
  deleteModule,
  editFormat,
  editFormula,
  editLineItem,
  editModuleName,
  redo as redoAction,
  reorderModule,
  undo as undoAction,
} from "actions/dataEntryActions";
import {
  setNavCellCoords,
  updateEditMode,
  updateFocusedCell,
  updateFormula,
} from "actions/dataEntryUIActions";
import usePrevious from "hooks/usePrevious";
import { getMetaKey, removeHeadersFromClipboard, vsNavigate } from "utils/generic";
import { multiToaster } from "utils/toaster";
import { DEFAULT_DECIMAL_PLACES, formatExternalCopy, internalCopyToText } from "utils/formatter";
import { formatVSObjects } from "utils/data";
import {
  defaultLineItemName as defaultLineItemNameConst,
  getDefaultName,
  tableCopy,
  tableDrag,
  tablePaste,
  tableWidthsWithDefaults,
} from "utils/modelGrid";
import DataEntryTable from "components/model/DataEntryTable";
import ModuleDiagram from "components/model/ModuleDiagram";
import NewModuleDialog from "components/model/NewModuleDialog";
import HotkeyDialog from "components/model/HotkeyDialog";
import DataEntryHeader from "components/model/DataEntryHeader";
import { Flex, FullSizeDiv } from "components/utility/StyledComponents";
import "styles/val/data-entry/data-entry.scss";
import "styles/val/data-entry/data-entry-table.scss";
import { DataEntryEditMode } from "constants/dataEntryUIConstants";
import { availableStateChangeSelector } from "selectors/eventHistorySelectors";
import { useLatest } from "hooks/useLatest";
import DeleteModuleDialog from "./DeleteModuleDialog";
import { EDashboardType } from "types/dashboard";
import {
  CONTROL_BAR_HEIGHT,
  DATAENTRY_TOOLBAR_HEIGHT,
  SIDE_MENU_WIDTH,
} from "constants/uiConstants";
import SideMenu from "./SideMenu";
import ModelContext from "context/modelContext";

const DataEntry = ({
  activeMenu,
  addClipboardItem,
  addColumnAction,
  addLineItem,
  appSize,
  availableGridChangeOps,
  availableStateChanges,
  clipboardData,
  currentCase,
  currentCaseData,
  currentModule,
  currentModuleUid,
  dataSources,
  deleteColumnAction,
  deleteLineItemAction,
  deleteModule,
  diagramOpen,
  editFormat,
  editFormula,
  editLineItem,
  editMode,
  editModuleName,
  events,
  facts,
  focusedCellCoords,
  groupBehaviour,
  historyData,
  lineItems,
  modelLocked,
  modules,
  navCellCoords,
  reorderModule,
  rowHeaderFocus,
  setNavCellCoords,
  selectedRegions,
  selectedRegionFacts,
  tickers,
  updateEditMode,
  updateFormula,
  updateFocusedCell,
}) => {
  const navigate = useNavigate();
  const {
    config: { theme },
  } = useContext(AppContext);
  const {
    config: { autoAdjustColumnWidths, columnWidths, showErrors },
    setConfig,
  } = useContext(ModelContext);

  const [rawValues, setRawValues] = useState(false);
  const rowsSelectorConfig = {
    // props need to be undefined to avoid selector triggering
    showErrors: showErrors ? true : undefined,
    rawValues: rawValues ? true : undefined,
  };
  const rows = useSelector((state) => rowsSelector(state, rowsSelectorConfig));
  const focusedCell = useSelector(focusedCellSelector);
  const [creatingNewModule, setCreatingNewModule] = useState(false);
  const [deleteModuleDialogOpen, setDeleteModuleDialogOpen] = useState(false);
  const [deletingModule, setDeletingModule] = useState(false);
  const [hotkeyDialogOpen, setHotkeyDialogOpen] = useState(false);
  const [dashboardSize, setDashboardSize] = useState({
    ...appSize,
    width: activeMenu > 0 ? appSize?.width && appSize.width - SIDE_MENU_WIDTH : appSize.width,
  });
  const [formulaBarHeight, setFormulaBarHeight] = useState(50);
  const [autoAdjustColsWidth, setAutoAdjustColsWidth] = useState(autoAdjustColumnWidths);
  const [showLineItemsInTree, setShowLineItemsInTree] = useState(false);
  const [showUntaggedLineItems, setShowUntaggedLineItems] = useState(false);

  const tableRef = useRef(null);
  const focusedCellRef = useLatest(focusedCell);
  const focusedCellCoordsRef = useLatest(focusedCellCoords);
  const selectedRegionsRef = useLatest(selectedRegions);
  const selectedRegionFactsRef = useLatest(selectedRegionFacts);
  const clipboardDataRef = useLatest(clipboardData);
  const lastDefaultLineItemName = useRef("");
  const defaultLineItemName = useRef("");
  const dataEntryHeaderRef = useRef();
  const prevModUid = usePrevious(currentModuleUid);
  const [expandedNodes, setExpandedNodes] = useState();
  const navCellTimeoutRef = useRef();

  const moduleList = useMemo(() => {
    if (!modules) return [];
    return Object.values(modules);
  }, [modules]);

  const rowHeaders = useMemo(() => {
    if (!rows) return [];
    return rows.map((row) => {
      return { name: row.rowHeader, uid: row.uid };
    });
  }, [rows]);
  const prevRowHeaders = usePrevious(rowHeaders);

  const updateAutoAdjustColsWidth = (nextValue) => {
    setAutoAdjustColsWidth(nextValue);
    setConfig({ autoAdjustColumnWidths: nextValue });
  };

  /** Hook to fetch quick calculations */
  /*useEffect(() => {
    if (currentCase) {
      fetchQuickCalculations();
    }
  }, [currentCase, fetchQuickCalculations]);*/

  /** Set focused cell based on url coords */
  const focusCell = useCallback(
    (coords) => {
      if (!tableRef.current) return;
      const { row, col } = focusedCellRef.current;
      // navigating to the already focused cell does nothing
      if (coords.row === row && coords.col === col) {
        navigate(window.location.pathname);
        return;
      }

      if (coords && (row !== coords.row || col !== coords.col)) {
        tableRef.current.selectCell({ rowIdx: coords.row, idx: coords.col + 1 });
        setTimeout(() => {
          if (!tableRef) return;
          tableRef.current.scrollToRow(coords.row);
        }, 200);
      } else {
        // go to 0,0 if no coords given
        tableRef.current.selectCell({ rowIdx: 0, idx: 1 });
      }

      // clean coords from url after url nav
      navigate(window.location.pathname);
    },
    [focusedCellRef, navigate, tableRef]
  );

  /** Handle focus on module change (including start) */
  useEffect(() => {
    if (currentModule) {
      Sentry.setContext("dataEntry", {
        caseID: currentCase.uid,
        col: focusedCellRef?.current?.col,
        currentModule: currentModule,
        ...(focusedCellRef.current?.fact?.formula && {
          fact: (({ formula, identifier, uid, value }) => ({ formula, identifier, uid, value }))(
            focusedCellRef.current?.fact
          ),
        }),
        row: focusedCellRef.current?.col,
      });
    }
  }, [currentCase, currentModule, focusedCellRef]);

  const handleDeleteModule = (moduleID) => {
    setDeletingModule(true);
    if (currentModule.uid === moduleID) {
      const nextModuleName =
        moduleList.find((mod) => mod.uid === moduleID.parentUid)?.name ||
        moduleList.find((mod) => mod.depth === 0)?.name;
      navigate(vsNavigate(nextModuleName));
    }
    const parentMod = moduleList.find((mod) => mod.childModules?.includes(moduleID));
    deleteModule(currentCase.uid, moduleID, parentMod?.uid);
  };

  /** Handle module change and url coords table cell focus */
  useEffect(() => {
    // we need timeout because other components might try to grab focus
    if (prevModUid !== currentModule.uid && !navCellCoords) {
      // save timeout to clear if a nav is attempted before this fires
      navCellTimeoutRef.current = setTimeout(() => {
        const moduleColumnWidthsObj = columnWidths?.[currentModule.uid] || {};
        const widths = tableWidthsWithDefaults(
          Object.values(moduleColumnWidthsObj),
          currentModule.formattedPeriods.length + 1
        );
        const tableFullWidth = widths.reduce((acc, res) => acc + res);
        const scrollRight = tableFullWidth > tableRef.current.clientWidth;
        const scrollIdx = scrollRight ? currentModule.formattedPeriods.length : 1;
        tableRef.current?.selectCell({ rowIdx: 0, idx: scrollIdx });
      }, 250);
    }
    if (navCellCoords) {
      clearInterval(navCellTimeoutRef.current);
      focusCell(navCellCoords);
      setNavCellCoords(null);
    }
  }, [columnWidths, currentModule, focusCell, navCellCoords, prevModUid, setNavCellCoords]);

  /** Initial set of the default row header name when landing in a module */
  useEffect(() => {
    if (!rowHeaders) return;
    if (prevModUid !== currentModuleUid)
      defaultLineItemName.current = getDefaultName(rowHeaders, 1);
  }, [currentModuleUid, prevModUid, rowHeaders]);

  /** Grabs focus for row header when new line is created */
  useEffect(() => {
    if (!tableRef.current || !rowHeaders) return;
    if ((prevRowHeaders?.length || 0) >= rowHeaders.length) return;
    for (let i = 0; i < rowHeaders.length; i++) {
      const rowHeader = rowHeaders[i];
      const prevHeaderUid = prevRowHeaders?.[i]?.uid;
      if (
        currentModuleUid === prevModUid &&
        prevHeaderUid !== rowHeader.uid &&
        rowHeader.name === lastDefaultLineItemName.current
      ) {
        tableRef.current.selectCell({ rowIdx: i, idx: 0 }, true);
        updateFocusedCell({ col: 0, row: i });
        break;
      }
    }
  }, [rowHeaders, lineItems, currentModuleUid, prevModUid, prevRowHeaders, updateFocusedCell]);

  /** Recompute size when bars change */
  useEffect(() => {
    // taking 3 height pixels to account for borders
    setDashboardSize({
      width: activeMenu > 0 ? appSize?.width && appSize.width - SIDE_MENU_WIDTH : appSize.width,
      height: appSize.height - CONTROL_BAR_HEIGHT - 3,
      tableHeight:
        appSize.height - CONTROL_BAR_HEIGHT - DATAENTRY_TOOLBAR_HEIGHT - formulaBarHeight - 3,
    });
  }, [activeMenu, appSize, formulaBarHeight]);

  /** If explorer is active give focus to table */
  useEffect(() => {
    if (activeMenu === 1) tableRef.current.focusGrid();
  }, [activeMenu]);

  const formulaConfig = useMemo(() => {
    return {
      mode: "dataEntry",
      data: {
        dataSources: dataSources,
        moduleList: moduleList,
        tickers: tickers,
      },
    };
  }, [dataSources, moduleList, tickers]);

  /**
   * Copies table regions with row/col headers as appropriate.
   *
   * rowHeaders are copied by default only when the RegionCardinality is FULL_ROWS. columnHeaders are copied
   * only when the RegionCardinality is FULL_COLUMNS.
   */
  const copy = useCallback(() => {
    if (!navigator.clipboard) {
      multiToaster.show({
        message:
          "Couldn't copy value. Your browser might not support clipboard functionality or you're using an insecure connection.",
        intent: Intent.WARNING,
      });
      return;
    }

    const userRequestedHeaders = false; // TODO: this should be a parameter but it's not being used yet

    const copyObj = tableCopy(
      rows,
      selectedRegionsRef.current,
      currentModule.formattedPeriods,
      rowHeaders,
      userRequestedHeaders
    );

    const copyText = internalCopyToText(copyObj.items);

    navigator.clipboard.writeText(copyText).catch((err) =>
      multiToaster.show({
        message: "Error when writing to clipboard: " + err,
        intent: Intent.WARNING,
      })
    );

    addClipboardItem(copyObj);
  }, [addClipboardItem, currentModule, rowHeaders, rows, selectedRegionsRef]);

  /**
   * Pastes data into the data entry table.
   * Figures out if the most recent copy was from external or internal source.
   * External source is always pasted as text while internal source considers formulas.
   * To distinguish when copy was with headers, headers are removed if match the ones saved at copy time. Then they are removed from the external clipboard text so it then can be compared to the internal one that is converted to text. If they match the internal one is used.
   * Note: if an outside copy is exactly equal to the internal the internal will be used. Google Sheets has the same issue.
   *
   * @param raw:boolean if true the pasted values are all raw, ignoring formulas.
   */
  const paste = useCallback(
    (raw) => {
      if (!navigator.clipboard) {
        multiToaster.show({
          message:
            "Couldn't paste value. Your browser might not support clipboard functionality or you're using an insecure connection.",
          intent: Intent.WARNING,
        });
        return;
      }

      // decide between internal or external clipboard
      // TODO: navigator.clipboard will fail if not https
      navigator.clipboard
        .readText()
        .then((externalClipboardText) => {
          let clipboard;
          let rawValues = raw;
          if (!clipboardDataRef.current) {
            clipboard = formatExternalCopy(externalClipboardText);
            rawValues = true;
          } else {
            const internalClipboardText = internalCopyToText(clipboardDataRef.current.items);
            if (internalClipboardText === externalClipboardText.replace(/\r/gm, "")) {
              clipboard = removeHeadersFromClipboard(clipboardDataRef.current);
            } else {
              clipboard = formatExternalCopy(externalClipboardText);
              rawValues = true;
            }
          }
          const editedFacts = tablePaste(
            clipboard,
            rows,
            focusedCellRef.current,
            rawValues,
            modules,
            lineItems,
            facts
          );
          if (!editedFacts?.length) return;
          editFormula(currentCaseData.uid, currentModule.uid, editedFacts);
        })
        .catch((err) => {
          multiToaster.show({
            message: "Error reading from clipboard: " + err,
            intent: Intent.WARNING,
          });
        });
    },
    [
      clipboardDataRef,
      currentCaseData.uid,
      currentModule.uid,
      editFormula,
      facts,
      focusedCellRef,
      lineItems,
      modules,
      rows,
    ]
  );

  const drag = useCallback(
    (direction, start, end) => {
      const editedFacts = tableDrag(
        rows,
        focusedCellRef.current,
        selectedRegionsRef.current,
        direction,
        start,
        end,
        modules,
        lineItems,
        facts
      );

      if (!editedFacts.length) return;
      editFormula(currentCaseData.uid, currentModule.uid, editedFacts);
    },
    [
      rows,
      focusedCellRef,
      selectedRegionsRef,
      facts,
      lineItems,
      modules,
      editFormula,
      currentCaseData.uid,
      currentModule.uid,
    ]
  );

  const handleAddLineItem = useCallback(() => {
    const rowIdx = focusedCellCoordsRef.current ? focusedCellCoordsRef.current.row : 0;
    const increment = rowHeaders.length ? 2 : 1;
    let newRowIdx = rowIdx + increment;
    addLineItem(
      currentCase.uid,
      newRowIdx,
      defaultLineItemName.current,
      currentModule,
      currentModule.uid
    );
    lastDefaultLineItemName.current = defaultLineItemName.current;
    defaultLineItemName.current =
      defaultLineItemNameConst +
      " " +
      (Number(defaultLineItemName.current.substring(defaultLineItemNameConst.length)) + 1);
  }, [addLineItem, currentCase, currentModule, rowHeaders, focusedCellCoordsRef]);

  const addColumn = useCallback(
    (pos) => {
      const newPeriod =
        pos === "start"
          ? currentModule.moduleStart - currentModule.forecastIncrement
          : currentModule.moduleEnd + currentModule.forecastIncrement;
      addColumnAction(currentCase.uid, currentModule.uid, newPeriod, pos);
    },
    [addColumnAction, currentCase, currentModule]
  );

  const deleteColumn = useCallback(
    (pos) => {
      const newPeriod = pos === "start" ? currentModule.moduleStart : currentModule.moduleEnd;
      if (pos === "end") {
        if (focusedCellCoordsRef.current) {
          const { col, row } = focusedCellCoordsRef.current;
          tableRef.current.selectCell({ rowIdx: row, idx: col });
        }
      }
      let restoreFacts = [];
      if (currentModule?.lineItems?.length) {
        currentModule.lineItems.forEach((liUid) => {
          const li = lineItems[liUid];
          li.facts.forEach((factUid) => {
            const fact = facts[factUid];
            if (fact.period === newPeriod) restoreFacts.push(fact);
          });
        });
      }
      deleteColumnAction(
        currentCase.uid,
        currentModule.uid,
        currentModule.name,
        newPeriod,
        restoreFacts
      );
    },
    [currentCase, currentModule, deleteColumnAction, facts, focusedCellCoordsRef, lineItems]
  );

  const deleteLineItem = useCallback(() => {
    if (focusedCellCoordsRef.current) {
      if (focusedCellCoordsRef.current.row - 1 >= 0) {
        tableRef.current.selectCell({
          rowIdx: focusedCellCoordsRef.current.row - 1,
          idx: focusedCellCoordsRef.current.col + 1,
        });
      }
      const { fact } = focusedCellRef.current;
      const restoreFacts = [];
      lineItems[fact.parentUid].facts.forEach((factUid) => {
        restoreFacts.push(facts[factUid]);
      });
      deleteLineItemAction(
        currentCase.uid,
        currentModule.uid,
        fact.parentUid,
        lineItems[fact.parentUid],
        restoreFacts
      );
    }
  }, [
    currentCase,
    currentModule,
    deleteLineItemAction,
    facts,
    focusedCellRef,
    focusedCellCoordsRef,
    lineItems,
  ]);

  const updateModuleName = useCallback(
    (moduleID, newModuleName) => {
      editModuleName(modules[moduleID], newModuleName);
    },
    [editModuleName, modules]
  );

  const handleRowsReordered = useCallback(
    (i, j) => {
      if (i === j) return;
      const lineItem = lineItems[rows[i][0].parentUid];

      // table is zero indexed but order is 1 indexed
      reorderModule(currentCase.uid, currentModule.uid, lineItem, j + 1);
      tableRef.current.selectCell({ rowIdx: j, idx: 1 });
    },
    [rows, currentCase, currentModule, lineItems, reorderModule]
  );

  const setFactError = useCallback(
    (e) => {
      if (rows && focusedCellRef.current) {
        const { col, row } = focusedCellRef.current;
        const currentFact = rows[row][col];
        if (currentFact) currentFact.error = e;
      }
    },
    [rows, focusedCellRef]
  );

  const formatAndEditCells = useCallback(
    (formatChange) => {
      let { type, change } = formatChange;

      // whole line item formatting
      if (
        (type === "fontWeight" ||
          type === "fontStyle" ||
          type === "textDecoration" ||
          type === "color" ||
          type === "backgroundColor") &&
        rowHeaderFocus
      ) {
        const facts = selectedRegionFactsRef.current;
        const parentUids = Array.from(new Set(facts.map((f) => f.parentUid)));
        const lineItemObjects = parentUids.map((uid) => ({
          ...lineItems[uid],
          format: { ...lineItems[uid].format } || {},
        }));
        const formattedLineItems = formatVSObjects(lineItemObjects, formatChange);
        change = formattedLineItems[0].format[type];
        formattedLineItems.forEach((li) => {
          li.format = JSON.stringify(li.format);
          li.id = li.uid;
        });
        editLineItem(currentCase.uid, currentModule.uid, formattedLineItems);
      }
      const facts = formatVSObjects(selectedRegionFactsRef.current, { type, change }).map((f) => ({
        ...f,
        ...(f.format && { format: JSON.stringify(f.format) }),
        id: f.uid,
      }));
      editFormat(currentCase.uid, currentModule.uid, facts, selectedRegionFactsRef.current);
    },
    [
      rowHeaderFocus,
      selectedRegionFactsRef,
      editFormat,
      currentCase.uid,
      currentModule.uid,
      editLineItem,
      lineItems,
    ]
  );

  const editDecimalPlaces = useCallback(
    (isIncrement) => {
      const { fact } = focusedCellRef.current;
      const decimalPlaces =
        fact.format?.decimalPlaces && !Number.isNaN(fact.format.decimalPlaces)
          ? Number(fact.format?.decimalPlaces)
          : DEFAULT_DECIMAL_PLACES;
      let change;
      if (isIncrement) {
        change = decimalPlaces + 1;
      } else {
        if (decimalPlaces === 0) {
          return;
        }
        change = decimalPlaces - 1;
      }
      formatAndEditCells({
        type: "decimalPlaces",
        change: change.toString(),
      });
    },
    [formatAndEditCells, focusedCellRef]
  );

  const onCreateModule = () => {
    setCreatingNewModule(!creatingNewModule);
  };

  const onDeleteModule = () => {
    setDeleteModuleDialogOpen(!deleteModuleDialogOpen);
  };

  // Callback function passed to the table to be called when user tries to edit a cell by typing
  const onCellInput = useCallback(
    (e) => {
      const metaKey = getMetaKey(e);
      if (editMode !== DataEntryEditMode.NORMAL) return;
      if (document.activeElement.parentElement.getAttribute("role") !== "grid") return;
      const normalEdit = () => {
        updateFormula("");
        updateEditMode(DataEntryEditMode.FORMULA);
      };
      switch (e.key) {
        // META
        case "?":
          e.preventDefault();
          setHotkeyDialogOpen(!hotkeyDialogOpen);
          break;
        // MODE
        case "Enter":
          break;
        case "Tab":
          e.preventDefault();
          break;
        case "=":
          if (modelLocked) return;
          if (metaKey && e.altKey) {
            handleAddLineItem();
            break;
          }
          e.preventDefault();
          updateEditMode(DataEntryEditMode.INSERT);
          updateFormula("");
          break;
        // COLUMN
        case "-":
        case "–":
          if (metaKey && e.altKey) {
            if (modelLocked) return;
            const selReg = selectedRegionsRef.current[0];
            if (
              selReg.cols[0] === 0 &&
              selReg.cols[1] === currentModule.formattedPeriods.length - 1
            ) {
              deleteLineItem();
            } else if (selReg.rows[0] === 0 && selReg.rows[1] === rowHeaders.length - 1) {
              if (selReg.cols[0] === selReg.cols[1] && selReg.cols[1] === 0) deleteColumn("start");
              else if (
                selReg.cols[0] === selReg.cols[1] &&
                selReg.cols[1] === currentModule.formattedPeriods.length - 1
              )
                deleteColumn("end");
              // else show toast can't delete middle col
            }
          }
          break;
        // TODO - Figure out a suitable ADD_COLUMN shortcut
        // case "+":
        //   console.log(e);
        //   if (metaKey && e.altKey) {
        //     const focusedCell = focusedCellRef.current;
        //     console.log(focusedCell);
        //   }
        //   break;
        // CELL MANIPULATION
        case "c":
          if (metaKey) copy();
          else normalEdit();
          break;
        case "v":
        case "V":
          if (modelLocked) return;
          if (metaKey) paste(e.shiftKey);
          else normalEdit();
          break;
        case "r":
          if (modelLocked) return;
          if (metaKey) {
            e.preventDefault();
            drag("col");
          } else normalEdit();
          break;
        case "d":
          if (modelLocked) return;
          if (metaKey) {
            e.preventDefault();
            drag("row");
          } else normalEdit();
          break;
        case "b":
          if (modelLocked) return;
          if (metaKey) formatAndEditCells({ type: "fontWeight", change: "bold" });
          else normalEdit();
          break;
        case "i":
          if (modelLocked) return;
          if (metaKey) formatAndEditCells({ type: "fontStyle", change: "italic" });
          else normalEdit();
          break;
        case "u":
          if (modelLocked) return;
          if (metaKey) formatAndEditCells({ type: "textDecoration", change: "underline" });
          else normalEdit();
          break;
        case "F2":
          if (modelLocked) return;
          if (focusedCellRef.current.fact.loading) return;
          if (editMode === DataEntryEditMode.NORMAL) {
            updateEditMode(DataEntryEditMode.FORMULA);
          } else if (editMode === DataEntryEditMode.FORMULA)
            updateEditMode(DataEntryEditMode.INSERT);
          else if (editMode === DataEntryEditMode.INSERT) updateEditMode(DataEntryEditMode.FORMULA);
          break;
        case "Backspace":
          if (metaKey) {
            tableRef.current.selectCell({
              rowIdx: focusedCellRef.current.row,
              idx: focusedCellRef.current.col + 1,
            });
          } else {
            if (modelLocked) return;
            const editedFacts = [];
            selectedRegionFactsRef.current.forEach((fact) => {
              const formula = focusedCellRef.current.fact.formula;
              if (formula === "0") return;
              editedFacts.push({
                uid: fact.uid,
                formula: "",
              });
            });
            if (editedFacts.length) editFormula(currentCase.uid, currentModule.uid, editedFacts);
          }
          break;
        /* reintroduce when we have undo/redo again
        case "y":
          if (metaKey && !modelLocked) {
            e.preventDefault();
            if (!availableStateChanges.redo) return;
            redo();
          }
          break;
        case "F4":
          if (modelLocked || !availableStateChanges.redo) return;
          redo();
          break;
        case "z":
          if (metaKey && !modelLocked) {
            e.preventDefault();
            if (!availableStateChanges.undo) return;
            undo();
          }
          break; */
        case "ArrowUp":
        case "ArrowDown":
        case "ArrowLeft":
        case "ArrowRight":
        case "Shift":
        case "Meta":
        case "Ctrl":
        case "Escape":
          break;
        default:
          if (!e.metaKey && !e.altKey && !e.ctrlKey) {
            if (modelLocked) return;
            normalEdit();
          }
          break;
      }
    },
    [
      currentCase,
      copy,
      currentModule,
      deleteColumn,
      deleteLineItem,
      drag,
      editMode,
      editFormula,
      focusedCellRef,
      formatAndEditCells,
      handleAddLineItem,
      hotkeyDialogOpen,
      modelLocked,
      paste,
      rowHeaders,
      selectedRegionsRef,
      selectedRegionFactsRef,
      updateFormula,
      updateEditMode,
    ]
  );

  return (
    <FullSizeDiv className="data-entry" fullHeight>
      <HotkeyDialog isOpen={hotkeyDialogOpen} setHotkeyDialogOpen={setHotkeyDialogOpen} />
      <ModuleDiagram />
      <NewModuleDialog
        isOpen={creatingNewModule}
        handleClose={useCallback((popoverOpen) => {
          if (!popoverOpen) {
            setCreatingNewModule(false);
          }
        }, [])}
      />
      <DeleteModuleDialog
        currentCase={currentCase}
        currentModule={currentModule}
        deleteModule={handleDeleteModule}
        deleting={deletingModule}
        isOpen={deleteModuleDialogOpen}
        lineItems={lineItems}
        moduleList={moduleList}
        modules={modules}
        setOpen={setDeleteModuleDialogOpen}
        theme={theme}
      />
      <Flex justifyContent="flex-start" alignItems="flex-start">
        {activeMenu > 0 ? (
          <SideMenu
            activeMenu={activeMenu}
            appSize={appSize}
            context={EDashboardType.Edit}
            currentModule={currentModule}
            modelContextProps={{
              onCreateModule,
              onDeleteModule,
              modelLocked,
            }}
            treeProps={{
              expandedNodes,
              setExpandedNodes,
              setShowLineItems: setShowLineItemsInTree,
              setShowUntaggedLineItems,
              showLineItems: showLineItemsInTree,
              showUntaggedLineItems,
              updateModuleName,
            }}
          />
        ) : null}
        <Flex justifyContent="flex-start" alignItems="flex-start" flexDirection="column">
          <DataEntryHeader
            autoAdjustColsWidth={autoAdjustColsWidth}
            addColumn={addColumn}
            availableGridChangeOps={availableGridChangeOps}
            availableStateChanges={availableStateChanges}
            handleAddLineItem={handleAddLineItem}
            copy={copy}
            currentCase={currentCase}
            currentModule={currentModule}
            deleteColumn={deleteColumn}
            deleteLineItem={deleteLineItem}
            diagramOpen={diagramOpen}
            editDecimalPlaces={editDecimalPlaces}
            editMode={editMode}
            events={events}
            focusedCell={focusedCell}
            formatAndEditCells={formatAndEditCells}
            formulaConfig={formulaConfig}
            groupBehaviour={groupBehaviour}
            historyData={historyData}
            locked={modelLocked}
            moduleList={moduleList}
            paste={paste}
            rawValues={rawValues}
            ref={dataEntryHeaderRef}
            rowHeaderFocus={rowHeaderFocus}
            setAutoAdjustColsWidth={updateAutoAdjustColsWidth}
            setFormulaBarHeight={setFormulaBarHeight}
            setFactError={setFactError}
            setRawValues={setRawValues}
            size={dashboardSize}
            tableRef={tableRef}
            theme={theme}
          />
          <DataEntryTable
            autoAdjustColsWidth={autoAdjustColsWidth}
            columnHeaders={currentModule.formattedPeriods}
            copy={copy}
            dashboardSize={dashboardSize}
            deleteColumn={deleteColumn}
            deleteLineItem={deleteLineItem}
            drag={drag}
            editDecimalPlaces={editDecimalPlaces}
            formatAndEditCells={formatAndEditCells}
            handleRowsReordered={handleRowsReordered}
            locked={modelLocked}
            onCellInput={onCellInput}
            paste={paste}
            rows={rows}
            tableRef={tableRef}
          />
        </Flex>
      </Flex>
    </FullSizeDiv>
  );
};

function mapStateToProps(state) {
  return {
    availableGridChangeOps: availableGridChangeOpsSelector(state),
    availableStateChanges: availableStateChangeSelector(state),
    clipboardData: state.dataEntry.data.clipboardData,
    currentCase: currentCaseSelector(state),
    currentCaseData: currentCaseDataSelector(state),
    currentModuleUid: currentModuleUidSelector(state),
    currentModule: currentModuleSelector(state),
    dataSources: state.model.datasources,
    diagramOpen: state.ui.global.diagramOpen,
    editMode: state.dataEntry.ui.editMode,
    facts: state.model.facts,
    focusedCellCoords: state.dataEntry.ui.focusedCell,
    historyData: state.history,
    lineItems: state.model.lineItems,
    modelID: modelUidSelector(state),
    modules: caseModulesSelector(state),
    navCellCoords: state.dataEntry.ui.navCellCoords,
    rowHeaderFocus: state.dataEntry.ui.rowHeader,
    selectedRegions: state.dataEntry.ui.selectedRegions,
    selectedRegionFacts: selectedRegionFactsSelector(state),
    tickers: state.create.availableTickers,
    unit: state.ui.global.unit,
  };
}

const mapDispatchToProps = {
  addClipboardItem,
  addColumnAction,
  addLineItem,
  deleteColumnAction,
  deleteLineItemAction,
  deleteModule,
  editFormat,
  editFormula,
  editLineItem,
  editModuleName,
  redoAction,
  reorderModule,
  setNavCellCoords,
  undoAction,
  updateEditMode,
  updateFocusedCell,
  updateFormula,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataEntry);
