import { Intent } from "@blueprintjs/core";
import * as consts from "constants/uiConstants";
import { appStates } from "constants/apiConstants";
import { getMetaKey } from "utils/generic";

export const DEFAULT_STATE = {
  appStatus: {
    intent: Intent.PRIMARY,
    message: appStates.CONNECTING,
    loading: true,
  },
  availableTabs: [],
  currency: "USD",
  currencyFormatOptions: {},
  dateFormatOptions: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  diagramOpen: false,
  loading: false,
  loadingList: 0,
  localeString: "en-US",
  metaKey: getMetaKey(),
  showFullEditHistory: false,
  theme: undefined,
  unit: "Millions",
  userAgent: "",
};

export default function globalUIReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case "UPDATE_THEME":
      return {
        ...state,
        theme: action.payload.theme,
      };
    case consts.UPDATE_APP_STATUS:
      return {
        ...state,
        appStatus: { ...action.payload.nextStatus, previous: state.appStatus },
      };
    case consts.UPDATE_DATE_FORMAT:
      return { ...state, dateFormatOptions: action.payload.format };

    case consts.UPDATE_UNIT:
      return { ...state, unit: action.payload.unit };
    case consts.SET_USER_AGENT: {
      const { userAgent } = action.payload;
      return {
        ...state,
        userAgent: userAgent.includes("Windows") ? "windows" : "Other",
        metaKey: userAgent.includes("Windows") ? "Ctrl" : "Meta (⌘)",
      };
    }
    case consts.GLOBAL_ERROR:
      return { ...state, globalError: true };
    case consts.TOGGLE_DIAGRAM_OPEN:
      return { ...state, diagramOpen: !state.diagramOpen };
    case consts.SET_BLOCKING:
      return {
        ...state,
        blocking: action.payload.msg,
      };
    default:
      return state;
  }
}
