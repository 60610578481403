import React from "react";
import styled, { css, StyledComponent } from "styled-components/macro";
import { BrowserRouter as Router, Link } from "react-router-dom";
import {
  Button,
  Classes,
  Colors,
  Dialog,
  DialogBody,
  Divider,
  FormGroup,
  H3,
  H6,
  HTMLSelect,
  HTMLTable,
  Icon,
  Intent,
  MultistepDialog,
  PanelStack2,
  RadioGroup,
  Spinner,
  Switch,
} from "@blueprintjs/core";
import { MultiSelect, Classes as MultiSelectClasses } from "@blueprintjs/select";
import { Themes } from "constants/uiConstants";
import { TTheme } from "types";
import colors from "styles/colors.module.scss";
import { BLUEPRINT_NAMESPACE } from "constants/appConstants";

type TStyledComponentReturn = StyledComponent<
  keyof JSX.IntrinsicElements | React.FC,
  Record<string, never>
>;

interface IFlexProps {
  alignContent?: string;
  alignItems?: string;
  columnGap?: number;
  flexDirection?: string;
  flex?: string;
  flexBasis?: string;
  flexGrow?: string;
  flexWrap?: string;
  fullHeight?: boolean;
  fullWidth?: boolean;
  gap?: number;
  justifyContent?: string;
  $minWidth?: number;
  rowGap?: number;
  relative?: boolean;
}

/**
 * Provides a generic customizable way to declaratively apply flex styling
 * to an element.
 * @category Styled Components
 * */
export const Flex = styled.div<IFlexProps>`
  position: ${({ relative }) => (relative ? css`relative` : css`unset`)};
  align-content: ${({ alignContent }) => alignContent || css`unset`};
  align-items: ${({ alignItems }) => alignItems || css`center`};
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || css`row`};
  flex: ${({ flex }) => flex || css`inherit`};
  ${({ flexBasis }) =>
    flexBasis &&
    css`
      flex-basis: ${flexBasis};
    `};
  ${({ flexGrow }) =>
    flexGrow &&
    css`
      flex-grow: ${flexGrow};
    `};
  flex-wrap: ${({ flexWrap }) => flexWrap || css`inherit`}; 
  justify-content: ${({ justifyContent }) => justifyContent || css`center`}};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `}
  ${({ columnGap }) =>
    columnGap &&
    css`
      column-gap: ${columnGap}px;
    `}
  ${({ $minWidth }) =>
    $minWidth &&
    css`
      min-width: ${$minWidth}px;
    `}
  ${({ rowGap }) =>
    rowGap &&
    css`
      row-gap: ${rowGap}px;
    `}
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}
`;

interface IGridProps {
  rows?: string;
  cols?: string;
  gap?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
}

/**
 * Like Flex but for grid containers
 * @category Styled Components
 * */
export const Grid = styled.div<IGridProps>`
  display: grid;
  grid-template-rows: ${({ rows }) => rows || css`unset`};
  grid-template-columns: ${({ cols }) => cols || css`unset`};
  gap: ${({ gap }) => gap || css`unset`};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `}
`;

interface IScrollableFlexProps {
  $subtractionHeight: number;
}

export const ScrollFlex = styled(Flex)<IScrollableFlexProps>`
  overflow: auto;
  ${({ $subtractionHeight }) =>
    $subtractionHeight &&
    css`
      height: calc(100% - ${$subtractionHeight}px);
      max-height: calc(100% - ${$subtractionHeight}px);
    `}
`;

export const StyledPanelStack = styled(PanelStack2)`
  display: flex;
  height: 100%;
  min-height: 400px;
  max-height: 750px;
  width: 600px;
`;

interface IVSPageProps {
  $height?: number;
  $width?: number;
}

/**
 * A Valsys page.
 * */
export const VSPage = styled.div<IVSPageProps>`
  height: ${({ $height }) => ($height ? `${$height}px` : css`calc(100% - 40px)`)};
  margin: 20px;
  width: ${({ $width }) => ($width ? `${$width}px` : css`calc(100% - 40px)`)};
  min-width: 800px;
`;

interface IMaybeStickyDivProps {
  $fill?: boolean;
  position?: "top" | "left";
  pinned?: boolean;
  pixels: number;
}

/**
 * Provides a transparent wrapper around a component that allows for pinning a
 * component at the top on the page.
 * @category Styled Components
 * */

export const MaybeStickyDiv = styled.div<IMaybeStickyDivProps>`
  ${({ $fill }) =>
    $fill &&
    css`
      height: 100%;
      width: 100%;
    `}
  ${({ position, pinned, pixels }) => {
    return (
      pinned &&
      css`
        position: sticky;
        ${position || "top"}: ${pixels}px;
        z-index: 6;
      `
    );
  }}
`;

// Small wrapper do display the current app version
export const AppVersion = styled.p`
  font-size: xx-small;
  margin: 0 0 2px 5px;
`;
interface IPaddedContentProps {
  padding: string;
  fullHeight?: boolean;
  fullWidth?: boolean;
}
/**
 * Wraps a component with a padded div
 * @category Styled Components
 * */
export const PaddedContent = styled.div<IPaddedContentProps>`
  padding: ${({ padding }) => padding};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `}
`;

/**
 * A full width divider with no margin or padding
 * @category Styled Components
 **/
export const FullWidthDivider = styled(Divider)`
  margin: 0;
`;

export const NormalAlignDivider = styled(Divider)`
  align-self: normal;
`;
/**
 * A full width divider with margin
 **/
export const FullWithDividerWithMargin = styled(Divider)`
  margin: 10px 0;
`;

/**
 * A full width FormGroup. Children like InputGroup can then use the
 * fill prop to extend to full width
 * @category Styled Components
 * */
export const FullWidthFormGroup = styled(FormGroup)`
  width: 100%;
  & > div {
    width: 100%;
  }
`;

/**
 * Blueprint header comopnents come by default with 10px of
 * margin-bottom. This component takes an optional margin
 * prop that will overrwrite the default margin
 * @category Styled Components
 */
export const noMarginComponent = (
  component: keyof JSX.IntrinsicElements | React.FC
): StyledComponent<typeof component, Record<string, never>> => {
  return styled(component)<{ margin: number }>`
    margin: 0 !important;
  `;
};

/**
 * Generic Icon componenet that can be used inline with headers in a flex context
 * @category Styled Components
 * */
export const HeaderIcon = styled(Icon)`
  margin: 0 7px 15px 0;
  padding: 2px;
`;

/**
 * Generic Spinner component used wherever a HeaderIcon can be used
 * @category Styled Components
 * */
export const HeaderSpinner = styled(Spinner)`
  margin: 0 7px 15px 0;
  padding: 2px;
`;

/**
 * A flexible child component.
 * @category Styled Components
 * */
export const flexChild = (
  component: keyof JSX.IntrinsicElements | React.FC
): TStyledComponentReturn => {
  return styled(component)<{ flex: string }>`
    flex: ${({ flex }) => flex || css`inherit`};
  `;
};

/**
 * An inline-block header for use in labels
 * @category Styled Components
 * */
export const labelHeader = (
  component: keyof JSX.IntrinsicElements | React.FC
): TStyledComponentReturn => styled(component)`
  display: inline-block;
`;

/**
 * Stickinize a component
 * @category Styled Components
 * */
export const stickinize = (
  component: keyof JSX.IntrinsicElements | React.FC
): TStyledComponentReturn => styled(component)<{ top?: number; left?: number }>`
  position: sticky;
  ${({ top }) =>
    (top || top === 0) &&
    css`
      top: ${top}px;
    `}
  ${({ left }) => {
    return (
      (left || left === 0) &&
      css`
        left: ${left}px;
      `
    );
  }}
`;

/**
 * Add horizontal scrolling to widget content
 * @category Styled Components
 * */
export const ScrollableWidgetContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
`;

export const StickyFlexHeader = stickinize(Flex);

/**
 * Header labels for Switch components
 * @category Styled Components
 * */
export const SwitchLabelH6 = labelHeader(H6);
export const SwitchLabelH3 = labelHeader(noMarginComponent(H3));

/**
 * A switch to use inline with Headers
 * @category Styled Components
 * */
export const StyledSwitch = styled(Switch)<{ margin?: number }>`
  display: inline-block;
  margin: ${({ margin }) => margin || css`0 0 10px 0`}};
`;

export const FullWidthSwitch = styled(Switch)`
  margin: 0 !important;
  width: 100%;
`;

interface IFullSizeDivProps {
  calcHeight?: number | string;
  fullHeight?: boolean;
  calcWidth?: number | string;
  fullWidth?: boolean;
}

/**
 * An optionally full size div. Also supports calculated heights and widths
 * @category Styled Components
 * */
export const FullSizeDiv = styled.div<IFullSizeDivProps>`
  height: ${({ fullHeight, calcHeight }) => calcHeight || (fullHeight && css`100%`)};
  width: ${({ fullWidth, calcWidth }) => calcWidth || (fullWidth && css`100%`)};
`;

/**
 * A full height router component. Can accept a height prop for calc etc.
 * @category Styled Components
 * */
export const FullHeightRouter = styled(Router)<{ height?: number }>`
  height: ${({ height }) => height || css`100%`};
`;

// TABLE STUFF

interface IStickyThProps {
  $top?: number;
  $left?: number;
  $scroll: number;
  $scrollStylePixels: number;
  $theme: "dark" | "light";
}
/**
 * Sticky header cell, theme aware
 * @category Styled Components
 */
export const StickyTh = styled.th<IStickyThProps>`
  text-align: ${({ align }) => align || "inherit"};
  position: sticky;

  ${({ $top, $scroll, $scrollStylePixels = 0, $theme }) =>
    typeof $top === "number" &&
    css`
      top: ${$top}px;
      ${$scroll >= $scrollStylePixels &&
      css`
        background-color: ${$theme === Themes.DARK ? colors.darkModuleBackgroundColor : "#fff"};
        border-bottom: 1px solid rgba(16, 22, 26, 0.15);
        border-top: 1px solid rgba(16, 22, 26, 0.15);
        z-index: 10;
      `}
    `}

  ${({ $left, $scroll, $scrollStylePixels = 0, $theme }) =>
    typeof $left === "number" &&
    css`
      left: ${$left}px;
      ${$scroll >= $scrollStylePixels &&
      css`
        background-color: ${$theme === Themes.DARK ? colors.darkAppBackgroundColor : "#fff"};
        border-bottom: 1px solid rgba(16, 22, 26, 0.15);
        border-top: 1px solid rgba(16, 22, 26, 0.15);
        z-index: 10;
      `}
    `}
`;

interface IStickyTr {
  $backgroundType?: "module" | "app";
  $top: number;
  $scrollStylePixels?: number;
  $theme: "dark" | "light";
  $scroll: number;
}

export const StickyTr = styled.tr<IStickyTr>`
  position: sticky;

  ${({ $backgroundType, $top, $scroll, $scrollStylePixels = 1, $theme }) =>
    css`
      top: ${$top}px;
      ${$scroll >= $scrollStylePixels &&
      css`
        background-color: ${$backgroundType === "module"
          ? $theme === Themes.DARK
            ? colors.darkModuleBackgroundColor
            : colors.moduleBackgroundColor
          : $theme === Themes.DARK
          ? colors.darkAppBackgroundColor
          : colors.appBackgroundColor};
        th {
          border-bottom: ${$theme === Themes.DARK
            ? "1px solid rgba(255, 255, 255, 0.15)"
            : "1px solid rgba(16, 22, 26, 0.15)"};
        }
        margin-bottom: 1px;
        z-index: 10;
      `}
    `}
`;

interface IStickyTd {
  align?: string;
  odd: boolean;
  scroll: number;
  theme: "light" | "dark";
}

/**
 * Sticky cell, theme aware
 * @category Styled Components
 */
export const StickyTd = styled.td<IStickyTd>`
  text-align: ${({ align }) => align || "inherit"};
  left: 0;
  position: sticky;
  ${({ scroll, theme, odd }) =>
    scroll > 0 &&
    css`
      background-color: ${theme === Themes.LIGHT
        ? odd
          ? "white !important"
          : colors.lightGray5 + "!important"
        : odd
        ? colors.darkGray4 + "!important"
        : colors.darkGray5 + "!important"};
      border-right: ${theme === Themes.LIGHT
        ? "1px solid rgba(16, 22, 26, 0.15)"
        : "1px solid rgba(255, 255, 255, 0.15)"};
    `}
`;

/**
 * A full with HTMLTable
 * @category Styled Components
 */
export const FullWidthHTMLTable = styled(HTMLTable)`
  width: 100%;
`;

/**
 * A full width dialog. Height is set by the Dialog width.
 * @category Styled Components
 */
export const FullWidthDialog = styled(Dialog)`
  width: calc(100% - 80px);
`;

/**
 * A full width multi step dialog
 */
export const FullWidthMultiStepDialog = styled(MultistepDialog)<{ $showSteps?: boolean }>`
  ${({ $showSteps = false }) =>
    !$showSteps &&
    css`
      .${Classes.MULTISTEP_DIALOG_LEFT_PANEL} {
        display: none;
      }
    `}
  width: calc(100% - 80px);
`;

export const DialogBodyWithHeight = styled(DialogBody)`
  max-height: 80vh;
`;

/**
 * A Dialog Body of fixed height (the same 70vh as the blueprint max height)
 * to allow for child scroll containers, rather than scrolling the whole
 * Dialog body. See CopyWidgetDialog.tsx for example usage.
 * */
export const NonScrollableDialogBody = styled(DialogBody)`
  height: 70vh;
  overflow: hidden;
`;

interface IStyledFormulaBarProps {
  $theme: TTheme;
}

export const StyledFormulaBar = styled(Flex)<IStyledFormulaBarProps>`
  height: 40px;
  background-color: ${({ $theme }) =>
    $theme === Themes.DARK ? colors.darkModuleBackgroundColor : colors.moduleBackgroundColor};
  border-bottom: 1px solid rgb(16 22 26 / 15%);
  padding: 9px 7px;
  width: 100%;
  #text-input {
    font-family: monospace;
  }
`;
export const StyledLink = styled(Link)`
  color: ${colors.blue3};
  &:hover {
    color: ${colors.blue3};
    text-decoration: underline;
  }
`;

export const StyledA = styled.a`
  color: ${colors.blue3};
  &:hover {
    color: ${colors.blue3};
    text-decoration: underline;
  }
`;

export const SideMenuHeader = styled(Flex)`
  height: 30px;
  padding: 0 0 0 10px;
  background-color: ${({ theme }) =>
    theme === Themes.LIGHT ? colors.lightGray5 : colors.darkGray4};
  width: 100%;
  border-bottom: ${({ theme }) =>
    "1px solid " + (theme === Themes.LIGHT ? colors.lightGray3 : colors.darkGray5)};
`;

export const ValsysAppWrapper = styled.div`
  background-color: ${({ theme }) =>
    theme === Themes.LIGHT ? colors.appBackgroundColor : colors.darkAppBackground};
  display: flex;
  height: 100%;
  overflow: hidden;
`;

export const SideMenyHeaderTitle = styled.span`
  font-variant: small-caps;
  font-size: 14px;
  margin-bottom: 0;
`;

export const FullHeightDialogBody = styled(DialogBody)`
  height: 80vh;
  max-height: 80vh;
`;

/**
 * Icon that will set its color based on a parent Intent. Normally, this will happen automatically, however when the spinner is within a parent Icon context it will break bp css
 */
interface IColorableIcon {
  $intent?: Intent;
}
export const ColorableSpinner = styled.span<IColorableIcon>`
  ${Classes.SPINNER} ${Classes.SPINNER_HEAD} {
    stroke: red !important;
  }
`;

/**
 * Stretches to edges of parent (parent must be positioned). Used for creating
 * a fixed-size container to enable scrolling within flex/grid items.
 */

export const AbsFullSize = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

/**
 * Styled scroll bar
 */
interface IStyledScrollBar {
  $theme: TTheme;
  $isSidebar?: boolean;
}

export const styledScrollBar = css<IStyledScrollBar>`
  overflow: auto;

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ $theme, $isSidebar }) =>
      $theme === Themes.LIGHT && !$isSidebar ? Colors.GRAY4 : Colors.DARK_GRAY5};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${({ $theme, $isSidebar }) =>
      $theme === Themes.LIGHT && !$isSidebar ? Colors.GRAY5 : Colors.GRAY1};
  }
  ::-webkit-scrollbar-corner {
    // Needed for the small square in the corner of the scroll container
    background-color: unset;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const StyledScrollContainer = styled.div`
  ${styledScrollBar}
  overflow: auto;
`;

export const StyledCustomMarginRadioGroup = styled(RadioGroup)<{ $marginBottom: number }>`
  .${Classes.RADIO} {
    margin-bottom: ${({ $marginBottom }) => $marginBottom};
  }
`;

/**
 * Wrapper for managing a Collection
 */
export const StyledManageCollection = styled(Grid)<{ $theme: TTheme }>`
  background-color: ${({ $theme }) => ($theme === Themes.LIGHT ? Colors.WHITE : Colors.DARK_GRAY3)};
  border: ${({ $theme }) =>
    $theme === Themes.LIGHT ? `1px solid ${Colors.LIGHT_GRAY3}` : `1px solid ${Colors.DARK_GRAY3}`};
  border-radius: 4px;
  box-shadow: none;
  height: 100%;
  overflow: auto;
  padding: ${({ $theme }) => ($theme === Themes.LIGHT ? "0" : "1px")};
  width: 100%;
`;

export const StyledButtonLeftAligned = styled(Button)`
  justify-content: flex-start;
  .${BLUEPRINT_NAMESPACE}-button-text {
    flex: 1;
  }
`;

export const StyledHTMLSelectSmall = styled(HTMLSelect)`
  select {
    font-size: 13px;
    height: 24px;
    padding: 0 20px 0 10px;
  }
  .${Classes.ICON} {
    right: 4px;
    top: 4px;
  }
`;

export const StyledMultiSelectSmall = styled(MultiSelect)`
  .${MultiSelectClasses.MULTISELECT} {
    min-height: 24px;
    max-width: 900px;

    > .${Classes.TAG_INPUT_ICON} {
      margin-top: 5px;
    }

    .${Classes.TAG_INPUT_VALUES} {
      margin-top: 1px;

      > .${Classes.TAG} {
        margin-bottom: 2px;
        font-size: 10px;
      }
    }
  }
` as unknown as typeof MultiSelect;
