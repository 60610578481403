import React from "react";
import { connect } from "react-redux";

import Explorer, { TNodeType } from "components/model/Explorer";
// Selectors
import { caseModulesSelector, currentModuleSelector } from "selectors/modelSelectors";
//Actions
import { updateCurrentModule } from "actions/dataEntryActions";
import { toggleDiagramOpen } from "actions/globalUIActions";
import { ILineItem, IModule, TVSEntity } from "types/model";
import { IActionReturnType } from "types";
import { IModelContextProps } from "./SideMenu";
import { useNavigate } from "react-router-dom";
import { schemaLevel } from "../../utils/normalizr";
import { vsNavigate } from "../../utils/generic";

interface IModelModuleTreeProps {
  currentModule: IModule;
  diagramOpen: boolean;
  expandedNodes?: string[];
  modelContextProps: IModelContextProps;
  modules: Record<string, IModule>;
  lineItems: Record<string, ILineItem>;
  setExpandedNodes: (nextExpanded: string[]) => void;
  setShowLineItems?: (showLineItems: boolean) => void;
  setShowUntaggedLineItems?: (showUntaggedLineItems: boolean) => void;
  showLineItems?: boolean;
  showUntaggedLineItems?: boolean;
  toggleDiagramOpen: () => void;
  updateCurrentModule: (moduleName: string) => IActionReturnType<string, { moduleName: string }>;
  updateModuleName?: (moduleID: string, moduleName: string) => void;
}

const ModelModuleTree: React.FC<IModelModuleTreeProps> = ({ modules, ...explorerProps }) => {
  const navigate = useNavigate();
  const onNodeClick = (nodeType: TVSEntity, nodeData: TNodeType) => {
    if (nodeType === schemaLevel.MODULE) navigate(vsNavigate(nodeData.name));
    else {
      const li = nodeData as ILineItem;
      const mod = modules[li.parentUid];
      navigate(vsNavigate(mod.name, { row: li.order - 1, col: 0 }));
    }
  };
  return <Explorer modules={modules} onNodeClick={onNodeClick} {...explorerProps} />;
};

// TODO: type state and remove the any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mapStateToProps(state: any) {
  return {
    currentModule: currentModuleSelector(state),
    diagramOpen: state.ui.global.diagramOpen,
    modules: caseModulesSelector(state),
    lineItems: state.model.lineItems,
  };
}

const mapDispatchToProps = {
  toggleDiagramOpen,
  updateCurrentModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelModuleTree);
