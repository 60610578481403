import { EUnit, EValFormat, IFormatObject } from "./format";
import { ESortDirection } from "types";
import { CompletionContext, CompletionResult } from "@codemirror/autocomplete";
import { isArray, isObject } from "lodash";

export type TVSLAutoCompleter = (context: CompletionContext) => Promise<CompletionResult | null>;

export interface VSLBodyContextSelector {
  id: string;
  selected_options: string[];
  type: string;
  source_id?: string;
}

export interface IVSLConfig {
  query: string;
  context?: { selectors: VSLBodyContextSelector[] };
  sortBy?: string;
  sortDirection?: ESortDirection.ASC | ESortDirection.DESC;
  widgetId?: string; // An optional widget id. If supplied, will persist the widget's type to the configurations service.
  widgetName?: string;
  viewId?: string;
  dashboardId?: string;
}

export type ChartWidgetType =
  | EWidgetType2.LINE_CHART
  | EWidgetType2.BAR_CHART
  | EWidgetType2.SCATTER_CHART
  | EWidgetType2.PIE_CHART;

export enum EWidgetType2 {
  "TABLE" = "TABLE",
  "LINE_CHART" = "LINE_CHART",
  "BAR_CHART" = "BAR_CHART",
  "SCATTER_CHART" = "SCATTER_CHART",
  "PIE_CHART" = "PIE_CHART",
}

export enum EButtonType {
  "XAXIS" = "XAXIS",
  "YAXIS" = "YAXIS",
  "OTHER" = "OTHER",
}
export interface VSLResponse {
  widgetType: EWidgetType2;
  title: string;
  data: IVSLTable | VSLChart;
  selectors: Selector[];
}

export enum SelectorContext {
  "WIDGET" = "widget",
  "VIEW" = "view",
}

export interface SelectorTypes {
  selector_types: { [key: string]: string[] };
}

export interface SelectorOption {
  label: string;
  value: string;
}

export interface SelectorRequest {
  available_options?: string[];
  case_only?: boolean;
  filtered_by?: string[];
  id: string;
  kpi?: boolean;
  multiselect?: boolean;
  required: boolean;
  root_tags_only?: boolean;
  selected_options?: string[];
  source_id?: string;
  tracked?: boolean;
  type: string;
}

export interface SelectorOptionsResponse {
  available_options: SelectorOption[];
  error?: string;
}

export interface Selector {
  available_options?: string[]; // locally we store only the value of the SelectorOption for simplicity
  breakline?: boolean;
  case_only?: boolean;
  filtered_by?: string[];
  hidden?: boolean;
  id: string;
  kpi?: boolean;
  multiselect?: boolean;
  name?: string;
  required?: boolean;
  root_tags_only?: boolean;
  selected_options?: SelectorOption[];
  source_id?: string;
  tracked?: boolean;
  type: string;
}

export interface VSLTableValue {
  format: string;
  identifier?: string; // Fact ID if that cell is a fact
  key: string;
  modelID?: string; // Optional modelID for link cells
  value: string;
}

export interface TableRow {
  identifier?: string; // modelID, if present
  cells: VSLTableValue[];
}

export interface IVSLTable {
  columns: string[];
  raw: TableRow[] | null;
  rows: string[][] | null;
  sortBy?: string;
  sortDirection?: ESortDirection;
}

export interface ChartPointLabel {
  rawLabel: string;
  calendarAnnualLabel: string;
  fiscalAnnualLabel: string;
  calendarQuarterlyLabel: string;
  fiscalQuarterlyLabel: string;
  semanticLabel: string;
}

export interface ChartPoint<Df = Date> extends ChartPointLabel {
  value: number | string;
  format?: string;
  dateFormat?: string;
  identifier?: string; // The fact id, if the data point is a Valsys fact.
  rawDate: Df; // The absolute date of the point.
  calendarAnnualDate: Df; // A calendar adjusted annual date, if appropriate.
  fiscalAnnualDate: Df;
  calendarQuarterlyDate: Df;
  fiscalQuarterlyDate: Df;
  seqIdx: number;
  yoYIdx: number;
}

export enum Frequency {
  A = "fiscal_annual",
  Q = "fiscal_quarterly",
  D = "daily",
  W = "weekly",
  M = "monthly",
  CQ = "quarterly", // calendar quarterly
}

export interface ChartDataset<Df = Date> {
  id: string;
  label: string;
  data: ChartPoint<Df>[] | null;
  xdata?: { value: number | string; format?: string }[] | null;
  specifics: {
    // Determines where in a dataset the chart moves from historical (actual) values to forecast (expected)
    crossoverIdx: number;
    item: string;
    frequency: Frequency;
    source: string;
    // The startPeriod of the model, if the response is model data. If the data is not associated with a model,
    // will be set as '0'
    startPeriod: string;
    ticker: string;
    isPercentage: boolean;
  };
  stepped?: boolean | string; // "middle" | "after" | "before" | ""
}

export interface ChartOpts {
  time?: boolean;
}
export interface VSLChart<Df = Date> {
  calendarAnnualLabels: string[] | null;
  calendarQuarterlyLabels: string[] | null;
  datasets: ChartDataset<Df>[] | null;
  fiscalAnnualLabels: string[] | null;
  fiscalQuarterlyLabels: string[] | null;
  labels?: string[];
  metrics?: { label: string; value: string; format: IFormatObject }[] | null;
  opts?: ChartOpts;
  xlabel?: string;
  ylabel?: string;
}

/** Type guard to check if an value is a IVSLChart **/
export const isIVSLChart = (value: unknown): value is VSLChart =>
  isObject(value) && "datasets" in value && isArray(value.datasets);

export interface IChartRendererConfig {
  valFormat?: EValFormat;
  unit?: EUnit;
}
