import {
  AnchorButton,
  Button,
  ButtonGroup,
  Classes,
  Colors,
  Divider,
  Menu,
  MenuItem,
  Popover,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { decreaseDecimalPlaces, increaseDecimalPlaces, ruler, textColor } from "assets/customIcons";
import CustomIcon from "components/utility/CustomIcon";
import { Flex } from "components/utility/StyledComponents";
import isFunction from "lodash.isfunction";
import React, { useContext } from "react";
import { EUnit, EValFormat, IFormatObject } from "types/format";
import { TableWidgetColumnConfig, TableWidgetConfig } from "types/widget";
import { DEFAULT_DECIMAL_PLACES } from "utils/formatter";
import AppContext from "context/appContext";
import styled from "styled-components/macro";
import { TTheme } from "types";
import { Themes } from "constants/uiConstants";

/** custom styled button to have solid background colors and show well on top of text */
const StyledColumnConfigButton = styled(AnchorButton)<{ $theme: TTheme }>`
  background-color: ${({ $theme }) =>
    $theme === Themes.LIGHT ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5} !important;
  &:hover {
    background-color: ${({ $theme }) =>
      $theme === Themes.LIGHT ? Colors.LIGHT_GRAY4 : Colors.DARK_GRAY4} !important;
  }
`;

const TableColumnConfigMenu = ({
  columnConfig,
  configOverrides,
  defaultHeader,
  id,
  setNotesDialogColumn,
  updateConfig,
  visible = true,
}: {
  columnConfig?: TableWidgetColumnConfig;
  configOverrides?: TableWidgetConfig;
  defaultHeader: string;
  id: string;
  setNotesDialogColumn: React.Dispatch<React.SetStateAction<TableWidgetColumnConfig | null>>;
  updateConfig?: (details: Partial<TableWidgetConfig>) => Promise<boolean>;
  visible?: boolean;
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);

  const updateTableColumnConfig = (update: Partial<TableWidgetColumnConfig>) => {
    const nextColumnConfig = {
      header: columnConfig?.header || defaultHeader,
      id,
      ...columnConfig,
      ...update,
    };

    const nextColumns = configOverrides?.columns ? [...configOverrides.columns] : [];
    const columnIndex = nextColumns.findIndex((col) => col.id === id);
    if (columnIndex === -1) nextColumns.push(nextColumnConfig);
    nextColumns.splice(columnIndex, 1, nextColumnConfig);

    if (isFunction(updateConfig)) {
      updateConfig({ columns: nextColumns });
    }
  };

  const updateTableColumnFormat = (update: Partial<IFormatObject>) => {
    updateTableColumnConfig({ format: { ...columnConfig?.format, ...update } });
  };

  return (
    <div
      style={{ visibility: visible ? "visible" : "hidden", position: "absolute", left: "5px" }}
      onClick={(e) => e.stopPropagation()}
    >
      <Popover
        minimal
        content={
          <Menu>
            <MenuItem
              text="Colorize"
              icon={<CustomIcon pathsOrSVG={textColor} iconName="text-color" />}
            >
              <MenuItem
                text="Simple Colorize"
                icon={columnConfig?.colorizeNumerics ? IconNames.TICK : null}
                onClick={() =>
                  updateTableColumnConfig({
                    applyRelativeCellColorGradient: undefined,
                    colorizeNumerics: true,
                  })
                }
              />
              <MenuItem
                text="Color Gradient"
                icon={columnConfig?.applyRelativeCellColorGradient ? IconNames.TICK : null}
                onClick={() =>
                  updateTableColumnConfig({
                    applyRelativeCellColorGradient: true,
                    colorizeNumerics: undefined,
                  })
                }
              />
              <MenuItem
                text="None"
                icon={
                  !columnConfig?.colorizeNumerics && !columnConfig?.applyRelativeCellColorGradient
                    ? IconNames.TICK
                    : null
                }
                onClick={() =>
                  updateTableColumnConfig({
                    applyRelativeCellColorGradient: undefined,
                    colorizeNumerics: undefined,
                  })
                }
              />
            </MenuItem>
            <MenuItem text="Value Format" icon={IconNames.NUMERICAL}>
              <MenuItem
                text="Default"
                icon={!columnConfig?.format?.valFormat ? IconNames.TICK : null}
                onClick={() => updateTableColumnFormat({ valFormat: undefined })}
              />
              {Object.values<EValFormat>(EValFormat).map((valFormat) => {
                return (
                  <MenuItem
                    icon={columnConfig?.format?.valFormat === valFormat ? IconNames.TICK : null}
                    key={valFormat}
                    onClick={() => updateTableColumnFormat({ valFormat })}
                    text={valFormat}
                  />
                );
              })}
            </MenuItem>
            <MenuItem
              text="Value Unit"
              icon={<CustomIcon pathsOrSVG={ruler} customViewBox="0 0 24 24" iconName="ruler" />}
            >
              <MenuItem
                text="Default"
                icon={!columnConfig?.format?.unit ? IconNames.TICK : null}
                onClick={() => updateTableColumnFormat({ unit: undefined })}
              />
              {Object.values<EUnit>(EUnit).map((unit) => {
                return (
                  <MenuItem
                    icon={columnConfig?.format?.unit === unit ? IconNames.TICK : null}
                    key={unit}
                    onClick={() => updateTableColumnFormat({ unit })}
                    text={unit}
                  />
                );
              })}
            </MenuItem>
            <MenuItem
              icon={
                <CustomIcon iconName="increase-decimal-places" pathsOrSVG={increaseDecimalPlaces} />
              }
              text="Decimal Places"
            >
              <MenuItem
                icon={columnConfig?.format?.decimalPlaces === undefined ? IconNames.TICK : null}
                onClick={() => updateTableColumnFormat({ decimalPlaces: undefined })}
                text="Default"
              />
              <Flex
                className={
                  Number.isNaN(columnConfig?.format?.decimalPlaces) ? Classes.TEXT_MUTED : undefined
                }
                justifyContent="space-between"
                style={{ padding: "5px 7px" }}
              >
                Custom:&nbsp;
                <ButtonGroup>
                  <Button
                    disabled={
                      columnConfig?.format?.decimalPlaces != undefined &&
                      columnConfig?.format?.decimalPlaces - 1 < 0
                    }
                    icon={
                      <CustomIcon
                        iconName="decrease-decimal-places"
                        pathsOrSVG={decreaseDecimalPlaces}
                      />
                    }
                    minimal
                    onClick={() => {
                      const decimalPlaces = columnConfig?.format?.decimalPlaces;
                      if (decimalPlaces != undefined) {
                        if (decimalPlaces - 1 > -1) {
                          updateTableColumnFormat({ decimalPlaces: decimalPlaces - 1 });
                        }
                      } else {
                        updateTableColumnFormat({ decimalPlaces: DEFAULT_DECIMAL_PLACES - 1 });
                      }
                    }}
                  />
                  <Button
                    icon={
                      <CustomIcon
                        iconName="increase-decimal-places"
                        pathsOrSVG={increaseDecimalPlaces}
                      />
                    }
                    minimal
                    onClick={() => {
                      const decimalPlaces = columnConfig?.format?.decimalPlaces;
                      if (decimalPlaces != undefined) {
                        updateTableColumnFormat({ decimalPlaces: decimalPlaces + 1 });
                      } else {
                        updateTableColumnFormat({ decimalPlaces: DEFAULT_DECIMAL_PLACES + 1 });
                      }
                    }}
                  />
                </ButtonGroup>
              </Flex>
            </MenuItem>
            <Divider />
            <MenuItem
              icon={IconNames.EYE_OFF}
              onClick={() => {
                updateTableColumnConfig({ hidden: true });
              }}
              text={"Hide column"}
            />
            <MenuItem
              icon={IconNames.EDIT}
              onClick={() => {
                setNotesDialogColumn(columnConfig || { id, header: id });
              }}
              text={columnConfig?.notes ? "Edit notes" : "Add notes"}
            />
          </Menu>
        }
        placement="bottom"
      >
        <StyledColumnConfigButton icon={IconNames.COG} minimal small $theme={theme} />
      </Popover>
    </div>
  );
};

export default TableColumnConfigMenu;
