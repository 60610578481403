import { IconName } from "@blueprintjs/icons";

import { IModelInfo } from "types/model";
import { IModelHistoryTable, TModelHistoryColumnDef } from "types/modelHistory";
import { InitialFilterState } from "components/table/ModelFilters";
import { TableWidgetColumnConfig } from "types/widget";

export const ModelHistoryPageSize = 200;

export const customColumnObjects: { [key: string]: TModelHistoryColumnDef } = {
  rowIndex: {
    enableSorting: false,
    enableResizing: false,
    id: "rowIndex",
    header: "",
    maxSize: 50,
    minSize: 50,
    meta: {
      colorizeNumerics: undefined,
      tdStyle: {
        textAlign: "center",
        verticalAlign: "middle",
      },
    },
    size: 50,
  },
  selectModel: {
    enableSorting: false,
    enableResizing: false,
    id: "selectModel",
    header: "",
    maxSize: 50,
    meta: {
      colorizeNumerics: undefined,
      tdStyle: {
        textAlign: "center",
        verticalAlign: "middle",
      },
    },
    minSize: 50,
    size: 50,
  },
  permissions: {
    id: "permissions",
    header: "Permissions",
    meta: {
      type: "permissions",
    },
  },
  createdAt: {
    id: "createdAt",
    header: "Created At",
    meta: {
      type: "date",
    },
  },
  "Last Updated": {
    id: "Last Updated",
    header: "Last Updated",
    meta: {
      type: "date",
    },
  },
  tags: {
    accessorKey: "tags",
    header: "Model Tags",
    id: "tags",
    meta: { colorizeNumerics: undefined },
    sortingFn: (a, b) => {
      const aTags = a.getValue<string[]>("tags");
      const bTags = b.getValue<string[]>("tags");
      if (aTags && !bTags) return 1;
      if (bTags && !aTags) return -1;
      if (aTags && bTags) return aTags.length - bTags.length;
      return 0;
    },
    size: 200,
    minSize: 200,
  },
};

export const DefaultCols2: TableWidgetColumnConfig[] = [
  {
    id: "companyName",
    header: "Company Name",
    width: 200,
  },
  {
    id: "ticker",
    header: "Ticker",
  },
  {
    id: "geography",
    header: "Geography",
    width: 200,
  },
  {
    id: "industry",
    header: "Industry",
    width: 200,
  },
  {
    id: "createdAt",
    header: "Created At",
    meta: {
      type: "date",
    },
  },
  {
    id: "tags",
    header: "Model Tags",
    width: 200,
  },
];

export enum EModelTables {
  userModels = "my-models",
  sharedModels = "shared-models",
}

export const DefaultModelTable: IModelHistoryTable = {
  columns: DefaultCols2,
  enableSelection: true,
  displayRowIndex: false,
  filters: InitialFilterState,
  frozenColumns: 1,
  modelType: "User",
  name: "",
  uid: "",
};

export const DefaultModelTables: IModelHistoryTable[] = [
  {
    ...DefaultModelTable,
    uid: EModelTables.userModels,
    name: "My Models",
    modelType: "User",
  },
  {
    ...DefaultModelTable,
    uid: EModelTables.sharedModels,
    name: "Shared Models",
    modelType: "Shared",
  },
];

/**
 * A list of defined IModelInfo keys for use when working with model history objects, for example
 * when looking up custom rendering methods
 */
export const ModelInfoKeys: { [key: string]: keyof IModelInfo } = {
  companyName: "companyName",
  createdAt: "createdAt",
  currency: "currency",
  dataSources: "dataSources",
  geography: "geography",
  historicalPeriod: "historicalPeriod",
  id: "id",
  industry: "industry",
  iterations: "iterations",
  lastEdit: "lastEdit",
  periodType: "periodType",
  permissions: "permissions",
  precision: "precision",
  rollForward: "rollForward",
  styling: "styling",
  tags: "tags",
  templateID: "templateID",
  ticker: "ticker",
  title: "title",
  updates: "updates",
};

export const AccessIndicators: { [key: string]: { label: string; icon: IconName } } = {
  noAccess: {
    label: "No access",
    icon: "eye-off",
  },
  owner: {
    label: "Owner (view, edit, share and delete)",
    icon: "user",
  },
  view: {
    label: "View",
    icon: "eye-open",
  },
  edit: {
    label: "Edit (view, edit)",
    icon: "edit",
  },
  fullAccess: {
    label: "Full access (view, edit, share and delete)",
    icon: "globe",
  },
};
