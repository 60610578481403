import * as Sentry from "@sentry/react";
import { call, put } from "redux-saga/effects";

import { HISTORICAL_SHARE_PRICE_ROUTE, EMethods } from "constants/apiConstants";
import { api } from "utils/api";
import {
  fetchHistoricalSharePriceDataSuccess,
  fetchHistoricalSharePriceDataFailure,
} from "actions/sharePriceActions";

export function* fetchHistoricalSharePriceData(action) {
  try {
    const { ticker } = action.payload;

    const res = yield call(api, HISTORICAL_SHARE_PRICE_ROUTE, {
      method: EMethods.GET,
      Ticker: ticker,
      Range: "full" /*req needs this header but doesn't use it, always returns full range*/,
    });

    if (res.status !== "success") {
      yield put(fetchHistoricalSharePriceDataFailure(ticker));
    } else {
      yield put(fetchHistoricalSharePriceDataSuccess(res.data, "full", ticker));
    }
  } catch (err) {
    yield put(fetchHistoricalSharePriceDataFailure());
    // eslint-disable-next-line
    console.log(err);
    Sentry.captureException(err);
  }
}
