// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
import {matchFactIdentifier} from "./tokens"
const spec_word = {__proto__:null,var:104, TRUE:114, FALSE:116, func:128}
export const parser = LRParser.deserialize({
  version: 14,
  states: ",YQVQQOOOOQO'#C`'#C`O_QQO'#C_OOQO'#DU'#DUOdQQO'#DTOiQQO'#DSOOQO'#C^'#C^OOQO'#DW'#DWQVQQOOOOQO'#Ca'#CaOwQQO,58yO|QQO,59oO!qQQO'#D[O!vQQO,59nOOQO-E7U-E7UO#UQQO1G.eOOQO'#Cc'#CcOOQO'#Cd'#CdO#vQRO'#CeO#UQQO'#CjO$lQSO'#DjO#UQQO'#CtO%kQSO'#DcOOQO'#Dj'#DjO%rQQO'#DiOOQO'#C}'#C}O%zQQO'#C|OOQO'#Dc'#DcO&PQQO1G/ZOOQO'#Ci'#CiOOQO,59v,59vOOQO-E7Y-E7YO&UQSO7+$PO&]QSO'#DcO&jQSO'#DfO&tQQO,59POOQO'#Cz'#CzO&yQQO,59eOOQO,59U,59UO#UQQO,59XO#UQQO,59XO#UQQO,59XO#UQQO,59XO#UQQO,59XO'OQSO,59`O#UQQO,59dO|QQO,59cO#UQQO'#DYO'VQQO,5:TO'_QQO,59hOOQO7+$u7+$uOOQO<<Gk<<GkO#UQQO'#DXO'gQQO,5:QOOQO1G.k1G.kO'oQSO1G/POOQO1G.s1G.sO(fQSO1G.sO(sQSO1G.sO)xQSO1G.sO*PQSO1G.sOOQO1G.z1G.zO*WQSO1G/OO*bQQO1G.}OOQO,59t,59tOOQO-E7W-E7WOOQO'#DO'#DOO*gQQO'#DoO*oQQO1G/SO*tQSO,59sOOQO-E7V-E7VO+OQWO'#C{O+WQQO7+$kOOQO7+$i7+$iO+]QQO'#DZO+bQQO,5:ZO+jQQO7+$nO+oQSO,59gO+tQQO<<HVOOQO,59u,59uOOQO-E7X-E7XO#UQQO<<HYOOQO1G/R1G/ROOQOAN=qAN=qO+yQSOAN=tOOQOG23`G23`",
  stateData: ",Q~O!SOS~O!TRO!UPO~O!TXO~OiZO~Oy[O!PvX!TvX!UvX~OU_O~OYbO_cO`cOieO!TXO!W`O!XaO!ZmO![mO!biOj!]P~O!TRO~Oy[O!Pva!Tva!Uva~OYbO_cO`cOieO!TXO!W`O!XaO!ZmO![mO!biO~O!QtO[!YP~P#UO_yO`yObwOcxOdxOexOfzOg{O~OZ!^Xj!^X~P$QOi!OOZ!VX_!VX`!VXb!VXc!VXd!VXe!VXf!VXg!VXj!VX~OU}O~P$vOZ!POj!]X~Oi!RO~Oj!SO~Ou!TO~P$QOu!VX[!VXt!VX~P$vOZ!UO[!YX~P$QO[!WO~OY!XO~Oj!_O~P$QOZ!POj!]a~O!T!dOj!cP~OZ!UO[!Ya~O!_!iO~ObwOZai_ai`aifaigaijaiuai[aitai~Ocaidaieai~P'tOcxOdxOexO~P'tO_yO`yObwOcxOdxOexOZaigaijaiuai[aitai~Ofai~P)QOfzO~P)QOZlijli~P$QOj!kO~OZ!lOj!cX~Oj!nO~OZ{a[{a~P$QO!`!oO[oX~O[!pO~O!T!dO~OZ!lOj!ca~Os!sO~O!a!tO~O[!uO~Ot!wO~P$QO",
  goto: "&h!dPP!e!i!m!qP#X#X#XPPP#X#XPP#XPPPPPP#XPP#X#j#X#o#r#X#u$WPPP!i$^$eP$j$p$v$|%SPPPPPP%YPP&TPP&W&^PPPP&eTVOWTUOWTQOWQYQUfZ!O!Piq_bcewxyz{}!U!sokZ_bcewxyz{}!O!P!U!sVgZ!O!PRubR!j!XojZ_bcewxyz{}!O!P!U!sQ!e!RR!q!lSTOWRn[VSOW[QWOR^WQ!VrR!h!VQ!QhR!c!QQ!m!eR!r!mQ]TRo]UdZ!O!PQp_QrbQvcQ|eQ!YwQ!ZxQ![yQ!]zQ!^{Q!`}Q!g!UR!v!sRsbQlZR!a!OShZ!OR!b!PR!f!R",
  nodeNames: "⚠ Program Statement VariableDeclaration Var Identifier Equals String Number Array Bracket Punctuation Bracket Boolean UnaryExpression ArithOp ArithOp BinaryExpression ArithOp ArithOp ArithOp ArithOp LogicOp LogicOp ParenthesizedExpression Bracket Bracket FunctionCall NamedArg FactRef FactIdentifier FactPeriod Function Func FunctionParam Bracket Bracket Punctuation DirectiveChain Directive DirectiveName Dot",
  maxTerm: 65,
  skippedNodes: [0],
  repeatNodeCount: 5,
  tokenData: "-u~RuX^#fpq#frs#kuv&Txy&Yyz&_z{&d{|&i|}&p}!O&u!O!P&|!P!Q'R!Q!R'W!R![(h!]!^({!_!`)Q!c!d)V!d!n)n!n!o*{!o!q)n!q!r,`!r!})n!}#O-[#P#Q-a#Q#R-f#R#S)n#T#o)n#o#p-k#q#r-p#y#z#f$f$g#f#BY#BZ#f$IS$I_#f$I|$JO#f$JT$JU#f$KV$KW#f&FU&FV#f~#kO!S~~#nWpq#kqr#krs$Ws#O#k#O#P$]#P;'S#k;'S;=`%}<%lO#k~$]O!W~~$`Xrs#k!P!Q#k#O#P#k#U#V#k#Y#Z#k#b#c#k#f#g#k#h#i#k#i#j${~%OR!Q![%X!c!i%X#T#Z%X~%[R!Q![%e!c!i%e#T#Z%e~%hR!Q![%q!c!i%q#T#Z%q~%tR!Q![#k!c!i#k#T#Z#k~&QP;=`<%l#k~&YOe~~&_Oi~~&dOj~~&iOc~V&pO`R!`S~&uOZ~V&|O_R!`S~'ROy~~'WOd~R'_R!aQ!XP!O!P'h!g!h'|#X#Y'|P'kP!Q!['nP'sR!XP!Q!['n!g!h'|#X#Y'|P(PR{|(Y}!O(Y!Q![(`P(]P!Q![(`P(eP!XP!Q![(`R(oS!aQ!XP!O!P'h!Q![(h!g!h'|#X#Y'|~)QOu~~)VOU~R)[U!TP!Q![)n!c!p)n!p!q*P!q!})n#R#S)n#T#o)nP)sS!TP!Q![)n!c!})n#R#S)n#T#o)nR*UU!TP!Q![)n!c!f)n!f!g*h!g!})n#R#S)n#T#o)nR*oSfQ!TP!Q![)n!c!})n#R#S)n#T#o)nR+QU!TP!Q![)n!c!h)n!h!i+d!i!})n#R#S)n#T#o)nR+iU!TP!Q![)n!c!{)n!{!|+{!|!})n#R#S)n#T#o)nR,SS!_Q!TP!Q![)n!c!})n#R#S)n#T#o)nR,eU!TP!Q![)n!c!t)n!t!u,w!u!})n#R#S)n#T#o)nR-OSgQ!TP!Q![)n!c!})n#R#S)n#T#o)n~-aOY~~-fO[~~-kOb~~-pOs~~-uOt~",
  tokenizers: [matchFactIdentifier, 0, 1, 2],
  topRules: {"Program":[0,1]},
  specialized: [{term: 51, get: value => spec_word[value] || -1}],
  tokenPrec: 0
})
