import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Classes,
  Colors,
  Divider,
  Intent,
  Menu,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  PopoverPosition,
  Text,
} from "@blueprintjs/core";
import styled, { css } from "styled-components";
import useSWR from "swr";
import { AppVersion, Flex, styledScrollBar } from "components/utility/StyledComponents";
import useUser from "hooks/useUser";
import {
  ElevationBoxShadows,
  MAIN_SIDE_MENU_COLLAPSED_WIDTH,
  MAIN_SIDE_MENU_WIDTH,
  Themes,
} from "constants/uiConstants";
import ValsysLogo from "./ValsysLogo";
import AppContext from "context/appContext";
import { IModelHistoryTable } from "types/modelHistory";
import { IconNames } from "@blueprintjs/icons";
import { MenuButton, MenuButtonItemProps } from "./MenuButton";
import { IResponse, TTheme } from "types";
import useListConfigs from "hooks/useListConfigs";
import { swrApi } from "utils/api";
import { FETCH_PORTFOLIOS_ROUTE, FETCH_WATCHLISTS_CONFIG_ROUTE } from "constants/apiConstants";
import { Dashboard } from "types/dashboard";
import { Portfolio } from "types/collections";

interface IStyledSideBarItemProps {
  $collapsed: boolean;
  $theme: TTheme;
  $isSidebar: boolean;
}

interface IStyledCollapsedProps {
  $collapsed: boolean;
}

const StyledSideBar = styled.div<IStyledSideBarItemProps>`
  ${({ $collapsed }) => !$collapsed && "margin-top: 55px;"}
  position: relative;
  align-items: flex-start;
  color: ${Colors.WHITE};
  background-color: ${Colors.DARK_GRAY3};
  border: none;
  border-right: 1px solid ${Colors.DARK_GRAY5};
  border-radius: 0;
  box-shadow: inset ${ElevationBoxShadows.ELEVATION_0};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 340px;
  padding: 20px 6px 10px 10px;
  li {
    list-style-type: none;
  }
  justify-content: space-between;
  width: ${({ $collapsed }) =>
    $collapsed ? `${MAIN_SIDE_MENU_COLLAPSED_WIDTH}px` : `${MAIN_SIDE_MENU_WIDTH}px`};
  z-index: 10;
  .navigation-divider {
    margin: 20px 0;
    border-color: ${Colors.DARK_GRAY5};
  }

  ${({ $collapsed }) => !$collapsed && styledScrollBar}
  ${({ $collapsed }) => !$collapsed && "overflow-y: scroll;"}
`;

const StyledCreateNewButton = styled(Button)`
  &&& {
    font-size: 12px;
    padding: 0 6px;
    border-radius: 3px;
  }
`;

const StyledLogo = styled.div<IStyledSideBarItemProps>`
  ${({ $collapsed }) =>
    !$collapsed &&
    css`
      position: fixed;
      width: ${MAIN_SIDE_MENU_WIDTH}px;
      background-color: ${Colors.DARK_GRAY3};
      left: 0;
      top: 0;
      padding: 20px 6px 0 10px;
    `}
`;

const StyledCollapsedButton = styled(Button)<IStyledCollapsedProps>`
  &&& {
    position: absolute;
    top: 38px;
    right: -10px;
    background-color: ${Colors.DARK_GRAY5};
    border-radius: 50%;
    border: 1px solid ${Colors.GRAY3};
    min-height: 20px;
    min-width: 20px;
    padding: 0;
    z-index: 1000;

    svg {
      fill: ${Colors.WHITE};
    }
  }
`;

interface IMainSideBarProps {
  collapsed: boolean;
  modelTableID: string;
  modelTables: IModelHistoryTable[] | null;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}

const MainSideBar: React.FC<IMainSideBarProps> = ({ collapsed, modelTables, setCollapsed }) => {
  const { user, error: userServiceError } = useUser();
  const { dashboards, isLoading: loadingDashboards } = useListConfigs();

  const { data: watchlistConfigs, isLoading: loadingWatchlists } = useSWR<
    IResponse<{ name: string; id: string; dashboard: Dashboard }[]>
  >([FETCH_WATCHLISTS_CONFIG_ROUTE], swrApi);

  const { data: portfolios, isLoading: loadingPortfolios } = useSWR<IResponse<Portfolio[]>>(
    [FETCH_PORTFOLIOS_ROUTE],
    swrApi
  );

  const { setLogoutDialogOpen, toggleTheme } = useContext(AppContext);
  const [selectedMenu, setSelectedMenu] = useState("");

  const {
    config: { theme },
  } = useContext(AppContext);

  const handleSelectedMenu = (id: string) => {
    setSelectedMenu(id);
  };

  const portfoliosItems: MenuButtonItemProps[] = portfolios?.data
    ? [
        {
          icon: IconNames.GRID_VIEW,
          text: "Manage",
          to: "/portfolios/shared",
          uid: "Manage",
          intent: Intent.PRIMARY,
        },
        ...portfolios.data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((portfolio) => ({
            icon: undefined,
            uid: portfolio.id,
            text: portfolio.name,
            to: `/portfolios/shared/${portfolio.id}/${portfolio.dashboardId}`,
          })),
      ]
    : [];

  const watchlistItems: MenuButtonItemProps[] = watchlistConfigs?.data
    ? [
        {
          icon: IconNames.GRID_VIEW,
          text: "Manage",
          to: "/watchlists/shared",
          uid: "Manage",
          intent: Intent.PRIMARY,
        },
        ...watchlistConfigs.data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((watchlistConfig) => ({
            icon: undefined,
            uid: watchlistConfig.dashboard.id,
            text: watchlistConfig.name,
            to: `/watchlists/shared/${watchlistConfig.id}/${watchlistConfig.dashboard.id}`,
          })),
      ]
    : [];

  const dashboardItems: MenuButtonItemProps[] = dashboards
    ? [
        {
          icon: IconNames.GRID_VIEW,
          uid: "Manage",
          text: "Manage",
          to: "/dashboards",
          intent: Intent.PRIMARY,
        },
        ...dashboards
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((db) => ({
            icon: undefined,
            uid: db.id,
            text: db.name,
            to: `/dashboards/${db.id}`,
          })),
      ]
    : [];

  return (
    <StyledSideBar $collapsed={collapsed} $theme={theme} $isSidebar={true}>
      <StyledLogo $collapsed={collapsed} $theme={theme} $isSidebar={true}>
        <Flex flexDirection="column" alignItems="left" fullWidth>
          <Link to="/">
            <div style={{ marginBottom: "15px" }}>
              {collapsed ? (
                <ValsysLogo initials size={{ width: "30px", height: "20px" }} theme={null} />
              ) : (
                <ValsysLogo theme={null} size={{ width: "112px", height: "20px" }} />
              )}
            </div>
          </Link>
          <StyledCollapsedButton
            icon={collapsed ? IconNames.CHEVRON_RIGHT : IconNames.CHEVRON_LEFT}
            minimal
            onClick={() => setCollapsed(!collapsed)}
            $collapsed={collapsed}
          />
        </Flex>
      </StyledLogo>
      <Flex
        flexDirection="column"
        alignItems="left"
        fullWidth
        style={{ marginTop: collapsed ? 1 : 0, marginBottom: "auto" }}
      >
        {!userServiceError ? (
          <>
            <MenuButton
              icon={IconNames.HOME}
              text={"Home" || "Loading..."}
              to="/"
              collapsed={collapsed}
              selected={selectedMenu === "Home"}
              handleSelectedMenu={handleSelectedMenu}
              style={{ marginTop: 0 }}
            />

            <MenuButton
              icon={IconNames.LAYOUT_AUTO}
              text="Analytics"
              collapsed={collapsed}
              selected={selectedMenu === "Analytics"}
              handleSelectedMenu={handleSelectedMenu}
              menuItems={[
                {
                  icon: IconNames.TRENDING_UP,
                  text: "KPI Trends",
                  to: "/kpi-trends",
                  uid: "KPI-Trends",
                },
                {
                  icon: IconNames.LAYOUT_AUTO,
                  text: "Scenario Analytics",
                  to: "/scenario-analytics",
                  uid: "Scenario Analytics",
                },
              ]}
            />
            <Divider className="navigation-divider" />
            <Flex justifyContent="space-between">
              {!collapsed && <Text className="navigation-section">SHARED</Text>}
              <Popover
                fill
                interactionKind={PopoverInteractionKind.HOVER}
                placement={PopoverPosition.RIGHT}
                shouldReturnFocusOnClose={false}
                content={
                  <Menu>
                    <Link to="/watchlists/shared?openCreateDialog=1">
                      <MenuItem icon={IconNames.EYE_ON} tagName="span" text="New Watchlist" />
                    </Link>
                    <Link to="/dashboards?openCreateDialog=1">
                      <MenuItem icon={IconNames.DASHBOARD} tagName="span" text="New Dashboard" />
                    </Link>
                  </Menu>
                }
              >
                <StyledCreateNewButton
                  intent={Intent.SUCCESS}
                  rightIcon={IconNames.PLUS}
                  text={collapsed ? "" : "New"}
                />
              </Popover>
            </Flex>
            <MenuButton
              collapsed={collapsed}
              icon={IconNames.DASHBOARD}
              loading={loadingDashboards}
              menuItems={dashboardItems}
              text="Dashboards"
              selected={selectedMenu === "Dashboards"}
              handleSelectedMenu={handleSelectedMenu}
            />

            <MenuButton
              collapsed={collapsed}
              icon={IconNames.EYE_ON}
              loading={loadingWatchlists}
              menuItems={watchlistItems}
              text="Watchlists"
              selected={selectedMenu === "Watchlists"}
              handleSelectedMenu={handleSelectedMenu}
            />

            <MenuButton
              icon={IconNames.MULTI_SELECT}
              text="Portfolios"
              collapsed={collapsed}
              selected={selectedMenu === "Portfolios"}
              handleSelectedMenu={handleSelectedMenu}
              loading={loadingPortfolios}
              menuItems={portfoliosItems}
            />
          </>
        ) : null}
        {user ? (
          <>
            <Divider className="navigation-divider" />
            <Flex justifyContent="space-between">
              {!collapsed && <Text className="navigation-section">USER</Text>}
              <Link to={"/"}>
                <StyledCreateNewButton
                  disabled={true}
                  intent={Intent.SUCCESS}
                  rightIcon={IconNames.PLUS}
                  text={collapsed ? "" : "New"}
                />
              </Link>
            </Flex>
            <MenuButton
              icon={IconNames.DASHBOARD}
              text="My Dashboards"
              collapsed={collapsed}
              to="/my-dashboards/user"
              disabled={true}
              selected={selectedMenu === "My Dashboards"}
              handleSelectedMenu={handleSelectedMenu}
            />

            <MenuButton
              icon={IconNames.EYE_ON}
              text="My Watchlists"
              collapsed={collapsed}
              to="/my-watchlists/user"
              disabled={true}
              selected={selectedMenu === "My Watchlists"}
              handleSelectedMenu={handleSelectedMenu}
            />

            <Divider className="navigation-divider" />

            <MenuButton
              icon={IconNames.TH}
              text="Models"
              collapsed={collapsed}
              to="/models/shared-models"
              selected={selectedMenu === "Models"}
              handleSelectedMenu={handleSelectedMenu}
              // menuItems={
              //   Array.isArray(modelTables)
              //     ? modelTables.map((table) => ({
              //         uid: table.uid,
              //         text: table.name,
              //         to: `/models/${table.uid}`,
              //         icon: getModelTableInfo(table),
              //       }))
              //     : undefined
              // }
              disabled={!modelTables}
            />
          </>
        ) : null}
      </Flex>
      <Flex flexDirection="column" alignItems="left" fullWidth>
        <MenuButton
          icon={theme === Themes.DARK ? IconNames.MOON : IconNames.FLASH}
          text="Toggle Theme"
          collapsed={collapsed}
          onClick={toggleTheme}
          selected={selectedMenu === "Toggle Theme"}
          handleSelectedMenu={handleSelectedMenu}
        />
        <MenuButton
          icon={IconNames.COG}
          text="Settings"
          collapsed={collapsed}
          to="/settings/profile"
          selected={selectedMenu === "Settings"}
          handleSelectedMenu={handleSelectedMenu}
        />
        <MenuButton
          icon={IconNames.LOG_OUT}
          text="Logout"
          collapsed={collapsed}
          onClick={() => setLogoutDialogOpen(true)}
          selected={selectedMenu === "Logout"}
          handleSelectedMenu={handleSelectedMenu}
        />

        <Button disabled minimal small style={{ marginTop: 15 }}>
          <AppVersion className={Classes.TEXT_MUTED}>{`${
            window.__env__.REACT_APP_RELEASE_PREFIX
              ? window.__env__.REACT_APP_RELEASE_PREFIX
              : "vs-dev"
          }-${process.env.REACT_APP_VERSION}`}</AppVersion>
        </Button>
      </Flex>
    </StyledSideBar>
  );
};

export default MainSideBar;
