import type Highcharts from "highcharts/highstock";

export default function (H: typeof Highcharts) {
  H.SVGRenderer.prototype.symbols["navigator-handle"] = function (
    x: number,
    y: number,
    _w: number,
    _h: number
  ) {
    const r = 2.5, // corner radius of the rectangle
      h = _h + 4, // increase the default height
      w = _w + 4, // increase the default width
      dotRadius = 0.5;

    // Parameters for the dot paths - these determine dots distance.
    const dx = w / 3,
      dotLeft = x + dx - dotRadius + 0.4,
      dotRight = x + w - dx - dotRadius - 0.4,
      dy = dotRight - dotLeft;

    let dotY = y + (h - 3 * dy) / 2;

    // Dot paths
    const dots = Array.from({ length: 4 }, () => {
      const dotPath = [
        "M",
        dotLeft,
        dotY,
        "a",
        dotRadius,
        dotRadius,
        0,
        1,
        0,
        dotRadius * 2,
        0,
        "a",
        dotRadius,
        dotRadius,
        0,
        1,
        0,
        -dotRadius * 2,
        0,
        "M",
        dotRight,
        dotY,
        "a",
        dotRadius,
        dotRadius,
        0,
        1,
        0,
        dotRadius * 2,
        0,
        "a",
        dotRadius,
        dotRadius,
        0,
        1,
        0,
        -dotRadius * 2,
        0,
      ];

      dotY += dy;

      return dotPath;
    });

    // Rounded rectangle path
    const rect = [
      "M",
      x + r,
      y,
      "L",
      x + w - r,
      y,
      "A",
      r,
      r,
      0,
      0,
      1,
      x + w,
      y + r,
      "L",
      x + w,
      y + h - r,
      "A",
      r,
      r,
      0,
      0,
      1,
      x + w - r,
      y + h,
      "L",
      x + r,
      y + h,
      "A",
      r,
      r,
      0,
      0,
      1,
      x,
      y + h - r,
      "L",
      x,
      y + r,
      "A",
      r,
      r,
      0,
      0,
      1,
      x + r,
      y,
      "Z",
    ];

    // Returns navigator handles resembling the
    // DRAG-HANDLE-VERTICAL from BlueprintJS Icons
    return [...rect, ...dots.flat()];
  };
}
