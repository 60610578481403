import React, { useContext } from "react";
import { Alignment, FormGroup, Switch } from "@blueprintjs/core";

import SettingsView from "components/user/SettingsView";
import { Themes } from "constants/uiConstants";
import AppContext from "../../context/appContext";

const InterfaceSettings = () => {
  const {
    config: { theme },
    toggleTheme,
  } = useContext(AppContext);

  return (
    <SettingsView title="Interface" helperText="Customise the appearance of the application.">
      <FormGroup
        className="form-group"
        fill
        helperText="Change the user interface to a dark theme."
      >
        <Switch
          alignIndicator={Alignment.RIGHT}
          checked={theme === Themes.DARK}
          labelElement={<strong>Dark mode:</strong>}
          onChange={toggleTheme}
          style={{ width: "fit-content" }}
        />
      </FormGroup>
    </SettingsView>
  );
};

export default InterfaceSettings;
