import { useEffect } from "react";
import { Intent } from "@blueprintjs/core";

import { IResponse } from "types";
import { multiToaster } from "utils/toaster";
import { EStatus, errorMessages } from "constants/apiConstants";

type TUseSuccessFailToast = (response: IResponse<unknown> | null, showSuccess?: boolean) => void;

/** Small hook to show a toast based on a Valsys response */
const useSuccessFailToast: TUseSuccessFailToast = (response, showSuccess = true) => {
  useEffect(() => {
    if (response) {
      if (response.status === EStatus.failed) {
        multiToaster.show({
          message: response?.error || response?.message || errorMessages.SOMETHING_WENT_WRONG,
          intent: Intent.DANGER,
        });
      } else if (showSuccess && response.status === EStatus.success) {
        multiToaster.show({
          message: response.message || "Success",
          intent: Intent.SUCCESS,
        });
      }
    }
  }, [response, showSuccess]);
};

export default useSuccessFailToast;
