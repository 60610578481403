import React from "react";
import { Classes, Dialog, H4, KeyComboTag } from "@blueprintjs/core";

const HotkeyDialog = React.memo(({ isOpen, setHotkeyDialogOpen }) => {
  return (
    <Dialog
      className={Classes.HOTKEY_DIALOG}
      isOpen={isOpen}
      onClose={() => setHotkeyDialogOpen(false)}
    >
      <div className={Classes.DIALOG_BODY}>
        <div className={Classes.HOTKEY_COLUMN}>
          <H4>Hotkeys</H4>
          <div className={Classes.HOTKEY}>
            <div className={Classes.HOTKEY_LABEL}>Toggle between Edit Mode and Insert mode</div>
            <KeyComboTag combo="F2" />
          </div>
          <div className={Classes.HOTKEY}>
            <div className={Classes.HOTKEY_LABEL}>Enter Insert Mode</div>
            <KeyComboTag combo="=" />
          </div>
          <div className={Classes.HOTKEY}>
            <div className={Classes.HOTKEY_LABEL}>Delete selected column/line item</div>
            <KeyComboTag combo="meta + alt + -" />
          </div>
          <div className={Classes.HOTKEY}>
            <div className={Classes.HOTKEY_LABEL}>Copy</div>
            <KeyComboTag combo="meta + c" />
          </div>
          <div className={Classes.HOTKEY}>
            <div className={Classes.HOTKEY_LABEL}>Paste</div>
            <KeyComboTag combo="meta + v" />
          </div>
          <div className={Classes.HOTKEY}>
            <div className={Classes.HOTKEY_LABEL}>Fill row to the right</div>
            <KeyComboTag combo="meta + r" />
          </div>
          <div className={Classes.HOTKEY}>
            <div className={Classes.HOTKEY_LABEL}>Fill column downwards</div>
            <KeyComboTag combo="meta + d" />
          </div>
          <div className={Classes.HOTKEY}>
            <div className={Classes.HOTKEY_LABEL}>Toggle bold</div>
            <KeyComboTag combo="meta + b" />
          </div>
          <div className={Classes.HOTKEY}>
            <div className={Classes.HOTKEY_LABEL}>Toggle italic</div>
            <KeyComboTag combo="meta + i" />
          </div>
          <div className={Classes.HOTKEY}>
            <div className={Classes.HOTKEY_LABEL}>Toggle underline</div>
            <KeyComboTag combo="meta + u" />
          </div>
        </div>
      </div>
    </Dialog>
  );
});

HotkeyDialog.displayName = "HotkeyDialog";
export default HotkeyDialog;
