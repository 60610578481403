import colors from "styles/colors.module.scss";

export const FETCH_CONFIGS = "FETCH_CONFIGS";
export const FETCH_CONFIGS_SUCCESS = "FETCH_CONFIGS_SUCCESS";
export const FETCH_CONFIGS_FAILURE = "FETCH_CONFIGS_FAILURE";
export const EDIT_CONFIG = "EDIT_CONFIG";
export const EDIT_CONFIG_SUCCESS = "EDIT_CONFIG_SUCCESS";
export const EDIT_CONFIG_FAILURE = "EDIT_CONFIG_FAILURE";
export const LOCK_UI = "LOCK_UI";
export const UNLOCK_UI = "UNLOCK_UI";
export const UPDATE_FOCUSED_CELL = "UPDATE_FOCUSED_CELL";
export const UPDATE_FOCUSED_CELL_BOUNDS = "UPDATE_FOCUSED_CELL_BOUNDS";
export const UPDATE_FOCUSED_CELL_REDUCER = "UPDATE_FOCUSED_CELL_REDUCER";
export const UPDATE_SELECTED_REGIONS = "UPDATE_SELECTED_REGIONS";
export const UPDATE_SELECTED_REGIONS_REDUCER = "UPDATE_SELECTED_REGIONS_REDUCER";
export const UPDATE_EDIT_MODE = "UPDATE_EDIT_MODE";
export const UPDATE_EDIT_MODE_REDUCER = "UPDATE_EDIT_MODE_REDUCER";
export const UPDATE_FORMULA = "UPDATE_FORMULA";
export const UPDATE_FORMULA_REDUX = "UPDATE_FORMULA_REDUX";
export const UPDATE_FORMULA_INSERT_DATA = "UPDATE_FORMULA_INSERT_DATA";
export const UPDATE_FORMULA_TEMP_INSERT = "UPDATE_FORMULA_TEMP_INSERT";
export const NAV_CELL_COORDS = "NAV_CELL_COORDS";

export const FormatKeys = {
  BOLD: "bold",
  INDENTED: "indented",
  ITALIC: "italic",
  UNDERLINE: "underline",
  TEXT_COLOR: "textColor",
  FILL_COLOR: "fillColor",
  // DECIMAL_PLACES: "decimalPlaces",
};

export const DataEntryEditMode = {
  NORMAL: "NORMAL",
  FORMULA: "FORMULA",
  INSERT: "INSERT",
};

export const FormatDisplayNames = {
  backgroundColor: "Background color",
  color: "Color",
  fontWeight: "Font weight",
  fontStyle: "Font style",
  textAlign: "Text align",
  textDecoration: "Text decoration",
  valFormat: "Format",
  unit: "Unit",
  decimalPlaces: "Decimal places",
};

export const COLOR_PICKER_OPTIONS = {
  color: {
    dark: [
      colors.textColorDark,
      colors.blue5,
      colors.textColorDarkMuted,
      colors.red5,
      colors.green5,
      colors.orange5,
      colors.vermilion5,
      colors.rose5,
      colors.violet5,
      colors.indigo5,
      colors.cobalt5,
      colors.turquoise5,
      colors.forest5,
      colors.lime5,
      colors.gold5,
      colors.sepia5,
    ],
    light: [
      colors.textColor,
      colors.blue2,
      colors.textColorMuted,
      colors.red2,
      colors.green2,
      colors.orange2,
      colors.vermilion2,
      colors.rose2,
      colors.violet2,
      colors.indigo2,
      colors.cobalt2,
      colors.turquoise2,
      colors.forest2,
      colors.lime2,
      colors.gold2,
      colors.sepia2,
    ],
  },
  backgroundColor: {
    dark: [
      colors.tableBackgroundDark,
      colors.blue3,
      colors.textColorDarkMuted,
      colors.red3,
      colors.green3,
      colors.orange3,
      colors.vermilion4,
      colors.rose4,
      colors.violet4,
      colors.indigo4,
      colors.cobalt4,
      colors.turquoise4,
      colors.forest4,
      colors.lime4,
      colors.gold4,
      colors.sepia4,
    ],
    light: [
      colors.tableBackground,
      colors.blue3,
      colors.textColorMuted,
      colors.red3,
      colors.green3,
      colors.orange3,
      colors.vermilion1,
      colors.rose1,
      colors.violet1,
      colors.indigo1,
      colors.cobalt1,
      colors.turquoise1,
      colors.forest1,
      colors.lime1,
      colors.gold1,
      colors.sepia1,
    ],
  },
};
