import React, { useContext } from "react";
import styled from "styled-components/macro";
import { Classes } from "@blueprintjs/core";
import AppContext from "context/appContext";
import { FullSizeDiv, styledScrollBar } from "components/utility/StyledComponents";
import { f } from "utils/formatter";
import { IRawFact } from "types/model";
import { IOutputsData, ISimulation } from "types/scenarioAnalytics";
import { IFormatObject } from "types/format";
import { MaybeStickyTd, StyledTable } from "components/utility/StyledDashboardComponents";

interface SimulationResultsTableProps {
  availablePeriods: string[];
  outputs: string[];
  outputsData?: IOutputsData<IRawFact<IFormatObject, string>>[];
  simulation?: ISimulation[];
  simulating: boolean;
  sortedModelIds: string[];
}

const StyledTableContainer = styled(FullSizeDiv)`
  overflow: auto;
  padding: 1;
  ${styledScrollBar}
`;

const SimulationOutputsTable: React.FC<SimulationResultsTableProps> = ({
  availablePeriods,
  outputs,
  outputsData,
  simulation,
  simulating,
  sortedModelIds,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);

  // TODO: Remove me once a back end fix for this has been implemented (ENG-95)
  availablePeriods = availablePeriods.filter((p) => !p.includes("-"));

  let rows: JSX.Element[] | null = null;
  // If we have a simulation result then build the before/after diff structure

  if (simulation) {
    rows = simulation
      .sort(
        (a, b) =>
          sortedModelIds.findIndex((modelId) => a?.id === modelId) -
          sortedModelIds.findIndex((modelId) => b?.id === modelId)
      )
      .map((model) => {
        const lineItems = model?.lineItems ? [...model.lineItems] : [];
        return lineItems
          .sort(
            (item1, item2) =>
              outputs.findIndex((output) => item1?.tags?.indexOf(output) !== -1) -
              outputs.findIndex((output) => item2?.tags?.indexOf(output) !== -1)
          )
          .map((lineItem, liIdx) => {
            return [
              <tr key={`${lineItem.id}-before`}>
                {liIdx === 0 ? (
                  <MaybeStickyTd
                    rowSpan={lineItems.length * 3}
                    style={{ left: 0, position: "sticky", verticalAlign: "middle" }}
                    $theme={theme}
                  >
                    <b>{model.ticker}</b>
                  </MaybeStickyTd>
                ) : null}
                {liIdx > 0 ? (
                  <MaybeStickyTd rowSpan={3} $theme={theme}>
                    <em>{lineItem.name}</em>
                  </MaybeStickyTd>
                ) : (
                  <MaybeStickyTd rowSpan={3} $theme={theme}>
                    <em>{lineItem.name}</em>
                  </MaybeStickyTd>
                )}
                <MaybeStickyTd $theme={theme}>Original</MaybeStickyTd>
                {availablePeriods.map((period) => {
                  const fact = lineItem.before.find((fact) => fact.period === period);
                  return (
                    <MaybeStickyTd
                      className={simulating ? Classes.SKELETON : undefined}
                      key={fact ? fact.period : `${lineItem.id}-${period}-missing`}
                      $theme={theme}
                    >
                      <span className={simulating ? Classes.SKELETON : undefined}>
                        {fact ? f(fact) : "-"}
                      </span>
                    </MaybeStickyTd>
                  );
                })}
              </tr>,
              <tr key={`${lineItem.id}-after`}>
                <MaybeStickyTd $theme={theme}>Simulated</MaybeStickyTd>
                {availablePeriods
                  .map((period) => {
                    const fact = lineItem.after.find((fact) => fact.period === period);
                    return (
                      <MaybeStickyTd
                        key={fact ? fact.period : `${lineItem.id}-${period}-missing`}
                        $theme={theme}
                      >
                        <span className={simulating ? Classes.SKELETON : undefined}>
                          {fact ? f(fact) : "-"}
                        </span>
                      </MaybeStickyTd>
                    );
                  })
                  .flat()}
              </tr>,
              <tr key={`${lineItem.id}-diff`}>
                <MaybeStickyTd $theme={theme}>Difference</MaybeStickyTd>
                {availablePeriods.map((period) => {
                  const fact = lineItem.difference.find((fact) => fact.period === period);
                  return (
                    <MaybeStickyTd
                      className={simulating ? Classes.SKELETON : undefined}
                      key={fact ? fact.period : `${lineItem.id}-${period}-missing`}
                      $theme={theme}
                    >
                      <span
                        className={simulating ? Classes.SKELETON : undefined}
                        style={{ color: fact?.format?.color }}
                      >
                        {fact ? f(fact) : "-"}
                      </span>
                    </MaybeStickyTd>
                  );
                })}
              </tr>,
            ];
          })
          .flat();
      })
      .flat();
    // If we have outputs and a target item, render the li with the current data
  } else if (outputsData && outputsData.every((model) => model.lineItems)) {
    rows = outputsData
      .sort(
        (a, b) =>
          sortedModelIds.findIndex((modelId) => a?.id === modelId) -
          sortedModelIds.findIndex((modelId) => b?.id === modelId)
      )
      .map((model) => {
        return (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          model
            .lineItems!.sort(
              (a, b) =>
                outputs.findIndex((output) => a?.tags?.indexOf(output) !== -1) -
                outputs.findIndex((output) => b?.tags?.indexOf(output) !== -1)
            )
            .map((lineItem, idx) => {
              return (
                <tr key={lineItem.id}>
                  {idx === 0 ? (
                    <MaybeStickyTd
                      rowSpan={model.lineItems?.length}
                      style={{ verticalAlign: "middle" }}
                      $theme={theme}
                    >
                      <b>{model.ticker}</b>
                    </MaybeStickyTd>
                  ) : null}
                  <MaybeStickyTd $theme={theme}>{lineItem.name}</MaybeStickyTd>
                  {availablePeriods.map((period) => {
                    const fact = lineItem.facts.find((fact) => fact.period === period);
                    return (
                      <MaybeStickyTd key={`${fact?.id}-${model.id}`} $theme={theme}>
                        {fact ? f({ ...fact, value: fact.value || fact.formula }) : "-"}
                      </MaybeStickyTd>
                    );
                  })}
                </tr>
              );
            })
        );
      })
      .flat();
  }

  return (
    <StyledTableContainer
      calcHeight="calc(100% - 42px)"
      fullWidth
      style={{ overflow: "auto", padding: 1 }}
      $theme={theme}
    >
      <StyledTable style={{ tableLayout: "auto", width: "100%" }} $theme={theme}>
        <thead>
          <tr>
            <th colSpan={simulation ? 3 : 2} style={{ left: 0, zIndex: 5 }} />
            {availablePeriods.map((period) => (
              <th
                key={period}
                style={{ fontWeight: "bold", textAlign: "right", whiteSpace: "nowrap" }}
              >
                {period}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </StyledTable>
    </StyledTableContainer>
  );
};

export default SimulationOutputsTable;
