import useSWR, { MutatorCallback } from "swr";
import { FETCH_USER_INFORMATION_ROUTE } from "constants/apiConstants";
import { IResponse } from "types";
import { IUser } from "../types/user";
import api from "../utils/api";

const useUser: () => {
  mutate: (
    data?: Promise<IResponse<IUser>> | MutatorCallback<IResponse<IUser>> | IResponse<IUser>,
    shouldRevalidate?: boolean
  ) => Promise<IResponse<IUser> | undefined>;
  data: IResponse<IUser> | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  user: IUser | undefined;
} = () => {
  const { data, mutate } = useSWR<IResponse<IUser>>(FETCH_USER_INFORMATION_ROUTE, api, {
    revalidateOnFocus: false,
  });
  return { user: data?.data, data, error: data?.error, mutate };
};

export default useUser;
