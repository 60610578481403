import React, { Fragment, useMemo } from "react";
import styled, { css } from "styled-components/macro";
import { Classes, Divider, Intent, Tag } from "@blueprintjs/core";
import { RenderTemplates } from "constants/eventHistoryConstants";
import { Flex } from "components/utility/StyledComponents";
import colors from "styles/colors.module.scss";

const StyledEvent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${({ current }) =>
    current &&
    css`
      background-color: ${`${colors.intentPrimary}26`};
    `}
  margin: 4px 0;
  flex-wrap: wrap;
  padding: 4px 7px;
  border-radius: 4px;
  width: 100%;
  ${({ disabled }) => disabled && css``}
`;

const HistoryEvent = ({ currentRef, disabled, event, handleTagClick }) => {
  const eventTemplate = useMemo(() => {
    const { customRenderer } = RenderTemplates[event.action][0];
    const templateArray = customRenderer ? customRenderer(event) : RenderTemplates[event.action];

    if (templateArray.length === 0) return null;

    const build = templateArray.map((elem) => {
      const { displayValue, value, Element, key, lookup } = elem;

      let val = displayValue || value;
      if (elem.lookup) {
        val = event;
        lookup.forEach((k) => {
          val = val[k.key];
        });
      }
      return (
        <Element
          disabled={disabled}
          key={key}
          onClick={elem.tagName === "tag" ? () => handleTagClick(value) : null}
          style={{ ...elem.style, marginBottom: 4 }}
          {...elem.elementProps}
        >
          <span className={disabled ? Classes.TEXT_MUTED : null}>{`${val} `}</span>
        </Element>
      );
    });
    return build;
  }, [disabled, event, handleTagClick]);
  return (
    <StyledEvent current={event.state === "CURRENT"} ref={currentRef || null}>
      <Flex
        alignItems="flex-start"
        flexWrap="wrap"
        fullWidth
        justifyContent="space-between"
        style={{ margin: "0px 0px 0px 0px" }}
      >
        {event.displayAction ? (
          <Flex>
            <Tag
              className={Classes.TEXT_MUTED}
              intent={disabled ? null : Intent.PRIMARY}
              interactive={true}
              minimal
              onClick={handleTagClick}
            >
              <strong className={disabled ? Classes.TEXT_MUTED : null}>
                {event.displayAction}
              </strong>
            </Tag>
            <Divider />
            <Tag interactive minimal onClick={handleTagClick}>
              {event.moduleName}
            </Tag>
          </Flex>
        ) : null}
        <Flex alignItems="center" flexDirection="row" justifyContent="center">
          <p className={Classes.TEXT_MUTED} style={{ margin: "0 2px 10px 0px" }}>
            <strong>{event.state}</strong>
          </p>
        </Flex>
      </Flex>
      <Flex
        alignItems="flex-start"
        fullWidth
        justifyContent="flex-start"
        style={{ margin: "0px 0px 0px 0px" }}
      >
        {eventTemplate &&
          eventTemplate.map((templateItem, i) => {
            return <Fragment key={i}>{templateItem}&nbsp;</Fragment>;
          })}
      </Flex>
    </StyledEvent>
  );
};

export default HistoryEvent;
