/**
 * SOCKET ACTIONS
 **/
export const COMMITTED = "COMMITTED";
export const COMMITTING = "COMMITTING";
export const SET_COMMIT_ERROR = "SET_COMMIT_ERROR";
export const RECALCULATE_MODEL = "RECALCULATE_MODEL";
export const RECALCULATE_MODEL_SUCCESS = "RECALCULATE_MODEL_SUCCESS";
export const RECALCULATE_MODEL_FAILURE = "RECALCULATE_MODEL_FAILURE";

// FACT OPERATIONS
export const EDIT_FORMULA = "EDIT_FORMULA";
export const EDIT_FORMULA_REDUX = "EDIT_FORMULA_REDUX";
export const EDIT_FORMULA_SUCCESS = "EDIT_FORMULA_SUCCESS";
export const EDIT_FORMULA_SUCCESS_REDUX = "EDIT_FORMULA_SUCCESS_REDUX";
export const EDIT_FORMULA_FAILURE = "EDIT_FORMULA_FAILURE";

export const EDIT_FORMAT = "EDIT_FORMAT";
export const EDIT_FORMAT_SUCCESS = "EDIT_FORMAT_SUCCESS";
export const EDIT_FORMAT_FAILURE = "EDIT_FORMAT_FAILURE";

// MODULE OPERATIONS
export const ADD_MODULE = "ADD_MODULE";
export const ADD_MODULE_SUCCESS = "ADD_MODULE_SUCCESS";
export const ADD_MODULE_FAILURE = "ADD_MODULE_FAILURE";
export const DELETE_MODULE = "DELETE_MODULE";
export const DELETE_MODULE_SUCCESS = "DELETE_MODULE_SUCCESS";
export const DELETE_MODULE_FAILURE = "DELETE_MODULE_FAILURE";

// COLUMN OPERATIONS
export const ADD_COLUMN = "ADD_COLUMN";
export const ADD_COLUMN_SUCCESS = "ADD_COLUMN_SUCCESS";
export const ADD_COLUMN_FAILURE = "ADD_COLUMN_FAILURE";

export const DELETE_COLUMN = "DELETE_COLUMN";
export const DELETE_COLUMN_SUCCESS = "DELETE_COLUMN_SUCCESS";
export const DELETE_COLUMN_FAILURE = "DELETE_COLUMN_FAILURE";

// LINE ITEM OPERATIONS
export const ADD_LINE_ITEM = "ADD_LINE_ITEM";
export const ADD_LINE_ITEM_FAILURE = "ADD_LINE_ITEM_FAILURE";
export const ADD_LINE_ITEM_SUCCESS = "ADD_LINE_ITEM_SUCCESS";
export const ADD_LINE_ITEM_SUCCESS_REDUX = "ADD_LINE_ITEM_SUCCESS_REDUX";
export const DELETE_LINE_ITEM = "DELETE_LINE_ITEM";
export const DELETE_LINE_ITEM_SUCCESS = "DELETE_LINE_ITEM_SUCCESS";
export const DELETE_LINE_ITEM_FAILURE = "DELETE_LINE_ITEM_FAILURE";
export const EDIT_LINE_ITEMS = "EDIT_LINE_ITEMS";
export const EDIT_LINE_ITEMS_SUCCESS = "EDIT_LINE_ITEMS_SUCCESS";
export const EDIT_LINE_ITEMS_FAILURE = "EDIT_LINE_ITEMS_FAILURE";
export const EDIT_LINE_ITEM_TAGS = "EDIT_LINE_ITEM_TAGS";
export const EDIT_LINE_ITEM_TAGS_FAILURE = "EDIT_LINE_ITEM_TAGS_FAILURE";
export const REORDER_MODULE = "REORDER_MODULE";
export const REORDER_MODULE_SUCCESS = "REORDER_MODULE_SUCCESS";
export const REORDER_MODULE_FAILURE = "REORDER_MODULE_FAILURE";

// UNDO
export const UNDO_HISTORY = "UNDO_HISTORY";
export const UNDO_HISTORY_SUCCESS = "UNDO_HISTORY_SUCCESS";
export const UNDO_HISTORY_FAILURE = "UNDO_HISTORY_FAILURE";
export const UNDO = "UNDO";
export const BUFFERED_UNDO = "BUFFERED_UNDO";
export const REDO = "REDO";
export const BUFFERED_REDO = "BUFFERED_REDO";

// FETCH MODULE DATA - fetch individual module data
export const FETCH_MODULE_DATA = "FETCH_MODULE_DATA";
export const FETCH_MODULE_DATA_SUCCESS = "FETCH_MODULE_DATA_SUCCESS";
export const FETCH_MODULE_DATA_FAILURE = "FETCH_MODULE_DATA_FAILURE";

// FETCH KEY RATIO DATA
export const FETCH_KEY_RATIO_DATA = "FETCH_KEY_RATIO_DATA";
export const FETCH_KEY_RATIO_DATA_SUCCESS = "FETCH_KEY_RATIO_DATA_SUCCESS";
export const FETCH_KEY_RATIO_DATA_FAILURE = "FETCH_KEY_RATIO_DATA_FAILURE";

// MODULE NAME
export const EDIT_MODULE_NAME = "EDIT_MODULE_NAME";
export const EDIT_MODULE_NAME_FAILURE = "EDIT_MODULE_NAME_FAILURE";
export const EDIT_MODULE_NAME_SUCCESS = "EDIT_MODULE_NAME_SUCCESS";

//UPDATE CURRENT MODULE - change the module id
export const UPDATE_CURRENT_MODULE = "UPDATE_CURRENT_MODULE";
export const UPDATE_CURRENT_MODULE_REDUCER = "UPDATE_CURRENT_MODULE_REDUCER";
export const CYCLE_MODULE = "CYCLE_MODULE";

// LINE ITEMS
export const FETCH_QUICK_CALCULATIONS = "FETCH_QUICK_CALCULATIONS";
export const FETCH_QUICK_CALCULATIONS_FAILURE = "FETCH_QUICK_CALCULATIONS_FAILURE";
export const FETCH_QUICK_CALCULATIONS_SUCCESS = "FETCH_QUICK_CALCULATIONS_SUCCESS";
export const ADD_QUICK_CALCULATION = "ADD_QUICK_CALCULATION";
export const ADD_QUICK_CALCULATION_FAILURE = "ADD_QUICK_CALCULATION_FAILURE";
export const ADD_QUICK_CALCULATION_SUCCESS = "ADD_QUICK_CALCULATION_SUCCESS";
export const ADD_QUICK_CALCULATION_FINISHED = "ADD_QUICK_CALCULATION_FINISHED";

// FACTS
export const OPEN_SOCKET = "OPEN_SOCKET";
export const CLOSE_SOCKET = "CLOSE_SOCKET";

// TABLE CLIPBOARD
export const ADD_CLIPBOARD_ITEM = "ADD_CLIPBOARD_ITEM";

// FLUSH
export const FLUSH_DATA_REDUCER = "FLUSH_DATA_REDUCER";

// SOURCE
export const PULL_SOURCE = "PULL_SOURCE";
export const PULL_SOURCE_SUCCESS = "PULL_SOURCE_SUCCESS";
export const PULL_SOURCE_FAILURE = "PULL_SOURCE_FAILURE";

//FORMAT EDITS
export const FormatTypes = {
  BOLD: "bold",
  ITALIC: "italic",
  UNDERLINED: "underlined",
  FILL_COLOR: "fillColor",
  TEXT_COLOR: "textColor",
  FORMAT: "format",
};

// Used by the take effect in socket sagas
export const SOCKET_ACTIONS = {
  EDIT_FORMULA,
  EDIT_FORMAT,
  EDIT_LINE_ITEMS,
  RECALCULATE_MODEL,
  REORDER_MODULE,
  UNDO_HISTORY,
  ADD_QUICK_CALCULATION,
};

export const BUFFERED_SOCKET_ACTIONS = {
  ADD_LINE_ITEM,
  DELETE_LINE_ITEM,
  ADD_COLUMN,
  DELETE_COLUMN,
  BUFFERED_REDO,
  BUFFERED_UNDO,
  ADD_MODULE,
  DELETE_MODULE,
};

export const BUFFERED_SOCKET_RESPONSE_ACTIONS = Object.values(BUFFERED_SOCKET_ACTIONS)
  .map((action) => [`${action}_FAILURE`, `${action}_SUCCESS`])
  .flat();

export const REFETCH_UNDO_HISTORY_ACTIONS = [
  ...Object.values(SOCKET_ACTIONS)
    .filter((action) => action !== UNDO_HISTORY)
    .map((action) => [`${action}_FINISHED`]),
  ...Object.values(BUFFERED_SOCKET_ACTIONS).map((action) => [`${action}_FINISHED_REDUX`]),
].flat();

export const UNDO_RESPONSE_ACTIONS = [
  ...Object.values(SOCKET_ACTIONS),
  ...Object.values(BUFFERED_SOCKET_ACTIONS),
]
  .map((action) => `${action}_UNDO`)
  .concat(["REDO_FAILURE", "UNDO_FAILURE"]);
