import React from "react";
import styled from "styled-components";
import { Button, TagInput } from "@blueprintjs/core";

const FilterAndSortModelsWrapper = styled.div`
  min-width: 150px;
  max-width: 180px;
  flex: 1;
`;

const FilterAndSortModels = ({ clearEventTagFilters, removeEventTagFilter, tagFilters }) => {
  function handleTagRemove(value) {
    removeEventTagFilter(value);
  }
  return (
    <FilterAndSortModelsWrapper>
      <TagInput
        addOnPaste={false}
        fill
        leftIcon="filter"
        onRemove={handleTagRemove}
        rightElement={<Button icon="filter-remove" onClick={clearEventTagFilters} minimal />}
        values={tagFilters}
      />
    </FilterAndSortModelsWrapper>
  );
};

export default FilterAndSortModels;
