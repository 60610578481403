import React from "react";
import CodeMirror from "@uiw/react-codemirror";
import { Prec } from "@codemirror/state";
import { acceptCompletion } from "@codemirror/autocomplete";
import { EditorView, keymap, ViewUpdate } from "@codemirror/view";
import { TVSLAutoCompleter } from "types/vsl";
import vsl from "utils/codemirror/languageSupport";
import { Themes } from "constants/uiConstants";
import { TTheme } from "types";
import "styles/vsl/vsl-editor.scss";

export interface IVSLEditorProps {
  value: string;
  autoCompleter?: TVSLAutoCompleter;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  theme?: TTheme;
  onChange?: (newValue: string) => void;
}

const VSLEditor: React.FC<IVSLEditorProps> = ({
  autoCompleter,
  height,
  minHeight,
  maxHeight,
  theme,
  value,
  onChange,
}) => {
  const onEditorChange = (value: string, _viewUpdate: ViewUpdate): void => {
    if (onChange) {
      onChange(value);
    }
  };

  const customKeymap = keymap.of([
    {
      key: "Tab",
      win: "Tab",
      run: acceptCompletion,
    },
  ]);

  return (
    <CodeMirror
      extensions={[EditorView.lineWrapping, vsl(autoCompleter), Prec.high(customKeymap)]}
      height={height}
      minHeight={minHeight}
      maxHeight={maxHeight}
      className="cm"
      theme={theme === Themes.DARK ? "dark" : "light"}
      basicSetup={{
        closeBrackets: false,
        allowMultipleSelections: false,
        rectangularSelection: false,
        foldGutter: false,
        highlightActiveLine: false,
        highlightSelectionMatches: false,
        bracketMatching: false,
      }}
      value={value}
      onChange={onEditorChange}
    />
  );
};

export default VSLEditor;
