import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import { getValidJSON } from "utils/api";
import CodeMirror, { StateEffect } from "@uiw/react-codemirror";
import { EditorView } from "@codemirror/view";
import { indentUnit } from "@codemirror/language";
import { redo, undo } from "@codemirror/commands";
import isFunction from "lodash.isfunction";

export interface IJSONEditorHandle {
  getValue: (() => string) | (() => void);
  setValue: ((val: string) => void) | (() => void);
  undo: () => void;
  redo: () => void;
}

interface ITemplateEditorProps {
  body: string;
  handleRef: React.RefObject<IJSONEditorHandle>;
  height: string;
  lint?: (body: string) => void;
  lintDelay?: number;
  saving: boolean;
  setParsing: (isParsing: boolean) => void;
  setValidJSON: (isValid: boolean) => void;
}

const TemplateEditor: React.FC<ITemplateEditorProps> = ({
  body,
  handleRef,
  height,
  lint,
  lintDelay = 500,
  saving,
  setParsing,
  setValidJSON,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const editorRef = useRef<EditorView | null>(null);
  const parserTimeout = useRef<ReturnType<typeof setTimeout>>();
  const linterTimeout = useRef<ReturnType<typeof setTimeout>>();
  const [editorValue, setEditorValue] = useState<string>(body);

  const handleUndo = () => {
    if (editorRef.current) {
      undo(editorRef.current);
    }
  };

  const handleRedo = () => {
    if (editorRef.current) {
      redo(editorRef.current);
    }
  };

  const getValue = () => {
    return editorValue;
  };

  const setValue = (text: string): undefined => {
    const editor = editorRef.current;
    if (!editor) return;
    editor.dispatch({
      changes: { from: 0, to: editor.state.doc.length, insert: text },
    });
  };

  useImperativeHandle(handleRef, () => ({
    getValue,
    setValue,
    redo: handleRedo,
    undo: handleUndo,
  }));

  const lintJSON = (text: string) => {
    if (isFunction(lint)) {
      if (linterTimeout.current) clearTimeout(linterTimeout.current);
      linterTimeout.current = setTimeout(() => {
        if (text) lint(text);
      }, lintDelay);
    }
  };

  const validateJSON = (text: string) => {
    if (parserTimeout.current) clearTimeout(parserTimeout.current);
    setParsing(true);
    parserTimeout.current = setTimeout(() => {
      if (text) {
        if (getValidJSON(text)) {
          setValidJSON(true);
        } else {
          setValidJSON(false);
        }
      } else {
        setValidJSON(true);
      }
      setParsing(false);
    }, 700);
  };

  const handleOnChange = (newValue: string) => {
    setEditorValue(newValue);
    validateJSON(newValue);
    lintJSON(newValue);
  };

  /** When the body string changes we update the editor's value */
  useEffect(() => {
    setEditorValue(body);
  }, [body, editorRef]);

  /** enable read only mode while saving */
  useEffect(() => {
    const editor = editorRef.current;
    if (!editor?.dispatch) return;
    editor.dispatch({
      effects: StateEffect.reconfigure.of([EditorView.editable.of(saving)]),
    });
  }, [editorRef, saving]);

  return (
    <CodeMirror
      basicSetup={{
        lineNumbers: true,
        highlightActiveLine: true,
      }}
      extensions={[EditorView.lineWrapping, indentUnit.of("    ")]}
      height={height}
      onChange={handleOnChange}
      ref={editorRef}
      theme={theme === Themes.DARK ? "dark" : "light"}
      value={editorValue}
      width="100%"
    />
  );
};

export default TemplateEditor;
