import { CSSProperties } from "react";
import { IFact, ILineItem, IModule } from "types/model";
import { TCompanyInfo } from "./createModel";
import {
  IFormulaEditorItem,
  INormalizedImportFact,
  TNormalizedImportLineItem,
  TNormalizedImportModule,
} from "./import";
import { EUnit, IFormatObject } from "./format";

export enum TResponseKey {
  LINE_ITEMS = "lineItems",
  SENSITIVITIES = "sensitivities",
}

export interface IVSSheetCell {
  coordinate: string;
  value: string;
  formula: string;
}

interface IModelFocusedCellFactProp extends IFact {
  parent: ILineItem;
  module: IModule;
}

interface IImportFocusedCellFactProp extends INormalizedImportFact {
  parent: TNormalizedImportLineItem;
  module: TNormalizedImportModule;
}

export interface IFormatChange {
  change: unknown;
  type: "fontWeight" | "fontStyle" | "textDecoration";
}

export interface IFocusedCell<F> {
  col: number;
  row: number;
  focusSelectionIndex?: number;
  fact: F extends IFact ? IModelFocusedCellFactProp : IImportFocusedCellFactProp;
}

export interface IFocusedCellStyle extends CSSProperties {
  default: IFormatObject;
  type: "Constant" | "Formula";
  unit: EUnit;
}

export interface IFormulaConfig {
  setEditMode: () => void;
  mode: string;
  onCommit: (
    commitFormula: string,
    commitItem: IFormulaEditorItem<INormalizedImportFact | IFact>
  ) => void;
  data?: {
    dataSources: string;
    moduleList: IModule[];
    tickers: TCompanyInfo[];
  };
}

export interface IRawSensitivityAnalyses {
  cols: number[];
  grid: IFact[][];
  format: string;
}

export interface IRowsSelectorConfig {
  showErrors: boolean | undefined;
  rawValues: boolean | undefined;
}
