import React, {
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import styled from "styled-components/macro";
import useSWR from "swr";
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Checkbox,
  Classes,
  ControlGroup,
  Divider,
  EditableText,
  FormGroup,
  H4,
  H5,
  H6,
  HTMLSelect,
  Intent,
  MaybeElement,
  MenuItem,
  Spinner,
  SpinnerSize,
  Tag,
  TagInput,
  Tooltip,
} from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";
import { HeaderTypes, SelectionCardinality, TimeSeriesStates } from "constants/importConstants";
import { FETCH_AVAILABLE_TIME_SERIES_FORMATS_ROUTE } from "constants/apiConstants";
import { AtLeastOnePropertyOf, IResponse } from "types";
import {
  IImportModuleInfo,
  IModuleConfiguration,
  ITestTimeSeries,
  TDraftModule,
  THeaderType,
  TTimeSeriesFormat,
} from "types/import";
import {
  Flex,
  noMarginComponent,
  PaddedContent,
  SideMenuHeader,
  StyledA,
} from "components/utility/StyledComponents";
import { TCardinality } from "types/vsTable";
import api from "utils/api";
import AppContext from "context/appContext";
import { BlueprintIcons_16Id } from "@blueprintjs/icons/lib/esnext/generated/16px/blueprint-icons-16";
import { EUnit } from "types/format";

export interface IAddOrEditImportModuleProps {
  addingModule: boolean;
  draftModule: TDraftModule;
  edited: { [key: string]: boolean };
  editMode: "ADD" | "EDIT";
  handleCreate: () => void;
  handleNameChange: (moduleName: string) => void;
  handleTypeCoord: (position: "start" | "stop", coord: string) => void;
  modules?: IImportModuleInfo[];
  onCancel: () => void;
  onDeleteModule: () => void;
  onEditModeChange: (editMode: "ADD" | "EDIT") => void;
  onModuleMetadataChange: (edit: AtLeastOnePropertyOf<IModuleConfiguration>) => void;
  onModuleSelect: (moduleInfo: IImportModuleInfo) => void;
  onTimeSeriesFormatChange: (timeSeriesFormat: TTimeSeriesFormat) => void;
  removeBreak: (breakCell: string, cardinality: TCardinality) => void;
  selectedModule?: IImportModuleInfo;
  selecting: { cardinality: TCardinality; type: THeaderType | "break" } | null;
  selectionType: string;
  setOnMouseOver: Dispatch<SetStateAction<THeaderType | undefined>>;
  setSelecting: Dispatch<
    SetStateAction<{
      cardinality: TCardinality;
      type: THeaderType | "break";
    } | null>
  >;
  timeSeriesValid: IResponse<ITestTimeSeries> | null;
  validating: boolean;
}

const LargeLabel = noMarginComponent(H6);

const CellTag = styled(Tag)`
  font-family: monospace;
  font-weight: bold;
`;

const AddOrEditImportModule: React.FC<IAddOrEditImportModuleProps> = ({
  addingModule,
  draftModule,
  edited,
  editMode,
  handleCreate,
  handleNameChange,
  handleTypeCoord,
  modules,
  onCancel,
  onDeleteModule,
  onEditModeChange,
  onModuleMetadataChange,
  onModuleSelect,
  onTimeSeriesFormatChange,
  removeBreak,
  selectedModule,
  selecting,
  selectionType,
  setOnMouseOver,
  setSelecting,
  timeSeriesValid,
  validating,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const rowHeaders = draftModule.rowHeaders.cellRange;
  const rowBreaks = draftModule.rowHeaders.breaks.coordinateRanges;
  const colHeaders = draftModule.columnHeaders.cellRange;
  const columnBreaks = draftModule.columnHeaders.breaks.coordinateRanges;
  const [dirtyModuleName, setDirtyModuleName] = useState("");
  const [dirtyRowHeaders, setDirtyRowHeaders] = useState(rowHeaders);
  const [dirtyColHeaders, setDirtyColHeaders] = useState(colHeaders);
  const editing = editMode === "EDIT";
  const loading =
    (editing && draftModule?.uid !== selectedModule?.uid.toString()) ||
    (!selectedModule && editing) ||
    addingModule;

  useEffect(() => {
    const rowHeaders = draftModule.rowHeaders.cellRange;
    const colHeaders = draftModule.columnHeaders.cellRange;
    setDirtyRowHeaders(rowHeaders);
    setDirtyColHeaders(colHeaders);
    setDirtyModuleName(draftModule.name ? draftModule.name : "");
  }, [draftModule]);

  const editMade = Object.values(edited).some((edit) => edit);

  const { data: timeSeriesFormats } = useSWR<IResponse<TTimeSeriesFormat[]>>(
    FETCH_AVAILABLE_TIME_SERIES_FORMATS_ROUTE,
    api
  );

  const handleTimeSeriesFormatChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    onTimeSeriesFormatChange(e.currentTarget.value as TTimeSeriesFormat);
  };

  const [selectQuery, setSelectQuery] = useState("");
  const [parentQuery, setParentQuery] = useState("");
  const modulePredicate = (query: string, moduleInfo: IImportModuleInfo) => {
    return moduleInfo.name.includes(query.toLowerCase());
  };
  const handleUnitChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    onModuleMetadataChange({ units: e.currentTarget.value });
  };
  const itemRenderer: ItemRenderer<IImportModuleInfo> = (
    moduleInfo,
    { modifiers, handleClick }
  ) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={moduleInfo.uid}
        onClick={handleClick}
        text={moduleInfo.name}
      />
    );
  };

  const validTimeSeries = timeSeriesValid?.message === TimeSeriesStates.Valid;

  const timeSeriesInfo: {
    icon?: BlueprintIcons_16Id | MaybeElement;
    intent?: Intent;
    tagText: string;
    helperText: string;
  } = {
    icon: undefined,
    intent: undefined,
    tagText: "N/A",
    helperText: "No time series selected.",
  };

  const moduleParentName =
    draftModule?.parentModule && modules
      ? modules.find((mod) => mod.uid === draftModule.parentModule)?.name
      : undefined;

  const canCreate =
    draftModule?.columnHeaders.cellRange[0] !== "None" &&
    draftModule?.rowHeaders.cellRange[0] !== "None" &&
    validTimeSeries;

  if (validating) {
    timeSeriesInfo.icon = <Spinner size={SpinnerSize.SMALL} />;
    timeSeriesInfo.intent = Intent.PRIMARY;
    timeSeriesInfo.tagText = "Testing...";
    timeSeriesInfo.helperText = "Validation in progress.";
  } else if (validTimeSeries && timeSeriesValid) {
    timeSeriesInfo.icon = "tick";
    timeSeriesInfo.intent = Intent.SUCCESS;
    timeSeriesInfo.tagText = "Valid.";
    timeSeriesInfo.helperText = timeSeriesValid?.message || "Time series valid.";
  } else if (timeSeriesValid) {
    timeSeriesInfo.icon = "warning-sign";
    timeSeriesInfo.intent = Intent.DANGER;
    timeSeriesInfo.tagText = "Invalid.";
    timeSeriesInfo.helperText = timeSeriesValid?.message || "Invalid time series!";
  }

  return (
    <Flex flexDirection="column" fullHeight fullWidth>
      <Flex justifyContent="flex-start" flexDirection="column" flexGrow="0" fullWidth>
        <SideMenuHeader
          alignItems="flex-start"
          flexGrow="0"
          flexDirection="row"
          justifyContent="space-between"
          theme={theme}
        >
          <Flex alignItems="center" fullHeight>
            <span style={{ fontVariant: "small-caps", fontSize: "14px", marginBottom: "0px" }}>
              Add or edit import module
            </span>
          </Flex>
          <ButtonGroup>
            <Tooltip content="Create new module" openOnTargetFocus={false}>
              <AnchorButton
                active={editMode === "ADD"}
                disabled={addingModule || (loading && !!selectedModule)}
                icon="cube-add"
                intent={editMode === "ADD" ? Intent.PRIMARY : undefined}
                onClick={() => {
                  onEditModeChange("ADD");
                }}
                minimal
              />
            </Tooltip>
            <Tooltip content="Delete module" openOnTargetFocus={false}>
              <AnchorButton
                disabled={addingModule || !draftModule?.uid}
                icon="cube-remove"
                minimal
                onClick={onDeleteModule}
              />
            </Tooltip>
            <Tooltip content="Edit module" openOnTargetFocus={false}>
              <AnchorButton
                active={editMode === "EDIT"}
                disabled={!modules || modules?.length < 1 || addingModule || loading}
                icon="edit"
                intent={editMode === "EDIT" ? Intent.PRIMARY : undefined}
                onClick={() => {
                  onEditModeChange("EDIT");
                }}
                minimal
              />
            </Tooltip>
          </ButtonGroup>
        </SideMenuHeader>
        <Select<IImportModuleInfo>
          popoverProps={{
            disabled: !editing || !!(loading && selectedModule),
          }}
          itemPredicate={modulePredicate}
          items={modules || []}
          itemRenderer={itemRenderer}
          noResults={<MenuItem disabled text="No results!" />}
          onItemSelect={onModuleSelect}
          onQueryChange={setSelectQuery}
          fill
          query={selectQuery}
        >
          <Button
            disabled={!editing || !!(loading && selectedModule)}
            icon={editing ? "cube" : undefined}
            intent={editing && !selectedModule ? Intent.PRIMARY : undefined}
            minimal
            outlined
            rightIcon={editing ? "double-caret-vertical" : undefined}
            text={
              editing
                ? selectedModule?.name
                  ? selectedModule?.name
                  : "Select module..."
                : "New module"
            }
            fill
          />
        </Select>
        <Divider />
        <Flex justifyContent="flex-start" fullWidth style={{ paddingLeft: 10 }}>
          <H4 className={loading ? Classes.TEXT_MUTED : undefined}>
            <EditableText
              disabled={loading}
              intent={loading ? undefined : Intent.PRIMARY}
              onChange={setDirtyModuleName}
              onConfirm={() => handleNameChange(dirtyModuleName)}
              value={dirtyModuleName}
            />
          </H4>
        </Flex>
      </Flex>
      <Flex
        justifyContent="flex-start"
        flex="1 0 0"
        flexDirection="column"
        style={{ overflowY: "auto" }}
      >
        <PaddedContent padding="10px" style={{ flex: 1 }}>
          <Flex
            flex="0"
            flexDirection="column"
            justifyContent="flex-start"
            style={{ height: "calc(100% - 31px)" }}
          >
            <Flex flexDirection="column" flex="1">
              <H5>Module Bounds</H5>
              <Flex flex="0" fullWidth justifyContent="space-between" style={{ marginBottom: 10 }}>
                <Tag minimal>
                  <span className={Classes.TEXT_MUTED}>Selecting:</span>{" "}
                  <strong>{selectionType}</strong>{" "}
                  {selecting ? (
                    <StyledA role="button" onClick={() => setSelecting(null)}>
                      Cancel
                    </StyledA>
                  ) : null}
                </Tag>
              </Flex>
              <Flex flex="0" justifyContent="flex-start" fullWidth fullHeight>
                <FormGroup
                  helperText="The default unit to apply to numeric values in the module."
                  label={<LargeLabel>Unit:</LargeLabel>}
                >
                  <HTMLSelect
                    disabled={loading}
                    onChange={handleUnitChange}
                    options={Object.values(EUnit)}
                    value={draftModule.units}
                  />
                </FormGroup>
              </Flex>
              <Flex
                alignItems="flex-start"
                flex="0"
                flexDirection="column"
                fullWidth
                justifyContent="flex-start"
              >
                <Flex
                  alignItems="flex-start"
                  flex="0"
                  flexDirection="column"
                  fullWidth
                  justifyContent="flex-start"
                >
                  <FormGroup
                    disabled={loading}
                    label={<LargeLabel>Time series:</LargeLabel>}
                    intent={timeSeriesInfo.intent}
                    helperText={timeSeriesInfo.helperText}
                  >
                    <div
                      onMouseEnter={() => setOnMouseOver(HeaderTypes.TimeSeries)}
                      onMouseLeave={() => setOnMouseOver(undefined)}
                    >
                      <ControlGroup>
                        <CellTag intent={timeSeriesInfo.intent} large minimal>
                          {selecting?.cardinality === SelectionCardinality.COL &&
                          selecting.type === HeaderTypes.TimeSeries ? (
                            <>
                              <EditableText
                                selectAllOnFocus
                                value={dirtyColHeaders[0]}
                                onChange={(v) => setDirtyColHeaders([v, dirtyColHeaders[1]])}
                                maxLength={4}
                                onConfirm={(coord) => handleTypeCoord("start", coord)}
                              />{" "}
                              :{" "}
                              <EditableText
                                value={dirtyColHeaders[1]}
                                maxLength={4}
                                onChange={(v) => setDirtyColHeaders([dirtyColHeaders[0], v])}
                                onConfirm={(coord) => handleTypeCoord("stop", coord)}
                              />
                            </>
                          ) : (
                            `${colHeaders[0]}:${colHeaders[1]}`
                          )}
                        </CellTag>
                        <Button
                          active={
                            selecting?.cardinality === SelectionCardinality.COL &&
                            selecting.type === HeaderTypes.TimeSeries
                          }
                          className={Classes.FIXED}
                          disabled={loading}
                          icon="edit"
                          onClick={() =>
                            selecting?.cardinality === SelectionCardinality.COL &&
                            selecting.type === HeaderTypes.TimeSeries
                              ? setSelecting(null)
                              : setSelecting({
                                  cardinality: SelectionCardinality.COL,
                                  type: HeaderTypes.TimeSeries,
                                })
                          }
                        />
                        <Tag rightIcon={timeSeriesInfo.icon} intent={timeSeriesInfo.intent} minimal>
                          {timeSeriesInfo.tagText}
                        </Tag>
                      </ControlGroup>
                    </div>
                  </FormGroup>
                  <Divider />
                  <FormGroup label={<LargeLabel>Time series format:</LargeLabel>}>
                    <HTMLSelect
                      disabled={loading}
                      onChange={handleTimeSeriesFormatChange}
                      options={timeSeriesFormats?.data || []}
                      value={draftModule.timeSeries?.format}
                    />
                  </FormGroup>
                </Flex>
                <FormGroup
                  label={<LargeLabel>Column breaks:</LargeLabel>}
                  helperText={edited.colBreaks ? "Edited." : undefined}
                  style={{ width: "100%" }}
                >
                  <Flex fullWidth style={{ maxWidth: 300 }}>
                    <TagInput
                      disabled={loading}
                      inputProps={{
                        onFocus: () =>
                          setSelecting({
                            cardinality: SelectionCardinality.COL,
                            type: "break",
                          }),
                      }}
                      fill
                      onRemove={(value) =>
                        typeof value === "string"
                          ? removeBreak(value, SelectionCardinality.COL)
                          : undefined
                      }
                      values={columnBreaks}
                    />
                    <Button
                      active={
                        selecting?.cardinality === SelectionCardinality.COL &&
                        selecting.type === "break"
                      }
                      disabled={loading}
                      icon="edit"
                      onClick={() =>
                        selecting?.cardinality === SelectionCardinality.COL &&
                        selecting.type === "break"
                          ? setSelecting(null)
                          : setSelecting({
                              cardinality: SelectionCardinality.COL,
                              type: "break",
                            })
                      }
                    />
                  </Flex>
                </FormGroup>
              </Flex>
              <Flex
                columnGap={20}
                flexDirection="column"
                flex="0"
                fullWidth
                alignItems="flex-start"
              >
                <Flex
                  alignItems="flex-start"
                  flexDirection="column"
                  fullWidth
                  justifyContent="flex-start"
                >
                  <FormGroup
                    label={<LargeLabel>Row headers:</LargeLabel>}
                    helperText={edited.rowHeaders ? "Edited." : undefined}
                  >
                    <Flex justifyContent="space-between">
                      <ControlGroup>
                        <CellTag
                          intent={
                            selecting?.cardinality === SelectionCardinality.ROW &&
                            selecting.type === HeaderTypes.LineItems
                              ? Intent.PRIMARY
                              : undefined
                          }
                          large
                          minimal
                        >
                          {selecting?.cardinality === SelectionCardinality.ROW &&
                          selecting.type === HeaderTypes.LineItems ? (
                            <>
                              <EditableText
                                selectAllOnFocus
                                value={dirtyRowHeaders[0]}
                                maxLength={5}
                                onChange={(v) => setDirtyRowHeaders([v, dirtyRowHeaders[1]])}
                                onConfirm={(coord) => handleTypeCoord("start", coord)}
                              />{" "}
                              :{" "}
                              <EditableText
                                value={dirtyRowHeaders[1]}
                                maxLength={5}
                                onChange={(v) => setDirtyRowHeaders([dirtyRowHeaders[0], v])}
                                onConfirm={(coord) => handleTypeCoord("stop", coord)}
                              />
                            </>
                          ) : (
                            `${rowHeaders[0]}:${rowHeaders[1]}`
                          )}
                        </CellTag>
                        <Button
                          active={
                            selecting?.cardinality === SelectionCardinality.ROW &&
                            selecting.type === HeaderTypes.LineItems
                          }
                          disabled={loading}
                          icon="edit"
                          onClick={() =>
                            selecting?.cardinality === SelectionCardinality.ROW &&
                            selecting.type === HeaderTypes.LineItems
                              ? setSelecting(null)
                              : setSelecting({
                                  cardinality: SelectionCardinality.ROW,
                                  type: HeaderTypes.LineItems,
                                })
                          }
                        />
                      </ControlGroup>
                    </Flex>
                  </FormGroup>
                  <FormGroup
                    label={<LargeLabel>Row breaks:</LargeLabel>}
                    helperText={edited.rowBreaks ? "Edited." : undefined}
                    style={{ width: "100%" }}
                  >
                    <Flex fullWidth style={{ maxWidth: 300 }}>
                      <TagInput
                        fill
                        disabled={loading}
                        inputProps={{
                          onFocus: () =>
                            setSelecting({ cardinality: SelectionCardinality.ROW, type: "break" }),
                        }}
                        onRemove={(value) =>
                          typeof value === "string"
                            ? removeBreak(value, SelectionCardinality.ROW)
                            : undefined
                        }
                        values={rowBreaks}
                      />
                      <Button
                        active={
                          selecting?.cardinality === SelectionCardinality.ROW &&
                          selecting.type === "break"
                        }
                        disabled={loading}
                        icon="edit"
                        onClick={() =>
                          selecting?.cardinality === SelectionCardinality.ROW &&
                          selecting.type === "break"
                            ? setSelecting(null)
                            : setSelecting({ cardinality: SelectionCardinality.ROW, type: "break" })
                        }
                      />
                    </Flex>
                  </FormGroup>
                </Flex>
              </Flex>
              <H5>Module Hierarchy</H5>
              <p className={Classes.RUNNING_TEXT}>
                The module hierarchy allows nesting of modules beneath other modules in a tree-like
                structure for ease of navigation.
              </p>
              <Flex
                fullWidth
                alignItems="flex-start"
                justifyContent="flex-start"
                flexDirection="column"
              >
                <FormGroup label={<LargeLabel>Module parent:</LargeLabel>}>
                  <Select<IImportModuleInfo>
                    itemPredicate={modulePredicate}
                    itemRenderer={itemRenderer}
                    items={modules?.filter((mod) => mod.uid !== selectedModule?.uid) || []}
                    noResults={<MenuItem disabled text="No results!" />}
                    onItemSelect={(moduleInfo) =>
                      onModuleMetadataChange({
                        parentModule: moduleInfo.uid,
                        unlinked: false,
                      })
                    }
                    onQueryChange={setParentQuery}
                    query={parentQuery}
                  >
                    <Button
                      disabled={loading}
                      icon="cube"
                      rightIcon={"double-caret-vertical"}
                      text={moduleParentName || "None"}
                    />
                  </Select>
                  <Button
                    icon="clean"
                    onClick={() =>
                      onModuleMetadataChange({ parentModule: undefined, unlinked: true })
                    }
                  />
                </FormGroup>
                <FormGroup helperText="Whether or not the module has a parent module.">
                  <div>
                    <Checkbox
                      checked={draftModule.unlinked}
                      disabled={loading || !!moduleParentName}
                      label="Root"
                      onChange={() => {
                        onModuleMetadataChange({
                          unlinked: !draftModule.unlinked,
                        });
                      }}
                    />
                  </div>
                </FormGroup>
              </Flex>
            </Flex>
          </Flex>
        </PaddedContent>
      </Flex>
      <PaddedContent padding="10px" style={{ flex: 0, width: "100%" }}>
        <Flex fullWidth justifyContent="space-between" flex="0">
          <Button
            disabled={!editMade || loading}
            icon={editing ? "cross" : "clean"}
            onClick={onCancel}
            text={editing ? "Cancel" : "Clear"}
          />
          {!editing ? (
            <Button
              disabled={!canCreate}
              icon={editing ? "edit" : "plus"}
              intent={Intent.PRIMARY}
              loading={addingModule}
              onClick={handleCreate}
              text="Create Module"
            />
          ) : null}
        </Flex>
      </PaddedContent>
    </Flex>
  );
};

export default AddOrEditImportModule;
