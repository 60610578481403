import { ColumnDef } from "@tanstack/react-table";
import { ModelInfoKeys } from "constants/modelHistoryConstants";
import { IModelInfo } from "./model";
import { EUnit, EValFormat, IFormatObject } from "./format";
import { ESortDirection } from "types";
import { TableValue } from "components/table/Table";

export interface IOptModelHistoryFields<Ft = string> {
  [key: string]: TableValue<Ft>;
}

export interface IModelInfoWithFields {
  model: IModelInfo;
  fields: IOptModelHistoryFields;
}

export type IModelHistoryInfo = IModelInfo & IOptModelHistoryFields;

export type TModelHistoryTablePartialModel = Partial<IModelHistoryInfo> &
  Pick<IModelHistoryInfo, "id">;

export type TModelHistoryColumnDef = ColumnDef<TModelHistoryTablePartialModel>;

export interface IFilterTypes {
  Name: boolean;
  Ticker: boolean;
  Geography: boolean;
  Industry: boolean;
}

export interface IFilterState {
  allActive: boolean;
  dateRange: [Date | null, Date | null];
  filters: IFilterTypes;
  geoFilters: string[];
  indFilters: string[];
  predicate: string;
  sortBy?: string;
  tagFilters?: string[];
  tagFilterType?: "And" | "Or";
}

export interface IColumn {
  cellRenderer?: string; // The cell renderer implementation to use
  dataKey: string; // The datakey used to identify the column
  dateFormat?: string; // If the sortType is date
  flexGrow: 1 | 0; // Whether the column should expand to fill available space
  flexShrink: 1 | 0; // Whether the column should shrink
  format?: Partial<IFormatObject>;
  headerRenderer?: string; // The headerRenderer implementation to use
  label?: string; // The title to display to the user.  If omitted, defaults to dataKey
  maxWidth?: number; // Optional maximum of the Column
  minWidth?: number; // Optional minimum width of the Column
  sortType: "number" | "date" | "alpha" | null; // Determines the sort function used by the Column
  width: number; // The starting width of the column
}

export type TModelType = "User" | "Shared" | "Both";

export interface ITableConfigColumn {
  colorizeNumerics?: boolean;
  enableSorting?: boolean;
  id: string;
  header: string;
  meta?: {
    tdStyle?: string | IFormatObject | null;
    type?: string;
  };
  unit?: EUnit;
  valFormat?: EValFormat;
  width?: number;
}

export interface ITableRendererConfig {
  columns?: ITableConfigColumn[];
  displayRowIndex?: boolean; // Whether the row index should be displayed as the 0th column
  enablePredicateSearch?: boolean;
  enableSelection?: boolean;
  pinnedColumns?: number;
  sort?: ITableConfigSort;
}

export interface ITableConfigSort {
  sortBy: string;
  sortDirection: boolean;
}

export interface IModelHistoryTable {
  uid: string;
  name: string;
  columns: ITableConfigColumn[];
  displayRowIndex?: boolean; // Whether the row index should be displayed as the 0th column
  enableSelection?: boolean;
  filters: IFilterState;
  frozenColumns?: number;
  modelType: TModelType;
  sortType?: string; // The id of the column to sort by.
  desc?: ESortDirection; // Boolean to determine sort direction.
  tagFilters?: string[];
}

// TYPE GUARDS
// Guard to check if a string is a key is in an IModelInfo.
export const isModelInfoKey = (testKey: string): testKey is keyof IModelInfo => {
  return !!(ModelInfoKeys as { [key: string]: string })[testKey];
};

// Guard to determine whether a saved model history column is valid.
export const isSavedModelHistoryColumn = (
  maybeColumn: unknown
): maybeColumn is ITableConfigColumn => {
  const hasID =
    "id" in (maybeColumn as ITableConfigColumn) &&
    typeof (maybeColumn as ITableConfigColumn)?.id === "string";
  const hasHeader =
    "header" in (maybeColumn as ITableConfigColumn) &&
    typeof (maybeColumn as ITableConfigColumn)?.header === "string";
  return hasID && hasHeader;
};

// Type guard to test whether an object is an IModelHistoryTable object
export const isModelHistoryTable = (
  maybeModelHistoryTable: unknown
): maybeModelHistoryTable is IModelHistoryTable => {
  const test = maybeModelHistoryTable as IModelHistoryTable;

  const hasUidAndName = "uid" in test && "name" in test && typeof test.name === "string";

  const validColumns =
    "columns" in test &&
    Array.isArray(test.columns) &&
    test.columns.every((column) => isSavedModelHistoryColumn(column));

  return hasUidAndName && validColumns && "filters" in test;
};

/** TODO: Use to error check available fields prop when editing using the template editor */
export const isAvailableFields = (
  maybeAvailableFields: unknown
): maybeAvailableFields is ITableConfigColumn[] => {
  return (
    Array.isArray(maybeAvailableFields) &&
    maybeAvailableFields.every((field) => isSavedModelHistoryColumn(field))
  );
};
