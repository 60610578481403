import { IFilterTypes, IFilterState, IModelHistoryInfo } from "types/modelHistory";
import { InitialFilterState } from "components/table/ModelFilters";

/**
 * Utility to filter an array of models by a supplied FilterState. Date filters by the createdAt prop.
 */
export const filterModels = (
  models: IModelHistoryInfo[],
  filterState: IFilterState
): IModelHistoryInfo[] => {
  const {
    allActive,
    dateRange,
    filters,
    geoFilters,
    indFilters,
    predicate,
    tagFilters,
    tagFilterType,
  } = filterState;
  let filtered = filterModelsByDate(models, dateRange);
  filtered = filterModelsByModelTags(filtered, tagFilters, tagFilterType);
  filtered = filterModelsByGeoAndIndTags(filtered, geoFilters, indFilters);
  filtered = filterModelsByPredicate(filtered, filters, allActive, predicate);
  return filtered;
};

/**
 * Utility to filter an array of Valsys models by the date they were created. The utility clamps between the supplied date range.
 * Expects an array of length two of the format [minDate, maxDate].
 */
export const filterModelsByDate = (
  models: IModelHistoryInfo[],
  dateRange: [Date | null, Date | null],
  filterProp: TModelDateProps = "createdAt"
): IModelHistoryInfo[] => {
  const [minDate, maxDate] = dateRange;
  if (typeof minDate?.getMonth === "function" && typeof maxDate?.getMonth === "function") {
    return models.filter((model) => {
      return model[filterProp] >= minDate && model[filterProp] <= maxDate;
    });
  }
  return models;
};

type TModelDateProps = "createdAt";

/**
 * Utility to filter an array of Valsys models by an array of model tags.
 */
export const filterModelsByModelTags = (
  models: IModelHistoryInfo[],
  tagFilters: string[] | undefined,
  tagFilterType = "And"
): IModelHistoryInfo[] => {
  if (tagFilters && tagFilters.length !== 0) {
    const withTags = models.filter((model) => model.tags);
    if (tagFilterType === "Or") {
      return withTags.filter(
        (model) => model?.tags && model.tags.some((tag) => tagFilters.includes(tag))
      );
    } else if (tagFilterType === "And") {
      return withTags.filter((model) =>
        tagFilters.every((tag) => model.tags && model.tags.includes(tag))
      );
    }
  }
  return models;
};

/**
 * Utility to filter by industry or geography tags.
 */
export const filterModelsByGeoAndIndTags = (
  models: IModelHistoryInfo[],
  geoFilters: string[],
  indFilters: string[]
): IModelHistoryInfo[] => {
  const geoFilterActive = geoFilters.length > 0;
  const indFilterActive = indFilters.length > 0;
  if (geoFilterActive || indFilterActive) {
    const filtered = models.filter((model) => {
      const geoMatch = geoFilterActive ? geoFilters.indexOf(model.geography) !== -1 : true;
      const indMatch = indFilterActive ? indFilters.indexOf(model.industry) !== -1 : true;
      return geoMatch && indMatch;
    });
    return filtered;
  }
  return models;
};

type TStringFilterKeys = "companyName" | "ticker" | "geography" | "industry";

const FilterMapping: { [key: string]: TStringFilterKeys } = {
  Name: "companyName",
  Ticker: "ticker",
  Geography: "geography",
  Industry: "industry",
};

/**
 * Utility to filter models by a predicate
 */
export const filterModelsByPredicate = (
  models: IModelHistoryInfo[],
  filterSettings: IFilterTypes,
  allActive: boolean,
  predicate: string
): IModelHistoryInfo[] => {
  if (predicate) {
    const entries = Object.entries(filterSettings);
    return models.filter((model) => {
      return entries.some((filter) => {
        const key = FilterMapping[filter[0] as keyof IFilterTypes];
        if (
          (filter[1] || allActive) &&
          model[key as TStringFilterKeys].toLowerCase().includes(predicate.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
    });
  } else {
    return models;
  }
};

/**
 * Utility to merge filter states in the order they are passed.
 *
 * Deep merges the 'filters' prop.
 */
export const mergeFilterStates = (...filterStates: IFilterState[]): IFilterState => {
  return filterStates.reduce(
    (acc, curr) => {
      return {
        ...acc,
        ...curr,
        filters: { ...acc.filters, ...curr.filters },
      };
    },
    { ...InitialFilterState }
  );
};
