import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, Classes, ControlGroup, H2, Intent } from "@blueprintjs/core";

const StyledNotFound = styled.div`
  padding: 40px;
`;

const NotFound = () => {
  return (
    <StyledNotFound>
      <H2>Unable to locate page!</H2>
      <p className={Classes.RUNNING_TEXT}>
        You appear to have entered an incorrect URL. Please use the back button to return to the
        previous screen or go to the homepage.
      </p>
      <ControlGroup>
        <Link to="/">
          <Button icon="home" intent={Intent.PRIMARY} text="Go home" />
        </Link>
      </ControlGroup>
    </StyledNotFound>
  );
};

export default NotFound;
