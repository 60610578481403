import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import Auth from "@aws-amplify/auth";
import { AuthState } from "constants/appConstants";
import propTypes from "prop-types";
import {
  Button,
  Classes,
  Elevation,
  FormGroup,
  H5,
  InputGroup,
  Intent,
  Position,
  Tooltip,
} from "@blueprintjs/core";

import { cognitoErrors } from "constants/errors";
import LockButton from "components/utility/LockButton";
import { multiToaster } from "utils/toaster";
import { AuthContainer, AuthForm, AuthFormGroup } from "components/user/StyledAuthComponents";
import { Flex } from "components/utility/StyledComponents";

const Login = ({ authState, setAuthState, setUser }) => {
  /**
   * State fields - Managed internally as these are currently  not used elsewhere
   */
  const [username, setUsername] = useState("");
  const [usernameHelperText, setUsernameHelperText] = useState("");
  const [password, setPassword] = useState("");
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);

  function resetAllFieldsExceptUsername() {
    setUsernameHelperText("");
    setPassword("");
    setPasswordHelperText("");
    setShowPassword(false);
  }

  function handleLogin(e) {
    e.preventDefault();
    if (loggingIn) return;

    /** Perform very basic form validation. Reason we can't disable the Button
     * is that the prop breaks the tooltip functionality. However, the AnchorButton,
     * which is the usual solution, cannot submit forms.
     */
    if (!username || !password) {
      const message = `Missing field(s): ${
        !username ? `${!password ? "username and " : "username"}` : ""
      } ${!password ? "password." : ""}`;

      setUsernameHelperText(message);

      multiToaster.show({
        message,
        intent: Intent.WARNING,
      });
      return;
    }

    setLoggingIn(true);

    Auth.signIn(username, password)
      .then((user) => {
        setUser(user);
        handleChallenge(user.challengeName);
        setLoggingIn(false);
      })
      .catch((err) => {
        handleError(err);
        setLoggingIn(false);
      });
    // TODO - Call this when users have more properties we need to display
    // fetchUserInformation();
  }

  const handleChallenge = (challengeName) => {
    switch (challengeName) {
      case "NEW_PASSWORD_REQUIRED":
        multiToaster.show({ message: "New password required.", intent: Intent.PRIMARY });
        setAuthState(AuthState.ConfirmSignUp);
        break;
      case "SOFTWARE_TOKEN_MFA":
        multiToaster.show({ message: "Verification token required.", intent: Intent.PRIMARY });
        setAuthState(AuthState.ConfirmSignIn);
        break;
      default:
        multiToaster.show({
          message: "Successfully logged in.",
          intent: Intent.SUCCESS,
        });
        break;
    }
  };

  function handleError(error) {
    // First, we reset all fields, except the username.
    resetAllFieldsExceptUsername();
    // If the error has a code prop, we are safe to assume we're dealing with a cognito error
    const isCognitoErr = error.code;
    let message = error.message;

    if (isCognitoErr) {
      switch (error.code) {
        case cognitoErrors.USER_NOT_FOUND:
          setUsernameHelperText("User does not exist.");
          multiToaster.show({
            message,
            intent: Intent.WARNING,
          });
          break;
        case cognitoErrors.NOT_AUTHORIZED:
          setUsernameHelperText("Incorrect username or password.");
          multiToaster.show({
            message,
            intent: Intent.WARNING,
          });
          break;
        case cognitoErrors.PASSWORD_RESET_REQUIRED:
          setAuthState(AuthState.ForgotPassword);
          multiToaster.show({
            message: "New password required.",
            intent: Intent.PRIMARY,
          });
          break;
        case cognitoErrors.INVALID_PARAMETER:
          // Something bad has happened on the Cognito side.
          multiToaster.show({
            message: "Something went wrong. Please contact support.",
            intent: Intent.WARNING,
          });
          break;
        default:
          Sentry.captureException(error);
          break;
      }

      // Finally, show a toast with either the cognito provided or default message.
    }
  }

  if (authState === AuthState.SignIn) {
    const tooltipText = (
      <span>
        Field(s)&nbsp;
        {!username ? (
          <>
            <strong>username</strong> {!password ? "and" : ""}
          </>
        ) : (
          ""
        )}
        &nbsp;
        {!password ? <strong>password</strong> : ""} required.
      </span>
    );

    return (
      <AuthContainer elevation={Elevation.TWO}>
        <H5>Login</H5>
        <p className={Classes.RUNNING_TEXT}>Enter your credentials below to login.</p>
        <AuthForm
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin(e);
          }}
        >
          <AuthFormGroup
            helperText={usernameHelperText}
            intent={usernameHelperText ? Intent.WARNING : Intent.PRIMARY}
            label="Username:"
            labelFor="username"
            labelInfo="(required)"
          >
            <InputGroup
              autoComplete="username"
              type="username"
              value={username}
              onChange={(e) => setUsername(e.target.value.trim())}
            />
          </AuthFormGroup>
          <FormGroup
            helperText={passwordHelperText}
            intent={passwordHelperText ? Intent.WARNING : Intent.PRIMARY}
            label="Password:"
            labelFor="password"
            labelInfo="(required)"
          >
            <InputGroup
              autoComplete="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              rightElement={
                <LockButton
                  handleLockClick={() => setShowPassword(!showPassword)}
                  intent={Intent.PRIMARY}
                  showPassword={showPassword}
                />
              }
              type={showPassword ? "text" : "password"}
              value={password}
            />
          </FormGroup>
          <Flex fullWidth justifyContent="space-between">
            <Button
              className="forgot-password"
              intent={Intent.PRIMARY}
              minimal
              onClick={() => setAuthState(AuthState.ForgotPassword)}
              small
              text="Forgot password?"
            />
            <Tooltip
              content={tooltipText}
              disabled={username && password}
              position={Position.RIGHT}
            >
              <Button icon="log-in" intent={Intent.PRIMARY} text="Login" type="submit" />
            </Tooltip>
          </Flex>
        </AuthForm>
      </AuthContainer>
    );
  }
  return null;
};

Login.propTypes = {
  setUser: propTypes.func.isRequired,
};

Login.defaultProps = {
  onStateChange: () => {},
  authState: "signIn",
};

export default Login;
