import React from "react";
import SettingsView from "components/user/SettingsView";
import {
  Button,
  Classes,
  ControlGroup,
  EditableText,
  FormGroup,
  H4,
  HTMLSelect,
} from "@blueprintjs/core";

import useUser from "hooks/useUser";
import useListConfigs from "hooks/useListConfigs";
import useUserConfig from "hooks/useUserConfig";

const ProfileSettings = () => {
  const { user } = useUser();
  const { dashboards, isLoading } = useListConfigs();
  const { home: _home } = useUserConfig();
  return (
    <SettingsView title="Profile">
      <H4>
        <span className={Classes.TEXT_MUTED}>Full Name:&nbsp;</span>
        <EditableText disabled value={user?.fullname} />
      </H4>
      <H4>
        <span className={Classes.TEXT_MUTED}>Email:&nbsp;</span>
        {user?.email}
      </H4>
      <FormGroup label="Select Dashboard:">
        <ControlGroup>
          <HTMLSelect
            disabled={isLoading}
            options={
              dashboards
                ? dashboards.map((dashboard) => ({ label: dashboard.name, value: dashboard.id }))
                : []
            }
          />
          <Button text="Assign to Homepage" />
        </ControlGroup>
      </FormGroup>
    </SettingsView>
  );
};

export default ProfileSettings;
