import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import Auth from "@aws-amplify/auth";
import { AuthState } from "constants/appConstants";
import {
  Button,
  ButtonGroup,
  Classes,
  Elevation,
  FormGroup,
  H5,
  H6,
  InputGroup,
  Intent,
  Tag,
} from "@blueprintjs/core";

import { AuthContainer, AuthForm } from "components/user/StyledAuthComponents";
import { Flex } from "components/utility/StyledComponents";
import NewPasswordForm from "components/user/NewPasswordForm";
import { multiToaster } from "utils/toaster";

const ForgotPassword = ({ authData, setAuthState }) => {
  // Username
  const [username, setUsername] = useState("");
  const [usernameHelperText, setUsernameHelperText] = useState("");

  // Validation state
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [usernameSet, toggleUsernameSet] = useState(false);

  // Code
  const [codeDeliveryDetails, setCodeDeliveryDetails] = useState({});
  const [code, setCode] = useState("");

  // Password
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [passwordConfirmationHelperText, setPasswordConfirmationHelperText] = useState("");

  const [title, setTitle] = useState("Forgot Password");

  useEffect(() => {
    if (authData && authData.username) {
      setUsername(authData.username);
      setTitle("Password reset required. Create a new password using the form below.");
    }
  }, [authData]);

  const initiateForgotPassword = () => {
    Auth.forgotPassword(username)
      .then((res) => {
        setCodeDeliveryDetails(res.CodeDeliveryDetails);
        multiToaster.show({
          message: (
            <span>
              Verification code sent via <strong>{res.CodeDeliveryDetails.AttributeName}</strong> to{" "}
              <strong>{res.CodeDeliveryDetails.Destination}</strong>
            </span>
          ),
          intent: Intent.PRIMARY,
        });
        setTitle("Complete new password");
        toggleUsernameSet(true);
      })
      .catch((err) => {
        const error = err.message ? err.message : err;
        multiToaster.show({
          message: error,
          intent: Intent.WARNING,
        });
        setUsernameHelperText(error);
      });
  };

  const forgotPasswordSubmit = () => {
    Auth.forgotPasswordSubmit(username, code, password)
      .then(() => {
        multiToaster.show({
          message: "Password successfully changed. Please login again.",
          intent: Intent.SUCCESS,
        });
        // TODO - TOTP setup should on be on first load.
        setAuthState(AuthState.SignIn);
      })
      .catch((err) => {
        const error = err.message ? err.message : err;
        setUsernameHelperText(error);
        multiToaster.show({
          message: error,
          intent: Intent.WARNING,
        });
      });
  };

  return (
    <AuthContainer elevation={Elevation.TWO}>
      <H5>{title}</H5>
      {!usernameSet ? (
        <AuthForm
          onSubmit={(e) => {
            e.preventDefault();
            initiateForgotPassword();
          }}
        >
          <p className={Classes.RUNNING_TEXT}>Enter your username to begin.</p>
          <FormGroup
            helperText={usernameHelperText}
            intent={usernameHelperText ? Intent.WARNING : null}
            label="Username:"
            labelFor="username"
            labelInfo="(required)"
          >
            <InputGroup
              id="username"
              type="username"
              value={username}
              onChange={(e) => setUsername(e.target.value.trim())}
            />
          </FormGroup>
          <Flex flexDirection="row" justifyContent="space-between">
            <Button
              icon="circle-arrow-left"
              minimal
              onClick={() => setAuthState(AuthState.SignIn)}
              small
              intent={Intent.PRIMARY}
              text="Login"
            />
            <Button
              disabled={!username}
              text="Submit"
              icon="log-in"
              intent={Intent.PRIMARY}
              type="submit"
            />
          </Flex>
        </AuthForm>
      ) : (
        <AuthForm
          onSubmit={(e) => {
            e.preventDefault();
            forgotPasswordSubmit();
          }}
        >
          <H6>Verification code:</H6>
          <Tag intent={Intent.PRIMARY} minimal style={{ marginBottom: "10px" }}>
            Verification code sent via <strong>{codeDeliveryDetails.AttributeName}</strong> to{" "}
            <strong>{codeDeliveryDetails.Destination}.</strong>
          </Tag>
          <NewPasswordForm
            password={password}
            passwordConfirmation={passwordConfirmation}
            setPassword={setPassword}
            setPasswordConfirmation={setPasswordConfirmation}
            passwordValid={passwordValid}
            setPasswordValid={setPasswordValid}
            passwordsMatch={passwordsMatch}
            setPasswordsMatch={setPasswordsMatch}
            passwordHelperText={passwordHelperText}
            setPasswordHelperText={setPasswordHelperText}
            passwordConfirmationHelperText={passwordConfirmationHelperText}
            setPasswordConfirmationHelperText={setPasswordConfirmationHelperText}
          >
            <FormGroup label="Verification code:" labelFor="code" labelInfo="(required)">
              <InputGroup type="text" value={code} onChange={(e) => setCode(e.target.value)} />
            </FormGroup>
            <H6>Password:</H6>
          </NewPasswordForm>
          <Flex flexDirection="row" justifyContent="space-between">
            <ButtonGroup>
              <Button
                icon="circle-arrow-left"
                minimal
                onClick={() => setAuthState(AuthState.SignIn)}
                small
                intent={Intent.PRIMARY}
                text="Login"
              />
              <Button
                minimal
                intent={Intent.PRIMARY}
                small
                text="Resend code"
                onClick={initiateForgotPassword}
              />
            </ButtonGroup>
            <Button
              disabled={!passwordValid || !passwordsMatch || !code}
              text="Confirm"
              icon="confirm"
              intent={Intent.PRIMARY}
              type="submit"
            />
          </Flex>
        </AuthForm>
      )}
    </AuthContainer>
  );
};

ForgotPassword.propTypes = {
  authState: propTypes.string,
  onStateChange: propTypes.func,
};

ForgotPassword.defaultProps = {
  authState: "signIn",
  onStateChange: () => {},
};

export default ForgotPassword;
