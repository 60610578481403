import { useEffect, useState } from "react";

import api from "utils/api";
import { noop } from "utils/generic";
import { IResponse, IUnboundedConfig } from "types";

export type TCallback = ((route: string, config: IUnboundedConfig) => Promise<void>) | (() => void);

interface IHookReturn<Dt> {
  callback: TCallback;
  clear: () => void;
  fetching: boolean;
  response: IResponse<Dt> | null;
}

export const useApiCallback = <Dt>(): IHookReturn<Dt> => {
  const [callback, setCallback] = useState<TCallback>(noop);
  const [fetching, setFetching] = useState(false);
  const [response, setResponse] = useState<IResponse<Dt> | null>(null);

  const clear = () => {
    setResponse(null);
  };

  useEffect(() => {
    const callApi: TCallback =
      () =>
      async (route: string, config: IUnboundedConfig): Promise<void> => {
        setFetching(true);
        const res: IResponse<Dt> = await api<Dt>(route, config);
        setFetching(false);
        setResponse(res);
      };
    setCallback(callApi);
  }, []);

  return { callback: callback || noop, clear, fetching, response };
};
