import useSWR, { KeyedMutator } from "swr";
import { View } from "types/view";
import { Widget } from "types/widget";
import { GET_CONFIGS } from "constants/apiConstants";
import api from "utils/api";
import { IResponse } from "types";
import { useMemo } from "react";
import { Dashboard } from "types/dashboard";

export interface Configs {
  dashboards?: Dashboard[];
  views?: View[];
  widgets?: Widget[];
}

interface ConfigList {
  configs?: IResponse<Configs>;
  error: { message: string };
  isLoading: boolean;
  mutateConfigs: KeyedMutator<IResponse<Configs>>;
}

/**
 * Hook to list configurations from the configurations service.
 * */
const useListConfigs = (): ConfigList & Configs => {
  const {
    data: configs,
    error,
    isLoading,
    mutate: mutateConfigs,
  } = useSWR<IResponse<Configs>>(GET_CONFIGS, api);

  const transformedConfigs = useMemo<Configs | undefined>(() => {
    if (configs?.data) {
      return {
        dashboards: configs?.data?.dashboards
          ? configs.data.dashboards.map((dashboard) => {
              return {
                ...dashboard,
                views: dashboard.views,
              };
            })
          : undefined,
        views: configs.data.views,
        widgets: configs.data.widgets,
      };
    }
  }, [configs]);

  return {
    configs,
    dashboards: transformedConfigs?.dashboards,
    error,
    isLoading,
    mutateConfigs,
    views: transformedConfigs?.views,
    widgets: transformedConfigs?.widgets,
  };
};

export default useListConfigs;
