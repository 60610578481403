import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { Flex, FullWidthDivider } from "components/utility/StyledComponents";

export interface IListItem {
  id: string;
  name: string;
  description?: string;
  date?: Date;
}

export interface IListProps {
  activeItemId?: string;
  className?: string;
  emptyMessage?: string;
  fullHeight?: boolean;
  items: IListItem[];
  onSelect?: (item: IListItem) => void;
  sortBy?: keyof IListItem;
}

interface IListItemProps {
  active: boolean;
  className?: string;
}

const Main = styled(Flex)`
  min-height: 0;
`;

const ItemDivider = styled(FullWidthDivider)`
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const Body = styled.div`
  flex-grow: 1;
  min-height: 0;
  overflow-y: auto;
`;

const Item = styled.div<IListItemProps>`
  padding: 8px 12px;
  cursor: pointer;

  ${({ active }) =>
    active
      ? null
      : css`
          & {
            background: transparent !important;
          }
        `}
`;

const Name = styled.div`
  font-weight: bold;
`;

const Desc = styled.div`
  font-size: 12px;
  margin-top: 4px;
`;

const Empty = styled.div`
  text-align: center;
  padding: 12px;
`;

const List: React.FC<IListProps> = ({
  className,
  fullHeight = false,
  items,
  onSelect,
  activeItemId,
  emptyMessage,
  sortBy,
}) => {
  const listItems: JSX.Element[] = useMemo(() => {
    let listItems = items;
    if (sortBy) {
      listItems = items.sort((itemA, itemB) => {
        const a = itemA[sortBy];
        const b = itemB[sortBy];
        if (!a || !b) return 1;
        if (a instanceof Date && b instanceof Date) return a.valueOf() - b.valueOf();
        if (typeof a === "string" && typeof b === "string")
          return a.localeCompare(b, undefined, { sensitivity: "accent" });
        else return 1;
      });
    }
    return listItems.map((item) => (
      <div key={item.id}>
        <Item
          className="bp5-callout"
          onClick={() => {
            if (onSelect) onSelect(item);
          }}
          active={item.id === activeItemId}
        >
          <Name>{item.name}</Name>
          {item.description && <Desc>{item.description}</Desc>}
        </Item>
        <ItemDivider style={{ visibility: item.id === activeItemId ? "visible" : "hidden" }} />
      </div>
    ));
  }, [activeItemId, items, onSelect, sortBy]);

  return (
    <Main flexDirection="column" alignItems="stretch" fullHeight={fullHeight} className={className}>
      <Body>
        {items.length === 0 ? (
          <Empty>{emptyMessage}</Empty>
        ) : (
          listItems.map((component, index) => (
            <React.Fragment key={index}>{component}</React.Fragment>
          ))
        )}
      </Body>
    </Main>
  );
};

export default List;
