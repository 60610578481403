import React, { memo, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Divider,
  Intent,
  KeyComboTag,
  Menu,
  MenuItem,
  Spinner,
  SpinnerSize,
} from "@blueprintjs/core";
import { FETCH_WIKI_LINK_ROUTE, EMethods, Status } from "constants/apiConstants";
import { addQuickCalculation } from "actions/dataEntryActions";
import { currentCaseSelector, currentModuleSelector } from "selectors/modelSelectors";
import { defaultFormatSelector } from "selectors/dataEntryUISelectors";
import { focusedCellSelector } from "selectors/dataEntrySelectors";
import { f } from "utils/formatter";
import { buildCellFormattingObject, navToCell } from "utils/modelGrid";
import { isNeg } from "utils/generic";
import { api } from "utils/api";

const GridContextMenu = styled.div`
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);
  border-radius: 3px;
  position: absolute;
  width: 310px;
`;

const DataEntryTableContextMenu = ({
  close,
  copy,
  pos,
  currentCase,
  defaultFormats,
  deleteLineItem,
  facts,
  focusedCell,
  lineItems,
  locale,
  locked,
  modules,
  paste,
  sources,
  unit,
}) => {
  const fact = focusedCell.fact;
  const lineItem = lineItems[fact.parentUid];
  const source = sources?.find((source) => source.period === fact.period);

  const navigate = useNavigate();
  const [fetchingWikiLink, setFetchingWikiLink] = useState(false);
  const [wikiLink, setWikiLink] = useState("");
  const [menuPosition, setMenuPosition] = useState(null);
  const contextMenuRef = useRef();

  // Position the menu
  useEffect(() => {
    if (!contextMenuRef.current?.clientHeight) return;
    let x = pos.posX;
    let y = pos.posY;
    // render left to avoid going beyond XX edge of screen
    if (pos.posClientX + contextMenuRef.current.clientWidth > window.innerWidth) {
      x -= contextMenuRef?.current.clientWidth - 20;
    }
    // render above to avoid going beyond YY edge of screen
    if (pos.posClientY + contextMenuRef.current.clientHeight > window.innerHeight) {
      y -= contextMenuRef?.current.clientHeight;
    }
    setMenuPosition({ x, y });
  }, [pos]);

  /**
   * Fetch wiki link
   */
  useEffect(() => {
    setFetchingWikiLink(true);
    (async function callApi() {
      const res = await api({
        method: EMethods.POST,
        route: FETCH_WIKI_LINK_ROUTE,
        body: { tags: lineItem.tags },
      });
      if (res.status === Status.SUCCESS) {
        setWikiLink(res.data);
      }
      setFetchingWikiLink(false);
    })();
  }, [lineItem.tags]);

  const getRefName = (fact) => {
    if (!fact) return null;
    const lineItem = lineItems[fact.parentUid];
    const module = modules[fact.moduleUid];
    return module.name + " > " + lineItem.name + " > " + fact.period;
  };

  const getRefValue = (fact) => {
    const precFact = { ...fact };
    if (!precFact) return null;
    const lineItem = lineItems[precFact.parentUid];
    let isNegative = isNeg(precFact.value);
    if (isNegative) precFact.value *= -1;
    const factFormats = buildCellFormattingObject(precFact.format, defaultFormats);
    let formattedVal = f({ ...precFact, ...factFormats, type: lineItem.type }, locale, unit);
    if (isNegative) formattedVal = "-" + formattedVal;
    return formattedVal;
  };

  return (
    <GridContextMenu ref={contextMenuRef} style={{ top: menuPosition?.y, left: menuPosition?.x }}>
      <Menu>
        <MenuItem
          text="Copy"
          icon="duplicate"
          onClick={copy}
          labelElement={<KeyComboTag combo="meta + c" />}
        />
        <MenuItem
          text="Paste"
          disabled={locked}
          icon="clipboard"
          onClick={paste}
          labelElement={<KeyComboTag combo="meta + v" />}
        />
        <Divider />
        <MenuItem
          text="Delete line item"
          disabled={locked}
          icon="delete"
          onClick={deleteLineItem}
          labelElement={<KeyComboTag combo="meta + alt + -" />}
        />
        <Divider />
        {/* TODO: re-add these when they are re-added on the server.
        <MenuItem text="Add quick calculation" disabled={locked}>
          <Divider title={lineItem.name} />
          {quickCalcs ? (
            quickCalcs.map((calc) => (
              <MenuItem
                key={calc.function}
                onClick={() => {
                  addQuickCalculation(currentCase.uid, currentModule.uid, lineItem.uid, calc);
                }}
                text={calc.function}
              />
            ))
          ) : (
            <MenuItem loading />
          )}
        </MenuItem>
        <Divider /> */}
        <MenuItem
          icon="data-lineage"
          text="Trace precedents"
          popoverProps={{ hoverCloseDelay: 500 }}
        >
          {fact.precedentCells?.length ? (
            fact.precedentCells.flat().map((prec, i) => {
              const fact = facts[prec.uid];
              if (!fact) return null;
              return !fact.error ? (
                <MenuItem
                  key={i}
                  text={getRefName(fact)}
                  label={getRefValue(fact)}
                  onClick={() => {
                    close();
                    navToCell(fact, modules, navigate);
                  }}
                />
              ) : (
                <MenuItem
                  key={i}
                  icon="error"
                  intent={Intent.DANGER}
                  text={getRefName(fact)}
                  label={getRefValue(fact)}
                  onClick={() => {
                    close();
                    navToCell(fact, modules, navigate);
                  }}
                />
              );
            })
          ) : (
            <MenuItem disabled text="No entries" />
          )}
        </MenuItem>
        <MenuItem
          icon="inheritance"
          text="Trace dependents"
          popoverProps={{ hoverCloseDelay: 500 }}
        >
          {fact.dependantCells?.length ? (
            fact.dependantCells.map((dep, i) => {
              const depFact = facts[dep.uid];
              if (!depFact) return null;
              return !depFact.error ? (
                <MenuItem
                  key={i}
                  text={getRefName(depFact)}
                  label={getRefValue(depFact)}
                  onClick={() => {
                    close();
                    navToCell(depFact, modules, navigate);
                  }}
                />
              ) : (
                <MenuItem
                  key={i}
                  icon="error"
                  intent={Intent.DANGER}
                  text={getRefName(depFact)}
                  label={getRefValue(depFact)}
                  onClick={() => {
                    close();
                    navToCell(depFact, modules, navigate);
                  }}
                />
              );
            })
          ) : (
            <MenuItem disabled text="No entries" />
          )}
        </MenuItem>
        <Divider />
        {source && (
          <MenuItem
            icon="document-open"
            text="View source in New Tab"
            onClick={() => {
              window.open(source.url, "_blank");
            }}
          />
        )}
        {fact.period <= currentCase.startPeriod && (
          <MenuItem
            disabled
            icon="search-template"
            text="View source in side menu"
            // onClick={
            // TODO: Get source view back in the side menu
            // }
          />
        )}
        <MenuItem
          disabled={fetchingWikiLink || !wikiLink}
          href={wikiLink}
          icon={fetchingWikiLink ? <Spinner size={SpinnerSize.SMALL} /> : "help"}
          target="_blank"
          text="More information"
        />
      </Menu>
    </GridContextMenu>
  );
};

function mapStateToProps(state) {
  return {
    currentCase: currentCaseSelector(state),
    currentModule: currentModuleSelector(state),
    defaultFormats: defaultFormatSelector(state),
    facts: state.model.facts,
    focusedCell: focusedCellSelector(state),
    lineItems: state.model.lineItems,
    modules: state.model.modules,
    locale: state.ui.global.localeString,
    metaKey: state.ui.global.metaKey,
    quickCalcs: state.dataEntry.data.quickCalcs,
    sources: state.model.sources,
    unit: state.ui.global.unit,
  };
}

const mapDispatchToProps = {
  addQuickCalculation,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(DataEntryTableContextMenu));
