import {
  Button,
  Callout,
  Code,
  EditableText,
  Intent,
  NonIdealState,
  Popover,
  Position,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import LoadingIndicator from "components/utility/LoadingIndicator";
import { Flex, Grid, NormalAlignDivider, PaddedContent } from "components/utility/StyledComponents";
import {
  StyledWidgetScrollContainer,
  StyledWidgetWrapper,
} from "components/utility/StyledDashboardComponents";
import { EStatus, GET_WIDGETS, VIEW_ROUTE } from "constants/apiConstants";
import AppContext from "context/appContext";
import HighchartsReact from "highcharts-react-official";
import useVSLSelector from "hooks/dashboard/useVSLSelector";
import useWidget from "hooks/dashboard/useWidget";
import useVSL from "hooks/useVSL";
import isFunction from "lodash.isfunction";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { KeyedMutator, mutate as globalMutate } from "swr/_internal";
import { ESortDirection, IResponse } from "types";
import { ViewWidget } from "types/view";
import {
  EWidgetType2,
  IVSLConfig,
  IVSLTable,
  SelectorOption,
  VSLBodyContextSelector,
  VSLResponse,
} from "types/vsl";
import { ChartWidgetConfig, TableWidgetConfig, Widget as TWidget } from "types/widget";
import { getSelectorOptions, parseWidgetTitleVars } from "utils/vsl";
import CopyWidgetDialog from "components/dashboard/widget/CopyWidgetDialog";
import EditWidgetDialog from "components/dashboard/widget/EditWidgetDialog";
import WidgetTitleMenu from "components/dashboard/widget/WidgetTitleMenu";
import EditWidgetSelectorsDialog from "components/dashboard/libraries/EditWidgetSelectorsDialog";
import { TableFilters } from "components/table/TableFilters";
import { WidgetSelectorsBar } from "components/dashboard/widget/WidgetSelectorsBar";
import TableWidget from "components/dashboard/widget/table/TableWidget";
import ChartWidget from "components/dashboard/widget/chart/ChartWidget";

interface WidgetProps {
  bordered?: boolean;
  enableTableColumnConfigEditing?: boolean;
  enableEditableSelectors?: boolean;
  enableEditWidgetDialog?: boolean;
  enableTableSort?: boolean;
  enableTitleMenu?: boolean;
  injectedSelectors?: Record<string, SelectorOption[]>;
  locked?: boolean;
  onRemove?: (widget: TWidget) => void;
  savingWidget?: boolean;
  showControlRibbon?: boolean;
  showTitleRibbon?: boolean;
  updateWidget?: (details: Partial<ViewWidget>) => Promise<boolean>;
  viewId?: string; // An optional viewId. If supplied, the widget will refetch its View context when it is edited.
  widget: TWidget;
}

export interface WidgetRef {
  rerunVSLQuery: KeyedMutator<IResponse<VSLResponse>>;
}

const WIDGET_BOTTOM_CONTROL_RIBBON_HEIGHT = "35px";

/**
 * A VSL Widget. Renders selectors, top and bottom control ribbons with Widget content specific controls.
 */
const Widget = forwardRef<WidgetRef, WidgetProps>(
  (
    {
      bordered = true,
      enableTableColumnConfigEditing = true,
      enableEditableSelectors = true,
      enableEditWidgetDialog = true,
      enableTableSort = true,
      enableTitleMenu = true,
      injectedSelectors,
      locked = false,
      onRemove,
      savingWidget,
      showControlRibbon = true,
      showTitleRibbon = true,
      updateWidget,
      viewId,
      widget,
    },
    ref
  ) => {
    const {
      config: { theme },
    } = useContext(AppContext);
    const [copyWidgetDialogOpen, setCopyWidgetDialogOpen] = useState(false);
    const [editWidgetDialogOpen, setEditWidgetDialogOpen] = useState(false);
    const [selectorsDialogOpen, setSelectorsDialogOpen] = useState(false);
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const { copyWidgetToView } = useWidget(widget?.id);

    const updateWidgetConfigCallback = useCallback(
      async (details: Partial<TableWidgetConfig | ChartWidgetConfig>) => {
        if (isFunction(updateWidget))
          return await updateWidget({ config: { ...widget?.config, ...details } });
        return false;
      },
      [updateWidget, widget?.config]
    );
    const updateWidgetConfig = isFunction(updateWidget) ? updateWidgetConfigCallback : undefined;

    const activeSelectors: Record<string, SelectorOption[]> = useMemo(() => {
      return {
        ...getSelectorOptions(widget.selectors),
        ...(injectedSelectors || {}),
      };
    }, [widget.selectors, injectedSelectors]);

    const copyToCurrentView = useCallback(async () => {
      if (viewId) {
        const response = await copyWidgetToView(viewId);
        if (response.status === EStatus.success) {
          await globalMutate([GET_WIDGETS, { viewId }]);
          await globalMutate([VIEW_ROUTE, { viewId }]);
        }
      }
    }, [copyWidgetToView, viewId]);

    /** VSL QUERY */
    const vslConfig = useMemo(() => {
      const newConfig: IVSLConfig = {
        query: widget?.query?.replaceAll("\n", "") || "",
        context: {
          selectors: Object.keys(activeSelectors).reduce((acc: VSLBodyContextSelector[], key) => {
            const widgetSelector = widget.selectors?.find((sel) => sel.id === key);
            acc.push({
              id: key,
              selected_options: activeSelectors[key]?.map((selOpt) => selOpt?.value),
              type: widgetSelector?.type || "",
              source_id: widgetSelector?.source_id || "",
            });
            return acc;
          }, []),
        },
        widgetId: widget?.id,
        widgetName: widget?.name,
      };
      if ((widget.config as TableWidgetConfig)?.sort) {
        newConfig.sortBy = (widget.config as TableWidgetConfig).sort?.sortBy;
        newConfig.sortDirection =
          (widget.config as TableWidgetConfig).sort?.sortDirection === "asc"
            ? ESortDirection.ASC
            : ESortDirection.DESC;
      }
      return newConfig;
    }, [activeSelectors, widget]);

    const { error, loading, mutate: mutateVSL, data } = useVSL(vslConfig);

    useImperativeHandle(ref, () => {
      return {
        rerunVSLQuery: mutateVSL,
      };
    });

    /** Fetch selectors options */
    const { loading: loadingSelectorsOptions } = useVSLSelector(widget?.selectors, {
      widgetId: widget.id,
      viewId: viewId,
    });

    /** RENDER WIDGET CONTENT */
    const renderedWidget = useMemo(() => {
      if (!data || !data.data || error || !widget?.id) return null;
      switch (data.widgetType) {
        case EWidgetType2.TABLE: {
          return (
            <TableWidget
              enableTableColumnConfigEditing={enableTableColumnConfigEditing}
              enableTableSort={enableTableSort}
              showControlRibbon={showControlRibbon}
              updateWidgetConfig={updateWidgetConfig}
              widget={widget}
              config={widget.config as TableWidgetConfig}
              error={error}
              loading={loading}
              data={data}
            />
          );
        }

        case EWidgetType2.LINE_CHART:
        case EWidgetType2.BAR_CHART:
        case EWidgetType2.SCATTER_CHART:
        case EWidgetType2.PIE_CHART:
          return (
            <ChartWidget
              updateWidget={updateWidget}
              updateWidgetConfig={updateWidgetConfig}
              showControlRibbon={showControlRibbon}
              locked={locked}
              widget={widget}
              config={widget.config as ChartWidgetConfig}
              error={error}
              loading={loading}
              data={data}
              chartComponentRef={chartComponentRef}
            />
          );
        default:
          return (
            <PaddedContent padding="10px">
              <Callout title="Unsupported Widget type!" intent={Intent.DANGER}>
                The supplied Widget type <Code>{data.widgetType}</Code> is currently unsupported by
                this version of the client. You may need to edit the VSL query associated with this
                Widget.
                <br />
                <br />
                <Button
                  icon={IconNames.COG}
                  onClick={() => setEditWidgetDialogOpen(true)}
                  text="Edit Widget"
                />
              </Callout>
            </PaddedContent>
          );
      }
    }, [
      data,
      error,
      loading,
      enableTableColumnConfigEditing,
      enableTableSort,
      locked,
      showControlRibbon,
      updateWidget,
      updateWidgetConfig,
      widget,
    ]);

    let mainContent: JSX.Element | null = null;

    if (error) {
      if (loadingSelectorsOptions || error.message.includes("failed to import identifier")) {
        mainContent = (
          <StyledWidgetScrollContainer
            $extraPadding={!!(loading || error || !widget?.query)}
            $theme={theme}
          >
            <NonIdealState title="Missing selector options." />
          </StyledWidgetScrollContainer>
        );
      } else {
        mainContent = (
          <StyledWidgetScrollContainer
            $extraPadding={!!(loading || error || !widget?.query)}
            $theme={theme}
          >
            <Callout intent={Intent.WARNING} title="Widget Error!">
              Error: <Code>{error.message}</Code>
              <br />
              Query: <Code>{widget?.query}</Code>
            </Callout>
          </StyledWidgetScrollContainer>
        );
      }
    } else if (loading && !data) {
      mainContent = (
        <StyledWidgetScrollContainer
          $extraPadding={!!(loading || error || !widget?.query)}
          $theme={theme}
        >
          <LoadingIndicator
            loading
            status={{ intent: Intent.PRIMARY, message: "Loading Widget content..." }}
          />
        </StyledWidgetScrollContainer>
      );
    } else if (!widget?.query) {
      mainContent = (
        <StyledWidgetScrollContainer
          $extraPadding={!!(loading || error || !widget?.query)}
          $theme={theme}
        >
          <NonIdealState
            action={
              enableEditWidgetDialog ? undefined : (
                <Button
                  icon={IconNames.COG}
                  onClick={() => setEditWidgetDialogOpen(true)}
                  text="Edit Widget"
                />
              )
            }
            description="This Widget has no VSL query associated with it and as such will not show any content. Edit the Widget to add a query."
            icon={IconNames.DATABASE}
            title="No VSL query supplied to Widget."
          />
        </StyledWidgetScrollContainer>
      );
    } else if (!data?.data) {
      mainContent = (
        <StyledWidgetScrollContainer
          $extraPadding={!!(loading || error || !widget?.query)}
          $theme={theme}
        >
          <NonIdealState title="No data available" />
        </StyledWidgetScrollContainer>
      );
    } else if (renderedWidget) {
      mainContent = renderedWidget;
    } else {
      return null;
    }

    const hasSelectors = (widget?.selectors ?? []).some((selector) => !selector.hidden);

    let rows = "1fr";
    if (showTitleRibbon) rows = "auto " + rows;
    if (hasSelectors) rows = "auto " + rows;
    if (data?.widgetType !== EWidgetType2.TABLE && updateWidgetConfig) rows = "auto " + rows; // chart top control ribbon
    if (showControlRibbon) rows += ` ${WIDGET_BOTTOM_CONTROL_RIBBON_HEIGHT}`;

    const widgetTitle = parseWidgetTitleVars(
      widget.name,
      Object.entries(activeSelectors).map(([key, value]) => ({
        id: key,
        selected_options: value,
      })) ?? []
    );

    return (
      <StyledWidgetWrapper $bordered={bordered} $theme={theme}>
        {editWidgetDialogOpen ? (
          <EditWidgetDialog
            isOpen={editWidgetDialogOpen}
            onClose={() => setEditWidgetDialogOpen(false)}
            saving={savingWidget}
            updateWidget={updateWidget}
            viewId={viewId}
            widget={widget}
          />
        ) : null}
        {selectorsDialogOpen ? (
          <EditWidgetSelectorsDialog
            onClose={() => setSelectorsDialogOpen(false)}
            isOpen={selectorsDialogOpen}
            name={widgetTitle}
            selectors={widget.selectors}
            updateWidget={updateWidget}
            queries={[widget.query || ""]}
          />
        ) : null}
        {copyWidgetDialogOpen ? (
          <CopyWidgetDialog
            isOpen={copyWidgetDialogOpen}
            onClose={() => setCopyWidgetDialogOpen(false)}
            widget={widget}
          />
        ) : null}
        <Grid
          cols="1fr"
          rows={rows}
          fullHeight
          fullWidth
          style={{ borderRadius: 4, overflow: "hidden" }}
        >
          {showTitleRibbon ? (
            <Flex
              alignItems="flex-start"
              flexDirection="column"
              fullWidth
              justifyContent="flex-start"
              style={{ overflow: "hidden" }}
            >
              <Flex
                fullWidth
                alignItems="center"
                justifyContent="space-between"
                style={{ minWidth: 0, padding: "5px 10px" }}
              >
                <Flex flex="0 1 auto" justifyContent="flex-start" style={{ minWidth: 100 }}>
                  <span
                    style={{
                      fontWeight: 500,
                      overflow: "hidden",
                      padding: 2,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <EditableText
                      defaultValue={widgetTitle}
                      maxLength={80}
                      onConfirm={(nextValue) => updateWidget?.({ name: nextValue })}
                      key={widget.name}
                    />
                  </span>
                  {enableTitleMenu && (
                    <Popover
                      content={
                        <WidgetTitleMenu
                          chartComponentRef={chartComponentRef}
                          copyToCurrentView={copyToCurrentView}
                          data={data}
                          enableEditWidgetDialog={enableEditWidgetDialog}
                          inView={!!viewId}
                          onRemove={onRemove}
                          setCopyWidgetDialogOpen={setCopyWidgetDialogOpen}
                          setEditWidgetDialogOpen={setEditWidgetDialogOpen}
                          setSelectorsDialogOpen={setSelectorsDialogOpen}
                          widget={widget}
                        />
                      }
                      minimal={true}
                      position={Position.BOTTOM}
                    >
                      <Button
                        disabled={locked}
                        minimal
                        rightIcon={IconNames.MENU}
                        style={{ flexShrink: 0 }}
                      />
                    </Popover>
                  )}
                </Flex>
                {widget?.widgetType === EWidgetType2.TABLE && (
                  <TableFilters
                    data={data?.data as IVSLTable}
                    config={widget?.config as TableWidgetConfig}
                    updateWidget={updateWidget}
                  />
                )}
              </Flex>
              <NormalAlignDivider style={{ margin: 0 }} />
            </Flex>
          ) : null}
          <WidgetSelectorsBar
            widget={widget}
            enableEditableSelectors={enableEditableSelectors}
            injectedSelectors={injectedSelectors}
            locked={locked}
            updateWidget={updateWidget}
            viewId={viewId}
            setSelectorsDialogOpen={setSelectorsDialogOpen}
          />
          {mainContent}
        </Grid>
      </StyledWidgetWrapper>
    );
  }
);

Widget.displayName = "Widget";

export default Widget;
