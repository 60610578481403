import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Classes, H3, Intent, Tag } from "@blueprintjs/core";

import { currentModuleSelector } from "selectors/modelSelectors";
import { currentKeyRatiosSelector, currentStatementSelector } from "selectors/dataEntrySelectors";
import { fetchKeyRatioData } from "actions/dataEntryActions";

import {
  FullWidthHTMLTable,
  PaddedContent,
  stickinize,
  StickyTd,
  StickyTh,
} from "components/utility/StyledComponents";
import ModuleSpinner from "components/dashboard/ModuleSpinner";
import { isOdd } from "utils/generic";
import AppContext from "../../context/appContext";

const StickyH3 = stickinize(H3);

const KeyRatios = ({ currentModule, currentStatement, fetchKeyRatioData, keyRatios }) => {
  const [scroll, setScroll] = useState(0);
  const {
    config: { theme },
  } = useContext(AppContext);

  useEffect(() => {
    if (!currentModule || !currentStatement) return;
    fetchKeyRatioData(currentModule.uid, currentStatement);
  }, [currentModule, currentStatement, fetchKeyRatioData]);

  const handleScroll = (e) => {
    setScroll(e.target.scrollLeft);
  };

  if (keyRatios?.name) {
    return (
      <div
        onScroll={handleScroll}
        style={{ height: "100%", textAlign: "left", overflowY: "scroll" }}
      >
        <StickyH3 left={0} theme={theme}>
          <span className={Classes.TEXT_MUTED}>Key Ratios:&nbsp;</span>
          {keyRatios.name}
        </StickyH3>
        <FullWidthHTMLTable striped>
          <thead>
            <tr>
              <StickyTh theme={theme} scroll={scroll}>
                Type
              </StickyTh>
              {keyRatios.periods.map((period) => (
                <th key={period}>{period}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {keyRatios.lineItems.map((lineItem, i) => (
              <tr key={lineItem.name}>
                <StickyTd odd={isOdd(i)} scroll={scroll} left={0} theme={theme}>
                  {lineItem.name}
                </StickyTd>
                {lineItem.facts.map((f) => (
                  <td key={f.period}>{f.value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </FullWidthHTMLTable>
      </div>
    );
  }
  if (keyRatios) {
    return (
      <PaddedContent>
        <H3 className={Classes.TEXT_MUTED}>Key Ratios</H3>
        <Tag large minimal intent={Intent.WARNING}>
          Key ratios for {currentStatement} not available!
        </Tag>
      </PaddedContent>
    );
  }
  return <ModuleSpinner />;
};

function mapStateToProps(state) {
  return {
    currentModule: currentModuleSelector(state),
    currentStatement: currentStatementSelector(state),
    keyRatios: currentKeyRatiosSelector(state),
  };
}

const mapDispatchToProps = {
  fetchKeyRatioData,
};

export default connect(mapStateToProps, mapDispatchToProps)(KeyRatios);
