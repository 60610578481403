import React, { useCallback, useContext, useMemo, useState } from "react";
import { IconNames } from "@blueprintjs/icons";
import {
  AnchorButton,
  Button,
  Callout,
  Classes,
  ControlGroup,
  Intent,
  Spinner,
  SpinnerSize,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import {
  ColumnSizingState,
  OnChangeFn,
  PaginationState,
  SortingState,
  Updater,
} from "@tanstack/react-table";
import { IModelHistoryTable, TModelHistoryTablePartialModel } from "types/modelHistory";
import { TableWidgetColumnConfig } from "types/widget";
import ModelHistoryContext from "context/modelHistoryContext";
import { transformModelsByColumnTemplate } from "utils/modelHistoryUtils";
import DeleteModelsDialog from "components/table/DeleteModelsDialog";
import ShareModelDialog from "components/model/ShareModelDialog";
import { Flex, styledScrollBar } from "components/utility/StyledComponents";
import ModelFilters from "components/table/ModelFilters";
import EditModelTagDialog from "components/utility/EditModelTagDialog";
import EditModelTableDialog from "components/table/EditModelTableDialog";
import useCopyModel from "hooks/useCopyModel";
import useModelHistory2 from "hooks/useModelHistory2";
import { mergeFilterStates } from "utils/modelFilterUtils";
import { Status } from "constants/apiConstants";
import { ESortDirection } from "types";
import isFunction from "lodash.isfunction";
import Table from "components/table/Table";
import styled from "styled-components";
import AppContext from "context/appContext";
import { useDebouncedCallback } from "hooks/useDebounce";

export interface IModelHistoryTableState {
  allModelsSelected?: boolean;
  editColumns: (nextColumns: TableWidgetColumnConfig[]) => void;
  editKey: "home" | "modelTables";
  editTableProperties: (edit: Partial<IModelHistoryTable>) => void;
  modelControlsActive?: boolean;
  modelTable: IModelHistoryTable;
  selectedModels?: TModelHistoryTablePartialModel[];
  setAllSelected?: () => void;
}

/**
 * A component that renders a model table. And set of model history filters.
 */
const ModelHistoryTable: React.FC<IModelHistoryTableState> = ({
  editColumns,
  editTableProperties,
  editKey, // Used for editing tables via the editor
  modelControlsActive = true,
  modelTable,
}) => {
  const { columns, desc, filters, modelType, name, sortType, tagFilters, uid } = modelTable;

  const {
    config: { theme },
  } = useContext(AppContext);

  const [editTagDialogOpen, setEditTagDialogOpen] = useState(false);
  const [editingModel, setEditingModel] = useState<TModelHistoryTablePartialModel | undefined>();
  const [pinnedCols, _setPinnedCols] = useState(1);
  const [advancedFiltersEnabled, setAdvancedFiltersEnabled] = useState(false);
  const [filterState, setFilterState] = useState(filters);
  const [sortState, setSortState] = useState<SortingState>(
    sortType && desc ? [{ id: sortType, desc: desc === ESortDirection.DESC ? true : false }] : []
  );
  const [activeTagFilters, setActiveTagFilters] = useState<string[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  // TODO: Re-include model table editing in DSL paradigm
  const [editTableDialogOpen, setEditTableDialogOpen] = useState(false);

  const { selectedModels: ctxSelectedModels, setSelectedModels } = useContext(ModelHistoryContext);

  const setSorting: OnChangeFn<SortingState> = useCallback(
    (nextSortState: Updater<SortingState>) => {
      setSortState(nextSortState);
      const sort = isFunction(nextSortState) ? nextSortState(sortState) : sortState;
      if (sort[0]) {
        editTableProperties({
          sortType: sort[0].id,
          desc: sort[0]?.desc ? ESortDirection.DESC : ESortDirection.ASC,
        });
      }
    },
    [editTableProperties, sortState]
  );

  const onColumnSizingChange = useCallback(
    (details: ColumnSizingState) => {
      const nextColumns = columns.map((col) => {
        if (details[col.id]) {
          return { ...col, width: details[col.id] };
        }
        return col;
      });
      editTableProperties({
        columns: nextColumns,
      });
    },
    [columns, editTableProperties]
  );

  const debouncedOncolumnsizingChange = useDebouncedCallback(onColumnSizingChange);

  const selectedModels = ctxSelectedModels?.[modelTable.uid];

  const setSelected = useCallback(
    (nextModels: TModelHistoryTablePartialModel[]) => {
      setSelectedModels({
        ...ctxSelectedModels,
        [modelTable.uid]: nextModels,
      });
    },
    [ctxSelectedModels, modelTable.uid, setSelectedModels]
  );

  const [paginationState, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const _onPaginationChange: OnChangeFn<PaginationState> = useCallback((nextPaginationState) => {
    setPaginationState(nextPaginationState);
  }, []);

  const modelHistoryConfig = useMemo(() => {
    return {
      fields: modelTable.columns.map((col) => col.id),
      filterConfig: mergeFilterStates(filters, {
        ...filterState,
        tagFilters: [...activeTagFilters, ...(tagFilters ? tagFilters : [])],
      }),
      modelType,
      pagination: paginationState.pageIndex + 1,
      sortBy: sortState[0]?.id,
      sortDirection: sortState[0]?.desc ? ESortDirection.DESC : ESortDirection.ASC,
      tableID: uid,
    };
  }, [
    activeTagFilters,
    filters,
    filterState,
    modelTable.columns,
    modelType,
    paginationState,
    sortState,
    tagFilters,
    uid,
  ]);

  const modelHistory = useModelHistory2(modelHistoryConfig);
  const { loading, models, mutate, response, totalModels } = modelHistory;

  const { copyModel } = useCopyModel({ mutate, data: response });

  const onTagEdit = useCallback((modelInfo: TModelHistoryTablePartialModel) => {
    setEditingModel(modelInfo);
    setEditTagDialogOpen(true);
  }, []);

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const openShareDialog = () => {
    setShareDialogOpen(true);
  };

  const copyHandler = () => {
    if (selectedModels.length > 0) {
      const model = selectedModels[0];
      copyModel(model);
    }
  };

  const maybeTransformedModels = useMemo(() => {
    return columns && models ? transformModelsByColumnTemplate(models, columns) : models;
  }, [columns, models]);

  const excludeFromLoadingCols = useMemo(() => ({ companyName: true, ticker: true }), []);

  const recalculatingModelIndexes = useMemo(() => {
    const idxs: number[] = [];
    maybeTransformedModels?.forEach((m, i) => {
      if (m.recalculating) idxs.push(i);
    });
    return idxs;
  }, [maybeTransformedModels]);

  if (!loading && totalModels === 0) {
    const title = modelTable.modelType === "User" ? "No private models." : "No models in table.";
    return (
      <Callout intent={Intent.PRIMARY} title={title}>
        <p className={Classes.RUNNING_TEXT}>
          {modelTable.modelType === "User"
            ? "You do not have any private models associated with your user."
            : "There are no models in the selected table."}
        </p>
      </Callout>
    );
  }

  return (
    <Flex alignItems="center" flexDirection="column" fullHeight justifyContent="flex-start">
      {modelControlsActive ? (
        <>
          <DeleteModelsDialog
            isOpen={deleteDialogOpen}
            modelHistoryResponse={response}
            mutator={mutate}
            setDeleteModelsDialogOpen={setDeleteDialogOpen}
            setSelectedModels={setSelected}
            toDelete={selectedModels}
          />
          <ShareModelDialog
            isOpen={shareDialogOpen}
            setOpen={setShareDialogOpen}
            modelInfo={selectedModels?.[0]}
          />
        </>
      ) : null}
      <EditModelTagDialog
        model={editingModel}
        modelTableName={name}
        modelHistoryResponse={response}
        modelHistoryMutator={mutate}
        isOpen={editTagDialogOpen}
        setEditTagDialogOpen={setEditTagDialogOpen}
        setModel={setEditingModel}
      />
      <EditModelTableDialog
        editKey={editKey}
        isOpen={editTableDialogOpen}
        modelTable={modelTable}
        setDialogOpen={setEditTableDialogOpen}
        sampleModel={modelHistory.models?.[0] || null}
      />
      <Flex
        alignItems="flex-start"
        flexDirection="column"
        flexGrow="0"
        fullWidth
        justifyContent="flex-start"
      >
        <Flex flex="1 0 auto" fullWidth justifyContent="flex-end">
          {modelControlsActive ? (
            <ControlGroup style={{ marginBottom: 10 }}>
              <Tooltip
                disabled={!selectedModels || selectedModels.length === 1}
                content="Only one model can be shared/duplicated at a time"
                openOnTargetFocus={false}
              >
                <AnchorButton
                  disabled={!selectedModels || selectedModels.length !== 1}
                  icon="share"
                  onClick={openShareDialog}
                  text="Share Model"
                />
              </Tooltip>
              <Tooltip
                disabled={!selectedModels || selectedModels.length === 1}
                content="Only one model can be shared/duplicated at a time"
                openOnTargetFocus={false}
              >
                <AnchorButton
                  disabled={!selectedModels || selectedModels.length !== 1}
                  icon="duplicate"
                  onClick={copyHandler}
                  text="Duplicate Model"
                />
              </Tooltip>
              <Button
                disabled={!selectedModels || selectedModels.length < 1}
                icon="delete"
                intent={Intent.DANGER}
                onClick={openDeleteDialog}
                text={
                  selectedModels && selectedModels.length > 1
                    ? "Delete models (" + selectedModels.length + ")"
                    : "Delete Model"
                }
              />
            </ControlGroup>
          ) : null}
          <Flex flex="1" fullWidth justifyContent="flex-end" style={{ marginBottom: 5 }}>
            <Tag minimal icon={loading ? <Spinner size={SpinnerSize.SMALL} /> : null}>
              {loading ? "Loading..." : "Models loaded."}
            </Tag>
          </Flex>
        </Flex>
        <div style={{ marginBottom: 5, width: "100%" }}>
          <ModelFilters
            advancedFiltersEnabled={advancedFiltersEnabled}
            filterState={filterState}
            setAdvancedFiltersEnabled={setAdvancedFiltersEnabled}
            setFilterState={setFilterState}
            setTagFilters={setActiveTagFilters}
            tagFilters={activeTagFilters}
          />
        </div>
        {recalculatingModelIndexes.length > 0 ? (
          <Callout intent={Intent.WARNING}>
            <p>
              Some models in the table are relcalculating. They will show as loading in the table.
            </p>
            <Button
              icon={IconNames.REFRESH}
              intent={Intent.PRIMARY}
              text="Reload"
              onClick={() => modelHistory.mutate()}
            />
          </Callout>
        ) : null}
      </Flex>
      <Flex
        alignItems="flex-start"
        flexDirection="column"
        flexGrow="1"
        fullHeight
        fullWidth
        justifyContent="flex-start"
        style={{ overflowY: "hidden" }}
      >
        <StyledTableScrollContainer $theme={theme}>
          <Table
            columnTemplates={columns}
            configOverrides={{ layoutMode: "auto", columns }}
            data={maybeTransformedModels}
            displayRowIndex={modelTable.displayRowIndex}
            enableConfigOverrides={false}
            enableSelection={modelTable.enableSelection}
            error={response?.status !== Status.SUCCESS}
            excludeFromLoadingCols={excludeFromLoadingCols}
            id={uid}
            loading={loading}
            loadingRows={recalculatingModelIndexes}
            onColumnSizingChange={debouncedOncolumnsizingChange}
            onTagEdit={onTagEdit}
            paginationState={paginationState}
            pinnedColumns={pinnedCols}
            selectedRows={selectedModels}
            setColumnTemplates={editColumns}
            setSelectedRows={setSelected}
            setSortingState={setSorting}
            sortingState={sortState}
          />
        </StyledTableScrollContainer>
        <Flex flex="0" justifyContent="flex-start" fullWidth style={{ padding: "5px 0" }}>
          <Button
            icon={IconNames.COG}
            onClick={() => setEditTableDialogOpen(true)}
            small
            text="Edit Table"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const StyledTableScrollContainer = styled.div`
  flex: 1;
  overflow: auto;
  padding: 1px;
  width: 100%;
  ${styledScrollBar}
`;

export default ModelHistoryTable;
