import { Layout as ILayout } from "react-grid-layout";
import { IVSWidget } from "types/dashboard";

/**
 * Calculates the intrinsic h property of a module given its pixel height, the margin of the grid
 * and the row height of the grid
 *
 * @param {number} moduleHeight - The height in pixels of the module
 * @param {number} gridMargin - The margin of the grid in pixels
 * @param {number} rowHeight - The height of the rows in pixels
 *
 * @returns {number} - The new h value of the item based upon it content's intrinsic height
 */
export const calcModuleIntrinsicHeight = (
  moduleHeight: number,
  gridMargin: number,
  rowHeight: number
): number => {
  return Math.ceil((moduleHeight * rowHeight + gridMargin) / (rowHeight + gridMargin));
};

/**
 * Utility to convert a valsys dashboard object into a react grid layout object.
 */
export const vsWidgetsToRGL = (widgets: IVSWidget[]): ILayout[] => {
  if (!widgets) return [];
  return widgets.map((widget) => widget.layout);
};
