import { useMemo } from "react";
import useSWRImmutable from "swr";
import api from "utils/api";
import { IExchange, TCompanyInfo } from "types/createModel";
import { IModelTemplate } from "types/modelTemplate";
import { IResponse } from "types";
import { GET_COMPANIES_ROUTE, GET_MODEL_TEMPLATES_ROUTE } from "constants/apiConstants";

type TUseModelConfigurationOptions = () => {
  currencies: { data: { ISO: string; name: string }[]; loading: boolean };
  exchanges: { data: IExchange[]; loading: boolean };
  geographies: { data: string[]; loading: boolean };
  industries: { data: string[]; loading: boolean };
  templates: { data: IModelTemplate[]; loading: boolean };
};

const DefaultIndustries = [
  "Broadline Retail",
  "Car Marketplace",
  "Car Parts",
  "Car Rental",
  "Consumer Staples Distribution and Retail",
  "Consumer Tech",
  "Dealership",
  "Events",
  "Financial Services",
  "Fitness",
  "Ground Transportation",
  "Hotels, Restaurants and Leisure",
  "Household Durables",
  "Interactive Media and Services",
  "Media",
  "Movie Theatres",
  "Music Streaming",
  "Online Dating",
  "Real Estate Tech",
  "Social Media",
  "Specialty Retail",
  "Sports Entertainment",
  "Streaming",
  "Tax",
  "Telecommunications",
  "Video Games",
];

/**
 * Hook to fetch and cache available configuration options, EXCLUDING companies which
 * has its own hook.
 */
const useModelConfigOptions: TUseModelConfigurationOptions = () => {
  // Grab all available companies for the given env.
  const { data: availableCompanies, isLoading } = useSWRImmutable<IResponse<TCompanyInfo[]>>(
    GET_COMPANIES_ROUTE,
    api
  );

  // Templates are on a separate env...
  const { data: templates, isLoading: isLoadingTemplates } = useSWRImmutable<
    IResponse<IModelTemplate[]>
  >(GET_MODEL_TEMPLATES_ROUTE, api);

  const { currencies, geographies, industries } = useMemo(() => {
    const currencies: { ISO: string; name: string }[] = [];
    const industries: string[] = DefaultIndustries;
    const geographies: string[] = [];
    if (availableCompanies?.data) {
      for (let i = 0; i < availableCompanies.data.length; i++) {
        // TODO: Currencies currently not used in any environment but may be soon.
        // currencies.push(availableCompanies.data[i].currency);

        if (!industries.includes(availableCompanies.data[i].industry)) {
          industries.push(availableCompanies.data[i].industry);
        }
        if (!geographies.includes(availableCompanies.data[i].geography)) {
          geographies.push(availableCompanies.data[i].geography);
        }
      }
    }

    return { currencies, geographies, industries };
  }, [availableCompanies]);

  // TODO: Re-include exchanges if they are deemed relevant and re-added to the company infos
  // const exchanges = useMemo(() => {
  //   return availableCompanies?.data
  //     ? availableCompanies.data.map((company) => company.exchange)
  //     : [];
  // }, [availableCompanies]);

  return {
    currencies: { data: currencies, loading: isLoading },
    exchanges: { data: [], loading: isLoading },
    geographies: { data: geographies, loading: isLoading },
    industries: { data: industries, loading: isLoading },
    templates: { data: templates?.data || [], loading: isLoadingTemplates },
  };
};

export default useModelConfigOptions;
