import React, { useContext, useEffect, useState } from "react";
import Auth from "@aws-amplify/auth";
import styled from "styled-components/macro";
import QRCode from "qrcode.react";
import {
  Button,
  Card,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  Spinner,
  Tag,
  Tooltip,
} from "@blueprintjs/core";

import SettingsView from "components/user/SettingsView";
import { Flex } from "components/utility/StyledComponents";
import AppContext from "../../context/appContext";
import { multiToaster } from "utils/toaster";
import { currentUser, getPreferredMFA, setPreferredMFA } from "utils/auth";
import { Themes } from "constants/uiConstants";

import colors from "styles/colors.module.scss";

const StyledQRCodeWrapper = styled.div`
  display: inline;
`;

const SetupStates = {
  CHECKING_MFA_TYPE: {
    message: "Retrieving two-step verification configuration...",
    intent: Intent.PRIMARY,
    icon: "spinner",
  },
  ENABLED: {
    message: "Two-step verfication is enabled.",
    intent: Intent.SUCCESS,
    icon: "lock",
  },
  DISABLED: {
    message: "Two-step verfication is disabled!",
    intent: Intent.WARNING,
    icon: "unlock",
  },
  SHOULD_VERIFY_CODE: {
    message: "Awaiting verification token.",
    intent: Intent.PRIMARY,
    icon: "mobilephone",
  },
  VERFIYING_CODE: {
    message: "Verifying supplied token...",
    intent: Intent.PRIMARY,
    icon: "spinner",
  },
  DISABLING: {
    message: "Disabling two-step verification...",
    intent: Intent.WARNING,
    icon: "spinner",
  },
};

const MFATypes = {
  NOMFA: "NOMFA",
  SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA",
  TOTP: "TOTP",
};

const SetupTwoStepVerification = () => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const [setupState, setSetupState] = useState(SetupStates.CHECKING_MFA_TYPE);
  const [user, setUser] = useState(null); // Cognito user object; cached to minimise calls.
  const [code, setCode] = useState(null);
  const [verificationToken, setVerificationToken] = useState("");
  const [verificationError, setVerificationError] = useState("");

  // Get and set the user's cognito user object.
  useEffect(() => {
    currentUser({ bypassCache: true }).then((user) => setUser(user));
  }, []);

  useEffect(() => {
    if (user) {
      const abortController = new AbortController();
      const signal = abortController.signal;

      getPreferredMFA(user, { signal, bypassCache: true }).then((result) => {
        if (result === MFATypes.NOMFA) {
          setSetupState(SetupStates.DISABLED);
        } else {
          setSetupState(SetupStates.ENABLED);
        }
      });

      return function cleanup() {
        abortController.abort();
      };
    }
  }, [user]);

  function disableMFA() {
    setSetupState(SetupStates.DISABLING);
    setPreferredMFA(user, MFATypes.NOMFA).then(() => {
      multiToaster.show({
        message: "Successfully disabled multi-factor authentication!",
        intent: Intent.SUCCESS,
      });
      setSetupState(SetupStates.DISABLED);
    });
  }

  async function setupMFA() {
    Auth.setupTOTP(user).then((verCode) => {
      setCode(verCode);
      setSetupState(SetupStates.SHOULD_VERIFY_CODE);
    });
  }

  async function verifyTOTPToken() {
    setSetupState(SetupStates.VERFIYING_CODE);
    Auth.verifyTotpToken(user, verificationToken)
      .then(() => {
        setPreferredMFA(user, MFATypes.TOTP).then(() => {
          setVerificationError("");
          setSetupState(SetupStates.ENABLED);
        });
      })
      .catch(() => {
        setVerificationError("Error. Please try again.");
        setSetupState(SetupStates.SHOULD_VERIFY_CODE);
      });
  }

  return (
    <SettingsView
      title="Two-Step Verification"
      helperText="Enable or disable two-step verification."
    >
      <Flex alignItems="flex-start" justifyContent="flex-start" flexDirection="column">
        <p className={Classes.RUNNING_TEXT}>
          Two-step verification protects your Valsys account with both your password and your phone.
          Enabling this feature means that even if an attacker gets your Valsys password, they
          cannot access your account. You can read more about two-step verification in this{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://ssd.eff.org/en/module/how-enable-two-factor-authentication"
          >
            article from the Electronic Frontier Foundation.
          </a>
        </p>
        <p className={Classes.RUNNING_TEXT}>
          Valsys currently supports two-step verification using authenticator apps like{" "}
          <a rel="noopener noreferrer" target="_blank" href="https://www.google.com/landing/2step/">
            Google Authenticator{" "}
          </a>
          or{" "}
          <a rel="noopener noreferrer" target="_blank" href="https://authy.com/">
            Authy
          </a>
          .
        </p>
        <Card style={{ width: "100%" }}>
          <Flex flexDirection="column" alignItems="flex-start" fullWidth>
            <Tag
              intent={setupState.intent}
              large
              icon={
                setupState.icon === "spinner" ? (
                  <Spinner size={Spinner.SIZE_SMALL} />
                ) : (
                  setupState.icon
                )
              }
              minimal
              style={{ marginBottom: 15 }}
            >
              <strong>{setupState.message}</strong>
            </Tag>
            {setupState === SetupStates.ENABLED ? (
              <Tooltip
                content="Valsys highly recommends using two-step verfication."
                intent={Intent.WARNING}
              >
                <Button
                  icon="warning-sign"
                  intent={Intent.WARNING}
                  onClick={disableMFA}
                  text="Disable two-step verification"
                />
              </Tooltip>
            ) : null}
            {setupState === SetupStates.DISABLING ? (
              <Button
                disabled
                icon="warning-sign"
                intent={Intent.WARNING}
                text="Disable two-step verification"
              />
            ) : null}
            {setupState === SetupStates.DISABLED ? (
              <Button
                icon="lock"
                intent={Intent.PRIMARY}
                onClick={setupMFA}
                text="Enable two-step verification"
              />
            ) : null}
            {setupState === SetupStates.SHOULD_VERIFY_CODE ||
            setupState === SetupStates.VERFIYING_CODE ? (
              <>
                <p>
                  Scan the QR code below using an app like{" "}
                  <a href="https://www.google.com/landing/2step/">Google Authenticator </a>
                  or&nbsp;
                  <a href="https://authy.com/">Authy</a>.
                </p>
                <Flex justifyContent="center">
                  <StyledQRCodeWrapper>
                    <QRCode
                      bgColor={
                        theme === Themes.DARK
                          ? colors.darkAppBackgroundColor
                          : colors.appBackgroundColor
                      }
                      fgColor={
                        theme === Themes.DARK
                          ? colors.appBackgroundColor
                          : colors.darkAppBackgroundColor
                      }
                      level="L"
                      renderAs="svg"
                      value={`otpauth://totp/AWSCognito:${user?.username}?secret=${code}&issuer=AWSCognito`}
                    />
                  </StyledQRCodeWrapper>
                </Flex>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    verifyTOTPToken();
                  }}
                >
                  <FormGroup
                    label="Enter a verification code from the app:"
                    labelFor="verification-code"
                    intent={verificationError ? Intent.WARNING : null}
                    helperText={verificationError}
                  >
                    <InputGroup
                      value={verificationToken}
                      onChange={(e) => setVerificationToken(e.target.value)}
                      intent={verificationError ? Intent.WARNING : null}
                      id="verification-code"
                    />
                  </FormGroup>
                  <Button
                    intent={Intent.PRIMARY}
                    rightIcon="circle-arrow-right"
                    text="Verify"
                    type="submit"
                  />
                </form>
              </>
            ) : null}
          </Flex>
        </Card>
      </Flex>
    </SettingsView>
  );
};

export default SetupTwoStepVerification;
