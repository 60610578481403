import { createContext } from "react";
import { noop } from "utils/generic";
import { IModelVersion } from "types/model";
import { Dashboard } from "types/dashboard";

export interface IModelConfig {
  autoAdjustColumnWidths: boolean;
  columnWidths: Record<string, number[]>;
  showErrors: boolean;
  dashboard: Dashboard;
}

export interface IPreviousVersionsContext {
  activeVersion: IModelVersion | null;
  setVersion: (version: IModelVersion | null) => void;
  availableVersions: IModelVersion[];
}

interface IModelContext {
  modelID: string;
  config: IModelConfig;
  locked: boolean;
  setConfig: (config: Partial<IModelConfig>) => void;
  previousVersions: IPreviousVersionsContext;
}

export const defaultModelConfig: IModelConfig = {
  autoAdjustColumnWidths: true,
  columnWidths: {},
  showErrors: false,
  dashboard: {
    contextId: "",
    createdAt: "01-01-2023",
    createdBy: "system",
    description: "",
    id: "model-dashboard",
    name: "Model dashboard",
    views: [],
    visibility: "model",
  },
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function isModelConfig(obj: any): obj is IModelConfig {
  return (
    obj &&
    obj.dashboardLayouts &&
    (!obj.columnWidths || (obj.columnWidths && typeof obj.columnWidths === "object"))
  );
}

const ModelContext = createContext<IModelContext>({
  modelID: "",
  config: defaultModelConfig,
  locked: true,
  setConfig: noop,
  previousVersions: {
    activeVersion: null,
    setVersion: noop,
    availableVersions: [],
  },
});

export default ModelContext;
