import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import propTypes from "prop-types";
import { SizeMe } from "react-sizeme";
import EnvContext, { EEnv } from "context/envContext";
import { IUser } from "types/user";
import "styles/App.scss";

// Note - not used as the child route (Authentication) is jsx
export type OutletContext = {
  size: { readonly width: number | null; readonly height: number | null };
  authState: string | null;
  setAuthState: Dispatch<SetStateAction<string | null>>;
  user: IUser | null;
  setUser: Dispatch<SetStateAction<IUser | null>>;
};

const App: React.FC = () => {
  const [user, setUser] = useState<IUser | null>(null);
  const [authState, setAuthState] = useState<string | null>(null);
  const [env, setEnv] = useState<EEnv | undefined>(undefined);

  useEffect(() => {
    // This is currently the canonical way of doing this. When we get proper BE driven build env vars we can change.
    if (process.env.NODE_ENV === "development") {
      setEnv(EEnv.DEVELOPMENT);
    } else {
      if (!window.__env__.REACT_APP_RELEASE_PREFIX.startsWith("vs-")) {
        setEnv(EEnv.PRIVATE_CLOUD);
      } else {
        setEnv(EEnv.PUBLIC_CLOUD);
      }
    }
  }, []);

  return (
    <EnvContext.Provider value={{ env, setEnv }}>
      <SizeMe monitorWidth monitorHeight refreshRate={200}>
        {({ size }) => (
          <div className="App">
            <Outlet context={{ size, authState, setAuthState, user, setUser }} />
          </div>
        )}
      </SizeMe>
    </EnvContext.Provider>
  );
};

App.propTypes = {
  authState: propTypes.string,
  hasAcceptedTerms: propTypes.bool.isRequired,
};

App.defaultProps = {
  authState: "signIn",
  hasAcceptedTerms: false,
};

export default App;
