import { Selector, SelectorOption, SelectorRequest, SelectorTypes } from "types/vsl";
import isFunction from "lodash.isfunction";
import { ImportSelectorRegex, vslParamsRegex, widgetTitleVariablesRegex } from "constants/vsl";

export const selectorToSelectorRequest = (selector: Selector): SelectorRequest => {
  return {
    ...selector,
    selected_options: selector.selected_options?.map((selectedOption) => selectedOption?.value),
    filtered_by: selector.filtered_by,
    available_options: selector.available_options?.map((option) =>
      isFunction(option?.trim) ? option.trim() : option
    ),
    required: !!selector.required,
  };
};

/** Given a selectors object outputs a new object with selector options only */
export const getSelectorOptions = (
  selectors: Selector[] | undefined
): Record<string, SelectorOption[]> => {
  if (!selectors) return {};
  const newSelectors: Record<string, SelectorOption[]> = {};
  selectors.forEach((selector) => {
    const options = selector.selected_options;
    newSelectors[selector.id] = options || [];
  });
  return newSelectors;
};

export const parseVSLQuerySelectors = (queries: string[]): Selector[] => {
  if (!queries) return [];
  const selectors: Selector[] = [];
  queries.forEach((query) => {
    const importSelectors = query.match(ImportSelectorRegex);
    if (!importSelectors) return [];
    importSelectors.forEach((importSelector) => {
      const selector: Record<string, string> = {};
      // slice to remove the last ')' so that it doesn't interfere with params parsing
      const params = importSelector.slice(0, -1).match(vslParamsRegex);
      params?.forEach((param) => {
        const paramSplit = param.split("=");
        const key = paramSplit[0].trim();
        selector[key] = paramSplit[1].replaceAll('"', "").trim();
      });

      // only add if it has the right props and identifier hasn't been added yet
      if (selector.id && selector.type && !selectors.some((sel) => sel.id === selector.id)) {
        const newSelector: Selector = { id: selector.id, type: selector.type };
        if (selector.required) newSelector.required = selector.required === "TRUE";
        if (selector.source_id) newSelector.source_id = selector.source_id;
        selectors.push(newSelector);
      }
    });
  });
  return selectors;
};

export const parseWidgetTitleVars = (title: string, selectors: Omit<Selector, "type">[]) => {
  if (!selectors || selectors.length === 0) return title;
  const variables = title.match(widgetTitleVariablesRegex);
  if (!variables) return title;

  let newTitle = title;
  variables.forEach((variable) => {
    // remove __ prefix and suffix to compare with selector id
    const variableName = variable.slice(2, -2);
    const selector = selectors.find((sel) => sel.id === variableName);
    if (selector?.id === variableName) {
      if (selector.selected_options) {
        let selectorsString = "";
        selector.selected_options.forEach((option) => {
          selectorsString += option.label + ", ";
        });
        // replace variable with selector options. trim and slice -1 to remove last comma.
        newTitle = newTitle.replace(variable, selectorsString.trim().slice(0, -1));
        // replace with selector id if no options are selected
      } else newTitle = newTitle.replace(variable, selector.id);
    }
  });
  return newTitle;
};

/** Updates an entry of a nested array by id of the array object */
export const updateNestedArray = <OT, AT>(
  obj: OT,
  arrayPropName: keyof OT,
  nextArrayEntry: AT,
  arrayEntryIdPropName: keyof AT,
  idx?: number
): OT => {
  const existingArray = (obj[arrayPropName] as AT[]) || [];
  const newArray: AT[] = [...existingArray];
  const arrayEntryIdx = existingArray.findIndex(
    (entry) => entry[arrayEntryIdPropName] === nextArrayEntry[arrayEntryIdPropName]
  );
  // no entry yet. If we have an index we add in the right place, otherwise we push to the end
  if (arrayEntryIdx === -1)
    idx ? newArray.splice(idx, 0, { ...nextArrayEntry }) : newArray.push(nextArrayEntry);
  else newArray[arrayEntryIdx] = { ...nextArrayEntry };

  return {
    ...obj,
    [arrayPropName]: newArray,
  };
};

export const isValidSelector = (
  selector: Selector,
  VSLSelectors: Selector[],
  selectorTypes?: SelectorTypes
): boolean => {
  const matchingQuerySelector = VSLSelectors.find(
    (querySelector) => querySelector.id === selector.id
  );

  if (!selectorTypes) {
    return !!matchingQuerySelector;
  }

  return (
    !!matchingQuerySelector &&
    Object.keys(selectorTypes.selector_types).some(
      (selectorType) => selectorType === matchingQuerySelector.type
    )
  );
};
