import React from "react";
import { Intent } from "@blueprintjs/core";

import LoadingIndicator from "components/utility/LoadingIndicator";

const ModuleSpinner = ({ classNames, loading, status }) => {
  return (
    <div className={classNames}>
      <LoadingIndicator loading={loading} status={status} />
    </div>
  );
};

ModuleSpinner.defaultProps = {
  status: {
    intent: Intent.PRIMARY,
    message: "LOADING MODULE...",
  },
};

export default ModuleSpinner;
