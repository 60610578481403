import React from "react";
import SettingsView from "components/user/SettingsView";

const AppearanceSettings = () => {
  return (
    <SettingsView
      title="Model Appearance"
      helperText="Modify default model appearance. (Coming soon)"
    ></SettingsView>
  );
};

export default AppearanceSettings;
