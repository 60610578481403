import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  Alignment,
  Button,
  Callout,
  Classes,
  Dialog,
  Divider,
  FormGroup,
  HTMLSelect,
  Intent,
  MenuItem,
  Spinner,
  SpinnerSize,
  Switch,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, Select } from "@blueprintjs/select";
import SettingsView from "components/user/SettingsView";
import TemplateEditor, { IJSONEditorHandle } from "components/model/TemplateEditor";
import { Flex } from "components/utility/StyledComponents";
import { getValidJSON } from "utils/api";
import { useApiCallback } from "hooks/useApiCallback";
import { FETCH_USERS_ROUTE, EMethods } from "constants/apiConstants";
import { IAppConfig, isAppConfig } from "types";
import { IUser } from "types/user";
import useUser from "hooks/useUser";
import useSetAndGetConfig from "hooks/useSetAndGetConfig";
import { Themes } from "constants/uiConstants";
import { isSavedModelHistoryColumn, ITableConfigColumn } from "types/modelHistory";

const NON_EDITOR_HEIGHT = 363;

const DeveloperSettings = () => {
  const { user } = useUser();

  // State variables to hold editing metadata
  const [editingDefault, setEditingDefault] = useState(false);
  const [editKey, setEditKey] = useState<"appConfig" | "availableFields">("appConfig");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [parsing, setParsing] = useState(false);
  const [validJSON, setValidJSON] = useState(true);
  const [isReset, setIsReset] = useState(false);
  const [usersList, setUsersList] = useState<IUser[]>();
  const [usersPredicate, setUsersPredicate] = useState("");
  const [activeUser, setActiveUser] = useState<IUser | undefined>(user);

  const editorHandle = useRef<IJSONEditorHandle>(null);

  const {
    config,
    fetchingConfig,
    isDefault,
    resetKeyedConfig,
    savingConfig,
    update: updateConfig,
  } = useSetAndGetConfig<IAppConfig | ITableConfigColumn[]>(
    editKey,
    editingDefault ? null : activeUser?.uid || null,
    editingDefault ? null : "userID"
  );

  const { callback: fetchUsersData, response: usersDataResp } = useApiCallback<IUser[]>();

  if (!activeUser && user) setActiveUser(user);

  // Handlers
  const changeSelectedUser = (selectedUser: IUser | undefined) => {
    setActiveUser(selectedUser);
  };

  useEffect(() => {
    fetchUsersData(FETCH_USERS_ROUTE, { method: EMethods.GET });
  }, [fetchUsersData]);

  useEffect(() => {
    if (usersDataResp?.data) setUsersList(usersDataResp.data);
  }, [usersDataResp]);

  const confirmSubmit = () => {
    if (isReset) {
      resetKeyedConfig();
      setDialogOpen(false);
    } else {
      const value = editorHandle?.current?.getValue();
      if (value) {
        const parsed = getValidJSON(value);
        if (parsed) {
          updateConfig(parsed);
          setDialogOpen(false);
        }
      }
    }
  };

  const lintTemplate = (text: string) => {
    if (text) {
      let lintError: boolean;
      switch (editKey) {
        case "appConfig":
          lintError = !isAppConfig(getValidJSON<IAppConfig>(text));
          break;
        case "availableFields":
          lintError = !getValidJSON<{ availableFields: ITableConfigColumn[] }>(
            text
          )?.availableFields?.every((maybeCol) => isSavedModelHistoryColumn(maybeCol));
          break;
        default:
          lintError = false;
      }
      if (text && getValidJSON(text) && lintError) {
        setInvalid(true);
      } else {
        setInvalid(false);
      }
    }
  };

  const renderUsers: ItemRenderer<IUser> = (userInfo, { handleClick, modifiers }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={`${userInfo.fullname}-${userInfo.uid}`}
        onClick={handleClick}
        text={userInfo.fullname}
        icon={IconNames.PERSON}
        labelElement={userInfo?.uid === user?.uid ? "Me" : undefined}
      />
    );
  };

  function itemPredicate(query: string, item: IUser) {
    return item.fullname ? item.fullname.toLowerCase().includes(query.toLowerCase()) : true;
  }

  let infoMessage: JSX.Element;
  if (isDefault && !editingDefault) {
    infoMessage = (
      <span>
        User <strong>{activeUser?.fullname}</strong> is currently using the default config. Any edit
        made will create a new custom config for the user.
      </span>
    );
  } else if (!isDefault && !editingDefault) {
    infoMessage = (
      <span>
        Editing custom configuration for user <strong>{activeUser?.fullname}</strong>.
      </span>
    );
  } else {
    infoMessage = (
      <span>
        Editing the default configuration for the <strong>organisation</strong>. Will likely affect
        other users!
      </span>
    );
  }

  return (
    <SettingsView
      helperText="Edit JSON configurations for the client side application."
      title="Developer"
    >
      <ConfirmSaveOrResetTemplateDialog
        submit={confirmSubmit}
        isOpen={dialogOpen}
        isReset={isReset}
        operationInProgress={savingConfig}
        setDialogOpen={setDialogOpen}
      />
      <Flex justifyContent="space-between" fullWidth>
        <Flex alignItems="flex-start">
          <FormGroup label="Configuration:">
            <HTMLSelect
              onChange={(e) => setEditKey(e.currentTarget.value as "appConfig" | "availableFields")}
              options={[
                { value: "appConfig", label: "Application Config" },
                { value: "availableFields", label: "Available Fields" },
              ]}
              value={editKey}
            />
          </FormGroup>
          <Divider />
          <FormGroup
            helperText="When checked any edits made will be to organisation default."
            label="Edit default:"
          >
            <Switch
              innerLabel="false"
              innerLabelChecked="true"
              large
              checked={editingDefault}
              onChange={() => setEditingDefault(!editingDefault)}
            />
          </FormGroup>
          <Divider />
          <Tooltip disabled={!editingDefault} content="Disable default editing to select a user.">
            <FormGroup disabled={editingDefault} label="User:">
              <Select<IUser>
                activeItem={user}
                itemPredicate={itemPredicate}
                itemRenderer={renderUsers}
                items={usersList || []}
                onItemSelect={changeSelectedUser}
                onQueryChange={setUsersPredicate}
                query={usersPredicate}
              >
                <Button
                  alignText={Alignment.LEFT}
                  disabled={!usersList || editingDefault}
                  icon={IconNames.PERSON}
                  loading={!usersList}
                  rightIcon={IconNames.DOUBLE_CARET_VERTICAL}
                  text={
                    user?.uid === activeUser?.uid
                      ? `${activeUser?.fullname} (Me)`
                      : activeUser?.fullname
                  }
                />
              </Select>
            </FormGroup>
          </Tooltip>
        </Flex>
      </Flex>
      <Callout
        icon={IconNames.WARNING_SIGN}
        intent={Intent.WARNING}
        style={{ marginBottom: 10 }}
        title="Potentially Destructive Operation!"
      >
        Only edit these templates if you are an administrator or power user and fully understand the
        implications of the changes you make. If you need any help, contact{" "}
        <a href="mailto:support@valsys.io">support@valsys.io</a>.
        <br />
      </Callout>
      <Tag
        fill
        icon={IconNames.INFO_SIGN}
        intent={Intent.PRIMARY}
        large
        minimal
        style={{ marginBottom: 10 }}
      >
        {infoMessage}
      </Tag>
      {!fetchingConfig ? (
        <TemplateEditor
          body={JSON.stringify(config ? config : {}, null, "\t")}
          handleRef={editorHandle}
          height={`calc(100vh - ${NON_EDITOR_HEIGHT}px)`}
          lint={lintTemplate}
          saving={false}
          setParsing={setParsing}
          setValidJSON={setValidJSON}
        />
      ) : (
        <Spinner />
      )}
      <Flex fullWidth justifyContent="space-between" style={{ marginTop: 10 }}>
        <Flex>
          <Tag
            icon={
              !parsing ? (
                validJSON ? (
                  IconNames.TICK
                ) : (
                  IconNames.ERROR
                )
              ) : (
                <Spinner size={SpinnerSize.SMALL} />
              )
            }
            intent={!parsing ? (validJSON ? Intent.SUCCESS : Intent.WARNING) : Intent.NONE}
            minimal
          >
            {validJSON ? "JSON valid" : "Invalid JSON!"}
          </Tag>
          <Divider />
          <Tag
            icon={
              !parsing ? (
                !invalid ? (
                  IconNames.TICK
                ) : (
                  IconNames.ERROR
                )
              ) : (
                <Spinner size={SpinnerSize.SMALL} />
              )
            }
            intent={!parsing ? (!invalid ? Intent.SUCCESS : Intent.WARNING) : Intent.NONE}
            minimal
          >
            {invalid ? "Template error!" : "Template valid"}
          </Tag>
        </Flex>
        <Flex>
          <Button
            disabled={editingDefault || isDefault}
            icon={IconNames.RESET}
            text="Reset"
            onClick={() => {
              setDialogOpen(true);
              setIsReset(true);
            }}
          />
          <Divider />
          <Button
            disabled={invalid || parsing || !validJSON || savingConfig}
            intent={Intent.PRIMARY}
            loading={savingConfig}
            onClick={() => {
              setDialogOpen(true);
              setIsReset(false);
            }}
            text="Update"
          />
        </Flex>
      </Flex>
    </SettingsView>
  );
};

interface IConfirmSaveTemplateDialogProps {
  submit: () => void;
  isOpen: boolean;
  isReset: boolean;
  operationInProgress: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const ConfirmSaveOrResetTemplateDialog: React.FC<IConfirmSaveTemplateDialogProps> = ({
  isOpen,
  isReset,
  operationInProgress,
  setDialogOpen,
  submit,
}) => {
  const { config } = useSetAndGetConfig<IAppConfig>("appConfig");
  return (
    <Dialog
      icon={IconNames.WARNING_SIGN}
      isOpen={isOpen}
      onClose={() => setDialogOpen(false)}
      title={isReset ? "Confirm reset template" : "Confirm edit template"}
      className={config?.theme === Themes.DARK ? Classes.DARK : undefined}
    >
      <div className={Classes.DIALOG_BODY}>
        <p className={Classes.RUNNING_TEXT}>
          {isReset
            ? "Resetting the configuration will remove any user made custom edits."
            : `Incorrect edits made to this configuration object can cause the application to behave in
          unexpected ways or crash.`}
        </p>
        <p className={Classes.RUNNING_TEXT}>Are you sure you want to proceed?</p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            disabled={operationInProgress}
            icon={IconNames.CROSS}
            text="Cancel"
            onClick={() => setDialogOpen(false)}
          />
          <Button
            disabled={operationInProgress}
            icon={isReset ? IconNames.RESET : null}
            intent={Intent.PRIMARY}
            onClick={submit}
            text={isReset ? "Reset" : "Update"}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DeveloperSettings;
