import React, { useState } from "react";
import { Flex } from "components/utility/StyledComponents";
import { useNavigate, useParams } from "react-router-dom";
import useView from "hooks/dashboard/useView";
import {
  Button,
  Callout,
  Classes,
  ControlGroup,
  EditableText,
  H4,
  H6,
  Intent,
} from "@blueprintjs/core";
import LoadingIndicator from "components/utility/LoadingIndicator";
import { IconNames } from "@blueprintjs/icons";
import useListConfigs from "hooks/useListConfigs";
import View from "./View";
import AddWidgetToViewDialog from "./AddWidgetToViewDialog";

/**
 * A wrapper for viewing a View in the View library.
 * */
const ViewLibraryView: React.FC = () => {
  const { viewId } = useParams();
  const [selectorsDialogOpen, setSelectorsDialogOpen] = useState(false);
  const {
    deleteView,
    error: viewError,
    loading: loadingView,
    savingView,
    setDescription: setViewDescription,
    setName: setViewName,
    view,
  } = useView(viewId);

  const [addWidgetDialogOpen, setAddWidgetDialogOpen] = useState(false);
  const [layoutEditable, setLayoutEditable] = useState(false);
  const { widgets } = useListConfigs();
  const navigate = useNavigate();

  if (loadingView) {
    return (
      <LoadingIndicator loading status={{ intent: Intent.PRIMARY, message: "Loading widget..." }} />
    );
  }
  if (viewError) {
    return (
      <Callout title="View error!" intent={Intent.WARNING}>
        <p>Unable to retrieve View. Please refresh and try again.</p>
      </Callout>
    );
  }
  if (!view) {
    return null;
  }

  return (
    <Flex
      alignItems="flex-start"
      flex="1 0 auto"
      flexDirection="column"
      fullHeight
      justifyContent="flex-start"
      style={{ overflow: "hidden" }}
    >
      <AddWidgetToViewDialog
        isOpen={addWidgetDialogOpen}
        onClose={() => setAddWidgetDialogOpen(false)}
        viewId={viewId}
      />
      <Flex
        alignItems="center"
        flex="0 auto"
        fullWidth
        justifyContent="space-between"
        style={{ paddingLeft: "10px" }}
      >
        <div>
          <H4>
            <Flex justifyContent="flex-start">
              <span className={Classes.TEXT_MUTED}>Name:&nbsp;</span>
              <EditableText
                value={view.name}
                placeholder="Enter a name (required)"
                maxLength={40}
                onChange={setViewName}
              />
            </Flex>
          </H4>
          <H6>
            <Flex flexWrap="wrap" justifyContent="flex-start">
              <span className={Classes.TEXT_MUTED}>Description:&nbsp;</span>
              <EditableText
                maxLength={120}
                onChange={setViewDescription}
                placeholder="Enter a description (optional)"
                value={view.description}
              />
            </Flex>
          </H6>
        </div>
        <ControlGroup>
          <Button
            icon={layoutEditable ? IconNames.UNLOCK : IconNames.LOCK}
            minimal
            onClick={() => setLayoutEditable(!layoutEditable)}
          />
          <Button
            icon={IconNames.FILTER_LIST}
            minimal
            onClick={() => setSelectorsDialogOpen(true)}
            text="Selectors"
          />
          <Button
            active={addWidgetDialogOpen}
            icon={IconNames.ADD}
            loading={!widgets}
            minimal
            onClick={() => setAddWidgetDialogOpen(true)}
            style={{ flexShrink: 0 }}
            text="Add Widget"
          />
          <Button
            disabled={savingView}
            icon={IconNames.DELETE}
            intent={Intent.DANGER}
            minimal
            onClick={() => {
              navigate("settings/views", { replace: true });
              deleteView();
            }}
            style={{ alignSelf: "flex-start" }}
            text="Delete"
          />
        </ControlGroup>
      </Flex>
      <Flex
        alignItems="flex-start"
        flex="1 0 auto"
        fullWidth
        style={{ height: "calc(100% - 80px)", overflow: "hidden" }}
      >
        {viewId ? (
          <View
            layoutEditable={layoutEditable}
            setEditSelectorsDialogOpen={setSelectorsDialogOpen}
            editSelectorsDialogOpen={selectorsDialogOpen}
            viewId={viewId}
          />
        ) : null}
      </Flex>
    </Flex>
  );
};

export default ViewLibraryView;
