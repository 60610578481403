import React from "react";
import styled, { css } from "styled-components/macro";
import {
  Alignment,
  Icon,
  IconName,
  IconSize,
  Intent,
  Spinner,
  SpinnerSize,
  Tag,
} from "@blueprintjs/core";
import { Flex } from "components/utility/StyledComponents";
import { IconNames } from "@blueprintjs/icons";

const StyledTag = styled(Tag)<{ $spinnerAlignment: Alignment }>`
  font-size: 12px;
  ${({ $spinnerAlignment }) =>
    $spinnerAlignment === Alignment.LEFT
      ? css`
          margin-left: 6px;
        `
      : css`
          margin-right: 6px;
        `}
`;

interface ILoadingIndicatorProps {
  loading?: boolean;
  status: { icon?: IconName; intent?: Intent; message?: string };
  spinnerAlignment?: Alignment;
}

/**
 * Component used to indicate whether some other content is currently in a ready state or working.
 * Renders a Spinner next to a tag with custom message and optional Alignment.
 * */
const LoadingIndicator: React.FC<ILoadingIndicatorProps> = ({
  loading = true,
  status,
  spinnerAlignment = Alignment.LEFT,
}) => {
  const icon =
    status.icon || status.intent === Intent.DANGER ? IconNames.WARNING_SIGN : IconNames.DOT;
  if (spinnerAlignment === Alignment.RIGHT) {
    return (
      <Flex justifyContent="flex-start" alignItems="center">
        <StyledTag intent={status.intent} minimal multiline $spinnerAlignment={spinnerAlignment}>
          {status.message}
        </StyledTag>
        {loading ? (
          <Spinner intent={status.intent} size={SpinnerSize.SMALL} />
        ) : (
          <Icon icon={icon} size={IconSize.LARGE} intent={status.intent} />
        )}
      </Flex>
    );
  } else {
    return (
      <Flex justifyContent="flex-start" alignItems="center">
        {loading ? (
          <Spinner intent={status.intent} size={SpinnerSize.SMALL} />
        ) : (
          <Icon icon={icon} size={IconSize.LARGE} intent={status.intent} />
        )}
        <StyledTag intent={status.intent} minimal multiline $spinnerAlignment={spinnerAlignment}>
          {status.message}
        </StyledTag>
      </Flex>
    );
  }
};

export default LoadingIndicator;
