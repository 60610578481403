import { addCustomClassCell } from "./modelGrid";
import { Themes } from "constants/uiConstants";
import { EPermissionLevels } from "types/model";
import { EValFormat } from "types/format";
import colors from "styles/colors.module.scss";

export function getMetaKey(e) {
  if (e) {
    return window.navigator.platform.match(/^Mac/) ? e.metaKey : e.ctrlKey;
  }
  return window.navigator.platform.match(/^Mac/) ? "cmd" : "ctrl";
}

export function isFloat(n) {
  return !(Number(n) % 1 === 0);
}

/** Only numeric chars except the . */
export function cleanString(s) {
  if (typeof s !== "string") {
    return s;
  } else {
    return s.replace(/[^\d.-]/g, "");
  }
}

/**
 * Clamps a number between a min and max value
 *
 * @param {number} val - The value to be clamped
 * @param {number} min - The minimum value allowed
 * @param {number} max - The maximum value allowed
 *
 * @return clampedValue - The clamped value
 */
export function clamp(val, min, max) {
  if (val === null) {
    return val;
  }
  return Math.min(Math.max(val, min), max);
}

export function basicEmailValidate(email) {
  return /^\S+@\S+$/.test(email);
}

export function toNum(val, shouldCleanString) {
  let v = shouldCleanString ? cleanString(val) : val;
  if (isFloat(v)) {
    return Number.parseFloat(v);
  } else {
    return Number.parseInt(v);
  }
}

/**
 * Reorder an item within an array. Returns a deep copy to one level (the containing array itself). Does not swap items, to do so see 'swapReorder' in tableUtils.js
 *
 * @param {Array} array - The array on which to execute the reorder.
 * @param {number} startIndex - The index from which to move the element.
 * @param {number} endIndex - The index to which to move the element.
 * */
export const reorder = (array, startIndex, endIndex) => {
  const nextArray = [...array];
  const [removed] = nextArray.splice(startIndex, 1);
  nextArray.splice(endIndex, 0, removed);
  return nextArray;
};

/**
 * Returns true if a number is negative.
 * @param {number} val - The nmber to test.
 *
 * @returns {bool} - Whether the supplied value is a negtive number.
 * */
export function isNeg(number) {
  return Math.sign(toNum(number, true)) < 0;
}

/**
 * Returns true if a number is odd, false if even
 *
 * @param {number} val -The number to be tested
 * */
export function isOdd(number) {
  // eslint-disable-next-line eqeqeq
  return Math.abs(number % 2) == 1;
}

/**
 * Updates the document's title.
 *
 * @param {string} title - The new page title.
 * */
export function updatePageTitle(title) {
  if (document.title.includes(title)) return;
  document.title = `${title} - Valsys`;
}

/**
 * Function to sort by a numeric value,
 *
 * @param {number} a - The first comparison number.
 * @param {number} b - The second comparison number.
 *
 * @returns {number} - Whether the elements should be shifted higher or lower in the array
 * */
export function sortNumeric(a, b) {
  return a - b;
}

/** Function to sort by alphabetical order */
export function sortAlpha(a, b) {
  return a.toLowerCase().localeCompare(b.toLowerCase());
}

/**
 * Sort two facts by the value prop
 */
export function sortFact(factA, factB) {
  if (!factA?.value || !factB?.value) return 0;
  if (
    factA.format?.valFormat !== EValFormat.PLAINTEXT ||
    factB.format?.valFormat !== EValFormat.PLAINTEXT
  ) {
    return Number(factA.value) - Number(factB.value);
  }
  return factA.value.localeCompare(factB.value);
}

/**
 * Strips a company suffix, for example Inc, Corp etc.
 *
 * @param companyName {string} - The company name, including the suffix to be
 * stripped.
 */
export function stripCompanySuffix(companyName) {
  // TODO - move this to a constant file/the server.
  const suffixes = [
    "Inc",
    "Inc.",
    "Corp",
    "Corp.",
    "Corporation",
    "Co",
    "Co.",
    "Ltd",
    "Ltd.",
    "Holdings",
    "plc",
  ];

  let suff = suffixes.find((suffix) => {
    return companyName.toLowerCase().includes(suffix.toLowerCase());
  });

  if (suff) {
    suff = suff.toLowerCase();
    return companyName
      .toLowerCase()
      .replace(suff, "")
      .replace(/[^\w\s]|_/g, "")
      .replace(/\s+/g, " ");
  }
  return companyName.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ");
}

/**
 * Goes through the copied items and if the first row matches the copied headers, removes.
 * Also, if the first item of each row matched row headers it's removed
 * @param clipboardData the object copied internally that contains items and headers
 * @returns {Object} an object with the items but no headers
 */
export function removeHeadersFromClipboard(clipboardData) {
  const { items, columnHeaders, rowHeaders } = clipboardData;
  let firstRow = items[0];
  if (!firstRow || (!columnHeaders && !rowHeaders)) return items;
  let itemsOnly = [];
  let startLine = 0;

  // decide to remove column headers row
  if (columnHeaders) {
    let remove = true;
    for (let i = 0; i < firstRow.length; i++) {
      if (!columnHeaders.includes(firstRow[i])) {
        remove = false;
        break;
      }
    }
    if (remove) startLine = 1;
    else itemsOnly.push(firstRow);
  }

  if (rowHeaders) {
    // decide to remove rowHeaders if they match
    let remove = true;
    for (let i = startLine; i < items.length; i++) {
      const row = items[i];
      if (!rowHeaders.map((rH) => rH.name).includes(row[0])) {
        remove = false;
        break;
      }
    }
    // write rows
    for (let i = startLine; i < items.length; i++) {
      if (remove) itemsOnly.push(items[i].slice(1));
      else itemsOnly.push(items[i]);
    }
  }

  return itemsOnly;
}

export function isDate(maybeDate) {
  return maybeDate instanceof Date && !isNaN(maybeDate);
}

/**
 * Validate date string format supported in formulas (ddmmyyyy)
 * @param dateString
 * @returns {boolean|boolean}
 */
export function validDateString(dateString) {
  if (!dateString || dateString.length !== 10) return false;
  const date = new Date(dateString);
  return isDate(date);
}

/**
 * Generates a complete data entry url with a new module. Only works when run when the url is on a
 * data entry screen. If not, returns the current URL.
 * @param {string} newModule - The name of the new module to navigate to
 * */
export function generateNewDataEntryUrl(newModuleName) {
  const splitUrl = document.URL.split("/");
  if (splitUrl[splitUrl.length - 2] !== "data") {
    return document.URL;
  }
  const newUrl = `${splitUrl.slice(0, splitUrl.length - 1).join("/")}/${newModuleName.replace(
    / /g,
    "-"
  )}`;
  return newUrl;
}

// A noop
export const noop = () => {};

/**
 * Styles a whole region in a matrix with different styles for edges
 * @param fromRow
 * @param toRow
 * @param fromCol
 * @param toCol
 * @param styleMatrix
 * @param map
 */
export function squareStyle(fromRow, toRow, fromCol, toCol, styleMatrix, map) {
  for (let i = fromCol; i <= toCol; i++) {
    for (let j = fromRow; j <= toRow; j++) {
      let styles = styleMatrix.all + " ";
      if (i === fromCol) styles += styleMatrix.left + " ";
      if (i === toCol) styles += styleMatrix.right + " ";
      if (j === fromRow) styles += styleMatrix.top + " ";
      if (j === toRow) styles += styleMatrix.bottom + " ";
      addCustomClassCell(j, i, map, styles);
    }
  }
}

/**
 * Truncate a string down to a specified length
 *
 * @param {string} string - The string to truncate.
 * @param {number} length - The length to correct to.
 * @param {bool} ellipsis - Whether an ellipsis should be appended to the truncated value.
 * */
export function truncate(string, length, ellipsis) {
  return string.length > length ? `${string.substring(0, length)}${ellipsis ? "..." : ""}` : string;
}

/**
 * Extracts valid coordinates (row, col) from url
 * @param queryParams
 * @returns {{col: number, row: number}}
 */
export function coordsFromUrl(queryParams) {
  const urlParams = new URLSearchParams(queryParams);
  let newCol = urlParams.get("col");
  let newRow = urlParams.get("row");
  if (newCol === null || newRow === null) return;
  newCol = Number(newCol);
  newRow = Number(newRow);
  if (isNaN(newCol) || isNaN(newRow)) return;
  return { row: newRow, col: newCol };
}

/** Returns the quotient and modulo of x / y */
export const divmod = (x, y) => [Math.floor(x / y), x % y];

export function getDefaultTheme() {
  if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches)
    return Themes.DARK;
  else return Themes.LIGHT;
}

export function isModelViewOnly(permissions, viewingPreviousVersion) {
  if (viewingPreviousVersion) return true;
  if (!permissions) return false; // legacy support
  return !(
    permissions[EPermissionLevels.edit] ||
    permissions[EPermissionLevels.fullAccess] ||
    permissions[EPermissionLevels.owner]
  );
}

export function vsNavigate(moduleName, coordinates) {
  if (!moduleName) return;
  let coordsQuery = coordinates && `?row=${coordinates.row}&col=${coordinates.col}`;
  const url = moduleName.replace(/ /g, "-");
  return coordsQuery ? url + coordsQuery : url;
}

/**
 * For generating IDs - given a name, normalise it and add a suffix if
 * necessary so that it doesn't conflict with existing keys in an object.
 */
export function generateUniqueKey(name, object) {
  const normalised = name
    .toLowerCase()
    .replace(/-/g, " ")
    .replace(/[^\w\s]/g, "")
    .replace(/\s+/g, " ")
    .trim()
    .replace(/ /g, "-");
  let id = normalised;
  let n = 0;

  while (object[id]) {
    n += 1;
    id = normalised + "-" + n;
  }

  return id;
}

export const sortItemsByMatch = (match, items, cap) => {
  if (!match) return items.sort((a, b) => a.label.localeCompare(b.label));
  const query = match.toLowerCase();

  // Partition the array into matching and non-matching items
  const matching = [];
  const nonMatching = [];

  items.forEach((item) => {
    const label = item.label.toLowerCase();
    if (label.startsWith(query)) {
      if (matching.length < cap) {
        matching.push(item);
      } else {
        nonMatching.push(item);
      }
    } else {
      nonMatching.push(item);
    }
  });

  // Sort the non-matching items alphabetically
  nonMatching.sort((a, b) => a.label.localeCompare(b.label));
  return [...matching, ...nonMatching];
};

/**
 * Utilty to download a file - if fileName is not supplied, defaults to 'Valsys Download'
 * */
export const downloadFile = (content, fileName = "Valsys Download") => {
  const encodedUri = encodeURI(content);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const colorizeNumeric = (value, theme) => {
  if (Number(value) > 0) return colors.intentSuccess;
  if (Number(value) < 0) return theme === Themes.DARK ? colors.textColorRed : colors.intentDanger;
};

/**
 * Function to shallow merge multiple objects but to only overwrite an existing property
 * if the incoming is not nullish value != undefined, .
 */
export const shallowMergeExcludingNullishValues = (...objects) => {
  const mergedObject = {};
  objects.forEach((object) => {
    for (const key in object) {
      if (object[key] != undefined) mergedObject[key] = object[key];
    }
  });
  return mergedObject;
};
