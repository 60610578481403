import React, { Dispatch, MouseEventHandler, SetStateAction } from "react";
import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { IModelHistoryTable } from "types/modelHistory";

interface IDeleteCustomTableDialogProps {
  deletingTable?: IModelHistoryTable;
  isOpen: boolean;
  onTableDelete: (tableName: string) => void;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const DeleteCustomTableDialog: React.FC<IDeleteCustomTableDialogProps> = ({
  deletingTable,
  isOpen,
  onTableDelete,
  setDialogOpen,
}) => {
  const close = () => {
    setDialogOpen(false);
  };
  const onDelete: MouseEventHandler<HTMLElement> = () => {
    if (deletingTable) {
      onTableDelete(deletingTable.uid);
    }
  };
  return (
    <Dialog icon="delete" isOpen={isOpen} onClose={close} title="Delete Custom Table">
      <div className={Classes.DIALOG_BODY}>
        <p>
          Are you sure you want to permanently delete the custom table <b>{deletingTable?.name}</b>?
        </p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={close} text="Cancel" />
          <Button
            icon="delete"
            intent={Intent.DANGER}
            onClick={onDelete}
            text="Delete Custom Table"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteCustomTableDialog;
