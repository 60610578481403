import React, { useContext, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";
import { connect } from "react-redux";
import { Flex, MaybeStickyDiv, SideMenuHeader } from "components/utility/StyledComponents";
import {
  Button,
  Callout,
  ControlGroup,
  Divider,
  Icon,
  Intent,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import {
  addEventTagFilter,
  clearEventTagFilters,
  removeEventTagFilter,
} from "actions/eventHistoryActions";
import { fetchUndoHistory } from "actions/dataEntryActions";
import { eventSelector } from "selectors/eventHistorySelectors";
import { getEventFilters } from "utils/eventHistoryUtils";
import { caseModulesSelector, currentCaseSelector } from "selectors/modelSelectors";
import FilterAndSortEvents from "components/model/FilterAndSortEvents";
import LoadingIndicator from "components/utility/LoadingIndicator";
import HistoryEvent from "components/model/HistoryEvent";
import AppContext from "context/appContext";

const HiddenEventContainer = styled.div`
  border-left: 2px solid black;
`;

const EventHistory = ({
  addEventTagFilter,
  caseModules,
  clearEventTagFilters,
  currentCase,
  events,
  fetchingUndoHistory,
  fetchUndoHistory,
  fetchHistoryFailed,
  locked,
  modelID,
  noHistory,
  removeEventTagFilter,
  tagFilters,
}) => {
  const currentRef = useRef(null);
  const [expandedHiddenGroups, setExpandedHiddenGroups] = useState([]);
  const {
    config: { theme },
  } = useContext(AppContext);

  function scrollToCurrent() {
    if (currentRef?.current) currentRef.current.scrollIntoView(false);
  }

  function handleTagClick(value) {
    addEventTagFilter(value);
  }

  const parsedEvents = useMemo(() => {
    if (!events || !caseModules) return events;
    const parsed = events.map((e) => {
      const originModuleID = e?.undo?.moduleID || e?.redo?.moduleID;
      const moduleName = caseModules.find((mod) => mod.uid === originModuleID)?.name;
      return {
        ...e,
        moduleName: moduleName || "Deleted module",
      };
    });
    let currentIndex = parsed.findIndex((event) => event.state !== "REDO");
    if (currentIndex === -1) currentIndex = parsed.length;
    parsed.splice(currentIndex, 0, {
      action: "CURRENT",
      state: "CURRENT",
      timestamp: new Date().getTime(),
    });
    return parsed;
  }, [caseModules, events]);

  const filteredEvents = useMemo(() => {
    if (tagFilters.length < 1 || !caseModules) return parsedEvents;
    const filtered = parsedEvents.map((event) => {
      if (event.action !== "CURRENT") {
        let filterBy = getEventFilters(event);
        const isValid = filterBy.find((prop) => tagFilters.includes(prop));
        if (isValid) return event;
        return { redacted: true, ...event };
      }
      return event;
    });
    const collapsed = [];
    filtered.forEach((event, index) => {
      const lastElement = collapsed[collapsed.length - 1];
      const addToPrev =
        typeof lastElement === "object" && lastElement[lastElement.length - 1]?.index === index - 2;
      if (!event.redacted || event.state === "CURRENT") {
        collapsed.push(event);
      } else if (addToPrev) {
        collapsed[collapsed.length - 1].push(event);
      } else {
        collapsed.push([event]);
      }
    });
    return collapsed;
  }, [caseModules, parsedEvents, tagFilters]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        textAlign: "left",
        overflowY: "auto",
      }}
    >
      <MaybeStickyDiv position="top" pixels={0} pinned={true}>
        <SideMenuHeader
          alignItems="flex-start"
          flexDirection="row"
          justifyContent="space-between"
          theme={theme}
        >
          <Flex alignItems="center" fullHeight>
            <span style={{ fontVariant: "small-caps", fontSize: "14px", marginBottom: "0px" }}>
              History
            </span>
            <Divider />
            {fetchingUndoHistory ? (
              <Tag icon={<Icon icon="refresh" size={14} />} minimal>
                {fetchingUndoHistory ? "Updating..." : "History loaded."}
              </Tag>
            ) : null}
          </Flex>
          <Flex justifyContent={"flex-start"}>
            <ControlGroup>
              <FilterAndSortEvents
                clearEventTagFilters={clearEventTagFilters}
                events={events}
                removeEventTagFilter={removeEventTagFilter}
                tagFilters={tagFilters}
              />
              <Divider />
              <Tooltip content="Go to current event">
                <Button icon="locate" onClick={scrollToCurrent} minimal />
              </Tooltip>
            </ControlGroup>
          </Flex>
        </SideMenuHeader>
        <Divider style={{ margin: "0" }} />
      </MaybeStickyDiv>
      {noHistory ? (
        <Callout intent={Intent.PRIMARY} title="No actions performed.">
          Previously performed actions will appear here.
        </Callout>
      ) : null}
      {events?.length > 0 ? (
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          flexWrap="wrap"
          fullWidth
          justifyContent="center"
        >
          {filteredEvents.map((event, mapIndex) => {
            if (event.state) {
              if (event.action === "CURRENT") {
                return (
                  <HistoryEvent
                    disabled={locked}
                    event={event}
                    handleTagClick={handleTagClick}
                    key={`${event.timestamp}-${event.messageID}`}
                    currentRef={currentRef}
                  />
                );
              }
              return (
                <HistoryEvent
                  disabled={locked}
                  event={event}
                  handleTagClick={handleTagClick}
                  key={`${event.timestamp}-${event.messageID}`}
                />
              );
            } else {
              if (expandedHiddenGroups.indexOf(mapIndex) !== -1) {
                return (
                  <HiddenEventContainer key={event[0].timestamp}>
                    <Flex alignItems="center" fullWidth justifyContent="flex-start">
                      <Button
                        icon="minus"
                        minimal
                        onClick={() =>
                          setExpandedHiddenGroups(
                            expandedHiddenGroups.filter((g) => g !== mapIndex)
                          )
                        }
                        text="Hidden by filters:"
                      />
                    </Flex>
                    {event.map((hiddenEvent) => (
                      <HistoryEvent
                        disabled={locked}
                        key={`${event[0].timestamp}-${event[0].messageID}`}
                        event={hiddenEvent}
                      />
                    ))}
                  </HiddenEventContainer>
                );
              }
              return (
                <Button
                  icon={"double-caret-vertical"}
                  key={event[0].timestamp}
                  minimal
                  onClick={() =>
                    setExpandedHiddenGroups(
                      Array.from(new Set([...expandedHiddenGroups, mapIndex]))
                    )
                  }
                  style={{ alignSelf: "center" }}
                  text={`${event.length} events hidden by filters`}
                />
              );
            }
          })}
        </Flex>
      ) : fetchHistoryFailed ? (
        <Flex>
          <Tag intent={Intent.DANGER} minimal>
            Unable to pull event history!
          </Tag>
          <Divider />
          <Button
            icon="refresh"
            minimal
            small
            text="Retry"
            intent={Intent.PRIMARY}
            onClick={() => fetchUndoHistory(modelID, currentCase.uid)}
          />
        </Flex>
      ) : !noHistory ? (
        <LoadingIndicator loading={true} status={{ message: "LOADING..." }} />
      ) : null}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    caseModules: Object.values(caseModulesSelector(state)),
    currentCase: currentCaseSelector(state),
    events: eventSelector(state),
    fetchingUndoHistory: state.eventHistory.fetchingUndoHistory,
    fetchHistoryFailed: state.eventHistory.fetchHistoryFailed,
    locked: state.dataEntry.ui.locked,
    modelID: state.model.modelInfo.id,
    noHistory: state.eventHistory.noHistory,
    tagFilters: state.eventHistory.tagFilters,
  };
}

const mapDispatchToProps = {
  addEventTagFilter,
  clearEventTagFilters,
  fetchUndoHistory,
  removeEventTagFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventHistory);
