import {
  Alignment,
  Button,
  ButtonGroup,
  Classes,
  DialogFooter,
  DialogProps,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  NumericInput,
  Tooltip,
} from "@blueprintjs/core";
import Highcharts from "highcharts/highstock";
import { IconNames } from "@blueprintjs/icons";
import {
  Flex,
  FullSizeDiv,
  FullWidthDialog,
  FullWidthSwitch,
  Grid,
  NonScrollableDialogBody,
  NormalAlignDivider,
  PaddedContent,
  styledScrollBar,
} from "components/utility/StyledComponents";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import React, { useContext, useState } from "react";
import { ChartWidgetConfig, Widget as TWidget } from "types/widget";
import Widget from "components/dashboard/widget/Widget";
import LoadingIndicator from "components/utility/LoadingIndicator";
import {
  ChartDataFrequency,
  chartDefaults,
  ChartPeriodType,
  VSChartToHighchartsSeriesMap,
  YAxisId,
  YAxisOptions,
} from "constants/chartConstants";
import { EWidgetType2, VSLChart } from "types/vsl";
import {
  getChartFrequency,
  getChartPeriodType,
  getChartPeriodTypeOptions,
  getConfigFormat,
  getCurrentChartConfig,
  getXAxisType,
} from "utils/chartUtils";
import ChartSeriesSettings from "./ChartSeriesSettings";
import styled from "styled-components";
import { EUnit, EValFormat, IFormatObject } from "types/format";
import { DEFAULT_DECIMAL_PLACES } from "utils/formatter";
import { useParams } from "react-router-dom";
import useView from "hooks/dashboard/useView";
import isFunction from "lodash.isfunction";
import YAxisMinMaxMenu from "components/dashboard/widget/chart/YAxisMinMaxMenu";
import XAxisMinMaxMenu from "components/dashboard/widget/chart/XAxisMinMaxMenu";
import { ViewWidget } from "types/view";

interface EditChartDialogProps extends DialogProps {
  chartRendererConfig?: ChartWidgetConfig;
  chartXAxisMin?: number | null;
  chartXAxisMax?: number | null;
  data: VSLChart;
  defaultYAxisBounds: {
    [key in YAxisId]: {
      min?: number | null;
      max?: number | null;
    };
  };
  onClose: () => void;
  type:
    | EWidgetType2.BAR_CHART
    | EWidgetType2.LINE_CHART
    | EWidgetType2.PIE_CHART
    | EWidgetType2.SCATTER_CHART;
  updateConfig: (details: Partial<ChartWidgetConfig>) => Promise<boolean>;
  updateWidget: (details: Partial<ViewWidget>) => Promise<boolean>;
  widget: TWidget;
}

enum SettingsType {
  CHART_AREA = "Chart Area",
  SERIES = "Series",
  X_AXIS = "X Axis",
  Y_AXIS_RIGHT = "Y Axis (Right)",
  Y_AXIS_LEFT = "Y Axis (Left)",
}

/**
 * Full featured editing of chart features and formatting.
 * */
const EditChartDialog: React.FC<EditChartDialogProps> = ({
  chartRendererConfig,
  chartXAxisMin,
  chartXAxisMax,
  data,
  defaultYAxisBounds,
  onClose,
  type,
  updateConfig,
  updateWidget,
  widget,
  ...dialogProps
}) => {
  const chartDatasets = data.datasets;
  const {
    config: { theme },
  } = useContext(AppContext);
  const { viewId } = useParams();
  const [settingsType, setSettingsType] = useState(SettingsType.CHART_AREA);
  const { mutate } = useView(viewId);

  const currentChartConfig = getCurrentChartConfig(
    chartDefaults[type],
    type,
    chartRendererConfig as ChartWidgetConfig
  );

  const chartConfig = Highcharts.merge(chartDefaults[type], chartRendererConfig);

  const frequency = getChartFrequency(data);

  const periodTypes = getChartPeriodTypeOptions(data);
  const periodType = getChartPeriodType(chartConfig.periodType, periodTypes, data?.opts?.time);
  const enablePeriodTypeSelection = periodTypes.length > 1;

  const xAxisType = getXAxisType(type, periodType);
  const metricsAvailable = data?.datasets?.length === 2 && xAxisType === "category";

  const leftAxisActive = Object.values(chartConfig?.seriesOptions ?? [])?.some(
    (series) => series.axis === YAxisId.LEFT
  );

  const leftYAxisFormat = getConfigFormat(data, YAxisId.LEFT, chartConfig);
  const rightYAxisFormat = getConfigFormat(data, YAxisId.RIGHT, chartConfig);
  const xAxisConfigFormat = getConfigFormat(data, null, chartConfig);

  let controls: JSX.Element | null;
  switch (settingsType) {
    case SettingsType.CHART_AREA:
      controls = (
        <ChartAreaSettings
          chartConfig={currentChartConfig}
          metricsAvailable={metricsAvailable}
          type={type}
          updateConfig={updateConfig}
        />
      );
      break;
    case SettingsType.SERIES:
      controls = (
        <ChartSeriesSettings
          chartRendererConfig={chartRendererConfig as ChartWidgetConfig}
          chartDatasets={chartDatasets}
          type={type}
          updateConfig={updateConfig}
        />
      );
      break;
    case SettingsType.X_AXIS:
      controls = (
        <XAxisSettings
          chartXAxisMax={chartXAxisMax}
          chartXAxisMin={chartXAxisMin}
          chartConfig={currentChartConfig}
          config={chartRendererConfig as ChartWidgetConfig}
          enablePeriodType={enablePeriodTypeSelection}
          frequency={frequency}
          periodType={periodType}
          periodTypes={periodTypes}
          type={type}
          updateConfig={updateConfig}
          xAxisConfigFormat={xAxisConfigFormat}
        />
      );
      break;
    case SettingsType.Y_AXIS_RIGHT:
      controls = (
        <YAxisSettings
          axis={YAxisId.RIGHT}
          axisFormat={rightYAxisFormat}
          axisSettings={currentChartConfig?.rightYAxisOptions}
          config={chartRendererConfig as ChartWidgetConfig}
          defaultYAxisBounds={defaultYAxisBounds}
          type={type}
          updateConfig={updateConfig}
        />
      );
      break;
    case SettingsType.Y_AXIS_LEFT:
      controls = (
        <YAxisSettings
          axis={YAxisId.LEFT}
          axisFormat={leftYAxisFormat}
          axisSettings={currentChartConfig?.leftYAxisOptions}
          config={chartRendererConfig as ChartWidgetConfig}
          defaultYAxisBounds={defaultYAxisBounds}
          type={type}
          updateConfig={updateConfig}
        />
      );
      break;
    default:
      controls = null;
  }

  const handleDialogClose = async () => {
    if (isFunction(mutate)) {
      await mutate();
    }
    onClose();
  };

  return (
    <FullWidthDialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      icon={IconNames.SETTINGS}
      onClose={handleDialogClose}
      title="Chart Settings"
      {...dialogProps}
    >
      <NonScrollableDialogBody>
        <Grid fullHeight rows="auto 1fr">
          <FullSizeDiv fullWidth>
            <PaddedContent padding="0 10px 10px 0px">
              <ButtonGroup>
                <Button
                  active={settingsType === SettingsType.CHART_AREA}
                  intent={settingsType === SettingsType.CHART_AREA ? Intent.PRIMARY : undefined}
                  onClick={() => setSettingsType(SettingsType.CHART_AREA)}
                  text="Chart Area"
                />
                <Button
                  active={settingsType === SettingsType.SERIES}
                  intent={settingsType === SettingsType.SERIES ? Intent.PRIMARY : undefined}
                  onClick={() => setSettingsType(SettingsType.SERIES)}
                  text="Series"
                />
                <Button
                  active={settingsType === SettingsType.X_AXIS}
                  intent={settingsType === SettingsType.X_AXIS ? Intent.PRIMARY : undefined}
                  onClick={() => setSettingsType(SettingsType.X_AXIS)}
                  text="X Axis"
                />
                {leftAxisActive && (
                  <Button
                    active={settingsType === SettingsType.Y_AXIS_LEFT}
                    intent={settingsType === SettingsType.Y_AXIS_LEFT ? Intent.PRIMARY : undefined}
                    onClick={() => setSettingsType(SettingsType.Y_AXIS_LEFT)}
                    text="Y Axis (Left)"
                  />
                )}
                <Button
                  active={settingsType === SettingsType.Y_AXIS_RIGHT}
                  intent={settingsType === SettingsType.Y_AXIS_RIGHT ? Intent.PRIMARY : undefined}
                  onClick={() => setSettingsType(SettingsType.Y_AXIS_RIGHT)}
                  text={leftAxisActive ? "Y Axis (Right)" : "Y Axis"}
                />
              </ButtonGroup>
            </PaddedContent>
            <NormalAlignDivider style={{ margin: "0px 0px 10px 0px" }} />
          </FullSizeDiv>
          <Grid cols="300px auto 1fr" fullHeight style={{ overflow: "hidden" }}>
            <StyledControlScrollContainer $theme={theme}>{controls}</StyledControlScrollContainer>
            <NormalAlignDivider style={{ margin: "0px 10px" }} />
            {widget ? (
              <Widget
                enableEditWidgetDialog={false}
                enableTitleMenu={false}
                showControlRibbon={false}
                updateWidget={updateWidget}
                widget={widget}
              />
            ) : (
              <LoadingIndicator status={{ intent: Intent.PRIMARY, message: "Loading Widget..." }} />
            )}
          </Grid>
        </Grid>
      </NonScrollableDialogBody>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleDialogClose} text="Close" />
        </div>
      </DialogFooter>
    </FullWidthDialog>
  );
};

interface ChartAreaSettingsProps {
  chartConfig: ReturnType<typeof getCurrentChartConfig>;
  metricsAvailable: boolean;
  type:
    | EWidgetType2.BAR_CHART
    | EWidgetType2.LINE_CHART
    | EWidgetType2.PIE_CHART
    | EWidgetType2.SCATTER_CHART;
  updateConfig: (details: Partial<ChartWidgetConfig>) => void;
}

const ChartAreaSettings: React.FC<ChartAreaSettingsProps> = ({
  chartConfig,
  metricsAvailable,
  type,
  updateConfig,
}) => {
  const {
    correlationMetricsEnabled,
    dataLabelsEnabled,
    legendEnabled,
    tooltipEnabled,
    transformationEnabled,
  } = chartConfig;

  return (
    <Flex
      alignItems="flex-start"
      flexDirection="column"
      fullWidth
      justifyContent="space-between"
      rowGap={15}
    >
      <FullWidthSwitch
        alignIndicator={Alignment.RIGHT}
        checked={dataLabelsEnabled}
        label="Show Data Labels"
        onChange={() => {
          updateConfig({
            plotOptions: {
              [VSChartToHighchartsSeriesMap[type]]: { dataLabels: { enabled: !dataLabelsEnabled } },
            },
          });
        }}
      />
      <FullWidthSwitch
        alignIndicator={Alignment.RIGHT}
        checked={legendEnabled}
        label="Show Legend"
        onChange={() => {
          updateConfig({
            legend: { enabled: !legendEnabled },
          });
        }}
      />
      <FullWidthSwitch
        alignIndicator={Alignment.RIGHT}
        checked={tooltipEnabled}
        label="Enable Mouseover"
        onChange={() => {
          updateConfig({
            tooltip: { enabled: !tooltipEnabled },
          });
        }}
      />
      {(type === EWidgetType2.LINE_CHART || type === EWidgetType2.BAR_CHART) && (
        <FullWidthSwitch
          alignIndicator={Alignment.RIGHT}
          checked={transformationEnabled}
          label="Enable Transformation Options"
          onChange={() => {
            updateConfig({
              enableTransformationOptions: !transformationEnabled,
            });
          }}
        />
      )}

      {(type === EWidgetType2.LINE_CHART || type === EWidgetType2.BAR_CHART) && (
        <div>
          <FullWidthSwitch
            alignIndicator={Alignment.RIGHT}
            checked={correlationMetricsEnabled}
            disabled={!metricsAvailable}
            label="Enable Correlation Metrics"
            onChange={() => {
              updateConfig({
                correlationMetrics: !correlationMetricsEnabled,
              });
            }}
          />
          <p className={Classes.TEXT_SMALL}>
            <span>
              This feature calculates and displays a set of correlation metrics when the chart
              contains two valid time-series. It is available only when exactly two series are
              present and the period type is calendar or fiscal.
            </span>
          </p>
        </div>
      )}
    </Flex>
  );
};

interface XAxisSettings {
  chartConfig: ReturnType<typeof getCurrentChartConfig>;
  chartXAxisMin?: number | null;
  chartXAxisMax?: number | null;
  config?: ChartWidgetConfig;
  enablePeriodType: boolean;
  frequency: ChartDataFrequency;
  periodType: ChartPeriodType;
  periodTypes: ChartPeriodType[];
  type:
    | EWidgetType2.BAR_CHART
    | EWidgetType2.LINE_CHART
    | EWidgetType2.PIE_CHART
    | EWidgetType2.SCATTER_CHART;
  updateConfig: (details: Partial<ChartWidgetConfig>) => void;
  xAxisConfigFormat: Partial<IFormatObject>;
}

const XAxisSettings: React.FC<XAxisSettings> = ({
  chartConfig,
  config,
  chartXAxisMax,
  chartXAxisMin,
  enablePeriodType,
  frequency,
  periodType,
  periodTypes,
  type,
  updateConfig,
  xAxisConfigFormat,
}) => {
  const delineateForecasts = chartConfig?.delineateForecasts;
  const rangeSelectorEnabled = chartConfig?.rangeSelectorEnabled;
  const navigatorEnabled = chartConfig?.navigatorEnabled;
  const xAxisType = getXAxisType(type, periodType);
  const showAxisBoundsControls = type === EWidgetType2.SCATTER_CHART;
  const enableDelineateForecasts =
    type === EWidgetType2.BAR_CHART || type === EWidgetType2.LINE_CHART;
  return (
    <Flex
      alignItems="flex-start"
      flexDirection="column"
      fullWidth
      justifyContent="flex-start"
      rowGap={15}
    >
      <StyledFormGroup inline label="Title:" $marginBottom={0}>
        <InputGroup
          placeholder="Type new axis title here"
          type="text"
          onChange={(e) => {
            updateConfig({
              xAxis: {
                ...config?.xAxis,
                title: {
                  text: e.target.value,
                },
              },
            });
          }}
          value={config?.xAxis?.title?.text || ""}
        />
      </StyledFormGroup>
      {showAxisBoundsControls && (
        <StyledFormGroup inline label="Min/Max:" $marginBottom={0}>
          <XAxisMinMaxMenu
            chartRendererConfig={config}
            chartXAxisMax={chartXAxisMax}
            chartXAxisMin={chartXAxisMin}
            configFormat={xAxisConfigFormat}
            updateConfig={updateConfig}
          />
        </StyledFormGroup>
      )}
      <FullWidthSwitch
        alignIndicator={Alignment.RIGHT}
        checked={rangeSelectorEnabled}
        disabled={!(type === EWidgetType2.LINE_CHART) && !(type === EWidgetType2.BAR_CHART)}
        inline
        label="Enable Range Selector:"
        onChange={() => {
          updateConfig({
            rangeSelector: { enabled: !rangeSelectorEnabled },
          });
        }}
      />
      <FullSizeDiv fullWidth>
        <Tooltip
          content={xAxisType === "category" ? "Navigator is unavailable for CY/FY charts." : ""}
          disabled={xAxisType !== "category"}
          fill
        >
          <FullWidthSwitch
            alignIndicator={Alignment.RIGHT}
            checked={navigatorEnabled}
            disabled={
              xAxisType === "category" ||
              (!(type === EWidgetType2.LINE_CHART) && !(type === EWidgetType2.BAR_CHART))
            }
            inline
            label="Enable Navigator:"
            onChange={() => {
              updateConfig({
                navigator: { enabled: !navigatorEnabled },
              });
            }}
          />
        </Tooltip>
      </FullSizeDiv>
      <FullSizeDiv fullWidth>
        <Tooltip content="Frequency is determined by chart datasets." fill>
          <StyledFormGroup disabled inline label="Frequency:">
            <HTMLSelect
              disabled
              onChange={(e) => {
                updateConfig({
                  frequency: e.currentTarget.value as ChartDataFrequency,
                });
              }}
              options={[`Default (${frequency})`]}
              value={`Default (${frequency})`}
            />
          </StyledFormGroup>
        </Tooltip>
      </FullSizeDiv>
      <Tooltip content="Period type is determined by chart datasets." fill>
        <StyledFormGroup disabled={!enablePeriodType} inline label="Period Type:">
          <HTMLSelect
            disabled={!enablePeriodType}
            onChange={(e) => {
              updateConfig({
                periodType: e.currentTarget.value as ChartPeriodType,
              });
            }}
            options={periodTypes}
            value={periodType}
          />
        </StyledFormGroup>
      </Tooltip>
      <FullWidthSwitch
        alignIndicator={Alignment.RIGHT}
        checked={delineateForecasts}
        disabled={!enableDelineateForecasts}
        inline
        label="Delineate Forecasts:"
        onChange={() => {
          updateConfig({
            delineateForecasts: !delineateForecasts,
          });
        }}
      />
    </Flex>
  );
};

interface YAxisSettings {
  axis: YAxisId;
  axisFormat: Partial<IFormatObject>;
  axisSettings: YAxisOptions;
  config?: ChartWidgetConfig;
  defaultYAxisBounds: {
    [key in YAxisId]: {
      min?: number | null;
      max?: number | null;
    };
  };
  type:
    | EWidgetType2.BAR_CHART
    | EWidgetType2.LINE_CHART
    | EWidgetType2.PIE_CHART
    | EWidgetType2.SCATTER_CHART;
  updateConfig: (details: Partial<ChartWidgetConfig>) => void;
}

const YAxisSettings: React.FC<YAxisSettings> = ({
  axis,
  axisFormat,
  config,
  defaultYAxisBounds,
  type,
  updateConfig,
}) => {
  const showAxisBoundsControls = [
    EWidgetType2.BAR_CHART,
    EWidgetType2.LINE_CHART,
    EWidgetType2.SCATTER_CHART,
  ].includes(type);

  return (
    <Flex alignItems="flex-start" flexDirection="column" fullWidth rowGap={15}>
      <StyledFormGroup inline label="Title:" $marginBottom={0}>
        <InputGroup
          placeholder="Type new axis title here"
          type="text"
          onChange={(e) => {
            updateConfig({
              ...config,
              yAxis: {
                ...config?.yAxis,
                [axis]: {
                  ...config?.yAxis?.[axis],
                  title: {
                    text: e.target.value,
                  },
                },
              },
            });
          }}
          value={config?.yAxis?.[axis]?.title?.text || ""}
        />
      </StyledFormGroup>
      {showAxisBoundsControls && (
        <StyledFormGroup inline label="Min/Max:" $marginBottom={0}>
          <YAxisMinMaxMenu
            axis={axis}
            chartRendererConfig={config}
            chartYAxisMax={defaultYAxisBounds[axis].max}
            chartYAxisMin={defaultYAxisBounds[axis].min}
            configFormat={axisFormat}
            updateConfig={updateConfig}
          />
        </StyledFormGroup>
      )}
      <StyledFormGroup inline label="Format:" $marginBottom={0}>
        <HTMLSelect
          options={Object.values<string>(EValFormat)
            .map((values) => values)
            .concat(["Default"])}
          onChange={(e) => {
            const nextValFormat =
              e.currentTarget.value === "Default" ? undefined : e.currentTarget.value;
            updateConfig({
              ...config,
              yAxis: {
                ...config?.yAxis,
                [axis]: { ...config?.yAxis?.[axis], valFormat: nextValFormat },
              },
            });
          }}
          value={axisFormat.valFormat ?? "Default"}
        />
      </StyledFormGroup>
      <StyledFormGroup inline label="Unit:" $marginBottom={0}>
        <HTMLSelect
          options={Object.values<string>(EUnit)
            .map((value) => value)
            .concat(["Default"])}
          onChange={(e) => {
            const nextUnit =
              e.currentTarget.value === "Default" ? undefined : e.currentTarget.value;
            updateConfig({
              ...config,
              yAxis: {
                ...config?.yAxis,
                [axis]: { ...config?.yAxis?.[axis], unit: nextUnit },
              },
            });
          }}
          value={axisFormat.unit ?? "Default"}
        />
      </StyledFormGroup>
      <StyledFormGroup inline label="Decimal Places:" $marginBottom={0}>
        <NumericInput
          min={0}
          max={20}
          onValueChange={(value) =>
            updateConfig({
              ...config,
              yAxis: {
                ...config?.yAxis,
                [axis]: { ...config?.yAxis?.[axis], decimalPlaces: value },
              },
            })
          }
          style={{ width: "80px" }}
          value={axisFormat?.decimalPlaces ?? DEFAULT_DECIMAL_PLACES}
        />
      </StyledFormGroup>
    </Flex>
  );
};

const StyledFormGroup = styled(FormGroup)<{ $marginBottom?: number }>`
  justify-content: space-between;
  margin-bottom: ${({ $marginBottom }) => $marginBottom ?? 5}px;
  width: 100%;
`;

const StyledControlScrollContainer = styled.div`
  overflow: auto;
  ${styledScrollBar}
`;

export default EditChartDialog;
