import React, { createRef } from "react";
import styled from "styled-components";
import dagreD3 from "dagre-d3";
import { zoom, zoomIdentity } from "d3-zoom";
import { select } from "d3-selection";
import { curveMonotoneY } from "d3-shape";
import colorVars from "styles/colors.module.scss";

import { colors } from "constants/uiConstants";

const StyledGraphContainer = styled.div`
  height: calc(100% - 40px);
`;

class DagreGraph extends React.Component {
  constructor(props) {
    super(props);

    this._drawChart = this._drawChart.bind(this);
    this._addEventListeners = this._addEventListeners.bind(this);

    this.state = {
      defaultModStyles: `fill: ${colorVars.darkGray4}; stroke-width: 3px; cursor: pointer;`,
      defaultDarkModStyles: `fill: ${colorVars.lightGray5}; stroke-width: 3px; cursor: pointer;`,

      coreModStyle: `stroke: ${colorVars.blue1}`,
      commonModStyle: `stroke: ${colorVars.red1}`,
      specialistModStyle: `stroke: ${colorVars.green1}`,
      DCFModStyle: `stroke: ${colorVars.gray4}`,
      activeModStyle: `fill: ${colorVars.gray1}; color: ${colorVars.textColorDark}; cursor: pointer;`,
      x: null,
      y: null,
      theme: "dark",
      nodes: props.nodes,
      links: props.links,
    };
  }

  svgRef = createRef();
  innerGRef = createRef();
  getD3ZoomEvent = () => require("d3-selection").event;

  _addEventListeners(g) {
    g.nodes().forEach((node) => {
      const n = select(`g#chart-module-${node}`);
      n.on("click", () => {
        this.props.onClick(node);
      });
    });
  }

  _drawChart() {
    let { currentModule, height, rankdir, theme, unlinked, width } = this.props;
    const { activeModStyle, defaultModStyles, defaultDarkModStyles, nodes, links } = this.state;
    if (nodes.length === 0) return;

    const g = new dagreD3.graphlib.Graph({ compound: true }).setGraph({
      rankdir,
      nodeSep: 15,
    });

    this.g = g;

    function truncateNodeLabel(label) {
      if (label.length > 20) {
        return `${label.slice(0, 17)} ...`;
      }
      return label;
    }

    if (unlinked) {
      g.setNode("unlinked_cluster", {
        clusterLabelPos: "top",
        style: `fill: ${theme === "dark" ? colorVars.darkAppBackgroundColor : "#fff"}`,
      });
    }

    nodes.forEach((node) => {
      g.setNode(node.id, {
        label: truncateNodeLabel(node.label),
        class: node.class || "",
        id: `chart-module-${node.id}`,
        style:
          node.id === currentModule
            ? activeModStyle
            : `${theme !== "dark" ? defaultDarkModStyles : defaultModStyles} ${
                this.state[`${node.type}ModStyle`]
              }`,
        labelType: node.labelType || "string",
        labelStyle:
          node.id === currentModule
            ? `cursor: pointer; fill: ${colorVars.textColorDark}`
            : `cursor: pointer; fill: ${
                theme === "dark" ? colorVars.textColorDark : colorVars.textColor
              }`,
      });
    });

    nodes.forEach((node) => {
      if (node.unlinked) g.setParent(node.id, "unlinked_cluster");
    });

    links.forEach((link) => {
      g.setEdge(link.source, link.target, {
        style: link.unlinked
          ? "stroke: none; fill: none;"
          : `stroke: ${colors.secondarymedgrey}; fill: none; stroke-width: 3`,
        label: link.label || "",
        class: link.class || "",
        curve: curveMonotoneY,
        arrowheadStyle: "display: none;",
        arrowheadClass: "core-arrow",
      });
    });

    let render = new dagreD3.render();
    let svg = select(this.svgRef.current);
    let inner = select(this.innerGRef.current);

    svg.attr("height", height);
    svg.attr("width", width);

    inner.selectAll("g.node").attr("title", function (v) {
      return v;
    });

    let z = zoom().on("zoom", () => {
      inner.attr("transform", this.getD3ZoomEvent().transform);
    });
    this._addEventListeners(g);
    render(inner, g);

    let initialScale = 0.8;
    if (width && g.graph().height > 0 && g.graph().width > 0) {
      initialScale = Math.min(width / g.graph().width, height / g.graph().height);
      let transform = zoomIdentity
        .translate(
          (width - g.graph().width * initialScale) / 2,
          (height - g.graph().height * initialScale) / 2
        )
        .scale(initialScale);

      svg.call(z.transform, transform);

      this._addEventListeners(g);
    }
  }

  componentDidMount() {
    this._drawChart();
  }

  componentDidUpdate(prevProps) {
    const { theme } = this.props;
    if (theme !== prevProps.theme) {
      this.setState({ theme });
    }
    this._drawChart();
  }

  static getDerivedStateFromProps(props, state) {
    const { currentModule, nodes, links } = props;
    if (
      props.nodes.length !== state.nodes.length ||
      !state.nodes.find((node) => currentModule === node.id)
    ) {
      return { nodes, links };
    } else {
      return null;
    }
  }

  render() {
    return (
      <StyledGraphContainer>
        <svg ref={this.svgRef} className={this.props.className || ""}>
          <g ref={this.innerGRef} />
        </svg>
      </StyledGraphContainer>
    );
  }
}

DagreGraph.defaultProps = {
  position: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};

export default DagreGraph;
