import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Callout,
  Classes,
  Dialog,
  FormGroup,
  H5,
  H6,
  InputGroup,
  Intent,
  UL,
} from "@blueprintjs/core";

import { Themes } from "constants/uiConstants";
import AppContext from "../../context/appContext";
import useDeleteModels from "hooks/useDeleteModels";

const DeleteModelDialog = ({
  isOpen,
  modelHistoryResponse,
  mutator,
  setDeleteModelsDialogOpen,
  setSelectedModels,
  toDelete = [],
}) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const [deleteCheck, setDeleteCheck] = useState("");
  const { deleteModels, deleting } = useDeleteModels({
    mutate: mutator,
    data: modelHistoryResponse,
  });
  const {
    config: { theme },
  } = useContext(AppContext);

  const closeDialog = () => {
    if (!deleting) {
      setDeleteModelsDialogOpen(false);
      setDeleteConfirmation("");
    }
  };

  const onDeleteSuccess = () => {
    setSelectedModels([]);
    setDeleteModelsDialogOpen(false);
    setDeleteCheck("");
    setDeleteConfirmation("");
  };

  const handleDeleteModels = () => {
    deleteModels([...toDelete], onDeleteSuccess);
  };

  useEffect(() => toDelete[0] && setDeleteCheck(toDelete[0].companyName || "Delete"), [toDelete]);

  return (
    <Dialog
      className={theme === Themes.DARK ? Classes.DARK : null}
      icon="delete"
      isOpen={isOpen}
      onClose={closeDialog}
      title="Delete Models"
    >
      <div className={Classes.DIALOG_BODY}>
        <Callout intent={Intent.DANGER}>
          <H5>Warning! Deleting models is permanent and cannot be undone.</H5>
        </Callout>
        <p className={Classes.RUNNING_TEXT}>
          You are about to permanently delete {toDelete.length}{" "}
          {toDelete.length > 1 ? "models" : "model"}, including model(s) of the following companies:
        </p>
        <UL>
          {toDelete.slice(0, 3).map((m) => (
            <li key={m.id}>{m.companyName}</li>
          ))}
          {toDelete.length > 3 ? (
            <li className={Classes.TEXT_MUTED}>... and {toDelete.length - 3} more</li>
          ) : null}
        </UL>
        <FormGroup
          helperText={
            deleteConfirmation.length !== 0
              ? deleteConfirmation === deleteCheck
                ? "Confirmation text matches!"
                : "Confirmation text does not match."
              : null
          }
          intent={
            deleteConfirmation.length !== 0
              ? deleteConfirmation === deleteCheck
                ? Intent.SUCCESS
                : Intent.WARNING
              : undefined
          }
          label={
            <H6>
              Type the following to continue:
              <p className={Classes.CODE_BLOCK}>{deleteCheck}</p>
            </H6>
          }
        >
          <InputGroup
            intent={
              deleteConfirmation.length !== 0
                ? deleteConfirmation === deleteCheck
                  ? Intent.SUCCESS
                  : Intent.WARNING
                : undefined
            }
            placeholder={deleteCheck}
            value={deleteConfirmation}
            onChange={(e) => setDeleteConfirmation(e.target.value)}
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button disabled={deleting} onClick={closeDialog} text="Cancel" />
          <Button
            disabled={deleting || deleteCheck !== deleteConfirmation}
            icon="delete"
            intent={Intent.DANGER}
            loading={deleting}
            onClick={handleDeleteModels}
            text="Permanently delete models"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteModelDialog;
