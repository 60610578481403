import { createContext, Dispatch, SetStateAction } from "react";
import { IAppConfig, ISize } from "types";
import { noop } from "utils/generic";
import { Milieu, TMilieu } from "constants/appConstants";
import defAppConf from "configs/defaultAppConfig.json";

interface IAppContext {
  appSize?: ISize;
  config: IAppConfig;
  milieu: TMilieu;
  setConfig: (config: Partial<IAppConfig>) => void;
  setLogoutDialogOpen: (isOpen: boolean) => void;
  toggleTheme: () => void;
  setActiveSideMenu: Dispatch<SetStateAction<number>>;
}

export const defaultAppConfig = defAppConf as unknown as IAppConfig;
export const importRegexp = /importer\/.*\/?(?!configure)/;
export const viewRegexp = /model\/uid=[a-zA-Z0-9]+/;
export const dataRegexp = /\/model\/uid=[A-Za-z0-9-]+\/data/;
export const DefaultAppContext = {
  config: defaultAppConfig,
  milieu: Milieu.MAIN,
  setConfig: noop,
  setLogoutDialogOpen: noop,
  toggleTheme: noop,
  setActiveSideMenu: noop,
};

const AppContext = createContext<IAppContext>(DefaultAppContext);

export default AppContext;
