import { useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";

const DEFAULT_DEBOUNCE_TIME_MS = 400;

/** Debounce a value */
export const useDebouncedValue = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay, value]);
  return debouncedValue;
};
/**
 * debounced callback
 * @param callback
 * @param delay defaults to 400ms
 * @returns debounced version of callback function
 */
export const useDebouncedCallback = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (...args: any) => void,
  delay = DEFAULT_DEBOUNCE_TIME_MS
) => {
  const debouncedCallback = useMemo(() => {
    return debounce(callback, delay);
  }, [callback, delay]);
  return debouncedCallback;
};
