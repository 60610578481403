export const WidgetKeys = {
  // SPECIAL
  SOURCE_VIEW: "SOURCE_VIEW",
  TABLE: "TABLE",
  TREE: "TREE",
  EVENT_HISTORY: "EVENT_HISTORY",
  KEY_RATIOS: "KEY_RATIOS",
  SEARCH: "SEARCH",
  IMPORT_MODULE_EDITOR: "IMPORT_MODULE_EDITOR",

  // GENERIC
  SUMMARY: "SUMMARY",
  HISTORICAL_PRICE_GRAPH: "HISTORICAL_PRICE_GRAPH",
  CASH_FLOW: "CASH_FLOW",
  TERMINAL_VALUE: "TERMINAL_VALUE",
  SENSITIVITY_ANALYSES: "SENSITIVITY_ANALYSES",
  IMPLIED_FORWARD_MULTIPLES: "IMPLIED_FORWARD_MULTIPLES",

  // CPP
  CPP_PEER_MULTIPLES: "CPP_PEER_MULTIPLES",
  CPP_HISTORICAL_VALUATION: "CPP_HISTORICAL_VALUATION",
  CPP_MARKET_DATA: "CPP_MARKET_DATA",
  CPP_TARGET_PRICE: "CPP_TARGET_PRICE",
  CPP_SCENARIOS: "CPP_SCENARIOS",
  CPP_VALUATION_SUMMARY: "CPP_VALUATION_SUMMARY",
  CPP_EXPECTED_RETURN: "CPP_EXPECTED_RETURN",
  CPP_TOTAL_RETURN: "CPP_TOTAL_RETURN",
  CPP_FULL_SCENARIOS: "CPP_FULL_SCENARIOS",
  CPP_MULTIPLES: "CPP_MULTIPLES",
} as const;
