import React from "react";
import { Classes, Icon, IconName, Intent, MaybeElement } from "@blueprintjs/core";
import styled, { css } from "styled-components";

interface IColorableIconProps {
  icon: IconName | MaybeElement;
  intent?: Intent;
  color?: string;
}
/**
 * Small utility component to allow explicit setting of icon or spinner color.
 * Color can be set either via a blueprint Intent (this will behave as expected) or explicitly if a 'color' prop is supplied,
 */
const ColorableIcon: React.FC<IColorableIconProps> = ({ color, icon, intent }) => {
  return (
    <IconWrapper $color={color} $intent={intent}>
      <Icon icon={icon} />
    </IconWrapper>
  );
};

const getStrokeFromIntent = (intent?: Intent): string | undefined => {
  if (!intent || intent === Intent.NONE) return undefined;
  return "#fff";
};

const IconWrapper = styled.span<{ $color?: string; $intent?: Intent }>`
  ${({ $color }) =>
    $color
      ? css`
          // spinner classes
          .${Classes.SPINNER} .${Classes.SPINNER_TRACK} {
            stroke: ${$color} !important;
          }
          // span to capture icons
          span {
            color: ${$color} !important;
            fill: ${$color} !important;
          }
        `
      : undefined}
  ${({ $color, $intent }) =>
    $intent && $intent !== Intent.NONE && !$color
      ? css`
          // spinner classes
          .${Classes.SPINNER} .${Classes.SPINNER_TRACK} {
            stroke: ${getStrokeFromIntent($intent)} !important;
          }
          // span to capture icons
          span {
            color: ${getStrokeFromIntent($intent)} !important;
            fill: ${getStrokeFromIntent($intent)} !important;
          }
        `
      : undefined}
`;

export default ColorableIcon;
