import { isNeg, truncate } from "./generic";
import { BaseFormatObject, EValFormat, SuffixToThresholdMap, SuffixToUnitMap } from "types/format";
import { MISSING_VALUE } from "constants/appConstants";
import { getValidJSON } from "utils/api";

export const currencySymbols = ["$", "€", "£", "¥"];

// WARNING - Do not reduce this without handling update decimal places code in chart
// and table widgets.
export const DEFAULT_DECIMAL_PLACES = 1;
const DEFAULT_CURRENCY = "USD";

/** Take a value and a format options object and returns the formatted value */
// TODO: Replace 'f' function below with this one across app
export const formatValue = (value, format, truncateLength) => {
  if (!format || format.valFormat === EValFormat.PLAINTEXT) return value || "";

  // non plain text needs to be a Number to be formatted properly
  let formattedValue = Number(value);
  if (isNaN(formattedValue)) {
    // eslint-disable-next-line no-console
    console.warn("Invalid format object for value " + value, format);
    return value || "";
  }

  // unit
  if (format.unit) formattedValue = applyUnit(formattedValue, format.unit);

  // locale formatting
  const decimalPlaces = format.decimalPlaces ?? DEFAULT_DECIMAL_PLACES;
  const locale = format.locale ?? "en-US";
  const localeFormatterObj = {
    style: "decimal",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  };
  // extra props for currency
  switch (format.valFormat) {
    case EValFormat.PERCENTAGE:
      localeFormatterObj.style = "percent";
      break;
    case EValFormat.CURRENCY:
      localeFormatterObj.style = "currency";
      localeFormatterObj.currencyDisplay = "symbol";
      localeFormatterObj.currency = format?.currency || DEFAULT_CURRENCY;
      break;
  }

  formattedValue = formattedValue.toLocaleString(locale, localeFormatterObj);

  // extra string formatting
  if (format.valFormat === EValFormat.NUMERIC && isNeg(formattedValue))
    formattedValue = "(" + formattedValue.replace("-", "") + ")";
  if (format.valFormat === EValFormat.MULTIPLE) formattedValue += "x";

  // truncate
  if (truncateLength) return truncate(formattedValue, truncateLength, true);

  if (format.hasSuffix) formattedValue += SuffixToUnitMap[format.unit] || "";

  return formattedValue;
};

/** Legacy value formatting function. Please use formatValue */
export function f(fact, locale, defaultUnit, truncateLength) {
  if (!fact?.format) return fact.value || "";
  const { formula, value } = fact;
  const {
    currency: originalCurrency,
    decimalPlaces: originalDecimalPlaces,
    unit,
    valFormat,
  } = fact.format;
  let decimalPlaces = Number(originalDecimalPlaces) < 0 ? 0 : Number(originalDecimalPlaces);
  if (!decimalPlaces && decimalPlaces !== 0) decimalPlaces = DEFAULT_DECIMAL_PLACES;

  let val = value;

  /** If value is ommitted and formula is present (not empty string or space) coerce value to 0 for render. */
  if (!val && formula) {
    val = "0";
  }
  /**
   * If the value is "0" and the formula is "" then we can assume a blank cell and coerce the value to ""
   */
  if ((val === "" || val === " " || !val) && (formula === "" || formula === " " || !formula)) {
    return "";
  }

  if (valFormat === EValFormat.PLAINTEXT) {
    return value;
  } else {
    val = Number(val);
    if (isNaN(val)) val = "";
  }

  const negative = isNeg(value);
  const currency = originalCurrency || DEFAULT_CURRENCY;

  val = applyUnit(val, unit);

  switch (valFormat) {
    case EValFormat.NUMERIC:
      if (negative) {
        val = `(${val
          .toLocaleString(locale, {
            style: "decimal",
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
          })
          .replace("-", "")})`;
      } else {
        val = val.toLocaleString(locale, {
          style: "decimal",
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        });
      }
      break;
    case EValFormat.CURRENCY:
      val = Number(val).toLocaleString(locale, {
        currency,
        style: "currency",
        currencyDisplay: "symbol",
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      });
      break;
    case EValFormat.PERCENTAGE:
      val = Number(val).toLocaleString(locale, {
        style: "percent",
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      });
      break;
    case EValFormat.MULTIPLE:
      val = `${Number(val).toLocaleString(locale, {
        style: "decimal",
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      })}x`;
      break;
    default:
      val = val.toString();
  }
  if (truncateLength) return truncate(val, truncateLength, true);
  return val;
}

/**
 * Apply unit transformation to a number.
 */
export function applyUnit(value, unit) {
  // TODO: Why is this here...
  const unitString = unit?.toLowerCase();
  switch (unitString) {
    case "trillions":
      return value / 1000000000000;
    case "billions":
      return value / 1000000000;
    case "millions":
      return value / 1000000;
    case "thousands":
      return value / 1000;
    case "raw":
      return value;
    default:
      return value;
  }
}

/**
 * Returns value without unit transformation.
 */
export function getRawValueFromUnit(value, unit) {
  const unitString = unit?.toLowerCase();
  switch (unitString) {
    case "trillions":
      return value * 1000000000000;
    case "billions":
      return value * 1000000000;
    case "millions":
      return value * 1000000;
    case "thousands":
      return value * 1000;
    case "raw":
      return value;
    default:
      return value;
  }
}

/**
 * Takes a period in number format and creates a string.
 * With startYear parameter the string will include quarter information as a prefix and historical/predicted as a suffix
 * Without startYear paramter the string will include quarter information as a suffix (this format is used in formulas)
 */
export const periodFormatter = (period, startYear, increment) => {
  let periodString = Math.floor(period).toString();

  if (increment !== 1) {
    const quarterString = getPeriodQuarterString(period);
    if (startYear) {
      periodString = `${quarterString} ${periodString}`;
    } else {
      periodString += quarterString;
    }
  }

  if (startYear) {
    if (period > startYear) periodString += "E";
    else periodString += "A";
  }

  return periodString;
};

/**
 * Convert a numerical period value to a quarter period string
 *
 * @param {number} period - The numerical period to format.
 * */
export function getPeriodQuarterString(period) {
  const decimal = period % 1;
  switch (decimal) {
    case 0:
      return "Q1";
    case 0.25:
      return "Q2";
    case 0.5:
      return "Q3";
    case 0.75:
      return "Q4";
    default:
      return "";
  }
}

/**
 * Takes a numeric period and returns a formula format string of the period
 */
export const formatFxPeriod = (period) => {
  return Math.floor(period) + getPeriodQuarterString(period);
};

/**
 * Takes a period in fx format (eg 2010Q2) and returns its numeric equivalent (eg 2010.25)
 */
export const formulaPeriodToNumber = (period) => {
  if (!period) return;
  let stringPeriod = period.toString();
  if (stringPeriod[0] === "$") stringPeriod = stringPeriod.substr(1);
  const quarter = stringPeriod.substring(4, 6);
  if (!quarter) return Number.parseInt(stringPeriod);
  const year = Number.parseInt(stringPeriod.substring(0, 4));
  switch (quarter) {
    case "Q1":
      return year;
    case "Q2":
      return year + 0.25;
    case "Q3":
      return year + 0.5;
    case "Q4":
      return year + 0.75;
    default:
      return year;
  }
};

/**
 * Turn the object with copied cells content into plain text in tabular format
 * @param copyObject
 */
export function internalCopyToText(copyObject) {
  let copyString = "";
  const lineItems = copyObject.filter((item) => item.length > 0);
  lineItems.forEach((item, i) => {
    item.forEach((fact, j) => {
      const val = fact.value;
      if (val || val === 0) copyString += f(fact);
      if (j < item.length - 1) copyString += "\t";
    });
    if (i < lineItems.length - 1) copyString += "\n";
  });
  return copyString;
}

/** Turn external text into an object with coordinates that we can paste */
export function formatExternalCopy(text) {
  const paste = [];
  text = text.replace(/\r/gm, "");
  text.split("\n").forEach((row) => {
    if (!row) return;
    const pasteRow = [];
    row.split("\t").forEach((text) => {
      if (text) pasteRow.push({ value: text });
    });
    paste.push(pasteRow);
  });
  return paste;
}

/**
 * Mutates a fact's value and formula to numeric from formatted string
 * Used when pasting formatted values into the app
 * @param fact
 * @returns {Object} fact - Mutated fact with value and formula converted to numeric internal format
 */
export function convertFormattedNumericFact(fact) {
  const val = convertFormattedNumeric(fact.formula);
  if (!val && val !== 0) return fact;
  if (!Number.isNaN(val)) {
    // TODO: this was being used for automatic format detection
    //newFact.format = format;
    fact.value = String(val);
    fact.formula = "" + val;
  }
  return fact;
}

/**
 * Converts string with formatted value into numeric
 * Percentage: 4.5% -> 0.045
 * Financial: 123,456.789 -> 123456.789
 * @param {String} val - String with formatted value
 * @returns {Number} value
 */
export function convertFormattedNumeric(val) {
  let value = val;
  let valFormat = EValFormat.NUMERIC;

  value = value.replace(/\s/g, "");

  if (currencySymbols.includes(value[0])) value = value.replace(value[0], "");
  if (value[0] === "(" && value[value.length - 1] === ")")
    value = "-" + value.substring(1, value.length - 1);
  // currency was inside negative parenthesis
  if (value[0] === "-" && currencySymbols.includes(value[1])) value = value.replace(value[1], "");

  if (value[value.length - 1] === "x") value = value.replace("x", "");
  if (value[value.length - 1] === "%") {
    value = value.replace("%", "");
    valFormat = EValFormat.PERCENTAGE;
  }
  // negative parenthesis had x or % after
  if (value[0] === "(" && value[value.length - 1] === ")")
    value = "-" + value.substring(1, value.length - 1);

  // comma separated thousands
  if (/^-?(\d{1,3}|(\d{1,3},)?(\d{3},)*\d{3})(\.\d+)?$/.test(value)) {
    value = value.replace(/,/g, "");
  }

  value = Number(value);
  if (!Number.isNaN(value)) {
    return valFormat === EValFormat.PERCENTAGE ? value / 100 : value;
  }
}

export function titleCase(str) {
  if (!str) return;
  return str
    .split(" ")
    .map((str) => {
      const word = str.toLowerCase();
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

/**
 * Attempt to auto find and apply a unit to a number value.
 **/
export const getAutoUnitDivisor = (num) => {
  const found = SuffixToThresholdMap.find((x) => Math.abs(num) >= x.threshold);
  return found;
};

/**
 * Auto formats a supplied number, applying a unit and adding a unit suffix (K, M, B, T).
 *
 * Accepts an optional precision argument to determine decimal places.
 * */
export default function autoFormatNumber(
  num,
  precision = 2,
  fixedPointNotation = true,
  applySuffix = true
) {
  const unitObj = getAutoUnitDivisor(num);

  let value = Number(num);

  if (unitObj) {
    value = num / unitObj.threshold;
  }
  if (fixedPointNotation) {
    value = value.toFixed(precision);
  }
  if (applySuffix && unitObj) value += unitObj.suffix;
  return value;
}

export const formatFact = (value, format) => {
  if (!value) return MISSING_VALUE;
  return value !== MISSING_VALUE
    ? formatValue(value, {
        ...BaseFormatObject,
        ...getValidJSON(format || ""),
      })
    : MISSING_VALUE;
};
