import { useCallback, useEffect } from "react";
import { mutate, MutatorCallback, MutatorOptions } from "swr";
import { useApiCallback } from "hooks/useApiCallback";
import { IResponse } from "types";
import { TModelID } from "types/model";
import {
  EStatus,
  EMethods,
  MODEL_PERMISSIONS_ROUTE,
  SEARCH_USERS_ROUTE,
  SHARE_MODELS_ROUTE,
} from "constants/apiConstants";
import useSuccessFailToast from "hooks/useSuccessFailToast";
import { IUser, IUserPermission, TAtLeastEmailUser } from "../types/user";

type TShareModelsCallback = (
  user: TAtLeastEmailUser[],
  permissionLevel: { [key: string]: boolean }
) => void;
type TSearchUsersCallback = (term: string) => void;

type TUseShareModels = (
  modelID: TModelID,
  permissions: IUserPermission[] | null,
  mutatePermissions: (
    data?:
      | Promise<IResponse<IUserPermission[]>>
      | MutatorCallback<IResponse<IUserPermission[]>>
      | IResponse<IUserPermission[]>,
    opts?: boolean | MutatorOptions<IResponse<IUserPermission[]>>
  ) => Promise<IResponse<IUserPermission[]> | undefined>
) => {
  searching: boolean;
  searchResults: IResponse<IUser[]> | null;
  searchUsers: TSearchUsersCallback;
  shareModels: TShareModelsCallback;
  sharing: boolean;
  shareResponse: IResponse<unknown> | null;
};

/**
 * Hook to get a model's current sharing permissions and update them as necessary.
 */
const useShareModels: TUseShareModels = (modelID, permissions, mutatePermissions) => {
  const { callback: shareCallback, fetching: sharing, response: shareResponse } = useApiCallback();
  const {
    callback: searchCallback,
    fetching: searching,
    response: searchResults,
  } = useApiCallback<IUser[]>();

  const shareModels: TShareModelsCallback = useCallback(
    (userOrUsers, permissionLevel) => {
      if (permissions) {
        // Loop over the supplied users and make the appropriate reqs to the server
        const newPermissionObjects: IUserPermission[] = [];
        userOrUsers.forEach((user) => {
          const config = {
            method: EMethods.POST,
            body: { ...permissionLevel },
            email: user.email,
            modelID,
          };
          shareCallback(SHARE_MODELS_ROUTE, config);

          const { email, uid, fullname } = user;
          const permissionObject: IUserPermission = {
            ...(uid && { userID: uid }),
            fullname: fullname || "",
            email,
            ...permissionLevel,
          };
          newPermissionObjects.push(permissionObject);
        });
        const nextPermissions: IResponse<IUserPermission[]> = {
          status: EStatus.success,
          data: permissions.concat(newPermissionObjects),
        };
        // TODO: should re-add add by email
        // const shouldReload = nextPermissions.some((permission) => !permission.fullname);
        mutatePermissions(nextPermissions, false);
      }
    },
    [shareCallback, modelID, mutatePermissions, permissions]
  );

  const searchUsers = useCallback(
    (term) => {
      if (term) searchCallback(SEARCH_USERS_ROUTE, { method: EMethods.GET, term });
    },
    [searchCallback]
  );

  useEffect(() => {
    if (shareResponse && shareResponse.status !== EStatus.success) {
      mutate([MODEL_PERMISSIONS_ROUTE, { modelID }]);
    }
  }, [modelID, permissions, shareResponse]);

  useSuccessFailToast(shareResponse);

  return {
    searching,
    searchUsers,
    searchResults,
    shareModels,
    sharing,
    shareResponse,
  };
};

export default useShareModels;
