import colors from "styles/colors.module.scss";
import * as consts from "constants/dataEntryUIConstants";
import { DataEntryEditMode } from "constants/dataEntryUIConstants";
import { ADD_LINE_ITEM_FAILURE } from "constants/dataEntryConstants";
import { cellsToRegion, selectionFromCell } from "utils/modelGrid";
import { getValidJSON } from "utils/api";
import { BaseFormatObject } from "types/format";

export default function dataEntryUIReducer(
  state = {
    creatingRow: null,
    defaultFormat: BaseFormatObject,
    editMode: DataEntryEditMode.NORMAL,
    focusedCell: { col: 0, row: 0 },
    focusedCellBounds: null,
    formula: "",
    formulaInsertData: {},
    formulaStyles: {
      light: {
        ...BaseFormatObject,
        backgroundColor: colors.tableBackground,
        color: colors.textColorBlue,
      },
      dark: {
        ...BaseFormatObject,
        color: colors.textColorBlueDark,
        backgroundColor: colors.tableBackgroundDark,
      },
    },
    constantStyles: {
      light: {
        ...BaseFormatObject,
        color: colors.textColor,
        backgroundColor: colors.tableBackground,
      },
      dark: {
        ...BaseFormatObject,
        color: colors.textColorDark,
        backgroundColor: colors.tableBackgroundDark,
      },
    },
    rowHeader: false,
    locked: false,
    savingTemplate: false,
    selectedRegions: [cellsToRegion(0, 0)],
  },
  action
) {
  switch (action.type) {
    case ADD_LINE_ITEM_FAILURE:
      return {
        ...state,
      };
    case consts.LOCK_UI:
      return {
        ...state,
        locked: true,
      };
    case consts.UNLOCK_UI:
      return {
        ...state,
        locked: false,
      };

    case consts.UPDATE_FOCUSED_CELL_REDUCER: {
      const { cell, rowHeader } = action.payload;
      return {
        ...state,
        focusedCell: { row: cell.row, col: cell.col },
        rowHeader: rowHeader,
      };
    }
    case consts.UPDATE_FOCUSED_CELL_BOUNDS:
      return {
        ...state,
        focusedCellBounds: action.payload.bounds,
      };
    case consts.UPDATE_SELECTED_REGIONS_REDUCER: {
      const { selection } = action.payload;
      const newState = {
        ...state,
        selectedRegions: selection,
      };
      return newState;
    }
    case consts.UPDATE_EDIT_MODE_REDUCER: {
      const { mode, formulaInsertData, currentModuleUid } = action.payload;
      const newState = {
        ...state,
        editMode: mode,
        formulaInsertData: formulaInsertData,
      };
      if (state.editMode === DataEntryEditMode.INSERT && mode !== DataEntryEditMode.INSERT) {
        const { formulaInsertData } = state;
        const cell =
          currentModuleUid === formulaInsertData.fact.moduleUid
            ? formulaInsertData.cell
            : { row: 0, col: 0 };
        newState.focusedCell = cell;
        newState.selectedRegions = selectionFromCell(cell);
      }
      return newState;
    }
    case consts.UPDATE_FORMULA: {
      const { formula } = action.payload;
      return {
        ...state,
        formula: formula,
      };
    }
    case consts.UPDATE_FORMULA_INSERT_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        formulaInsertData: data,
      };
    }
    case consts.UPDATE_FORMULA_TEMP_INSERT: {
      const { tempInsertion, currentModuleUid } = action.payload;
      const { focusedCell, formulaInsertData, selectedRegions } = state;
      const cell =
        currentModuleUid === formulaInsertData.fact.moduleUid
          ? formulaInsertData.cell
          : focusedCell;
      return {
        ...state,
        focusedCell: tempInsertion ? focusedCell : cell,
        selectedRegions: tempInsertion ? selectedRegions : selectionFromCell(cell),
        formulaInsertData: {
          ...formulaInsertData,
          tempInsertion: tempInsertion,
        },
      };
    }

    case consts.FETCH_CONFIGS_SUCCESS: {
      const { key, data } = action.payload;
      if (key.includes("Layout")) return state;
      return {
        ...state,
        configs: {
          [key]: getValidJSON(data) || {},
        },
      };
    }
    case consts.EDIT_CONFIG: {
      const { key, value } = action.payload;
      return {
        ...state,
        configs: {
          ...state.configs,
          [key]: value,
        },
      };
    }
    case consts.NAV_CELL_COORDS:
      return {
        ...state,
        navCellCoords: action.payload.coords,
      };
    default:
      return state;
  }
}
