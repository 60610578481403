import {
  Button,
  Card,
  Classes,
  Dialog,
  DialogFooter,
  DialogProps,
  Divider,
  Intent,
  Tree,
  TreeNodeInfo,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import LoadingIndicator from "components/utility/LoadingIndicator";
import {
  Flex,
  Grid,
  NonScrollableDialogBody,
  StyledLink,
} from "components/utility/StyledComponents";
import {
  EStatus,
  FETCH_PORTFOLIOS_CONFIG_ROUTE,
  FETCH_WATCHLISTS_CONFIG_ROUTE,
} from "constants/apiConstants";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import useWidget from "hooks/dashboard/useWidget";
import useListConfigs from "hooks/useListConfigs";
import useUserConfig from "hooks/useUserConfig";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { IResponse } from "types";
import { Dashboard } from "types/dashboard";
import { View } from "types/view";
import { Widget } from "types/widget";
import { swrApi } from "utils/api";
import { multiToaster } from "utils/toaster";

interface CopyWidgetDialogProps extends DialogProps {
  onClose: () => void;
  widget: Widget;
}

const CopyWidgetDialog: React.FC<CopyWidgetDialogProps> = ({ onClose, widget, ...dialogProps }) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const [copying, setCopying] = useState(false);
  const [selectedView, setSelectedView] = useState<View>();
  const [expandedNodes, setExpandedNodes] = useState<{ [key: string]: boolean }>({});
  const { copyWidgetToView } = useWidget(widget.id);

  const {
    data: watchlistConfigs,
    error: watchlistConfigsError,
    isLoading: loadingWatchlistConfigs,
  } = useSWR<IResponse<{ name: string; id: string; dashboard: Dashboard }[]>>(
    [FETCH_WATCHLISTS_CONFIG_ROUTE],
    swrApi
  );

  const {
    data: portfolioConfigs,
    error: portfolioConfigsError,
    isLoading: loadingPortfolioConfigs,
  } = useSWR<IResponse<{ name: string; id: string; dashboard: Dashboard }[]>>(
    [FETCH_PORTFOLIOS_CONFIG_ROUTE],
    swrApi
  );

  const { home } = useUserConfig();

  const { dashboards, isLoading: loadingDashboards } = useListConfigs();

  const handleCopyView = async () => {
    if (!selectedView) return;
    setCopying(true);
    const response = await copyWidgetToView(selectedView.id);
    if (response.status === EStatus.success) {
      multiToaster.show({
        intent: Intent.SUCCESS,
        message: `Successfully copied ${widget?.name} to ${selectedView.name}`,
      });
      setSelectedView(undefined);
    } else {
      multiToaster.show({
        intent: Intent.WARNING,
        message: `Unable to copy ${widget?.name} to ${selectedView.name}. Please try again.`,
      });
    }
    setCopying(false);
  };

  const tree: TreeNodeInfo<{ view?: View }>[] = [
    {
      icon: IconNames.HOME,
      id: "homeViews",
      isExpanded: expandedNodes.homeViews,
      label: "Home",
    },
    {
      icon: IconNames.DASHBOARD,
      id: "generalDashboards",
      isExpanded: expandedNodes["generalDashboards"],
      label: "General Dashboards",
    },
    {
      icon: IconNames.EYE_ON,
      id: "watchlistDashboards",
      isExpanded: expandedNodes["watchlistDashboards"],
      label: "Watchlist Dashboards",
    },
    {
      icon: IconNames.MULTI_SELECT,
      id: "portfolioDashboards",
      isExpanded: expandedNodes["portfolioDashboards"],
      label: "Portfolio Dashboards",
    },
  ];

  if (home?.views) {
    const homeViews = home.views.map((view) => {
      return {
        isSelected: selectedView && selectedView.id === view.id,
        id: view.id,
        label: (
          <Flex justifyContent="space-between">
            {view.name}
            <Link target="_blank" to={`/dashboards/${home.id}/${view.id}`}>
              <Button icon={IconNames.DOCUMENT_OPEN} minimal />
            </Link>
          </Flex>
        ),
        nodeData: { view },
      };
    });
    tree[0].childNodes = homeViews;
  }

  if (dashboards) {
    const generalDashboards = dashboards.map((dashboard) => {
      return {
        id: dashboard.id,
        isExpanded: expandedNodes?.[dashboard.id],
        label: dashboard.name,
        ...(dashboard.views && {
          childNodes: dashboard.views.map((view) => ({
            isSelected: selectedView && selectedView.id === view.id,
            id: view.id,
            label: (
              <Flex justifyContent="space-between">
                {view.name}
                <Link target="_blank" to={`/dashboards/${dashboard.id}/${view.id}`}>
                  <Button icon={IconNames.DOCUMENT_OPEN} minimal />
                </Link>
              </Flex>
            ),
            nodeData: { view },
          })),
        }),
      };
    });
    tree[1].childNodes = generalDashboards;
  }
  if (watchlistConfigs?.data && !watchlistConfigsError) {
    const watchlistDashboards = watchlistConfigs.data.map((watchlist) => {
      const dash = watchlist?.dashboard;
      return {
        id: dash.id,
        isExpanded: expandedNodes?.[dash.id],
        label: dash.name,
        childNodes: dash?.views
          ? dash.views.map((view) => ({
              isSelected: selectedView && selectedView.id === view.id,
              id: view.id,
              label: (
                <Flex justifyContent="space-between">
                  {view.name}
                  <Link target="_blank" to={`/dashboards/${dash.id}/${view.id}`}>
                    <Button icon={IconNames.DOCUMENT_OPEN} minimal />
                  </Link>
                </Flex>
              ),
              nodeData: { view },
            }))
          : undefined,
      };
    });
    tree[2].childNodes = watchlistDashboards;
  }
  if (portfolioConfigs?.data && !portfolioConfigsError) {
    const portfolioDashboards = portfolioConfigs.data.map((portfolio) => {
      const dash = portfolio?.dashboard;
      return {
        id: dash.id,
        isExpanded: expandedNodes?.[dash.id],
        label: dash.name,
        childNodes: dash?.views
          ? dash.views.map((view) => ({
              isSelected: selectedView && selectedView.id === view.id,
              id: view.id,
              label: (
                <Flex justifyContent="space-between">
                  {view.name}
                  <Link target="_blank" to={`/dashboards/${dash.id}/${view.id}`}>
                    <Button icon={IconNames.DOCUMENT_OPEN} minimal />
                  </Link>
                </Flex>
              ),
              nodeData: { view },
            }))
          : undefined,
      };
    });
    tree[3].childNodes = portfolioDashboards;
  }

  const loading = loadingWatchlistConfigs || loadingDashboards || loadingPortfolioConfigs;
  return (
    <Dialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      onClose={onClose}
      title="Copy Widget to View"
      {...dialogProps}
    >
      <NonScrollableDialogBody>
        {loading && (
          <LoadingIndicator status={{ intent: Intent.PRIMARY, message: "Loading Dashboards..." }} />
        )}
        <Grid fullHeight rows="auto auto 1fr">
          <Card>
            <p className={Classes.RUNNING_TEXT}>
              Copy Widget <strong>{widget?.name}</strong> to View: <br />
              {selectedView ? (
                <StyledLink to={`/dashboards/${selectedView.id}`}>
                  <strong>{selectedView.name}</strong>
                </StyledLink>
              ) : (
                <span className={Classes.TEXT_MUTED}>No Dashboard selected.</span>
              )}
            </p>
          </Card>
          <Divider style={{ margin: "10px 0" }} />
          <div style={{ overflowY: "scroll" }}>
            {tree && (
              <Tree
                contents={tree}
                onNodeClick={(node) => {
                  if (node?.nodeData?.view) {
                    setSelectedView(node.nodeData.view);
                  }
                }}
                onNodeCollapse={(node) => setExpandedNodes({ ...expandedNodes, [node.id]: false })}
                onNodeExpand={(node) => setExpandedNodes({ ...expandedNodes, [node.id]: true })}
              />
            )}
          </div>
        </Grid>
      </NonScrollableDialogBody>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose} text="Cancel" />
          <Button
            disabled={copying || !selectedView}
            icon={IconNames.DUPLICATE}
            intent={Intent.PRIMARY}
            loading={copying}
            onClick={handleCopyView}
            text="Copy"
          />
        </div>
      </DialogFooter>
    </Dialog>
  );
};

export default CopyWidgetDialog;
