import { Intent } from "@blueprintjs/core";
import { EMethods, EStatus, FETCH_WATCHLISTS_ROUTE, WATCHLIST_ROUTE } from "constants/apiConstants";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { IResponse } from "types";
import { MatchingModel, Watchlist } from "types/collections";
import api, { swrApi } from "utils/api";
import { multiToaster } from "utils/toaster";

/***
 * Take in a watchlistId as an argument. This determines the watchlist that is being updated/removed.
 */
const useWatchlists = () => {
  const navigate = useNavigate();
  const [creatingWatchlist, setCreatingWatchlist] = useState(false);
  const {
    data: watchlists,
    error: fetchWatchlistsError,
    mutate: mutateWatchlists,
    isLoading: loadingWatchlists,
  } = useSWR<IResponse<Watchlist[]>>([FETCH_WATCHLISTS_ROUTE], swrApi);

  const deleteWatchlist = useCallback(
    async (watchlist: Watchlist) => {
      const response = await api(WATCHLIST_ROUTE, {
        watchlistId: watchlist.id,
        method: EMethods.DELETE,
      });
      if (response.status === EStatus.success) {
        multiToaster.show({
          intent: Intent.SUCCESS,
          message: `${watchlist.name} was deleted.`,
        });
        navigate("../");
        if (watchlists?.data) {
          const nextWatchlists = watchlists?.data?.filter((wL) => wL.id !== watchlist.id);
          mutateWatchlists({ ...watchlists, data: nextWatchlists }, false);
        }
      } else {
        multiToaster.show({ intent: Intent.WARNING, message: response.error });
      }
    },
    [mutateWatchlists, navigate, watchlists]
  );

  const createWatchlist = useCallback(
    async (watchlist: Omit<Watchlist, "id">) => {
      setCreatingWatchlist(true);
      const createWatchlistResponse = await api<{
        watchlist: Watchlist;
        matchingModels: MatchingModel[] | null;
      }>(WATCHLIST_ROUTE, {
        body: watchlist,
        method: EMethods.POST,
      });
      if (createWatchlistResponse.status === EStatus.success && createWatchlistResponse?.data) {
        mutateWatchlists(
          {
            ...watchlists,
            status: EStatus.success,
            data: watchlists?.data
              ? watchlists.data.concat(createWatchlistResponse.data.watchlist)
              : [createWatchlistResponse.data.watchlist],
          },
          false
        );
      }
      setCreatingWatchlist(false);
      return createWatchlistResponse;
    },
    [mutateWatchlists, watchlists]
  );

  return {
    createWatchlist,
    creatingWatchlist,
    deleteWatchlist,
    error: fetchWatchlistsError,
    loadingWatchlists,
    mutateWatchlists,
    watchlists: watchlists?.data,
  };
};

export default useWatchlists;
