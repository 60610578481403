import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { pullSource } from "../../actions/dataEntryActions";
import { focusedCellSelector } from "../../selectors/dataEntrySelectors";
import { FullSizeDiv } from "../utility/StyledComponents";
import ModuleSpinner from "../dashboard/ModuleSpinner";
import { Callout } from "@blueprintjs/core";
import { currentCaseSelector } from "../../selectors/modelSelectors";

const SourceIFrame = styled.iframe`
  width: 100%;
  height: 100%;
  background-color: white;
  border: 0;
  visibility: ${(props) => (props.ready > 0 ? "visible" : "hidden")};
`;

const SourceView = ({ currentCase, focusedCell, lineItems, pullSource, sources, ticker }) => {
  const [sourceURL, setSourceURL] = useState(null);
  const [ready, setReady] = useState(0);
  const iFrameRef = useRef();
  const previousHighlightRef = useRef();

  const highlightSourceElem = useCallback(
    (window) => {
      if (!window.document) return;
      const lineItem = lineItems[focusedCell.fact.parentUid];
      const elems = window.document.getElementsByClassName(lineItem.sourceId);
      if (!elems || !elems.length) {
        window.scroll(0, 0);
        setReady(1);
        return;
      }
      // TODO: Ability to iterate over multiple elems
      const elem = elems[0];
      previousHighlightRef.current = elem;
      elems[0].style.border = "3px solid red";
      elem.scrollIntoView();
      setReady(1);
    },
    [focusedCell, lineItems]
  );

  /**
   * Hook to deal with the different loading states of the source file.
   * -2: Inexistent for expected periods
   * -1: Couldn't retrieve from VS Server
   * 0: Loading
   * 1: Loaded
   */
  useEffect(() => {
    if (focusedCell.fact.period > currentCase.startPeriod) {
      setReady(-2);
      return;
    } else setReady(0);
    if (previousHighlightRef.current) previousHighlightRef.current.style.border = "0";
    const newSourceUrl = sources[ticker + focusedCell.fact.period];
    const iFrameDoc = iFrameRef.current.contentDocument;
    if (newSourceUrl === null) {
      setReady(-1);
    } else if (newSourceUrl === undefined) {
      pullSource(ticker, focusedCell.fact.period);
    } else {
      if (newSourceUrl !== sourceURL) {
        iFrameDoc.removeChild(iFrameDoc.documentElement);
        setSourceURL(newSourceUrl);
      } else if (iFrameRef.current.contentDocument.body?.childNodes.length) {
        highlightSourceElem(iFrameRef.current.contentWindow);
      } else {
        window.scroll(0, 0);
        setReady(1);
      }
    }
  }, [focusedCell, highlightSourceElem, pullSource, sources, sourceURL, ticker, currentCase]);

  return (
    <FullSizeDiv fullWidth fullHeight>
      {ready === 0 && <ModuleSpinner statusText="LOADING DATA..." />}
      {ready === -1 && <Callout>Could not load source file</Callout>}
      {ready === -2 && <Callout>Forecast data does not originate from a company filing.</Callout>}
      <SourceIFrame
        onLoad={(e) => {
          highlightSourceElem(e.currentTarget.contentWindow);
        }}
        ready={ready}
        ref={iFrameRef}
        src={sourceURL}
      />
    </FullSizeDiv>
  );
};

function mapStateToProps(state) {
  return {
    currentCase: currentCaseSelector(state),
    focusedCell: focusedCellSelector(state),
    lineItems: state.model.lineItems,
    sources: state.model.parsedSources,
    ticker: state.model.ticker,
  };
}

const mapDispatchToProps = {
  pullSource,
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceView);
