import React from "react";
import propTypes from "prop-types";
import { AuthState } from "constants/appConstants";
import { AnchorButton, Button, Callout, Classes, H5, H6, Intent } from "@blueprintjs/core";

import { Flex } from "components/utility/StyledComponents";
import { AuthForm, AuthContainer } from "components/user/StyledAuthComponents";

const SetupTOTP = ({ setAuthState, inSettingsPage }) => {
  if (inSettingsPage) {
    return;
  }
  return (
    <AuthContainer>
      <H5>Setup two-step verification?</H5>
      <p className={Classes.RUNNING_TEXT}>
        Two step verification helps make your account more secure by requiring an extra code each
        time you login.
      </p>
      <p className={Classes.RUNNING_TEXT}>
        Two step verification can be enabled later in Settings.
      </p>
      <Callout intent={Intent.PRIMARY}>
        <H6>
          Valsys <strong>highly recommends</strong> enabling this feature.
        </H6>
      </Callout>
      <AuthForm
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Flex justifyContent="space-between">
          <Button
            intent={Intent.PRIMARY}
            minimal
            onClick={() => setAuthState(AuthState.SignedIn)}
            text="Skip"
          />
          <AnchorButton
            href="/settings/two-step-auth"
            intent={Intent.PRIMARY}
            onClick={() => setAuthState(AuthState.SignedIn)}
            text="Setup Two Step Verification"
          />
        </Flex>
      </AuthForm>
    </AuthContainer>
  );
};

SetupTOTP.propTypes = {
  authState: propTypes.string.isRequired,
  setAuthState: propTypes.bool.isRequired,
  inSettingsPage: propTypes.bool,
};

SetupTOTP.defaultProps = {
  inSettingsPage: false,
};

export default SetupTOTP;
