import { Colors } from "@blueprintjs/core";
import { GraphSeriesColors } from "constants/sharePriceConstants";
import Highcharts, { merge, Options } from "highcharts/highstock";
import exportingOption from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import offlineOption from "highcharts/modules/offline-exporting";

exportingOption(Highcharts);
exportData(Highcharts);
offlineOption(Highcharts);

import scssVars from "styles/ptVars.module.scss";

const lightTheme: Partial<Options> = {
  exporting: {
    chartOptions: {
      chart: { backgroundColor: Colors.WHITE },
    },
  },
  legend: {
    itemStyle: { color: Colors.BLACK },
  },
  navigator: {
    handles: {
      backgroundColor: Colors.LIGHT_GRAY3,
      borderColor: Colors.GRAY3,
    },
    xAxis: { gridLineColor: undefined, labels: { style: { color: Colors.BLACK } } },
    yAxis: { gridLineColor: undefined, labels: { style: { color: Colors.BLACK } } },
  },
  plotOptions: {
    column: {
      borderWidth: 0,
      dataLabels: {
        style: { color: Colors.BLACK, textOutline: "none" },
      },
    },
    line: {
      dataLabels: {
        style: { color: Colors.BLACK, textOutline: "none" },
      },
      endLabelStyle: {
        backgroundColor: Colors.LIGHT_GRAY5 || "#f6f7f9",
        color: Colors.BLACK || "black",
      },
    },
    pie: {
      dataLabels: {
        style: { color: Colors.BLACK, textOutline: "none" },
      },
    },
    scatter: {
      dataLabels: {
        style: { color: Colors.BLACK, textOutline: "none" },
      },
    },
  },
  rangeSelector: {
    buttonTheme: {
      fill: "none",
      states: {
        disabled: { style: { color: Colors.GRAY4, cursor: "not-allowed" } },
      },
      stroke: Colors.BLACK,
      style: {
        color: Colors.BLACK,
      },
    },
    inputStyle: { color: Colors.BLUE3 },
  },
  scrollbar: {
    barBackgroundColor: undefined,
    barBorderColor: undefined,
    trackBorderColor: undefined,
  },
  xAxis: {
    gridLineColor: Colors.LIGHT_GRAY4,
    labels: { style: { color: Colors.BLACK } },
    lineColor: Colors.LIGHT_GRAY4,
    tickColor: Colors.LIGHT_GRAY4,
    title: {
      style: {
        color: Colors.BLACK,
      },
    },
  },
  yAxis: {
    gridLineColor: Colors.LIGHT_GRAY4,
    lineColor: Colors.LIGHT_GRAY4,
    labels: { style: { color: Colors.BLACK } },
    title: {
      style: {
        color: Colors.BLACK,
      },
    },
  },
};

const darkTheme: Partial<Options> = {
  exporting: {
    chartOptions: {
      chart: { backgroundColor: Colors.DARK_GRAY2 },
    },
  },
  legend: {
    itemStyle: { color: Colors.WHITE },
  },
  navigator: {
    handles: {
      backgroundColor: Colors.DARK_GRAY5,
      borderColor: Colors.LIGHT_GRAY3,
    },
    xAxis: { gridLineColor: Colors.DARK_GRAY3, labels: { style: { color: Colors.WHITE } } },
    yAxis: { gridLineColor: Colors.DARK_GRAY3, labels: { style: { color: Colors.WHITE } } },
  },
  plotOptions: {
    column: {
      borderWidth: 0,
      dataLabels: {
        style: { color: Colors.WHITE, textOutline: "none" },
      },
    },
    line: {
      dataLabels: {
        style: { color: Colors.WHITE, textOutline: "none" },
      },
      endLabelStyle: {
        backgroundColor: Colors.DARK_GRAY2 || "#252A31",
        color: Colors.WHITE || "white",
      },
    },
    pie: {
      dataLabels: {
        style: { color: Colors.WHITE, textOutline: "none" },
      },
    },
    scatter: {
      dataLabels: {
        style: { color: Colors.WHITE, textOutline: "none" },
      },
    },
  },
  rangeSelector: {
    buttonTheme: {
      fill: "none",
      states: {
        disabled: { style: { color: Colors.GRAY4, cursor: "not-allowed" } },
      },
      stroke: Colors.WHITE,
      style: {
        color: Colors.WHITE,
      },
    },
    inputStyle: { color: Colors.BLUE3 },
  },
  scrollbar: {
    barBackgroundColor: Colors.DARK_GRAY3,
    barBorderColor: Colors.DARK_GRAY1,
    trackBorderColor: Colors.DARK_GRAY5,
  },
  xAxis: {
    gridLineColor: Colors.DARK_GRAY4,
    labels: { style: { color: Colors.WHITE } },
    lineColor: Colors.DARK_GRAY3,
    tickColor: Colors.DARK_GRAY3,
    title: {
      style: {
        color: Colors.WHITE,
      },
    },
  },
  yAxis: {
    gridLineColor: Colors.DARK_GRAY4,
    lineColor: Colors.DARK_GRAY3,
    labels: { style: { color: Colors.WHITE } },
    title: {
      style: {
        color: Colors.WHITE,
      },
    },
  },
};

const commonHighchartsConfig: Highcharts.Options = {
  chart: {
    zooming: {
      mouseWheel: {
        enabled: false,
      },
    },
    backgroundColor: undefined,
    marginTop: 20,
    marginRight: 45,
    style: {
      fontFamily: scssVars.fontFamily,
    },
    spacingLeft: 30,
    spacingRight: 30,
  },
  colors: GraphSeriesColors,
  credits: { enabled: false },
  exporting: { enabled: false },
  legend: { itemHoverStyle: { color: undefined }, padding: 4 },
  navigator: { margin: 10, outlineColor: undefined, stickToMax: true },
  scrollbar: {
    height: 6,
  },
  title: { text: undefined },
  xAxis: {
    minRange: 1,
    labels: {
      style: {
        textOverflow: "none",
        whiteSpace: "nowrap",
      },
    },
  },
};

export const lightThemeHighchartsConfig: Highcharts.Options = merge(
  commonHighchartsConfig,
  lightTheme
);
export const darkThemeHighchartsConfig: Highcharts.Options = merge(
  commonHighchartsConfig,
  darkTheme
);
