import {
  Button,
  Classes,
  DialogFooter,
  DialogProps,
  FormGroup,
  HTMLTable,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  FullWidthDialog,
  Grid,
  NonScrollableDialogBody,
  StyledScrollContainer,
} from "components/utility/StyledComponents";
import { DEFAULT_MODEL_TAB, Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { MatchingModel, Portfolio, Watchlist } from "types/collections";
import CollectionModelSelection from "./CollectionModelSelection";
import { multiToaster } from "utils/toaster";
import styled from "styled-components";

interface UpdateCollectionModelsDialogProps extends DialogProps {
  collection: Watchlist | Portfolio | undefined;
  editCollection: (edit: Partial<Watchlist> | Partial<Portfolio>) => void;
  loadingCollection: boolean;
  matchingModels: MatchingModel[] | null | undefined;
  onClose: () => void;
}

const StyledHTMLTable = styled(HTMLTable)`
  border-radius: 4px;
  thead {
    th {
      padding: 2px 5px;
    }
  }
  tbody {
    tr:last-child {
      td:first-child {
        border-radius: 0 0 0 4px;
      }
      td:last-child {
        border-radius: 0 0 4px 0;
      }
    }
  }
  td {
    padding: 2px 5px;
  }
`;

const UpdateCollectionModelsDialog: React.FC<UpdateCollectionModelsDialogProps> = ({
  collection,
  editCollection,
  isOpen,
  matchingModels,
  onClose,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);

  const [tickerPredicate, setTickerPredicate] = useState("");
  const [updateTicker, setUpdateTicker] = useState<MatchingModel>();

  let currentCompany;

  const isP = isPortfolio(collection);

  if (isP) {
    currentCompany = collection?.holdings?.find((c) => c.ticker === updateTicker?.ticker);
  } else {
    currentCompany = collection?.companies?.find((c) => c.ticker === updateTicker?.ticker);
  }

  const handleUpdateTickerModel = (modelId: string) => {
    if (updateTicker && matchingModels && collection) {
      if (isP) {
        const nextHoldings = [...collection.holdings];
        const index = nextHoldings.findIndex((ticker) => ticker.ticker === updateTicker.ticker);
        if (index !== -1) {
          nextHoldings.splice(index, 1, { ...nextHoldings[index], modelId });
          editCollection({ holdings: nextHoldings });
        }
      } else {
        const nextCompanies = [...collection.companies];
        const index = nextCompanies.findIndex((ticker) => ticker.ticker === updateTicker.ticker);
        if (index !== -1) {
          nextCompanies.splice(index, 1, { ...nextCompanies[index], modelId });
          editCollection({ companies: nextCompanies });
        }
      }
    }
    multiToaster.show({ intent: Intent.SUCCESS, message: "Model updated successfully." });
  };

  const handleClose = () => {
    setTickerPredicate("");
    setUpdateTicker(undefined);
    onClose();
  };

  return (
    <FullWidthDialog
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      isOpen={isOpen}
      onClose={handleClose}
      title={`Update ${isP ? "Portfolio " : "Watchlist "} Models`}
    >
      <NonScrollableDialogBody>
        {updateTicker ? (
          <CollectionModelSelection
            selectedModelId={currentCompany?.modelId}
            setModel={handleUpdateTickerModel}
            ticker={updateTicker}
          />
        ) : (
          <Grid fullHeight rows="auto auto 1fr">
            <p className={Classes.RUNNING_TEXT}>
              The table below shows all tickers in the {isP ? "Portfolio " : "Watchlist "}with more
              than one associated model in our platform.
            </p>
            <FormGroup label="Search tickers:" style={{ width: 200 }}>
              <InputGroup
                leftIcon={IconNames.SEARCH}
                onChange={(e) => setTickerPredicate(e.currentTarget.value)}
                placeholder="Search..."
                value={tickerPredicate}
              />
            </FormGroup>
            <StyledScrollContainer $theme={theme}>
              <StyledHTMLTable bordered>
                <thead>
                  <tr>
                    <th>Ticker</th>
                    <th>Current Model</th>
                    <th>Matching Models</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {matchingModels &&
                    matchingModels
                      .filter((model) =>
                        model.ticker.toLowerCase().includes(tickerPredicate.toLowerCase())
                      )
                      .map((ticker) => {
                        let modelId;
                        if (isPortfolio(collection)) {
                          modelId = collection?.holdings.find(
                            (c) => c.ticker === ticker.ticker
                          )?.modelId;
                        } else {
                          modelId = collection?.companies.find(
                            (c) => c.ticker === ticker.ticker
                          )?.modelId;
                        }
                        const modelLink = `/model/uid=${modelId}/${DEFAULT_MODEL_TAB}`;
                        return (
                          <tr key={ticker.ticker}>
                            <td>{ticker.ticker}</td>
                            <td>
                              <Link target="_blank" to={modelLink}>
                                {modelId}
                              </Link>
                            </td>
                            <td>{ticker.models.length}</td>
                            <td>
                              <Button
                                small
                                onClick={() => setUpdateTicker(ticker)}
                                text="Update Model"
                              />
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </StyledHTMLTable>
            </StyledScrollContainer>
          </Grid>
        )}
      </NonScrollableDialogBody>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {updateTicker && (
            <Button
              icon={IconNames.ARROW_LEFT}
              onClick={() => setUpdateTicker(undefined)}
              text="Back"
            />
          )}
          <Button onClick={handleClose} text="Cancel" />
        </div>
      </DialogFooter>
    </FullWidthDialog>
  );
};

const isPortfolio = (collection: Watchlist | Portfolio | undefined): collection is Portfolio =>
  !!collection && "holdings" in (collection as Portfolio);

export default UpdateCollectionModelsDialog;
