import * as consts from "constants/createModelConstants";

// CREATE

/**
 * Dispatched with config option to trigger model creation.
 *
 * @param {Object} configuration - The create configuration options.
 * */
export function createModel(configuration) {
  return {
    type: consts.CREATE_MODEL,
    payload: {
      ...configuration,
      action: consts.CREATE_MODEL,
      currency: configuration.currency.ISO,
      templateID: configuration.template.uid,
      forecastYears: configuration.projectionPeriod,
      historicalYears: configuration.historicalPeriod,
      variables: configuration.variables.reduce((acc, variable) => {
        const key = Object.keys(variable)[0];
        return {
          ...acc,
          [key]: variable[key],
        };
      }, {}),
    },
  };
}

/**
 * Dispatched on successful creation of a model.
 *
 * @param {Object} model - The created model.
 * */
export function createModelSuccess(model) {
  return {
    type: consts.CREATE_MODEL_SUCCESS,
    payload: {
      model,
    },
  };
}

/**
 * Dispatched on successful creation of a model.
 *
 * @param {Object} step - The step at which the creation process failed
 * */
export function createModelFailure(step, error) {
  return {
    type: consts.CREATE_MODEL_FAILURE,
    payload: {
      step,
      error,
    },
  };
}

/**
 * Dispatched to update the current status of a model build.
 *
 * @param
 * */
export function updateBuildingStatus(status, isBuilding, step, retry) {
  return {
    type: consts.UPDATE_BUILDING_STATUS,
    payload: {
      status,
      isBuilding,
      step,
      retry,
    },
  };
}

/**
 * Dispatched to add to active filters.
 *
 * @param {string} filter - The key of the filter being updated.
 * @param {string} item - The item to add to the filter.
 * */
export function addFilter(filter, item) {
  return {
    type: consts.ADD_FILTER,
    payload: {
      filter,
      item,
    },
  };
}

/**
 * Dispatched to remove active filters.
 *
 * @param {string} filter - The key of the filter being updated.
 * @param {string} item - The item to o remove from the filter.
 * */
export function removeFilter(filter, item) {
  return {
    type: consts.REMOVE_FILTER,
    payload: {
      filter,
      item,
    },
  };
}

/**
 * Dispatched to clear a company select filter.
 *
 * @param {string} filter - The filter to clear.
 * */
export function clearFilter(filter) {
  return {
    type: consts.CLEAR_FILTER,
    payload: {
      filter,
    },
  };
}

/**
 * Set the valuation type, either blank or default (pre-populated)
 *
 */
export function setValuationType(type) {
  return {
    type: consts.SET_VALUATION_TYPE,
    payload: {
      type,
    },
  };
}

/**
 * Update the new company name
 *
 * @param name {string} - The new company's name
 */
export function updateNewCompanyName(name) {
  return {
    type: consts.UPDATE_COMPANY_NAME,
    payload: {
      name,
    },
  };
}

/**
 * Generic update action for any configuration object.
 *
 * @param event - The event dispatched by the config select option.
 * */
export function updateConfiguration(e) {
  const { id, value } = e.currentTarget;
  return {
    type: consts.UPDATE_CONFIGURATION,
    payload: {
      configItem: id,
      newValue: value,
    },
  };
}

/**
 * Update the new company ticker
 *
 * @param ticker {string} - The new company's ticker
 */
export function updateNewCompanyTicker(ticker) {
  return {
    type: consts.UPDATE_COMPANY_TICKER,
    payload: {
      ticker,
    },
  };
}

export function toggleMarginOfSafety() {
  return {
    type: consts.TOGGLE_MARGIN_OF_SAFETY,
  };
}

export function updateMarginOfSafety(value) {
  return {
    type: consts.UPDATE_MARGIN_OF_SAFETY,
    payload: {
      value,
    },
  };
}

export function updateCompanyType(type) {
  return {
    type: consts.UPDATE_COMPANY_TYPE,
    payload: {
      type,
    },
  };
}

export function updateExitMultipleTarget(value) {
  return {
    type: consts.UPDATE_EXIT_MULTIPLE_TARGET,
    payload: {
      value,
    },
  };
}

export function updateGeography(geography) {
  return {
    type: consts.UPDATE_GEOGRAPHY,
    payload: {
      geography,
    },
  };
}

export function updateIndustry(industry) {
  return {
    type: consts.UPDATE_INDUSTRY,
    payload: {
      industry,
    },
  };
}

export function updateSelectedCompany(company) {
  return {
    type: consts.UPDATE_SELECTED_COMPANY,
    payload: {
      company,
    },
  };
}

export function updateHistoricalYears(years) {
  return {
    type: consts.UPDATE_HISTORICALS,
    payload: {
      years,
    },
  };
}

export function updateForecastYears(years) {
  return {
    type: consts.UPDATE_FORECASTS,
    payload: {
      years,
    },
  };
}

export function updateCurrency(currency) {
  return {
    type: consts.UPDATE_CURRENCY,
    payload: {
      currency,
    },
  };
}

export function updateUnit(unit) {
  return {
    type: consts.UPDATE_UNIT,
    payload: {
      unit,
    },
  };
}

export function updateStartDate(date) {
  return {
    type: consts.UPDATE_START_DATE,
    payload: {
      date,
    },
  };
}

export function updateStartPeriod(startPeriod) {
  return {
    type: consts.UPDATE_START_PERIOD,
    payload: {
      startPeriod,
    },
  };
}

export function updatePeriodType(periodType) {
  return {
    type: consts.UPDATE_PERIOD_TYPE,
    payload: {
      periodType,
    },
  };
}

export function updateTerminalValuationCalcType(type) {
  return {
    type: consts.UPDATE_TERMINAL_VALUATION_TYPE,
    payload: {
      type,
    },
  };
}

// CONFIGURATION OPTIONS
export function fetchConfigOptions(type) {
  return {
    type: consts.FETCH_CONFIG_ITEM_OPTIONS,
    payload: {
      type,
    },
  };
}

export function fetchConfigOptionsSuccess(data) {
  return {
    type: consts.FETCH_CONFIG_ITEM_OPTIONS_SUCCESS,
    payload: {
      ...data,
    },
  };
}

export function fetchConfigOptionsFailure() {
  return {
    type: consts.FETCH_CONFIG_ITEM_OPTIONS_FAILURE,
  };
}

/**
 * Dispatched to trigger fetches to all the config options.
 * */
export function fetchAllConfigOptions() {
  return {
    type: consts.FETCH_ALL_CONFIG_OPTIONS,
  };
}

/**
 * Dispatched to fetch available tickers based on a provided tempalte ID.
 *
 * @param {string} templateID - The uid of the currently selected template
 * */
export function fetchAvailableTickers(templateID) {
  return {
    type: consts.FETCH_AVAILABLE_TICKERS,
    payload: {
      templateID,
    },
  };
}

export function fetchAvailableTickersSuccess(data) {
  return {
    type: consts.FETCH_AVAILABLE_TICKERS_SUCCESS,
    payload: {
      data,
    },
  };
}

export function fetchAvailableTickersFailure() {
  return {
    type: consts.FETCH_AVAILABLE_TICKERS_FAILURE,
  };
}

/**
 * Dispatched to fetch the configuration options for a specified company.
 *
 * @param {string} templateID - The ID of the template currently selected
 * @param {string} ticker - The ticker to fetch the config options for
 * */
export function fetchCompanyConfigOptions(templateID, ticker) {
  return {
    type: consts.FETCH_COMPANY_CONFIG_OPTIONS,
    payload: {
      templateID,
      ticker,
    },
  };
}

/**
 * Dispatched to fetch the configuration options for a specified company.
 *
 * @param {Object} data - The company's config data
 * */
export function fetchCompanyConfigOptionsSuccess(data) {
  return {
    type: consts.FETCH_COMPANY_CONFIG_OPTIONS_SUCCESS,
    payload: {
      data,
    },
  };
}

/**
 * Dispatched to fetch the configuration options for a specified company.
 * */
export function fetchCompanyConfigOptionsFailure() {
  return {
    type: consts.FETCH_COMPANY_CONFIG_OPTIONS_FAILURE,
  };
}

// META
/**
 * Flush the selected configuration
 * */
export function flushConfig() {
  return {
    type: consts.FLUSH_CONFIG,
  };
}

/**
 * Flush the whole create model reducer
 * */
export function flushCreate() {
  return {
    type: consts.FLUSH_CREATE,
  };
}

/**
 * Used to determine whether the advanced controls are active for a given config panel
 *
 * @param {string} panel - The configuration panel that is being updated.
 * @param {string} isActive - The
 **/
export function updateAdvancedControlsActive(panel, isActive) {
  return {
    type: consts.UPDATE_ADVANCED_CONTROLS_ACTIVE,
    payload: {
      isActive,
      panel,
    },
  };
}

/**
 * Dispatched to fetch a user's available templates
 * */
export function fetchModelTemplates() {
  return {
    type: consts.FETCH_MODEL_TEMPLATES,
  };
}

/**
 * Dispatched on successful fetch of a user's templates
 *
 * @param {Array} templates - The user's templates
 * */
export function fetchModelTemplatesSuccess(templates) {
  return {
    type: consts.FETCH_MODEL_TEMPLATES_SUCCESS,
    payload: {
      templates,
    },
  };
}

export function addTemplateVariable() {
  return {
    type: consts.ADD_TEMPLATE_VARIABLE,
  };
}

export function removeTemplateVariable(key) {
  return {
    type: consts.REMOVE_TEMPLATE_VARIABLE,
    payload: { key },
  };
}

export function editTemplateVariable(key, nextVariable) {
  return {
    type: consts.EDIT_TEMPLATE_VARIABLE,
    payload: {
      key,
      nextVariable,
    },
  };
}
