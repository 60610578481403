import React from "react";
import { Icon, Tooltip } from "@blueprintjs/core";
import { CellContext, Row } from "@tanstack/react-table";
import { IModelInfo, IModelPermissions, IRawFact } from "types/model";
import {
  IFilterState,
  IModelHistoryInfo,
  IModelHistoryTable,
  IModelInfoWithFields,
  IOptModelHistoryFields,
  TModelHistoryColumnDef,
  TModelHistoryTablePartialModel,
} from "types/modelHistory";
import { InitialFilterState } from "components/table/ModelFilters";
import { filterModels } from "./modelFilterUtils";
import { IModelHistoryResponse } from "hooks/useModelHistory";
import { shallowMergeExcludingNullishValues, isDate } from "utils/generic";
import {
  AccessIndicators,
  customColumnObjects,
  ModelInfoKeys,
} from "constants/modelHistoryConstants";
import { isRawFactOrOtherValue, TableValue } from "components/table/Table";
import { StyledLink } from "components/utility/StyledComponents";
import { DEFAULT_DECIMAL_PLACES, f } from "./formatter";
import { getValidJSON } from "./api";
import colors from "styles/colors.module.scss";
import { BaseFormatObject, EValFormat, IFormatObject } from "types/format";
import { ESortDirection, TTheme } from "types";
import { TableWidgetColumnConfig } from "types/widget";
import { DEFAULT_MODEL_TAB } from "constants/uiConstants";
import { MISSING_VALUE } from "constants/appConstants";

export const updateModelHistory = (
  modelEdits: TModelHistoryTablePartialModel[],
  modelHistory: IModelInfoWithFields[]
): IModelInfoWithFields[] => {
  const nextModelHistory = [...modelHistory];
  modelEdits.forEach((edit) => {
    const editIndex = nextModelHistory.findIndex((m) => m.model.id === edit.id);
    if (editIndex !== -1) {
      const toEdit = nextModelHistory[editIndex];
      nextModelHistory.splice(editIndex, 1, { ...toEdit, model: { ...toEdit.model, ...edit } });
    }
  });
  return nextModelHistory;
};
/** Merge models-with-fields into a single IModelHistoryInfo[]. */
export const flattenModelFields = (
  data: { fields: { [key: string]: TableValue }; model: IModelInfo }[]
): IModelHistoryInfo[] => {
  return data.map((m) => ({
    ...m.model,
    ...m.fields,
    createdAt: new Date(m.model?.createdAt),
    lastEdit: new Date(m.model?.lastEdit),
  }));
};

/**
 * react-table column definitions for special case columns, typically always present rows or rows requiring complex rendering/styling.
 */
export const savedColumnsToColumnDefs = (
  columns: TableWidgetColumnConfig[] | string[]
): TModelHistoryColumnDef[] => {
  return columns
    .map((column) => {
      if (typeof column === "string") {
        if (customColumnObjects[column]) {
          return customColumnObjects[column];
        }
        return {
          accessorKey: column,
          id: column,
        };
      } else {
        if (customColumnObjects[column.id]) {
          return customColumnObjects[column.id];
        }
        return {
          ...column,
          accessorKey: column.id,
          ...{ size: column?.width },
          meta: {
            ...column?.meta,
            colorizeNumerics: column?.colorizeNumerics,
            applyRelativeCellColorGradient: column?.applyRelativeCellColorGradient,
          },
        };
      }
    })
    .filter((columnDef) => columnDef.id !== "");
};

/**
 *  Utility to sort react-table rows by a single type primitive column
 */
export const sortPrimitiveColumn = (
  valueA: string | number | boolean | undefined,
  valueB: string | number | boolean | undefined,
  fallback: { a: string; b: string },
  sortDirection: ESortDirection
) => {
  const valueAHasValue = valueA && valueA !== MISSING_VALUE;
  const valueBHasValue = valueB && valueB !== MISSING_VALUE;

  if (valueA && !valueBHasValue) return -1;
  if (!valueAHasValue && valueB) return 1;
  if (!valueAHasValue && !valueBHasValue) return fallback.a.localeCompare(fallback.b);

  if (typeof valueA === "boolean" && typeof valueB === "boolean") {
    if (sortDirection === ESortDirection.ASC) {
      if (valueA && !valueB) return 1;
      if (!valueA && valueB) return -1;
    } else {
      if (valueA && !valueB) return -1;
      if (!valueA && valueB) return 1;
    }
    return 0;
  }

  if (typeof valueA === "string" && typeof valueB === "string") {
    if (sortDirection === ESortDirection.ASC) {
      return valueA.localeCompare(valueB);
    }
    return valueB.localeCompare(valueA);
  }
  if (typeof valueA === "number" && typeof valueB === "number") {
    if (sortDirection === ESortDirection.ASC) {
      return valueA - valueB;
    }
    return valueB - valueA;
  }
  return 0;
};

/**
 * Utility to sort react-table rows by the 'value' prop of facts. Uses the format.valFormat prop to perform numeric conversion, if appropriate.
 * Otherwise will perform a simple alphanumeric search.
 */
export const sortFactColumn = (
  factA: IRawFact | null,
  factB: IRawFact | null,
  fallback: { a: string; b: string },
  sortDirection: ESortDirection
) => {
  /**
   * Handle completely missing facts.
   * If both facts are null, attempt to use the ticker fallback,
   * if none present maintain current position.
   * */
  const factAExists = isRawFactOrOtherValue(factA);
  const factBExists = isRawFactOrOtherValue(factB);

  const factAHasValue = factAExists && factA.value && factA.value !== MISSING_VALUE;
  const factBHasValue = factBExists && factB.value && factB.value !== MISSING_VALUE;

  const factAFormat = getValidJSON<IFormatObject>(factA?.format ?? "");
  const factBFormat = getValidJSON<IFormatObject>(factB?.format ?? "");

  if (!factAHasValue && !factBHasValue) {
    return fallback.a.localeCompare(fallback.b);
  }

  if (factAHasValue && !factBHasValue) return -1;
  if (!factAHasValue && factBHasValue) return 1;

  if (factAExists && factBExists) {
    // Always push errored cells to the end of sort
    if (factA.error && factB.error) {
      return fallback.a.localeCompare(fallback.b);
    }
    if (factA.error && !factB.error) return -1;
    if (!factA.error && factB.error) return 1;

    // If both values are the same then sort by ticker.
    if (factA.value === factB.value) return fallback.a.localeCompare(fallback.b);

    // If both formats are non plain-text, convert to numbers and subtract. If they are both plain text
    // then localCompare.
    if (
      factAFormat?.valFormat !== EValFormat.PLAINTEXT ||
      factBFormat?.valFormat !== EValFormat.PLAINTEXT
    ) {
      if (sortDirection === ESortDirection.ASC) {
        return Number(factA.value) - Number(factB.value);
      }
      return Number(factB.value) - Number(factA.value);
    } else if (
      factAFormat?.valFormat === EValFormat.PLAINTEXT ||
      factBFormat?.valFormat === EValFormat.PLAINTEXT
    ) {
      if (factA.value && factB.value) {
        if (sortDirection === ESortDirection.ASC) {
          return factA.value.localeCompare(factB.value);
        }
        return factB.value.localeCompare(factA.value);
      }
    }
  }

  // TODO: Handle mixed format items, ex one plain text and one numeric.
  return fallback.a.localeCompare(fallback.b);
};

/**
 * Utility to sort a by array length
 */
export const sortArray = (arrayA: string[], arrayB: string[]) => {
  return arrayA.length - arrayB.length;
};

/**
 *  Utility to sort react-table rows which have a column meta prop set to 'date'
 */
export const sortDateColumn = (valueA: Date, valueB: Date, fallback: { a: string; b: string }) => {
  const dateOne = isDate(valueA) ? valueA : new Date(valueA);
  const dateTwo = isDate(valueB) ? valueB : new Date(valueB);
  // Safe to assume that value is a date due to meta type.
  if (!dateOne || !dateTwo) return fallback.a.localeCompare(fallback.b);
  const timeOne = dateOne.getTime();
  const timeTwo = dateTwo.getTime();
  if (
    (Number.isNaN(timeOne) && !Number.isNaN(timeTwo)) ||
    (Number.isNaN(timeOne) && Number.isNaN(timeTwo))
  ) {
    return 1;
  }
  if (!Number.isNaN(timeOne) && Number.isNaN(timeTwo)) return -1;
  return timeOne - timeTwo;
};

/**
 * Function to get a model sort calback based on a model history value.
 *
 *  Expects an optional fallback prop that will be used if both sorted items' values are equal.
 */
export const getSortCallback = (sortItem: TableValue) => {
  if (typeof sortItem !== "object") {
    return sortPrimitiveColumn;
  } else if (Array.isArray(sortItem)) {
    return sortArray;
  } else if (isDate(sortItem)) {
    return sortDateColumn;
  } else if (isRawFactOrOtherValue(sortItem)) {
    return sortFactColumn;
  }
  return sortPrimitiveColumn;
};

/**
 * Wrapper function to get functions from a react-table row and pass them to a
 * provided sort function.
 */
export const getSortValuesFromRows = <T extends TableValue>(
  a: Row<IModelHistoryInfo>,
  b: Row<IModelHistoryInfo>,
  columnID: string
) => {
  const valueA = a.getValue<T>(columnID);
  const valueB = b.getValue<T>(columnID);

  return [valueA, valueB];
};

export const sortReactTableRow = <Dt,>(
  a: Row<TModelHistoryTablePartialModel>,
  b: Row<TModelHistoryTablePartialModel>,
  sortingFunc: (a: Dt, b: Dt, fallback: { a: string; b: string }) => number,
  columnID?: string
) => {
  if (!columnID) return 0;
  const valueA = a.getValue<Dt>(columnID);
  const valueB = b.getValue<Dt>(columnID);
  return sortingFunc(valueA, valueB, {
    a: a.getValue<string>("ticker"),
    b: b.getValue<string>("ticker"),
  });
};

/**
 * Sorts models according to a specific column
 * */
export function sortModels(
  models: { id: string; [key: string]: TableValue }[],
  sortBy: string,
  sortDirection: ESortDirection
) {
  if (!models[0]) return [];
  // Find the first non null, non undefined model and derive type from sortBy prop.
  const firstValid = models.find((model) => model[sortBy] !== undefined && model[sortBy] !== null);

  // If all props are null/undefined, then sort by ticker and return
  if (!firstValid) {
    return models.sort((a, b) =>
      sortPrimitiveColumn(
        a.ticker as string,
        b.ticker as string,
        { a: "modelId", b: "modelId" },
        sortDirection
      )
    );
  }

  const sortCallback = getSortCallback(firstValid[sortBy]);

  const sorted = [...models].sort((a, b) => {
    const sortA = a[sortBy];
    const sortB = b[sortBy];
    return sortCallback(
      sortA as never,
      sortB as never,
      {
        a: a.ticker as string,
        b: b.ticker as string,
      },
      sortDirection
    );
  });

  return sorted;
}

/**
 * Given an array of model history info objects, returns an array of react-table columns
 */
export const getAvailableModelHistoryColumns = (
  model: IModelHistoryInfo
): TableWidgetColumnConfig[] => {
  if (!model) {
    return [];
  }
  const columnEntries = Object.entries(model);
  return columnEntries.map((entry) => {
    const key = entry[0];
    const value = entry[1];
    const type = typeof value;
    if (isDate(value)) {
      return {
        id: key,
        header: key,
        meta: {
          type: "date",
        },
      };
    }
    switch (type) {
      case "object":
        // Stringify unknown object types for safety
        return {
          id: key,
          header: key,
          accessorKey: key,
          meta: {
            type,
          },
        };
      default:
        // Also handles non-number primitives
        return {
          id: key,
          header: key,
          meta: {
            type,
          },
        };
    }
  });
};

export const updatePaginatedModelHistory = (
  data: IModelHistoryResponse[],
  edit: Partial<IModelInfo> & Pick<IModelInfo, "id">,
  page?: number,
  filters?: IFilterState
): IModelHistoryResponse[] => {
  if (page !== undefined && data[page]?.data) {
    // We know the page number and so can do a surgical update
    const nextPageModels = [...data[page].data.models];
    const editIndex = nextPageModels.findIndex((m) => m.model.id === edit.id);
    const modelInfoWithFields = nextPageModels[editIndex];

    const nextModelInfoWithFields = {
      ...modelInfoWithFields,
      model: { ...modelInfoWithFields.model, ...edit },
    };
    // Check to see if the edited model matches a supplied custom filter set.
    if (
      filters &&
      filterModels(
        [
          {
            ...nextModelInfoWithFields.model,
            ...(nextModelInfoWithFields.model?.createdAt && {
              createdAt: new Date(nextModelInfoWithFields.model?.createdAt),
            }),
          },
        ],
        filters
      ).length < 1
    ) {
      nextPageModels.splice(editIndex, 1);
    } else {
      nextPageModels.splice(editIndex, 1, nextModelInfoWithFields);
    }

    const mutatedPage = {
      ...data[page],
      data: { ...data[page].data, models: nextPageModels },
    };

    const nextData = [...data];
    nextData.splice(page, 1, mutatedPage);
    return nextData;
  } else {
    // TODO: Implement this
    // We have to find the page manually
    return data;
  }
};

// Convert json dates to Date objects in model history tables
export const parseStoredModelTables = (modelTables: IModelHistoryTable[]): IModelHistoryTable[] => {
  return modelTables
    .filter((table) => table.name && table.filters)
    .map((table) => {
      return {
        ...table,
        columns: table.columns.map((column) => {
          if (column?.meta?.tdStyle) {
            return {
              ...column,
              meta: { ...column.meta, tdStyle: getValidJSON(column?.meta.tdStyle as string) },
            };
          }
          return column;
        }),
        ...(table.filters.dateRange && {
          filters: {
            ...InitialFilterState,
            ...table.filters,
            dateRange: [
              table.filters.dateRange[0] ? new Date(table.filters.dateRange[0]) : null,
              table.filters.dateRange[1] ? new Date(table.filters.dateRange[1]) : null,
            ],
          },
        }),
      };
    });
};

/**
 * Given a flattened model history, returns a paginated model history of a specified page size.
 * // TODO: Could be a generic array pagination utility.
 */
export const paginateModels = <T,>(models: T[], pageSize: number): T[][] => {
  const totalPages = Math.ceil(models.length / pageSize);
  const paginated = [];
  for (let i = 0; i < totalPages; i++) {
    const m = models.slice(i * pageSize, (i + 1) * pageSize);
    paginated.push(m);
  }
  return paginated;
};

/**
 * Format or otherwise transform an array of models based upon a column template array
 */
export const transformModelsByColumnTemplate = (
  models: IModelHistoryInfo[],
  columnTemplates: TableWidgetColumnConfig[]
): IModelHistoryInfo[] => {
  return models.map((model) => {
    const next = { ...model };
    columnTemplates.forEach((colTemplate) => {
      const m = next[colTemplate.id];
      if (colTemplate?.meta?.type === "date" && m) {
        if (isRawFactOrOtherValue(m)) {
          next[colTemplate.id] = new Date(m.value as string);
        } else {
          next[colTemplate.id] = new Date(model[colTemplate.id] as string);
        }
      }
    });
    // Need the specificity of modelID here as the Table supports non model rows - historically we could
    // say that 'id' was always a model ID. This is no linger the case so when building a link cell,
    // we always check for a 'modelID' prop on the parent row.
    next.modelID = next.id;
    return next;
  });
};

const getAccessLevel = (permission: IModelPermissions) => {
  if (!permission) return AccessIndicators.view;
  if (permission.owner) return AccessIndicators.owner;
  if (permission.view) return AccessIndicators.view;
  if (permission.edit) return AccessIndicators.edit;
  if (permission.fullAccess) return AccessIndicators.fullAccess;
  return AccessIndicators.edit;
};

/**
 * Function to add custom renderers and sorting functions to a set of TModelHistoryColumnDefs
 */
export const renderPermissions = (cell: CellContext<TModelHistoryTablePartialModel, unknown>) => {
  const access = getAccessLevel(cell.getValue() as IModelPermissions);
  return (
    <Tooltip content={access.label} openOnTargetFocus={false}>
      <Icon icon={access.icon} />
    </Tooltip>
  );
};

/**
 * Generic renderer for "other" cells. Handles both formatted primitive values and unformatted fact values.
 * Expects fact formats to be parsed.
 */
export const renderFactOrValue = (
  col: TModelHistoryColumnDef,
  row: TModelHistoryTablePartialModel,
  isLink: boolean,
  theme: TTheme,
  textToHighlight?: string
) => {
  if (col.id && col.id !== "selectModel") {
    const toRender: TableValue | undefined = row[col.id];
    if (!toRender) {
      return <span>-</span>;
    }
    let value = toRender;
    let formula: string | undefined;
    let format = { ...BaseFormatObject };
    if (isRawFactOrOtherValue(toRender)) {
      value = toRender.error || toRender.value || "";
      formula = toRender.formula || "";
      const factFormat = getValidJSON<IFormatObject>(toRender.format as string) || {};
      const colFormat = col.meta?.format;
      format = shallowMergeExcludingNullishValues(
        BaseFormatObject,
        factFormat,
        {
          ...(toRender.error && { valFormat: EValFormat.PLAINTEXT }),
          decimalPlaces: DEFAULT_DECIMAL_PLACES,
        },
        colFormat
      );
      value = f({
        value,
        formula,
        format,
      });
    }

    if (isLink && row.modelID) {
      return (
        <StyledLink target="_blank" to={`/model/uid=${row.modelID}/${DEFAULT_MODEL_TAB}`}>
          {value}
        </StyledLink>
      );
    }

    const isHighlightedText =
      textToHighlight &&
      format.valFormat === EValFormat.PLAINTEXT &&
      value.toString().toLowerCase().includes(textToHighlight.toLowerCase());

    if (isHighlightedText) return <span>{highlightText(value.toString(), textToHighlight)}</span>;

    return (
      <span>
        {typeof value === "string" && value.indexOf("\n") !== -1
          ? value.split("\n").map((valueSplit, idx) => {
              if (idx === 0)
                return (
                  <span key={"valSplit" + idx}>
                    {valueSplit}
                    <br />
                  </span>
                );
              else return <span key={"valSplit" + idx}>{valueSplit}</span>;
            })
          : value.toString()}
      </span>
    );
  }
};

/**
 * Utility to generate an approximate skeleton of a model table given a set of column templates
 * and an optional number of rows to generate
 */
export const generateSkeletonModels = (columns: TModelHistoryColumnDef[], totalModels = 50) => {
  const emptyModel: IOptModelHistoryFields = {};
  columns.forEach((col) => {
    if (!col.id) return;
    if (col.id === ModelInfoKeys.tags) {
      emptyModel[col.id] = [];
    } else if (col.meta?.type === "date") {
      emptyModel[col.id] = new Date();
    } else if (col.meta?.type === "permissions") {
      emptyModel[col.id] = {
        view: false,
        edit: false,
        fullAccess: false,
        owner: false,
      };
    } else {
      emptyModel[col.id] = "..........";
    }
  });
  return new Array(totalModels).fill(emptyModel);
};

export const highlightText = (value: string, textToHighlight: string): JSX.Element => {
  const indexOfSubString = value.toLowerCase().indexOf(textToHighlight.toLowerCase());

  const firstSubString = value.slice(0, indexOfSubString);
  const highlightedSubString = value.slice(
    indexOfSubString,
    indexOfSubString + textToHighlight.length
  );
  const endSubString = value.slice(indexOfSubString + textToHighlight.length);

  return (
    <>
      {firstSubString}
      <span style={{ backgroundColor: colors.texthighlight }}>{highlightedSubString}</span>
      {endSubString}
    </>
  );
};
