import styled, { css } from "styled-components/macro";
import { Card, FormGroup } from "@blueprintjs/core";

const AuthContainer = styled(Card)`
  margin: auto auto;
  padding: 15px;
  width: ${({ width }) => width || css`400px`};
`;

const AuthForm = styled.form`
  // display: flex;
  // flex-direction: column;
`;

const AuthFormGroup = styled(FormGroup)`
  margin: 0 0 15px;
`;

export { AuthContainer, AuthForm, AuthFormGroup };
