import React, { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { Button, Classes, Dialog, FormGroup, Intent } from "@blueprintjs/core";

import { multiToaster } from "utils/toaster";
import ModelTagInput from "components/user/ModelTagInput";
import AppContext from "../../context/appContext";
import { Themes } from "constants/uiConstants";
import useEditModelProperties from "hooks/useEditModelProperties";
import { TModelHistoryTablePartialModel } from "../../types/modelHistory";
import { IconNames } from "@blueprintjs/icons";
import { IModelHistoryResponse, TModelHistoryMutator } from "hooks/useModelHistory2";
import { EStatus } from "constants/apiConstants";

interface IEditTagDialogProps {
  isOpen: boolean;
  model?: TModelHistoryTablePartialModel;
  modelHistoryResponse?: IModelHistoryResponse;
  modelTableName: string;
  modelHistoryMutator?: TModelHistoryMutator;
  setEditTagDialogOpen: Dispatch<SetStateAction<boolean>>;
  setModel: Dispatch<SetStateAction<TModelHistoryTablePartialModel | undefined>>;
}

const EditModelTagDialog: React.FC<IEditTagDialogProps> = ({
  modelHistoryResponse,
  modelHistoryMutator,
  isOpen,
  model,
  setEditTagDialogOpen,
  setModel,
}) => {
  const {
    editModelProperties,
    fetching: editingModelProperties,
    response,
  } = useEditModelProperties({
    data: modelHistoryResponse,
    mutate: modelHistoryMutator,
  });
  const {
    config: { theme },
  } = useContext(AppContext);

  // TODO: This whole piece needs rearchitecting, but that is a job for the harmonisation process.
  // For now, we are closing the dialog in the very unlikely event that the req fails. On add, the actual underlying
  // data structure will be correct, the dirty state in the Dialog will not be.

  const closeDialog = () => {
    setEditTagDialogOpen(false);
  };

  useEffect(() => {
    if (response?.status === EStatus.failed) {
      setEditTagDialogOpen(false);
    }
  }, [setEditTagDialogOpen, response]);

  const handleAddTag = (tag: string) => {
    if (model) {
      const { tags } = model;
      if (tags && tags?.indexOf(tag) !== -1) {
        multiToaster.show({
          message: (
            <span>
              Tag already exists on valuation of <strong>{model.companyName}</strong>
            </span>
          ),
          intent: Intent.WARNING,
        });
      } else {
        const newTags = tags ? tags.concat([tag]) : [tag];
        setModel({ ...model, tags: newTags });
        editModelProperties(model, { tags: newTags });
      }
    }
  };

  const handleTagRemove = (tag: string) => {
    if (model?.tags) {
      const { tags } = model;
      const newTags = [...tags];
      newTags.splice(tags.indexOf(tag), 1);
      setModel({ ...model, tags: newTags });
      editModelProperties(model, { ...model, tags: newTags });
    }
  };
  return (
    <Dialog
      className={theme === Themes.DARK ? Classes.DARK : ""}
      icon={IconNames.EDIT}
      isOpen={isOpen}
      onClose={closeDialog}
      title={` Edit Model Tags - ${model?.companyName}`}
    >
      {model ? (
        <div className={Classes.DIALOG_BODY}>
          <p className={Classes.RUNNING_TEXT}>
            Editing tags for model: <strong>{model.companyName}</strong>
          </p>
          <FormGroup label="Edit model tags:">
            <ModelTagInput
              allTags={[]}
              handleAddTag={handleAddTag}
              handleTagRemove={handleTagRemove}
              loading={editingModelProperties}
              tags={model?.tags || []}
            />
          </FormGroup>
        </div>
      ) : null}
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button disabled={editingModelProperties} onClick={closeDialog} text="Close" />
        </div>
      </div>
    </Dialog>
  );
};

export default EditModelTagDialog;
