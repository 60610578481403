import * as consts from "constants/sharePriceConstants";

export default function sharePriceReducer(
  state = {
    activeCases: {},
    autoDisplayLineByCase: true,
    range: "1y",
  },
  action
) {
  switch (action.type) {
    case consts.UPDATE_HISTORICAL_PRICE_CHART_RANGE:
      return {
        ...state,
        range: action.payload.range,
      };
    case consts.TOGGLE_AUTO_SHARE_PRICE_LINE: {
      const { activeCaseName } = action.payload;
      return {
        ...state,
        autoDisplayLineByCase: !state.autoDisplayLineByCase,
        ...(activeCaseName && { activeCases: { [activeCaseName]: true } }),
      };
    }
    case consts.FETCH_HISTORICAL_SHARE_PRICE_DATA_SUCCESS:
      return {
        ...state,
        [action.payload.ticker]: action.payload.data,
      };
    case consts.FETCH_HISTORICAL_SHARE_PRICE_DATA_FAILURE:
      return {
        ...state,
        sharePriceDataUnavailable: true,
      };
    default:
      return state;
  }
}
