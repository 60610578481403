import { Intent } from "@blueprintjs/core";
import { EMethods, EStatus, PORTFOLIO_ROUTE } from "constants/apiConstants";
import { useCallback, useState } from "react";
import useSWR from "swr";
import { IResponse } from "types";
import { MatchingModel, Portfolio } from "types/collections";
import api, { swrApi } from "utils/api";
import { multiToaster } from "utils/toaster";

const usePortfolio = (portfolioId?: string) => {
  const [editingPortfolio, setEditingPortfolio] = useState(false);

  const {
    data: portfolio,
    isLoading,
    mutate,
  } = useSWR<IResponse<{ portfolio: Portfolio; matchingModels: MatchingModel[] | null }>>(
    portfolioId ? [PORTFOLIO_ROUTE, { portfolioId }] : null,
    swrApi
  );

  const editPortfolio = useCallback(
    async (edit: Partial<Portfolio>) => {
      setEditingPortfolio(true);

      const body = {
        ...portfolio?.data?.portfolio,
        ...edit,
      };

      const previousPortfolio = portfolio?.data?.portfolio;

      mutate(
        {
          ...portfolio,
          status: EStatus.success,
          data: { ...portfolio?.data, portfolio: body } as {
            matchingModels: MatchingModel[] | null;
            portfolio: Portfolio;
          },
        },
        false
      );

      const response = await api<{ matchingModels: MatchingModel[] | null; portfolio: Portfolio }>(
        PORTFOLIO_ROUTE,
        {
          body,
          method: EMethods.PUT,
          portfolioId,
        }
      );

      if (response.status !== EStatus.success) {
        multiToaster.show({ message: response.error, intent: Intent.WARNING });
        mutate(
          {
            ...portfolio,
            status: EStatus.success,
            data: { ...portfolio?.data, portfolio: previousPortfolio } as {
              matchingModels: MatchingModel[] | null;
              portfolio: Portfolio;
            },
          },
          false
        );
      }

      setEditingPortfolio(false);

      return response;
    },
    [mutate, portfolio, portfolioId]
  );

  return {
    editPortfolio,
    editingPortfolio,
    loadingPortfolio: isLoading,
    matchingModels: portfolio?.data?.matchingModels,
    portfolio: portfolio?.data?.portfolio,
  };
};

export default usePortfolio;
