import { createContext, Dispatch, SetStateAction } from "react";
import { TModelHistoryMutator } from "hooks/useModelHistory";
import { TModelHistoryTablePartialModel } from "types/modelHistory";

interface IModelHistoryContext {
  mutators: { mutator: TModelHistoryMutator; tableName: string }[];
  setMutators: Dispatch<SetStateAction<{ mutator: TModelHistoryMutator; tableName: string }[]>>;
  selectedModels: { [key: string]: TModelHistoryTablePartialModel[] };
  setSelectedModels: Dispatch<SetStateAction<{ [key: string]: TModelHistoryTablePartialModel[] }>>;
}

const ModelHistoryContext = createContext<IModelHistoryContext>({
  mutators: [],
  setMutators: () => {
    throw new Error("setMutators function must be overridden!");
  },
  selectedModels: {},
  setSelectedModels: () => {
    throw new Error("setMutators function must be overridden!");
  },
});

export default ModelHistoryContext;
