import React from "react";
import { Intent } from "@blueprintjs/core";
import { captureException } from "@sentry/react";
import { all, call, cancel, delay, put, retry, takeEvery } from "redux-saga/effects";

import {
  appStates,
  CREATE_SOCKET_ROUTE,
  FETCH_COMPANY_CONFIG_OPTIONS_ROUTE,
  GET_COMPANIES_ROUTE,
  GET_MODEL_TEMPLATES_ROUTE,
  GET_SUPPORTED_CURRENCIES_ROUTE,
  GET_SUPPORTED_GEOGRAPHIES_ROUTE,
  GET_SUPPORTED_INDUSTRIES_ROUTE,
  errorMessages,
  EMethods,
  Status as StatusTypes,
  Status,
} from "constants/apiConstants";
import * as consts from "constants/createModelConstants";
import {
  createModelFailure,
  createModelSuccess,
  fetchAvailableTickersFailure,
  fetchAvailableTickersSuccess,
  fetchCompanyConfigOptionsFailure,
  fetchCompanyConfigOptionsSuccess,
  fetchConfigOptionsFailure,
  fetchConfigOptionsSuccess,
  fetchModelTemplatesSuccess,
  updateBuildingStatus,
} from "actions/createModelActions";
import { updateAppStatus } from "actions/globalUIActions";
import { changeAppStatus } from "sagas/socketSagas";
import { api } from "utils/api";
import { createDataEventChannel, createWebSocketConnection } from "utils/sockets";
import { getToken } from "utils/auth";
import { multiToaster } from "utils/toaster";
import { IconNames } from "@blueprintjs/icons";

function* handleCreateSocketMessage(payload) {
  try {
    /**
     * Collect the data from the message.
     * Produce the relevant toast and update the building status.
     * */
    const data = JSON.parse(payload.data);
    const { error, status } = data;

    let model, step, state;

    if (data.data) {
      model = data.data.data;
      step = data.data.step;
      state = data.data.state;
    }
    // Feedback the build stage to the user.
    const success = status === Status.SUCCESS;
    const building = success && state === consts.BuildStates.BUILDING;

    const intent = !success ? Intent.DANGER : building ? Intent.PRIMARY : Intent.SUCCESS;
    const loading = building && status !== Status.FAILURE;
    const icon = success ? IconNames.TICK : IconNames.ERROR;
    const message = error ? (
      <span>
        STEP FAILED: <strong>{step}</strong>. ERROR: <strong>{error}</strong>.
      </span>
    ) : (
      <span>
        STEP: <strong>{step}</strong>
      </span>
    );

    yield put(updateBuildingStatus(status, building, message));
    yield call(changeAppStatus, intent, message, loading, icon, true);

    // Handle a failed step
    if (status === Status.FAILURE) {
      yield put(createModelFailure(step, error));
      yield cancel();
    }

    if (state === consts.BuildStates.COMPLETED) {
      yield put(createModelSuccess(model));
      yield delay(2000);
      yield updateAppStatus({ intent, message: appStates.NORMAL, loading });
    }
  } catch (err) {
    //eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
  }
}

export function* createModel(action) {
  try {
    const { payload } = action;
    const token = yield call(getToken, "accessToken");
    const socket = yield call(createWebSocketConnection, `${CREATE_SOCKET_ROUTE}/${token}`);
    const channel = yield call(createDataEventChannel, socket);
    yield takeEvery(channel, handleCreateSocketMessage);

    // Send the initial payload to the create socket.
    socket.send(JSON.stringify({ ...payload, token }));
  } catch (err) {
    multiToaster.show({
      message: err.message || err,
      intent: err.message === errorMessages.INTERNAL_SERVER_ERROR ? Intent.DANGER : Intent.WARNING,
    });
    // eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
  }
}

function buildFetchConfigOptionsConfig(type) {
  switch (type) {
    case consts.CURRENCY:
      return {
        route: GET_SUPPORTED_CURRENCIES_ROUTE,
        method: EMethods.GET,
      };
    case consts.INDUSTRY:
      return {
        route: GET_SUPPORTED_INDUSTRIES_ROUTE,
        method: EMethods.GET,
      };
    case consts.GEOGRAPHY:
      return {
        route: GET_SUPPORTED_GEOGRAPHIES_ROUTE,
        method: EMethods.GET,
      };
    case consts.TEMPLATE:
      return {
        route: GET_MODEL_TEMPLATES_ROUTE,
        method: EMethods.GET,
      };
    default:
      return {
        route: "/",
        method: "GET",
      };
  }
}

export function* fetchAllConfigOptions() {
  try {
    yield retry(4, 1000, function* triggerConfigFetchSagas() {
      yield all([
        call(fetchConfigOptions, { payload: { type: consts.GEOGRAPHY } }),
        call(fetchConfigOptions, { payload: { type: consts.INDUSTRY } }),
        call(fetchConfigOptions, { payload: { type: consts.CURRENCY } }),
        call(fetchModelTemplates),
      ]);
    });
  } catch (err) {
    multiToaster.show({
      intent: Intent.DANGER,
      message: "Unable to fetch config options. Please refresh to try again.",
    });
    // eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
  }
}

export function* fetchConfigOptions(action) {
  try {
    const { type } = action.payload;
    const { route, ...config } = buildFetchConfigOptionsConfig(type);
    const res = yield call(api, route, config);
    if (res.status === "success") {
      yield put(fetchConfigOptionsSuccess({ type, values: res.data }));
    } else {
      const error = res.message || res.error || errorMessages.INTERNAL_SERVER_ERROR;
      multiToaster.show({
        message: error,
        intent: error === errorMessages.INTERNAL_SERVER_ERROR ? Intent.DANGER : Intent.WARNING,
      });
      yield put(fetchConfigOptionsFailure());
    }
  } catch (err) {
    multiToaster.show({
      message: err.message,
      intent: err.message === errorMessages.INTERNAL_SERVER_ERROR ? Intent.DANGER : Intent.WARNING,
    });
    yield put(fetchConfigOptionsFailure());
  }
}

export function* fetchModelTemplates() {
  try {
    const res = yield call(api, GET_MODEL_TEMPLATES_ROUTE);
    if (res.status === StatusTypes.SUCCESS) {
      yield put(fetchModelTemplatesSuccess(res.data));
    } else {
      multiToaster.show({
        intent: Intent.DANGER,
        message: "Unable to fetch model templates. Please refresh to  try again.",
      });
    }
  } catch (err) {
    multiToaster.show({
      intent: Intent.DANGER,
      message: "Unable to fetch model templates. Please refresh to  try again.",
    });
    // eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
  }
}

export function* fetchAvailableTickers(action) {
  try {
    const isUploader = GET_COMPANIES_ROUTE.includes("uploader");
    const res = yield call(api, GET_COMPANIES_ROUTE, {
      method: isUploader ? EMethods.POST : EMethods.GET,
      templateID: action.payload.templateID,
      ...(isUploader && {
        body: { companyName: "", ticker: "", industry: "", currency: "", geography: "" },
      }),
    });
    if (res.status === StatusTypes.SUCCESS) {
      yield put(fetchAvailableTickersSuccess(res.data));
    } else {
      yield put(fetchAvailableTickersFailure());
      multiToaster.show({
        message: res.message || "Couldn't retrieve company tickers",
        intent: Intent.WARNING,
      });
    }
  } catch (err) {
    multiToaster.show({
      message: err.message,
      intent: err.message === errorMessages.INTERNAL_SERVER_ERROR ? Intent.DANGER : Intent.WARNING,
    });
    yield put(fetchAvailableTickersFailure());
  }
}

export function* fetchCompanyConfigOptions(action) {
  try {
    const res = yield call(api, FETCH_COMPANY_CONFIG_OPTIONS_ROUTE, {
      method: EMethods.GET,
      templateID: action.payload.templateID,
      ticker: action.payload.ticker,
    });
    if (res.status === StatusTypes.SUCCESS) {
      yield put(fetchCompanyConfigOptionsSuccess(res.data));
    } else {
      multiToaster.show({
        status: Intent.WARNING,
        message: res.message,
      });
      yield put(fetchCompanyConfigOptionsFailure());
    }
  } catch (err) {
    //eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
  }
}
