import { useNavigate, useParams } from "react-router-dom";
import { Intent } from "@blueprintjs/core";
import { multiToaster } from "utils/toaster";

import useSWR from "swr";
import { IResponse } from "types";
import { TModelHistoryTablePartialModel } from "types/modelHistory";
import {
  EMethods,
  EStatus,
  FETCH_PORTFOLIOS_ROUTE,
  PORTFOLIO_ROUTE,
  FETCH_WATCHLISTS_ROUTE,
  WATCHLIST_ROUTE,
  WATCHLIST_CONFIG_ROUTE,
} from "constants/apiConstants";
import api, { swrApi } from "utils/api";
import { useState } from "react";
import { Dashboard } from "types/dashboard";
import { Portfolio, PortfolioCallback, Watchlist, WatchlistCallback } from "types/collections";

/**
 * TODO
 * See if watchlists and portfolios can share the same props
 * Request the type as input prop for the hook
 */
type TUseCollections = () => {
  newWatchlist: WatchlistCallback | undefined;
  watchlists: Watchlist[] | undefined;
  fetchWatchlistsError: unknown;
  mutateWatchlists: () => void;
  fetchingWatchlists: boolean;
  navigate: ReturnType<typeof useNavigate>;
  selectedWatchlist: Watchlist | undefined;
  fetchWatchlistError: unknown;
  fetchingWatchlist: boolean;
  creatingWatchlist: boolean;
  createWatchlist: (
    watchlistName: string,
    selectedCompanies: TModelHistoryTablePartialModel[],
    editWatchlist?: Watchlist,
    watchlistWithModels?: Watchlist
  ) => Promise<boolean>;
  watchlistConfig: { dashboard: Dashboard } | undefined;
  resetWatchlist: () => void;
  portfolios: Portfolio[] | undefined;
  fetchPortfoliosError: unknown;
  mutatePortfolios: () => void;
  fetchingPortfolios: boolean;
  selectedPortfolio: PortfolioCallback | undefined;
  fetchPortfolioError: unknown;
  fetchingPortfolio: boolean;
  editPortfolio: (portfolioWithModels: Portfolio) => void;
};

const useCollections: TUseCollections = () => {
  const navigate = useNavigate();
  const { watchlistId } = useParams();
  const { portfolioId } = useParams();
  const [newWatchlist, setNewWatchlist] = useState<WatchlistCallback | undefined>();
  const [fetchingCreateWatchlist, setFetchingCreateWatchlist] = useState<boolean>(false);

  const {
    data: watchlists,
    error: fetchWatchlistsError,
    mutate: mutateWatchlists,
    isLoading: fetchingWatchlists,
  } = useSWR<IResponse<Watchlist[]>>([FETCH_WATCHLISTS_ROUTE], swrApi);

  const {
    data: selectedWatchlist,
    error: fetchWatchlistError,
    isLoading: fetchingWatchlist,
    mutate: mutateWatchlist,
  } = useSWR<IResponse<Watchlist>>(watchlistId ? [WATCHLIST_ROUTE, { watchlistId }] : null, swrApi);

  const { data: watchlistConfig } = useSWR<IResponse<{ dashboard: Dashboard }>>(
    [WATCHLIST_CONFIG_ROUTE, { watchlistId }],
    swrApi
  );

  const createWatchlist = async (
    watchlistName: string,
    selectedCompanies: TModelHistoryTablePartialModel[],
    editWatchlist?: Watchlist,
    watchlistWithModels?: Watchlist
  ) => {
    setFetchingCreateWatchlist(true);
    const response: IResponse<WatchlistCallback> = await api(WATCHLIST_ROUTE, {
      body: {
        ...(watchlistWithModels
          ? watchlistWithModels
          : {
              companies: selectedCompanies.map((company) => ({
                modelId: company.modelId,
                ticker: company.ticker,
                companyName: company.companyName,
              })),
              name: watchlistName,
              ...(editWatchlist ? { id: editWatchlist.id } : {}),
            }),
      },
      method: editWatchlist || watchlistWithModels ? EMethods.PUT : EMethods.POST,
    });
    if (response.status === EStatus.success) {
      if (
        !response.data.matchingModels ||
        response.data.matchingModels.length === 0 ||
        watchlistWithModels
      ) {
        multiToaster.show({
          intent: Intent.SUCCESS,
          message: `${response.data.watchlist.name} was ${editWatchlist ? "edited" : "created"}`,
        });

        mutateWatchlist();
        mutateWatchlists();
        return true;
      } else {
        setFetchingCreateWatchlist(false);
        setNewWatchlist(response.data);
      }
    } else {
      multiToaster.show({ intent: Intent.WARNING, message: response.error });
      return true;
    }

    return false;
  };

  const resetWatchlist = () => {
    setNewWatchlist(undefined);
  };

  const {
    data: portfolios,
    error: fetchPortfoliosError,
    mutate: mutatePortfolios,
    isLoading: fetchingPortfolios,
  } = useSWR<IResponse<Portfolio[]>>([FETCH_PORTFOLIOS_ROUTE], swrApi);

  const {
    data: selectedPortfolio,
    error: fetchPortfolioError,
    isLoading: fetchingPortfolio,
    mutate: mutatePortfolio,
  } = useSWR<IResponse<PortfolioCallback>>(
    portfolioId ? [PORTFOLIO_ROUTE, { portfolioId }] : null,
    swrApi
  );

  const editPortfolio = async (portfolioWithModels?: Portfolio) => {
    const response: IResponse<PortfolioCallback> = await api(PORTFOLIO_ROUTE, {
      body: {
        ...portfolioWithModels,
      },
      method: EMethods.PUT,
    });
    if (response.status === EStatus.success) {
      if (
        !response.data.matchingModels ||
        response.data.matchingModels.length === 0 ||
        portfolioWithModels
      ) {
        multiToaster.show({
          intent: Intent.SUCCESS,
          message: `${response.data.portfolio.name} edited`,
        });
        mutatePortfolios();
        mutatePortfolio();
      }
    } else {
      multiToaster.show({ intent: Intent.WARNING, message: response.error });
    }
  };

  return {
    newWatchlist,
    watchlists: watchlists?.data,
    fetchWatchlistsError,
    mutateWatchlists,
    fetchingWatchlists,
    navigate,
    selectedWatchlist: selectedWatchlist?.data,
    fetchWatchlistError,
    fetchingWatchlist,
    creatingWatchlist: fetchingCreateWatchlist,
    createWatchlist,
    watchlistConfig: watchlistConfig?.data,
    resetWatchlist,
    portfolios: portfolios?.data,
    fetchPortfoliosError,
    mutatePortfolios,
    fetchingPortfolios,
    selectedPortfolio: selectedPortfolio?.data,
    fetchPortfolioError,
    fetchingPortfolio,
    editPortfolio,
  };
};

export default useCollections;
