import React from "react";
import "styles/sensitivity-analysis.scss";
import VSHTMLTable from "components/dashboard/VSHTMLTable";
import { useSelector } from "react-redux";
import { H6 } from "@blueprintjs/core";
import { cppTableWidths, fetchTagsData, getEffectivePeriodRange } from "utils/cpp";
import { lineItemsSelector, modelInfoSelector } from "selectors/modelSelectors";
import { factsSelector } from "selectors/dataEntrySelectors";
import { tagLineItemsMapSelector } from "selectors/cpp_selectors";

const summaryPriceTargetTags = [
  "Total Inc Dividends {Valuation} (Base)",
  "Total Inc Dividends {Valuation} (Upside)",
  "Total Inc Dividends {Valuation} (Downside)",
  "Total Inc Dividends {Valuation} (Consensus)",
];
const summaryTotalReturnTags = [
  "Total Return (%) {Valuation} (Base)",
  "Total Return (%) {Valuation} (Upside)",
  "Total Return (%) {Valuation} (Downside)",
  "Total Return (%) {Valuation} (Consensus)",
];
// TODO: Risk reward to be temporarily disabled
//const summaryRiskRewardTags = ["Risk Reward Up/down (%), Valuation (Upside)"];
const rowHeaders = ["Base", "Upside", "Downside", "Consensus"];

const CPP_ValuationSummary = () => {
  const tagLineItemsMap = useSelector(tagLineItemsMapSelector);
  const lineItems = useSelector(lineItemsSelector);
  const facts = useSelector(factsSelector);
  const modelInfo = useSelector(modelInfoSelector);
  const startPeriod = modelInfo?.startPeriod;

  const header: number[] = getEffectivePeriodRange(startPeriod, tagLineItemsMap, lineItems, facts);

  const priceTarget = fetchTagsData(
    summaryPriceTargetTags,
    header,
    tagLineItemsMap,
    lineItems,
    facts,
    rowHeaders
  );
  const totalReturn = [
    null,
    ...fetchTagsData(summaryTotalReturnTags, header, tagLineItemsMap, lineItems, facts, rowHeaders),
  ];
  /*const riskReward = [
    null,
    ...fetchTagsData(summaryRiskRewardTags, header, tagLineItemsMap, lineItems, facts, ["Up/down"]),
  ];*/

  const valuationSummaryData = {
    priceTarget: [["Price Target (w/ div)", ...header], ...priceTarget],
    totalReturn,
    //riskReward,
  };

  return (
    <>
      <VSHTMLTable data={valuationSummaryData.priceTarget} widths={cppTableWidths} />
      <br />
      <H6>&nbsp;&nbsp;&nbsp;Total Return (%)</H6>
      <VSHTMLTable data={valuationSummaryData.totalReturn} widths={cppTableWidths} />
      <br />
      {/*<H6>&nbsp;&nbsp;&nbsp;Risk Reward</H6>
      <VSHTMLTable data={valuationSummaryData.riskReward} widths={cppTableWidths} />
      */}
    </>
  );
};

export default CPP_ValuationSummary;
