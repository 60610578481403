import * as consts from "constants/dataEntryConstants";

/**
 * Opens a socket and triggers sags to listen and handle socket events.
 * */
export function openSocket(modelID) {
  return {
    type: consts.OPEN_SOCKET,
    payload: { modelID },
  };
}

/**
 * Closes an open data entry socket
 * */
export function closeSocket() {
  return {
    type: consts.CLOSE_SOCKET,
  };
}

/**
 * Dispatched to edit a fact's formula.
 *
 * @param {string} caseID - The ID of the case being edited
 * @param {string} moduleID - The ID of the module of the edited facts (to navigate to on undo)
 * @param {Array} facts - An array of facts of the following shape.
 * @param {string} customSuccessMsg - Message to be toasted after socket success
 *
 * facts: [{ uid, period, formula, identifier }]
 * */
export function editFormula(caseID, moduleID, facts, customSuccessMsg) {
  return {
    type: consts.EDIT_FORMULA,
    payload: {
      params: {
        caseID,
        moduleID,
        facts,
        customSuccessMsg,
      },
      reverseParams: { caseID, moduleID },
    },
  };
}

/**
 * Dispatched to edit a facts format.
 *
 * @param {string} caseID - The ID of the case being edited
 * @param {string} moduleID - The ID of the case being edited
 * @param {Array} facts - An array of facts with JSONified format props.
 * @param {Array} originalFacts - An array of facts of the following shape.
 *
 * facts: [{ uid, period, formula, identifier }]
 * */
export function editFormat(caseID, moduleID, facts, originalFacts) {
  return {
    type: consts.EDIT_FORMAT,
    payload: {
      params: { caseID, moduleID, facts },
      reverseParams: { caseID, facts: originalFacts },
    },
  };
}

/**
 * Dispatched to show the app is in a committing state
 * */
export function committing() {
  return {
    type: consts.COMMITTING,
  };
}

/**
 * Dispatched to show the app has committed some data
 *
 * @param {number} clear - Whether the committed array should be cleared
 * */
export function committed(clear) {
  return {
    type: consts.COMMITTED,
    payload: {
      clear,
    },
  };
}

/**
 * Dispatched to set the commit error state.
 * */
export function setCommitError(commitErrorState) {
  return {
    type: consts.SET_COMMIT_ERROR,
    payload: {
      commitErrorState,
    },
  };
}

/**
 * Dispatched to add a module to a case.
 *
 * @param {string} name - The name of the module being added.
 * @param {string} caseID - The ID of the case being edited.
 * @param {string} moduleID - The ID of the moduel being added.
 * @param {string} parentModuleID - The ID of the module's parent.
 * @param {string} templateModuleID - The ID of the module's template.
 * @param {bool} unlinked - Whether the module is unlinked or linked.
 * */
export function addModule(name, caseID, moduleID, parentModuleID, unlinked, periodType) {
  return {
    type: consts.ADD_MODULE,
    payload: {
      params: {
        buffered: true,
        caseID,
        name,
        moduleID,
        parentModuleID,
        unlinked,
        periodType,
      },
    },
  };
}

export function addModuleSuccess(data, parentItem) {
  return {
    type: consts.ADD_MODULE_SUCCESS,
    payload: {
      data,
      parentItem,
    },
  };
}

export function addModuleFailure() {
  return {
    type: consts.ADD_MODULE_FAILURE,
  };
}

export function addClipboardItem(item) {
  return {
    type: consts.ADD_CLIPBOARD_ITEM,
    payload: {
      item,
    },
  };
}

export function fetchKeyRatioData(moduleUid, statement) {
  return {
    type: consts.FETCH_KEY_RATIO_DATA,
    payload: {
      moduleUid,
      statement,
    },
  };
}

export function fetchKeyRatioDataSuccess(data, moduleUid) {
  return {
    type: consts.FETCH_KEY_RATIO_DATA_SUCCESS,
    payload: {
      data,
      moduleUid,
    },
  };
}

export function fetchKeyRatioDataFailure(moduleUid) {
  return { type: consts.FETCH_KEY_RATIO_DATA_FAILURE, payload: { moduleUid } };
}

export function updateCurrentModule(moduleName) {
  return {
    type: consts.UPDATE_CURRENT_MODULE,
    payload: {
      moduleName,
    },
  };
}

export function updateCurrentModuleReducer(module) {
  return {
    type: consts.UPDATE_CURRENT_MODULE_REDUCER,
    payload: {
      module,
    },
  };
}

export function cycleModule(dir) {
  return {
    type: consts.CYCLE_MODULE,
    payload: {
      dir,
    },
  };
}

/**
 * Dispatched to add a column to a module
 *
 * @param {string} caseID - The case of the module being edited
 * @param {string} moduleUid - The uid of the module being operated on
 * @param {number} newPeriod - The new period being added
 * @param {string} position - start | end The postiion of the period being added
 * */
export function addColumn(caseID, moduleID, newPeriod, position) {
  return {
    type: consts.ADD_COLUMN,
    payload: {
      params: {
        caseID,
        buffered: true,
        moduleID,
        newPeriod,
      },
      reverseParams: {
        caseID,
        moduleID,
        newPeriod,
        position,
      },
    },
  };
}

/**
 * Action disptched to delete a column
 *
 * @param {string} caseID - The case of the module being edited
 * @param {string} moduleID - The uid of the module being edited
 * @param {string} moduleName - The name of the module being edited
 * @param {string} period - The period of the column being deleted
 * @param {Array} facts - The facts removed when the column was deleted
 * */
export function deleteColumn(caseID, moduleID, moduleName, period, facts) {
  return {
    type: consts.DELETE_COLUMN,
    payload: {
      params: {
        caseID,
        buffered: true,
        period,
        moduleID,
        moduleName,
      },
      reverseParams: {
        caseID,
        period,
        moduleID,
        facts,
      },
    },
  };
}

/**
 * Dispatched to add a new line item.
 */
export function addLineItem(caseID, order, lineItemName, currentModule, moduleID) {
  return {
    type: consts.ADD_LINE_ITEM,
    payload: {
      params: {
        buffered: true,
        caseID,
        order,
        lineItemName,
        moduleID,
      },
      reverseParams: {
        currentModule,
        moduleID,
        order,
      },
    },
  };
}

/**
 * Dispatched to trigger the parent reorderer.
 * @param {string} caseID - The id of the case being edited
 * @param {string} moduleID- The id of the module being edited
 * @param {Object} editedLineItem - The edited line item
 * @param {number} newOrder - The new order of the line item
 * */
export function reorderModule(caseID, moduleID, editedLineItem, newOrder) {
  return {
    type: consts.REORDER_MODULE,
    payload: {
      params: {
        caseID,
        lineItemID: editedLineItem.uid,
        moduleID,
        prevOrder: editedLineItem.order,
        ...editedLineItem,
        uid: moduleID,
        order: newOrder,
      },
      reverseParams: {
        caseID,
        lineItemID: editedLineItem.uid,
        prevOrder: editedLineItem.order,
        ...editedLineItem,
        uid: moduleID,
        order: newOrder,
      },
    },
  };
}

/**
 * Dispatched to edit a line item
 *
 * @param {string} caseID - The id of the case being edited.
 * @param {string} moduleID - The id of the module being edited
 * @param {Object} editedLineItems - The edited line items
 * */
export function editLineItem(caseID, moduleID, editedLineItems) {
  return {
    type: consts.EDIT_LINE_ITEMS,
    payload: {
      params: {
        caseID,
        moduleID,
        lineItems: editedLineItems,
      },
      reverseParams: {
        caseID,
        moduleID,
        editedLineItems,
      },
    },
  };
}

export function editLineItemTags(lineItemID, modelID, tags, oldTags) {
  return {
    type: consts.EDIT_LINE_ITEM_TAGS,
    payload: {
      lineItemID,
      modelID,
      tags,
      oldTags,
    },
  };
}

export function editLineItemTagsFailure(lineItemID, tags) {
  return {
    type: consts.EDIT_LINE_ITEM_TAGS_FAILURE,
    payload: {
      lineItemID,
      tags,
    },
  };
}

/** Dispatched to delete a line item */
export function deleteLineItem(caseID, moduleID, lineItemId, lineItem, restoreFacts) {
  return {
    type: consts.DELETE_LINE_ITEM,
    payload: {
      params: {
        buffered: true,
        caseID,
        moduleID,
        order: lineItem.order,
        lineItemId,
      },
      reverseParams: {
        caseID,
        moduleID,
        lineItem,
        restoreFacts,
      },
    },
  };
}

export function deleteLineItemByOrder(order, moduleUid, historic) {
  return {
    type: consts.DELETE_LINE_ITEM,
    payload: {
      order,
      moduleUid,
      historic,
    },
  };
}

export function editLineItemSuccess() {
  return {
    type: consts.EDIT_LINE_ITEMS_SUCCESS,
  };
}

export function editLineItemFailure() {
  return {
    type: consts.EDIT_LINE_ITEMS_FAILURE,
  };
}

/** DELETE MODULE
 * Module uid is passed on click, in case user navigates to another module prior
 * to the currentModuleIdSelector being called in the deleteModule sagas
 *
 * @param {string} caseID - The id of the case being operated upon
 * @param {string} moduleID - The id of the module being deleted
 */
export function deleteModule(caseID, moduleID, parentModuleID) {
  return {
    type: consts.DELETE_MODULE,
    payload: {
      params: {
        buffered: true,
        caseID,
        uid: moduleID,
        moduleID,
        parentModuleID,
      },
    },
  };
}

export function deleteModuleSuccess(data) {
  return {
    type: consts.DELETE_MODULE_SUCCESS,
    payload: {
      data,
    },
  };
}

export function deleteModuleFailure(module) {
  return {
    type: consts.DELETE_MODULE_FAILURE,
    payload: {
      module,
    },
  };
}

/**
 * Dispatched to tell the server to undo an action
 * */
export function undo(caseID) {
  return {
    type: consts.UNDO,
    payload: { params: { caseID } },
  };
}

/**
 * Dispatched to tell the server to redo an action
 * */
export function redo(caseID) {
  return {
    type: consts.REDO,
    payload: { params: { caseID } },
  };
}

/**
 * Dispatched to fetch a case's undo history
 *
 * @param {string} modelID - The id of the model to fetch the undo history for
 * @param {string} caseID - The id of the case being fetched
 * */
export function fetchUndoHistory(modelID, caseID) {
  return {
    type: consts.UNDO_HISTORY,
    payload: {
      params: {
        modelID,
        caseID,
      },
    },
  };
}

/**
 * Dispatched on a successful fetch of a case's undo history
 *
 * @param {Object} data - The data response object
 * */
export function fetchUndoHistorySuccess(data) {
  return {
    type: consts.UNDO_HISTORY_SUCCESS,
    payload: {
      data,
    },
  };
}

/**
 * Dispatched on unsuccessful fetch of a case's undo history
 * */
export function fetchUndoHistoryFailure() {
  return {
    type: consts.UNDO_HISTORY_FAILURE,
  };
}

/**
 * This will flush the data reducer back to its default state.
 */
export function flushDataReducer() {
  return {
    type: consts.FLUSH_DATA_REDUCER,
  };
}

/** edit a module's name */
export function editModuleName(module, newName) {
  return {
    type: consts.EDIT_MODULE_NAME,
    payload: {
      module,
      newName,
    },
  };
}

/**
 * Dispatched on failed edit of module name
 *
 * @param {Object} module - The original module
 * */
export function editModuleNameFailure(module) {
  return {
    type: consts.EDIT_MODULE_NAME_FAILURE,
    payload: {
      module,
    },
  };
}

export function editModuleNameSuccess(data) {
  return {
    type: consts.EDIT_MODULE_NAME_SUCCESS,
    payload: {
      data,
    },
  };
}

export function fetchQuickCalculations() {
  return {
    type: consts.FETCH_QUICK_CALCULATIONS,
  };
}

export function fetchQuickCalculationsFailure() {
  return {
    type: consts.FETCH_QUICK_CALCULATIONS_FAILURE,
  };
}

export function fetchQuickCalculationsSuccess(data) {
  return {
    type: consts.FETCH_QUICK_CALCULATIONS_SUCCESS,
    payload: {
      data,
    },
  };
}

export function addQuickCalculation(caseID, moduleID, lineItemID, calc) {
  return {
    type: consts.ADD_QUICK_CALCULATION,
    payload: {
      params: {
        ...calc,
        caseID,
        moduleID,
        lineItemID,
      },
    },
  };
}

export function addQuickCalculationFailure() {
  return {
    type: consts.ADD_QUICK_CALCULATION_FAILURE,
  };
}

export function addQuickCalculationSuccess(data, module) {
  return {
    type: consts.ADD_QUICK_CALCULATION_SUCCESS,
    payload: {
      data,
      module,
    },
  };
}

/**
 * Request the HTML file with the filling data
 * @param ticker
 * @param period
 */
export function pullSource(ticker, period) {
  return {
    type: consts.PULL_SOURCE,
    payload: {
      ticker,
      period,
    },
  };
}

/**
 * @param {String} ref - Concatenation of ticker and period used to store retrieved source HTML
 * @param {String} data - HTML document
 */
export function pullSourceSuccess(ref, data) {
  return {
    type: consts.PULL_SOURCE_SUCCESS,
    payload: {
      ref,
      data,
    },
  };
}

/**
 * @param {String} ref - Concatenation of ticker and period used to store retrieved source HTML
 */
export function pullSourceFailure(ref) {
  return {
    type: consts.PULL_SOURCE_FAILURE,
    payload: {
      ref,
    },
  };
}

export function recalculateModel() {
  return {
    type: consts.RECALCULATE_MODEL,
  };
}
