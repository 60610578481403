import { useEffect } from "react";
import { updatePageTitle } from "utils/generic";

/**
 * Setter hook for the page title
 *
 * @param {string} title - The title of the page.
 * */
const useSetPageTitle = (title) => {
  useEffect(() => {
    updatePageTitle(title);
  }, [title]);
};

export default useSetPageTitle;
