import { Intent } from "@blueprintjs/core";
import { captureException } from "@sentry/react";
import { call, put, select } from "redux-saga/effects";
import * as modelActions from "actions/modelActions";
import { api } from "utils/api";
import {
  companyNameSelector,
  currentCaseSelector,
  modelTypeSelector,
  modelUidSelector,
  tickerSelector,
} from "selectors/modelSelectors";
import * as apiConsts from "constants/apiConstants";
import { multiToaster } from "utils/toaster";
import { getToken } from "utils/auth";
import { WidgetKeys } from "../constants/widgetKeys";

function buildConfig(type, caseUid, modelType, headers) {
  const DEFAULT_REQ = {
    method: apiConsts.EMethods.GET,
    caseID: caseUid,
    "valuation-type": modelType,
    ...headers,
  };
  switch (type) {
    case WidgetKeys.SUMMARY:
      return {
        ...DEFAULT_REQ,
        route: `${apiConsts.SUMMARY_ROUTE}`,
      };
    case WidgetKeys.CASH_FLOW:
      return {
        ...DEFAULT_REQ,
        route: `${apiConsts.FCFF_ROUTE}`,
      };
    case WidgetKeys.TERMINAL_VALUE:
      return {
        ...DEFAULT_REQ,
        route: `${apiConsts.TERMINAL_VALUE_ROUTE}`,
      };
    case WidgetKeys.SENSITIVITY_ANALYSES:
      return {
        ...DEFAULT_REQ,
        route: `${apiConsts.SENSITIVITY_ANALYSES_ROUTE}`,
      };
    case WidgetKeys.IMPLIED_FORWARD_MULTIPLES:
      return {
        ...DEFAULT_REQ,
        route: apiConsts.IMPLIED_FORWARD_MULTIPLES_ROUTE,
      };
    case WidgetKeys.CPP_PEER_MULTIPLES:
      return {
        ...DEFAULT_REQ,
        route: apiConsts.COMPS_ROUTE,
      };
    case WidgetKeys.CPP_HISTORICAL_VALUATION:
      return {
        ...DEFAULT_REQ,
        route: apiConsts.HISTORICAL_VALUATION_ROUTE,
      };
    default:
      return {
        route: "/",
        method: "GET",
      };
  }
}

/** edit model info */
export function* editModelProperties(action) {
  try {
    const { modelID, edit } = action.payload;
    const fallbackModelInfo = yield select((state) => state.model.modelInfo);
    yield put(modelActions.editModelPropertiesRedux(modelID, edit));
    const res = yield call(api, apiConsts.EDIT_MODEL_PROPERTIES_ROUTE, {
      method: apiConsts.EMethods.POST,
      body: {
        modelID,
        ...edit,
      },
    });
    if (res?.status !== apiConsts.Status.SUCCESS) {
      const err = "Unable to edit model properties:" + res?.error;
      multiToaster.show({
        message: err,
        intent: Intent.DANGER,
      });
      yield put(modelActions.editModelPropertiesFailure(fallbackModelInfo));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
  }
}

/** Pull all model data */
export function* pullModel(action) {
  try {
    const { modelId, previousVersionHash } = action.payload;
    const res = previousVersionHash
      ? yield call(api, apiConsts.PULL_MODEL_PREVIOUS_VERSION_ROUTE, {
          method: apiConsts.EMethods.GET,
          modelId,
          hash: previousVersionHash,
        })
      : yield call(api, apiConsts.PULL_MODEL_ROUTE, {
          method: apiConsts.EMethods.GET,
          modelId,
        });

    if (res?.status === apiConsts.Status.SUCCESS) {
      yield put(modelActions.pullModelSuccess(res.data));
    } else {
      const err = res?.error || "Unable to retrieve model data";
      multiToaster.show({
        message: err,
        intent: Intent.DANGER,
      });
      yield put(modelActions.pullModelFailure(err));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
  }
}

export function* fetchWidgetData(action) {
  try {
    const { caseUid, widgetName } = action.payload;
    const modelType = yield select(modelTypeSelector);
    const ticker = yield select(tickerSelector);
    const { route, ...config } = buildConfig(widgetName, caseUid, modelType, { ticker });
    const res = yield call(api, route, config);
    if (res.status === apiConsts.Status.SUCCESS) {
      yield put(modelActions.fetchWidgetDataSuccess(res, caseUid));
    } else {
      yield put(modelActions.fetchWidgetDataFailure(widgetName, `ERROR: ${res.error}`, caseUid));
    }
  } catch (err) {
    const { caseUid, widgetName } = action.payload;
    yield put(
      modelActions.fetchWidgetDataFailure(
        widgetName,
        "ERROR: Something went wrong. Please reload.",
        caseUid
      )
    );
    // eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
  }
}

export function* fetchData(action) {
  try {
    const { route, identifier, headers } = action.payload;
    const res = yield call(api, route, headers);
    if (res.status === apiConsts.Status.SUCCESS) {
      yield put(modelActions.fetchDataSuccess(identifier, res.data));
    } else {
      multiToaster.show({
        message: "Unable to fetch " + identifier + ": " + res.message,
        intent: Intent.DANGER,
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
  }
}

export function* updateSensitivityIncrement(action) {
  try {
    const { caseUid, change, currentAnalysis } = action.payload;
    const modelType = yield select(modelTypeSelector);
    const nextAnalysis = { ...currentAnalysis, ...change };
    yield put(modelActions.updateSensitivityIncrementRedux(caseUid, nextAnalysis));
    const res = yield call(api, apiConsts.UPDATE_SENSITIVITY_ROUTE, {
      method: apiConsts.EMethods.POST,
      caseId: caseUid,
      body: { ...nextAnalysis, valuationType: modelType },
    });
    if (res.status === apiConsts.Status.SUCCESS) {
      yield put(modelActions.updateSensitivityIncrementSuccess(caseUid, res.data));
    } else {
      yield put(modelActions.updateSensitivityIncrementFailure(caseUid, currentAnalysis));
    }
  } catch (err) {
    //eslint-disable-next-line no-console
    console.log(err);
    captureException(err);
  }
}

export function* exportToExcel() {
  try {
    const valName = yield select(companyNameSelector);
    const modelID = yield select(modelUidSelector);
    const currentCase = yield select(currentCaseSelector);
    const token = yield call(getToken, "accessToken");

    const headers = new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "octet-stream",
      Client: "web-client",
      caseId: currentCase.uid,
      modelID: modelID,
    });

    fetch(apiConsts.EXPORT_TO_EXCEL_ROUTE, { headers })
      .then((res) => {
        const reader = res.body.getReader();
        return new ReadableStream({
          start(controller) {
            const pump = () => {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                return pump();
              });
            };
            return pump();
          },
        });
      })
      .then((stream) => new Response(stream))
      .then((res) => res.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Valsys Export - ${valName}.xlsx`;
        a.click();
        multiToaster.show({
          message: "Successfully created Excel template.",
          intent: Intent.SUCCESS,
        });
      });
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    captureException(err);
    multiToaster.show({
      message: "Unable to create Excel file. Please contact support.",
      intent: Intent.DANGER,
    });
  }
}
