import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Callout,
  Card,
  Classes,
  Dialog,
  Divider,
  Intent,
  Position,
  Spinner,
  SpinnerSize,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Themes } from "constants/uiConstants";
import {
  EStatus,
  EMethods,
  PULL_TEMPLATE_ROUTE,
  UPDATE_TEMPLATE_ROUTE,
} from "constants/apiConstants";
import { useApiCallback } from "hooks/useApiCallback";
import AppContext from "context/appContext";
import { singleToaster } from "utils/toaster";
import { getValidJSON } from "utils/api";
import TemplateEditor, { IJSONEditorHandle } from "./TemplateEditor";
import { Flex } from "components/utility/StyledComponents";

interface ITemplateEditorDialogProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  templateID?: string;
}

export const TemplateEditorDialog: React.FC<ITemplateEditorDialogProps> = ({
  isOpen,
  setOpen,
  templateID,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);
  const [validJSON, setValidJSON] = useState(true);
  const [parsing, setParsing] = useState(false);
  const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);
  const [templateBody, setTemplateBody] = useState<string>();
  const editorHandle = useRef<IJSONEditorHandle>(null);

  const {
    callback: fetchTemplateCallback,
    fetching: fetchingTemplate,
    response: templateResponse,
  } = useApiCallback();

  const {
    callback: saveTemplateCallback,
    fetching: savingTemplate,
    response: saveResponse,
  } = useApiCallback();

  useEffect(() => {
    if (isOpen && fetchTemplateCallback) {
      fetchTemplateCallback(PULL_TEMPLATE_ROUTE, {
        method: EMethods.GET,
        templateID,
      });
    }
  }, [fetchTemplateCallback, isOpen, templateID]);

  useEffect(() => {
    setTemplateBody(JSON.stringify(templateResponse, null, "\t"));
  }, [templateResponse]);

  const handleClose = () => {
    if (!savingTemplate) setOpen(false);
  };

  const submit = (value: string) => {
    saveTemplateCallback(UPDATE_TEMPLATE_ROUTE, {
      method: EMethods.POST,
      templateID,
      body: JSON.parse(value),
    });
  };

  useEffect(() => {
    if (!savingTemplate && saveResponse) {
      if (saveResponse.status === EStatus.failed) {
        singleToaster.show({
          message: "Error while saving template model: " + saveResponse.message,
          intent: Intent.DANGER,
        });
      } else {
        const currentVal = editorHandle?.current?.getValue();
        if (currentVal) {
          setTemplateBody(currentVal);
          singleToaster.show({
            message: "Template saved successfully",
            intent: Intent.SUCCESS,
          });
        }
      }
    }
  }, [savingTemplate, saveResponse]);

  const indentJSON = () => {
    const currentVal = editorHandle?.current?.getValue();
    if (currentVal) {
      const currentValObj = getValidJSON(currentVal);
      const newVal = JSON.stringify(currentValObj, null, "\t");
      // TODO: fix session based set value clearing undoHistory
      editorHandle.current?.setValue(newVal);
    }
  };

  const confirmSubmit = () => {
    const toSubmit = editorHandle.current?.getValue();
    submit(toSubmit || "");
  };

  return (
    <Dialog
      icon={IconNames.MANUALLY_ENTERED_DATA}
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      title="Template Editor"
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      enforceFocus={true}
      isOpen={isOpen}
      onClose={handleClose}
      style={{ height: "90vh", width: "80vw" }}
    >
      <Dialog
        className={theme === Themes.DARK ? Classes.DARK : undefined}
        title="Confirm template update"
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        enforceFocus={true}
        isOpen={confirmSubmitOpen}
        onClose={() => setConfirmSubmitOpen(false)}
        style={{ height: "!00%" }}
      >
        <div className={Classes.DIALOG_BODY}>
          <Callout intent={Intent.WARNING} title="Editing organisation wide template!">
            Changes to this template might impact models that use this same template. Are you sure
            you want to proceed?
          </Callout>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setConfirmSubmitOpen(false)}>Cancel</Button>
            <Button
              disabled={parsing || !validJSON}
              intent={Intent.PRIMARY}
              type="submit"
              loading={savingTemplate}
              onClick={confirmSubmit}
              text="Update"
            />
          </div>
        </div>
      </Dialog>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setConfirmSubmitOpen(true);
        }}
        style={{ height: "calc(100% - 40px)" }}
      >
        <Card className={Classes.DIALOG_BODY} style={{ height: "calc(100% - 60px)" }}>
          <Flex fullWidth justifyContent="flex-start" style={{ marginBottom: 10 }}>
            <Flex justifyContent="flex-start">
              <ButtonGroup>
                <Tooltip content="Undo" placement={Position.BOTTOM} openOnTargetFocus={false}>
                  <Button
                    disabled={savingTemplate}
                    icon={IconNames.UNDO}
                    onClick={editorHandle.current?.undo}
                  />
                </Tooltip>
                <Tooltip content="Redo" placement={Position.BOTTOM}>
                  <Button
                    disabled={savingTemplate}
                    icon={IconNames.REDO}
                    onClick={editorHandle.current?.redo}
                  />
                </Tooltip>
              </ButtonGroup>
              <Divider />
              <Button
                disabled={validJSON || savingTemplate}
                icon={IconNames.ALIGN_LEFT}
                onClick={indentJSON}
              >
                Auto-format
              </Button>
              {/*<Divider />
              <Tooltip content="Find and Replace" placement={Position.BOTTOM}>
                <Button icon={IconNames.SEARCH} onClick={find} />
              </Tooltip>*/}
            </Flex>
            <Tag
              icon={
                parsing ? (
                  <Spinner size={SpinnerSize.SMALL} />
                ) : !validJSON ? (
                  IconNames.ERROR
                ) : (
                  IconNames.TICK
                )
              }
              intent={parsing ? Intent.PRIMARY : !validJSON ? Intent.DANGER : Intent.SUCCESS}
              large
              minimal
            >
              {parsing ? "Validating..." : !validJSON ? "JSON Error" : "JSON Valid"}
            </Tag>
          </Flex>
          {fetchingTemplate || !templateBody ? (
            <Spinner size={SpinnerSize.STANDARD} />
          ) : (
            <TemplateEditor
              body={templateBody}
              handleRef={editorHandle}
              height={"calc(100% - 70px)"}
              saving={savingTemplate}
              setParsing={setParsing}
              setValidJSON={setValidJSON}
            />
          )}
        </Card>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button disabled={savingTemplate} onClick={handleClose}>
              Close
            </Button>
            <Button
              disabled={parsing || !validJSON}
              intent={Intent.PRIMARY}
              loading={savingTemplate}
              onClick={() => setConfirmSubmitOpen(true)}
              text="Update"
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default TemplateEditorDialog;
