import { useEffect } from "react";
import { useApiCallback } from "hooks/useApiCallback";
import { TCompanyInfo } from "types/createModel";
import { IFilterState } from "types/modelHistory";
import isFunction from "lodash.isfunction";
import { GET_COMPANIES_ROUTE, EMethods } from "constants/apiConstants";
import { InitialFilterState } from "components/table/ModelFilters";

type TUseGetAvailableCompanies = (
  templateID: string | undefined,
  filters?: IFilterState
) => {
  availableCompanies: TCompanyInfo[];
  filters: IFilterState;
  fetching: boolean;
};

// eslint-disable-next-line
// @ts-ignore
const useGetAvailableCompanies: TUseGetAvailableCompanies = (
  templateID,
  filters = InitialFilterState
) => {
  const { response, callback, fetching } = useApiCallback<{
    data: TCompanyInfo[] | { data: TCompanyInfo[] };
    field: string;
  }>();

  // Effect to fetch initial data on hook first run.
  useEffect(() => {
    if (isFunction(callback)) {
      const method = GET_COMPANIES_ROUTE.includes("templates") ? EMethods.GET : EMethods.POST;
      const config = { method, templateID: templateID || "", body: filters };
      callback(GET_COMPANIES_ROUTE, config);
    }
  }, [callback, filters, templateID]);

  return {
    availableCompanies: response?.data?.data ? response.data.data : response?.data || [],
    fetching,
    filters,
  };
};

export default useGetAvailableCompanies;
