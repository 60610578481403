import { normalize, NormalizedSchema, schema } from "normalizr";
import {
  IImportCase,
  IImportFact,
  IImportLineItem,
  IImportModule,
  INormalizedImportFact,
  TNormalizedImportCase,
  TNormalizedImportLineItem,
  TNormalizedImportModule,
} from "types/import";
import { ICase, IFact, ILineItem, IModule, IRawFact, IRawModule, TVSEntity } from "types/model";
import { stringToSelector, vsFullVarRegex } from "./formulas";
import { PeriodTypes } from "../constants/createModelConstants";
import { getValidJSON } from "./api";
import { periodFormatter } from "./formatter";
import range from "lodash.range";

const factSchema = new schema.Entity<IFact | IImportFact>(
  "facts",
  {},
  {
    idAttribute: "uid",
    processStrategy: (value, parent) => {
      // extract deps
      if (!parent.deps) parent.deps = {};
      if (value?.formula) {
        const refs = value.formula.matchAll(new RegExp(vsFullVarRegex, "g"));
        parent.deps[value.uid] = [...refs].map((ref) => {
          return stringToSelector(ref[0]);
        });
      }
      // TODO: convert any decimalPlaces values from strings to numerics
      return {
        ...value,
        caseUid: value.caseUid || parent.caseUid,
        moduleUid: value.moduleUid || parent.parentUid,
        parentOrder: value.parentOrder || parent.order,
        parentUid: value.parentUid || parent.uid,
        format: getValidJSON(value.format) || {},
      };
    },
  }
);

const lineItemSchema = new schema.Entity<ILineItem | IImportLineItem>(
  "lineItems",
  {
    facts: [factSchema],
  },
  {
    idAttribute: "uid",
    processStrategy: (value, parent) => {
      // TODO - figure out a way to not alter the parent argument in place.
      if (!parent.lineItemsNames) parent.lineItemsNames = [];
      if (!parent.lineItemsNames.includes(value.name)) parent.lineItemsNames.push(value.name);

      return {
        ...value,
        parentUid: value.parentUid || parent.uid,
        caseUid: value.caseUid || parent.caseUid,
        format: getValidJSON(value.format),
      };
    },
  }
);

const moduleSchema = new schema.Entity<IModule | IImportModule>(
  "modules",
  {
    lineItems: [lineItemSchema],
  },
  {
    idAttribute: "uid",
    processStrategy: (module, parent) => {
      const mod = { ...module };

      mod.depth = parent.depth + 1 || 0;

      if (mod.depth === 0) {
        mod.caseUid = module.caseUid || parent.caseUid || parent.uid;
      } else {
        mod.caseUid = module.caseUid || parent.caseUid;
        mod.parentUid = module.parentUid || parent.uid;
      }

      mod.forecastIncrement = module.periodType === PeriodTypes.Quarterly ? 0.25 : 1;
      mod.formattedPeriods = range(
        module.moduleStart,
        module.moduleEnd + mod.forecastIncrement,
        module.forecastIncrement
      ).map((period) => periodFormatter(period, mod.startPeriod, mod.forecastIncrement));

      return mod;
    },
  }
);
moduleSchema.define({ childModules: [moduleSchema] });

const caseSchema = new schema.Entity<ICase | IImportCase>(
  "cases",
  {
    modules: [moduleSchema],
  },
  {
    idAttribute: "uid",
  }
);
const casesSchema = new schema.Array(caseSchema);

export const schemaLevel: { [key: string]: TVSEntity } = {
  FACT: "fact",
  LINE_ITEM: "line_item",
  MODULE: "module",
  CASE: "case",
};

const schemaAliasMap = {
  fact: factSchema,
  line_item: lineItemSchema,
  module: moduleSchema,
  case: casesSchema,
};

/**
 * Normalizes Valsys API responses. Adds dependencies and precedents to each fact afterwards.
 *
 * Where the relevant parent uids are not available they should be spread into the data object.
 *
 * Eg for a module level normalization:
 * vsNormalize({ ...moduleData, caseUid }, schemaLevel.MODULES);
 *
 * */
type TNormalizableData<F> = F extends IFact
  ? ICase[] | IRawModule | IRawFact
  : IImportCase[] | IImportModule | IImportFact;

type TNormalizedData<F> = F extends IFact
  ? Partial<{
      cases: { [key: string]: ICase };
      lineItems: Record<string, ILineItem>;
      modules: Record<string, IModule>;
      facts: { [key: string]: IFact };
    }>
  : Partial<{
      cases: { [key: string]: TNormalizedImportCase };
      lineItems: Record<string, TNormalizedImportLineItem>;
      modules: Record<string, TNormalizedImportModule>;
      facts: { [key: string]: INormalizedImportFact };
    }>;

export const vsNormalize = <F>(
  data: TNormalizableData<F>,
  level: TVSEntity
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): NormalizedSchema<TNormalizedData<F>, any> => {
  return normalize(data, schemaAliasMap[level]);
};
