/** eslint-disable */
import { createSelector } from "reselect";
import range from "lodash.range";

import { defaultFormatSelector } from "selectors/dataEntryUISelectors";
import { GridChangeType } from "constants/modelConstants";

// UTILS
import { f, periodFormatter } from "utils/formatter";
import { buildCellFormattingObject } from "utils/modelGrid";
import { getTransformMethod, isDataReady } from "utils/data";
import { constantStylesSelector } from "./dataEntryUISelectors";

// MODEL INFO
export const modelInfoSelector = (state) => state.model.modelInfo;
export const modelUidSelector = createSelector([modelInfoSelector], (modelInfo) => modelInfo.id);
export const companyNameSelector = createSelector(
  [modelInfoSelector],
  (modelInfo) => modelInfo.companyName
);
export const tickerSelector = (state) => state.model.modelInfo.ticker;

// METADATA
export const currentCaseSelector = (state) => state.model.currentCase;

export const currentModuleUidSelector = (state) => state.model.currentModuleUid;

// DATA
const caseDataSelector = (state) => state.model.cases;
export const casesSelector = (state) => state.model.cases;
export const modulesSelector = (state) => state.model.modules;
export const lineItemsSelector = (state) => state.model.lineItems;
const factsSelector = (state) => state.model.facts;

export const currentModuleSelector = createSelector(
  [modulesSelector, currentModuleUidSelector],
  (modules, currentModuleUid) => {
    if (!modules || !currentModuleUid) return;
    const currentMod = modules[currentModuleUid];
    return {
      ...currentMod,
      // Optional chaining here b/c DCF has no parent
      parentName: modules[currentMod?.parentUid]?.name,
    };
  }
);

/**
 * Returns all available data for the current case.
 * */
export const currentCaseDataSelector = createSelector(
  [currentCaseSelector, caseDataSelector],
  (cse, data) => {
    return data?.[cse?.uid];
  }
);

/**
 * Returns the current available cases.
 * */
export const availableCasesSelector = createSelector([modelInfoSelector], (info) => info.cases);

/**
 * Inject the name prop supplied to the selector.
 **/
export const getArgs = (_, { ...args }) => {
  return args;
};

/**
 * Selects and tranforms widget data based on the widget data type.
 * */
export const widgetDataSelector = createSelector(
  [currentCaseDataSelector, defaultFormatSelector, constantStylesSelector, getArgs],
  (cseData, defaultFormats, cStyles, { widgetKey }) => {
    if (cseData?.[widgetKey]) {
      const widgetData = { ...cseData?.[widgetKey] };
      if (!isDataReady(widgetData)) return widgetData;
      Object.keys(widgetData).forEach((key) => {
        widgetData[key] = getTransformMethod(key)(widgetData[key], defaultFormats);
      });
      return widgetData;
    }
  }
);

export const dcfModuleUidSelector = createSelector([tickerSelector], () => 1);

/**
 * Selects the implied premiums on initial load. Feeds into the impliedPremiumSelector which
 * will overwrite these figures with evergreen numbers from the data entry tables if present.
 *
 * */
export const initialSharePriceSelector = createSelector([casesSelector], (cses) => {
  if (cses) {
    const sharePrices = {};
    Object.values(cses).forEach((cse) => {
      sharePrices[cse.case] = { cse: cse.case, impliedSharePrice: cse.impliedSharePrice };
    });
    return sharePrices;
  }
});

/**
 * Selects the current type of the model.
 * */
export const modelTypeSelector = createSelector([modelInfoSelector], (modelInfo) => {
  return modelInfo.terminalValuationCalcType;
});

/**
 * Selects the time series metadata, including the periodType, startPeriod etc
 * */
export const timeSeriesMetaDataSelector = createSelector(
  [currentCaseDataSelector],
  (currentCaseData) => {
    return currentCaseData
      ? {
          periodType: currentCaseData.periodType,
          forecastStart: currentCaseData.forecastStart,
          forecastEnd: currentCaseData.forecastEnd,
          forecastIncrement: currentCaseData.forecastIncrement,
          startPeriod: currentCaseData.startPeriod,
          historicalStart: currentCaseData.historicalStart,
        }
      : null;
  }
);

export const caseModulesSelector = createSelector(
  [currentCaseSelector, modulesSelector],
  (currentCase, modules) => {
    if (!currentCase || !modules) return {};
    return Object.fromEntries(
      Object.entries(modules).filter(([, value]) => value.caseUid === currentCase.uid)
    );
  }
);

export const currentAnalysisTypeSelector = createSelector(
  [currentCaseDataSelector],
  (cse) => cse?.currentSensitivityAnalysisType
);

// PERIODS

export const modelPeriodSelector = createSelector([currentCaseDataSelector], (currentCase) => {
  if (!currentCase) return;
  const periodRange = range(
    currentCase.historicalStart,
    currentCase.forecastEnd + 1,
    currentCase.forecastIncrement
  );

  return periodRange.map((period) =>
    periodFormatter(period, currentCase.startPeriod, currentCase.forecastIncrement)
  );
});

export const modulePeriodsFormulaSelector = createSelector(
  [currentModuleSelector],
  (currentModule) => {
    if (!currentModule) return;
    const periods = range(
      currentModule.moduleStart,
      currentModule.moduleEnd + currentModule.forecastIncrement,
      currentModule.forecastIncrement
    );
    return periods.map((period) =>
      periodFormatter(period, null, currentModule.forecastIncrement, true)
    );
  }
);

export const impliedForwardMultiplesSelector = createSelector(
  [currentCaseSelector, defaultFormatSelector],
  (currentCase, defaultFormats) => {
    if (currentCase?.IMPLIED_FORWARD_MULTIPLES) {
      const periods = currentCase.IMPLIED_FORWARD_MULTIPLES.lineItems[0].facts
        .map((fact) => fact.period)
        .sort((a, b) => a - b);
      const multiples = currentCase.IMPLIED_FORWARD_MULTIPLES.lineItems
        .map((lineItem) => ({
          ...lineItem,
          facts: lineItem.facts
            .sort((a, b) => a.period - b.period)
            .map((fact) => {
              const formats = buildCellFormattingObject(
                { ...lineItem.format, ...fact.format },
                defaultFormats
              );
              return {
                period: fact.period,
                value: f({ ...fact, ...formats }),
              };
            }),
        }))
        .sort((liA, liB) => liA.order - liB.order);
      return {
        multiples,
        periods,
      };
    }
  }
);

export const keyMetricsSelector = (state) => state.ui.valuation.keyMetrics;

export const keyMetricDataSelector = createSelector(
  [factsSelector, defaultFormatSelector, currentCaseDataSelector],
  (facts, defaultFormats, caseData) => {
    if (caseData?.keyMetricUids && facts) {
      return Object.entries(caseData.keyMetricUids).map(([name, uid]) => {
        const fact = facts[uid];
        if (!fact) return { name, value: "N/A" };
        return {
          uid,
          name,
          value: f({
            value: fact.value,
            format: { ...defaultFormats, ...fact.format, decimalPlaces: 2 },
          }),
          rawValue: fact.value,
        };
      });
    }
  }
);

/**
 * Selector for the upcoming and preceeding modules
 */
export const nextModuleSelector = createSelector(
  [currentModuleUidSelector, caseModulesSelector],
  (module, modules) => {
    if (module && modules) {
      const moduleList = Object.values(modules);
      const currentIndex = moduleList.findIndex((mod) => mod.uid === module);
      if (!moduleList[currentIndex + 1]) {
        return moduleList[0];
      }
      return moduleList[currentIndex + 1];
    }
  }
);

/**
 * Selector for the previous module
 */
export const previousModuleSelector = createSelector(
  [currentModuleUidSelector, caseModulesSelector],
  (module, modules) => {
    if (module && modules) {
      const moduleList = Object.values(modules);
      const currentIndex = moduleList.findIndex((mod) => mod.uid === module);
      if (!moduleList[currentIndex - 1]) {
        return moduleList[moduleList.length - 1];
      }
      return moduleList[currentIndex - 1];
    }
  }
);

/**
 * Select a module name based on a provided ID.
 * Only works for modules from the currently active case.
 * */
export const moduleNameByIDSelector = createSelector(
  [caseModulesSelector, getArgs],
  (caseModules, { moduleID }) => {
    if (caseModules) return Object.values(caseModules).find((mod) => mod.uid === moduleID).name;
  }
);

const gridChangeOperationsSelector = (state) => state.model.gridChangeOperations;

export const availableGridChangeOpsSelector = createSelector(
  [gridChangeOperationsSelector],
  (gridChangeOps) => {
    /* we probably want to reintroduce these later when fast add/del is solved
    if (gridChangeOps[0] === GridChangeType.ADD_COLUMN) {
      return { [GridChangeType.ADD_COLUMN]: GridChangeType.ADD_COLUMN };
    } else if (gridChangeOps[0] === GridChangeType.DELETE_COLUMN) {
      return { [GridChangeType.DELETE_COLUMN]: GridChangeType.DELETE_COLUMN };
    } else if (gridChangeOps[0] === GridChangeType.ADD_ROW) {
      return { [GridChangeType.ADD_ROW]: GridChangeType.ADD_ROW };
    } else if (gridChangeOps[0] === GridChangeType.DELETE_ROW) {
      return { [GridChangeType.DELETE_ROW]: GridChangeType.DELETE_ROW };
    }*/
    if (gridChangeOps[0]) return {};
    return {
      [GridChangeType.ADD_COLUMN]: GridChangeType.ADD_COLUMN,
      [GridChangeType.DELETE_COLUMN]: GridChangeType.DELETE_COLUMN,
      [GridChangeType.ADD_ROW]: GridChangeType.ADD_ROW,
      [GridChangeType.DELETE_ROW]: GridChangeType.DELETE_ROW,
    };
  }
);
