import Highcharts from "highcharts/highstock";
import { EValFormat, IFormatObject } from "types/format";
import {
  EWidgetType2,
  ChartDataset,
  VSLChart,
  ChartPoint,
  ChartPointLabel,
  Frequency,
} from "types/vsl";
import autoFormatNumber, {
  DEFAULT_DECIMAL_PLACES,
  applyUnit,
  formatValue,
  getAutoUnitDivisor,
} from "./formatter";
import {
  ETransformationType,
  chartDefaults,
  ChartOptions,
  YAxisId,
  ChartDataFrequency,
  ChartPeriodType,
  ESeriesStatistic,
  VSChartToHighchartsSeriesMap,
  MaxChartPrecision,
} from "constants/chartConstants";
import { GraphSeriesColors } from "constants/sharePriceConstants";
import { getValidJSON } from "./api";
import { ChartWidgetConfig } from "types/widget";
import { Colors } from "@blueprintjs/core";
import { Themes } from "constants/uiConstants";
import { isNil, round } from "lodash";
import { toDate } from "date-fns";
import { TSeriesLineOptions } from "types/chart";
import { getMetrics, Metric } from "mathematicalFunctions";

/**
 * Type guards for Highcharts TypeScript errors
 **/

/**
 * Utility to build a scatter chart from a ChartDataset.
 * */
export const buildScatterChart = (dataset: ChartDataset): Highcharts.PointOptionsObject[] => {
  const scatterDataset: Highcharts.PointOptionsObject[] = [];
  // Empty array will allow the chart to render without plotting anything.
  if (!dataset?.data) return scatterDataset;
  for (let i = 0; i < dataset.data.length; i++) {
    const xValue = dataset?.xdata ? +dataset.xdata[0].value : NaN;
    const point = dataset.data[i];
    if (!Number.isNaN(xValue) && !Number.isNaN(+point.value)) {
      scatterDataset.push({
        x: xValue,
        y: +point.value,
      });
    }
  }
  return scatterDataset;
};

/**
 * Get data lookup key. Given a frequency and periodtype, get the appropriate lookup key
 * for a chart point label and value.
 * */
export const getChartLabelKey = (
  frequency: ChartDataFrequency,
  periodType: ChartPeriodType
): keyof ChartPointLabel => {
  if (periodType === ChartPeriodType.Raw || frequency === ChartDataFrequency.Raw) {
    return "semanticLabel";
  }
  if (frequency === ChartDataFrequency.Annual) {
    if (periodType === ChartPeriodType.Fiscal) {
      return "fiscalAnnualLabel";
    } else if (periodType === ChartPeriodType.Calendar) {
      return "calendarAnnualLabel";
    }
  }

  if (frequency === ChartDataFrequency.Quarterly) {
    if (periodType === ChartPeriodType.Fiscal) {
      return "fiscalQuarterlyLabel";
    } else if (periodType === ChartPeriodType.Calendar) {
      return "calendarQuarterlyLabel";
    }
  }
  return "semanticLabel";
};

export const getChartDateKey = (
  frequency: ChartDataFrequency,
  periodType: ChartPeriodType
): keyof ChartPoint => {
  switch (true) {
    case periodType === ChartPeriodType.Raw || frequency === ChartDataFrequency.Raw:
      return "rawDate";

    case frequency === ChartDataFrequency.Annual:
      switch (periodType) {
        case ChartPeriodType.Fiscal:
          return "fiscalAnnualDate";

        case ChartPeriodType.Calendar:
          return "calendarAnnualDate";
      }
      break;
    case frequency === ChartDataFrequency.Quarterly:
      switch (periodType) {
        case ChartPeriodType.Fiscal:
          return "fiscalQuarterlyDate";

        case ChartPeriodType.Calendar:
          return "calendarQuarterlyDate";
      }
  }

  return "rawDate";
};

/**
 * Utility functions used to create charts and their initial configs
 **/
export const parseData = (
  dataset: ChartDataset,
  type: EWidgetType2,
  frequency: ChartDataFrequency,
  periodType: ChartPeriodType
) => {
  const dataArray: Highcharts.PointOptionsObject[] = [];

  if (!dataset.data) return [];

  if (type === EWidgetType2.SCATTER_CHART) {
    // Scatter charts rely on the 'xdata', and require check to drop missing values on both axes.
    return buildScatterChart(dataset);
  }

  /** If the chart has been set to raw, we want to return an x datetime
   * in milliseconds as we are dealing with absolute date values. */
  if (periodType === ChartPeriodType.Raw || frequency === ChartDataFrequency.Raw) {
    dataset.data.forEach((point) => {
      if (point.rawDate && !Number.isNaN(+point.value)) {
        dataArray.push([point.rawDate.getTime(), round(+point.value, MaxChartPrecision)]);
      }
    });
    return dataArray;
  }

  const labelKey = getChartLabelKey(frequency, periodType);

  dataset.data.forEach((point) => {
    if (point.calendarQuarterlyDate && !Number.isNaN(+point.value)) {
      dataArray.push([point[labelKey], +point.value]);
    }
  });

  return dataArray;
};

/*
 * This function gets the labels of the overlapping period of a chart within a specific
 */
export const getOverlappingPeriodLabels = (
  chart: VSLChart,

  periodType: ChartPeriodType | undefined
): [string, string] | null => {
  if (!chart.datasets || !periodType) {
    return null;
  }

  const frequency = getChartFrequency(chart);

  const dateKey = getChartDateKey(frequency, periodType);
  const labelKey = getChartLabelKey(frequency, periodType);
  const overlapExtremeTimes = getOverlappingTime(chart, dateKey);
  if (!overlapExtremeTimes) return null;
  return getLabelsFromTimes(chart, overlapExtremeTimes, dateKey, labelKey);
};

/*
 This gets specific labels from times, defined by the dateKey and labelKey, if they exist within the chart. 
*/
export const getLabelsFromTimes = (
  chart: VSLChart,
  extremeTimes: [Date, Date],
  dateKey: keyof ChartPoint,
  labelKey: keyof ChartPointLabel
): [string, string] | null => {
  let startLabel: string | undefined = undefined;
  let endLabel: string | undefined = undefined;
  const [startTime, endTime] = extremeTimes;
  const startTimeNumber = startTime.getTime();
  const endTimeNumber = endTime.getTime();

  if (!chart.datasets) {
    return null;
  }
  for (let i = 0; i < chart.datasets.length; i++) {
    const dataset = chart.datasets[i];
    if (dataset.data) {
      for (let j = 0; j < dataset.data?.length; j++) {
        const cellString = dataset.data?.[j][dateKey];
        if (cellString) {
          const cellTime: Date = new Date(cellString);
          if (cellTime.getTime() === startTimeNumber) {
            startLabel = dataset.data[j][labelKey];
          }
          if (cellTime.getTime() === endTimeNumber) {
            endLabel = dataset.data[j][labelKey];
          }
          if (startLabel && endLabel) {
            // eagerly return
            return [startLabel, endLabel];
          }
        }
      }
    }
  }
  return null;
};
/*
 * this function extracts the extreme times, within which both datasets have valid data
 * if there aren't any times, null is returned
 */
export const getOverlappingTime = (
  chart: VSLChart,
  labelKey: keyof ChartPoint
): [Date, Date] | null => {
  let endTime: Date = new Date(9999, 1, 1); // an exceedingly future date
  let startTime: Date = new Date(0, 1, 1); // and exceedling historical date

  chart.datasets?.forEach((dataset) => {
    const st = dataset.data?.[0]?.[labelKey]; // the points are ordered, so the first is always the earliest
    const end = dataset.data?.[dataset.data.length - 1]?.[labelKey]; // and the last is always the latest

    if (end === undefined || st === undefined) {
      return null;
    }
    const tempStartTime: Date = new Date(st);
    const tempEndTime: Date = new Date(end);
    if (tempStartTime.getTime() > startTime.getTime()) {
      startTime = tempStartTime;
    }
    if (tempEndTime.getTime() < endTime.getTime()) {
      endTime = tempEndTime;
    }
  });

  if (startTime.getTime() >= endTime.getTime()) {
    return null;
  }
  return [startTime, endTime];
};

/*
 * this function extracts the indeces of a dataset between which there is data that lies within the extreme times
 */
export const getOverlapIndeces = (
  dataset: ChartDataset,
  extremeTimes: [Date, Date],
  labelKey: keyof ChartPoint
): [number, number] | null => {
  if (!dataset.data) return null;

  let startIdx: number = dataset.data.length;
  let endIdx = 0;

  const [startTime, endTime] = extremeTimes;
  const startTimeNumber = startTime.getTime();
  const endTimeNumber = endTime.getTime();

  for (let i = 0; i < dataset.data.length; i++) {
    const cellString = dataset.data[i][labelKey];
    if (cellString) {
      const cellTime: Date = new Date(cellString);

      if (cellTime.getTime() >= startTimeNumber) {
        startIdx = Math.min(i, startIdx);
      }
      if (cellTime.getTime() <= endTimeNumber) {
        endIdx = Math.max(i, endIdx);
      }
    }
  }

  return startIdx <= endIdx ? [startIdx, endIdx] : null;
};

/*
  This is the entry function for when we want only the overlapping data points on the chart.
  It returns an original chart if the initial inputs aren't correct,
  It returns an empty chart if there isn't any overlap
 */
export const getOverlappingDatasetPoints = (
  chart: VSLChart,
  periodType: ChartPeriodType | undefined
): VSLChart => {
  if (!chart.datasets || !periodType) {
    return chart; // Return the original chart if datasets are empty or periodType is not provided.
  }

  // we require the frequency
  const frequency = getChartFrequency(chart);

  const labelKey = getChartDateKey(frequency, periodType);
  const overlapExtremeTimes = getOverlappingTime(chart, labelKey);
  if (!overlapExtremeTimes) return { ...chart, datasets: [] };

  const overlappingChartDatasets: ChartDataset[] = chart.datasets?.map((dataset) => {
    const indeces = getOverlapIndeces(dataset, overlapExtremeTimes, labelKey);
    if (!indeces) {
      return dataset;
    }
    const [startIndex, endIndex] = indeces;
    const overlappingData = dataset.data?.slice(startIndex, endIndex + 1);

    return { ...dataset, data: overlappingData || [] };
  });
  return { ...chart, datasets: overlappingChartDatasets };
};

export const getChartSeriesFromDataset = (
  data: VSLChart | undefined,
  type: EWidgetType2,
  frequency: ChartDataFrequency,
  periodType: ChartPeriodType,
  dataLabelsEnabled: boolean,
  config?: ChartWidgetConfig | null
):
  | (
      | Highcharts.SeriesLineOptions
      | Highcharts.SeriesColumnOptions
      | Highcharts.SeriesPieOptions
      | Highcharts.SeriesScatterOptions
      | TSeriesLineOptions
    )[]
  | undefined => {
  if (!data) return undefined;
  if (!data.datasets) return undefined;

  if (type === EWidgetType2.PIE_CHART) {
    // Parse datasets to create one series with multiple data points
    const pieDataset = data.datasets.map((dataset) => {
      return {
        label: "",
        name: dataset.label, // Pie slice name
        y: dataset.data ? +dataset.data[0].value : 0,
      };
    });
    const pieSeriesOpts: Highcharts.SeriesPieOptions[] = [
      {
        data: pieDataset,
        name: "PIE SERIES NAME",
        type: "pie",
      },
    ];

    return pieSeriesOpts;
  }

  const series = data.datasets.map((dataset, i) => {
    const seriesOptions = config?.seriesOptions?.[dataset.label];
    const yAxisId = seriesOptions?.axis;
    const color = seriesOptions?.color || GraphSeriesColors[i];
    const lineWidth = seriesOptions?.lineWidth;
    const dashStyle = seriesOptions?.dashStyle;
    const endLabel = seriesOptions?.endLabel;
    const isPercentage = dataset.specifics.isPercentage;
    return {
      color,
      dashStyle,
      data: parseData(dataset, type, frequency, periodType),
      dataLabels: { enabled: dataLabelsEnabled },
      isPercentage,
      lineWidth: lineWidth || 2,
      marker: {
        lineColor: color, // Added for scatter charts
      },
      name: dataset.label,
      // Unfortunately seem to require a cast here.
      step: dataset.stepped ? ("center" as Highcharts.OptionsStepValue) : undefined,
      stacking: undefined,
      showInNavigator: true,
      type: chartDefaults[type].type as unknown as "line",
      yAxis: yAxisId,
      endLabel: endLabel || false,
    };
  });
  return series;
};

interface SeriesFormatObject {
  xFormat: Partial<IFormatObject> | null;
  yFormat: Partial<IFormatObject> | null;
}

export const getChartSeriesFormats = (
  series: ChartDataset[],
  configFormat: Partial<IFormatObject> | null
): {
  [key: string]: SeriesFormatObject;
} => {
  return series.reduce((acc, curr) => {
    const xDataFmt = getValidJSON<Partial<IFormatObject>>(
      curr?.xdata?.find((dt) => dt?.format)?.format ?? ""
    );
    const yFmt = getValidJSON<IFormatObject>(curr?.data?.find((dt) => dt?.format)?.format ?? "");
    acc[curr.label] = { xFormat: xDataFmt, yFormat: { ...yFmt, ...configFormat } };
    return acc;
  }, {} as { [key: string]: { xFormat: Partial<IFormatObject> | null; yFormat: Partial<IFormatObject> } });
};

// Get X/Y value pairs from datset
function getXYPoints(chartSeriesOptions: Highcharts.SeriesOptionsType[]) {
  const points: { x: number; y: number }[] = [];

  chartSeriesOptions.forEach((chartSeries) => {
    const series = chartSeries as Highcharts.SeriesScatterOptions;
    if (!series?.data) return;
    series.data.forEach((point) => {
      if (point) {
        // Some TS workarounds, data structure is set in chartUtils.ts
        points.push(point as { x: number; y: number });
      }
    });
  });

  return points;
}

// Adjust axis extremes automatically for various scatter datasets
export function calculateScatterExtremes(chartSeriesOptions: Highcharts.SeriesOptionsType[]) {
  const points = getXYPoints(chartSeriesOptions);

  // Get extremes
  const extremes = points.reduce(
    (acc, obj) => {
      acc.maxX = Math.max(acc.maxX, Math.abs(obj.x));
      acc.maxY = Math.max(acc.maxY, Math.abs(obj.y));

      return acc;
    },
    {
      maxY: Math.abs(points?.[0]?.y) || 0,
      maxX: Math.abs(points?.[0]?.x) || 0,
    }
  );
  return extremes;
}

export function getSeriesStatistics(
  series: (Highcharts.SeriesLineOptions | Highcharts.SeriesColumnOptions)[]
) {
  const yValues: number[] = [];

  series.forEach((series) => {
    if (!series?.data) return;
    series.data.forEach((point) => {
      if (point) {
        const tempPoint = point as [string, number];
        if (Highcharts.isNumber(tempPoint[1])) {
          yValues.push(tempPoint[1]);
        }
      }
    });
  });
  const mean = yValues.reduce((sum, value) => sum + value, 0) / yValues.length;
  const sortedYValues = yValues.slice().sort((a, b) => a - b);
  const middleIndex = Math.floor(sortedYValues.length / 2);

  const median =
    sortedYValues.length % 2 === 0
      ? (sortedYValues[middleIndex - 1] + sortedYValues[middleIndex]) / 2
      : sortedYValues[middleIndex];

  return { mean, median };
}

const updatedCalculateTransform = (
  dateTypeDataset: ChartDataset<Date>,
  isYoYTransform: boolean
): ChartDataset<Date> => {
  const pointsYoY = dateTypeDataset?.data?.map((point) => {
    let prevVal: number | null = null;

    if (isYoYTransform && point.yoYIdx !== -1) {
      prevVal = Number(dateTypeDataset?.data?.[point.yoYIdx]?.value);
    } else if (!isYoYTransform && point.seqIdx !== -1) {
      prevVal = Number(dateTypeDataset?.data?.[point.seqIdx]?.value);
    }

    if (Number.isNaN(prevVal) || prevVal === null) {
      return { ...point, value: "-" };
    }

    const currentVal = +point.value;

    if (!currentVal) {
      return { ...point, value: "-" };
    }
    let percentageChange: number | string = "-";

    if (prevVal && currentVal) {
      percentageChange = (currentVal / prevVal - 1) * 100;
    }
    return { ...point, value: percentageChange };
  });
  if (!pointsYoY) return dateTypeDataset;

  return { ...dateTypeDataset, data: pointsYoY };
};

export function handleSEQandYOYTransformations(
  dateTypeData: VSLChart<Date> | undefined,
  config: ChartWidgetConfig,
  yAxisId: YAxisId
): VSLChart<Date> | undefined {
  if (!dateTypeData?.datasets) return dateTypeData;

  const x = dateTypeData?.datasets?.map((dataset) => {
    const seriesOptions = config.seriesOptions?.[dataset.label];
    const datasetAxisId = seriesOptions?.axis ?? YAxisId.RIGHT;

    if (yAxisId !== datasetAxisId) {
      return dataset;
    }
    switch (config.yAxis?.[yAxisId]?.transformation) {
      case ETransformationType.YOY:
        return updatedCalculateTransform(dataset, true);
      case ETransformationType.SEQ:
        return updatedCalculateTransform(dataset, false);
      default:
        return dataset;
    }
  });
  return { ...dateTypeData, datasets: x };
}

export function handleStackTransformation(
  chartSeriesOptions: Highcharts.SeriesLineOptions[],
  transformation: ETransformationType,
  dataLabelsEnabled: boolean,
  yAxisId: YAxisId
): (Highcharts.SeriesLineOptions | Highcharts.SeriesColumnOptions)[] {
  return chartSeriesOptions.map((series) => {
    const datasetAxisId = series?.yAxis ?? YAxisId.RIGHT;
    if (yAxisId !== datasetAxisId) return series;

    switch (transformation) {
      case ETransformationType.STACK:
        return {
          ...series,
          dataLabels: { enabled: dataLabelsEnabled },
          type: "column",
          stacking: "normal",
        } as Highcharts.SeriesColumnOptions;
      case ETransformationType.STACK_PERCENT:
        return {
          ...series,
          dataLabels: { enabled: dataLabelsEnabled },
          type: "column",
          stacking: "percent",
        } as Highcharts.SeriesColumnOptions;
      default:
        return series;
    }
  });
}

export function labelFormatter(
  val: number | string | undefined,
  format: Partial<IFormatObject>,
  hideSuffix = false
): string {
  let value: number | string | undefined = val;
  if (value === undefined) return "";
  const unitObj = getAutoUnitDivisor(value);

  const { valFormat, unit } = format;
  format.hasSuffix =
    !hideSuffix && (valFormat === EValFormat.NUMERIC || valFormat === EValFormat.CURRENCY);

  if (valFormat && unit) {
    value = formatValue(value, { ...format, unit, valFormat });
  } else if (valFormat && !unit) {
    // Apply auto unit and then pass to formatValue
    if (unitObj && valFormat !== EValFormat.PLAINTEXT) {
      value = Number(value) / unitObj.threshold;
    }
    value = formatValue(value, { ...format, valFormat });
    if (
      !hideSuffix &&
      unitObj &&
      (valFormat === EValFormat.CURRENCY || valFormat === EValFormat.NUMERIC)
    ) {
      value = value + unitObj.suffix;
    }
  } else if (!valFormat && unit) {
    // Apply unit
    value = applyUnit(value, unit).toFixed(format.decimalPlaces || 2);
  } else if (!valFormat && !unit) {
    value = autoFormatNumber(val, format.decimalPlaces, true, !!(!hideSuffix || format.hasSuffix));
  }
  return value ? value.toString() : "";
}

export function tooltipPointFormatter(
  point: Highcharts.Point,
  format: Partial<IFormatObject>,
  type?: EWidgetType2,
  percentageTransformation = false
) {
  const { valFormat, unit, decimalPlaces } = format;

  let xValue: number | string | undefined = point.x;
  let yValue: number | string | undefined = point.y;

  const name = type === EWidgetType2.PIE_CHART ? point.name : point.series.name;
  format.hasSuffix = valFormat === EValFormat.NUMERIC || valFormat === EValFormat.CURRENCY;

  if (yValue === undefined) {
    return `${point.series.name}: <strong>Missing Value</strong>`;
  }
  // Percentage transformation tooltip format
  if (percentageTransformation) {
    if (point.total) {
      yValue = (yValue / point.total) * 100;
    }
    return `${name} ${`<strong>${yValue.toFixed(1)}</strong>%`} `;
  }

  // Performance wise it is better to handle Scatter as a separate if
  if (type === EWidgetType2.SCATTER_CHART) {
    const unitObjX = getAutoUnitDivisor(xValue);

    if (valFormat && unit) {
      xValue = formatValue(xValue, format);
    } else if (valFormat && !unit) {
      // Apply auto unit and then pass to formatValue
      if (unitObjX && valFormat !== EValFormat.PLAINTEXT) {
        xValue = xValue / unitObjX.threshold;
      }
      xValue = formatValue(xValue, format);
      if (unitObjX && (valFormat === EValFormat.CURRENCY || valFormat === EValFormat.NUMERIC)) {
        xValue = xValue + unitObjX.suffix;
      }
    } else if (!valFormat && unit) {
      // Apply unit
      xValue = applyUnit(xValue, unit).toFixed(decimalPlaces ?? 2);
    } else if (!valFormat && !unit) {
      xValue = autoFormatNumber(point.y);
    }
  }
  const unitObj = getAutoUnitDivisor(yValue);

  if (valFormat && unit) {
    yValue = formatValue(yValue, format);
  } else if (valFormat && !unit) {
    // Apply auto unit and then pass to formatValue
    if (unitObj && valFormat !== EValFormat.PLAINTEXT) {
      yValue = yValue / unitObj.threshold;
    }
    yValue = formatValue(yValue, format);
    if (unitObj && (valFormat === EValFormat.CURRENCY || valFormat === EValFormat.NUMERIC)) {
      yValue = yValue + unitObj.suffix;
    }
  } else if (!valFormat && unit) {
    // Apply unit
    yValue = applyUnit(yValue, unit).toFixed(decimalPlaces ?? 2);
  } else if (!valFormat && !unit) {
    yValue = autoFormatNumber(point.y);
  }

  const values =
    type === EWidgetType2.SCATTER_CHART
      ? `<strong>${xValue}</strong>, <strong>${yValue}</strong>`
      : `<strong>${yValue}</strong>`;

  return `${name}: ${values}`;
}

/**
 * Getter for an IFormatObject from a dataset.
 * */
export const getDatasetFormat = (datasets: ChartDataset<Date | string>[]) => {
  let format = "";
  for (let i = 0; i < datasets.length; i++) {
    if (format) {
      break;
    }
    const dataLength = datasets[i]?.data?.length || 0;
    for (let j = 0; j < dataLength || 0; j++) {
      const fmt = datasets[i].data?.[j]?.format;
      if (fmt) {
        format = fmt;
        break;
      }
    }
  }
  return getValidJSON<IFormatObject>(format);
};

/**
 * Returns as much of a format object as possible, given a dataset and a
 * chart config option.
 * */
export const getConfigFormat = (
  data: VSLChart<Date>,
  axisId: YAxisId | null,
  config?: ChartWidgetConfig | null
): Partial<IFormatObject> => {
  const axisFormat = !isNil(axisId) ? config?.yAxis?.[axisId] : config?.xAxisFormat;
  const axis = axisId || YAxisId.RIGHT;
  const decimalPlaces = axisFormat?.decimalPlaces;
  const unit = axisFormat?.unit;
  const valFormat = axisFormat?.valFormat;

  const seriesOptions = config?.seriesOptions ?? {};
  const axisDatasets = data?.datasets?.filter((dataset) => {
    const seriesAxis = seriesOptions?.[dataset.label]?.axis ?? YAxisId.RIGHT;
    return seriesAxis === axis;
  });

  const datasetFormat = axisDatasets ? getDatasetFormat(axisDatasets) : {};

  return {
    ...datasetFormat,
    decimalPlaces: DEFAULT_DECIMAL_PLACES, // Disconnect the chart from the decimal places prop supplied by the model data.
    ...(unit && { unit }),
    ...(decimalPlaces !== undefined && { decimalPlaces }),
    ...(valFormat && { valFormat }),
  };
};

/**
 * Given a chart default configuration, get the current option state for a given option.
 *
 * This is for chart level options. See ... for per series values.
 * */
export const getCurrentChartConfig = (
  defaults: ChartOptions,
  type:
    | EWidgetType2.BAR_CHART
    | EWidgetType2.LINE_CHART
    | EWidgetType2.PIE_CHART
    | EWidgetType2.SCATTER_CHART,
  chartRendererConfig?: ChartWidgetConfig
) => {
  const correlationMetricsEnabled =
    typeof chartRendererConfig?.correlationMetrics !== "boolean"
      ? false
      : chartRendererConfig?.correlationMetrics;

  const delineateForecasts =
    typeof chartRendererConfig?.delineateForecasts !== "boolean"
      ? false
      : chartRendererConfig?.delineateForecasts;

  const rangeSelectorEnabled =
    typeof chartRendererConfig?.rangeSelector?.enabled !== "boolean"
      ? defaults?.rangeSelector?.enabled
      : chartRendererConfig?.rangeSelector?.enabled;

  const navigatorEnabled =
    typeof chartRendererConfig?.navigator?.enabled !== "boolean"
      ? defaults?.navigator?.enabled
      : chartRendererConfig?.navigator?.enabled;

  const legendEnabled =
    typeof chartRendererConfig?.legend?.enabled !== "boolean"
      ? defaults?.legend?.enabled
      : chartRendererConfig?.legend?.enabled;

  const tooltipEnabled =
    typeof chartRendererConfig?.tooltip?.enabled !== "boolean"
      ? defaults?.tooltip?.enabled
      : chartRendererConfig.tooltip.enabled;

  const transformationEnabled = !!chartRendererConfig?.enableTransformationOptions;

  const leftYAxisOptions = Highcharts.merge(
    defaults?.yAxis?.[YAxisId.LEFT],
    chartRendererConfig?.yAxis?.[YAxisId.LEFT]
  );

  const confDLEnabled = (
    chartRendererConfig?.plotOptions?.[VSChartToHighchartsSeriesMap[type]]
      ?.dataLabels as Highcharts.PlotSeriesDataLabelsOptions
  )?.enabled;

  const dataLabelsEnabled =
    typeof confDLEnabled !== "boolean"
      ? (
          defaults?.plotOptions?.[VSChartToHighchartsSeriesMap[type]]
            ?.dataLabels as Highcharts.PlotSeriesDataLabelsOptions
        )?.enabled
      : confDLEnabled;

  const rightYAxisOptions = Highcharts.merge(
    defaults?.yAxis?.[YAxisId.RIGHT],
    chartRendererConfig?.yAxis?.[YAxisId.RIGHT]
  );

  const overlapOnlyEnabled = true;

  return {
    correlationMetricsEnabled,
    dataLabelsEnabled,
    delineateForecasts,
    leftYAxisOptions,
    legendEnabled,
    navigatorEnabled,
    overlapOnlyEnabled,
    rangeSelectorEnabled,
    rightYAxisOptions,
    tooltipEnabled,
    transformationEnabled,
  };
};

/**
 * this function returns the chart's periodType, limited by the available options.
 */

export const getChartPeriodType = (
  configPeriodType: ChartPeriodType | undefined,
  periodTypeOptions: ChartPeriodType[],
  time?: boolean
) => {
  let periodType = configPeriodType ?? ChartPeriodType.Fiscal;
  if (!periodTypeOptions.includes(periodType)) {
    periodType = ChartPeriodType.Raw;
  }
  if (time) {
    periodType = ChartPeriodType.Raw;
  }

  return periodType;
};

/**
 *  This function returns all of the periodType options in which the data can be displayed.
 */
export const getChartPeriodTypeOptions = (data: VSLChart): ChartPeriodType[] => {
  // If opts.time is true then the frequency is Raw
  if (data?.opts?.time) {
    return [ChartPeriodType.Raw];
  }
  if (data.datasets) {
    // if any dataset is not fiscal quarterly or fiscal annual or calendar quarterly, then the period_type is Raw
    if (
      data?.datasets?.some((dataset) => {
        const { frequency } = dataset.specifics;
        return frequency !== Frequency.Q && frequency !== Frequency.A && frequency !== Frequency.CQ;
      })
    ) {
      return [ChartPeriodType.Raw];
    }

    // if any dataset is calendar quarterly, then the period type is either calendar or raw
    if (
      data?.datasets?.some((dataset) => {
        const { frequency } = dataset.specifics;
        return frequency === Frequency.CQ;
      })
    ) {
      return [ChartPeriodType.Calendar, ChartPeriodType.Raw];
    }
  }
  // otherwise all options are available
  return Object.values(ChartPeriodType);
};

/**
 * this gets the proposed frequency of the x-axis ticks. this will be deprecated once we move to continuous axes
 */
export const getChartFrequency = (data: VSLChart): ChartDataFrequency => {
  // If opts.time is true then the frequency is Raw
  if (data?.opts?.time) {
    return ChartDataFrequency.Raw;
  }
  // Otherwise, if every dataset is annual, return Annual
  else if (data.datasets) {
    if (data.datasets.every((dataset) => dataset.specifics.frequency === Frequency.A)) {
      return ChartDataFrequency.Annual;
    }
  }
  // Otherwise return Quarterly
  return ChartDataFrequency.Quarterly;
};

export function getStatisticsPlotLines(
  config: ChartWidgetConfig | null | undefined,
  chartSeriesOptions: Highcharts.SeriesOptionsType[],
  rightAxisConfigFormat: Partial<IFormatObject>,
  theme: string
) {
  const statisticPlotLines: Highcharts.YAxisPlotLinesOptions[] = [];
  for (const key of Object.keys(config?.seriesOptions || {})) {
    if (config?.seriesOptions?.[key]) {
      // Generate plotLines per series
      const stType = config.seriesOptions[key].statistic;
      if (
        (stType === ESeriesStatistic.MEAN || stType === ESeriesStatistic.MEDIAN) &&
        config.seriesOptions[key].axis !== YAxisId.LEFT
      ) {
        const hcSeries: Highcharts.SeriesOptionsType | undefined = chartSeriesOptions.filter(
          (s) => s.name === key
        )[0];

        if (!hcSeries || (!isLineSeries(hcSeries) && !isColumnSeries(hcSeries))) continue;

        const seriesStatistics = getSeriesStatistics([hcSeries]);
        const rawVal =
          stType === ESeriesStatistic.MEAN ? seriesStatistics.mean : seriesStatistics.median;
        const formattedVal = labelFormatter(rawVal, rightAxisConfigFormat, true);
        let color = Colors.RED1;
        if ("color" in hcSeries && hcSeries?.color) {
          color = hcSeries.color.toString();
        }
        config.seriesOptions[key].statisticPlotLine = {
          id: key,
          color: color,
          dashStyle: "Dash",
          label: {
            align: "right",
            text: `${stType}: ${formattedVal}`,
            style: {
              color: theme === Themes.LIGHT ? Colors.BLACK : Colors.WHITE,
              fontWeight: "bold",
            },
          },
          value: rawVal,
          zIndex: 3,
        };
      } else {
        config.seriesOptions[key].statisticPlotLine = undefined;
      }
      // Add plotLines to statisticPlotLines array
      const plotLine = config.seriesOptions[key].statisticPlotLine;
      if (plotLine) {
        statisticPlotLines.push(plotLine);
      }
    }
  }
  return statisticPlotLines;
}
export const parseDatasetDates = (dataset: ChartDataset<string>): ChartDataset<Date> => {
  if (dataset.data) {
    return {
      ...dataset,
      data: dataset.data.map((point) => ({
        ...point,
        rawDate: new Date(point.rawDate),
        calendarAnnualDate: new Date(point.calendarAnnualDate),
        fiscalAnnualDate: new Date(point.fiscalAnnualDate),
        calendarQuarterlyDate: new Date(point.calendarQuarterlyDate),
        fiscalQuarterlyDate: new Date(point.fiscalQuarterlyDate),
      })),
    };
  }
  // Cast here as we know that if there is no data present on the set,
  // there are no points with dates to transform.
  return dataset as unknown as ChartDataset<Date>;
};

export const formatDateTimeXTicks = function formatDateTimeXTicks(
  that: { value: string | number },
  frequency: ChartDataFrequency,
  periodType: ChartPeriodType
) {
  const date = toDate(Number(that.value));
  const prefix = periodType === ChartPeriodType.Fiscal ? "FY" : "CY";
  if (frequency === ChartDataFrequency.Annual) {
    return `${prefix}${date.getFullYear()}`;
  } else if (frequency === ChartDataFrequency.Quarterly) {
    return `${prefix}${date.getFullYear()}`;
  }
  return that.value.toString();
};

/**
 * Utility to get an x axis category.
 * */
export const getXAxisType = (
  type: EWidgetType2 | undefined,
  periodType: ChartPeriodType
): Highcharts.AxisTypeValue => {
  if (type === EWidgetType2.SCATTER_CHART) {
    return "linear";
  }
  if (periodType === ChartPeriodType.Raw) {
    return "datetime";
  }
  return "category";
};

export const getChartPointLabelKey = (
  frequency: ChartDataFrequency,
  periodType: ChartPeriodType
) => {
  if (periodType === ChartPeriodType.Raw) {
    return "semanticLabel";
  }
  if (frequency === ChartDataFrequency.Annual) {
    if (periodType === ChartPeriodType.Fiscal) return "fiscalAnnualLabel";
    if (periodType === ChartPeriodType.Calendar) return "calendarAnnualLabel";
  }
  if (frequency === ChartDataFrequency.Quarterly) {
    if (periodType === ChartPeriodType.Fiscal) return "fiscalQuarterlyLabel";
    if (periodType === ChartPeriodType.Calendar) return "calendarQuarterlyLabel";
  }
  return "semanticLabel";
};

/**
 * For a category type x axis with multiple series we need to provide
 * a 'categories' prop to the x axis.
 * */
export const getXAxisCategories = (
  chartData: VSLChart,
  axisType: Highcharts.AxisTypeValue,
  frequency: ChartDataFrequency,
  periodType: ChartPeriodType
) => {
  if (axisType !== "category" || periodType === ChartPeriodType.Raw) return undefined;
  if (frequency === ChartDataFrequency.Annual) {
    if (periodType === ChartPeriodType.Fiscal) {
      return chartData.fiscalAnnualLabels ?? undefined;
    } else if (periodType === ChartPeriodType.Calendar) {
      return chartData.calendarAnnualLabels ?? undefined;
    }
  }
  if (frequency === ChartDataFrequency.Quarterly) {
    if (periodType === ChartPeriodType.Fiscal) {
      return chartData.fiscalQuarterlyLabels ?? undefined;
    } else if (periodType === ChartPeriodType.Calendar) {
      return chartData.calendarQuarterlyLabels ?? undefined;
    }
  }
  return undefined;
};

export const isLineSeries = (
  series: Highcharts.SeriesOptionsType
): series is Highcharts.SeriesLineOptions => {
  return series.type === "line";
};

export const isColumnSeries = (
  series: Highcharts.SeriesOptionsType
): series is Highcharts.SeriesColumnOptions => {
  return series.type === "column";
};

/*
 * we want to run metrics for a specific frequency and cut off point
 */

export function calculateDataMetrics(
  series: TSeriesLineOptions[],
  axisType: Highcharts.AxisTypeValue
): Metric[] | undefined {
  if (series?.length !== 2) {
    return;
  }

  // this only works for categorical charts- with raw charts we don't support metrics
  if (axisType !== "category") return;
  const firstSeries = series[0];
  const secondSeries = series[1];

  const metricSeries: number[][] = [[], []];

  // this is an issue for repeated points.
  firstSeries.data?.forEach((firstPoint) => {
    secondSeries.data?.forEach((secondPoint) => {
      const tempfirstPoint = firstPoint as [string, number];
      if (!tempfirstPoint) return;
      const firstNum = +tempfirstPoint[1];
      if (isNaN(firstNum)) {
        return;
      }
      const tempSecondPoint = secondPoint as [string, number];
      if (!tempSecondPoint) return;
      const secondNum = +tempSecondPoint[1];
      if (isNaN(secondNum)) {
        return;
      }

      if (tempSecondPoint[0] === tempfirstPoint[0]) {
        metricSeries[0].push(firstNum);
        metricSeries[1].push(secondNum);
      }
    });
  });

  // need to check if we are looking at % points on the y axis. if we are, then we need to multiple
  // rmse, min diff and max diff by 100 (and return as a %), and multiple mse by 10000, (and return as a %)

  const overwritePercentage = series.every((s) => s.isPercentage);

  return getMetrics(metricSeries, overwritePercentage);
}

export function getYAxisIds(chartData?: VSLChart<Date>, config?: ChartWidgetConfig): YAxisId[] {
  if (!config?.seriesOptions || !chartData?.datasets) return [YAxisId.RIGHT];

  if (
    // We know that every dataset has an explicit config setting it to the left axis.
    // Unfortunately 'label' is the only semi-stable key we have.
    chartData.datasets.every(
      (dataset) => config.seriesOptions?.[dataset.label]?.axis === YAxisId.LEFT
    )
  ) {
    return [YAxisId.LEFT];
  }

  if (
    chartData.datasets.some(
      (dataset) => config.seriesOptions?.[dataset.label]?.axis === YAxisId.LEFT
    )
  ) {
    return [YAxisId.LEFT, YAxisId.RIGHT];
  }

  return [YAxisId.RIGHT];
}

export function isYAxisPercentage(
  axisID: YAxisId,
  config?: ChartWidgetConfig
): boolean | undefined {
  const x = config?.yAxis?.[axisID]?.transformation;
  if (!x) return;
  return [
    ETransformationType.STACK_PERCENT,
    ETransformationType.SEQ,
    ETransformationType.YOY,
  ].includes(x);
}
