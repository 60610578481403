import * as consts from "constants/sharePriceConstants";

export function fetchHistoricalSharePriceData(ticker, range) {
  return {
    type: consts.FETCH_HISTORICAL_SHARE_PRICE_DATA,
    payload: {
      ticker,
      range,
    },
  };
}

export function fetchHistoricalSharePriceDataSuccess(data, range, ticker) {
  return {
    type: consts.FETCH_HISTORICAL_SHARE_PRICE_DATA_SUCCESS,
    payload: {
      data,
      range,
      ticker,
    },
  };
}

export function fetchHistoricalSharePriceDataFailure(ticker) {
  return {
    type: consts.FETCH_HISTORICAL_SHARE_PRICE_DATA_FAILURE,
    payload: {
      ticker,
    },
  };
}

/**
 * Clear any stored share price data on Valuation unmount
 * */
export function flushSharePriceReducer() {
  return {
    type: consts.FLUSH_SHARE_PRICE_REDUCER,
  };
}

/**
 * Update the currently active preview chart ticker
 * */
export function updatePreviewTicker(ticker) {
  return {
    type: consts.UPDATE_PREVIEW_CHART_TICKER,
    payload: {
      ticker,
    },
  };
}

export function updateHistoricalPriceChartRange(range) {
  return {
    type: consts.UPDATE_HISTORICAL_PRICE_CHART_RANGE,
    payload: {
      range,
    },
  };
}

/**
 * Action to determine which implied share price lines are being displayed on the historical
 * price chart.
 *
 * @param cse - The case item to set as active.
 * */
export function updateActiveSharePriceLines(cse) {
  return {
    type: consts.UPDATE_ACTIVE_SHARE_PRICE_LINES,
    payload: {
      cse,
    },
  };
}
