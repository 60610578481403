import React from "react";
import "styles/sensitivity-analysis.scss";
import VSHTMLTable from "components/dashboard/VSHTMLTable";
import { useSelector } from "react-redux";
import { H6 } from "@blueprintjs/core";
import range from "lodash.range";
import { buildScenarios, cppTableWidths } from "utils/cpp";
import { lineItemsSelector, modelInfoSelector } from "selectors/modelSelectors";
import { factsSelector } from "selectors/dataEntrySelectors";
import { tagLineItemsMapSelector } from "selectors/cpp_selectors";

const fullScenariosTags = [
  "Revenue",
  "EBIT ex SBC",
  "EBIT incl SBC",
  "EBITDA ex SBC",
  "EBITDA incl SBC",
  "FCF",
  "EPS - Diluted ex SBC",
  "EPS - Diluted incl SBC",
  "BVPS",
  "Dividends Per Share",
  "Net Cash",
  "Diluted Shares",
  "CapEx",
];
const fullScenariosTable = {
  name: "fullScenarios",
  header: true,
  tags: fullScenariosTags,
  altTagsIdxs: [5, 8, 9, 10, 11, 12],
};
const fullScenariosTables = [fullScenariosTable];

const CPP_FullScenarios = () => {
  const tagLineItemsMap = useSelector(tagLineItemsMapSelector);
  const lineItems = useSelector(lineItemsSelector);
  const facts = useSelector(factsSelector);
  const modelInfo = useSelector(modelInfoSelector);
  const startPeriod = modelInfo?.startPeriod;
  const lfyHeader: number[] = range(startPeriod, startPeriod + 6, 1);

  const fullScenariosData = buildScenarios(
    fullScenariosTables,
    lfyHeader,
    tagLineItemsMap,
    lineItems,
    facts,
    [fullScenariosTags] // rowHeader names
  );

  return (
    <div>
      <H6>Base</H6>
      <VSHTMLTable data={fullScenariosData.base.fullScenarios} widths={cppTableWidths} />
      <br />
      <br />
      <H6>Upside</H6>
      <VSHTMLTable data={fullScenariosData.upside.fullScenarios} widths={cppTableWidths} />
      <br />
      <br />
      <H6>Downside</H6>
      <VSHTMLTable data={fullScenariosData.downside.fullScenarios} widths={cppTableWidths} />
      <br />
      <br />
      <H6>Consensus</H6>
      <VSHTMLTable data={fullScenariosData.consensus.fullScenarios} widths={cppTableWidths} />
    </div>
  );
};

export default CPP_FullScenarios;
