import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Button, Callout, Card, H5, Intent, Tooltip } from "@blueprintjs/core";

import useUser from "hooks/useUser";
import { useApiCallback } from "hooks/useApiCallback";
import TermsOfUse from "components/user/TermsOfUse";
import { Flex, PaddedContent } from "components/utility/StyledComponents";
import SettingsView from "components/user/SettingsView";
import { UPDATE_TERMS_AND_CONDITIONS_STATUS_ROUTE } from "constants/apiConstants";

const StyledCard = styled(Card)`
  max-height: calc(100% - 120px);
  min-width: 440px;
  overflow-y: scroll;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const TOUWrapper = styled(PaddedContent)`
  height: calc(100% - 100px);
`;

const TermsOfUseSettings = () => {
  const { data, user, mutate } = useUser();
  const { callback, response } = useApiCallback();
  const updateTermsAndConditionsStatus = (status) => {
    callback(UPDATE_TERMS_AND_CONDITIONS_STATUS_ROUTE, { status });
    mutate({ ...data, data: { ...user, userHasAcceptedTerms: status } }, false);
  };
  useEffect(() => {
    if (response && response.status !== "success") {
      mutate();
    }
  }, [mutate, response]);
  return (
    <SettingsView
      title="Terms of Use"
      helperText="Review, accept or decline the Valsys platform terms and conditions."
    >
      <Callout icon="warning-sign" intent={Intent.WARNING}>
        Declining the terms of use will revoke your access to the platform. The terms can be
        reaccepted from within the application at a later date.
      </Callout>
      <TOUWrapper padding="15px 12rem">
        <StyledCard>
          <H5>Terms of Use</H5>
          <TermsOfUse />
        </StyledCard>
        <PaddedContent padding="15px 0">
          <Flex justifyContent="flex-end" flexDirection="row" fullWidth>
            <Tooltip content="Declining the Terms of Use will revoke your access to the platform.">
              <Button
                disabled={!user?.userHasAcceptedTerms}
                intent={Intent.WARNING}
                onClick={() => updateTermsAndConditionsStatus(false)}
                text="Decline"
              />
            </Tooltip>
            <StyledButton
              disabled={user?.userHasAcceptedTerms}
              intent={Intent.PRIMARY}
              onClick={() => updateTermsAndConditionsStatus(true)}
              text="Accept"
            />
          </Flex>
        </PaddedContent>
      </TOUWrapper>
    </SettingsView>
  );
};

export default TermsOfUseSettings;
