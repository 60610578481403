import React from "react";

import { TTheme } from "types";
import { Colors } from "@blueprintjs/core";
import { Themes } from "constants/uiConstants";

interface IValsysLogo {
  initials?: boolean;
  size?: { width: string; height: string };
  theme: TTheme | null;
}

const ValsysLogo: React.FC<IValsysLogo> = ({ initials, size, theme }) => {
  if (initials) {
    return (
      <svg
        width={size ? size.width : "20px"}
        height={size ? size.height : "10px"}
        viewBox="0 0 64 64"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Group</title>
        <g
          transform="matrix(-1 0 0 1 31.81 32.14)"
          id="Vlogo"
          fill={!theme || theme === Themes.DARK ? Colors.WHITE : Colors.DARK_GRAY3}
        >
          <path
            transform=" translate(-31.5, -23.96)"
            d="M 51.98739 0 L 31.499999999999996 31.52316 L 20.814609999999995 15.09551 C 35.91923 19.31417 41.59464 20.49017 37.840849999999996 18.62352 C 33.967639999999996 16.69748 29.160859999999996 14.792219999999999 23.304149999999996 10.76427 C 17.888129999999997 7.120329999999999 14.064819999999996 3.53224 11.834229999999996 0 L 0.000009999999996068709 0 L 31.500009999999996 47.91667 L 63.000009999999996 0 z"
          />
        </g>
      </svg>
    );
  }
  return (
    <svg
      width={size ? size.width : "112px"}
      height={size ? size.height : "19px"}
      viewBox="0 0 116 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Valsys Logo</title>
      <g id="Symbols" stroke="none" strokeWidth="1">
        <g
          id="Navigation/nav-bar"
          transform="translate(-36.000000, -19.000000)"
          fill={!theme || theme === Themes.DARK ? Colors.WHITE : Colors.DARK_GRAY3}
        >
          <g id="Group-8">
            <g transform="translate(36.000000, 19.000000)">
              <path
                d="M39.3291289,8 L32.5,17.8681193 L28.9382019,12.7255518 C33.9730758,14.0461749 35.86488,14.4143146 34.6136143,13.8299711 C33.322543,13.2270382 31.7202857,12.6306077 29.7680464,11.3696851 C27.9627061,10.2289723 26.6882705,9.1057439 25.9447396,8 L22,8 L32.5,23 L43,8 L39.3291289,8 Z"
                id="Path-2-Copy-2"
                transform="translate(32.500000, 15.500000) scale(1, -1) translate(-32.500000, -15.500000) "
              />
              <polygon
                id="Path-2-Copy-4"
                points="26.2156751 0 15.5 15.9766252 4.84012259 0 0 0 15.5 23 31 1.00356261e-17"
              />
              <path
                d="M45,8 L45,23 L49.5703472,23 C56.5515893,23 60.3604821,23 60.9970257,23 C61.0652017,21.6631099 59.9613299,21.0460247 57.6854103,21.1487444 L48.2227983,21.1487444 L48.2227983,8 L45,8 Z"
                id="Path-3-Copy-12"
              />
              <polygon
                id="Path-3-Copy-15"
                points="100 15 100 17 106.687912 17 116 17 116 15 104.716007 15"
              />
              <path
                d="M116,15 L116,23 L100.002974,23 C99.9347983,21.5739839 101.03867,20.9157597 103.31459,21.0253274 L112.777202,21.0253274 L112.777202,15 L116,15 Z"
                id="Path-3-Copy-10"
              />
              <path
                d="M100,16 L100,8 L104.570347,8 L115.997026,8 C116.065202,9.42601613 114.96133,10.0842403 112.68541,9.97467261 L103.222798,9.97467261 L103.222798,16 L100,16 Z"
                id="Path-3-Copy-8"
              />
              <polygon id="Path-3-Copy-18" points="84 8 84 16 81 16 81 8" />
              <polygon
                id="Path-3-Copy-17"
                points="81 15 81 17 87.6879117 17 97 17 97 15 85.7160072 15"
              />
              <path
                d="M97,8 L97,23 L81.0029743,23 C80.9347983,21.6631099 82.0386701,21.0460247 84.3145897,21.1487444 L93.7772017,21.1487444 L93.7772017,8 L97,8 Z"
                id="Path-3-Copy-7"
              />
              <polygon
                id="Path-3-Copy-15"
                points="62.5 15 62.5 17 69.1879117 17 78.5 17 78.5 15 67.2160072 15"
              />
              <path
                d="M78.5,15 L78.5,23 L62.5029743,23 C62.4347983,21.5739839 63.5386701,20.9157597 65.8145897,21.0253274 L75.2772017,21.0253274 L75.2772017,15 L78.5,15 Z"
                id="Path-3-Copy-10"
              />
              <path
                d="M62.5,16 L62.5,8 L67.0703472,8 L78.4970257,8 C78.5652017,9.42601613 77.4613299,10.0842403 75.1854103,9.97467261 L65.7227983,9.97467261 L65.7227983,16 L62.5,16 Z"
                id="Path-3-Copy-8"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ValsysLogo;
