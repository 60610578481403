import { CSSProperties } from "styled-components";
import { IProcessedSimulationOutputItem, ISimulationLineItem } from "./scenarioAnalytics";
import { IFormatObject } from "./format";

export type TModelID = string;
export type TModuleID = string;
export type TCaseID = string;
export type TCaseReference = {
  uid: TCaseID;
  case: string; // Case name, e.g. Base
};

export type TVSEntity = "fact" | "line_item" | "module" | "case";

export type TPeriodType = "ANNUAL" | "QUARTERLY";

export enum EPermissionLevels {
  view = "view",
  edit = "edit",
  fullAccess = "fullAccess",
  owner = "owner",
}

export interface IModelPermissions {
  [EPermissionLevels.view]: boolean;
  [EPermissionLevels.edit]: boolean;
  [EPermissionLevels.fullAccess]: boolean;
  [EPermissionLevels.owner]: boolean;
}

export interface IModelVersion {
  hash: string;
  author: string;
  date: Date;
  action: string;
}

export interface IBasicModelInfo<D> {
  companyName: string;
  createdAt: D;
  createdBy?: string;
  currency: string;
  dataSources: string[];
  forecastPeriod: number;
  geography: string;
  historicalPeriod: number;
  id: string;
  industry: string;
  iterations: number;
  lastEdit: D;
  periodType: TPeriodType;
  permissions: IModelPermissions;
  precision: number;
  rollForward: boolean;
  startPeriod: number;
  styling: string;
  tags?: string[];
  templateID: string;
  ticker: string;
  title: string;
  updates: boolean;
}

export type IRawModelInfo = IBasicModelInfo<string>;

export type IModelInfo = IBasicModelInfo<Date>;

export interface IExtendedModelInfo<Fact = IRawFact> {
  cases: TCaseReference[];
  companyName: string;
  createdAt: string | Date;
  currency: string;
  geography: string;
  industry: string;
  iterations: number;
  lastEdit: Date;
  modelTags: string[];
  periodType: string;
  permissions: IModelPermissions;
  precision: number;
  startPeriod: number;
  styling: string;
  tags: string[] | null;
  templateID: string;
  terminalValuationCalcType?: string;
  ticker: string;
  id: string;
  error?: string;

  [key: string]:
    | number
    | string
    | TCaseReference[]
    | Date
    | string[]
    | undefined
    | null
    | ISimulationLineItem<Fact>[]
    | IProcessedSimulationOutputItem[]
    | IModelPermissions;
}

export interface ICell {
  formula: string;
  identifier: string;
  uid: string;
  value: string;
}

/** Denormalized (server) fact */
export interface IRawFact<F = string, P = number> {
  dependantCells?: ICell[];
  error?: string;
  format: F;
  formula?: string;
  identifier: string;
  internalFormula?: string;
  period: P;
  precedentCells?: ICell[];
  id: string;
  value?: string;
  loading?: number;
  uid: string;
}

/** Denormalized (server) line item */
export interface IRawLineItem {
  facts: IRawFact[];
  format: string;
  name: string;
  order: number;
  tags: string[];
  uid: string;
}

/** Denormalized (server) module */
export interface IRawModule {
  caseUid: string;
  depth: number;
  forecastIncrement: number;
  lineItems: IRawLineItem[];
  moduleEnd: number;
  moduleStart: number;
  name: string;
  order: number;
  periodType: string;
  uid: string;
}

export interface IRawCase {
  case: string;
  forecastEnd: number;
  forecastIncrement: number;
  forecastPeriod: number;
  historicalStart: number;
  modules: IRawModule[];
  startPeriod: number;
  uid: TCaseID;
}

// Interface for an unnormalized case
export interface ICase {
  case: string;
  forecastEnd: number;
  forecastIncrement: number;
  forecastPeriod: number;
  historicalStart: number;
  keyMetricUids?: { [key: string]: string };
  modules: TModuleID[];
  startPeriod: number;
  uid: TCaseID;
  [key: string]: unknown;
}

export interface IModuleInfo {
  depth: number;
  label?: string;
  name: string;
  parentUid: string;
  uid: string;
  unlinked?: boolean;
}

export interface IModule {
  caseUid: string;
  childModules?: string[];
  depth: number;
  forecastIncrement: number;
  lineItems?: string[];
  lineItemsNames?: string[];
  moduleEnd: number;
  moduleStart: number;
  name: string;
  order: number;
  parentUid: string;
  formattedPeriods: string[];
  type?: TVSEntity;
  uid: string;
  unlinked?: boolean;
  startPeriod: number;
}

/** Type guard to check if an object is an IModule **/
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function isModule(obj: any): obj is IModule {
  return (obj as IModule).startPeriod !== undefined;
}

export interface ILineItem {
  caseUid: string;
  deps: { [uid: string]: ILineItem };
  facts: string[];
  format: CSSProperties;
  name: string;
  order: number;
  parentUid: string;
  id: string;
  uid: string;
  tags?: string[];
  type?: TVSEntity;
}

/** Type guard to check if an object is an ILineItem **/
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function isLineItem(obj: any): obj is ILineItem {
  return (obj as ILineItem).facts !== undefined;
}

export interface IFact extends Omit<IRawFact, "format"> {
  caseUid: string;
  error: string;
  format: IFormatObject;
  moduleUid: string;
  parentOrder: number;
  parentUid: string;
  type?: TVSEntity;
  loading: number; // counts the number of loading dependencies
  period: number;
  formattedPeriod: string;
}

/** Type guard to check if an entity is a fact when using generic utils **/
export function isFactEntity(entity: IModule | ILineItem | IFact): entity is IFact {
  return (entity as IFact).loading !== undefined;
}

export interface IModel {
  lineItems: Record<string, ILineItem>;
  facts: Record<string, IFact>;
  modules: Record<string, IModule>;
  modelInfo: IModelInfo;
}

export interface IAvailableStateChanges {
  undo: boolean;
  redo: boolean;
}

export interface IVSRow<F> {
  rowHeader: string;
  //uid?: string;
  [index: number]: F;
  [key: string]: string | F | CSSProperties;
}

export interface IVSCompsTable {
  table: { colName: string; values: number[] }[];
}
