import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Flex, NormalAlignDivider } from "components/utility/StyledComponents";
import { ViewWidget } from "types/view";
import { Widget as TWidget } from "types/widget";
import { FullWidthTooltip } from "components/utility/StyledDashboardComponents";
import { Button, Classes, Icon, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Selector, SelectorContext, SelectorOption, SelectorTypes } from "types/vsl";
import useVSLSelector from "hooks/dashboard/useVSLSelector";
import { getSelectorOptions } from "utils/vsl";
import isFunction from "lodash.isfunction";
import { sanitiseWidgetSelectorsOptions } from "utils/selectors";
import { SelectorsGroup } from "components/dashboard/SelectorsGroup";
import useSWRImmutable from "swr/immutable";
import { IResponse } from "types";
import { FETCH_SELECTOR_TYPES } from "constants/apiConstants";
import { swrApi } from "utils/api";

interface WidgetSelectorsBarProps {
  updateWidget?: (details: Partial<ViewWidget>) => Promise<boolean>;
  widget: TWidget;
  locked?: boolean;
  injectedSelectors?: Record<string, SelectorOption[]>;
  enableEditableSelectors?: boolean;
  viewId?: string;
  setSelectorsDialogOpen: Dispatch<SetStateAction<boolean>>;
}

export const WidgetSelectorsBar: React.FC<WidgetSelectorsBarProps> = ({
  updateWidget,
  widget,
  injectedSelectors,
  enableEditableSelectors = true,
  locked = false,
  viewId,
  setSelectorsDialogOpen,
}) => {
  const [localSelectorsHidden, setLocalSelectorsHidden] = useState(widget.selectorsHidden);

  const [activeSelectors, setActiveSelectors] = useState<Record<string, SelectorOption[]>>({
    ...getSelectorOptions(widget.selectors),
    ...(injectedSelectors || {}),
  });

  /** Fetch selectors options */
  const {
    error: selectorsOptionsError,
    selectorsOptions,
    loading: loadingSelectorsOptions,
  } = useVSLSelector(widget?.selectors, { widgetId: widget.id, viewId: viewId });

  /** fetch selectortypes to pass to SelectorsGroup */
  const {
    data: selectorTypes,
    error: selectorTypesError,
    isLoading: loadingSelectorTypes,
  } = useSWRImmutable<IResponse<SelectorTypes>>([FETCH_SELECTOR_TYPES], swrApi);

  /** update active selectors based on widget config */
  useEffect(() => {
    setActiveSelectors((prevActiveSelectors) => ({
      ...prevActiveSelectors,
      ...injectedSelectors,
    }));
  }, [injectedSelectors]);

  /** Reset selectors on widget change */
  useEffect(() => {
    setActiveSelectors(getSelectorOptions(widget.selectors));
    // instead of passing another prop for ID into the component to reset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget.id]);

  // Sanitize selectors selected_options after re-fetching selectors options
  useEffect(() => {
    if (!selectorsOptions) return;
    setActiveSelectors((prevSelectors) => {
      const nextSelectors = sanitiseWidgetSelectorsOptions(
        prevSelectors,
        selectorsOptions,
        injectedSelectors
      );
      if (!nextSelectors) return prevSelectors;
      if (widget.selectors && updateWidget)
        updateWidget({
          selectors: widget.selectors.map((widgetSelector) => ({
            ...widgetSelector,
            selected_options: nextSelectors[widgetSelector.id] ?? widgetSelector.selected_options,
          })),
        });
      return nextSelectors;
    });
  }, [injectedSelectors, selectorsOptions, updateWidget, widget.selectors]);

  /** keep widget.selectorsHidden in sync */
  useEffect(() => {
    setLocalSelectorsHidden((prevState) => {
      if ("selectorsHidden" in widget && prevState !== widget.selectorsHidden)
        return widget.selectorsHidden;
      else return prevState;
    });
  }, [widget]);

  const setSelectorsHidden = (hideSelectors: boolean) => {
    if (updateWidget) {
      updateWidget({ ...widget, selectorsHidden: hideSelectors });
    }
    // we use a local variable so we can use the functionality without an updateWidget method available
    setLocalSelectorsHidden((prevState) => !prevState);
  };

  /** active selectors that are not hidden */
  const activeVisibleSelectors = Object.keys(activeSelectors)
    .map(
      (activeSelectorId) =>
        widget.selectors?.find((selector) => selector.id === activeSelectorId && !selector.hidden)
          ?.id
    )
    .filter((sel): sel is string => sel !== undefined);

  const selectorLength = widget.selectors
    ? Object.values(widget?.selectors).filter((selector) => !selector.hidden).length
    : 0;

  const setSelectedSelector = useCallback(
    (selector: Selector) => {
      setActiveSelectors((prevActiveSelectors) => ({
        ...prevActiveSelectors,
        [selector.id]: selector.selected_options ?? [],
      }));
      const selectorIndex = widget.selectors?.findIndex((sel) => sel.id === selector.id);
      const newSelectors = [...(widget?.selectors || [])];
      newSelectors[selectorIndex ?? widget.selectors?.length ?? 0] = selector;
      if (isFunction(updateWidget)) {
        updateWidget({ selectors: newSelectors });
      }
    },
    [updateWidget, widget.selectors]
  );

  if (selectorLength === 0) {
    return null;
  }

  return (
    <Flex flexDirection="column" fullWidth>
      <Flex alignItems="flex-start" justifyContent="flex-start" flexDirection="row" fullWidth>
        {localSelectorsHidden ? (
          <FullWidthTooltip
            content={activeVisibleSelectors.join(", ")}
            enforceFocus={false}
            openOnTargetFocus={false}
            hoverOpenDelay={200}
            position={Position.BOTTOM}
          >
            <Flex
              className={Classes.MENU_ITEM}
              fullWidth
              justifyContent="space-between"
              style={{ padding: "2px 0 2px 10px", cursor: "pointer", height: "100%" }}
              onClick={() => setSelectorsHidden(!localSelectorsHidden)}
            >
              <a className={Classes.TEXT_SMALL}>
                {`${selectorLength} selector${selectorLength === 1 ? "" : "s"}`}
              </a>

              <Icon
                className={Classes.TEXT_MUTED}
                icon={IconNames.CHEVRON_DOWN}
                style={{ padding: "0 5px" }}
              />
            </Flex>
          </FullWidthTooltip>
        ) : (
          <Flex alignItems="stretch" flexDirection="row" fullWidth>
            <SelectorsGroup
              selectors={widget.selectors}
              context={SelectorContext.WIDGET}
              setSelectorOnSelect={setSelectedSelector}
              setSelectorsDialogOpen={setSelectorsDialogOpen}
              activeSelectors={activeSelectors}
              enableEditableSelectors={enableEditableSelectors}
              injectedSelectors={injectedSelectors}
              loadingSelectorsOptions={loadingSelectorsOptions}
              locked={locked}
              query={widget.query}
              selectorsOptions={selectorsOptions}
              selectorsOptionsError={selectorsOptionsError}
              availableSelectorTypes={selectorTypes?.data}
              isLoading={loadingSelectorTypes}
              error={selectorTypesError}
            />
            <Button
              disabled={locked}
              icon={IconNames.CHEVRON_UP}
              minimal
              small
              onClick={() => setSelectorsHidden(!localSelectorsHidden)}
            />
          </Flex>
        )}
      </Flex>
      <NormalAlignDivider style={{ margin: 0 }} />
    </Flex>
  );
};
