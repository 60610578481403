import React, { MouseEventHandler, useState } from "react";
import { Classes, MenuItem, Spinner, SpinnerSize } from "@blueprintjs/core";
import { ItemRenderer, MultiSelect2 } from "@blueprintjs/select";
import { IconNames } from "@blueprintjs/icons";

interface IModelTagInputProps {
  allTags?: string[];
  disabled?: boolean;
  fill?: boolean;
  handleAddTag: (tag: string) => void;
  handleTagClick?: (tag: string) => void;
  handleTagRemove?: (tag: string) => void;
  loading?: boolean;
  tags: string[];
}

const ModelTagInput: React.FC<IModelTagInputProps> = ({
  allTags = [],
  disabled = false,
  fill = true,
  handleAddTag,
  handleTagClick,
  handleTagRemove,
  loading = false,
  tags,
}) => {
  let uid;
  const [query, setQuery] = useState("");

  const itemRenderer: ItemRenderer<string> = (item, { handleClick, modifiers }) => {
    return <MenuItem active={modifiers.active} key={item} onClick={handleClick} text={item} />;
  };

  const filterTags = (query: string, item: string) => {
    return item.toLowerCase().includes(query.toLowerCase());
  };

  const onTagClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.currentTarget.innerText && e.currentTarget.innerText !== "smallcross") {
      if (typeof handleTagClick === "function") {
        handleTagClick(e.currentTarget.innerText);
      }
    }
  };

  function newItemRenderer(queryString: string, active: boolean) {
    return (
      <MenuItem
        disabled={disabled}
        active={active}
        icon={IconNames.ADD}
        onClick={() => addTag(queryString)}
        text={`Add "${queryString}"`}
      />
    );
  }
  const addTag = (tag: string) => {
    setQuery("");
    handleAddTag(tag);
  };
  return (
    <MultiSelect2
      className={`tag-input ${Classes.FILL}`}
      createNewItemFromQuery={(query) => {
        return query;
      }}
      createNewItemRenderer={newItemRenderer}
      disabled={disabled || loading}
      fill={fill}
      onItemSelect={addTag}
      onQueryChange={setQuery}
      onRemove={handleTagRemove}
      placeholder="Click to add tag..."
      popoverProps={{ minimal: true }}
      itemPredicate={filterTags}
      itemRenderer={itemRenderer}
      items={allTags}
      selectedItems={tags}
      tagRenderer={(item) => item}
      tagInputProps={{
        rightElement: loading ? (
          <div style={{ marginTop: 4 }}>
            <Spinner size={SpinnerSize.SMALL} />
          </div>
        ) : undefined,
        tagProps: {
          onClick: onTagClick,
          interactive: uid !== "filter",
          minimal: true,
          round: true,
        },
      }}
      query={query}
    />
  );
};

export default React.memo(ModelTagInput);
