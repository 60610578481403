import { ColumnSizingState, OnChangeFn, SortingState, Updater } from "@tanstack/react-table";
import Table from "components/table/Table";
import { StyledWidgetScrollContainer } from "components/utility/StyledDashboardComponents";
import AppContext from "context/appContext";
import isFunction from "lodash.isfunction";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IVSLTable, VSLResponse } from "types/vsl";
import { TableWidgetConfig, Widget as TWidget } from "types/widget";
import { vslTableDataToTableData } from "utils/table";
import TableBottomControlRibbon from "components/dashboard/widget/table/TableBottomControlRibbon";
import debounce from "lodash.debounce";

interface TableWidgetProps {
  enableTableColumnConfigEditing?: boolean;
  enableTableSort?: boolean;
  showControlRibbon?: boolean;
  updateWidgetConfig?: (details: Partial<TableWidgetConfig>) => Promise<boolean>;
  widget: TWidget;
  config?: TableWidgetConfig;
  /* eslint-disable-next-line "@typescript-eslint/no-explicit-any" */
  error: any;
  loading: boolean;
  data: VSLResponse;
}

/**
 * Table Widget - widget that displays are table data
 */
const TableWidget: React.FC<TableWidgetProps> = ({
  enableTableColumnConfigEditing = true,
  enableTableSort = true,
  showControlRibbon = true,
  updateWidgetConfig,
  widget,
  config,
  error,
  loading,
  data,
}) => {
  const {
    config: { theme },
  } = useContext(AppContext);

  const [sortingState, setSortingState] = useState<SortingState>(
    config?.sort
      ? [
          {
            id: config.sort.sortBy,
            desc: config.sort.sortDirection === "desc",
          },
        ]
      : []
  );

  useEffect(() => {
    if (config?.sort && config.sort.sortBy !== sortingState[0]?.id) {
      setSortingState([
        {
          id: config.sort.sortBy,
          desc: config.sort.sortDirection === "desc",
        },
      ]);
    }
  }, [sortingState, config?.sort]);

  const [pinnedColumns, setPinnedColumns] = useState(config?.pinnedColumns ?? 1);
  const layoutMode = config?.layoutMode ?? "fixed";
  const [fontSize, setFontSize] = useState(config?.fontSize ?? "Medium");

  const updateFontSize = useCallback(
    (nextFontSize: "Small" | "Medium" | "Large") => {
      setFontSize(nextFontSize);
      if (updateWidgetConfig) updateWidgetConfig({ fontSize: nextFontSize });
    },
    [updateWidgetConfig]
  );

  const updatePinnedColumns = useCallback(
    (nextPinnedColumns: number) => {
      setPinnedColumns(nextPinnedColumns);
      if (updateWidgetConfig) updateWidgetConfig({ pinnedColumns });
    },
    [pinnedColumns, updateWidgetConfig]
  );

  const onColumnSizingChange = useCallback(
    (columnSizingState: ColumnSizingState) => {
      if (updateWidgetConfig) {
        const columns = config?.columns ? [...config.columns] : [];

        Object.entries(columnSizingState).forEach(([id, size]) => {
          const colIndex = columns?.findIndex((c) => c.id === id);
          if (colIndex !== -1) {
            columns.splice(colIndex, 1, { ...columns[colIndex], width: size });
          } else {
            columns.push({ id, header: id, width: size });
          }
        });
        updateWidgetConfig({ columns });
      }
    },
    [config, updateWidgetConfig]
  );

  const debouncedOnColumnSizingChange = useMemo(() => {
    return debounce(onColumnSizingChange, 500);
  }, [onColumnSizingChange]);

  const updateSortingState: OnChangeFn<SortingState> = useCallback(
    (nextSortState: Updater<SortingState>) => {
      if (setSortingState && sortingState) {
        setSortingState(nextSortState);
        const sort = isFunction(nextSortState) ? nextSortState(sortingState) : sortingState;

        if (updateWidgetConfig) {
          updateWidgetConfig({
            sort: sort.length
              ? {
                  sortBy: sort[0].id,
                  sortDirection: sort[0].desc ? "desc" : "asc",
                }
              : undefined,
          });
        }
      }
    },
    [sortingState, setSortingState, updateWidgetConfig]
  );

  const showContent = data.data && !error && widget.id;

  const dt = data.data as IVSLTable;
  const vslTable = showContent
    ? vslTableDataToTableData(dt.raw, dt.columns, config?.filters)
    : null;

  return (
    <>
      <StyledWidgetScrollContainer
        $extraPadding={!!(loading || error || !widget?.query)}
        $theme={theme}
      >
        {showContent ? (
          <Table
            // The columns from the server, derived from the vsl query.
            id={widget.id}
            columnTemplates={dt.columns}
            configOverrides={config}
            displayRowIndex={true}
            data={vslTable}
            enableConfigOverrides={enableTableColumnConfigEditing}
            enableSelection={false}
            loading={loading}
            onColumnSizingChange={debouncedOnColumnSizingChange}
            pinnedColumns={pinnedColumns}
            setSortingState={enableTableSort ? updateSortingState : undefined}
            sortingState={enableTableSort ? sortingState : undefined}
            updateConfig={updateWidgetConfig}
          />
        ) : null}
      </StyledWidgetScrollContainer>
      {showControlRibbon && showContent ? (
        <TableBottomControlRibbon
          columns={dt.columns}
          fontSize={fontSize}
          layoutMode={layoutMode}
          pinnedColumns={pinnedColumns}
          tableRendererConfig={config}
          updatePinnedColumns={updatePinnedColumns}
          updateFontSize={updateFontSize}
          updateConfig={updateWidgetConfig}
          numberOfEntries={dt.rows?.length ?? 0}
        />
      ) : null}
    </>
  );
};

TableWidget.displayName = "Widget";

export default TableWidget;
