import React from "react";
import { Button, Classes, Dialog, DialogBody, Intent } from "@blueprintjs/core";

import TermsOfUse from "components/user/TermsOfUse";
import { multiToaster } from "utils/toaster";
import { Themes } from "../../constants/uiConstants";

const TermsAndConditionsDialog = ({ closed, theme, updateTermsAndConditionsStatus }) => {
  return (
    <Dialog
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      className={theme === Themes.DARK ? Classes.DARK : undefined}
      icon="info-sign"
      intent={Intent.PRIMARY}
      isCloseButtonShown={false}
      isOpen={!closed}
      title="Terms of Use"
    >
      <DialogBody>
        <TermsOfUse />
        <div style={{ textAlign: "right" }}>
          <Button
            intent={Intent.WARNING}
            onClick={() => {
              multiToaster.show({
                message: " Use of the platform is conditional upon acceptance of the Terms of Use.",
                intent: Intent.DANGER,
              });
              updateTermsAndConditionsStatus(false);
            }}
            text="Decline"
          />
          &nbsp;
          <Button
            intent={Intent.PRIMARY}
            onClick={() => updateTermsAndConditionsStatus(true)}
            text="Accept"
          />
        </div>
      </DialogBody>
    </Dialog>
  );
};

TermsAndConditionsDialog.defaultProps = { closed: true };

export default TermsAndConditionsDialog;
