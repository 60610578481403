import {
  AnchorButton,
  Button,
  Callout,
  Classes,
  Colors,
  Divider,
  EditableText,
  H4,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  NonIdealState,
  Popover,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import LoadingIndicator from "components/utility/LoadingIndicator";
import { Flex, FullSizeDiv, styledScrollBar } from "components/utility/StyledComponents";
import { MainContainer } from "components/utility/StyledDashboardComponents";
import { Themes } from "constants/uiConstants";
import AppContext from "context/appContext";
import useDashboard from "hooks/dashboard/useDashboard";
import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import AddViewDialog from "components/dashboard/AddViewDialog";
import { Watchlist } from "types/collections";
import { IResponse, TTheme } from "types";
import {
  DASHBOARD_ROUTE,
  EMethods,
  EStatus,
  REORDER_VIEWS_ROUTE,
  WATCHLIST_ROUTE,
} from "constants/apiConstants";
import api from "utils/api";
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from "react-beautiful-dnd";
import { reorder } from "utils/generic";
import ViewControls from "./ViewControls";
import { multiToaster } from "utils/toaster";
import useSetPageTitle from "hooks/useSetPageTitle";
import { View } from "types/view";
import FormDialog from "components/utility/FormDialog";
import { Dashboard as IDashboard } from "types/dashboard";
import useView from "hooks/dashboard/useView";
import { mutate as globalMutate } from "swr/_internal";

const Dashboard = () => {
  const [editDashbaordDialogOpen, setEditDashboardDialogOpen] = useState(false);
  const [editViewDialogId, setEditViewDialogId] = useState<string>();
  const [watchlist, setWatchlist] = useState<Watchlist>();
  const [addViewDialogOpen, setAddViewDialogOpen] = useState(false);
  const { dashboardId, watchlistId, viewId } = useParams();
  const navigate = useNavigate();
  const { addView, dashboard, loading, removeView, updateDashboard, updating } =
    useDashboard(dashboardId);
  const { updateView, view, loading: loadingView } = useView(editViewDialogId);

  useSetPageTitle(dashboard?.name ? `${dashboard?.name}` : "");

  const {
    config: { theme },
  } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      if (!watchlistId) return;
      const response: IResponse<{ watchlist: Watchlist }> = await api(WATCHLIST_ROUTE, {
        watchlistId: watchlistId,
        method: EMethods.GET,
      });

      setWatchlist(response.data?.watchlist);
    })();
  }, [watchlistId]);

  const handleDelete = async (view: View) => {
    if (confirm(`Permanently delete View "${view.name}"? It cannot be recovered.`)) {
      if (await removeView(view.id)) navigate(`/dashboards/${dashboardId}`);
    }
  };

  const onTabDragEnd: OnDragEndResponder = async (dropResult) => {
    if (dashboard?.views && dropResult?.destination) {
      const nextIndex = dropResult.destination.index;
      const oldViews = dashboard.views;
      const nextViews = reorder(dashboard.views, dropResult.source.index, nextIndex);

      updateDashboard({ views: nextViews }, false);

      const response = await api(REORDER_VIEWS_ROUTE, {
        dashboardId,
        method: EMethods.PUT,
        newOrder: nextIndex + 1, // BE order 1 indexed, rbdnd order 0 indexed.
        viewId: dropResult.draggableId,
      });

      if (response?.status !== EStatus.success) {
        multiToaster.show({ intent: Intent.WARNING, message: response.message || response?.error });
        updateDashboard({ views: oldViews }, false);
      }
    }
  };

  if (!viewId && dashboard?.views?.length) {
    const to = watchlistId
      ? `/watchlists/shared/${watchlistId}/${dashboardId}/${dashboard.views[0].id}`
      : `/dashboards/${dashboardId}/${dashboard.views[0].id}`;
    return <Navigate replace to={to} />;
  }

  return (
    <MainContainer rows="auto auto 1fr" style={{ overflow: "hidden" }}>
      {!!editViewDialogId && (
        <FormDialog<View>
          failureToastMessage="Couldn't update view"
          fields={[{ id: "name", label: "Name", type: "text" }]}
          isOpen={!!editViewDialogId}
          title={`Edit View "${view?.name}"`}
          setDialogOpen={(isOpen) => {
            if (!isOpen) setEditViewDialogId(undefined);
          }}
          submit={async (nextView) => {
            const success = await updateView(nextView);
            if (success) {
              globalMutate([DASHBOARD_ROUTE, { dashboardId }]);
              return true;
            }
          }}
          successToastMessage="View updated successfully"
          values={view}
          loading={loadingView}
        />
      )}
      <AddViewDialog
        addView={addView}
        isOpen={addViewDialogOpen}
        onClose={() => setAddViewDialogOpen(false)}
        updating={updating}
      />
      <FormDialog<IDashboard>
        title={`Edit Dashboard "${dashboard?.name}"`}
        fields={[
          { id: "name", label: "Name:", type: "text" },
          { id: "description", label: "Description:", type: "text", optional: true },
        ]}
        isOpen={editDashbaordDialogOpen}
        setDialogOpen={setEditDashboardDialogOpen}
        submit={updateDashboard}
        values={dashboard}
      />
      {loading ? (
        <LoadingIndicator status={{ intent: Intent.PRIMARY, message: "Loading Dashboard..." }} />
      ) : null}
      {dashboard ? (
        <>
          <Flex
            alignItems="flex-start"
            fullWidth
            justifyContent="space-between"
            style={{ marginBottom: 10 }}
          >
            <Flex flex="1 1 80%" fullWidth justifyContent="flex-start" style={{ minWidth: 0 }}>
              {watchlist ? (
                <H4 style={{ margin: 0 }}>{watchlist.name}</H4>
              ) : (
                <H4 style={{ margin: 0 }}>
                  <EditableText
                    onChange={(nextValue) => updateDashboard({ name: nextValue })}
                    value={dashboard.name}
                  />
                </H4>
              )}
              <Divider />
              <Tag
                round
                minimal
                intent={Intent.PRIMARY}
                style={{ border: `1px solid ${Colors.BLUE3}` }}
              >
                Visible to everyone
              </Tag>
            </Flex>
            {!loading && !watchlist && (
              <Button
                icon={IconNames.COG}
                intent={Intent.PRIMARY}
                onClick={() => setEditDashboardDialogOpen(true)}
                style={{ flex: "0 1 auto" }}
                text="Settings"
              />
            )}
          </Flex>
          {!dashboard?.views || dashboard.views.length === 0 ? (
            <NonIdealState
              action={
                <Button
                  icon={IconNames.ADD}
                  onClick={() => setAddViewDialogOpen(true)}
                  text="Add View"
                />
              }
              icon={IconNames.SEARCH}
              title="No Views associated with Dashboard!"
            />
          ) : (
            <DragDropContext onDragEnd={onTabDragEnd}>
              <ViewTabScrollContainer justifyContent="flex-start" $theme={theme}>
                <Droppable direction="horizontal" droppableId="view-tabs">
                  {(provided) => (
                    <div
                      className={Classes.TAB_LIST}
                      ref={provided.innerRef}
                      style={{ justifyContent: "flex-start", minWidth: 0 }}
                    >
                      {dashboard.views
                        ? [...dashboard.views].map((view, index) => {
                            const dashboardLink = watchlistId
                              ? `/watchlists/shared/${watchlistId}/${dashboardId}/${view.id}`
                              : `/dashboards/${dashboardId}/${view.id}`;
                            return (
                              <Draggable draggableId={view.id} index={index} key={view.id}>
                                {(provided) => (
                                  <ViewTab
                                    $active={viewId === view.id}
                                    className={
                                      viewId === view.id
                                        ? `${Classes.TAB} ${Classes.SELECTED}`
                                        : Classes.TAB
                                    }
                                    ref={provided.innerRef}
                                    $theme={theme}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Link to={dashboardLink}>
                                      <span>{view.name}</span>
                                    </Link>
                                    <Popover
                                      content={
                                        <Menu>
                                          <MenuItem
                                            icon={IconNames.EDIT}
                                            onClick={() => setEditViewDialogId(view.id)}
                                            text="Rename View"
                                          />
                                          <MenuDivider />
                                          <MenuItem
                                            icon={IconNames.DELETE}
                                            intent={Intent.DANGER}
                                            onClick={() => handleDelete(view)}
                                            text="Delete View"
                                          />
                                        </Menu>
                                      }
                                    >
                                      <Button
                                        icon={IconNames.MORE}
                                        minimal
                                        small
                                        style={{ marginLeft: 2, transform: "rotate(90deg)" }}
                                      />
                                    </Popover>
                                  </ViewTab>
                                )}
                              </Draggable>
                            );
                          })
                        : null}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                <Tooltip content="Add View" openOnTargetFocus={false}>
                  <AnchorButton
                    minimal
                    icon={IconNames.ADD}
                    intent={Intent.PRIMARY}
                    onClick={() => setAddViewDialogOpen(true)}
                  />
                </Tooltip>
              </ViewTabScrollContainer>
            </DragDropContext>
          )}
        </>
      ) : null}
      {!loading && !dashboard ? (
        <Callout intent={Intent.WARNING} title="Unable to fetch Dashboard.">
          <p>
            Unable to load the selected Dashboard. It may have been deleted, or the server may be
            experiencing issues. Please refresh and try again.
          </p>
        </Callout>
      ) : null}
      {!loading && dashboard && (
        <FullSizeDiv fullHeight style={{ overflow: "hidden" }}>
          {viewId && <ViewControls removeView={removeView} viewId={viewId} />}
        </FullSizeDiv>
      )}
    </MainContainer>
  );
};

const ViewTabScrollContainer = styled(Flex)`
  min-width: 0;
  overflow: auto;
  ${styledScrollBar}
`;

const ViewTab = styled(Flex)<{ $active: boolean; $theme: TTheme }>`
  ${({ $active, $theme }) =>
    $active
      ? css`
          border-bottom: 3px solid ${$theme === Themes.DARK ? Colors.BLUE5 : Colors.BLUE2};
          a {
            span {
              color: ${$theme === Themes.DARK ? Colors.BLUE5 : Colors.BLUE2};
            }
          }
        `
      : css`
          padding-bottom: 3px;
        `};
`;

export default Dashboard;
