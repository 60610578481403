import React from "react";
import "styles/sensitivity-analysis.scss";
import VSHTMLTable from "components/dashboard/VSHTMLTable";
import { useSelector } from "react-redux";
import { tagLineItemsMapSelector } from "selectors/cpp_selectors";
import { lineItemsSelector, modelInfoSelector } from "selectors/modelSelectors";
import { factsSelector } from "selectors/dataEntrySelectors";
import { cppTableWidths, fetchTagData } from "utils/cpp";

const historicalValuationTags = [
  "EV/Revenue",
  "EV/EBITDA ex SBC",
  "P/E NTM ex SBC",
  "P/Bk",
  "EV/EBIT ex SBC",
];

const suffix = "CIQ {Market Multiples (Read Only)}";

const header: string[] = ["", "Min", "Max", "1-yr Avg", "5-yr Avg", "Current"];
const columns: string[] = ["", "Min", "Max", "1-yr Avg", "5-yr Avg", "Current"];

const HistoricalValuationTable = () => {
  const tagLineItemsMap = useSelector(tagLineItemsMapSelector);
  const lineItems = useSelector(lineItemsSelector);
  const facts = useSelector(factsSelector);
  const modelInfo = useSelector(modelInfoSelector);
  const startPeriod = modelInfo?.startPeriod;

  const data = [header];
  historicalValuationTags.forEach((tag) => {
    const row = [tag];
    columns.forEach((col) => {
      if (!col) return;
      row.push(
        fetchTagData(`${tag} ${col} ${suffix}`, startPeriod, tagLineItemsMap, lineItems, facts)
      );
    });
    data.push(row);
  });

  return <VSHTMLTable data={data} widths={cppTableWidths} />;
};

export default HistoricalValuationTable;
